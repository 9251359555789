import {styled} from '@mui/material/styles';
import {Paper} from '@mui/material';

interface TileProps {
    bgVariant?: 'standard' | 'neutral' | 'white';
}

export const Tile = styled(Paper, {shouldForwardProp: (prop) => prop !== 'bgVariant'})<TileProps>(
    ({theme, bgVariant = 'standard'}) => {
        let bgColor: string;
        let bgImage: string;
        let border: string;
        switch (bgVariant) {
            case 'neutral':
                if (theme.logo.name === 'devizovaburza') {
                    bgColor = 'white';
                    bgImage = 'linear-gradient(120deg,rgba(226,128,214,0.25),rgba(113,235,240,0.25))';
                } else {
                    bgColor = theme.cool.grey10;
                    bgImage = 'none';
                }
                border = 'none';
                break;
            case 'white':
                bgColor = theme.palette.common.white;
                bgImage = 'none';
                border = '1px solid ' + theme.warm.grey20;
                break;
            default:
                if (theme.logo.name === 'devizovaburza') {
                    bgColor = 'white';
                    bgImage = 'linear-gradient(120deg,rgba(226,128,214,0.40),rgba(113,235,240,0.40))';
                } else {
                    bgColor = theme.palette.background.default;
                    bgImage = 'none';
                }
                border = 'none';
        }

        return {
            padding: '22px',
            boxShadow: 'none',
            backgroundColor: bgColor,
            backgroundImage: bgImage,
            border: border,
            borderRadius: '16px',
            '& .MuiFormControl-root': {
                backgroundColor: theme.logo.name === 'devizovaburza' ? 'transparent' : bgColor,
            },
            '& .MuiInputBase-root': {
                backgroundColor: '#FFFFFF',
            },
        };
    }
);
