import {truncateMiddle} from '~/helpers/text';
import {ResponsiveStyleValue} from '@mui/system';
import {Breakpoint, useTheme} from '@mui/material';
import React from 'react';

interface ResponsiveTextProps {
    text?: string;
    maxSize?: ResponsiveStyleValue<number>;
}

/**
 * Renders text with length that can be changes based on device width
 */
export default function ResponsiveText({text, maxSize}: ResponsiveTextProps) {
    const theme = useTheme();

    if (!text) {
        return null;
    }

    if (!maxSize) {
        const truncatedText = truncateMiddle({
            text: text,
        });
        return <span>{truncatedText}</span>;
    }

    const validBreakpointKeys: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];
    const breakpoints: Breakpoint[] = [];
    let highestMatchedBreakpointSize = 24;

    if (typeof maxSize === 'object') {
        Object.keys(maxSize)
            .reverse()
            .forEach((breakpoint: Breakpoint) => {
                if (validBreakpointKeys.includes(breakpoint)) {
                    breakpoints.push(breakpoint);
                }
            });
    }

    for (const breakpoint of breakpoints) {
        const breakpointWidth = theme.breakpoints.values[breakpoint];
        const mq = window.matchMedia(`(min-width: ${breakpointWidth}px)`);

        if (mq.matches) {
            highestMatchedBreakpointSize = maxSize[breakpoint];
            break;
        }
    }

    const truncatedText = truncateMiddle({
        text: text,
        maxSize: highestMatchedBreakpointSize,
    });

    return <span>{truncatedText}</span>;
}
