import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, {forwardRef} from 'react';
import {Chip} from '@mui/material';

const PriceChange = forwardRef(
    (
        {
            priceChange,
            contrastColor = false,
        }: {
            priceChange: number | string;
            contrastColor?: boolean;
        },
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        if (typeof priceChange === 'string') {
            priceChange = Number.parseFloat(priceChange);
        }

        const increase = priceChange > 0;

        function getIcon() {
            return increase ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
        }

        function getBgColor() {
            if (contrastColor) {
                return '#FFFFFF';
            } else {
                return increase ? '#1CC22C1A' : '#EE35351A';
            }
        }

        function getColor() {
            if (contrastColor) {
                return '#FD6300';
            } else {
                return increase ? '#1CC22C' : '#EE3535';
            }
        }

        return (
            <Chip
                size='small'
                label={`${priceChange} %`}
                icon={getIcon()}
                sx={{
                    backgroundColor: getBgColor(),
                    color: getColor(),
                    fontWeight: 700,
                    '& .MuiChip-icon': {
                        color: getColor(),
                    },
                }}
                ref={ref}
            />
        );
    }
);
PriceChange.displayName = 'PriceChange';
export default PriceChange;
