import {Button, Paper, Stack, styled, Typography} from '@mui/material';
import {useLocalizedNavigate} from '@simplecoin/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const PurpleBall = styled('div')`
    border-radius: 50%;
    position: absolute;
    width: 96px;
    height: 96px;
    right: -52px;
    top: 104px;
    background: linear-gradient(180deg, #41277e 23.65%, #f4f2ff 113.33%);
`;

const OrangeBall = styled('div')`
    border-radius: 50%;
    position: absolute;
    width: 204px;
    height: 204px;
    bottom: -34px;
    left: -102px;
    background: linear-gradient(200.04deg, #fd6300 37.65%, #fff1e7 84.29%);
`;

export default function StartWithFunding() {
    const {t} = useTranslation();
    const navigate = useLocalizedNavigate();

    return (
        <Paper
            sx={{
                width: 1,
                position: 'relative',
                overflow: 'clip',
                display: 'flex',
                justifyContent: 'center',
                height: 1,
            }}
        >
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={{xs: 4, md: 5}}
                sx={{height: 1, width: {xs: 0.8, md: 0.7}, my: 4}}
            >
                <CheckCircleOutlineRoundedIcon color='secondary' sx={{fontSize: '82px'}} />
                <Typography variant='h2' textAlign='center'>
                    {t('start_with_funding_text_1')}
                </Typography>
                <Typography textAlign='center'>{t('start_with_funding_text_2')}</Typography>
                <Button
                    variant='outlined'
                    onClick={() => {
                        navigate('/trade', {
                            state: {
                                value: 'depositForm',
                            },
                        });
                    }}
                >
                    {t('page_dashboard_insert_deposit')}
                </Button>
            </Stack>
            <PurpleBall />
            <OrangeBall />
        </Paper>
    );
}
