import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {RootState} from '../redux/store';
import {getPathWithoutLanguage} from '../helpers/url';
import {redirectProgrammaticInvalidate} from '../redux/redirectSlice';

/**
 * Listener of redirect reducer that redirects user to specified url page.
 */
export default function RedirectProgrammatic() {
    const navigate = useNavigate();
    const {redirectProgrammatic} = useSelector((state: RootState) => state.redirect);
    const dispatch = useDispatch();

    useEffect(() => {
        if (redirectProgrammatic?.url) {
            dispatch(redirectProgrammaticInvalidate());
            const shouldPushNewUrl =
                redirectProgrammatic.url &&
                getPathWithoutLanguage(location.pathname, true) !== redirectProgrammatic.url;
            if (shouldPushNewUrl) {
                navigate(redirectProgrammatic.url);
            }
        }
    }, [redirectProgrammatic]);

    return null;
}

RedirectProgrammatic.displayName = 'RedirectProgrammatic';
