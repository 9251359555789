import {configureStore} from '@reduxjs/toolkit';
import {coreReducers, rootSaga} from '@simplecoin/core';
import {useDispatch} from 'react-redux';
import user from '~/src/pages/site/siteReducer';
import recipients from '~/src/pages/recipient/RecipientReducers';
import security from '~/src/pages/settings/security/securityReducers';
import accountSettings from '~/src/pages/settings/settingsReducers';
import modules from '~/src/modules/combinedModules';
import {breadcrumbSlice} from '~/src/redux/slices/breadcrumbSlice';
import upload from '~/src/widgets/upload/uploadReducers';
import createSagaMiddleware from 'redux-saga';
import {reducer as profileReducer} from './slices/profileSlice';
import {reducer as incomesReducer} from './slices/incomeSlice';
import {reducer as accountReducer} from '~/src/redux/slices/accountSlice';
import {reducer as transactionReducer} from '~/src/redux/slices/transactionSlice';
import {reducer as tradingLimitsReducer} from '~/src/redux/slices/tradingLimitsSlice';
import {reducer as blogReducer} from '~/src/redux/slices/blogSlice';
import {invalidatorSaga} from '~/src/redux/saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        user,
        recipients,
        security,
        accountSettings,
        profile: profileReducer,
        incomes: incomesReducer,
        tradingLimits: tradingLimitsReducer,
        modules,
        upload,
        breadcrumbs: breadcrumbSlice.reducer,
        accounts: accountReducer,
        trade: transactionReducer,
        blog: blogReducer,
        ...coreReducers,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(invalidatorSaga);
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export const getState = store.getState;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
