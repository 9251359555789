import React, {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {AppBar, IconButton, Toolbar, styled, useMediaQuery} from '@mui/material';
import Stack from '@mui/material/Stack';
import DrawerSidebar from './DrawerSidebar';
import {RootState} from '~/src/redux/store';
import {WidthBreakpoint} from '~/src/config/localParams';
import logoSrc from '~/src/assets/svg/simplecoin-logo-orange-rgb.svg';
import Breadcrumbs from '../Breadcrumbs';
import LanguageChooser from '../LanguageChooser';
import UserProfile from '~/src/components/Auth/UserProfile';
import {getPathWithoutLanguage} from '@simplecoin/core';
import Logo from '../Logo/Logo';

const HeaderWrapper = styled('div')`
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 24px 64px 16px 32px;
`;

/**
 * Renders global header for authenticated users.
 *
 * @returns {*}
 * @constructor
 */
function PageHeader() {
    const smallScreen = useMediaQuery(`(max-width:${WidthBreakpoint.md})`);
    const {auth: user} = useSelector((state: RootState) => state.user, shallowEqual);
    const userLoggedIn = user?.response?.is_auth;
    const [drawerOpened, setDrawerOpened] = useState(false);
    const navigate = useNavigate();

    /**
     * Opens drawer.
     */
    function handleDrawerOpen() {
        setDrawerOpened(true);
    }

    /**
     * Closes drawer.
     */
    function handleDrawerClose() {
        setDrawerOpened(false);
    }

    /**
     * Always refresh
     */
    function handleLogoClick() {
        if (userLoggedIn) {
            const isOnDashboard = getPathWithoutLanguage(location.pathname, true) === '/dashboard';

            if (isOnDashboard) {
                window.location.reload();
            } else {
                navigate('/dashboard');
            }
        } else {
            navigate('/');
        }
    }

    const icon = drawerOpened ? (
        <IconButton size='large' aria-label='close' onClick={handleDrawerClose}>
            <CloseIcon />
        </IconButton>
    ) : (
        <IconButton size='large' aria-label='menu' onClick={handleDrawerOpen}>
            <MenuIcon />
        </IconButton>
    );

    const isAuthorisedUser = user?.status === 'ok';

    if (smallScreen) {
        return (
            <AppBar color='inherit' position='fixed' sx={{zIndex: (theme) => theme.zIndex.drawer + 1, padding: '10px'}}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Logo src={logoSrc} />
                    {isAuthorisedUser && icon}
                    {!isAuthorisedUser && <LanguageChooser isOutlined={false} />}
                </Toolbar>
                {isAuthorisedUser && (
                    <DrawerSidebar open={drawerOpened} handleClose={handleDrawerClose} handleOpen={handleDrawerOpen} />
                )}
            </AppBar>
        );
    }

    return (
        <HeaderWrapper>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Logo onClick={handleLogoClick} />

                <Breadcrumbs />

                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                    <LanguageChooser />
                    <UserProfile />
                </Stack>
            </Stack>
        </HeaderWrapper>
    );
}

PageHeader.displayName = 'AuthHeader';

export default PageHeader;
