import React from 'react';
import {
    Divider,
    Drawer,
    ListItemButton,
    Stack,
    ListItemIcon,
    ListItemText,
    styled,
    Typography,
    Link,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Menu from './Menu';
import settingsIcon from '~/src/assets/svg/settings-icon.svg';
import i18n from '~/src/config/i18n';
import helpIcon from '~/src/assets/svg/help-icon.svg';
import logoutIcon from '~/src/assets/svg/logout-icon.svg';
import profileIcon from '~/src/assets/svg/verification-icon.svg';
import {useSelector} from 'react-redux';
import {RootState} from '~/src/redux/store';
import {ensureLanguageInPath} from '@simplecoin/core';
import UserAvatar from './UserAvatar';
import LanguageChooser from '../LanguageChooser';

export interface DrawerSidebarAuthProps {
    open: boolean;
    handleClose: () => void;
    handleOpen: () => void;
}

const StyledMenuItem = styled(ListItemButton)`
    margin: 8px 0px;

    :hover {
        background: rgba(92, 62, 168, 0.1);
        border-radius: 12px;
    }
`;

/**
 * Renders sidebar inside drawer.
 *
 * @param open
 * @param handleClose
 * @param handleOpen
 * @return {*}
 * @constructor
 */
export default function DrawerSidebar({open, handleClose}: DrawerSidebarAuthProps) {
    const {t} = useTranslation();
    const {auth: user} = useSelector((state: RootState) => state.user);
    const userData = user?.response?.user;
    /**
     * Hide drawer on clickaway
     */
    function handleDrawerClose(): void {
        if (typeof handleClose === 'function') {
            handleClose();
        }
    }

    function getCommonItems() {
        if (user?.status === 'ok') {
            return (
                <Stack direction='column'>
                    <Link underline='none' href={ensureLanguageInPath({path: '/settings', language: i18n.language})}>
                        <StyledMenuItem>
                            <ListItemIcon>
                                <img src={settingsIcon} alt='settings' />
                            </ListItemIcon>
                            <ListItemText>{t('sidebar_navigation_settings')}</ListItemText>
                        </StyledMenuItem>
                    </Link>

                    <Link
                        underline='none'
                        href={ensureLanguageInPath({path: '/verification', language: i18n.language})}
                    >
                        <StyledMenuItem>
                            <ListItemIcon>
                                <img src={profileIcon} alt='profile' />
                            </ListItemIcon>
                            <ListItemText>{t('verification_and_limits')}</ListItemText>
                        </StyledMenuItem>
                    </Link>

                    <Link underline='none' href={ensureLanguageInPath({path: '/support', language: i18n.language})}>
                        <StyledMenuItem>
                            <ListItemIcon>
                                <img src={helpIcon} alt='help' />
                            </ListItemIcon>
                            <ListItemText>{t('sidebar_navigation_ticket')}</ListItemText>
                        </StyledMenuItem>
                    </Link>

                    <Link href='/logout' underline='none'>
                        <StyledMenuItem>
                            <ListItemIcon>
                                <img src={logoutIcon} alt='logout' />
                            </ListItemIcon>
                            <ListItemText>{t('navigation_link_logout')}</ListItemText>
                        </StyledMenuItem>
                    </Link>
                </Stack>
            );
        } else {
            return null;
        }
    }
    return (
        <Drawer
            onClose={handleDrawerClose}
            variant='temporary'
            anchor='left'
            open={open}
            PaperProps={{sx: {width: '85%', maxWidth: '340px'}}}
        >
            <Stack direction='column' divider={<Divider orientation='horizontal' flexItem />} paddingTop='80px'>
                <Stack
                    direction='row'
                    sx={{backgroundColor: 'rgba(65, 39, 126, 0.05)', p: 2, borderRadius: '16px', my: 1}}
                >
                    <UserAvatar src={userData?.avatar_url} />
                    <Typography sx={{paddingLeft: '12px'}}>
                        {userData?.first_name ? `${userData?.first_name} ${userData?.last_name}` : `${userData?.email}`}
                    </Typography>
                </Stack>
                <Stack direction='column'>
                    <Menu closeDrawer={handleDrawerClose} />
                </Stack>
                <Stack sx={{my: 2, width: 1}}>
                    <LanguageChooser />
                </Stack>
                {getCommonItems()}
            </Stack>
        </Drawer>
    );
}

DrawerSidebar.displayName = 'DrawerSidebar';
