import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {dayjs} from '@simplecoin/core';

interface Props {
    datetime: number;
    style?: Record<string, unknown> | null;
    rest?: any;
}

/**
 * Receives timestamp or timestamp with miliseconds and make it in "1 minute ago" format.
 *
 * Notice, when title prop is not specified and datetime is in UNIX timestamp, component would automatically convert
 * it into human-readable format and set as title.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function TimeAgo({datetime, style, ...rest}: Props) {
    const {i18n} = useTranslation();

    //  Add default style to style proper when not defined
    if (!style) {
        style = {color: '#888'};
    }

    const checkLength = `${datetime}`;

    if (checkLength.length === 10) {
        // making sure timestamp is always in miliseconds, not seconds
        datetime *= 1000;
    }

    const fromNow = useMemo(() => dayjs(datetime).from(dayjs()), [datetime, i18n.language]);
    const parsedDate = useMemo(() => dayjs(datetime).format('LLL'), [datetime, i18n.language]);

    return (
        <time dateTime={String(datetime)} style={style} title={parsedDate} {...rest}>
            {fromNow}
        </time>
    );
}

TimeAgo.displayName = 'TimeAgo';
