import {OptionsObject, SnackbarMessage, VariantType} from 'notistack';
import {enqueueSnackbar, closeSnackbar as coreCloseSnackbar} from '@simplecoin/core';
import {AnyAction} from 'redux';

export interface SnackbarInterface {
    logId?: string;
    message: SnackbarMessage;
    variant: VariantType;
    options?: OptionsObject;
    dismissed?: boolean;
    key?: number;
}
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type AppSnackbarInterface = Omit<SnackbarInterface, 'variant'>;

// the methods below are only here for code compatibility, should eventually be replced with enqueueSnackbar from core package

/**
 * Displays success alert.
 */
export function successSnackbar(props: AppSnackbarInterface): AnyAction {
    return enqueueSnackbar({
        ...props,
        options: {
            variant: 'success',
        },
    });
}

/**
 * Reexporting func
 */
export function closeSnackbar(props) {
    return coreCloseSnackbar(props);
}

/**
 * Dispatches error snackbar
 */
export function failureSnackbar(props: AppSnackbarInterface): AnyAction {
    return enqueueSnackbar({
        ...props,
        options: {
            ...props.options,
            persist: true,
            variant: 'error',
        },
    });
}

/**
 * Displays warning alert.
 */
export function warningSnackbar(props: AppSnackbarInterface): AnyAction {
    return enqueueSnackbar({
        ...props,
        options: {
            ...props.options,
            variant: 'warning',
        },
    });
}
