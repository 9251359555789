import React, {useRef} from 'react';
import {parseScannedContent} from '~/helpers/qr';
import {useTranslation} from 'react-i18next';
import QrScanner from '../QrScanner';
import Dialog, {DialogInterface, InvokerProps} from '../Dialog';
import {CropFree as CropFreeIcon} from '@mui/icons-material';
import {Stack, TextField, Typography, styled, Link, useTheme} from '@mui/material';
import {ElementRenderProps} from '~/typings/ExchangeFormTypings';
import useBackendSettings from '../../hooks/useBackendSettings';
import AddAddressFromTrezorButton from '../../plugins/trezor/AddAddressFromTrezorButton';
import {FormTextField} from '../formComponents/FormTextField';
import {Currencies} from '~/typings/currency';
import {useCurrency} from '~/hooks/currency';
import {getConfigValue} from '~/helpers/config';

const QrScannerChip = styled('div')`
    padding: 6px 10px;
    background: #f4f2ff;
    border-radius: 99px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
`;

export interface CryptoAddressFieldProps extends ElementRenderProps {
    cryptoAccount?: string;
    handleChange: (e: React.ChangeEvent<any>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
    arbitraryDataLabel?: string | null;
    arbitraryData?: string;
    currencyCode: string;
    error?: string;
}

/**
 * Renders crypto address field for guest exchange form
 */
export default function CryptoAccountFields({
    cryptoAccount = '',
    arbitraryData = '',
    handleChange,
    setFieldValue,
    currencyCode,
    error,
}: CryptoAddressFieldProps) {
    const currency = useCurrency({code: currencyCode});
    const currencyList = useBackendSettings<Currencies>('currencies', {});
    const {t} = useTranslation();
    const theme = useTheme();
    const qrScannerRef = useRef<DialogInterface>(null);
    const arbitraryDataLabel = currency?.arbitrary_data;
    const landingUrl = getConfigValue('landingUrl', '');
    const linkToGetWalletPost = `${landingUrl}/post/category/wallets`;

    const onSuccessfulScanCallback = (data) => {
        qrScannerRef?.current?.closeDialog();
        onSuccessfulScan(data, setFieldValue);
    };

    /**
     * Function invoked on successful scan event. It changes appropriate fields in form with received information.
     * After setting fields, snackbar with success message is shown
     * @param data
     * @param setFieldValue
     */
    function onSuccessfulScan(data, setFieldValue) {
        const parsedData = parseScannedContent(data, currencyList);

        if (parsedData) {
            if (parsedData.address) {
                setFieldValue('cryptoAccount', parsedData.address);
            }

            if (parsedData.options) {
                // eslint-disable-next-line
        for (let propertyName in parsedData.options) {
                    if (parsedData.options[propertyName] && propertyName !== 'label') {
                        setFieldValue('arbitraryData', parsedData.options[propertyName]);
                        break;
                    }
                }
            }
        }
    }

    const qrScannerInvoker = ({handleOpen}: InvokerProps) => {
        return (
            <QrScannerChip onClick={handleOpen}>
                <CropFreeIcon htmlColor={theme.cool.grey100} fontSize='small' />
                <Typography variant='subtitle2' color={theme.cool.grey100} fontWeight={400}>
                    {t('scan_address_qr_code')}
                </Typography>
            </QrScannerChip>
        );
    };

    return (
        <Stack direction='column' spacing={2} alignItems='flex-start' width='100%'>
            <FormTextField
                id='crypto-account'
                name='cryptoAccount'
                value={cryptoAccount}
                errorMessage={error}
                handleChange={handleChange}
                label={t('exchange_form_crypto_address_field_label')}
                helperText={t('validation_required_crypto_account')}
            />

            {!!arbitraryDataLabel && (
                <TextField
                    id='arbitrary-data'
                    label={arbitraryDataLabel}
                    name='arbitraryData'
                    value={arbitraryData}
                    onChange={handleChange}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                />
            )}

            <Stack
                direction={{xs: 'column', md: 'row'}}
                width='100%'
                justifyContent='space-between'
                alignItems='center'
            >
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={{xs: 1, sm: 4}}>
                    <Dialog
                        dialogContent={<QrScanner onSuccessfulScan={onSuccessfulScanCallback} />}
                        dialogTitle={t('scan_address')}
                        invoker={qrScannerInvoker}
                        ref={qrScannerRef}
                    />

                    <AddAddressFromTrezorButton
                        currencyCode={currencyCode}
                        setFieldValue={setFieldValue}
                        addressFieldName='cryptoAccount'
                    />
                </Stack>

                <Link
                    color='secondary'
                    href={linkToGetWalletPost}
                    paddingTop='16px'
                    sx={{paddingTop: {xs: '16px', md: 'initial'}}}
                >
                    {t('exchange_form_get_wallet_help')}
                </Link>
            </Stack>
        </Stack>
    );
}
