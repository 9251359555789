import {SvgIcon} from '@mui/material';
import React from 'react';

export default function TradeIcon() {
    return (
        <SvgIcon viewBox='0 0 24 24' sx={{fill: 'none', strokeWidth: 2, stroke: 'currentColor'}}>
            <path d='M 9.1907418,6.1795698 C 7.4038466,6.1775593 6.3225029,6.1763894 5.3401306,6.1677238 5.0646887,5.7309658 4.9052058,5.2130118 4.9052057,4.6576137 c -10e-8,-1.5597923 1.2578788,-2.8242538 2.8095516,-2.824254 0.4022689,-10e-8 0.7847918,0.084984 1.1307705,0.2380662 -1.1165442,1.4012528 -0.7990592,3.221367 0.345214,4.1081439 z' />
            <path d='m 5.2163128,20.546302 h -2.685541 c -0.2348708,0 -0.4239546,-0.18939 -0.4239546,-0.42464 V 6.6023814 c 0,-0.2352503 0.1890838,-0.4246394 0.4239546,-0.4246394 l 6.665252,0.00183' />
            <path d='m 13.758184,3.972085 c 0,0.1715529 -0.01521,0.3395332 -0.04437,0.5026517 -1.388539,-0.020213 -2.461756,0.9432706 -2.765187,2.3216022 -1.5516722,0 -2.8095517,-1.2644615 -2.8095517,-2.8242539 0,-1.5597926 1.2578795,-2.8242542 2.8095517,-2.824254 1.551674,-2e-7 2.809553,1.2644614 2.809553,2.824254 z' />
            <path d='m 16.51547,7.2959829 c 0,0.4298478 -0.09553,0.8372672 -0.266409,1.2019749 -1.786243,-0.00865 -2.779056,-0.014347 -5.09545,-0.019762 -0.165128,-0.3596397 -0.257244,-0.7601132 -0.257244,-1.1822139 0,-1.5597925 1.25788,-2.824254 2.809553,-2.8242538 1.551672,-2e-7 2.80955,1.2644613 2.80955,2.8242548 z' />
            <path d='m 19.510075,18.565354 v 3.862176 c 0,0.23525 -0.189083,0.424639 -0.423954,0.424639 H 5.6402671 c -0.2348706,0 -0.4239543,-0.189389 -0.4239543,-0.424639 V 8.9082495 c 0,-0.2352495 0.1890837,-0.4246389 0.4239543,-0.4246389 H 19.086121 c 0.234871,0 0.423954,0.1893894 0.423954,0.4246389 v 3.8621825' />
            <path d='m 14.243065,12.770432 h 7.152373 c 0.275751,0 0.497745,0.222353 0.497745,0.498547 v 4.797828 c 0,0.276194 -0.221994,0.498547 -0.497745,0.498547 h -7.152373 c -0.275751,0 -0.497745,-0.222353 -0.497745,-0.498547 v -4.797828 c 0,-0.276194 0.221994,-0.498547 0.497745,-0.498547 z' />
            <path d='m 16.678561,15.667893 a 0.35119388,0.35303166 0 0 1 -0.351194,0.353033 0.35119388,0.35303166 0 0 1 -0.351193,-0.353033 0.35119388,0.35303166 0 0 1 0.351193,-0.353031 0.35119388,0.35303166 0 0 1 0.351194,0.353031 z' />
        </SvgIcon>
    );
}
