import React from 'react';
import {styled} from '@mui/material/styles';

const PageWrapper = styled('div')`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IconWrapper = styled('div')`
    margin-bottom: 50px;
`;

const ErrorTitle = styled('div')`
    font-size: 24px;
    max-width: 40%;
    line-height: 1.5;
    text-align: center;
`;

export interface HouseErrorProps {
    title?: string;
    children?: React.ReactNode;
}

/**
 * Renders error view with house icon.
 *
 * @param title
 * @param children
 * @param rest
 * @constructor
 */
export default function HouseError({title, children, ...rest}: HouseErrorProps) {
    return (
        <PageWrapper {...rest}>
            <IconWrapper>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    x='0'
                    y='0'
                    enableBackground='new 0 0 595.3 419.5'
                    version='1.1'
                    viewBox='195 50 220 320'
                    width={220}
                    height={320}
                    xmlSpace='preserve'
                >
                    <path fill='#F58A1F' d='M289.2 339.3H303.59999999999997V355H289.2z' />
                    <path
                        fill='#F58A1F'
                        d='M388.7 107.8c-1.3-22.3-21-40.1-45.1-40.1-7.7 0-15.2 1.8-21.8 5.3-10-13.3-26-21.1-43.3-21.1-29.3 0-53.2 22.5-53.2 50.1 0 1.5.1 3 .3 4.6-16 5.1-26.9 19.3-26.9 35.4 0 20.6 17.8 37.4 40.1 37.4H293l-14.4 37.8h22.6l-17.3 49.6 66.5-75.4h-25.8l4.8-12h43.2c21.9 0 39.8-16.8 39.8-37.5-.2-14.7-9.5-28.1-23.7-34.1zm-16.6 64.6H332l6-15.1h-36.7l-5.8 15.1h-57.3c-18.1 0-32.8-13.7-32.8-30.5 0-13.9 10.1-26 24.6-29.5 1.8-.4 2.9-2.2 2.6-4-.4-2.5-.6-4.6-.6-6.6 0-23.8 20.7-43.1 46.2-43.1 16.2 0 30.9 7.7 39.4 20.6 1 1.6 3.1 2 4.7 1.1 6.3-3.9 13.5-5.9 21-5.9 21 0 38.1 15.9 38.2 35.4v.2c0 1.5.9 2.8 2.3 3.3 12.6 4.5 21.1 15.9 21.1 28.5.3 16.8-14.4 30.5-32.8 30.5zM339.7 332.9c.9-1.2.7-3-.6-3.9L298 298.3c-1-.7-2.4-.7-3.3 0l-16 11.9v-13h-9V317l-16.1 12c-1.2.9-1.5 2.7-.6 3.9.9 1.2 2.7 1.5 3.9.6l39.4-29.5 39.4 29.5c.5.4 1.1.6 1.7.6.9 0 1.7-.4 2.3-1.2z'
                    />
                    <path
                        fill='#F58A1F'
                        d='M296.4 311.5l-29.6 22.2v35.1h59V366c.2-32.1.2-32.1-.6-32.9l-28.8-21.6zm23.8 51.7h-47.9v-26.7l24.1-18 24 18c0 4.7-.1 20.3-.2 26.7z'
                    />
                    <path
                        fill='#F58A1F'
                        d='M377.9 338.1L358.7 328l11.3-7.1c1-.6 1.4-1.7 1.3-2.8s-1-2-2.1-2.2l-23-5.3 9.3-13.4c.6-.9.7-2.2 0-3.1-.6-.9-1.8-1.4-2.9-1.2l-21.9 4.3 5.8-18.2c.3-1 0-2.2-.8-2.9-.8-.7-2-.9-3-.5l-23 10.3-4.9-20.1c-.2-1-1.1-1.8-2.1-2.1-1-.2-2.1.1-2.8.9l-16.7 20.1-16.9-12.2c-.8-.6-1.9-.7-2.8-.3-.9.4-1.5 1.2-1.7 2.2l-2.8 23.3-6.6-6.1c-.9-.8-2.1-1-3.2-.5-1 .5-1.6 1.6-1.5 2.8l1.9 16.3-17.4-8.4c-1-.5-2.3-.3-3.1.5-.8.8-1.1 2-.7 3.1l7.8 20.1-21.4.4c-1.3 0-2.3.9-2.7 2.1-.3 1.2.2 2.5 1.3 3.1l21.4 12.4-13.9 2.3c-1.2.2-2.1 1.1-2.3 2.3-.2 1.2.4 2.4 1.4 2.9l21 11.3-10 1.9c-1.5.3-2.5 1.8-2.2 3.3.3 1.5 1.8 2.5 3.3 2.2l17.7-3.4c1.2-.2 2.1-1.2 2.2-2.3s-.4-2.3-1.4-2.9L230.1 350l13.5-2.2c1.2-.2 2.1-1.1 2.3-2.2.2-1.2-.3-2.3-1.3-2.9l-19.5-11.3 15.4-.3c.9 0 1.8-.5 2.3-1.2.5-.8.6-1.7.3-2.6l-6.7-17.2 15.5 7.5c.9.4 2 .4 2.8-.2.8-.6 1.3-1.6 1.2-2.6l-1.6-13.8 5 4.7c.8.7 1.9.9 2.9.6 1-.4 1.7-1.2 1.8-2.3l2.9-24 15.2 11c1.2.9 2.8.6 3.8-.5l14.8-17.8 4.4 17.9c.2.8.7 1.5 1.5 1.8.7.4 1.6.4 2.4 0l20.4-9.1-5.3 16.8c-.3.9-.1 2 .6 2.7.7.7 1.7 1.1 2.6.9l19.7-3.9-7.8 11.1c-.5.8-.6 1.7-.3 2.6.3.9 1.1 1.5 2 1.7l20.5 4.7-9.7 6.1c-.8.5-1.3 1.5-1.3 2.5s.6 1.9 1.5 2.4l15.8 8.3-23 3.2c-1.2.2-2.2 1.1-2.4 2.4-.2 1.2.5 2.4 1.6 2.9l14.2 6.2-19.7 1.7c-1.3.1-2.3 1.1-2.5 2.3-.2 1.3.5 2.5 1.6 3l19.8 8.8c.4.2.8.2 1.1.2 1.1 0 2.1-.6 2.5-1.7.6-1.4 0-3.1-1.4-3.7l-9.9-4.4 19.9-1.7c1.3-.1 2.3-1.1 2.5-2.3.2-1.3-.5-2.5-1.6-3l-15.3-6.6 21.7-3c1.2-.2 2.2-1.1 2.4-2.3.3-1.3-.3-2.5-1.3-3.1z'
                    />
                </svg>
            </IconWrapper>
            {title && <ErrorTitle>{title}</ErrorTitle>}
            {children}
        </PageWrapper>
    );
}

HouseError.displayName = 'HouseError';
