import {Stack, Typography, useTheme} from '@mui/material';
import {FormattedAmount} from '@simplecoin/core';
import React from 'react';
import {asNum} from '../helpers/financialOperationHelpers';

interface StyledAmountProps {
    amount: number | string | undefined;
    currencyCode: string | undefined;
    size?: 'small' | 'medium' | 'large';
    style?: 'standard' | 'accounting';
}

export default function StyledAmount({amount, currencyCode, size = 'medium', style = 'standard'}: StyledAmountProps) {
    const theme = useTheme();
    const usedAmount = asNum(amount);
    const sign = usedAmount < 0 ? '-' : '+';
    const displayedAmount = style === 'accounting' ? Math.abs(usedAmount) : usedAmount;
    const textBefore = style === 'accounting' ? sign : '';
    const color = style === 'accounting' && usedAmount < 0 ? '#EE3535' : theme.palette.text.primary;

    const [amountFontSize, currencyFontSize] = (() => {
        switch (size) {
            case 'small':
                return ['15px', '15px'];
            case 'medium':
                return ['20px', '18px'];
            case 'large':
                return ['24px', '18px'];
            default:
                return ['20px', '18px'];
        }
    })();

    if (amount == null || currencyCode == null) {
        return null;
    }

    return (
        <Stack direction='row' spacing={1} alignItems='baseline'>
            <Typography sx={{fontSize: {xs: amountFontSize}, fontWeight: 700, color: color}}>
                <FormattedAmount currencyCode={currencyCode} value={displayedAmount} textBefore={textBefore} />
            </Typography>
            <Typography sx={{fontSize: {xs: currencyFontSize}, fontWeight: 400, color: '#6B6F7E'}}>
                {currencyCode}
            </Typography>
        </Stack>
    );
}
