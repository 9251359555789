import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {actions} from '../redux/snackbarSlice';
import {RootState} from '../redux/store';
import {IconButton, Stack, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const {removeSnackbar} = actions;

let displayed: number[] = [];

/**
 * Renders snack content with log.
 *
 */
function snackWithLog(message, logId) {
    const logIdMessage = '\n(Request ID: ' + logId + ')';

    return (
        <Stack direction='column'>
            <Typography variant='body2' sx={{color: '#FFFFFF'}}>
                {message}
            </Typography>
            <small>{logIdMessage}</small>
        </Stack>
    );
}

const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((store: RootState) => store.snackbar.notifications || []);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const storeDisplayed = (id: number) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    /**
     * Returns close button component with onClick set to close this particular snackabr
     * @param key
     */
    function getAction(key) {
        return (
            <IconButton onClick={() => closeSnackbar(key)} size='large'>
                <CloseIcon />
            </IconButton>
        );
    }

    useEffect(() => {
        notifications.forEach(({key, message, logId, options = {variant: 'warning'}, dismissed = false}) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (!key || displayed.includes(key)) return;

            let renderedMessage = message;

            if (logId) {
                renderedMessage = snackWithLog(message, logId);
            }

            // display snackbar using notistack
            enqueueSnackbar(renderedMessage, {
                key,
                action: getAction(key),
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
