import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {successSnackbar} from '~/src/snackbars/SnackbarActions';
import {RootState, useAppDispatch} from '~/src/redux/store';
import {REDIRECT_PARAM_NAME} from '~/src/config/localParams';
import {manageResponse, SiteActions} from '@simplecoin/core';
import useSearchParameters from '~/src/hooks/useSearchParameters';
import resendActivationEmail = SiteActions.resendActivationEmail;

/**
 * Component is responsible for dispatching action to resend activation email, show snackbar on success and redirect
 * back to login.
 */
function ResendEmail() {
    const {resendActivationEmail: resendEmail} = useSelector((state: RootState) => state.site);
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const searchParameters = useSearchParameters();

    // On component mount
    useEffect(() => {
        const {email} = params;
        if (email) {
            dispatch(resendActivationEmail(email));
        } else {
            navigate('/login');
            // eslint-disable-next-line no-console
            console.error('Failed to resend email: no email provided');
        }
    }, []);

    // On resendEmail reducer change
    useEffect(() => {
        manageResponse({
            reducer: resendEmail,
            onSuccess: () => {
                dispatch(successSnackbar({message: t('alert_success_email_link_was_resent_again')}));
                navigate('/login');
            },
            onAny: () => {
                if (searchParameters[REDIRECT_PARAM_NAME]) {
                    // redirect back
                    navigate(searchParameters[REDIRECT_PARAM_NAME]);
                }
            },
        });
    }, [resendEmail]);

    return null;
}

export default ResendEmail;
