import React from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {WidthBreakpoint} from '~/src/config/localParams';
import {Stack} from '@mui/material';

type Position = 'fixed' | 'relative';

interface WrapperProps {
    position: Position;
}

const Wrapper = styled('div')`
    position: ${(props: WrapperProps) => props.position};
    z-index: 1;
    padding: 48px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${WidthBreakpoint.md}) {
        padding: 32px 0;
    }
`;

export interface PageHeaderAuthProps {
    title?: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    action?: React.ReactNode;
}

/**
 * Renders page header with specified title and children when specified.
 *
 * Usage:
 *
 * ```js
 * <PageHeader title="Some title">
 *     {Some other content to renders on the right side of the title}
 * </PageHeader>
 * ```
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function AppHeader({title, subTitle, action}: PageHeaderAuthProps) {
    return (
        <Wrapper data-testid='page-header-wrapper'>
            <Stack
                direction={{xs: 'column', md: 'row'}}
                justifyContent={{xs: 'flex-start', md: 'space-between'}}
                spacing={2}
                flexGrow={1}
            >
                <Stack direction='column'>
                    {React.isValidElement(title) ? title : <Typography variant='h1'>{title}</Typography>}
                    {subTitle && typeof subTitle === 'string' && (
                        <Typography variant='caption' sx={{marginTop: '1em'}}>
                            {subTitle}
                        </Typography>
                    )}
                    {subTitle && typeof subTitle !== 'string' && subTitle}
                </Stack>
                {action}
            </Stack>
        </Wrapper>
    );
}

AppHeader.displayName = 'AppHeader';
