import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import {manageResponse, Form, yup} from '@simplecoin/core';
import {useNavigate} from 'react-router-dom';
import {failureSnackbar, successSnackbar} from '~/src/snackbars/SnackbarActions';
import {requestPasswordReset} from './SiteActions';
import {HeadManager, Space} from '../../components';
import {RootState} from '~/src/redux/store';
import FormLayout from '~/src/components/Guest/FormLayout';
import useReCaptcha from '~/src/hooks/useReCaptcha';

/**
 * Renders page where user can enter email to reset password.
 */
export default function RequestPasswordReset() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const showCaptcha = useReCaptcha('passwordReset');

    const dispatch = useDispatch();

    const {requestPasswordReset: requestAction} = useSelector((state: RootState) => state.user);

    useEffect(() => {
        manageResponse({
            reducer: requestAction,
            onSuccess: (response) => {
                if (response.isRequested) {
                    dispatch(successSnackbar({message: t('action_reset_password_success')}));
                    navigate('/login');
                } else {
                    dispatch(failureSnackbar({message: t('action_reset_password_error_email_not_sent')}));
                }
            },
        });
    }, [requestAction]);

    /**
     * Posts form data
     * @param values form values
     * @param formik
     */
    function onSubmit(values) {
        dispatch(requestPasswordReset(values));
    }

    const rules = yup.object().shape({
        email: yup.string().email().required(),
        reCaptcha: showCaptcha
            ? yup.string().nullable().required() // eslint-disable-line
            : yup.string(),
    });
    const fields = [
        {
            type: 'email',
            name: 'email',
            autoComplete: 'username',
            label: t('enter_form_email_placeholder'),
            value: '',
        },
        {
            type: 'captcha',
            name: 'reCaptcha',
            visible: showCaptcha,
        },
    ];

    const formProps = {name: 'request-password-reset-form'};

    return (
        <FormLayout>
            <HeadManager title={t('request_password_reset')} />

            <Typography variant='body2'>{t('enter_email_address_to_recover_account')}</Typography>
            <Space size={30} />
            <Form
                formProps={formProps}
                reducer={requestAction}
                rules={rules}
                fields={fields}
                onSubmit={onSubmit}
                submitText={t('send')}
            />
        </FormLayout>
    );
}

RequestPasswordReset.displayName = 'RequestPasswordReset';
