import * as yup from 'yup';
import {getConfigValue} from '~/helpers/config';

/**
 * Prepares and sets Yup locale.
 *
 * @param t
 * @param {string} locale code
 */
export function changeYupLocale(t, locale) {
    if (getConfigValue('environment') === 'development') {
        // eslint-disable-next-line no-console
        console.log('Setting yup locale to ', locale);
    }
    yup.setLocale({
        mixed: {
            required: t('validation_field_required'),
            oneOf: t('validation_error_not_matching_passwords'),
        },
        string: {
            min: t('validation_min_characters'),
            max: t('validation_max_characters'),
            email: t('validation_error_incorrect_email_format'),
            length: t('validation_error_required_characters_exactly'),
        },
    });
}

export default yup;
