import React, {useEffect, useState} from 'react';
import {CircularProgress, CircularProgressProps, Backdrop} from '@mui/material';
import {ReducerEnvelope} from '../typings/Api';

interface Props {
    reducer?: ReducerEnvelope<any>;
    reducerList?: Array<ReducerEnvelope<any>>;
    circularProgressProps?: CircularProgressProps;
}

/**
 * Renders a backdrop (loader with transparent layer over whole screen) triggered by reducer fetching state
 */
export default function FetchingBackdrop({reducer, reducerList, circularProgressProps, ...props}: Props) {
    const [backdropOpen, setBackdropOpen] = useState(false);

    useEffect(() => {
        if (reducer && reducer.isFetching) {
            setBackdropOpen(true);
        } else if (reducerList && reducerList.some((reducer) => reducer?.isFetching)) {
            setBackdropOpen(true);
        } else {
            setBackdropOpen(false);
        }
    }, [reducer, reducerList]);

    const dataTestId = backdropOpen ? 'backdrop-shown' : 'backdrop-hidden';

    return (
        <Backdrop
            open={backdropOpen}
            sx={{color: 'secondary', zIndex: (theme) => theme.zIndex.drawer + 1}}
            data-testid={dataTestId}
            {...props}
        >
            <CircularProgress {...circularProgressProps} />
        </Backdrop>
    );
}

FetchingBackdrop.displayName = 'FetchingBackdrop';
