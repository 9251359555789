import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useTranslation} from 'react-i18next';
import {getConfigValue} from '../helpers/config';

/**
 * Renders base ReCaptcha component with current language
 * See https://www.google.com/recaptcha/admin/create
 */
const ReCaptcha = React.forwardRef(({onChange}: {onChange: (value: string) => void}, ref) => {
    const {i18n} = useTranslation();
    const recaptchaSiteKey: string = getConfigValue('recaptchaSiteKey', '');
    return <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onChange} hl={i18n.language} />;
});

ReCaptcha.displayName = 'ReCaptcha';

export default ReCaptcha;
