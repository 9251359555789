import {PaginatedTicketsProps, Pagination} from '~/src/typings/Pagination';
import {Ticket} from '~/src/typings/models/Ticket';
import {fetchGeneric} from '~/src/helpers/action';

export const REQUEST_TICKET_LIST = 'REQUEST_TICKET_LIST';
export const REQUEST_TICKET_LIST_SUCCESS = 'REQUEST_TICKET_LIST_SUCCESS';
export const REQUEST_TICKET_LIST_FAILURE = 'REQUEST_TICKET_LIST_FAILURE';

export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';
export const CREATE_TICKET_INVALIDATE = 'CREATE_TICKET_INVALIDATE';

export const REQUEST_DISCUSSION_TICKET = 'REQUEST_DISCUSSION_TICKET';
export const REQUEST_DISCUSSION_TICKET_UPDATE = 'REQUEST_DISCUSSION_TICKET_UPDATE';
export const REQUEST_DISCUSSION_TICKET_SUCCESS = 'REQUEST_DISCUSSION_TICKET_SUCCESS';
export const REQUEST_DISCUSSION_TICKET_FAILURE = 'REQUEST_DISCUSSION_TICKET_FAILURE';

export const REQUEST_DISCUSSION_TICKET_LOAD_MORE = 'REQUEST_DISCUSSION_TICKET_LOAD_MORE';
export const REQUEST_DISCUSSION_TICKET_LOAD_MORE_SUCCESS = 'REQUEST_DISCUSSION_TICKET_LOAD_MORE_SUCCESS';
export const REQUEST_DISCUSSION_TICKET_LOAD_MORE_FAILURE = 'REQUEST_DISCUSSION_TICKET_LOAD_MORE_FAILURE';

export const CLOSE_TICKET = 'CLOSE_TICKET';
export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS';
export const CLOSE_TICKET_FAILURE = 'CLOSE_TICKET_FAILURE';
export const CLOSE_TICKET_INVALIDATE = 'CLOSE_TICKET_INVALIDATE';

export const REQUEST_TICKET_INFO = 'REQUEST_TICKET_INFO';
export const REQUEST_TICKET_INFO_UPDATE = 'REQUEST_TICKET_INFO_UPDATE';
export const REQUEST_TICKET_INFO_SUCCESS = 'REQUEST_TICKET_INFO_SUCCESS';
export const REQUEST_TICKET_INFO_FAILURE = 'REQUEST_TICKET_INFO_FAILURE';

export const CREATE_TICKET_REPLY = 'CREATE_TICKET_REPLY';
export const CREATE_TICKET_REPLY_SUCCESS = 'CREATE_TICKET_REPLY_SUCCESS';
export const CREATE_TICKET_REPLY_FAILURE = 'CREATE_TICKET_REPLY_FAILURE';
export const CREATE_TICKET_REPLY_INVALIDATE = 'CREATE_TICKET_REPLY_INVALIDATE';

export const CREATE_TICKET_LAST_REPLY_SUCCESS = 'CREATE_TICKET_LAST_REPLY_SUCCESS';
export const CREATE_TICKET_LAST_REPLY_FAILURE = 'CREATE_TICKET_LAST_REPLY_FAILURE';

export const REQUEST_TICKET_ORDER = 'REQUEST_TICKET_ORDER';
export const REQUEST_TICKET_ORDER_SUCCESS = 'REQUEST_TICKET_ORDER_SUCCESS';
export const REQUEST_TICKET_ORDER_FAILURE = 'REQUEST_TICKET_ORDER_FAILURE';
export const REQUEST_TICKET_ORDER_INVALIDATE = 'REQUEST_TICKET_ORDER_INVALIDATE';

/**
 * Fetch tickets.
 *
 * @param {number | null | undefined} page
 * @param {number | undefined} perPage
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchTickets({
    pagination: {page = null, perPage = 12},
    query: filterStatus = 'all',
}: PaginatedTicketsProps) {
    const usedFilterStatuses: Array<string> = [];
    if (filterStatus) {
        if (filterStatus !== 'all') {
            if (filterStatus === 'in-progress') {
                // either
                usedFilterStatuses.push('waiting_for_client');
                // or
                usedFilterStatuses.push('waiting_for_admin');
            } else {
                usedFilterStatuses.push(filterStatus);
            }
        }
    }
    let query;
    if (usedFilterStatuses.length > 0) {
        query = {
            page,
            perPage,
            filterStatuses: usedFilterStatuses,
        };
    } else {
        query = {
            page,
            perPage,
        };
    }

    return fetchGeneric({
        url: 'ticket/list',
        query,
        actions: {
            pre: REQUEST_TICKET_LIST,
            success: REQUEST_TICKET_LIST_SUCCESS,
            failure: REQUEST_TICKET_LIST_FAILURE,
        },
    });
}

/**
 * Create new ticket.
 *
 * @param {Ticket} data
 * @param {number} orderId
 * @return {(dispatch: any) => AxiosPromise}
 */
export function createTicket(data: Ticket, orderId: number) {
    return fetchGeneric({
        method: 'post',
        url: 'ticket/create',
        query: {
            order_id: orderId,
        },
        data,
        actions: {
            pre: CREATE_TICKET,
            success: CREATE_TICKET_SUCCESS,
            failure: CREATE_TICKET_FAILURE,
            invalidate: CREATE_TICKET_INVALIDATE,
        },
    });
}

/**
 * Ticket discussion.
 *
 * @param {number} id
 * @param {number | null | undefined} page
 * @param {number | undefined} perPage
 * @param {number | undefined} offset
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchDiscussion(id: number, {page = null, perPage = 10, offset = 0}: Pagination, updateOnly = false) {
    return fetchGeneric({
        url: 'ticket/discussion',
        query: {
            id,
            page,
            perPage,
            offset,
        },
        actions: {
            pre: updateOnly ? REQUEST_DISCUSSION_TICKET_UPDATE : REQUEST_DISCUSSION_TICKET,
            success: REQUEST_DISCUSSION_TICKET_SUCCESS,
            failure: REQUEST_DISCUSSION_TICKET_FAILURE,
        },
    });
}

/**
 * Load more button for ticket discussion.
 *
 * @param {number} id
 * @param {number | null | undefined} page
 * @param {number | undefined} perPage
 * @param {number | undefined} offset
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchDiscussionLoadMore(id: number, {page = null, perPage = 10, offset = 0}: Pagination) {
    return fetchGeneric({
        url: 'ticket/discussion',
        query: {
            id,
            page,
            perPage,
            offset,
        },
        actions: {
            pre: REQUEST_DISCUSSION_TICKET_LOAD_MORE,
            success: REQUEST_DISCUSSION_TICKET_LOAD_MORE_SUCCESS,
            failure: REQUEST_DISCUSSION_TICKET_LOAD_MORE_FAILURE,
        },
    });
}

/**
 * Close ticket.
 *
 * @param {number} id
 * @return {(dispatch: any) => AxiosPromise}
 */
export function closeTicket(id: number) {
    return fetchGeneric({
        url: 'ticket/close',
        method: 'POST',
        query: {id},
        actions: {
            pre: CLOSE_TICKET,
            success: CLOSE_TICKET_SUCCESS,
            failure: CLOSE_TICKET_FAILURE,
        },
        afterSuccess: CLOSE_TICKET_INVALIDATE,
    });
}

export interface GetTicketInfoProps {
    ticketId: number;
    expand?: string[];
    updateOnly?: boolean;
}

/**
 *  Get ticket info.
 *
 * @param {number} ticketId
 * @param {"support" | "order" | undefined} expand
 * @return {(dispatch: any) => AxiosPromise}
 */
export function getTicketInfo({ticketId, expand, updateOnly = false}: GetTicketInfoProps) {
    const query = {
        id: ticketId,
        expand: expand?.join(','),
    };
    return fetchGeneric({
        url: 'ticket/view',
        query,
        actions: {
            pre: updateOnly ? REQUEST_TICKET_INFO_UPDATE : REQUEST_TICKET_INFO,
            success: (data) => (dispatch) => {
                dispatch({type: REQUEST_TICKET_INFO_SUCCESS, payload: data});

                if (data.response.orderId) {
                    dispatch(fetchOrder(data.response.orderId));
                }
            },
            failure: REQUEST_TICKET_INFO_FAILURE,
        },
    });
}

/**
 * Create ticket reply.
 *
 * @param {number} id
 * @param {string} text
 */
export function createTicketReply(id: number, text: string) {
    return fetchGeneric({
        url: 'ticket/reply',
        method: 'POST',
        query: {id},
        data: {text},
        actions: {
            pre: CREATE_TICKET_REPLY,
            success: CREATE_TICKET_REPLY_SUCCESS,
            failure: CREATE_TICKET_REPLY_FAILURE,
            invalidate: CREATE_TICKET_REPLY_INVALIDATE,
        },
        afterSuccess: fetchLastReplies(id),
    });
}

/**
 * Fetch last ticket replies.
 *
 * @param {number} id
 * @param {number | null | undefined} page
 * @param {number | undefined} perPage
 * @param {number | undefined} offset
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchLastReplies(id: number, {page = 1, perPage = 10, offset = 0}: Pagination = {}) {
    return fetchGeneric({
        url: 'ticket/discussion',
        query: {
            id,
            page,
            perPage,
            offset,
        },
        actions: {
            success: CREATE_TICKET_LAST_REPLY_SUCCESS,
            failure: CREATE_TICKET_LAST_REPLY_FAILURE,
        },
    });
}

/**
 * Fetch single order information.
 *
 * @param {number} id Order id to fetch.
 * @return {Function}
 */
export function fetchOrder(id: number) {
    return fetchGeneric({
        method: 'get',
        url: 'order/view',
        query: {
            id,
            expand: [
                'fromCurrency',
                'toCurrency',
                'bankAccount',
                'incomingCryptoTransaction',
                'outgoingCryptoTransaction',
                'incomingBankTransaction',
                'outgoingBankTransaction',
                'cryptoAccount',
                'statusLogs',
                'incomingBanks',
            ].join(','),
        },
        actions: {
            pre: REQUEST_TICKET_ORDER,
            success: REQUEST_TICKET_ORDER_SUCCESS,
            failure: REQUEST_TICKET_ORDER_FAILURE,
        },
    });
}

/**
 * Invalidates ticket.
 *
 * @return {{type: *}}
 */
export function invalidateOrder() {
    return {type: REQUEST_TICKET_ORDER_INVALIDATE};
}
