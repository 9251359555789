import {
    REQUEST_AFFILIATE_OVERVIEW,
    REQUEST_AFFILIATE_OVERVIEW_FAILURE,
    REQUEST_AFFILIATE_OVERVIEW_SUCCESS,
    REQUEST_AFFILIATE_STATISTICS,
    REQUEST_AFFILIATE_STATISTICS_FAILURE,
    REQUEST_AFFILIATE_STATISTICS_SUCCESS,
    REQUEST_AFFILIATE_BALANCE,
    REQUEST_AFFILIATE_BALANCE_FAILURE,
    REQUEST_AFFILIATE_BALANCE_SUCCESS,
    REQUEST_JOIN_AFFILIATE,
    REQUEST_JOIN_AFFILIATE_FAILURE,
    REQUEST_JOIN_AFFILIATE_INVALIDATE,
    REQUEST_JOIN_AFFILIATE_SUCCESS,
    REQUEST_AFFILIATE_WITHDRAW,
    REQUEST_AFFILIATE_WITHDRAW_SUCCESS,
    REQUEST_AFFILIATE_WITHDRAW_FAILURE,
    REQUEST_AFFILIATE_WITHDRAW_INVALIDATE,
    REQUEST_AFFILIATE_WITHDRAW_HISTORY_FAILURE,
    REQUEST_AFFILIATE_WITHDRAW_HISTORY_SUCCESS,
    REQUEST_AFFILIATE_WITHDRAW_HISTORY,
    REQUEST_AFFILIATE_INCOME_SUCCESS,
    REQUEST_AFFILIATE_INCOME_FAILURE,
    REQUEST_AFFILIATE_INCOME,
    REQUEST_RECORD_AFFILIATE,
    REQUEST_RECORD_AFFILIATE_SUCCESS,
    REQUEST_RECORD_AFFILIATE_FAILURE,
} from './AffiliateActions';
import {Action} from '~/src/typings';
import {AffiliateOverview} from '~/src/typings/endpoints/affiliate/AffiliateOverview';
import {AffiliateStatistics} from '~/src/typings/endpoints/affiliate/AffiliateStatistics';
import {AffiliateIncome, AffiliateWithdrawals} from '~/src/typings/endpoints/affiliate/AffiliateWithdrawals';
import {ReducerEnvelope} from '@simplecoin/core';

export interface AffiliateReducersInterface {
    overview?: ReducerEnvelope<AffiliateOverview>;
    join?: ReducerEnvelope<null>;
    statistics?: ReducerEnvelope<AffiliateStatistics>;
    balance?: ReducerEnvelope<{balance: string}>;
    withdraw?: ReducerEnvelope<{amount: number; id: number}>;
    withdrawHistory?: ReducerEnvelope<AffiliateWithdrawals>;
    recordAffiliate?: ReducerEnvelope<null>;
    incomeHistory?: ReducerEnvelope<AffiliateIncome>;
}

/**
 * @param state
 * @param action
 * @return {object}
 */
export default function (state: AffiliateReducersInterface = {}, action: Action = {}): AffiliateReducersInterface {
    switch (action.type) {
        // affiliate overview
        case REQUEST_AFFILIATE_OVERVIEW:
            return {...state, overview: {isFetching: true}};
        case REQUEST_AFFILIATE_OVERVIEW_SUCCESS:
            return {...state, overview: {isFetching: false, ...action.payload}};
        case REQUEST_AFFILIATE_OVERVIEW_FAILURE:
            return {...state, overview: {isFetching: false, ...action.payload}};

        // join affiliate
        case REQUEST_JOIN_AFFILIATE:
            return {...state, join: {isFetching: true}};
        case REQUEST_JOIN_AFFILIATE_SUCCESS:
            return {...state, join: {isFetching: false, ...action.payload}};
        case REQUEST_JOIN_AFFILIATE_FAILURE:
            return {...state, join: {isFetching: false, ...action.payload}};
        case REQUEST_JOIN_AFFILIATE_INVALIDATE:
            return {...state, join: undefined};

        // get click statistics
        case REQUEST_AFFILIATE_STATISTICS:
            return {...state, statistics: {isFetching: true}};
        case REQUEST_AFFILIATE_STATISTICS_SUCCESS:
            return {...state, statistics: {isFetching: false, ...action.payload}};
        case REQUEST_AFFILIATE_STATISTICS_FAILURE:
            return {...state, statistics: {isFetching: false, ...action.payload}};

        // get affiliate balance
        case REQUEST_AFFILIATE_BALANCE:
            return {...state, balance: {isFetching: true}};
        case REQUEST_AFFILIATE_BALANCE_SUCCESS:
            return {...state, balance: {isFetching: false, ...action.payload}};
        case REQUEST_AFFILIATE_BALANCE_FAILURE:
            return {...state, balance: {isFetching: false, ...action.payload}};

        // withdraw affiliate balance
        case REQUEST_AFFILIATE_WITHDRAW:
            return {...state, withdraw: {isFetching: true}};
        case REQUEST_AFFILIATE_WITHDRAW_SUCCESS:
            return {...state, withdraw: {isFetching: false, ...action.payload}};
        case REQUEST_AFFILIATE_WITHDRAW_FAILURE:
            return {...state, withdraw: {isFetching: false, ...action.payload}};
        case REQUEST_AFFILIATE_WITHDRAW_INVALIDATE:
            return {...state, withdraw: undefined};

        // fetch withdraw history
        case REQUEST_AFFILIATE_WITHDRAW_HISTORY:
            return {...state, withdrawHistory: {isFetching: true}};
        case REQUEST_AFFILIATE_WITHDRAW_HISTORY_SUCCESS:
            return {...state, withdrawHistory: {isFetching: false, ...action.payload}};
        case REQUEST_AFFILIATE_WITHDRAW_HISTORY_FAILURE:
            return {...state, withdrawHistory: {isFetching: false, ...action.payload}};

        // fetch affiliate income history
        case REQUEST_AFFILIATE_INCOME:
            return {...state, incomeHistory: {isFetching: true}};
        case REQUEST_AFFILIATE_INCOME_SUCCESS:
            return {...state, incomeHistory: {isFetching: false, ...action.payload}};
        case REQUEST_AFFILIATE_INCOME_FAILURE:
            return {...state, incomeHistory: {isFetching: false, ...action.payload}};

        // fetch record affiliate
        case REQUEST_RECORD_AFFILIATE:
            return {...state, recordAffiliate: {isFetching: true}};
        case REQUEST_RECORD_AFFILIATE_SUCCESS:
            return {...state, recordAffiliate: {isFetching: false, ...action.payload}};
        case REQUEST_RECORD_AFFILIATE_FAILURE:
            return {...state, recordAffiliate: {isFetching: false, ...action.payload}};

        default:
            return state;
    }
}
