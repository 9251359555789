import {Stack, Typography} from '@mui/material';
import React from 'react';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import FormattedAmount from './FormattedAmount';
import ColoredCurrencyIcon from './ColoredCurrencyIcon';

interface AmountToAmountProps {
    fromCurrencyCode: string;
    toCurrencyCode: string;
    fromAmount: number;
    toAmount: number;
    showCurrencyIcons?: boolean;
    size?: 'standard' | 'large';
}

/**
 * Renders field showing exchange of one amount and currency to another amount and currency.
 */
export default function AmountToAmount({
    fromCurrencyCode,
    toCurrencyCode,
    fromAmount,
    toAmount,
    showCurrencyIcons = false,
    size = 'standard',
}: AmountToAmountProps) {
    let currencyFontSize: any;
    let amountFontSize: any;
    let iconSize: number;
    if (size === 'large') {
        currencyFontSize = {xs: 32, md: 36};
        amountFontSize = {xs: 36, md: 40};
        iconSize = 36;
    } else {
        currencyFontSize = {xs: 14, md: 15};
        amountFontSize = {xs: 15, md: 17};
        iconSize = 24;
    }

    return (
        <Stack
            direction={size === 'large' ? {xs: 'column', md: 'row'} : {xs: 'row'}}
            spacing={{xs: 0.5, md: 0.8}}
            alignItems={size === 'large' ? {xs: 'flex-start', md: 'center'} : {xs: 'center'}}
            fontSize={currencyFontSize}
        >
            {showCurrencyIcons && (
                <Stack direction='row' pr={2} spacing={size === 'large' ? -1.5 : -0.6}>
                    <ColoredCurrencyIcon code={fromCurrencyCode} size={iconSize} />
                    <ColoredCurrencyIcon code={toCurrencyCode} size={iconSize} />
                </Stack>
            )}
            <Stack direction='row' spacing={{xs: 0.5, md: 0.8}} alignItems='center'>
                <Typography color='primary' fontWeight={700} fontSize={currencyFontSize}>
                    {fromCurrencyCode}
                </Typography>
                <Typography fontSize={amountFontSize}>
                    <FormattedAmount value={fromAmount} currencyCode={fromCurrencyCode} />
                </Typography>
            </Stack>
            <EastRoundedIcon sx={{color: '#898E9E', fontSize: {xs: '0.7em', md: '0.8em'}}} />
            <Stack direction='row' spacing={{xs: 0.5, md: 0.8}} alignItems='center'>
                <Typography color='primary' fontWeight={700} fontSize={currencyFontSize}>
                    {toCurrencyCode}
                </Typography>
                <Typography fontSize={amountFontSize}>
                    <FormattedAmount value={toAmount} currencyCode={toCurrencyCode} />
                </Typography>
            </Stack>
        </Stack>
    );
}
