import {useTranslation} from 'react-i18next';
import {Link as RouterLink, LinkProps} from 'react-router-dom';
import React, {Ref} from 'react';
import Link from '@mui/material/Link';
import {ensureLanguageInPath, ensureLanguageOnly} from '@simplecoin/core';

interface LocalizedLinkProps extends LinkProps {
    disabled?: boolean;
    underline?: 'none' | 'hover' | 'always';
}

/**
 * Renders LocalizedLink component which handles adding language to url and then
 * returning Link from react-router-dom
 * @constructor
 */
const LocalizedLink = React.forwardRef(
    ({to, disabled, underline = 'none', ...rest}: LocalizedLinkProps, ref: Ref<any>) => {
        const {i18n} = useTranslation();
        let urlWithLanguage;

        if (!disabled) {
            // parse only if not disabled
            const url: string = typeof to === 'string' ? to : '';
            const language = ensureLanguageOnly(i18n.language);
            urlWithLanguage = ensureLanguageInPath({path: url, language});
        }

        return (
            <Link
                to={urlWithLanguage}
                component={RouterLink}
                disabled={disabled}
                underline={underline}
                {...rest}
                ref={ref}
            />
        );
    }
);
LocalizedLink.displayName = 'LocalizedLink';
export default LocalizedLink;
