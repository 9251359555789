import {OrderBaseStatus, OrderDirectionStatus, OrderFinalStatus} from '~/typings/Order';

export type SimplifiedOrderStatus = 'new' | 'paidUnconfirmed' | 'paid' | 'dispatched' | 'canceled' | 'done';

export function getSimplifiedOrderStatus(
    status: OrderBaseStatus,
    statusDirection: OrderDirectionStatus,
    statusFinal: OrderFinalStatus
): SimplifiedOrderStatus {
    if (statusFinal) {
        switch (statusFinal) {
            case 'cancelled':
            case 'returned':
                return 'canceled';

            case 'delivered':
                return 'done';
        }
    }

    if (statusDirection) {
        switch (statusDirection) {
            case 'return_to_client':
                return 'canceled';
        }
    }

    if (status) {
        switch (status) {
            case 'new':
                return 'new';
            case 'confirming_incoming':
                return 'paidUnconfirmed';
            case 'confirming_outgoing':
                return 'dispatched';
            case 'received':
                return 'paid';
            case 'done':
                return 'done';
        }
    }
    // eslint-disable-next-line no-console
    console.log('Unable to determine simplified order status.', status, statusDirection, statusFinal);
    throw 'Unable to determine simplified order status!';
}

/**
 * Used to determine simplified order status for history log.
 */
export function getSimplifiedOrderStatus2(status: string): SimplifiedOrderStatus | 'suspended' | 'proceed' {
    if (status) {
        switch (status) {
            case 'cancelled':
            case 'returned':
            case 'return_to_client':
                return 'canceled';
            case 'delivered':
                return 'done';
            case 'new':
                return 'new';
            case 'confirming_incoming':
                return 'paidUnconfirmed';
            case 'confirming_outgoing':
                return 'dispatched';
            case 'received':
                return 'paid';
            case 'done':
                return 'done';
            case 'suspended':
                return 'suspended';
            case 'proceed':
                return 'proceed';
        }
    }
    // eslint-disable-next-line no-console
    console.log('Unable to determine simplified order status.', status);
    throw 'Unable to determine simplified order status!';
}
