import React, {memo} from 'react';
import {MenuItem, ListItemText, ListItemIcon} from '@mui/material';
import {Currency} from '~/typings/currency';
import FlatCurrencyIcon from './FlatCurrencyIcon';
import CustomSelect, {CustomSelectProps} from './CustomSelect';
import {useCurrenciesArray} from '~/hooks/currency';
import {getConfigValue} from '~/helpers/config';

export type CurrencyType = 'crypto' | 'fiat' | 'all';

export interface CurrencyDropdownProps {
    id: string;
    name: string;
    value: number | string;
    errorMessage: string;
    onChange: (event: React.ChangeEvent<{name?: string; value: any}>) => void;
    label: string;
    filter: CurrencyType;
    onlyOutCurrencies?: boolean;

    /** Allows to select empty value */
    allowEmptyValue?: boolean;

    /** Label for the empty value */
    emptyValueLabel?: string;

    /** Use currency name + code instead of just code as a displayed label. */
    currencyLongName?: boolean;

    /** Use currency ID as a value instead of currency code. */
    useCurrencyId?: boolean;
}

/**
 * Renders from currency list.
 */
const CurrencyDropdown = memo(
    ({
        id,
        name,
        value = '',
        filter = 'all',
        label,
        onlyOutCurrencies = false,
        allowEmptyValue = false,
        emptyValueLabel,
        currencyLongName = false,
        useCurrencyId = false,
        ...props
    }: CurrencyDropdownProps & CustomSelectProps) => {
        const currencyArray = useCurrenciesArray();
        const isWallet = getConfigValue('buildType', '') === 'wallet';

        // filter by type
        let filteredCurrencies =
            filter === 'all' ? currencyArray : currencyArray.filter((currency) => currency?.type === filter);

        if (isWallet) {
            filteredCurrencies = filteredCurrencies.filter((currency) => currency.is_wallet);
        }

        filteredCurrencies = onlyOutCurrencies
            ? filteredCurrencies.filter((currency) => currency?.is_out)
            : filteredCurrencies;

        function getDisplayedLabel(currency: Currency) {
            return currencyLongName ? `${currency.full_name} (${currency.name})` : currency.name;
        }

        const renderFunction = (currency, key) => {
            return (
                <MenuItem key={key} value={useCurrencyId ? currency.id : currency.name}>
                    <ListItemIcon sx={{margin: '4px 0'}}>
                        <FlatCurrencyIcon code={currency.name} height={28} width={28} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{variant: 'body2', color: 'primary'}}
                        sx={{
                            display: 'inline-flex',
                            paddingLeft: '5px',
                            alignItems: 'flex-end',
                            margin: 0,
                        }}
                    >
                        {getDisplayedLabel(currency)}
                    </ListItemText>
                </MenuItem>
            );
        };

        return (
            <CustomSelect
                id={id}
                name={name}
                label={label}
                value={value}
                fullWidth
                notched
                native={false}
                appendEmptyOption={allowEmptyValue}
                emptyOptionLabel={emptyValueLabel}
                {...props}
                renderOption={renderFunction}
                options={filteredCurrencies}
            />
        );
    }
);

CurrencyDropdown.displayName = 'CurrencyDropdown';

export default CurrencyDropdown;
