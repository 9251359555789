import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {dayjs} from '@simplecoin/core';

export interface DateTimeProps {
    date: string | number;
    format?: string;
}

/**
 * Renders date time locally formatted. It uses day.js to properly parse the date.
 *
 * @param {string|int} date Date as UNIX epoch or with milliseconds. UNIX epoch will be converted to milliseconds for
 * <Moment> component.
 * @param {string} format Moment.js format. See official documentation.
 * @param {object} rest
 * @return {*}
 * @constructor
 */
export default function DateTime({date, format = 'L LT', ...rest}: DateTimeProps) {
    const {i18n} = useTranslation();

    // Trick to get length of integers here
    if (`${date}`.length === 10 && typeof date === 'number') {
        date *= 1000;
    }

    const formatted = useMemo(() => dayjs(date).format(format), [date, i18n.language]);

    return (
        <time dateTime={String(date)} {...rest}>
            {formatted}
        </time>
    );
}

DateTime.displayName = 'DateTime';
