import Cookies from 'js-cookie';
import {parseJson} from './json';

export const AUTH_KEY = 'auth_key';

export interface LoginReducer {
    token: string;
    expires_at: number;
    created_at?: number;
    token_lifetime?: number;
}

/**
 * Sets access token cookie and to local storage as JSON
 * @param {LoginReducer} accessProps
 */
export function setAuthProps(accessProps: LoginReducer): void {
    let expiresAtDate;
    if (accessProps.expires_at) {
        const fromCookies = Cookies.get(AUTH_KEY);
        const sessionObjectFromCookies: LoginReducer | undefined = fromCookies ? parseJson(fromCookies) : undefined;
        const mergedAccessProps = {...sessionObjectFromCookies, ...accessProps};
        const sessionPropsJson = JSON.stringify(mergedAccessProps);
        expiresAtDate = new Date(accessProps.expires_at * 1000);
        // save values to session storage for SessionTimeoutWatcher component

        Cookies.set(AUTH_KEY, sessionPropsJson, {
            //todo: domain and strict
            expires: expiresAtDate,
            sameSite: 'lax',
            secure: process.env.NODE_ENV === 'production',
        });
    }
}

/**
 * Delete auth key from cookies.
 */
export function deleteAuthKey() {
    Cookies.set(AUTH_KEY, '', {expires: -1});
}

/**
 * Gets access token cookie or local storage value
 * @return {string | undefined}
 */
export function getAuthKey(): string | undefined {
    const sessionObject: string | undefined | null = Cookies.get(AUTH_KEY);

    if (!sessionObject) {
        return;
    }

    const parsed: LoginReducer | undefined = parseJson(sessionObject);
    if (!parsed) {
        deleteAuthKey();
        return;
    }
    const token = parsed?.token;
    const matches = /^([A-Za-z0-9-_=]+.){2}[A-Za-z0-9-_=]+$/.test(token);
    if (matches) {
        return token;
    } else {
        deleteAuthKey();
    }

    return;
}

/**
 * Gets specified parameter from session object stored in cookies
 * @param {string} param
 * @return {T | undefined}
 */
export function getAuthParam<T = number>(param: string): T | undefined {
    const sessionObject: string | undefined | null = Cookies.get(AUTH_KEY);
    if (!sessionObject) {
        return;
    }

    const parsed: LoginReducer | undefined = parseJson(sessionObject);
    return parsed?.[param];
}
