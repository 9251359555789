import {decode} from 'bip21';

interface ParseScannedContentProps {
    address: string;
    currency?: number;
    options?: {
        label?: string | null;
    };
}

/**
 * Function to parse scanned string with crypto address and optional parameters
 * @param scannedContent
 * @param currencyList list of currencies returned by useSetting('currencies')
 * @param options
 */
export function parseScannedContent(
    scannedContent: string,
    currencyList: any,
    options = {defaultTemplate: 'dummy:{content}'}
): ParseScannedContentProps | null {
    if (!scannedContent) {
        return null;
    }

    try {
        if (currencyList) {
            let shouldMakeCurrencySearch = true;

            // bip21.decode() needs prefix to successfully parse address with options. If it's missing, we fake it
            if (scannedContent.indexOf(':') === -1) {
                shouldMakeCurrencySearch = false;
                options.defaultTemplate = options.defaultTemplate || 'dummy:{content}';
                scannedContent = options.defaultTemplate.replace('{content}', scannedContent);
            }

            const prefix = scannedContent.split(':')[0];
            const returnObject = decode(scannedContent, prefix);

            if (currencyList && shouldMakeCurrencySearch) {
                const currencyKeys = Object.keys(currencyList);
                const currencyId = currencyKeys.find((key) => {
                    return !isNaN(parseInt(key)) && currencyList[key].urn_schema === prefix;
                });

                if (currencyId) {
                    returnObject.currency = currencyId;
                }
            }

            return returnObject;
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }

    return {address: scannedContent, options: {}};
}
