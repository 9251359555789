import React, {forwardRef} from 'react';
import {Button as MaterialButton, ButtonProps} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {ensureLanguageOnly} from '~/helpers/language';
import {ensureLanguageInPath} from '~/helpers/url';
import {ReducerEnvelope} from '~/typings/Api';

interface CustomButtonProps extends ButtonProps {
    reducer?: ReducerEnvelope<any>; // provided reducer for determining that there is an action in progress
    loadingButton?: boolean; // whether to show loader while reducer is fetching
    disabled?: boolean;
    fetchingLabel?: string;
}

/**
 * Presentational component to render button/button-link element. The link will have appended locale and not cause additional redirect
 */
const CustomButton = forwardRef(
    ({href, loadingButton, reducer, fetchingLabel, children, ...props}: CustomButtonProps, ref: React.Ref<any>) => {
        const {i18n} = useTranslation();
        const fetching = reducer?.isFetching === true;

        const url: string = typeof href === 'string' ? href : '';
        const language = ensureLanguageOnly(i18n.language);
        const urlWithLanguage = ensureLanguageInPath({path: url, language});

        if (loadingButton) {
            return (
                // @ts-ignore
                <LoadingButton
                    {...props}
                    disabled={fetching || props.disabled}
                    loading={fetching}
                    href={href ? urlWithLanguage : undefined}
                    ref={ref}
                    label={fetching && fetchingLabel}
                >
                    {children}
                </LoadingButton>
            );
        }

        return (
            <MaterialButton {...props} href={href ? urlWithLanguage : undefined} ref={ref}>
                {children}
            </MaterialButton>
        );
    }
);

CustomButton.displayName = 'LocalizedButton';

export default CustomButton;
