{
  "bank_account": "Bankkonto",
  "cancel": "Stornieren",
  "crypto_wallet": "Krypto-Geldbeutel",
  "exchange": "Wechseln",
  "order_has_been_canceled": "Auftrag ist storniert worden",
  "order_has_been_proceeded": "Auftrag wurde fortgesetzt",
  "order_has_been_suspended": "Auftrag wurde unterbrochen",
  "order_status_history": "Auftragsstatus-Verlauf",
  "orders": "Auftr\u00e4ge",
  "payment_information": "Zahlungsinformationen",
  "proceed": "Fortfahren",
  "rates": "Kurse",
  "return": "Zur\u00fcckgeben",
  "setting_language_preference_text": "W\u00e4hlen Sie die Sprache, in der die Webseite und Emails angezeigt werden sollen.",
  "setting_send_email_after_login": "Nach jeder Anmeldung eine Email senden.",
  "suspend": "Unterbrechen",
  "wallet_updated_successfully": "Geldbeutel wurde erfolgreich aktualisiert",
  "we_will_send_you_money_back_shortly": "Wir werden Ihnen das Geld in K\u00fcrze zur\u00fccksenden",
  "guest_login_and_signup_order_separation": "oder",
  "order_button_create_order": "Auftrag erstellen",
  "guest_login": "Anmelden",
  "guest_signup": "Registrieren",
  "menu_guest_link_about_us": "\u00dcber uns",
  "menu_guest_link_help": "Hilfe",
  "menu_guest_link_terms": "Nutzungsbedingungen",
  "order_form_caption": "Erstellen Sie Ihren Auftrag genau hier",
  "order_form_email": "Email",
  "forgot_password": "Passwort vergessen?",
  "login_form_submit_button": "Anmelden",
  "page_login_title": "Anmelden",
  "page_signup_title": "Registrieren",
  "signup_form_please_fill": "Bitte f\u00fcllen Sie die folgenden Felder aus um sich zu registrieren:",
  "signup_form_submit_button": "Registrieren",
  "order_form_placeholder_currency": "W\u00e4hrung",
  "refund_widget_add_new_refund_address": "R\u00fcckerstattungsadresse hinzuf\u00fcgen",
  "refund_address_select_label": "R\u00fcckerstattungsadresse",
  "copied": "Kopiert",
  "suspended_widget_return_money_back": "Geld zur\u00fcckgeben",
  "suspended_widget_top_alert_message": "Auftrag wurde unterbrochen, es wird jetzt auf Ihre Entscheidung gewartet. ",
  "add_order": "Neuen Auftrag hinzuf\u00fcgen",
  "language": "Sprache",
  "logged_in": "Angemeldet",
  "search": "Suchen",
  "status_direction": "Statusrichtung",
  "status_direction_description_cancelled": "Auftrag stornieren",
  "status_direction_description_proceed": "Auftrag fortfahren",
  "status_direction_description_return_to_client": "Mein Geld/M\u00fcnzen zur\u00fcckgeben",
  "status_direction_description_suspended": "Auftrag unterbrechen",
  "status_final": "Endg\u00fcltige Status",
  "status_name_cancelled": "Storniert",
  "status_name_confirming_incoming": "Eingehende best\u00e4tigt",
  "status_name_confirming_outgoing": "Ausgehende best\u00e4tigt",
  "status_name_delivered": "Zugestellt",
  "status_name_done": "Fertig",
  "status_name_new": "Neu",
  "status_name_proceed": "Fortfahren",
  "status_name_received": "Erhalten",
  "status_name_return_to_client": "Dem Kunden zur\u00fcckgeben",
  "status_name_returned": "Zur\u00fcckgegeben",
  "status_name_suspended": "Unterbrochen",
  "suspended_widget_table_change_percent": "\u00c4ndern",
  "client_bank_account_list_add_new_account": "Neues Konto hinzuf\u00fcgen",
  "account_form_create_button": "Bankkonto hinzuf\u00fcgen",
  "account_form_save_button": "Speichern",
  "create_new_wallet_header": "Neuen Geldbeutel hinzuf\u00fcgen",
  "delete_account": "Konto l\u00f6schen",
  "delete_wallet": "Geldbeutel l\u00f6schen",
  "update_account": "Konto aktualisieren",
  "update_wallet": "Geldbeutel aktualisieren",
  "wallet_form_create_button": "Geldbeutel hinzuf\u00fcgen",
  "header_what_now": "Was jetzt",
  "profile_page_header_my_documents": "Beigef\u00fcgte Dateien",
  "profile_page_header_my_profile": "Mein Profil",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "Ihr Profil wird \u00fcberpr\u00fcft.",
  "profile_page_save_button": "Speichern",
  "profile_page_tab_level1": "Level 1",
  "profile_page_tab_level2": "Level 2",
  "profile_page_tab_this_level_is_accepted": "Dieses Level wurde akzeptiert",
  "refund_address_set_successfully": "R\u00fcckerstattungsadresse erfolgreich festgelegt ",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "Wenn Sie das unten aufgef\u00fchrte Kontrollk\u00e4stchen klicken und das Formular abschicken, werden Sie Ihre Dateien nicht mehr \u00e4ndern oder l\u00f6schen k\u00f6nnen. Die Daten werden zur \u00dcberpr\u00fcfung an unsere Moderatoren \u00fcbermittelt. \n",
  "client_sidebar_avatar_hash_description": "Merken Sie sich dieses Bild. Es wurde ausschlie\u00dflich f\u00fcr Ihr Konto generiert. Wenn es sich ge\u00e4ndert hat, ist es m\u00f6glich, dass jemand versucht unsere Seiten zu imitieren. Bitte \u00fcberpr\u00fcfen Sie die Adressleiste Ihres Browsers, ob die Adresse korrekt ist und das gr\u00fcne Sicherheitsschloss links davon angezeigt wird.",
  "navigation_link_logout": "Abmelden",
  "navigation_link_rates": "Kurse",
  "company_about": "Simplecoin ist eine europ\u00e4ische Kryptow\u00e4hrungs-Wechselstube mit Hauptsitz im Herzen Europas, in Prag, Tschechische Republik. Unser Hauptziel ist es, die Verwendung von Bitcoin und anderen Kryptow\u00e4hrungen einfacher zu gestalten. Mit Simplecoin k\u00f6nnen Sie Bitcoins sicher und einfach kaufen, verkaufen oder versenden.",
  "sidebar_navigation_orders": "Auftr\u00e4ge",
  "sidebar_navigation_profile": "Profil",
  "sidebar_navigation_security": "Sicherheit",
  "sidebar_navigation_settings": "Einstellungen",
  "company_header": "\u00dcBER {{brandName}}",
  "company_header_contacts": "Kontakte",
  "company_header_social": "Soziale Netzwerke",
  "company_subheader": "EINFACHER KRYPTOW\u00c4HRUNGS-WECHSEL",
  "company_social_group": "Simplecoin Gruppe",
  "company_header_billing_address": "Rechnungsadresse",
  "page_header_company": "Firma",
  "footer_sidebar_link_about": "\u00dcber",
  "client_order_reset_all_label": "Alles zur\u00fccksetzten",
  "client_order_search_btn": "Auftr\u00e4ge suchen",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "Die Zahlungsaufforderung wurde von der Bank akzeptiert und jetzt warten wir auf die Best\u00e4tigung der Transaktion in der Liste der ausgehenden Bank-Transaktionen. ",
  "error": "Fehler ",
  "client_bank_accounts_widget_header": "Bankkonto Dokumente",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "Wir warten auf die \u00dcbereinstimmung der Transaktion. Wir werden es zur\u00fccksenden sobald es ankommt, da die Auftragsrichtung ''dem Kunden zur\u00fcckgeben'' besagt.",
  "page_subheader_settings_order_emails": "Auftragsemails",
  "page_subheader_settings_security_emails": "Sicherheitsemails",
  "client_setting_hint_send_email_when_order_confirming_incoming": "Senden Sie mir eine Email, sobald der Auftrag mit der eingehenden Kryptotransaktion gepaart wurde, welche auf Best\u00e4tigung wartet",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "Bitte informieren Sie mich, wenn die Kryptotransaktion oder die Bank\u00fcberweisung abgewickelt wurde und auf Best\u00e4tigung wartet.",
  "client_setting_hint_send_email_when_order_done": "Senden Sie mir eine Email, sobald ein Auftragsstatus in ''fertig'' ge\u00e4ndert wurde.",
  "client_setting_hint_send_email_when_order_proceed": "Senden Sie mir eine Email, sobald ein Auftragsstatus in ''fortfahren'' ge\u00e4ndert wurde.",
  "client_setting_hint_send_email_when_order_received": "Senden Sie mir eine Email, sobald SimpleCoin die Bankeinzahlung f\u00fcr den Auftrag erhalten hat oder die eingehende Kryptozahlung best\u00e4tigt wurde",
  "client_setting_hint_send_email_when_order_return_to_client": "Senden Sie mir eine Email, sobald eine Auftragsrichtung in ''zur\u00fcckgegeben'' ge\u00e4ndert wurde.",
  "client_setting_hint_send_email_when_order_suspended": "Senden Sie mir eine Email, sobald die Auftragsrichtung in ''unterbrochen'' ge\u00e4ndert wurde.",
  "client_settings_label_order_confirming_incoming": "Eingehende wartet auf Best\u00e4tigung",
  "client_settings_label_order_confirming_outgoing": "Confirming Outgoing",
  "client_settings_label_order_done": "Fertig",
  "client_settings_label_order_proceed": "Fortfahren",
  "client_settings_label_order_received": "Erhalten",
  "client_settings_label_order_return_to_client": "Dem Kunden zur\u00fcckgeben",
  "client_settings_label_order_suspended": "Unterbrochen",
  "page_subheader_settings_general": "Allgemein",
  "profile_page_level1_my_document_subheader_paragraph": "Sonstige Dokumente, welche zur online Identifizierung genutzt werden k\u00f6nnen. Sie k\u00f6nnen hier mehrere Dateien hochladen.",
  "profile_page_level2_my_document_subheader_paragraph": "Sie k\u00f6nnen hier mehrere Dateien hochladen",
  "profile_page_level2_note_description": "Wenn Sie sich als Firma registrieren, bitte geben Sie ihre Position, den Firmennamen und die Registernummer an. Bitte erl\u00e4utern Sie jegliche ungew\u00f6hnliche Situationen wie etwa den Empfang von Zahlungen von einer dritten Person, die Erstellung von Auftr\u00e4gen f\u00fcr jemanden anderen usw.",
  "profile_page_level2_trading_purpose_description": "Bitte geben Sie an, was der Zweck Ihres Wechsels ist. Wir sind gem\u00e4\u00df der tschechischen Regulierungsbeh\u00f6rden dazu verpflichtet, Sie nach dem Zweck zu befragen. ",
  "profile_page_level2_trading_source_of_income_description": "Bitte erl\u00e4utern Sie, wie Sie die Mittel f\u00fcr Ihren Handel erworben haben. Bei sehr gro\u00dfen Betr\u00e4gen werden wir nach jeglichem Dokument fragen, welches Ihre Stellungnahme unterst\u00fctzen kann. Wenn Sie kein Dokument hochladen k\u00f6nnen, schreiben Sie bitte explizit, dass es so einen Nachweis nicht gibt.",
  "action_reset_password_error_email_not_sent": "Leider k\u00f6nnen wir das Passwort f\u00fcr die eingegebene Emailadresse nicht zur\u00fccksetzen. ",
  "action_reset_password_success": "F\u00fcr weitere Anweisungen kontrollieren Sie bitte Ihre Email.",
  "address_was_deleted": "Adresse wurde gel\u00f6scht.",
  "bank_account_was_added": "Bankkonto wurde hinzugef\u00fcgt.",
  "bank_account_was_deleted": "Bankkonto wurde gel\u00f6scht.",
  "bank_account_was_updated": "Bankkonto wurde aktualisiert.",
  "change": "\u00c4ndern",
  "change_password": "Passwort \u00e4ndern",
  "delete": "L\u00f6schen",
  "email_confirmed": "Ihre Email wurde best\u00e4tigt.",
  "enter_email_address_to_recover_account": "Bitte tragen Sie Ihre Email ein. Ein Link zur Zur\u00fccksetzung Ihres Passworts wird dorthin zugesendet.",
  "file_was_deleted": "Datei wurde gel\u00f6scht",
  "information_successfully_saved": "Ihre Information wurde erfolgreich gespeichert.",
  "new_address_was_added": "Neue Adresse wurde hinzugef\u00fcgt.",
  "new_password_saved": "Neues Passwort wurde gespeichert.",
  "order_has_been_created_guest": "Auftrag wurde erstellt. Bitte kontrollieren Sie Ihre Email um weitere Informationen zu diesem Auftrag zu erhalten. ",
  "pls_choose_new_password": "Bitte w\u00e4hlen Sie Ihr neues Passwort:",
  "request_password_reset": "Passwort-Reset anfragen \n",
  "reset_password": "Passwort zur\u00fccksetzen",
  "save": "Speichern",
  "security": "Sicherheit",
  "send": "Senden",
  "signup": "Anmelden",
  "update": "Aktualisieren ",
  "are_you_sure_you_want_to_delete_this_file": "Sind Sie sicher, dass Sie dies Datei l\u00f6schen m\u00f6chten?",
  "client_bank_account_message_warning_about_symbol": "* Simplecoin Symbol wird zur Nachricht hinzugef\u00fcgt, wenn wir Ihnen das Geld schicken.",
  "from_amount_currency_label": "Vom Betrag",
  "to_amount_currency_label": "Auf den Betrag",
  "menu_guest_link_rates": "Kurse",
  "is_automatic_proceed_on_duplicate_payment": "Status ''Fortfahren'' bei doppelten Anfragen automatisch einstellen",
  "is_automatic_proceed_on_late_payment": "Bei versp\u00e4teter Zahlung automatisch fortfahren ",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "Wenn es zu einer doppelten Zahlung kommt (die Zahlung stimmt mit dem Auftrag, welcher bereits bezahlt wurde, \u00fcberein), wird das System automatisch den bezahlten Auftrag kopieren. Normalerweise wird dieser kopierte Auftrag unterbrochen und wartet auf Ihre Entscheidung. Wenn dieses K\u00e4stchen \u00fcberpr\u00fcft und aktiviert ist, wird der kopierte Auftrag zum aktuellen Preis aktualisiert und der Status auf ''Fortfahren'' eingestellt. Auf diese Weise k\u00f6nnen Sie ganz einfach W\u00e4hrungen wechseln indem Sie Zahlungen senden. Es wird keine Interaktion mit unserer Webseite notwendig sein.",
  "setting_hint_is_automatic_proceed_on_late_payment": "Wenn Ihre Zahlung versp\u00e4tet ankommt, wird das System automatisch den neuen Marktpreis akzeptieren und den Auftrag f\u00fcr Sie fortsetzen. Standardm\u00e4\u00dfige Anfragen werden unterbrochen und warten auf Ihre Entscheidung.",
  "no_camera_found": "Es wurde keine Kamera gefunden oder der Zugang steht nicht zur Verf\u00fcgung.",
  "scan_address": "Scan QR",
  "button_disable": "Deaktivieren",
  "confirm": "Best\u00e4tigen",
  "header_disable_google_authenticator": "Zwei Faktoren Authentifizierung (2FA) deaktivieren",
  "header_enable_google_authenticator": "Zwei Faktoren Authentifizierung (2FA) aktivieren",
  "label_2fa_password": "Passwort",
  "label_2fa_secret": "Geheim",
  "2fa_secret_description": "Hinweis: Sichern Sie diesen Code und teilen Sie diesen niemandem mit. Falls Sie Ihr Telefon verlieren, kann der Code genutzt werden, um erneuten Zugang zu der zwei Faktoren Authentifizierung zu erhalten. ",
  "btn_disable": "Deaktivieren",
  "btn_enable": "Aktivieren",
  "two_factor_authentication": "Zwei Faktoren Authentifizierung (2FA)",
  "fill_information_below_to_disable_two_factor_authentication": "Bitte f\u00fcllen Sie die nachstehenden Informationen aus, um die zwei Faktoren Authentifizierung zu deaktivieren: ",
  "alert_success_email_link_was_resent_again": "Neue Email zur Aktivierung wurde erfolgreich versendet.",
  "hint_login_form_2fa_code": "Code ist nicht erforderlich, wenn zum Konto keine zwei Faktoren Authentifizierung erm\u00f6glicht wird.\n",
  "footer_sidebar_link_terms": "Nutzungsbedingungen",
  "button_download_order_pdf": "PDF Herunterladen",
  "button_hint_download_order_pdf": "Auftragsinformation als PDF herunterladen",
  "alert_success_affiliate_payout_request_successfully_created": "Die angefragte Affiliate Abhebung wurde erhalten.\n",
  "alert_success_you_have_become_affiliate": "Sie sind erfolgreich zu unserem Affiliate geworden.",
  "button_create": "Erstellen",
  "button_save": "Speichern",
  "page_affiliate_dashboard_copy_url_header": "Affiliate URL",
  "page_affiliate_dashboard_overview_header": "Affiliate \u00dcbersicht",
  "page_affiliate_dashboard_overview_paragraph": "Weiter unten k\u00f6nnen Sie eine \u00dcbersicht Ihrer Affiliate Konten sehen.",
  "page_affiliate_dashboard_paragraph": "Auf dieser Seite k\u00f6nnen Sie eine Gesamt\u00fcbersicht Ihres Affilicate Kontos sehen, detaillierte Statistiken betrachten und die Abhebung jeder beliebigen von Simplecoin Support unterst\u00fctzten W\u00e4hrung anfragen.  ",
  "page_affiliate_statistics_button": "Statistiken",
  "page_affiliate_stats_first_paragraph": "Auf dieser Seite k\u00f6nnen Sie Statistiken Ihres Affilicate Programms sehen. Wie oft es von Endnutzern benutzt wurde und wann.",
  "page_affiliate_stats_label_conversion": "Banner Klicks",
  "page_affiliate_stats_label_period": "Datum",
  "page_affiliate_withdraw_button": "Abheben",
  "page_become_affiliate_first_paragraph": "Wenn Sie unser Affiliate werden m\u00f6chten, bitte kontrollieren Sie die Checkbox weiter unten um zu best\u00e4tigen, dass Sie mit unseren Affiliate Bedingungen einverstanden sind und schicken Sie die Form ab um weiter fortzufahren. ",
  "page_header_affiliate_dashboard": "Affiliate \u00dcbersicht",
  "page_header_become_affiliate": "Affiliate werden",
  "sidebar_navigation_affiliate": "Affiliate",
  "label_agree_with_affiliate_conditions": "Ich erkl\u00e4re mich mit den Affiliate Bedingungen einverstanden",
  "page_affiliate_withdraw_request_paragraph": "Bitte w\u00e4hlen Sie entweder das Bankkonto oder die Adresse, um Mittel von Ihrem Affiliate Guthaben abzuheben. ",
  "page_affiliate_finance_first_paragraph": "Auf dieser Seite k\u00f6nnen Sie eine Liste von Auftr\u00e4gen sehen, welche mit Ihrem Konto verbunden sind. Au\u00dferdem k\u00f6nnen Sie eine Liste von Ihrem Profit sehen, welchen Sie bei jedem Auftrag erhalten haben (in EUR).",
  "page_affiliate_finance_label_order": "Auftrag",
  "page_affiliate_finance_button": "Einkommen",
  "page_affiliate_finance_label_profit_in_eur": "Profit (EUR)",
  "page_affiliate_asset_banners_paragraph": "Auf Ihrer Webseite k\u00f6nnen Sie weiter unten interaktive Banner anbringen. Kopieren Sie eines der Codes weiter unten und f\u00fcgen Sie es an die Stelle ein, wo Sie dieses gerne angezeigt haben m\u00f6chten. ",
  "page_affiliate_assets_button": "Werbematerial",
  "api_order_unsupported_currency_pair": "Wechsel steht f\u00fcr dieses W\u00e4hrungspaar nicht zur Verf\u00fcgung",
  "footer_sidebar_link_data_processing": "GDRP",
  "alert_success_ticket_created": "Ticket wurde erfolgreich erstellt.",
  "alert_success_ticket_reply_sent": "Antwort zum Ticket wurde erfolgreich gesendet.",
  "button_send": "Senden",
  "page_header_ticket_create": "Neues Ticket",
  "page_header_ticket_list": "Tickets",
  "page_header_ticket_reply": "Ticket beantworten",
  "page_ticket_reply_you": "Sie",
  "sidebar_navigation_ticket": "Support",
  "alert_success_ticket_closed": "Ticket wurde als gel\u00f6st markiert.",
  "button_create_support_ticket": "Support",
  "button_hint_create_support_ticket": "Bitte erstellen Sie ein Support Ticket, wenn Sie irgendwelche Fragen oder Probleme mit diesem Auftrag haben.",
  "page_tickets_pending_tickets": "Bevorstehende Tickets",
  "page_tickets_solved_tickets": "Gel\u00f6ste Tickets",
  "page_tickets_no_pending_tickets": "Bisher keine bevorstehenden oder erstellten Tickets",
  "page_tickets_no_solved_tickets": "Bisher keine gel\u00f6sten Tickets.",
  "take_selfie_button": "Selfie machen",
  "no_camera_access_provided": "Es steht kein Kamerazugang zur Verf\u00fcgung. Bitte kontrollieren Sie die Kameragenehmigungen und stellen Sie sicher, dass diese nicht gesperrt sind.",
  "menu_guest_link_privacy_policy": "Datenschutzbestimmungen",
  "change_password_social_reset_password_claim": "Bitte benutzen Sie den unten aufgef\u00fchrten Button, um Ihr Passwort zu ver\u00e4ndern. Nachdem Sie diesen gedr\u00fcckt haben, senden wir Ihnen eine Email mit Link zu damit Sie Ihr Passwort neu festlegen k\u00f6nnen.",
  "alert_change_password_email_was_sent": "Bitte kontrollieren Sie Ihre Email, wo Sie den Link zur Festlegung Ihres Passworts finden k\u00f6nnen.",
  "page_confirm_two_factor": "Zwei Faktoren Authentifizierung best\u00e4tigen",
  "page_confirm_two_factor_paragraph": "Bitte geben Sie den Code von Ihrer zwei Faktoren Authentifizierungs-App ein:",
  "cookie_consent_i_agree": "Ich verstehe",
  "cookie_consent_message": "Diese Seite benutzt Cookies um bei der Navigation zu assistieren, Feedback zu geben, Ihre Nutzung unserer Produkte und unseres Services zu analisieren. <0>Cookie-Politik.</0>",
  "try_again_button": "Noch einmal versuchen ",
  "dropable_or_pastable_from_clipboard": "Ziehen und Ablegen von Daten von Ihrem PC hierher oder Einf\u00fcgung von Fotos in der Zwischenablage indem Sie CTRL-V dr\u00fccken",
  "hint_enter_two_factor_code_to_confirm_action": "Bitte geben Sie den Code vom Google Authenticator ein",
  "add_new_ticket": "F\u00fcgen Sie ein neues Ticket hinzu",
  "close_ticket": "Schlie\u00dfen",
  "client_setting_hint_send_email_limit_price_changed": "Bitte senden Sie uns eine Email wenn das Auftrags-Preislimit erstellt oder ver\u00e4ndert wurde.  ",
  "client_settings_label_order_limit_price_changed": "Preislimit",
  "button_set_limit": "Legen Sie ein Limit fest",
  "suspended_widget_header_limit_rate": "Kurslimit",
  "suspended_widget_header_limit_rate_hint": "Indem Sie das Kurslimit festlegen wird Ihr Auftrag automatisch akzeptiert sobald der Marktkurs das von Ihnen festgelegte Kurslimit erreicht.",
  "suspended_widget_header_market_rate": "Marktkurs",
  "suspended_widget_header_market_rate_hint": "Marktkurs f\u00fcr Ihren Auftrag, den wir Ihnen anbieten k\u00f6nnen. Alle Geb\u00fchren sind inbegriffen daher ist es der genaue Kurs den Sie erhalten.",
  "suspended_widget_header_order_rate": "Auftrags-Kurs",
  "suspended_widget_header_order_rate_hint": "Dies ist der festgelegte Kurs im Moment Ihrer neuen Auftrags-Erstellung. Alle Geb\u00fchren sind bereits inbegriffen.",
  "button_change_limit": "\u00c4ndern",
  "button_limit_cancel": "Stornieren",
  "suspended_widget_limit_form_paragraph": "Diese zwei Felder repr\u00e4sentieren den selben Kurs. Der Wert im zweiten Feld ist der Kehrwert des ersten Feldes, beide Felder werden entsprechend aktualisiert. Es erm\u00f6glicht Ihnen den Kurs so einzugeben, wie Sie es gewohnt sind. ",
  "hint_close_ticket_button": "Wenn Sie auf diesen Button klicken, wird das Ticket als gel\u00f6st markiert und es k\u00f6nnen keine weiteren Anforderungen gestellt werden, es sei denn, Sie erstellen ein neues Ticket. ",
  "heading_chart": "Grafik",
  "button_close": "Schlie\u00dfen",
  "boolean_option_no": "Nein",
  "boolean_option_yes": "Ja",
  "generic_link_learn_more": "Erfahren Sie mehr",
  "profile_page_header_my_documents_level0_paragraph": "<strong> Bitte f\u00fcgen Sie die folgenden pers\u00f6nlichen Dokumente hinzu (zwei Dokumente sind ausreichend):</strong><br>\n- Personalausweis (beidseitig) oder Pass ist als Dokument zur Identifizierung notwendig, beide sind ideal<br>\n- F\u00fchrerschein oder ein anderes Dokument mit Foto (ebenfalls beidseitig)<br><br>\n- Nachweis der Existenz des Bankkontos: Kopftext des Kontoauszuges mit Name und Kontonummer, erstellen Sie Ihr Konto in der Sektion ''Konten'' und f\u00fcgen Sie die Dokumente genau dort hinzu<br><br>\nWir ben\u00f6tigen Scans von hoher Qualit\u00e4t (300dpi) oder vollst\u00e4ndig lesbare Fotos, es k\u00f6nnen weder Teile von den Dokumenten hinzugef\u00fcgt noch entfernt werden ",
  "profile_page_header_my_documents_level1_paragraph": "<strong>Bitte f\u00fcgen Sie die folgenden Dokumente hinzu:</strong><br>\n- ein beliebiges Dokument, welches hilft Ihre Einnahmequelle nachzuweisen<br>\n- ein Foto von Ihnen, auf welchem Sie Ihren Ausweis halten<br>\n- gegebenenfalls Registrierungsdokumente des Unternehmens<br>\n- weitere Dokumente, die von unseren Mitarbeitern angefordert wurden \n\n",
  "chart_module_market_price_inverse": "Umkehren",
  "chart_module_no_data_available": "Daten sind nicht verf\u00fcgbar.",
  "bank_account_verified": "Verifiziert",
  "profile_page_bank_account_header": "Ihre Bankkonten",
  "profile_page_bank_accounts_paragraph": "F\u00fcr eine erfolgreiche Identifizierung \u00fcber das Internet ist es notwendig mindestens ein Bankkonto zu verifizieren. Bitte richten Sie auf der Kontoseite Ihr Konto ein, f\u00fcgen anschlie\u00dfend das erforderliche Dokument gem\u00e4\u00df der Anweisungen hinzu und \u00fcberweisen gegebenenfalls eine Test-Zahlung. ",
  "bank_account_file_upload_unverified_level0_paragraph": "Um Ihre Identifizierung \u00fcber das Internet zu vervollst\u00e4ndigen (Level 1 Profil Verifizierung) ben\u00f6tigen wir eine Best\u00e4tigung der Existenz Ihres Bankkontos. Bitte f\u00fcgen Sie ein von der Bank erstelltes Dokument bei, auf welchem wir den Kontoinhaber und die Kontonummer sehen k\u00f6nnen. Dies kann ein Kontoauszug oder ein Kontof\u00fchrungs-Vertrag sein. Ein Scan des Papierdokuments oder PDF Datei wird akzeptiert. Bitte f\u00fcgen Sie das Dokument hier hinzu.",
  "guest_market_table": "Preistabelle",
  "validation_error_not_matching_passwords": "Kennwort stimmt nicht \u00fcberein",
  "react_send_from_amount_here": "Senden Sie {{amount}} <0>{{currency}}</0> an die folgende Adresse:",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Wir haben Ihre Zahlung erhalten, aber Sie haben sich entschieden diese zur\u00fcckzubekommen. Wir werden Ihnen {{amount}} <0>{{currency}}</0> in K\u00fcrze senden.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "F\u00fcr Ihren Auftrag haben wir {{amountFrom}} <0>{{currencyFrom}}</0> erhalten und werden Ihnen {{amountTo}} <1>{{currencyTo}}</1> senden.",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "Wir sehen die eingehende Krypto-Transaktion f\u00fcr {{amount}} <0>{{currency}}</0>. Momentan warten wir auf weitere Best\u00e4tigungen.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "Wir haben die ausgehende Krypto-Transaktionen f\u00fcr {{amount}} <0>{{currency}}</0> gesendet. Momentan warten wir auf weitere Best\u00e4tigungen.",
  "react_suspended_widget_accept_btn": "Akzeptieren Sie {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": " {{amount}} {{currency}} zur\u00fcckgeben",
  "react_order_created_high_market_volatility_message": "Wegen hoher Marktvolatilit\u00e4t k\u00f6nnen wir diesen Kurs nur f\u00fcr {duration} halten. Die Geschwindigkeit Ihrer Krypto-Transaktion h\u00e4ngt von den Transaktionsgeb\u00fchren ab, also ist es wichtig die korrekte Transaktionsgeb\u00fchr zu benutzen damit Ihre Transaktion bald verifiziert wird. Wenn Ihre Zahlung nach dieser Zeit ankommt und sich gleichzeitig der Kurs insbesondere ver\u00e4ndert, werden wir Sie per Email kontaktieren um Ihnen diese Optionen anzubieten - den neuen Wechselkurs zu akzeptieren und mit dem Auftrag fortzufahren oder Ihre Mittel zur\u00fcckzugeben. ",
  "react_order_suspend_over_limit_message": "Auftrag wurde unterbrochen, da Sie das maximale {{limitDays}} Tage Handelsvolumen erreicht haben. Es ist notwendig, dass Sie Ihr Profil auf das Level {{level}} aktualisieren.",
  "chart_timeframe_option_all": "ALLES",
  "react_bank_account_file_upload_unverified_paragraph": "We will also need to see incoming bank transaction from this account so we can see account holder name. If you plan to send funds from this account to us, no extra steps are needed. Only if you plan to receive funds to this account as first time time transaction, please send any minimum amount to <0>any of our accounts</0> first. ",
  "react_2fa_qr_code_description": "For extra security of your account, you may enable two-factor authentication (2FA). We use Google Authenticator. In order to start using it, install <0>Google Authenticator application</0> into your phone, scan QR code shown on this page, enter current password of the account and code form the application.",
  "chart_timeframe_option_day": "Tag",
  "chart_timeframe_option_month": "Monat",
  "chart_timeframe_option_week": "Woche",
  "chart_timeframe_option_year": "Jahr",
  "chart_timeframe_option_year_to_date": "Jahresverlauf",
  "react_profile_page_level1_paragraph_before_form": "Level 1 allows trading for volume up to equivalent of {{limit_level_2}} EUR in {{tradeLimitDays}} days.",
  "react_profile_page_level2_paragraph_before_form": "Level 2 allows clients to trade cumulative volume above equivalent of {{limit_level_2}} EUR in {{tradeLimitDays}} days. In some cases additional AML questioning may be conducted.",
  "react_page_header_posts_by_category": "Kategorie: {{name}}",
  "chart_timeframe_option_3months": "3 Monate",
  "ticket_load_more": "Load more",
  "ticket_type_your_message": "Type your message here...",
  "no_results_found": "No results found.",
  "validation_field_required": "This field is required",
  "validation_min_characters": "Field must contain at least ${min} characters",
  "uploaded_file_information_complete": "Information about this document is complete",
  "uploaded_file_click_to_fill_information": "Click here to fill additional information",
  "react_order_header": "Order {{id}}",
  "profile_page_header_my_personal_data": "Personal information",
  "validation_error_incorrect_phone_number": "Incorrect phone number ",
  "react_file_upload_extension_error": "Only the following extensions are allowed: {{extensions}}",
  "qr_code_scan_success": "Successfully scanned QR code",
  "upload_success": "Upload was successful",
  "button_open_form": "Open form",
  "client_bank_trade_volume_label": "Trade volume of bank account",
  "qr_payment_hint": "You can use QR Platba. Scan code below using smart banking application in your phone",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0>Sign up</0> and get trading volume and/or personal discount. ",
  "sidebar_user_traded_volume": "{{tradeLimitDays}} days volume: {{traded}}/{{limit}}EUR",
  "trading_limits_info_message": "Unverified volume limit: {{limitLevel1}} EUR/{{level1TradeLimitDays}} days <br/>\nLevel 1 volume limit: {{limitLevel2}} EUR/{{level2TradeLimitDays}} days <br/>\nLevel 2 volume limit is not formally limited",
  "sidebar_user_traded_volume_sum": "Trade volume: {{traded}} EUR",
  "react_refund_widget_already_chosen_refund_address": "You have chosen {{refundAddress}} as the refund address.",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "We have received your coins already. You have decided to get them back. Return payment has additional fee. You will get {{amount}} {{currency}} back to your refund address.",
  "refund_widget_btn_change_refund_address": "Change refund address",
  "react_order_crypto_return_fee_info_message": "Returning the order has additional fee. You will get {{amount}} {{currency}} back to selected address.",
  "react_suspended_widget_return_currency_btn": "Return {{amount}} {{currency}} to this wallet",
  "we_will_send_you_crypto_back_shortly": "We will send you coins back shortly.",
  "button_remove_limit": "Remove limit",
  "react_alert_limit_removed": "Limit removed successfully",
  "unconfirmed_email_please_confirm_or_resend": "Please confirm your email address first or <0>resend activation email</0>",
  "button_back": "Back",
  "button_next": "Next",
  "button_submit_profile": "Submit profile",
  "you_need_to_log_out_before_seeing_anonymous_order": "You need to log out before seeing anonymous order",
  "react_form_rate_explenation": "How is your price calculated?",
  "sign_up_to_return_guest_order": "In order to return your order please sign up and add a wallet.",
  "error_page_not_found": "Page Not Found",
  "react_page_affiliate_withdraw_request_button": "Withdraw <strong>{{amount}} {{currency}}</strong>",
  "affiliate_withdraw_history_table_converted_amount": "Converted amount",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Copy URL below and share it on your blog, social media or with your friends. Anybody who clicks on this link and registers or create order within {{duration}} days will associated to your affiliate account. You will receive provision from every exchange which was made by user you invited.",
  "react_affiliaite_balance_information": "Balance: {{balance}}<br/>Total profit: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "Balance is your current amount which you can withdraw. Total profit is what you earned since you joined affiliate program.",
  "label_affiliate_overview_joined_at": "Joined",
  "label_repeat_password": "Repeat Password",
  "react_consent_to_tos": "By continuing I declare that I have read and agree with the <0>Terms of Services</0> and <1>Privacy Policy</1> of {{companyName}}",
  "label_delete_account": "Delete account",
  "button_delete_account": "Delete my account",
  "delete_account_warning_paragraph": "If you proceed, your account's data will be permanently deleted. We are sorry to see you go. You can register again any time with the same email address but your account will be empty.",
  "account_delete_success": "Your account has been deleted",
  "log_out_success": "You have been logged out.",
  "session_time_remaining_label": "Session time remaining",
  "session_expire_in_label": "Your session will expire",
  "order_filter_label": "Filter orders",
  "order_filter_button_all": "All",
  "to_currency_amount_field_label": "I want to receive",
  "status_name_unfinished": "Unfinished",
  "status_description_unfinished": "Order does not have a final status yet",
  "filter_drawer_incomplete_filters_warning": "Please select at least one status from every category",
  "help_pages_label": "Help Center",
  "react_file_upload_size_error": "The file you tried to upload is too big. The maximum file size is {{size}}MB",
  "alert_submit_previous_level_first": "Please submit previous profile verification level first.",
  "wrong_vs_format_message": "Variable symbol should only contain digits (up to 10)",
  "wrong_ss_format_message": "Spacific symbol should only contain digits (up to 10)",
  "wrong_ks_format_message": "Constant symbol should only contain digits (up to 4)",
  "button_extend_session": "Extend session",
  "fiat_payment_recipient_name": "Recipient name",
  "fiat_payment_recipient_street": "Recipient street",
  "fiat_payment_recipient_city": "Recipient city",
  "fiat_payment_recipient_country": "Recipient country",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "Send {{amount}} <0>{{currency}}</0> to the following bank account",
  "crypto_qr_hint": "You can scan the code below or click on it to open wallet on your device.",
  "react_validation_error_insufficient_funds": "Unzureichende Mittel. Sie haben nur {{amount}} {{currencyName}}",
  "client_settings_label_affiliate_new_income": "New Funds Credited",
  "client_setting_hint_affiliate_new_income": "Send me an email when I get new credit to my affiliate account.",
  "page_subheader_settings_affiliate": "Affiliate",
  "check_address_on_trezor_header": "Check address on Trezor",
  "check_address_on_trezor_body": "And press the continue button on it",
  "show_address_on_trezor_button_label": "Show address on Trezor",
  "send_by_trezor_button": "Pay by Trezor",
  "send_by_trezor_description_tooltip": "You can use this feature to send your payment safely from connected Trezor wallet. ",
  "trezor_address_import_dialog_header": "The following addresses belong to the chosen Trezor account. Please select one.",
  "import_from_trezor_button": "Import from Trezor",
  "address_used": "used",
  "trezor_transaction_sent_dialog_title": "The transaction has been successfully sent.",
  "trezor_transaction_sent_dialog_content": "You have paid with Trezor! The hash is: {{txid}}. Please wait for the transaction to be paired to your order.",
  "trezor_address_import_available_for_currencies": "Trezor address import in available for: {{currencies}}",
  "trezor_link_learn_more": "Learn more about using Trezor on {{brandName}}",
  "page_refresh_button_label": "Refresh",
  "profile_level_one_send_transfer_instead_of_document_upload": "I will send bank transfer from an account held in my name instead of uploading a document.",
  "profile_level1_primary_document_front_label": "The front of the primary document",
  "profile_level1_primary_document_reverse_label": "The reverse of the primary document",
  "profile_level1_secondary_document_front_label": "The front of the secondary document",
  "profile_level1_secondary_document_reverse_label": "The reverse of the secondary document",
  "profile_page_level1_primary_document_label": "Primary document",
  "profile_page_level1_secondary_document_label": "Secondary document",
  "profile_page_level1_additional_documents_label": "Additional documents",
  "profile_page_level1_document_passport": "Passport",
  "profile_page_level1_document_national_id": "Personal ID",
  "profile_page_level1_document_other": "Other document",
  "profile_page_level1_document_driving_license": "Driving License",
  "profile_page_level1_primary_document_type_select_information": "Choose your primary document type",
  "profile_page_level1_secondary_document_type_select_information": "Choose your secondary document type",
  "profile_page_level1_additional_document_type_select_information": "Add additional document",
  "profile_page_level1_additional_document_skip_label": "I do not have any additional documents to upload",
  "address_format_bech32_wallet_support_warning": "This address is in bech32 format which is not fully supported by Trezor Wallet. For full functionality please select type \"Segwit accounts\" or \"Legacy Accounts\". Address in bech32 format can now be used in electrum.org wallet.",
  "label_document_title": "Document title",
  "react_new_app_version_available_dialog_title": "New version of {{brandName}} is available",
  "update_application_version": "Update",
  "third_party_guest_order_provide_email_paragraph": "In order to continue please provide an email address.",
  "third_party_order_claim_email_verification_message": "Please check your email where you can find a confirmation link. ",
  "third_party_guest_order_confirm_or_resend_email": "Please confirm your email address or use the button below to send it again.",
  "log_in_to_see_order_registered_client": "Log in to view order",
  "client_recipients_list_header": "Recipients",
  "sidebar_navigation_recipients": "Recipients",
  "recipients_filter_label": "Filter recipients",
  "client_add_new_recipient_button": "Add recipient",
  "reset_filters_button": "Reset",
  "create_new_recipient_header": "Add new recipient",
  "disable_social_login_label": "Unlink social network account",
  "btn_unlink": "Unlink",
  "alert_error_please_set_password_to_disable_social_login": "Please set account password first to unlink social media account login.",
  "disable_social_login_warning_paragraph": "If you proceed, your social media account will be unlinked. You will only be able to log in using your {{brandName}} account password. ",
  "social_login_disable_success": "Social login disabled successfully",
  "file_attached_to_ticket_auto_message": "File attached: {{fileNames}}",
  "network_error_warning": "Oops, it looks like you are offline",
  "password_strength_very_weak": "Very weak password",
  "password_strength_weak": "Weak password",
  "password_strength_good": "Acceptable password",
  "password_strength_great": "Strong password",
  "status_description_on_hold": "Order is on hold",
  "status_direction_description_on_hold": "Order is on hold",
  "exchange_form_fee_included_tooltip_title": "The rate you see includes the following transaction fee",
  "exchange_form_fee_not_included_tooltip_title": "The following transaction fees are not included in rate shown. Specify amount to see the final rate.",
  "exchange_form_total_fee_eur": "Total fees: ~{{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Incoming transaction fee: {{amount}} {{currency}}",
  "exchange_form_fee_tooltip_fee_out": "Outgoing transaction fee: {{amount}} {{currency}}",
  "exchange_form_fee_label_no_fee": "Zero fee",
  "exchange_form_fee_label_no_fee_explanation": "This trade will have no additional transaction fee",
  "exchange_form_fee_label_included": "Including transaction fee",
  "exchange_form_fee_label_excluded": "Excluding transaction fee",
  "order_view_back_to_vendor_button_label": "Go back to {{providerName}}",
  "table_sort_label": "Sort",
  "pagination_next_page": "Next Page",
  "pagination_previous_page": "Previous Page",
  "pagination_rows_per_page": "Rows per page",
  "pagination_display_rows": "of",
  "button_download_csv": "Download visible as CSV",
  "button_print": "Print",
  "table_view_columns": "View Columns",
  "table_filter": "Filter Table",
  "table_filters_label": "Filters",
  "show_columns_label": "Show Columns",
  "table_rows_selected": "row(s) selected",
  "add_account": "Add account",
  "account_balance": "Guthaben:",
  "account_minimal_balance_label": "Minimal balance:",
  "page_header_accounts": "Accounts",
  "sidebar_navigation_accounts": "Accounts",
  "sidebar_navigation_trade": "Trade",
  "landing_page_chart_widget_buy_button": "Buy",
  "exchange_form_from_amount_label": "Buy for",
  "exchange_form_to_amount_label": "You get",
  "footer_trading_since": "Trading cryptocurrencies since 2013",
  "footer_headers_subscribe_to_newsletter": "Subscribe to our newsletter",
  "footer_rights_reserved": "\u00a9 {{year}} {{companyName}} All rights reserved.",
  "landing_header": "Cryptocurrency exchange trusted since 2013",
  "landing_subheader": "Trading made easy. <br /> Operates over 30 currency pairs.",
  "landing_button_join": "I'm all in",
  "landing_button_about_us": "About us",
  "landing_recent_orders_header": "Recent orders",
  "landing_statistics_30_day_order_count_label": "Number of exchanges made in the last 30 days",
  "landing_statistics_verified_clients_count": "Verified clients",
  "landing_statistics_volume_in_currency": "30 days volume in {{currency}}",
  "landing_header_help": "Help",
  "button_buy_now": "Exchange now",
  "footer_header_support": "Support",
  "footer_link_help": "Help",
  "footer_header_company": "Company",
  "footer_header_features": "Features",
  "footer_features_trezor_support": "Trezor support",
  "footer_features_limit_order": "Limit order",
  "footer_email_newsletter_form_text": "Join our newsletter and never miss anything important happening on {{brandName}}.",
  "landing_email_newsletter_subscribe_button": "Subscribe",
  "landing_email_newsletter_subscribe_field_placeholder": "Enter your email address here",
  "landing_email_subscribe_consent_warning": "By entering your email you agree to our <0>Terms of Services</0> and <1>Privacy Policy</1>",
  "footer_link_transaction_fees": "Transaction fees",
  "footer_link_trade_limits": "Trading limits",
  "add_the_following_comment_to_payment": "F\u00fcgen Sie die folgende Anmerkung zur Zahlung hinzu",
  "alert_error_please_set_password_to_enable_two_factor": "Bitte legen Sie ein Kontopasswort fest, um eine zwei Faktoren Authentifizierung zu erm\u00f6glichen.",
  "alert_success_google_authenticator_disabled_successfully": "Die zwei Faktoren Authentifizierung wurde erfolgreich deaktiviert.",
  "alert_success_google_authenticator_enabled_successfully": "Die zwei Faktoren Authentifizierung wurde erfolgreich aktiviert.",
  "confirmed": "Best\u00e4tigt",
  "created_at": "Erstellt am",
  "enter_form_email_placeholder": "Email",
  "enter_form_password_placeholder": "Passwort",
  "fiat_payments_without_note_cause_delay_warning": "Alle Zahlungen ohne diesen Text werden manuell \u00fcberpr\u00fcft werden m\u00fcssen. Dies kann zu Versp\u00e4tungen bei der Verarbeitung von Auftr\u00e4gen f\u00fchren!",
  "form_label_new_password": "Neues Passwort",
  "incoming_bank_transaction": "Eingehende Banktransaktion ",
  "incoming_crypto_transaction": "Eingehende Kryptotransaktion ",
  "label_2fa_code": "Code",
  "label_account_constant": "Konstante Symbol",
  "label_account_label": "Bezeichnung",
  "label_account_number": "Kontonummer",
  "label_account_specific": "Spezifisch",
  "label_account_vs": "Variables Symbol",
  "label_address": "Adresse",
  "label_address_label": "Bezeichnung",
  "label_addresses": "Adresse",
  "label_affiliate_overview_affiliate_id": "Affiliate ID",
  "label_affiliate_overview_estimated_profit": "Auftr\u00e4ge in Arbeit",
  "label_affiliate_overview_referrals_count": "Eingeladene Nutzer",
  "label_affiliate_overview_url_hits_count": "Banner Klicks",
  "label_amount": "Betrag",
  "label_arbitrary_data": "Ziel-Tag",
  "label_bank_account": "Bankkonto",
  "label_bank_account_id": "Bankkonto",
  "label_bank_accounts": "Bankkonten",
  "label_birthday": "Geburtsdatum",
  "label_city": "Stadt",
  "label_confirmations": "Best\u00e4tigungen",
  "label_country": "Land",
  "label_created_at": "Erstellt am",
  "label_currency": "W\u00e4hrung",
  "label_current_password": "Aktuelle Passwort",
  "label_document_number": "Dokumentnummer",
  "label_expires_at": "G\u00fcltig bis",
  "label_first_name": "Vorname",
  "label_from_amount_currency": "Von",
  "label_hash": "Raute",
  "label_id": "ID",
  "label_in_block_id": "Block ID",
  "label_is_politician": "Politisch exponierte Person",
  "label_issued_by": "Ausgestellt von",
  "label_issued_by_country": "Ausgestellt vom Land",
  "label_last_name": "Nachname",
  "label_limit_price": "Kurslimit",
  "label_message": "Nachricht",
  "label_monthly_volume": "Volumen pro Monat",
  "label_new_password_again": "Noch einmal neues Passwort ",
  "label_note": "Notiz",
  "label_other_account_number": "Bankkonto",
  "label_our_bank_account_id": "{{brandName}} Bankkonto",
  "label_owner_address": "Adresse des Eigent\u00fcmers",
  "label_owner_city": "Stadt des Eigent\u00fcmers",
  "label_owner_country": "Land des Eigent\u00fcmers",
  "label_owner_name": "Name des Eigent\u00fcmers",
  "label_paid_at": "Gezahlt am",
  "label_personal_id": "Pers\u00f6nliche ID",
  "label_phone": "Telefonnummer",
  "label_rate": "Kurs",
  "label_ready_for_verification": "Bereit zur Verifizierung",
  "label_receive_address": "Empfangs-Adresse",
  "label_status": "Status",
  "label_status_direction": "Richtungsstatus",
  "label_status_final": "Endg\u00fcltige Status",
  "label_street": "Stra\u00dfe",
  "label_ticket_body": "Ticket Beschreibung",
  "label_ticket_category": "Kategorie",
  "label_ticket_title": "Betreff",
  "label_to_amount_currency": "Zu",
  "label_trading_purpose": "Handelszweck",
  "label_trading_source_of_income": "Einnahmequelle",
  "label_zipcode": "Postleitzahl",
  "mail_client_identification_bullet_point_fill_personal_information": "Pers\u00f6nliche Informationen eingeben",
  "oops_something_went_wrong": "Oops, etwas ist schief gegangen. Bitte versuchen Sie es sp\u00e4ter noch einmal. ",
  "order_id": "Auftrags ID",
  "outgoing_bank_transaction": "Ausgehende Banktransaktion ",
  "outgoing_crypto_transaction": "Ausgehende Kryptotransaktion",
  "page_header_settings": "Einstellungen",
  "password_was_changed": "Passwort wurde ge\u00e4ndert",
  "settings_updated": "Einstellungen wurden aktualisiert",
  "social_login_with_facebook": "Mit Facebook einloggen",
  "social_login_with_vkontakte": "Mit Vkontakte einloggen",
  "social_signin_with_google": "Mit Google einloggen",
  "social_signin_with_linkedin": "Mit LinkedIn einloggen",
  "status": "Status",
  "status_description_cancelled": "Auftrag wurde storniert",
  "status_description_confirming_incoming": "Auftrag wartet auf Best\u00e4tigung, neue eingehende Transaktion",
  "status_description_confirming_outgoing": "Auftrag wartet auf Best\u00e4tigung, neue ausgehende Transaktion",
  "status_description_delivered": "Auftrag wurde zugestellt",
  "status_description_done": "Auftrag wurde abgeschlossen",
  "status_description_new": "Auftrag wurde erstellt, es ist noch keine Zahlung eingegangen",
  "status_description_proceed": "Der Auftrag wird auf normale Weise durchgef\u00fchrt, so wie er erstellt wurde. Die TO W\u00e4hrung wird Ihnen zugesendet",
  "status_description_received": "Auftrag hat die Bank-/Kryptotransaktion erhalten und ist bereit zum Fortfahren",
  "status_description_return_to_client": "Auftrag ist zur R\u00fcckgabe an den Kunden festgelegt",
  "status_description_returned": "Auftrags-Transaktion wurde an den Kunden zur\u00fcckgegeben",
  "status_description_suspended": "Auftrag wurde unterbrochen. Einige m\u00f6gliche Gr\u00fcnde: Zahlungsfrist ist abgelaufen, Banktransaktions-Informationen sind falsch, monatliches Volumen wurde \u00fcberschritten, eine Verifizierung ist erforderlich oder Sie haben den Auftrag manuell unterbrochen",
  "ticket_category_affiliate": "Affiliate",
  "ticket_category_api": "API",
  "ticket_category_business_verification": "Firmen Verifizierung",
  "ticket_category_order": "Auftr\u00e4ge",
  "ticket_category_other": "Sonstiges",
  "ticket_category_security": "Sicherheit",
  "ticket_category_suggestion": "Vorschlag",
  "ticket_category_verification": "Verifizierung",
  "validation_error_incorrect_email_format": "Incorrect email format",
  "you_have_accepted_current_suspended_deal": "Sie haben den aktuell unterbrochenen Handel akzeptiert",
  "close": "Close",
  "status_on_hold": "On hold",
  "submit": "Submit",
  "resend": "Resend",
  "reviews": "Wrote about us",
  "label_counter_address": "Counter address",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "Send {{amount}} <0>{{currency}}</0> to one of the following bank accounts",
  "order_filter_button_new": "New",
  "order_filter_button_in_progress": "In progress",
  "order_filter_button_finished": "Finished",
  "order_filter_button_custom": "Custom",
  "client_order_status_base_label": "Status",
  "client_order_status_direction_label": "Richtung",
  "client_order_status_final_label": "Endg\u00fcltige Status",
  "ticket_status_solved": "Gel\u00f6st",
  "ticket_status_in_progress": "In Arbeit",
  "ticket_status_waiting_for_admin": "Simplecoin Antwort wird erwartet",
  "ticket_status_waiting_for_client": "Antwort des Kunden wird erwartet",
  "member_of": "Member of",
  "level_one_verification_warning_back_transfer_instead_of_statement": "Please refer to <0>this article</0> for more information about bank account verification",
  "label_bank_account_number": "Bank account number",
  "label_bank_account_details": "Bank account information",
  "validation_max_characters": "Field may only contain up to ${max} characters",
  "verification_required_if_order_made_warning_for_registered_clients": "In order to proceed with this order you will have to increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "verification_required_if_order_made_warning_for_guests": "In order to proceed with this order you will have to sign up and then increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "Please log in or sign up to create an order",
  "get_verified_to_start_trading_warning_message": "You need to increase your verification level in order to start trading. You currently have <strong>level {{currentVerificationLevel}}</strong> and need <strong>level {{requiredVerificationLevel}}</strong> to start trading. Please go to the profile tab and fill in the verification form.",
  "offer_will_expire_in": "This offer expires",
  "offer_has_expired": "This offer has expired. Please create a new one on our partner's site or directly on <0>{{company}}</0>.",
  "order_offer_header": "{{companyName}} exchange offer",
  "verification_required_for_order_creation_partner_order": "It is now required to reach verification level {{level}} on {{brandName}} before processing your order. Please sign up and verify your profile in order to continue.",
  "label_specify_destination_tag": "Important: enter {{destinationTag}} as destination tag.",
  "validation_error_required_characters_exactly": "Field ${path} must be exactly ${length} characters",
  "csv_export_column_from_currency": "Von W\u00e4hrung",
  "csv_export_column_to_currency": "Zur W\u00e4hrung",
  "react_order_crypto_no_fee_info_message": "We do not charge any fees for this order. You will receive full refund.",
  "login_confirmation_form_check_email_paragraph": "A verification code has been sent to your email. Please check your inbox and paste the code below to confirm your login.",
  "login_confirmation_title": "Email verification",
  "login_confirmation_email_resent": "Confirmation email send successfully",
  "login_confirmation_email_not_received": "Did not receive verification email? Please check your spam folder. It can take several minutes.",
  "login_confirmation_code_input_placeholder": "6 digit code",
  "lost_2fa_device_contact_support": "Lost your device? Contact support at {{companyEmail}} or {{companyPhone}} ",
  "higher_verification_limit_required_warning": "Please increase your verification level to see payment instructions",
  "verification_through_onfido_disclaimer": "Identity verification will be performed by Onfido.  ",
  "signup_and_verification_required_warning": "Please <0>sign up</0> and verify your account in order to proceed with your order.",
  "go_to_profile_button": "Go to profile",
  "profile_page_divex_address_ownership_disclaimer_heading": "Wallet security statement",
  "profile_page_divex_address_ownership_disclaimer_message": "Please attach a scan or a photocopy of the declaration of wallet ownership which shows that you are the only one with access to the cryptocurrency wallet that you will use within Divex.",
  "profile_page_divex_address_ownership_disclaimer_warning": "The statement is required to ensure that your advisor does not have access to your cryptocurrency wallet and therefore can not steal your funds in future. </br> If you would like to know more about recommended wallet security, <0>click here</0>.",
  "signup_for_invited_only_divex_message": "Registration is only for Divex exchange clients. </br>\nDo you want to become a client? Contact one of our partners to help you register and set up the entire process. </br>\nThe list of all partners is available <0>here</0>.",
  "divex_address_ownership_disclaimer_upload_label": "Dokument hier beif\u00fcgen",
  "email_verification_token_expired_log_in_again": "Dieser Verifizierungslink ist nicht korrekt oder ist abgelaufen. Bitte melden Sie sich erneut an",
  "pagination_total_visible_rows": "{visibleCount} of {totalCount}",
  "phone_hint_include_prefix": "Bitte geben Sie die Nummer mit Vorwahl an",
  "sidebar_navigation_transactions": "\u00dcberweisungen",
  "account_balance_formatted": "Guthaben: {{Guthaben mit W\u00e4hrung}}",
  "transfer_sent_successfully": "\u00dcberweisung erfolgreich gesendet",
  "cookie_consent_i_agree_to_all": "Alle aktivieren",
  "cookie_consent_i_agree_to_required": "Nur funktionale Cookies aktivieren",
  "divex_affil_find_more": "Find out more about affiliate program in <0>this article.<0/>",
  "button_i_confirm": "I confirm",
  "wallet_ownership_declaration_paragraph_1": "I have been advised to use the Trezor hardware wallet for storage of digital assets and use it with PIN code.",
  "wallet_ownership_declaration_paragraph_2": "I am the owner of the master seed (12-24 words) that I have stored securely. I am aware that the cryptocurrencies in the wallet can be spent using the master seed, even without the device itself.",
  "wallet_ownership_declaration_paragraph_3": "The advisor who was helping me set up my wallet did not have access to my master seed and could not make a copy of it.",
  "page_header_exchanges": "Exchange currencies",
  "sidebar_navigation_dashboard": "Dashboard",
  "dashboard_header": "Dashboard",
  "page_header_transfers": "Transfers",
  "settings_button_general_settings": "General",
  "settings_button_security_settings": "Security",
  "settings_button_email_settings": "Rules for sending emails",
  "settings_button_profile_verification": "Profile verification",
  "action_activate_account_email_sent": "We have sent you an e-mail with link for activation of your password to the e-mail address.",
  "action_activate_account_error_email_not_sent": "The e-mail you have provided does not match account that may be activated this way. Please choose another e-mail.",
  "request_account_activation": "Account activation",
  "account_activation_enter_email_provided_to_support": "Activate your Divex account by entering the email you used to register for the Introduction to Bitcoin or Diverzo Academy course.",
  "account_activate_button": "Activate account",
  "account_activation_password_saved": "Your password has been saved. You can now log in.",
  "breadcrumb_category_order": "Order",
  "breadcrumb_subcategory_order_id": "Order #{{id}}",
  "page_header_general_settings": "General settings",
  "page_header_email_settings": "Rules for sending emails",
  "dashboard_order_table_last_orders": "Last orders",
  "dashboard_order_table_all_orders": "All orders",
  "user_discount": "Your discount",
  "get_discount": "Get discount",
  "volume_discount": "Volume discount",
  "personal_discount": "Personal discount",
  "ticket_status_all": "All",
  "id": "ID",
  "exchange_form_total_fee": "Total fees",
  "exchange_form_out_fee_crypto": "Outgoing transaction fee",
  "exchange_form_out_fee_fiat": "Outgoing bank transfer fee",
  "exchange_form_in_fee_crypto": "Incoming network fee",
  "exchange_form_in_fee_fiat": "Incoming bank transfer fee",
  "support": "Support",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "message": "Message",
  "upload_file": "Upload file",
  "you": "You",
  "page_become_affiliate_partner_header": "Become Simplecoin <0>affiliate</0> partner",
  "page_become_affiliate_partner_bonus": "Recommend our exchange office to people and get a <strong>  commission of <br> up to {{affiliatePercent}}%</strong> of each exchange they make.",
  "page_become_affiliate_partner_involved": "I want to get involved",
  "page_become_affiliate_partner_information": "More information",
  "page_affiliate_dashboard_header": "Affiliate program",
  "page_affiliate_dashboard_current_balance_header": "Current balance",
  "page_affiliate_dashboard_current_balance_withdraw": "Withdraw balance",
  "order_related_support": "Support related to order",
  "page_affiliate_dashboard_total_profit": "Total profit",
  "page_become_affiliate_partner_copy_link_header": "Your affiliate link",
  "page_become_affiliate_partner_bonus_start": "Start of cooperation:",
  "page_become_affiliate_partner_bonus_tip": "<strong> Tip! </strong> Also use pre-made promotional banners in various sizes and motifs.",
  "page_become_affiliate_partner_promotional": "Show promotional materials",
  "document_issued_by_government": "Document issued by government",
  "document_back_side_scan": "Document back side scan",
  "document_front_side_scan": "Document front side scan",
  "document_type": "Document type",
  "scan_quality_warning": "Good quality scans (300dpi) or photos which are fully readable are required. No parts of any document can be edited nor removed.",
  " page_become_affiliate_partner_income_header": "Overview of income",
  "page_become_affiliate_partner_withdrawal_header": "Overview of withdrawal",
  "page_become_affiliate_partner_statistics_header": "Overwiew of statistics",
  "secondary_document": "Secondary document",
  "selfie_with_id": "Selfie with ID card",
  "affiliate_withdraw_breadcrumb": "Withdraw",
  "affiliate_withdraw_header": "Withdraw affiliate balance",
  "affiliate_withdraw_info_alert": "Your affiliate balance is <strong>{{balanceCurrencyEur}}</strong>. You may withdraw it in any of the supported currencies. You can see the converted amount after selecting the currency and recipient. ",
  "bank_account_create_form_header": "Add a new {{currencyCode}} bank account",
  "crypto_address_create_form_header": "Add a new {{currencyCode}} wallet",
  "settings_social_create_password_button": "Set password",
  "recipient_page_table_name": "Name",
  "recipient_page_table_address": "Address / Bank account no.",
  "recipient_page_table_currency": "Currency",
  "recipient_page_table_header": "List",
  "wallet_welcome_page": "You are about to go to <br /> Simplecoin Wallet",
  "wallet_welcome_page_condions": "I agree with the terms and <0>conditions</0> of Simplecoin Wallet",
  "wallet_welcome_page_button": "Continue to Simplecoin Wallet",
  "wallet_welcome_page_benefits_1": "Create a portfolio at Simplecoin",
  "wallet_welcome_page_benefits_2": "Easy deposit of funds",
  "wallet_welcome_page_benefits_3": "Trade cryptocurrencies easier and faster",
  "wallet_welcome_page_benefits_4": "More advantageous shift fees",
  "wallet_welcome_page_benefits_5": "Priority support",
  "verification_transaction": "Verification transaction",
  "verification_call_info": "As part of the verification process, we also need to verify your phone number, so we will call you to confirm that the verification was completed without any problems. You can choose the approximate time that is most convenient for you for the final phone call.",
  "verification_call": "Verification call",
  "when_should_we_call": "When should we call you",
  "asap": "As soon as possible",
  "email": "E-mail",
  "verification_and_limits": "Verifications and limits",
  "profile_verification": "Profile verification",
  "limits": "Limits",
  "start_with_verification": "Start with verification",
  "verification_in_progress": "Verification in progress",
  "level1": "Level 1",
  "done": "Done",
  "ok": "OK",
  "profile_verification_done_info": "We've emailed you the verification payment information in case you want to get back to them. We will let you know by email that you have been verified.",
  "sidebar_navigation_portfolios": "Porfolio",
  "page_dashboard_insert_deposit": "Deposit funds",
  "page_trade_head_title": "Trade",
  "transaction_tabs_title_exchange": "Exchange",
  "transaction_tabs_title_deposit": "Deposit",
  "transaction_tabs_title_withdraw": "Withdraw",
  "transaction_tabs_title_transfer": "Transfer",
  "label_summary": "Summary",
  "increase_limit": "Increase limit",
  "read_only": "Read-only",
  "page_portfolio_header": "Portfolio",
  "amount": "Amount",
  "purpose_of_crypto_trading": "Purpose of trading with cryptocurrencies",
  "what_is_purpose_of_crypto_trading": "What is your purpose of trading with cryptocurencies",
  "get_familiar_with_cryptocurrencies": "Get familiar with cryptocurencies",
  "occasional_buing_selling": "Occasional buying/selling",
  "one_off_investment": "One-off investment",
  "regular_savings": "Regular savings",
  "other": "Other / Sale of cryptocurrency",
  "speculations": "Speculations",
  "describe_your_purpose_of_cryptotrading": "Describe your purpose of trading with cryptocurrencies",
  "today": "today",
  "morning": "morning",
  "evening": "evening",
  "afternoon": "afternoon",
  "tomorrow": "tomorrow",
  "proof_of_income": "Proof of income",
  "proof_of_income_info": "The Anti-Money Laundering and Anti-Terrorism Financing Act (AML Act) requires to verify the source of your income if you want to trade with higher amounts. We therefore need to ask you some questions about the origin of the money you want to trade with us.",
  "income": "Income",
  "one-off_income": "one-off income",
  "monthly_income": "monthly income",
  "quarterly_income": "quarterly income",
  "yearly_income": "yearly income",
  "frequency": "Frequency",
  "source_of_income": "Source of income",
  "employment": "Employment",
  "position_in_company": "Position in company",
  "company_name": "Company name",
  "running_own_business": "Running own business",
  "rental": "Rental",
  "share_of_profit": "Share of profit",
  "pension": "Pension",
  "documents_supporting_the_income": "Documents supporting the income",
  "income_source_description": "Description",
  "add_income": "Add next income",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Proof of basic income",
  "proof_of_basic_income_info": "In order to set at least a basic trading and deposit limit, we need to know if you have at least a minimum income. There is no problem to increase this basic limit later if you want to trade \"at full throttle\". Even if you don't have that income, it is still OK. Later you will describe the source of the funds you want to trade with and we will set the limit accordingly.",
  "step": "Step",
  "basic_income_agreement": "I confirm that my income corresponds to at least 600 EUR per month",
  "semi-yearly_income": "semi-yearly income",
  "incomes": "Incomes",
  "income_state_submited": "awaits approval",
  "income_state_approved": "approved",
  "income_state_new": "new",
  "updating_incomes_info": "Any modification of your incomes (adding a new income or deleting an existing one) must be assessed by us and we will adjust your trading limit accordingly.",
  "submit_for_approval": "Submit for approval",
  "income_state_unapproved_change": "unapproved change",
  "prooved_incomes": "Prooved incomes",
  "verification_and_incomes": "Verification and incomes",
  "trading_limits": "Trading limits",
  "current_allowed_trading_amount": "Allowed amount for trading",
  "current_allowed_trading_amount_info": "This is the maximum amount you can right now deposit into Simplecoin Wallet or exchange in Simplecoin Exchange. This amount changes over time depending on how often you trade. It can gradually decrease to zero or increase back to your original limit. Only paid orders and confirmed deposits counts.",
  "individual_limits": "Individual limits",
  "time_period": "Time period",
  "limit_extension": "Limit extension",
  "remaining_limit_amount": "Remaining amount you can still draw down",
  "state_of_limit_execution": "State of limit execution",
  "in_time_period": "in",
  "individual_trading_limits_info": "If you have more individual limits, you must meet all of them. The one that is currently the most restrictive is always applied.",
  "trading_limit_extension_info": "If the standard limit is not enough for you, we can increase your limit even further by providing proof of one-off income. We will gradually reduce this limit whenever you trade above the standard limit.",
  "income_state_deleted": "deleted",
  "account_update_success": "Account was updated",
  "account_label_edit": "Edit account",
  "account_action_archive": "Archive account",
  "waiting_for_payment": "waiting for payment",
  "waiting_for_payment_approval": "waiting for payment approval",
  "verification_transaction_info": "For successful remote identification, you will need to verify at least one of your bank accounts (in your name). For this we only need to receive a single euro from this account.",
  "verification_transaction_received_info": "The verification transaction has been received. All we have to do is verify that it came from your bank account.",
  "verification_transaction_approved_info": "Your bank account has been verified. So we can skip this step.",
  "export_to_csv": "Export to CSV",
  "new_account_button": "Create new Account",
  "remove_recipient": "Remove recipient",
  "recipient_detail": "Recipient detail",
  "account_label": "Account label",
  "veriffied": "veriffied",
  "not_verified": "not verified",
  "label_create_trade": "Create trade",
  "remove_income": "Remove income",
  "transaction_detail_header": "Transaction detail",
  "transaction_type": "Type",
  "trade": "Trade",
  "withdraw_label_about": "You are about to withdraw",
  "withdraw_summary_fees": "Summary and fees",
  "Choose-destination-bank-account": "Choose destination bank account",
  "wallet_account_destination": "Chose destination {{currencyName}} address",
  "account_unique_name_label": "Provide a unique name of this account",
  "account_note_label": "You can add any additional note here",
  "account_detail_suspend_alert": "Your account is suspended. Please email us for support.",
  "current_balance_label": "Current balance",
  "balance_blocked_label": "Blocked balance",
  "label_not_choosen_account": "You do not have an account selected",
  "label_not_enough_money": "You have insufficient funds",
  "label_set_all_funds": "Set all funds",
  "label_available_balance": "Available balance",
  "label_withdraw": "Withdraw",
  "account_label_description": "Account description",
  "label_balance_after_transaction": "Balance after transaction",
  "label_fee": "Fee",
  "label_counter_party": "Counterparty",
  "label_operation_confirm_withdraw": "Confirm your withdrawal",
  "label_operation_confirm_transfer": "Confirm your transfer",
  "label_operation_confirm_exchange": "Confirm your exchange",
  "label_no_account_this_currency": "You do not have an account in this currency",
  "transaction_info_acc_head": "Amount and currency",
  "label_from_account": "From account",
  "label_target_account": "Target account",
  "exchanges_balances_alert_fiat": "Conversion between non-crypto is currently not possible",
  "account_create_success": "Account was created",
  "withdraw_label_requests": "Withdraw requests",
  "operations_label_dashboard_header": "Operations",
  "label_currency_general": "Base currency",
  "income_state_draft": "unverified",
  "label_client_currency_balance": "Balance in base currency",
  "label_show_all": "Show all",
  "dashboard_balances_last_transactions": "Latest transactions",
  "dashboard_balances_no_accounts": "You don't have an account yet",
  "dashboard_balances_no_withdrawal_requests": "No withdrawal requests",
  "dashboard_balances_no_transaction": "You don't have transactions yet",
  "general_settings_label_base_currency": "This currency will be used as default throughout the app",
  "general_settings_header_base_currency": "Base currency",
  "page_portfolio_closed_suspend_accounts_header": "Suspended and canceled Accounts",
  "confirm_withdraw_button": "Withdraw",
  "button_withdraw_continue_to_confirmation": "Continue",
  "recipient_name": "Recipient name",
  "button_create_transfer": "Create transfer",
  "button_transfer_continue_to_confirmation": "Continue",
  "button_exchange_continue_to_confirmation": "Continue",
  "total_balance": "Total balance",
  "no_trading_limit_extension": "There is no extension of the trading limit.",
  "deposit_component_automatic_create_acc_label": "You do not have an account at Simplecoin in this currency yet. We'll set it up for you automatically.",
  "deposit": "Deposit",
  "withdraw": "Withdraw",
  "create_default_account_label": "Default account for",
  "create_default_account_description": "Automatically created account when depositing funds",
  "create_default_account_button": "Create default {{currencyCode}} account",
  "dashboard_blog_posts_header": "Last blog posts",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "Credited amount",
  "exchange_rate": "Exchange rate",
  "accounting_time": "Accounting time",
  "date": "Date",
  "amounts": "Amounts",
  "success_order_header": "What's next? Pay for your order to complete.",
  "success_order_email": "We have sent you payment instructions by email <0>{{email}}</0>.",
  "finop_type_DEPOSIT": "deposit",
  "finop_type_EXCHANGE": "exchange",
  "finop_type_TRANSFER": "transfer",
  "finop_type_WITHDRAWAL": "withdraw",
  "exchange_made": "Exchange successful",
  "from_account": "From account",
  "to_account": "To account",
  "accounts": "Accounts",
  "portfolio_balance_summary_fiat_header": "Fiat:",
  "portfolio_balance_summary_crypto_header": "Cryptocurrency:",
  "order_success_check_crypto": "Please check if your wallet address is correct.",
  "success_order_label_id": "Your order number is <0>{{order_id}}</0> created. Thank you!",
  "exchange_form_crypto_address_field_label": "Cryptocurrency address",
  "order_success_check_bank": "Please check if the bank account is correct.",
  "other_currencies": "Other currencies",
  "no_funds_in_portfolio": "You have no funds in your portfolio now. Deposit fiat or crypto funds into your accounts and then you can start trading immediately.",
  "exchange_form_register": "for higher limits.",
  "list": "List",
  "withdrawal_request_status_new": "Pending",
  "withdrawal_request_status_confirming_outgoing": "Dispatched",
  "withdrawal_request_status_done": "Done",
  "withdrawal_request_status_canceled": "Cancelled",
  "landing_main_header": "<0>The biggest</0> cryptocurrency exchange in Czechia",
  "next_landing_subheader": "Exchanging cryptocurrencies <0>since 2013</0>.",
  "header_link_about_us": "About us",
  "header_link_get_started": "Get started",
  "header_link_blog": "Blog",
  "header_service_hours": "Mo-So 9-21 Uhr",
  "buy_now_button": "Buy now",
  "chart_header": "Buy and sell",
  "exchange_form_label": "Buy and sell",
  "how_it_works_paragraph_1_content": "It only takes a minute to place an order. Up to 200 \u20ac / 10 days, all we need is your email and cryptocurrency wallet address. From higher amounts you will need to register and get verified.",
  "how_it_works_paragraph_1_heading": "Order",
  "how_it_works_paragraph_2_heading": "Pay",
  "how_it_works_paragraph_3_heading": "Receive cryptocurrency",
  "how_it_works_paragraph_2_content": "Once your order is complete, we will provide you with payment instructions.",
  "how_it_works_paragraph_3_content": "Upon receiving of your payment, we will send the cryptocurrency to your wallet. We can usually do it within 30 minutes!",
  "landing_support_section_paragraph": "Need help with exchanging cryptocurrencies or using the Trezor hardware wallet? Contact us anytime via email, phone, or visit our client center for personalized assistance.",
  "landing_support_section_heading": "Real people, real customer support",
  "contact_us_button": "Contact",
  "landing_advantages_1_heading": "Simple and safe cryptocurrency trade",
  "landing_advantages_1_text": "In only takes a minute to create an order. And once the funds are credited to our account, we will manually verify the transaction and send you the cryptocurrency within 30 minutes on average.",
  "landing_advantages_2_heading": "Real people, real customer support",
  "landing_advantages_3_heading": "Biggest and oldest exchange in Czechia",
  "landing_advantages_2_text": "Need advice on exchanging cryptocurrencies? You can always write, call or send us a ticket. We handle most requests promptly.",
  "landing_advantages_3_text": "We have been exchanging cryptocurrencies since 2013. We have exchanged cryptocurrencies for more than 21 thousand people with a total value of over 8 billion CZK. We are one of the founding members of <0>\u010cKMA</0>.",
  "ckma_founder": "Founding member of",
  "newsletter_field_placeholder": "Your e-mail",
  "footer_sub_header_newsletter_subscription": "Sign up for news",
  "footer_link_career": "Career",
  "footer_link_affiliate_program": "Affiliate program",
  "footer_link_privacy_notice": "Privacy",
  "footer_link_terms_and_conditions": "Terms and conditions",
  "footer_link_contact": "Contact",
  "footer_link_limits_and_verification": "Limits and account verification",
  "footer_link_faq": "Frequently asked questions",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "We have exchanged over 8 billion Czech Crowns since 2013.",
  "landing_bottom_section_header_trade_easily": "Trade coins with ease",
  "landing_bottom_section_button_buy_coins": "Buy cryptocurrency",
  "how_it_works_header": "How does it work?",
  "landing_wrote_about_us_header": "Wrote about us",
  "landing_last_orders_header": "Last transactions",
  "header_faq": "Frequently asked questions",
  "faq_general_questions_header": "General",
  "log_in_button": "Log in",
  "header_button_buy_crypto": "Buy cryptocurrency",
  "header_short_button_buy": "Buy",
  "search_button": "Search",
  "header_support": "Support",
  "send_button": "Send",
  "email_subscribe_header": "Subscribe for news from the cryptocurrency world",
  "affiliate_advantage_payout_anytime": "Payout possible at any moment",
  "affiliate_advantage_banner": "Banners for your website or blog",
  "affiliate_advantage_referral_link": "Own referral link",
  "affiliate_advantage_fiat_or_crypto": "Get paid in crypto or fiat currency",
  "affiliate_advantage_cookie": "Cookies for {{affiliateCookieValidityDays}} days",
  "affiliate_advantage_reward_from_margin": "{{affiliateMarginReward}}% reward from our margin",
  "affiliate_header": "Affiliate program",
  "affiliate_subheader": "Become a Simplecoin affiliate partner and get commission from every exchange done by people you brought.",
  "header_earn_with_us": "Earn with {{brand}}",
  "create_account_button": "Sign up",
  "affiliate_how_it_works_header": "How does it work?",
  "affiliate_how_it_works_step_1_header": "Create an account and sign up for our affiliate program",
  "affiliate_how_it_works_step_2_header": "A unique link will be created for you",
  "affiliate_how_it_works_step_3_text": "Every person who comes to the Simplecoin site via your link receives a cookie. If they create an order within 7 days, you will earn a profit of {{affiliateMarginReward}}% of our margin. You will earn these returns for every order he trades with us. For orders created after the 7 day limit, the link is no longer active.",
  "affiliate_how_it_works_step_2_text": "You have a URL and banners that you can share on your website, blog, social media or with your friends. Anyone who uses this link and then signs up or creates an order within 7 days will be entered into your affiliate program for two years.",
  "affiliate_how_it_works_step_1_text": "Both registration and verification are required to join the affiliate program. Registration allows you to access the administration and manage your account. Verification is required for payment of rewards. We follow good manners and do not encourage unfair practices or dealings. We expect the same from our partners.",
  "affiliate_how_it_works_step_4_text": "You can view how much you have earned at any time in your affiliate program administration. When you feel like it, you can choose the amount in crypto or fiat currency. We will send the payment to your bank account or crypto wallet.",
  "affiliate_how_it_works_step_3_header": "You will receive {{affiliateMarginReward}}% from our profit margin for every customer you bring",
  "affiliate_how_it_works_step_4_header": "You can withdraw your earnings at any time",
  "limits_and_verification_header": "Limits and verification",
  "limits_and_verification_subheader": "For legal reasons, like all other exchanges, we must <0>verify every transaction above a certain limit.</0> If this applies to you, you can easily register and have your account verified. See the boxes below for information on limits and verification.",
  "limits_table_level_0_header": "up to {{limitLevel0Eur}} \u20ac",
  "limits_table_level_0_subheader": "per {{limitLevel0DurationDays}} days",
  "limits_table_registration": "Compulsory registration and identification",
  "limits_table_row_2_documents_photo": "Photo of 2 personal documents",
  "limits_table_row_selfie": "Selfie with document",
  "limits_table_row_verification_bank_transfer": "Verification bank transfer",
  "limits_table_row_verification_phone_call": "Verification phone call",
  "limits_table_row_purpose_od_crypto_purchase": "Purpose of the purchase",
  "limits_table_row_source_of_income": "Source of income",
  "limits_table_row_individual_limits_base_on_income_source": "Individual limits based of source of income",
  "limits_table_level_1_header": "{{limitLevel0Eur}} \u2014 {{limitLevel1Eur}} \u20ac",
  "limits_table_level_1_subheader": "per {{limitLevel1DurationDays}} days",
  "limits_table_level_2_subheader": "per {{limitLevel2DurationDays}} days",
  "limits_table_level_2_header": "over {{limitLevel2EurStart}} \u20ac ",
  "footer_mobile_navigation_expand_label": "Site navigation",
  "support_search_placeholder": "What would you like to know?",
  "error_404_header": "We couldn't find that page",
  "error_404_subheader": "But you can continue to...",
  "error_page_contact_us": "If you think this is a mistake, write us on {{supportEmail}}. Thank you!",
  "link_blog_label": "What's new on Simplecoin",
  "link_contact_label": "Contact us",
  "link_faq_label": "Find answers here",
  "about_page_recommendations_section_header": "Recommended by",
  "cookie_dialog_text_content": "{{brand}} uses cookies to make using our services more comfortable and to constantly improve user experience. You can can choose which types of cookies will be used below. For more information read our <0>cookie policy</0>",
  "cookie_dialog_necessary_cookies_header": "Necessary cookies",
  "cookie_dialog_necessary_cookies_text": "Cookies which are needed for the functioning of the website",
  "cookie_dialog_necessary_cookies_always_active": "Always active",
  "cookie_dialog_statistical_cookies_header": "Statistical cookies",
  "cookie_dialog_statistical_cookies_text": "Cookies which help us measure and understand how you use our services",
  "cookie_dialog_marketing_cookies_header": "Marketing cookies",
  "cookie_dialog_marketing_cookies_text": "Cookies that help us in some way",
  "cookie_dialog_personalization_cookies_header": "Personalization cookies",
  "cookie_dialog_personalization_cookies_text": "Cookies thanks to which you will not need to set some things twice",
  "cookie_dialog_accept_all_button": "Accept all",
  "cookie_dialog_confirm_choices_button": "Confirm my choices",
  "cookie_dialog_header": "Your cookie preferences",
  "support_label_breadcrumbs_navigation": "Help",
  "buy_currency_button": "Buy {{currencyName}}",
  "exchange_form_get_wallet_help": "No wallet?",
  "scan_address_qr_code": "Scan QR",
  "exchange_form_crypto_address_field_placeholder": "bc1...",
  "order_form_email_and_account_step_label": "Order information",
  "order_form_amounts_step_label": "Amount and currency",
  "order_form_header": "Order",
  "landing_login_dialog_title": "Introducing Simplecoin Pro. A new platform with lower fees and instant currency exchanges. No need for a crypto wallet.\n",
  "landing_login_dialog_pro_header": "Try Simplecoin Pro",
  "landing_login_dialog_exchange_header": "Go to Simplecoin Exchange",
  "landing_login_dialog_exchange_text": "Simple crypto exchange service you know and love",
  "landing_login_dialog_pro_text": "Try Pro for even better trading experience",
  "landing_login_dialog_pro_login_button": "Log in to Pro",
  "landing_login_dialog_exchange_login_button": "Log in to Exchange",
  "about_us_section_small_header": "About us",
  "about_us_section_1_header": "We make the world of buying or selling <0>Bitcoin</0> and other digital currencies <0>easier</0>.",
  "about_us_section_1_subheader": "The world of cryptocurrencies erases boundaries and brings you real freedom and a new perspective on finance.",
  "about_us_section_2_header": "We are cryptocurrency experts",
  "about_us_section_2_subheader": "We help put money back to regular people's hands",
  "about_us_section_2_content": "Simplecoin is first and foremost a technology company that relies on the expertise of our programmers. We invest time and attention specifically in a high standard of security for your client accounts and data.",
  "about_us_section_3_header": "We've been making it easy for people to buy cryptocurrencies <0>since 2013</0>",
  "about_us_section_3_text": "From the very beginning, we have been a purely Czech company with a clear ownership structure. Anyone can trade cryptocurrencies. We bring another possibility of working with finances that are not managed by banks and the state. We bring you options that you manage yourself. We show people that the cryptocurrency world is simple.",
  "about_us_section_4_header": "Modern technology and security",
  "about_us_section_4_subheader": "We take care of the high security of user accounts",
  "about_us_section_4_text": "Simplecoin is primarily a technology company. We base our experience on the expert knowledge of our programmers. We invest time and attention in a high level of security for our clients' accounts and data.",
  "contact_header": "Get in touch",
  "contact_page_email_label": "Email",
  "contact_page_phone_label": "Phone",
  "contact_page_customer_center": "Customer center",
  "contact_page_billing_info": "Billing information",
  "support_search_result_count": "{{resultsCount}} results",
  "limits_table_item_not_needed": "No",
  "limits_table_item_required": "Required",
  "how_to_get_started_step_1_name": "Step 1",
  "how_to_get_started_step_1_header": "You need a crypto wallet",
  "how_to_get_started_step_1_cta": "How to set up a crypto wallet",
  "how_to_get_started_step_1_text": "The wallet is used to store cryptocurrencies. You will need it when making a purchase. If you don't have a wallet, you can download it as an app on your mobile phone or computer. We'll show you how. ",
  "how_to_get_started_step_2_name": "Step 2",
  "how_to_get_started_step_2_header": "Sign up",
  "how_to_get_started_step_2_subheader": "It has its advantages",
  "how_to_get_started_step_2_text": "You will need an email address and a secure password. You can place an order without registering, but you will lose the volume discount and the ability to manage your order in your user account.",
  "how_to_get_started_step_3_name": "Step 3",
  "how_to_get_started_step_3_header": "Create an order",
  "how_to_get_started_step_3_subheader": "Now it only takes a minute",
  "how_to_get_started_step_3_text": "Fill out the order form - you already have everything you need (email address and crypto wallet address).  We will send you payment instructions by email. Once we have verified the funds have been credited, we will send you the desired currency. We can usually do this within 30 min.",
  "how_to_get_started_step_3_hint": "Pay attention to payment instructions. Remember to insert the text in the message to the recipient as instructed. This will help us process your order faster.",
  "how_to_get_started_step_4_name": "",
  "how_to_get_started_step_4_header": "",
  "how_to_get_started_step_4_subheader": "",
  "how_to_get_started_step_4_text": "",
  "how_to_get_started_step_4_cta": "",
  "landing_clients_since_2013": "clients since 2013",
  "landing_monthly_volume": "of volume in the last 30 days ",
  "landing_exchanges_per_month": "exchanges in the last 30 days",
  "cookie_bar_header": "<0>Simplecoin</0> uses cookies to function correctly",
  "cookie_bar_text": "We use cookies to for login as well as navigation and traffic analysis. Thank you for your consent to their use. Select your cookies.",
  "cookie_bar_agree_and_continue_button": "Agree and continue",
  "cookie_bar_manage_cookie_settings_button": "Cookie preferences",
  "order_detail_info_header": "Order details",
  "order_detail_order_id": "Order number",
  "order_detail_created_at": "Created at",
  "order_detail_status": "Status",
  "order_detail_rate": "Order rate",
  "order_detail_button_cancel": "Cancel",
  "order_detail_button_suspend": "Suspend",
  "order_detail_button_download": "Download in PDF",
  "order_detail_button_new_ticket": "Create a ticket",
  "order_detail_history_table_header": "Order history",
  "order_history_datetime_column_name": "Date and time",
  "order_history_status_column_name": "Status",
  "order_detail_outgoing_payment_label": "Outgoing payment",
  "order_detail_incoming_payment_label": "Your payment",
  "order_detail_incoming_payment_waiting_for_transaction": "Your transaction has not been detected yet",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "We are waiting for your payment",
  "order_payment_instructions_header": "Send payment with the following details",
  "order_form_payment_instructions_bank_account_number_label": "Bank account number",
  "order_form_payment_instructions_bank_amount_label": "Amount",
  "order_form_payment_instructions_bank_message_label": "Payment message",
  "order_form_payment_instructions_use_qr": "Or scan the following QR code",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Company account",
  "order_status_done": "Done",
  "order_status_delivered": "Delivered",
  "order_status_canceled": "Canceled",
  "order_status_returned": "Returned",
  "order_status_in_progress": "In progress",
  "order_status_confirming_incoming": "Confirming incoming",
  "order_status_confirming_outgoing": "Confirming outgoing",
  "order_status_new": "New",
  "order_status_suspended": "Suspended",
  "order_status_received": "Received",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "Your transaction has not been detected yet",
  "show_payment_instructions_dialog_opener_button": "Show payment instructions",
  "support_section_header": "Did not find the answer?",
  "support_section_text": "The customer service line can be reached Monday to Sunday between 9:00 and 21:00. Or send us an email. Our colleagues will always be happy to advise you and guide you safely through your purchase.",
  "label_crypto_sent_to_address": "Address",
  "label_transaction_id": "Transaction ID",
  "order_detail_button_proceed": "Proceed",
  "label_amount_and_currency": "Amount",
  "label_payment_sent_at": "Created",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "Incoming transaction is being confirmed",
  "order_detail_outgoing_payment_order_suspended": "Order is suspended, now waiting for your decision. ",
  "order_detail_outgoing_payment_order_return_to_client": "Your payment will be returned",
  "order_form_payment_instructions_company_billing_details": "Billing information",
  "order_has_been_created": "Order successfully created",
  "fiat_payment_instructions_amount_label": "Amount",
  "order_detail_button_return": "Return",
  "order_view_original_price_label": "Previous offer",
  "order_view_limit_price_label": "Limit price",
  "order_view_current_price_label": "Current offer",
  "cryypto_payment_instructions_amount_label": "Amount",
  "payment_instruction_hint_fill_amount_to_show_qr": "Fill amount filed to generate QR code",
  "deposit_payment_instruction_amount_label": "Amount to deposit",
  "form_email_field": "Email",
  "confirm_button": "Confirm",
  "email_placeholder": "Email",
  "change_in_24hours": "Change in the last 24 hours",
  "order_offer_amounts_label": "Your offer",
  "order_offer_your_crypto_address": "Your cryptocurrency address",
  "order_offer_continue_to_login": "Continue with {{companyName}} account",
  "order_offer_or_separator": "or",
  "order_offer_continue_without_registration": "Continue without registration",
  "order_offer_validity_notice": "This offer will expire in <strong>{{timeRemaining}}</strong>. Please log in or continue with email. ",
  "order_offer_continue_as_guest": "Continue as guest",
  "order_offer_existing_email_please_click_continue_button": "This email is already registered. Please choose the \"Continue with {{companyName}} account\" option",
  "landing_header_references": "References",
  "crypto_payment_instructions_amount_label": "Amount",
  "crypto_payment_instructions_destination_tag_field_label": "Destination tag",
  "order_form_mail_help_text": "We will send you instructions for completing the order to this e-mail",
  "validation_required_email": "Enter your e-mail address",
  "validation_required_crypto_account": "Enter your crypto account",
  "landing_help_section_header": "Hier finden Sie Antworten auf die am h\u00e4ufigsten gestellten Fragen. Neben den schriftlichen Anleitungen stehen wir Ihnen auch unter der Telefonnummer +420 608 746 753 oder per E-Mail unter info@simplecoin.eu zur Verf\u00fcgung, und zwar montags bis sonntags von 9 bis 21 Uhr. Wir begleiten Sie bei Ihrem ersten Einkauf, helfen Ihnen bei der Bestellung oder Registrierung, beraten Sie bei der Einrichtung Ihrer Brieftasche oder beantworten Ihre Fragen. Sie k\u00f6nnen uns einfach alles fragen.",
  "guest_exchange_form_limit_alert": "Purchase without registration is possible up to {{code}} {{limit}}. For higher amounts, please log in or register.",
  "exchange_form_email_label": "Your e-mail",
  "exchange_form_alert_change": "The exchange between these currencies cannot be traded. Please adjust your currency selection.",
  "welcome_in_wallet": "Welcome in Wallet!",
  "start_with_funding_text_2": "You have been verified. Now you can deposit funds and start trading.",
  "start_with_funding_text_1": "Well done!",
  "amount_in_fiat_payment_instructions_info": "The amount is required to generate the QR code",
  "limit_table_level_0_text": "You can buy up to \u20ac{{limitLevel0Eur}} within 10 days without registration and without need to verify personal documents.",
  "label_go_to_order": "Create Order",
  "label_register": "Register",
  "account_detail": "Account detail",
  "signup_form_subtitle": "You will get a better rate and higher trading limits. You will have an overview of your transactions.",
  "label_guest_login": "If you already have an account <0>Login</0>",
  "label_guest_signup": "If you don't have an account yet, <0>sign up</0>",
  "label_register_success": "Registration was successful",
  "label_register_success_verify": "Please activate your account now",
  "label_register_success_sended_verify": "We have sent you a link to activate your account to the e-mail {{mail}}.",
  "label_account_notes": "Account notes",
  "deposit_request_card_head": "Deposit requests",
  "dashboard_balances_no_deposit_requests": "No deposit requests",
  "deposit_request_status_pending": "Pending",
  "exchange_form_terms_must_be_accepted": "Terms and conditions should be accepted",
  "validation_required_bank_account": "Enter your bank account number",
  "order_status_dispatched": "Dispatched",
  "order_status_paid": "Paid",
  "order_status_paidUnconfirmed": "Paid (1/2)",
  "order_status_tooltip_new": "The order is waiting to be paid.",
  "order_status_tooltip_paidUnconfirmed": "We see an incoming transaction. It is waiting to be confirmed on the block-chain.",
  "order_status_tooltip_paid": "The order has been paid. We're working on dispatching the ordered currency.",
  "order_status_tooltip_paid_suspended": "The order has been paid.",
  "order_status_tooltip_dispatched": "We have dispatched the ordered currency to the target address/account. We are waiting for the confirmation on the block-chain/bank.",
  "order_status_tooltip_done": "The order is successfully processed.",
  "order_status_tooltip_canceled": "The order has been canceled.",
  "suspended_order_tooltip": "Order processing is suspended.",
  "order_detail_outgoing_payment_waiting_to_be_sent": "We are working to dispatch the ordered funds as soon as possible.",
  "exchange_limit_for_verification_level_2_text": "Your trading limit is not formally set. For large amounts, it will be assessed individually according to your <0>documented income</0>.",
  "your_limit_is": "Your limit is",
  "trading_limit": "Trading limit",
  "exchange_trading_limit_info": "The limit is automatically adjusted after we receive payment for your order. New unpaid orders do not affect the limit.",
  "you_have_no_suitable_wallet_among_recipients": "You have no wallet for {{currencyCode}} currency among your recipients.",
  "you_have_no_suitable_account_among_recipients": "You have no bank account for {{currencyCode}} currency among your recipients.",
  "rate_when_order_created": "Rate when order created",
  "order_status_proceed": "Proceed processing",
  "waiting_for_limit": "Waiting for limit",
  "button_more": "More ...",
  "exchange_form_expand_optional_fields": "Expand optional fields",
  "return_fiat_payment_info": "We have received your payment already. You have decided to get it back. But return payment has additional fee. You can get {{amount}} {{currency}} back to original bank account.",
  "cancel_order_and_return_payment": "Cancel order and return payment",
  "remaining_limit": "Remaining",
  "trading_limit_reached": "You have reached your trading limit for given period.",
  "attached_files": "Attached files",
  "there_are_no_files": "There are no uploaded files",
  "why_we_need_verification_info": "The anti money laundering law (AML) requires us to know our clients better. Therefore, it is necessary for you to tell us some information about yourself, which of course remains safely stored only with us.",
  "proceed_to_verification_action": "Proceed to verification, please",
  "verification_request_line2": "There are a few steps in our guide and then you can make full use of our wallet.",
  "verification_request_line1": "Now we need to get to know each other a little bit",
  "document_issued_by_government_info": "Please attach a government-issued document confirming the information provided in the previous step.",
  "secondary_document_info": "Please attach another document that confirms the information provided in the first step.",
  "selfie_with_id_info": "Please attach a photo of you holding the personal document  in your hand. The details on the document must be legible and your face must be visible.",
  "understood_button": "Understood",
  "no_prooved_incomes_info": "There is no proved income yet. You can still trade cryptocurrencies up to the base limit (10.000 EUR/month for verified clients). To increase the trading limit above 10 000 EUR/month, you will need to provide proof of income.",
  "verified": "Verified",
  "payment_instructions": "Payment instructions",
  "make_payment_to_one_of_our_accounts": "Make payment to one of our accounts",
  "payment_in": "Payment in",
  "iban_payment_instructions": "IBAN payment instructions",
  "new_price_is_being_loaded": "New price is being loaded...",
  "price_refresh_timer_tooltip": "We update the displayed price and rate for you every few seconds.",
  "send_to_address": "Send to address",
  "payment_specific_data": "Payment data",
  "bank_account_owner": "Bank account owner",
  "filter_all_currencies": "All currencies",
  "ticket_detail": "Ticket detail",
  "last_verification_step_warning": "This is the last step of the verification process. Once you have submitted it, you will no longer be able to change the information you have entered. Your profile will be verified by our back-office staff.",
  "exchange_form_limit_alert": "The order of this amount already exceeds your allowed trading limit {{limit}} {{code}}. You can still create the order, but it will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "order_limit_alert": "Once you will pay for this order, you will exceed your allowed trading limit and the order will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "remove_recipient_confirmation_question": "Do you really want to delete this recipient? You will not be able to undo this action.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Field '{{fieldName}}' is required",
  "inheritance": "Inheritance",
  "relationship_to_testator": "Relationship to testator",
  "inherited_from": "Inherited from (name)",
  "landing_how_to_get_started_button": "How to get started",
  "affiliate_partners_must_be_verified": "To become our affiliate partner, we first need to grab a few information about you.",
  "regular_income": "Regular income",
  "tax_declaration": "Tax declaration",
  "annual_report": "Annual report",
  "date_of_acceptance": "Date of acceptance",
  "document_confirming_acceptance_of_inheritance": "Document confirming acceptance of inheritance",
  "financial_closing_statement": "Financial closing statement",
  "decision_on_sharing_profit": "Decision on sharing profit",
  "sale_contract": "Sales contract",
  "real_estate_registry_extract": "Real estate registry extract",
  "typical_income_attachments": "Typical attachments",
  "sale_of_immovable_property": "Sale of immovable property",
  "address_of_property": "Address of property",
  "date_of_sale": "Date of sale",
  "sale_channel": "Sales channel / Way of sale",
  "paycheck": "Paycheck",
  "employment_contract": "Employment contract",
  "field_of_business": "Field of business",
  "company_id": "Company ID",
  "monetary_donation": "Monetary donation",
  "donor": "Donator",
  "donor_relationship_to_recipient": "Relationship of donor to recipient",
  "purpose_of_donation": "Purpose of donation",
  "source_of_donated_funds": "Source of donated funds",
  "email_subscribe_success": "You have successfully subscribed to our newsletter.",
  "landing_index_meta_tag_title": "Simplecoin - The largest cryptocurrency exchange in the Czech Republic",
  "landing_index_meta_tag_description": "Trusted since 2013",
  "landing_how_to_get_started_meta_tag_title": "Simplecoin - How to start buying cryptocurrencies",
  "landing_how_to_get_started_meta_tag_description": "A simple step-by-step guide for everyone",
  "landing_about_meta_tag_description": "We are cryptocurrency experts",
  "landing_about_meta_tag_title": "Simplecoin - About us",
  "landing_faq_meta_tag_description": "What do I need to buy cryptocurrencies? What do I gain by signing up?",
  "landing_faq_meta_tag_title": "Simplecoin - FAQ",
  "landing_support_meta_tag_title": "Simplecoin - Help",
  "landing_support_meta_tag_description": "Detailed instructions on how to set up a wallet, place an order or set up a DCA",
  "landing_tos_meta_tag_description": "Fair trading with cryptocurrencies",
  "landing_tos_meta_tag_title": "Simplecoin - Terms and conditions",
  "landing_privacy_meta_tag_title": "Simplecoin - Privacy Policy",
  "landing_privacy_meta_tag_description": "Your data is safe with us",
  "landing_affiliate_meta_tag_description": "Become a Simplecoin partner and earn commission",
  "landing_affiliate_meta_tag_title": "Simplecoin - Affiliate Program",
  "landing_limits_meta_tag_description": "Purchase options without registration and when you need to be verified",
  "landing_limits_meta_tag_title": "Simplecoin - Limits and Verification",
  "landing_contact_meta_tag_description": "Book a personal appointment at our client centre or take advantage of our client support 9am-9pm every day of the week.",
  "landing_contact_meta_tag_title": "Simplecoin - contact",
  "react_new_app_version_available_dialog_content": "A new version is available. Please refresh the page to ensure you're using the latest version.",
  "wallet_recipient_warning_1": "Anyone who knows the access secrets to the crypto-wallet (especially the recovery seed - 12-24 words used to recover the wallet) can freely use the funds in this wallet.",
  "wallet_recipient_warning_2": "If the wallet was created by someone else than myself, he/she probably has access to this wallet and can freely use the funds in this wallet.",
  "wallet_recipient_warning_3": "If I lose access to my wallet and do not have a backup (recovery seed), I lose access to all funds in my wallet.",
  "wallet_recipient_warning_4": "If I add an address of crypto-wallet that is not mine, I will not be able to directly use of the funds in that wallet. It is up to the third party to allow me to dispose of the funds.",
  "warning": "Warning",
  "ticket_attachments_info": "Attachments can be added to the ticket in the next step (after the ticket is created).",
  "one_off_income": "One-off income",
  "button_previous": "Previous",
  "years_of_service": "Years of service",
  "primary_or_secondary_activity": "Primary / secondary activity",
  "rental_contract": "Rental contract",
  "bank_statement_with_incoming_payment": "Bank statement with incoming payment",
  "pension_confirmation_letter": "Pension confirmation letter",
  "loan": "Loan",
  "loan_maturity_date": "Maturity date",
  "loan_effective_date": "Effective date",
  "lender": "Lender",
  "loan_agreement": "Loan agreement",
  "donation_agreement": "Donation agreement",
  "savings": "Savings",
  "bank_statement": "Bank statement",
  "sale_of_movable_property": "Sale of movable property",
  "sale_of_company_share": "Sale of company share",
  "investment_return": "Investment return",
  "investment_period": "Investment period",
  "crypto_mining": "Crypto mining",
  "mining_target_crypto_address": "Target crypto-address of mined coins",
  "verified_clients_limit_info": "Verified clients can trade up to EUR 10.000 per month without providing further proof of incomes.",
  "expected_trading_volume": "Expected trading volume",
  "expected_trading_volume_info": "Setting up expected trading volume is optional and is not a commitment. It will help us to better set your trading limits and thus speed up your trading.",
  "add_expected_trading_volume": "Add expected trading volume",
  "trading_volume_period": "Trading period",
  "landing_quarterly_volume": "90 day volume",
  "landing_quarterly_exchanges": "Exchanges in the last 90 days",
  "income_state_revoked": "revoked",
  "crypto_deposit_fee_info": "Speed of your crypto transaction depends on the transaction fee so it is important to use the correct transaction fee in order for transaction to be verified soon.",
  "deposit_on_account": "Deposit on account",
  "show": "show",
  "hide": "hide",
  "account_status_suspended": "suspended",
  "account_status_closed": "closed",
  "account_status_active": "active",
  "suspended_accounts": "Suspended accounts",
  "closed_accounts": "Closed accounts",
  "active_accounts": "Active accounts",
  "create_wallet_account_form_header": "Add a new account",
  "account_number": "Account number",
  "accounted_transactions": "Accounted transactions",
  "counterparty": "Counterparty",
  "all_financial_operations": "All",
  "accounting_balance": "Accounting balance",
  "pending_deposits": "Pending deposits",
  "pending_withdrawals": "Pending withdrawals",
  "pending_transactions": "Pending transactions",
  "blocked_amount_alert": "There is an amount {{amount}} {{currency}} blocked on this account. Please contact support for more information.",
  "available_balance_tooltip": "Available balance is the amount of money you currently have available for trading. The available balance does not include pending deposits but already takes into account amounts blocked, e.g. due to pending withdrawals.",
  "total_balance_tooltip": "The account total balance is the sum of all confirmed transactions in your account.",
  "deposit_requests_total_tooltip": "Pending deposits are incoming transactions to your account for which we are waiting for confirmation on the block-chain or have not been credited due to exceeding your trading limit.",
  "withdrawal_requests_total_tooltip": "Pending withdrawals are withdrawals awaiting confirmation on the block-chain.",
  "cancel_account": "Close account",
  "cancel_account_confirmation_question": "Are you sure you want to close this account? Closing the account is an irreversible operation.",
  "empty_account_required_to_close_it": "Only accounts with zero balance can be closed.",
  "account_closed_success": "The account has been closed.",
  "approved_incomes": "Approved incomes",
  "pending_incomes": "Pending incomes",
  "show_details": "show details",
  "hide_details": "hide details",
  "hide_approved_incomes": "Hide approved incomes",
  "show_approved_incomes": "Show approved incomes",
  "increase_trading_limit": "Increase trading limit",
  "save_as_draft": "Save as draft",
  "withdrawal_success": "Withdrawal has been successfully requested",
  "withdrawal_confirmation_transaction_fee_label": "Transaction fee: {{feeAmount}} {{feeCurrencyCode}}",
  "withdrawal_form_fee_in_amount_switch_label": "Withdrawal fee included",
  "withdrawal_recap_table_fee": "Fee amount",
  "withdrawal_recap_table_deducted_amount": "Withdrawal amount",
  "withdrawal_recap_table_net_withdraw_amount": "Recipient will get",
  "remove_income_confirmation": "Do you really want to delete this income?",
  "deposit_form_show_instructions": "Show deposit instructions",
  "income_state_readyForApproval": "awaits approval",
  "withdrawal_form_show_bank_fields_toggle": "Show modifiable fields",
  "end_income": "End income",
  "edit_income": "Edit income",
  "delete_income": "Delete income",
  "pending_transaction_detail": "Pending transaction detail",
  "account": "Account",
  "verification_wizard": "Verification wizard",
  "portfolio_volume": "Your portfolio volume",
  "profile_verification_info_message_for_business": "For business verification <0>read our guide</0>.",
  "unknown_source": "unknown source",
  "amount_netto": "Amount (netto)",
  "deposit_request_status_on_hold": "On Hold",
  "exchange_form_verified_client_discount_message": "Verified clients can save up to <0>{{nominalDiscount}} {{discountCurrency}}</0> per {{toCurrency}}. </1>",
  "sign_up_to_get_discount": "Sign up to get the discount",
  "exchange_form_verified_client_discount_message_generic": "Verified clients get a better price",
  "exchange_form_verified_client_discount_message_from_crypto": "Verified clients can immediately get <0>{{nominalDiscount}} {{discountCurrency}}</0> more per {{toCurrency}}.",
  "price_for_verified_clients": "Price for verified clients:",
  "contact_page_customer_center_reservation_info": "If you are interested in visiting our office, please <0>make an appintment</0>",
  "label_house_number": "House No",
  "react_file_upload_count_error": "the maximum number of uploaded files is {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "We are sorry, but payments in {{currency}} are not possible at this moment.",
  "payment_instructions_delay_warning": "Bank transfer in Czech crowns is processed immediately if your bank offers this service.<br/>\nEuro transfers may take longer. To secure the original exchange rate, you can attach a PDF payment confirmation  (this service is available daily from 9:00 AM to 9:00 PM). Each request is assessed individually, and we will contact you as soon as possible.\n",
  "payment_confirmation_upload_info": "The duration of original exchange-rate is evaluated on an individual basis and depends on your payment history and order amount.",
  "attach_payment_confirmation": "Attach payment confirmation",
  "price_fixed_until": "The price is fixed until {{time}}",
  "payment_confirmation_attached": "Payment confirmation attached",
  "validation_incorrect_format_generic": "Invalid format",
  "exchange_fee_percentage_info": "Fee makes {{feePercentage}}% of the traded amount",
  "citizenship": "Citizenship",
  "second_citizenship": "Second citizenship",
  "second_citizenship_helper": "If you have two citizenships state both",
  "dashboard_intro_text_how_to_start": "Our wallet works similarly to internet banking. In order to start using Simplecoin Wallet, you need to deposit funds into virtual accounts you create in it. To get started, we recommend you set up one account each for the currencies you want to start trading. Later, of course, you can add more currency accounts.",
  "how_to_start_using_wallet": "How to start using Simplecoin wallet?",
  "create_accounts": "Create accounts",
  "default_account_label": "{{currencyCode}} default account",
  "default_account_description": "This account was created automatically for you by the initial wizard.",
  "about_virtual_account_info": "A virtual account is where you keep your funds in your Simplecoin wallet. If you want to hold funds in your chosen currency, you must create at least one account for that currency. Setting up and maintaining an account is free. Trading is done by transferring funds between accounts, \nwhich may be in different currencies.",
  "preferred_currencies_question": "Which currencies will you most often trade in?",
  "crypto": "Crypto",
  "fiat": "Fiat",
  "create_accounts_for_selected_currencies": "Create accounts for selected currencies",
  "accounts_successfully_created": "Accounts has been successfully created! We have created {{accountsCount}} accounts for you.",
  "create_your_first_accounts": "Create your first accounts",
  "wallet_logo": "wallet",
  "just_one_citizenship": "just one citizenship",
  "information_about_verification_status_change": "We will inform you about the change of verification status to e-mail {{email}}.",
  "amount_too_low": "Amount too low",
  "footer_link_legal_documents": "Legal documents",
  "log_into_wallet": "Wallet",
  "log_into_exchange": "Exchange",
  "log_into_exchange_caption": "One-off purchases to your wallet",
  "log_into_wallet_caption": "The easiest cryptocurrency trading",
  "payment_request": "Payment request",
  "payment_hash": "Payment hash",
  "memo": "Memo",
  "scan_invoice_qr": "Scan invoice QR",
  "lightning_payment": "Lightning payment",
  "scan_qr": "Scan QR",
  "pay": "Pay"
}