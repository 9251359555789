import Multichart, {MultichartProps} from './components/chart/MultiChart';
import LandingExchangeForm, {LandingExchangeFormTypes} from './components/exchange-form/LandingExchangeForm';
import ExchangeForm from './components/exchange-form/ExchangeForm';
import {FormTextField} from './components/formComponents/FormTextField';
import CurrencyIcon from './components/CurrencyIcon';
import FlatCurrencyIcon from './components/FlatCurrencyIcon';
import OrderView from './components/order/OrderView';
import QrScanner from './components/QrScanner';
import {simplecoinProAppTheme, simplecoinLandingTheme, simplecoinExchangeAppTheme} from './theme/MuiTheme';
import OrderStatus from './components/OrderStatus';
import {coreReducers} from './redux/store';
import {actions as snackbarActions} from './redux/snackbarSlice';
import SnackbarNotifier from './snackbars/Notifier';
import SnackbarProvider from './snackbars/SnackbarProvider';
import {fetchSettings} from './redux/siteSlice';
import {Settings} from './typings/settings';
import useBackendSettings from './hooks/useBackendSettings';
import DetailView, {DetailViewItem} from './components/DetailView';
import Switch from './components/Switch';
import {Rate} from './typings/Rate';
import {Price} from './typings/Price';
import {Currencies, Currency} from './typings/currency';
import {User, UserMeta, UserAuth} from './typings/User';
import {fetchGeneric, initiateAxiosApi, ThunkApi} from './api';
import {fetchPrice, fetchRate, invalidatePrice, invalidateRate} from './redux/exchangeFormSlice';
import CryptoAccountFields from './components/exchange-form/CryptoAccountFields';
import BankAccountFields from './components/BankAccountFields';
import CurrencyDropdown from './components/CurrencyDropdown';
import Dialog, {DialogInterface, InvokerProps} from './components/Dialog';
import FiatPaymentInstructions from './components/order/FiatPaymentInstructions';
import {InfoIconTooltip} from './components/InfoIconTooltip';
import useInterval from './hooks/useInterval';
import {useCurrencyCode, useCurrency, useCurrencyId, useCurrencies, useCurrenciesArray} from './hooks/currency';
import {
    Wallet,
    CryptoTransaction,
    Bank,
    BankAccount,
    BankTransaction,
    Order,
    StatusLog,
    LiveSuspend,
    OrderOffer,
} from './typings/Order';
import {
    createWallet,
    deleteWallet,
    fetchWallet,
    fetchWallets,
    invalidateWalletCreate,
    updateWallet,
} from './redux/walletSlice';
import {createBank, deleteBank, fetchBank, fetchBanks, invalidateBankCreate, updateBank} from './redux/bankSlice';
import * as coreParams from './config/params';
import {rootSaga} from './redux/sagas';
import CurrencyField from './components/exchange-form/CurrencyField';
import CryptoPaymentInstructions from './components/order/CryptoPaymentInstructions';
import RateDisclaimer from './components/exchange-form/RateDisclaimer';
import FormattedAmount from './components/FormattedAmount';
import useLocalizedNavigate from './hooks/useLocalizedNavigate';
import yup, {changeYupLocale} from './config/Yup';
import dayjs, {Dayjs} from './config/dayjs';
import {version} from '../package.json';

/* Icons */
import {SwapIcon} from './components/icons/SwapIcon';
import {HorizontalSwapIcon} from './components/icons/HorizontalSwipeIcon';
import {SimpleArrowRightIcon} from './components/icons/SimpleArrowRightIcon';
import {SimpleArrowDownIcon} from './components/icons/SimpleArrowDownIcon';
import CustomSelect from './components/CustomSelect';
import CustomButton from './components/CustomButton';
import Form from './components/Form';
import ViewResponse from './components/ViewResponse';
import AddAddressFromTrezorButton from './plugins/trezor/AddAddressFromTrezorButton';
import ColoredCurrencyIcon from './components/ColoredCurrencyIcon';
import AmountToAmount from './components/AmountToAmount';
import OrderLifecycleWidget from './components/order/OrderLifecycleWidget';
import SuspendedOrderIcon from './components/icons/SuspendedOrderIcon';
import {CommonFrontendConfig} from './helpers/config';
import DisplayBetween from './components/DisplayBetween';
import TextFieldWithCopy from './components/TextFieldWithCopy';
import RedirectProgrammatic from './handlers/RedirectProgrammatic';

const {enqueueSnackbar, closeSnackbar} = snackbarActions;

export * from './helpers';

// types
export {
    Rate,
    Price,
    Settings,
    Currencies,
    Currency,
    Bank,
    BankAccount,
    BankTransaction,
    Order,
    Wallet,
    CryptoTransaction,
    StatusLog,
    LiveSuspend,
    OrderOffer,
    User,
    UserMeta,
    UserAuth,
    DetailViewItem,
    coreParams,
    ThunkApi,
    InvokerProps,
    CommonFrontendConfig,
    LandingExchangeFormTypes,
    DialogInterface,
    MultichartProps,
};

export * from './typings/Api';

// themes
export {simplecoinProAppTheme, simplecoinLandingTheme, simplecoinExchangeAppTheme};

// helpers and hooks
export {
    useInterval,
    useBackendSettings,
    useCurrency,
    useCurrencyId,
    useCurrencyCode,
    useLocalizedNavigate,
    useCurrencies,
    useCurrenciesArray,
};

// components
export {
    CurrencyIcon,
    FlatCurrencyIcon,
    ColoredCurrencyIcon,
    QrScanner,
    OrderStatus,
    DetailView,
    Switch,
    BankAccountFields,
    CryptoAccountFields,
    CurrencyDropdown,
    Dialog,
    FiatPaymentInstructions,
    CurrencyField,
    CryptoPaymentInstructions,
    RateDisclaimer,
    FormattedAmount,
    CustomSelect,
    CustomButton,
    Form,
    ViewResponse,
    InfoIconTooltip,
    AddAddressFromTrezorButton,
    FormTextField,
    AmountToAmount,
    OrderLifecycleWidget,
    DisplayBetween,
    TextFieldWithCopy,
};

// redux related
export {
    enqueueSnackbar,
    closeSnackbar,
    coreReducers,
    snackbarActions,
    fetchSettings,
    SnackbarProvider,
    SnackbarNotifier,
    fetchRate,
    fetchPrice,
    invalidateRate,
    invalidatePrice,
    initiateAxiosApi,
    createWallet,
    invalidateWalletCreate,
    deleteWallet,
    updateWallet,
    fetchWallets,
    fetchWallet,
    createBank,
    invalidateBankCreate,
    deleteBank,
    fetchBanks,
    fetchBank,
    updateBank,
    rootSaga,
    fetchGeneric,
    RedirectProgrammatic,
};

export * as ExchangeFormTypings from './typings/ExchangeFormTypings';
export * as OrderViewTypings from './typings/OrderViewTypings';

// order actions
export * as OrderActions from './redux/orderSlice';

export * as SiteActions from './redux/siteSlice';
export * as TrezorActions from './redux/trezorConnectSlice';
export * as RedirectActions from './redux/redirectSlice';

// trezor-related
export {trezorVerifyAddress, trezorSendTransaction, getTrezorAccountInfo} from './plugins/trezor/TrezorActions';

// big standalone components
export {Multichart, LandingExchangeForm, ExchangeForm, OrderView};

// icons
export {SwapIcon, HorizontalSwapIcon, SimpleArrowRightIcon, SimpleArrowDownIcon, SuspendedOrderIcon};

// reexporting yup with config
export {yup, changeYupLocale};

// reexporting dayjs extended with relativeTime & localised format plugins
export {dayjs, Dayjs};

export const coreLibVersion = version;
