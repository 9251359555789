import {CountryCode, CountryCodeRegion} from '../typings';
import {OrderBaseStatus, OrderDirectionStatus, OrderFinalStatus} from '~/src/typings/Common';

export interface LanguageItemProps {
    code: CountryCode;
    region: CountryCodeRegion;
    name: string;
    nativeName: string;
}

/**
 * Test language code. It is not standard, but does not conflict with any ISO 639-1 code.
 */
export const TEST_LANGUAGE_CODE = 'tx';

/**
 * Test language region code.
 */
export const TEST_LANGUAGE_REGION = 'tx-TX';

// List of available languages
// Use by language chooser
export const LANGUAGES: LanguageItemProps[] = [
    {
        code: 'en',
        region: 'en-US',
        name: 'English',
        nativeName: 'English',
    },
    {
        code: 'cs',
        region: 'cs-CZ',
        name: 'Czech',
        nativeName: 'Čeština',
    },
    {
        code: 'sk',
        region: 'sk-SK',
        name: 'Slovak',
        nativeName: 'Slovenčina',
    },
    {
        code: 'pl',
        region: 'pl-PL',
        name: 'Polish',
        nativeName: 'Polski',
    },
    {
        code: 'de',
        region: 'de-DE',
        name: 'German',
        nativeName: 'Deutsch',
    },
    {
        code: 'it',
        region: 'it-IT',
        name: 'Italian',
        nativeName: 'Italiano',
    },
    {
        code: 'es',
        region: 'es-ES',
        name: 'Spanish',
        nativeName: 'Español',
    },
    {
        code: 'ru',
        region: 'ru-RU',
        name: 'Russian',
        nativeName: 'Русский',
    },
    {
        code: 'uk',
        region: 'uk-UA',
        name: 'Ukrainian',
        nativeName: 'Українська',
    },
    {
        code: 'nl',
        region: 'nl-NL',
        name: 'Dutch',
        nativeName: 'Nederlands',
    },
    {
        code: 'vi',
        region: 'vi-VN',
        name: 'Vietnamese',
        nativeName: 'Tiếng Việt',
    },
    {
        code: TEST_LANGUAGE_CODE,
        region: TEST_LANGUAGE_REGION,
        name: 'Test',
        nativeName: 'Test',
    },
];

/**
 * Possible base statuses.
 */
export const STATUS_NEW: OrderBaseStatus = 'new';
export const STATUS_CONFIRMING_INCOMING: OrderBaseStatus = 'confirming_incoming';
export const STATUS_RECEIVED: OrderBaseStatus = 'received';
export const STATUS_CONFIRMING_OUTGOING: OrderBaseStatus = 'confirming_outgoing';
export const STATUS_DONE: OrderBaseStatus = 'done';

/**
 * Possible direction statuses.
 */
export const STATUS_PROCEED: OrderDirectionStatus = 'proceed';
export const STATUS_SUSPENDED: OrderDirectionStatus = 'suspended';
export const STATUS_RETURN_TO_CLIENT: OrderDirectionStatus = 'return_to_client';
export const STATUS_ON_HOLD: OrderDirectionStatus = 'on_hold';

/**
 * Possible final statuses.
 */
export const STATUS_CANCELLED: OrderFinalStatus = 'cancelled';
export const STATUS_DELIVERED: OrderFinalStatus = 'delivered';
export const STATUS_RETURNED: OrderFinalStatus = 'returned';
export const STATUS_MISSING: OrderFinalStatus = null;
// the unfinished status is only used for order filtering
export const STATUS_UNFINISHED: OrderFinalStatus = 'unfinished';

/**
 * List of available status types.
 */
export type OrderStatusType = 'base' | 'direction' | 'final';

export const STATUS_TYPE_BASE: OrderStatusType = 'base';
export const STATUS_TYPE_DIRECTION: OrderStatusType = 'direction';
export const STATUS_TYPE_FINAL: OrderStatusType = 'final';

export interface StatusProps {
    baseDescription_i18nKey: string;
    directionDescription: string | null;
    name_i18nKey: string;
    color: string;
    type: string;
}

export interface StatusesProps {
    [status: string]: StatusProps;
}

export const STATUSES: StatusesProps = {
    [STATUS_NEW]: {
        baseDescription_i18nKey: 'status_description_new',
        directionDescription: null,
        name_i18nKey: 'status_name_new',
        color: '#f0f0f0',
        type: STATUS_TYPE_BASE,
    },
    [STATUS_CONFIRMING_INCOMING]: {
        baseDescription_i18nKey: 'status_description_confirming_incoming',
        directionDescription: null,
        name_i18nKey: 'status_name_confirming_incoming',
        color: '#f0f0f0',
        type: STATUS_TYPE_BASE,
    },
    [STATUS_CONFIRMING_OUTGOING]: {
        baseDescription_i18nKey: 'status_description_confirming_outgoing',
        directionDescription: null,
        name_i18nKey: 'status_name_confirming_outgoing',
        color: '#f0f0f0',
        type: STATUS_TYPE_BASE,
    },
    [STATUS_RECEIVED]: {
        baseDescription_i18nKey: 'status_description_received',
        directionDescription: null,
        name_i18nKey: 'status_name_received',
        color: '#dcfac3',
        type: STATUS_TYPE_BASE,
    },
    [STATUS_DONE]: {
        baseDescription_i18nKey: 'status_description_done',
        directionDescription: null,
        name_i18nKey: 'status_name_done',
        color: '#f0f0f0',
        type: STATUS_TYPE_BASE,
    },
    [STATUS_PROCEED]: {
        baseDescription_i18nKey: 'status_description_proceed',
        directionDescription: 'status_direction_description_proceed',
        name_i18nKey: 'status_name_proceed',
        color: '#f0f0f0',
        type: STATUS_TYPE_DIRECTION,
    },
    [STATUS_SUSPENDED]: {
        baseDescription_i18nKey: 'status_description_suspended',
        directionDescription: 'status_direction_description_suspended',
        name_i18nKey: 'status_name_suspended',
        color: '#fff5c8',
        type: STATUS_TYPE_DIRECTION,
    },
    [STATUS_RETURN_TO_CLIENT]: {
        baseDescription_i18nKey: 'status_description_return_to_client',
        directionDescription: 'status_direction_description_return_to_client',
        name_i18nKey: 'status_name_return_to_client',
        color: '#f0f0f0',
        type: STATUS_TYPE_DIRECTION,
    },
    [STATUS_ON_HOLD]: {
        baseDescription_i18nKey: 'status_description_on_hold',
        directionDescription: 'status_direction_description_returned',
        name_i18nKey: 'status_on_hold',
        color: '#f0f0f0',
        type: STATUS_TYPE_DIRECTION,
    },
    [STATUS_CANCELLED]: {
        baseDescription_i18nKey: 'status_description_cancelled',
        directionDescription: 'status_direction_description_cancelled',
        name_i18nKey: 'status_name_cancelled',
        color: '#d7f0ff',
        type: STATUS_TYPE_FINAL,
    },
    [STATUS_DELIVERED]: {
        baseDescription_i18nKey: 'status_description_delivered',
        directionDescription: null,
        name_i18nKey: 'status_name_delivered',
        color: '#f0f0f0',
        type: STATUS_TYPE_FINAL,
    },
    [STATUS_RETURNED]: {
        baseDescription_i18nKey: 'status_description_returned',
        directionDescription: 'status_direction_description_returned',
        name_i18nKey: 'status_name_returned',
        color: '#f0f0f0',
        type: STATUS_TYPE_FINAL,
    },
    // the unfinished status is only used for order filtering
    [STATUS_UNFINISHED]: {
        baseDescription_i18nKey: 'status_description_unfinished',
        directionDescription: null,
        name_i18nKey: 'status_name_unfinished',
        color: '#f0f0f0',
        type: STATUS_TYPE_FINAL,
    },
};

/**
 * Most common countries to show on top in selects
 * @type {*[]}
 */
export const PREFERRED_COUNTRIES = ['cz', 'sk', 'pl', 'de', 'ru', 'ua', 'nl'];

/**
 * Number of seconds before auth token is expired to invoke logout
 * @type {number}
 */
export const LOGOUT_BEFORE_EXPIRED_SECONDS = 30;

export const LANGUAGE_URL_MATCH = ':locale';
export const REDIRECT_PARAM_NAME = 'backUrl';

export const AFFILIATE_PARAM_NAME = 'a';

export const DEFAULT_CALCULATION_CURRENCY = 'EUR';

/**
 * Breakpoints matching default material-ui
 * @type {{xl: string, md: string, sm: string, xs: string, lg: string}}
 */
export const WidthBreakpoint = {
    xs: '0px',
    sm: '600px',
    md: '900px',
    lg: '1200px',
    xl: '1536px',
};

export const CLOSED_NOTIFICATIONS_STORAGE_KEY = 'closed_notifications';

// mind the underscore in onfido docs instead of dash; Check for updated version
export const ONFIDO_WEBSDK_SUPPORTED_LANGUAGES = ['en-US', 'de-DE', 'es-ES', 'fr-FR', 'it-IT', 'pt-PT'];
