import React from 'react';
import {InputAdornment, SxProps, TextField} from '@mui/material';
import {FormikErrors} from 'formik';
import ErrorIcon from '@mui/icons-material/Error';

interface FormTextFieldProps<Value> {
    id: string;
    name: string;
    type?: string;
    value: string;
    handleChange: (e) => void;
    handleBlur?: (e) => void;
    touched?: boolean;
    errorMessage?: FormikErrors<Value>;
    label: string;
    autoComplete?: string;
    sx?: SxProps;
    helperText?: string;
}

export const FormTextField = ({
    id,
    name,
    type = 'text',
    value,
    handleChange,
    handleBlur,
    touched = true,
    errorMessage,
    label,
    autoComplete,
    sx,
    helperText,
    ...rest
}: FormTextFieldProps<string>): JSX.Element => {
    let helperTextMessage: string;
    if (errorMessage && helperText) {
        helperTextMessage = errorMessage ? errorMessage : helperText;
    } else if (errorMessage && !helperText) {
        helperTextMessage = errorMessage;
    } else if (!errorMessage && helperText) {
        helperTextMessage = helperText;
    } else {
        helperTextMessage = '';
    }
    return (
        <TextField
            value={value}
            id={id}
            name={name}
            type={type}
            onChange={handleChange}
            onBlur={handleBlur}
            margin='normal'
            variant='outlined'
            label={label}
            autoComplete={autoComplete}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        {errorMessage && touched && <ErrorIcon color='error' />}
                    </InputAdornment>
                ),
            }}
            error={!!errorMessage}
            fullWidth
            helperText={helperTextMessage}
            sx={sx}
            {...rest}
        />
    );
};
