import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface RedirectReducerInterface {
    redirectProgrammatic?: {url: string};
    backendVersion?: string;
}

const initialState: RedirectReducerInterface = {
    redirectProgrammatic: undefined,
    backendVersion: undefined,
};

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        redirectProgrammatic: (state, action: PayloadAction<{url: string}>) => {
            state.redirectProgrammatic = {url: action.payload.url};
        },
        redirectProgrammaticInvalidate: (state) => {
            state.redirectProgrammatic = initialState.redirectProgrammatic;
        },
        setBackendVersion: (state, action) => {
            state.backendVersion = action.payload.version;
        },
    },
});

export const {actions, reducer} = slice;
export const {redirectProgrammatic, redirectProgrammaticInvalidate, setBackendVersion} = actions;
