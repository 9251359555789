import {SvgIcon} from '@mui/material';
import React from 'react';

export default function TicketIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path
                d='M22 11V9C22 4.5 19.5 2 15 2H9C4.5 2 2 4.5 2 9V11.5C2 15.9392 4.5 18.5 9 18.5H16.5L22 22V11Z'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                fill='none'
            />
            <path
                d='M7.5 12C8.32843 12 9 11.3284 9 10.5C9 9.67157 8.32843 9 7.5 9C6.67157 9 6 9.67157 6 10.5C6 11.3284 6.67157 12 7.5 12Z'
                fill='currentColor'
            />
            <path
                d='M12 12C12.8284 12 13.5 11.3284 13.5 10.5C13.5 9.67157 12.8284 9 12 9C11.1716 9 10.5 9.67157 10.5 10.5C10.5 11.3284 11.1716 12 12 12Z'
                fill='currentColor'
            />
            <path
                d='M16.5 12C17.3284 12 18 11.3284 18 10.5C18 9.67157 17.3284 9 16.5 9C15.6716 9 15 9.67157 15 10.5C15 11.3284 15.6716 12 16.5 12Z'
                fill='currentColor'
            />
        </SvgIcon>
    );
}
