import {createTheme} from '@mui/material';
import {simplecoinExchangeAppTheme} from '@simplecoin/core';

const chainBlockFont = ['"Rubik"', 'sans-serif'].join(', ');
const chainBlockPrimaryColor = '#021bab';

export const chainblockTheme = createTheme(simplecoinExchangeAppTheme, {
    logo: {
        name: 'chainblock',
        width: 200,
        height: 60,
    },
    palette: {
        primary: {
            light: '#757ce8',
            main: chainBlockPrimaryColor,
            dark: '#010528',
            contrastText: '#fff',
        },
        secondary: {
            main: '#f99506',
            dark: '#ba000d',
            contrastText: '#fff',
        },
        text: {
            primary: '#010635',
            secondary: '#23262b',
            disabled: '#898E9E',
        },
        background: {
            default: '#f5f6f9',
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: chainBlockFont,
        h1: {
            fontFamily: chainBlockFont,
            color: chainBlockPrimaryColor,
        },
        h2: {
            fontFamily: chainBlockFont,
            color: chainBlockPrimaryColor,
        },
        h3: {
            fontFamily: chainBlockFont,
            color: chainBlockPrimaryColor,
        },
        h4: {
            fontFamily: chainBlockFont,
            color: chainBlockPrimaryColor,
        },
        h5: {
            fontFamily: chainBlockFont,
            color: chainBlockPrimaryColor,
        },
        h6: {
            fontFamily: chainBlockFont,
            color: chainBlockPrimaryColor,
        },
        body1: {
            fontFamily: chainBlockFont,
        },
        body2: {
            fontFamily: chainBlockFont,
        },
        subtitle1: {
            fontFamily: chainBlockFont,
        },
        subtitle2: {
            fontFamily: chainBlockFont,
        },
        caption: {
            fontFamily: chainBlockFont,
        },
        text: {
            fontFamily: chainBlockFont,
        },
        label: {
            fontFamily: chainBlockFont,
        },
        textL: {
            fontFamily: chainBlockFont,
        },
        textM: {
            fontFamily: chainBlockFont,
        },
        textS: {
            fontFamily: chainBlockFont,
        },
        textXs: {
            fontFamily: chainBlockFont,
        },
        button: {
            fontFamily: chainBlockFont,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Rubik';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'),
                },
            `,
        },
        MuiButton: {
            styleOverrides: {
                containedSecondary: {
                    boxShadow: '0px 4px 16px rgba(253, 99, 0, 0.2), 0px 1px 2px rgba(253, 99, 0, 0.25)',
                    ':hover': {
                        backgroundColor: '#ac6612',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    color: chainBlockPrimaryColor,
                    fontWeight: '700',
                    textTransform: 'none',
                },
            },
        },
    },
});
