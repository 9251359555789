import React, {memo} from 'react';
import {Box, Link, Stack, Typography, useTheme} from '@mui/material';
import Big from 'big.js';
import {Price} from '~/typings/Price';
import {Rate} from '~/typings/Rate';
import {Currencies} from '~/typings/currency';
import useBackendSettings from '~/hooks/useBackendSettings';
import {Trans, useTranslation} from 'react-i18next';
import {formatAmount} from '~/helpers/price';
import {getConfigValue} from '~/helpers/config';
import {InfoIconTooltip} from '~/components/InfoIconTooltip';

type R = (Price & Rate) | undefined;

export interface DiscountedPriceProps {
    rate?: Rate;
    price?: Price;
    isLanding?: boolean;
    errorFetchingRate?: boolean;
}

const DiscountedPrice = memo(({rate, price, isLanding, errorFetchingRate}: DiscountedPriceProps) => {
    const {t, i18n} = useTranslation();
    const currencies: Currencies = useBackendSettings('currencies', {});
    const appUrl: string = getConfigValue('exchangeUrl', '');
    const theme = useTheme();

    const fromCurrencyCode = (price?.from && currencies[price.from]?.name) ?? rate?.from;
    const toCurrencyCode = (price?.to && currencies[price.to]?.name) ?? rate?.to;

    // @ts-ignore
    const reducer: R = price ? price : rate;

    // If the rate cannot be fetched
    if (errorFetchingRate || !reducer || !fromCurrencyCode || !toCurrencyCode) {
        return null;
    }
    const signupUrl = isLanding ? `${appUrl}/signup` : '/signup';
    const lang = i18n.language;
    const fromDecimals = currencies[fromCurrencyCode ?? '']?.fixed_decimals ?? 6;
    const toDecimals = currencies[toCurrencyCode ?? '']?.fixed_decimals ?? 6;
    const fromIsCrypto = currencies[fromCurrencyCode]?.type === 'crypto';
    const toIsCrypto = currencies[toCurrencyCode]?.type === 'crypto';
    const cryptoToCrypto = fromIsCrypto && currencies[toCurrencyCode]?.type === 'crypto';
    const cryptoToFiat = fromIsCrypto && !toIsCrypto;
    const discountedRate = reducer.discounted_rate ?? reducer.discounted_raw_rate;
    const discountedRateInverse = reducer.discounted_rate_inverse ?? reducer.discounted_raw_rate_inverse;
    const discountedRateValue = reducer.discounted_rate_value ?? reducer.discounted_raw_rate_value;
    const rawRate = reducer.raw_rate ?? reducer.rate;
    const rawRateInverse = reducer.raw_rate_inverse ?? reducer.rate_inverse;
    const discountedRateValueInverse =
        reducer.discounted_rate_value_inverse ?? reducer.discounted_raw_rate_value_inverse;

    let nominalDiscount = new Big(rawRateInverse).minus(discountedRateInverse).toNumber();

    if (cryptoToFiat) {
        nominalDiscount = new Big(discountedRate).minus(rawRate).toNumber();
    }

    const formattedNominalDiscount = formatAmount(nominalDiscount, fromDecimals, lang);
    const formattedRateValue = formatAmount(discountedRateValue, fromDecimals, lang);
    const formattedRateValueInverse = formatAmount(discountedRateValueInverse, fromDecimals, lang);

    let discountedRateText;
    let discountMessage = (
        <Trans
            i18nKey='exchange_form_verified_client_discount_message'
            components={[<b style={{whiteSpace: 'nowrap'}} key={0} />]}
            values={{
                nominalDiscount: formattedNominalDiscount,
                discountCurrency: fromCurrencyCode,
                toCurrency: toCurrencyCode,
            }}
        />
    );

    if (cryptoToCrypto) {
        // should have both ways
        discountedRateText = (
            <Stack direction='column'>
                <Typography variant='textS' color={theme.cool.grey10} fontWeight={700}>
                    {`${formattedRateValue} ${fromCurrencyCode} = ${discountedRate} ${toCurrencyCode}`}
                </Typography>
                <Typography variant='textS' color={theme.cool.grey10} fontWeight={700}>
                    {` ${formattedRateValueInverse} ${toCurrencyCode} = ${discountedRateInverse} ${fromCurrencyCode}`}
                </Typography>
            </Stack>
        );
    } else if (fromIsCrypto) {
        // 1 LTC = 3500 CZK

        const shouldRound = !toIsCrypto && discountedRate > 100;
        const roundedRate = shouldRound ? Math.round(discountedRate) : discountedRate;

        discountedRateText = (
            <Typography variant='body2' color={theme.cool.grey10} fontWeight={700}>
                {` ${formattedRateValue} ${fromCurrencyCode} = ${formatAmount(
                    roundedRate,
                    toDecimals,
                    lang
                )} ${toCurrencyCode}`}
            </Typography>
        );

        // discount should be displayed in fiat saved per crypto if cyrpto -> fiat
        discountMessage = (
            <Trans
                i18nKey='exchange_form_verified_client_discount_message_from_crypto'
                components={[<b style={{whiteSpace: 'nowrap'}} key={0} />]}
                values={{
                    nominalDiscount: formattedNominalDiscount,
                    discountCurrency: toCurrencyCode,
                    toCurrency: fromCurrencyCode,
                }}
            />
        );
    } else {
        // shown as  1 BTC = 1 200 354 CZK
        let roundedInverseRate = discountedRateInverse;

        if (currencies[fromCurrencyCode]?.type === 'fiat' && discountedRateValue > 100) {
            roundedInverseRate = Math.round(discountedRateInverse);
        }

        const formattedRateInverse = formatAmount(roundedInverseRate, fromDecimals, lang);

        discountedRateText = (
            <>
                <Typography variant='body2' color={theme.cool.grey10} fontWeight={700} noWrap>
                    {` ${formattedRateValueInverse} ${toCurrencyCode} = ${formattedRateInverse} ${fromCurrencyCode}`}
                </Typography>
            </>
        );
    }

    const discountedPriceTooltip = (
        <Typography component='span' color='primary.contrastText' whiteSpace='pre-wrap' variant='body2'>
            {t('price_for_verified_clients')}
            {discountedRateText}
        </Typography>
    );

    const showGenericDiscount = !(nominalDiscount > 0) || (!fromIsCrypto && nominalDiscount < 1);

    if (showGenericDiscount) {
        discountMessage = t('exchange_form_verified_client_discount_message_generic');
    }

    return (
        <Stack
            display='flex'
            alignItems='center'
            spacing={1}
            sx={{borderRadius: '16px', background: '#FAFAFA', py: 2, px: 6}}
        >
            <Box display='flex' alignItems='center'>
                <Typography variant='textS' align='center'>
                    {discountMessage}
                    <InfoIconTooltip key={1} text={discountedPriceTooltip} arrow />
                </Typography>
            </Box>

            {isLanding && (
                <Link key={1} component='a' href={signupUrl} variant='body2' align='center'>
                    {t('sign_up_to_get_discount')}
                </Link>
            )}
        </Stack>
    );
});

DiscountedPrice.displayName = 'DiscountedPrice';

export default DiscountedPrice;
