import React from 'react';
import {isObject} from './common';

/**
 * Returns options from either object or array of objects with specified keys
 */
export function getOptions({
    options,
    itemValueKey = 'value',
    itemDisplayKey = 'text',
}: {
    options?: [] | Record<string, string>[];
    itemValueKey?: string;
    itemDisplayKey?: string;
}): React.ReactElement[] | null {
    if (Array.isArray(options)) {
        return getOptionsFromArray({options, itemValueKey, itemDisplayKey});
    }
    if (isObject(options)) {
        return getOptionsFromObject({options});
    }

    return null;
}

/**
 * function returns options from array
 */
function getOptionsFromArray({
    options,
    itemValueKey,
    itemDisplayKey,
}: {
    options: any;
    itemValueKey: string;
    itemDisplayKey: string;
}): React.ReactElement[] | null {
    if (options.length > 0) {
        const result: React.ReactElement[] = [];

        options.forEach((item: any, id: number): any => {
            if (item[itemValueKey] && item[itemDisplayKey]) {
                result.push(
                    <option key={id} value={item[itemValueKey]}>
                        {item[itemDisplayKey]}
                    </option>
                );
            }
        });

        if (result.length > 0) {
            return result;
        }
    }
    return null;
}

/**
 * Converts options from object.
 * Notice: It reverts behaviour back to getOptionsFromArray() to reduce duplication of the code.
 */
function getOptionsFromObject({options}: {options: any}): React.ReactElement[] | null {
    const optionsArray = Object.keys(options);

    if (optionsArray.length > 0) {
        const treatedAsArray = optionsArray.map((key) => ({
            value: key,
            text: options[key],
        }));

        return getOptionsFromArray({options: treatedAsArray, itemValueKey: 'value', itemDisplayKey: 'text'});
    }

    return null;
}
