import React from 'react';

import CzkIcon from '../assets/icons/currency-fiat/czechrepublic.svg';
import EurIcon from '../assets/icons/currency-fiat/europeanunion.svg';
import UsdIcon from '../assets/icons/currency-fiat/unitedstates.svg';
import PlnIcon from '../assets/icons/currency-fiat/poland.svg';
import BtcIcon from '../assets/icons/currency-crypto/currency=btc.svg';
import BchIcon from '../assets/icons/currency-crypto/currency=bch.svg';
import EthIcon from '../assets/icons/currency-crypto/currency=eth.svg';
import LtcIcon from '../assets/icons/currency-crypto/currency=ltc.svg';
import XrpIcon from '../assets/icons/currency-crypto/currency=xrp.svg';
import ZecIcon from '../assets/icons/currency-crypto/currency=zec.svg';
import OmgIcon from '../assets/icons/currency-crypto/currency=omg.svg';
import XlmIcon from '../assets/icons/currency-crypto/currency=xlm.svg';
import UsdcIcon from '../assets/icons/currency-crypto/currency=usdc.svg';
import LinkIcon from '../assets/icons/currency-crypto/currency=link.svg';
import DogeIcon from '../assets/icons/currency-crypto/currency=doge.svg';
import SolIcon from '../assets/icons/currency-crypto/currency=sol.svg';
import DotIcon from '../assets/icons/currency-crypto/currency=dot.svg';

interface CurrencyIconProps {
    code: string;
    size?: number;
}

export default function ColoredCurrencyIcon({code, size = 24, ...rest}: CurrencyIconProps) {
    let img;

    if (!code) {
        return null;
    }
    switch (code.toUpperCase()) {
        case 'CZK':
            img = CzkIcon;
            break;
        case 'EUR':
            img = EurIcon;
            break;
        case 'USD':
            img = UsdIcon;
            break;
        case 'PLN':
            img = PlnIcon;
            break;
        case 'BTC':
            img = BtcIcon;
            break;
        case 'BCH':
            img = BchIcon;
            break;
        case 'ETH':
            img = EthIcon;
            break;
        case 'LTC':
            img = LtcIcon;
            break;
        case 'XRP':
            img = XrpIcon;
            break;
        case 'ZEC':
            img = ZecIcon;
            break;
        case 'OMG':
            img = OmgIcon;
            break;
        case 'XLM':
            img = XlmIcon;
            break;
        case 'USDC':
            img = UsdcIcon;
            break;
        case 'LINK':
            img = LinkIcon;
            break;
        case 'DOGE':
            img = DogeIcon;
            break;
        case 'SOL':
            img = SolIcon;
            break;
        case 'DOT':
            img = DotIcon;
            break;
        default:
            return null;
    }
    return <img src={img} alt={code} height={size} width={size} {...rest} />;
}
