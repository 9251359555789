import {styled} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tooltip} from '@mui/material';

const StyledTooltip = styled(Tooltip)`
    max-width: 500px;
`;

const UserAvatarImg = styled('img')`
    width: 32px;
    height: 32px;
`;

/**
 * Renders user avatar.
 *
 * @param src
 * @return {*}
 * @constructor
 */
export default function UserAvatar({src = ''}: {src: string}) {
    const {t} = useTranslation();
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    if (!src) {
        return null;
    }

    return (
        <StyledTooltip
            title={t('client_sidebar_avatar_hash_description')}
            open={tooltipOpen}
            onClose={() => setTooltipOpen(false)}
            onOpen={() => setTooltipOpen(true)}
        >
            <UserAvatarImg data-testid='user-avatar-img' src={src} />
        </StyledTooltip>
    );
}

UserAvatar.displayName = 'UserAvatar';
