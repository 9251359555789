import React from 'react';
import {styled, useMediaQuery} from '@mui/material';
import Error from '@mui/icons-material/Error';
import Check from '@mui/icons-material/CheckCircle';
import Warning from '@mui/icons-material/Warning';
import Info from '@mui/icons-material/Info';
import {SnackbarOrigin, SnackbarProvider as BaseSnackbarProvider} from 'notistack';

const StyledBaseSnackbarProvider = styled(BaseSnackbarProvider)(({theme}) => ({
    '&.SnackbarItem-variantSuccess': {
        backgroundColor: `${theme.palette.success.main} !important`,
        flexWrap: 'nowrap',
    },
    '&.SnackbarItem-variantWarning': {
        backgroundColor: `${theme.palette.warning.main} !important`,
        flexWrap: 'nowrap',
    },
    '&.SnackbarItem-variantError': {
        backgroundColor: `${theme.palette.error.main} !important`,
        flexWrap: 'nowrap',
    },
    '&.SnackbarItem-variantInfo': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        flexWrap: 'nowrap',
    },
    '& .MuiIconButton-root': {
        color: '#FFFFFF',
    },
}));

const desktopAnchorOrigin: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

const mobileAnchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const snackBarIconStyle = {marginRight: '5px', marginBottom: '4px'};

const snackbarIconVariant = {
    error: <Error style={snackBarIconStyle} />,
    success: <Check style={snackBarIconStyle} />,
    warning: <Warning style={snackBarIconStyle} />,
    info: <Info style={snackBarIconStyle} />,
};

/**
 * Renders lost of snackbars in the application.
 *
 * @param {node} children List of children passed down to the tree.
 * @return {*}
 * @constructor
 */
export default function SnackbarProvider({children}) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const anchorOrigin = isMobile ? mobileAnchorOrigin : desktopAnchorOrigin;

    return (
        <StyledBaseSnackbarProvider anchorOrigin={anchorOrigin} iconVariant={snackbarIconVariant}>
            {children}
        </StyledBaseSnackbarProvider>
    );
}

SnackbarProvider.displayName = 'SnackbarProvider';
