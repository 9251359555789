import {Chip, keyframes, ListItemButton, ListItemIcon, ListItemText, MenuList, Stack, styled} from '@mui/material';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ensureLanguageInPath, useBackendSettings} from '@simplecoin/core';
import {useLocation, useNavigate} from 'react-router-dom';
import {RootState} from '~/src/redux/store';
import i18n from '~/src/config/i18n';
import {isBrand} from '~/src/helpers/config';
import exchangeMenuIcon from '~/src/assets/svg/exchange-menu-icon.svg';
import ordersMenuIcon from '~/src/assets/svg/orders-menu-icon.svg';
import walletsMenuIcon from '~/src/assets/svg/wallets-menu-icon.svg';
import supportMenuIcon from '~/src/assets/svg/support-menu-icon.svg';
import affiliateMenuIcon from '~/src/assets/svg/affiliate-menu-icon.svg';

import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import DashboardIcon from '~/src/assets/icons/DashboardIcon';
import {Profile} from '~/src/redux/slices/profileSlice';
import PortfolioIcon from '../Icons/PortfolioIcon';
import TradeIcon from '../Icons/TradeIcon';
import RecipientIcon from '../Icons/RecipientIcon';
import UserDiscountAndLimit from '../UserDiscountAndLimit';

const Icon = styled('img')`
    :hover {
        color: #fff;
    }
`;

const StyledMenuItem = styled(ListItemButton)(
    ({theme}) => `
    min-width: 240px;
    margin: 12px 0px;
    padding: 12px 12px 12px 26px;
    border-left: 6px solid transparent;
    
    .MuiSvgIcon-root, .MuiTypography-root {
        color: ${theme.palette.primary.main};
    }

    border-radius: 0 16px 16px 0;

    :hover {
        background: rgba(65, 39, 126, 0.01);
        border-left:  6px solid ${theme.palette.secondary.main}4C;
    }
    :active,
    :focus,
    &.Mui-selected {
        border-left: 6px solid ${theme.palette.secondary.main};
        background: rgba(65, 39, 126, 0.05);
    }
`
);

export default function Menu({closeDrawer}: {closeDrawer?: () => void}) {
    const {t} = useTranslation();
    const {auth: user} = useSelector((state: RootState) => state.user);
    const pendingTickets = user?.response?.user.meta?.pending_tickets;
    const anonymousTradingAllowed = parseInt(useBackendSettings<string>('client_create_order_min_level', 0)) === 0;
    const exchangeDisabled = !anonymousTradingAllowed && user?.response?.user.verification_level === 0;
    const isDivex = isBrand('divex');
    const affiliateHidden = isDivex && user?.response?.user?.affiliate_enabled === false;
    const navigate = useNavigate();
    const isWallet = process.env.BUILD_TYPE === 'wallet';
    const profileInterface = useSelector((state: RootState) => state.profile);
    const profileData: Profile | undefined = profileInterface?.data?.response;

    const pop = keyframes`
        40% {
            transform: scale(1);
        }
        45%  {
            transform: scale(1.2);
        }
        55%  {
            transform: scale(0.9);
        }
        60% {
            transform: scale(1);
        }
    `;

    function getItems() {
        const ticketsLabel = pendingTickets ? (
            <Stack direction='row' alignItems='center'>
                {t('sidebar_navigation_ticket')}
                <Chip
                    label={pendingTickets}
                    color='primary'
                    size='small'
                    sx={{ml: 2, animation: `${pop} 4s 2s 15 ease`}}
                />
            </Stack>
        ) : (
            <>{t('sidebar_navigation_ticket')}</>
        );

        if (user?.status === 'ok') {
            if (isWallet) {
                if (profileData?.verified && profileData?.wallet_policy_consent) {
                    return [
                        {
                            url: '/dashboard',
                            icon: <DashboardIcon name='dashboard' />,
                            text: t('sidebar_navigation_dashboard'),
                        },
                        {
                            url: '/portfolio',
                            icon: <PortfolioIcon />,
                            text: t('accounts'),
                        },
                        {
                            url: '/trade',
                            icon: <TradeIcon />,
                            text: t('sidebar_navigation_trade'),
                            navigate: {state: {value: 'exchange'}},
                        },
                        {
                            url: '/transactions',
                            icon: <ReceiptLongRoundedIcon />,
                            text: t('sidebar_navigation_transactions'),
                            navigate: {state: {value: 'exchange'}},
                        },
                        {
                            url: '/recipients',
                            icon: <RecipientIcon />,
                            text: t('sidebar_navigation_recipients'),
                        },
                        {
                            url: '/ticket',
                            icon: <Icon src={supportMenuIcon} alt='support' />,
                            text: ticketsLabel,
                        },
                    ];
                } else {
                    return [];
                }
            }
            return [
                {
                    url: '/dashboard',
                    icon: <DashboardIcon name='dashboard' />,
                    text: t('sidebar_navigation_dashboard'),
                },
                {
                    url: '/exchange',
                    icon: <Icon src={exchangeMenuIcon} alt='exchange' />,
                    text: t('exchange'),
                    disabled: exchangeDisabled,
                },
                {
                    url: '/orders',
                    icon: <Icon src={ordersMenuIcon} alt='orders' />,
                    text: t('sidebar_navigation_orders'),
                },
                {
                    url: '/recipients',
                    icon: <Icon src={walletsMenuIcon} alt='recipients' />,
                    text: t('sidebar_navigation_recipients'),
                },
                {
                    url: '/ticket',
                    icon: <Icon src={supportMenuIcon} alt='support' />,
                    text: ticketsLabel,
                },
                {
                    url: '/affiliate',
                    icon: <Icon src={affiliateMenuIcon} alt='affiliate' />,
                    text: t('sidebar_navigation_affiliate'),
                    hidden: affiliateHidden,
                },
            ];
        } else {
            return [];
        }
    }
    const location = useLocation();
    const links = useMemo(
        () =>
            getItems().map((link: any, key: number) => {
                const href = ensureLanguageInPath({path: link.url, language: i18n.language});
                if (!link.hidden) {
                    const isSelected = location.pathname.startsWith(href);
                    return (
                        <StyledMenuItem
                            key={key}
                            disabled={link.disabled}
                            onClick={() => handleNavigate(!link.disabled, href, link.navigate)}
                            selected={isSelected}
                        >
                            <ListItemIcon>{link.icon}</ListItemIcon>
                            <ListItemText>{link.text}</ListItemText>
                        </StyledMenuItem>
                    );
                }
            }),
        [i18n.language, pendingTickets, user, profileData]
    );

    const handleNavigate = (shouldNavigate, to: string, nav: any) => {
        if (shouldNavigate) {
            if (location.pathname === to) {
                navigate(0);
            } else {
                navigate(to, nav);
                closeDrawer?.();
            }
        }
    };

    const displayuserDiscountAndLimitWidget =
        !isWallet || (profileData?.verified && profileData?.wallet_policy_consent);

    return (
        <Stack sx={{pr: {xs: 1, lg: 3}}}>
            <MenuList>{links}</MenuList>
            {displayuserDiscountAndLimitWidget && <UserDiscountAndLimit />}
        </Stack>
    );
}
