import {getConfigValue} from '@simplecoin/core';
import {ReactFrontendSettings} from '~/src/config/FrontendSettingsInterface';

/**
 * Returned boolean value of whether brand matches one from config.json
 * @param brand
 */
export function isBrand(brand: string): boolean {
    return brand.toLowerCase() === getConfigValue<string, ReactFrontendSettings>('brand', '').toLowerCase();
}

export function getAppVersion(): string | null {
    try {
        // @ts-ignore get value from head tag with head id
        const appV = document?.getElementById?.('head').getAttribute('data-app-v');
        return appV?.split('-')[0] ?? null;
    } catch (e) {
        if (getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.error('Failed to get app version', e);
        }
        return null;
    }
}
