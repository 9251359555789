import {fetchGeneric} from '~/src/helpers/action';
import {ensureWithRegion} from '@simplecoin/core';

export const REQUEST_FETCH_SETTINGS = 'REQUEST_FETCH_SETTINGS';
export const REQUEST_FETCH_SETTINGS_SUCCESS = 'REQUEST_SETTINGS_SUCCESS';
export const REQUEST_FETCH_SETTINGS_FAILURE = 'REQUEST_SETTINGS_FAILURE';

export const REQUEST_UPDATE_SETTINGS = 'REQUEST_UPDATE_SETTINGS';
export const REQUEST_UPDATE_SETTINGS_SUCCESS = 'REQUEST_UPDATE_SETTINGS_SUCCESS';
export const REQUEST_UPDATE_SETTINGS_FAILURE = 'REQUEST_UPDATE_SETTINGS_FAILURE';
export const REQUEST_UPDATE_SETTINGS_INVALIDATE = 'REQUEST_UPDATE_SETTINGS_INVALIDATE';

export interface FetchUpdateSettingsProps {
    [name: string]: number | string;
}

/**
 * Fetch user's settings
 */
export function fetchAccountSettings() {
    return fetchGeneric({
        method: 'get',
        url: '/setting',
        actions: {
            pre: REQUEST_FETCH_SETTINGS,
            success: REQUEST_FETCH_SETTINGS_SUCCESS,
            failure: REQUEST_FETCH_SETTINGS_FAILURE,
        },
        failureUrl: '/error',
    });
}

/**
 * Update single or multiple settings at once.
 */
export function fetchUpdateAccountSettings(data: FetchUpdateSettingsProps) {
    if (typeof data.language === 'string') {
        data.language = ensureWithRegion(data.language);
    }

    return fetchGeneric({
        method: 'post',
        url: '/setting/update',
        data,
        actions: {
            pre: REQUEST_UPDATE_SETTINGS,
            success: REQUEST_UPDATE_SETTINGS_SUCCESS,
            failure: REQUEST_UPDATE_SETTINGS_FAILURE,
            invalidate: REQUEST_UPDATE_SETTINGS_INVALIDATE,
        },
    });
}
