import {Recipients} from '~/src/typings/endpoints/recipients/Recipients';
import {
    REQUEST_RECIPIENTS,
    REQUEST_RECIPIENTS_FAILURE,
    REQUEST_RECIPIENTS_SUCCESS,
} from '~/src/pages/recipient/RecipientActions';
import {ReducerEnvelope} from '@simplecoin/core';

export interface RecipientReducerInterface {
    index: ReducerEnvelope<Recipients>;
}

/**
 *
 */
export default function (
    state: RecipientReducerInterface = {index: {isFetching: true}},
    action
): RecipientReducerInterface {
    switch (action.type) {
        // Get recipients
        case REQUEST_RECIPIENTS:
            return {...state, index: {...state.index, isFetching: true}};
        case REQUEST_RECIPIENTS_SUCCESS:
            return {...state, index: {isFetching: false, ...action.payload}};
        case REQUEST_RECIPIENTS_FAILURE:
            return {...state, index: {isFetching: false, ...action.payload}};

        default:
            return state;
    }
}
