import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface BreadcrumbData {
    category?: string;
    categoryHref?: string;
    subCategory?: string;
    subCategoryHref?: string;
    currentPage?: string;
    currentPageHref?: string;
}

export interface BreadcrumbActionHolder {
    refreshOnLastBreadcrumbClick?: () => void;
}

// Define a type for the slice state
interface BreadcrumbsState {
    data: BreadcrumbData;
    previous: BreadcrumbData;
}

// Define the initial state using that type
const initialState: BreadcrumbsState = {
    data: {},
    previous: {},
};

export function buildBreadcrumbBasedOnPrevious(
    currentPage: string,
    previousBreadcrumb?: BreadcrumbData
): BreadcrumbData {
    if (previousBreadcrumb) {
        if (previousBreadcrumb?.category) {
            return {
                category: previousBreadcrumb.category,
                categoryHref: previousBreadcrumb.categoryHref,
                subCategory: previousBreadcrumb.currentPage,
                subCategoryHref: previousBreadcrumb.currentPageHref,
                currentPage: currentPage,
            };
        } else {
            return {
                category: previousBreadcrumb.currentPage,
                categoryHref: previousBreadcrumb.currentPageHref,
                currentPage: currentPage,
            };
        }
    } else {
        return {
            currentPage: currentPage,
        };
    }
}

export const breadcrumbSlice = createSlice({
    name: 'breadcrumbs',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        invalidateBreadcrumbs: (state) => {
            if (state.data.currentPage || state.data.category) {
                state.previous = state.data;
            }
            state.data = {};
        },
        updateBreadcrumbs: (state, action: PayloadAction<BreadcrumbData>) => {
            state.data = action.payload;
        },
    },
});

export const {invalidateBreadcrumbs, updateBreadcrumbs} = breadcrumbSlice.actions;
