import {MenuItem, OutlinedInput, Select} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface TimeframeDropdownProps {
    value: number | string;
    name: string;
    id: string;
    onChange: (e: React.ChangeEvent<any>) => void;
}

/**
 * Renders timeframe dropdown.
 *
 * @param {number | string} value
 * @param {(e: React.ChangeEvent<any>) => void} onChange
 * @param {string} name
 * @param {string} id
 * @return {any}
 * @constructor
 */
export default function TimeframeDropdown({value, onChange, name, id}: TimeframeDropdownProps) {
    const {t} = useTranslation();

    return (
        <Select value={value} onChange={onChange} id={id} size='small' input={<OutlinedInput name={name} id={name} />}>
            <MenuItem value='1440'>{t('chart_timeframe_option_day')}</MenuItem>
            <MenuItem value='10080'>{t('chart_timeframe_option_week')}</MenuItem>
            <MenuItem value='43200'>{t('chart_timeframe_option_month')}</MenuItem>
            <MenuItem value='129600'>{t('chart_timeframe_option_3months')}</MenuItem>
            <MenuItem value='518400'>{t('chart_timeframe_option_year')}</MenuItem>
            <MenuItem value='-365'>{t('chart_timeframe_option_year_to_date')}</MenuItem>
            <MenuItem value='-42'>{t('chart_timeframe_option_all')}</MenuItem>
        </Select>
    );
}

TimeframeDropdown.displayName = 'TimeframeDropdown';
