import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    Dialog as MaterialDialog,
    IconButton,
    DialogProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface InvokerProps {
    handleOpen: () => void;
    handleClose: () => void;
}

type DialogActionsFunction = (invokerProps: InvokerProps) => React.ReactElement;

interface DialogPropsInterface {
    dialogContent?: React.ReactElement | DialogActionsFunction;
    dialogTitle?: React.ReactElement;
    dialogActions?: React.ReactElement;
    invoker?: (props: InvokerProps) => React.ReactElement;
    withCancelButton?: boolean;
    setCloseDialogFunction?: (closeDialog: () => void) => void;
    onCloseDialog?: () => void;
    dialogProps?: DialogProps;
    children?: any;
}

export interface DialogInterface {
    openDialog: () => void;
    closeDialog: () => void;
}

/**
 * Renders Dialog and handles basic internal workings
 *
 * Usage example:
 *
 *   const qrScannerInvoker = ({handleClose, handleOpen}: InvokerProps) => {
 *      closeQrDialog = handleClose;
 *      return (
 *       <Button variant='outlined' color='secondary' onClick={handleOpen} icon={<PhotoCameraIcon />}>
 *            <Typography variant='button'>{t('scan_address')}</Typography>
 *         </Button>
 *      );
 *   };
 *
 *    <Dialog
 *      dialogContent={<QrScanner onSuccessfulScan={onSuccessfulScanCallback} />}
 *      dialogTitle={t('scan_address')}
 *      invoker={qrScannerInvoker}
 *    />
 */
const Dialog = forwardRef(
    (
        {
            invoker,
            dialogContent,
            dialogActions,
            dialogTitle,
            withCancelButton = true,
            setCloseDialogFunction,
            children,
            onCloseDialog,
            dialogProps,
        }: DialogPropsInterface,
        ref
    ) => {
        const [dialogOpen, setDialogOpen] = useState(false);
        const {t} = useTranslation();

        /**
         * Handles dialog open
         */
        function handleOpen() {
            setDialogOpen(true);
        }

        /**
         * Handles dialog close
         */
        const handleClose = useCallback(() => {
            setDialogOpen(false);
            onCloseDialog?.();
        }, []);

        // expose close and open functions
        useImperativeHandle(ref, () => ({
            openDialog() {
                setDialogOpen(true);
            },
            closeDialog() {
                setDialogOpen(false);
            },
        }));

        useEffect(() => {
            setCloseDialogFunction?.(handleClose);
        }, [handleClose]);

        const invokedElement = typeof invoker === 'function' ? invoker({handleOpen, handleClose}) : null;

        const actions = (
            <DialogActions
                sx={{
                    justifyContent: {xs: 'center', md: 'flex-end'},
                    gap: {xs: 'none', md: '8px'},
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: {xs: 'stretch', md: 'baseline'},
                }}
            >
                {withCancelButton && (
                    <Button variant='outlined' color='secondary' onClick={handleClose}>
                        {t('button_close')}
                    </Button>
                )}
                {dialogActions}
            </DialogActions>
        );

        return (
            <>
                {invokedElement}

                <MaterialDialog onClose={handleClose} open={dialogOpen} maxWidth='lg' {...dialogProps}>
                    <DialogTitle variant='h3'>
                        {dialogTitle}
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 16,
                                top: 16,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    {typeof dialogContent === 'function' && (
                        <DialogContent>{dialogContent({handleClose, handleOpen})}</DialogContent>
                    )}
                    {typeof dialogContent !== 'function' && <DialogContent>{dialogContent}</DialogContent>}
                    {actions}
                    {children}
                </MaterialDialog>
            </>
        );
    }
);

Dialog.displayName = 'CoreDialog';

export default Dialog;
