import React, {memo} from 'react';
import {Box, Stack, styled, Tooltip, Typography, useTheme} from '@mui/material';
import useBackendSettings from '../../hooks/useBackendSettings';
import {Price} from '~/typings/Price';
import {Rate} from '~/typings/Rate';
import {useTranslation} from 'react-i18next';
import {Currencies} from '~/typings/currency';
import {formatAmount} from '~/helpers/price';
import {InfoIconTooltip} from '../InfoIconTooltip';
import Big from 'big.js';

const RateBox = styled(Stack)`
    font-size: 15px;
    line-height: 30px;
    border-radius: 16px;
`;

export interface RateDisclaimerProps {
    rate?: Rate;
    price?: Price;
    showFee?: boolean;
    timer?: number;
    // Flag that the rate cannot be fetched.
    errorFetchingRate?: boolean;
    isLanding?: boolean;
}

/**
 * Renders rate disclaimer.
 *
 */
const RateDisclaimer = memo(
    ({rate, price, showFee = true, timer, errorFetchingRate, isLanding = false}: RateDisclaimerProps) => {
        const {t, i18n} = useTranslation();
        const currencies: Currencies = useBackendSettings('currencies', {});
        const lang = i18n.language;
        const theme = useTheme();

        let rateText;

        // If the rate cannot be fetched
        if (errorFetchingRate) {
            return null;
        }

        // The rate is not fetched yet but there is no error
        if (!rate && !price) {
            return (
                <RateBox justifyContent='center' alignItems='center' width={1} padding={4}>
                    <Typography variant='textS' color={theme.cool.grey80}>
                        {t('new_price_is_being_loaded')}
                    </Typography>
                </RateBox>
            );
        }

        const fromCurrencyCode = (price?.from && currencies[price.from]?.name) ?? rate?.from;
        const toCurrencyCode = (price?.to && currencies[price.to]?.name) ?? rate?.to;

        const fromDecimals = currencies[fromCurrencyCode ?? '']?.fixed_decimals ?? 6;
        const toDecimals = currencies[toCurrencyCode ?? '']?.fixed_decimals ?? 6;

        type R = (Price & Rate) | undefined;
        // @ts-ignore
        const reducer: R = price ? price : rate;

        if (!reducer || !fromCurrencyCode || !toCurrencyCode) {
            return null;
        }

        const inFee = parseFloat(reducer?.in_absolute_fee);
        const outFee = parseFloat(reducer?.out_absolute_fee);

        const fromIsCrypto = currencies[fromCurrencyCode]?.type === 'crypto';
        const toIsCrypto = currencies[toCurrencyCode]?.type === 'crypto';
        const cryptoToCrypto = fromIsCrypto && currencies[toCurrencyCode]?.type === 'crypto';
        // const formattedRate = formatAmount(reducer.rate_value, fromDecimals, lang);
        const usedRate = price ? (isLanding ? reducer.raw_rate : reducer.discounted_raw_rate) : reducer.rate;
        const usedRateInverse = price
            ? isLanding
                ? reducer.raw_rate_inverse
                : reducer.discounted_raw_rate_inverse
            : reducer.rate_inverse;
        const rateValue = price
            ? isLanding
                ? reducer.raw_rate_value
                : reducer.discounted_raw_rate_value
            : reducer.rate_value;
        const rateValueInverse = price
            ? isLanding
                ? reducer.raw_rate_value_inverse
                : reducer.discounted_raw_rate_value_inverse
            : reducer.rate_value_inverse;

        const formattedRateValue = formatAmount(rateValue, fromDecimals, lang);
        const formattedRateValueInverse = formatAmount(rateValueInverse, toDecimals, lang);

        if (cryptoToCrypto) {
            // should have both ways
            rateText = (
                <Stack direction='column'>
                    <Typography variant='textS' color={theme.cool.grey80} fontWeight={700}>
                        {`${formattedRateValue} ${fromCurrencyCode} = ${usedRate} ${toCurrencyCode}`}
                    </Typography>
                    <Typography variant='textS' color={theme.cool.grey80} fontWeight={700}>
                        {` ${formattedRateValueInverse} ${toCurrencyCode} = ${usedRateInverse} ${fromCurrencyCode}`}
                    </Typography>
                </Stack>
            );
        } else if (fromIsCrypto) {
            // 1 LTC = 3500 CZK

            const shouldRound = !toIsCrypto && usedRate > 100;
            const roundedRate = shouldRound ? Math.round(usedRate) : usedRate;

            rateText = (
                <Typography variant='textS' color={theme.cool.grey80} fontWeight={700}>
                    {` ${formattedRateValue} ${fromCurrencyCode} = ${formatAmount(
                        roundedRate,
                        toDecimals,
                        lang
                    )} ${toCurrencyCode}`}
                </Typography>
            );
        } else {
            // shown as  1 BTC = 1 200 354 CZK
            let roundedInverseRate = usedRateInverse;

            if (currencies[fromCurrencyCode]?.type === 'fiat' && usedRateInverse > 100) {
                roundedInverseRate = Math.round(usedRateInverse);
            }

            const formattedRateInverse = formatAmount(roundedInverseRate, fromDecimals, lang);

            rateText = (
                <>
                    <Typography variant='textS' color={theme.cool.grey80} fontWeight={700}>
                        {`${formattedRateValueInverse} ${toCurrencyCode} = ${formattedRateInverse} ${fromCurrencyCode}`}
                    </Typography>
                </>
            );
        }

        const noFee = price && inFee === 0 && outFee === 0;

        const showFeeTooltip = (
            <Stack sx={{color: '#fff'}}>
                {reducer.in_absolute_fee && (
                    <Stack direction='row'>
                        <Stack mr={1}>
                            {fromIsCrypto ? t('exchange_form_in_fee_crypto') : t('exchange_form_in_fee_fiat')}
                        </Stack>
                        <Stack>
                            {`${formatAmount(reducer?.in_absolute_fee, fromDecimals, lang)} ${fromCurrencyCode}`}
                        </Stack>
                    </Stack>
                )}

                {reducer.out_absolute_fee && (
                    <Stack direction='row'>
                        <Stack mr={1}>
                            {toIsCrypto ? t('exchange_form_out_fee_crypto') : t('exchange_form_out_fee_fiat')}
                        </Stack>
                        <Stack>
                            {`${formatAmount(reducer?.out_absolute_fee, toDecimals, lang)} ${toCurrencyCode}`}
                        </Stack>
                    </Stack>
                )}

                <Stack direction='row'>
                    <Stack mr={1}>{t('exchange_form_total_fee')}</Stack>
                    <Stack>{`≈ ${formatAmount(reducer?.in_out_absolute_in_eur, 2, lang)} EUR`}</Stack>
                </Stack>
            </Stack>
        );

        const feePercentage = price ? Big(price.fee_percentage).times(100).round(0) : Big(0);

        return (
            <>
                <RateBox justifyContent='center' alignItems='center' width={1} padding={1}>
                    <Stack direction='row' justifyContent='center' width='100%'>
                        {price && showFee && (
                            <Stack>
                                {noFee ? (
                                    <Typography align='center' variant='text'>
                                        {t('No transaction fees')}
                                    </Typography>
                                ) : (
                                    <Stack
                                        direction='row'
                                        spacing={1}
                                        justifyContent={{xs: 'space-between', md: 'flex-end'}}
                                        alignItems='center'
                                    >
                                        {feePercentage.lt(1) ? (
                                            <Typography variant='body2'>
                                                {t('exchange_form_fee_label_included')}
                                            </Typography>
                                        ) : (
                                            <Typography variant='body2'>
                                                {t('exchange_fee_percentage_info', {feePercentage})}
                                            </Typography>
                                        )}
                                        <InfoIconTooltip text={showFeeTooltip} width={21} height={21} />
                                    </Stack>
                                )}
                            </Stack>
                        )}
                    </Stack>
                    <Stack direction='column' justifyContent='center' alignItems='center' width='100%'>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            {rateValue && rateText}
                            {timer !== undefined && (
                                <Tooltip title={t('price_refresh_timer_tooltip')}>
                                    <Box width={20}>
                                        <Typography
                                            variant='body2'
                                            color='cool.gray[70]'
                                            fontSize={13}
                                            sx={{textDecoration: 'underline dotted', textUnderlineOffset: '3px'}}
                                        >
                                            {timer}s
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            )}
                        </Stack>
                    </Stack>
                </RateBox>
            </>
        );
    }
);

RateDisclaimer.displayName = 'RateDisclaimer';

export default RateDisclaimer;
