import React from 'react';
import {styled} from '@mui/material';
import swapIcon from '../../assets/images/swap-icon.svg';
import {CustomIcon} from '../../typings/IconsTypings';

const HorizontalSwapImg = styled('img')`
    transform: rotate(90deg);
    box-shadow: '0px 4px 8px rgba(42, 28, 82, 0.08)';
`;

export const HorizontalSwapIcon = ({height = 30, width = 30, ...rest}: CustomIcon) => {
    return <HorizontalSwapImg src={swapIcon} height={height} width={width} {...rest} />;
};
