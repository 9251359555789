import CurrencyField from './CurrencyField';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IconButton, styled} from '@mui/material';
import {SwapIcon} from '../icons/SwapIcon';
import {HorizontalSwapIcon} from '../icons/HorizontalSwipeIcon';
import {useCurrency} from '~/hooks/currency';

const SwapWrapper = styled('div')`
    cursor: pointer;
    width: 100%;
    position: relative;
`;

interface ExchangeFormCurrencySelectsProps {
    onFromCurrencyChange: (e: any) => void;
    onFromAmountChange: (e: any) => void;
    swapCurrencies: () => void;
    onToCurrencyChange: (e: any) => void;
    onToAmountChange: (e: any) => void;
    toCurrencyCode?: string;
    fromCurrencyCode?: string;
    onBlur: (e: any) => void;
    fromAmount?: number;
    toAmount?: number;
    errors: Record<string, string>;
    direction?: 'row' | 'column';
}

const fromAmountProps = {
    id: 'from-amount',
    name: 'fromAmount',
};

const fromCurrencyProps = {
    id: 'from-currency',
    name: 'fromCurrencyCode',
};

const toAmountProps = {
    id: 'to-amount',
    name: 'toAmount',
};

const toCurrencyProps = {
    id: 'to-currency',
    name: 'toCurrencyCode',
};

/**
 * Handles common exchange form part for ExchangeAuthContainer and ExchangeGuestContainer
 */
const ExchangeFormCurrencySelects = memo(
    ({
        onFromCurrencyChange,
        onFromAmountChange,
        swapCurrencies,
        onToCurrencyChange,
        onToAmountChange,
        onBlur,
        toCurrencyCode,
        fromCurrencyCode,
        fromAmount,
        toAmount,
        errors,
        direction = 'column',
    }: ExchangeFormCurrencySelectsProps) => {
        const {t} = useTranslation();
        const toCurrency = useCurrency({code: toCurrencyCode});
        const fromCurrency = useCurrency({code: fromCurrencyCode});

        const {fromCurrencyError, toCurrencyError, fromAmountError, toAmountError} = useMemo(() => {
            return {
                fromCurrencyError: errors?.fromCurrencyCode,
                toCurrencyError: errors?.toCurrencyCode,
                fromAmountError: errors?.fromAmount ?? errors?.from_amount,
                toAmountError: errors?.toAmount ?? errors?.to_amount,
            };
        }, [errors]);

        const swapElement =
            direction === 'column' ? (
                <SwapWrapper onClick={swapCurrencies} sx={{height: direction === 'column' ? '10px' : 'auto'}}>
                    <SwapIcon />
                </SwapWrapper>
            ) : (
                <IconButton
                    sx={{
                        width: '42px',
                        height: '42px',
                        boxShadow: '0px 3.41667px 6.83333px rgba(42, 28, 82, 0.08)',
                        padding: '10px',
                        marginLeft: '16px',
                        marginRight: '16px',
                    }}
                    onClick={swapCurrencies}
                >
                    <HorizontalSwapIcon width={20} height={20} />
                </IconButton>
            );

        return (
            <>
                <div style={{margin: '5px 0'}}>
                    <CurrencyField
                        currencyCode={fromCurrency?.name ?? ''}
                        onCurrencyChange={onFromCurrencyChange}
                        amountLabel={t('exchange_form_from_amount_label')}
                        amount={fromAmount}
                        amountPlaceholder='0'
                        onAmountChange={onFromAmountChange}
                        handleBlur={onBlur}
                        currencyError={fromCurrencyError}
                        amountError={fromAmountError}
                        amountFieldProps={fromAmountProps}
                        currencySelectProps={fromCurrencyProps}
                        currencyDirection='from'
                    />
                </div>

                {swapElement}

                <div style={{margin: '5px 0'}}>
                    <CurrencyField
                        currencyCode={toCurrency?.name ?? ''}
                        onCurrencyChange={onToCurrencyChange}
                        amountLabel={t('exchange_form_to_amount_label')}
                        amount={toAmount}
                        amountPlaceholder='0'
                        onAmountChange={onToAmountChange}
                        handleBlur={onBlur}
                        currencyError={toCurrencyError}
                        amountError={toAmountError}
                        amountFieldProps={toAmountProps}
                        currencySelectProps={toCurrencyProps}
                        currencyDirection='to'
                    />
                </div>
            </>
        );
    }
);

ExchangeFormCurrencySelects.displayName = 'ExchangeFormCurrencySelects';

export default ExchangeFormCurrencySelects;
