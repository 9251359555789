import useBackendSettings from './useBackendSettings';
import {Currencies, Currency} from '~/typings/currency';

interface UseCurrencyProps {
    code?: string;
    id?: number;
}

export function useCurrency({code, id}: UseCurrencyProps): Currency | null {
    const currencies = useBackendSettings<Currencies>('currencies', {});

    if (code) {
        return currencies[code.toUpperCase()] ?? null;
    } else if (id) {
        return currencies[id] ?? null;
    } else {
        return null;
    }
}
export function useCurrencyId(code?: string): number | null {
    const currencies = useBackendSettings<Currencies>('currencies', {});

    if (code) {
        return currencies[code.toUpperCase()]?.id ?? null;
    } else {
        return null;
    }
}
export function useCurrencyCode(id?: number): string | null {
    const currencies = useBackendSettings<Currencies>('currencies', {});

    if (id) {
        return currencies[id]?.name ?? null;
    } else {
        return null;
    }
}

/**
 * Will return all currencies from settings in format {currencyCode: currencyObject}
 */
export function useCurrencies(): Currencies {
    const currencies = useBackendSettings<Currencies>('currencies', {});
    const filteredCurrencies: {[key: string]: Currency} = {};

    Object.keys(currencies).flatMap((key) => {
        if (key.length >= 3) {
            // should be a currency code
            const currencyObject = currencies[key];
            if (currencyObject) {
                filteredCurrencies[key] = currencyObject;
            }
        }
    });

    return filteredCurrencies;
}

/**
 * Will return all currencies from settings in format {currencyCode: currencyObject}
 * type `from` indicates that only currencies that the exchange is buying are returned, `to` only those that it sells
 */
export function useCurrenciesArray(type?: 'from' | 'to'): Array<Currency> {
    const currencies = useBackendSettings<Currencies>('currencies', {});
    const filteredCurrencies: {[key: string]: Currency} = {};

    Object.keys(currencies).flatMap((key) => {
        if (key.length >= 3) {
            // should be a currency code
            const currencyObject = currencies[key];
            if (currencyObject && !currencyObject.deleted) {
                filteredCurrencies[key] = currencyObject;
            }
        }
    });

    const currencyArray = Object.values(filteredCurrencies);

    if (type === 'from') {
        return currencyArray.filter((currency: Currency) => currency.is_in);
    } else if (type === 'to') {
        return currencyArray.filter((currency: Currency) => currency.is_out);
    } else {
        return currencyArray.filter((currency: Currency) => currency.is_out || currency.is_in);
    }
}
