import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchGeneric, handleReducerCases, ThunkApi, ReducerEnvelope} from '@simplecoin/core';

export interface TradeLimit {
    uuid: string;
    windowInDays: number;
    amount: string;
    remainingAmount: string;
}

export interface TradeLimitExtension {
    amount?: string;
}

export interface TradeLimitsInfo {
    currency: string;
    allowedTrading: string;
    limits: Array<TradeLimit>;
    extension?: TradeLimitExtension;
}

export interface TradeLimitInterface {
    info: ReducerEnvelope<TradeLimitsInfo>;
}

const initialState: TradeLimitInterface = {
    info: {isFetching: false},
};

export const fetchTradeLimits = createAsyncThunk<ReducerEnvelope<TradeLimitsInfo>>(
    'limits/info',
    async (_props, thunkApi) => {
        const response = await fetchGeneric(
            {
                url: '/trade-limits/info',
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

const slice = createSlice({
    name: 'tradeLimits',
    initialState,
    extraReducers: (builder) => {
        handleReducerCases(fetchTradeLimits, 'info', builder);
    },
    reducers: {
        invalidateTradeLimits: (state) => {
            state.info = initialState.info;
        },
    },
});

export const {actions, reducer} = slice;
export const {invalidateTradeLimits} = actions;
