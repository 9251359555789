import React from 'react';
import {useBackendSettings} from '@simplecoin/core';
import {useTranslation} from 'react-i18next';
import Notification from './Notification';

/**
 * Notifications object where the string in the array are HTML
 */
export interface AdminNotifications {
    [language: string]: string[];
}

/**
 * Displays notifications taken from settings. In case of the notification not being evailable for current language,
 * english is displayed
 *
 * @constructor
 */
function NotificationList() {
    const {i18n} = useTranslation();
    const notifications: AdminNotifications = useBackendSettings('notifications', {});
    const language = notifications[i18n.language] ? i18n.language : 'en';
    return (
        <div id='notification-list'>
            {notifications[language] &&
                notifications[language].map((notification, key) => <Notification key={key} content={notification} />)}
        </div>
    );
}

NotificationList.displayName = 'NotificationList';

export default React.memo(NotificationList);
