import {IconButton, TextField, Tooltip} from '@mui/material';
import copyIcon from '../assets/images/copy-icon.svg';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const popperProps = {disablePortal: true};

export default function TextFieldWithCopy({InputProps = {}, value, ...props}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const copyButton = (
        <Tooltip
            PopperProps={popperProps}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={t('copied')}
        >
            <div>
                <CopyToClipboard text={value}>
                    <IconButton>
                        <img src={copyIcon} />
                    </IconButton>
                </CopyToClipboard>
            </div>
        </Tooltip>
    );

    return (
        <TextField
            value={value}
            InputProps={{
                endAdornment: copyButton,
                ...InputProps,
            }}
            {...props}
        />
    );
}
