import React, {ForwardedRef} from 'react';
import {useTranslation} from 'react-i18next';
import OrderReturnFiatForm from './OrderReturnFiatForm';
import {Currency} from '../../typings/currency';
import {Order} from '../../typings/Order';
import {Button, Typography} from '@mui/material';
import Dialog, {InvokerProps} from '../Dialog';
import OrderReturnCryptoForm from './OrderReturnCryptoForm';
import CancelIcon from '@mui/icons-material/Cancel';
import {CryptoAddressDropdownRenderProps} from '~/typings/ExchangeFormTypings';

export interface OrderReturnButtonWithFormProps {
    order: Order;
    fromCurrency: Currency;
    token?: string;
    buttonChildren?: React.ReactElement;
    cryptoAddressDropdown?: (props: CryptoAddressDropdownRenderProps) => JSX.Element;
}

let closeDialog;
let dialogContent;

/** Renders return button which triggers opening of Return form
 *
 */
const OrderReturnButtonWithForm = React.forwardRef(
    (
        {order, fromCurrency, token, buttonChildren, cryptoAddressDropdown}: OrderReturnButtonWithFormProps,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        const {t} = useTranslation();

        if (fromCurrency && fromCurrency.type === 'fiat') {
            dialogContent = (
                <OrderReturnFiatForm
                    order={order}
                    fromCurrency={fromCurrency}
                    token={token}
                    handleClose={closeDialog}
                />
            );
        } else if (token) {
            dialogContent = <Typography variant='h5'>{t('sign_up_to_return_guest_order')}</Typography>;
        } else {
            dialogContent = (
                <OrderReturnCryptoForm
                    order={order}
                    fromCurrency={fromCurrency}
                    cryptoAddressDropdown={cryptoAddressDropdown}
                />
            );
        }

        const dialogInvoker = ({handleClose, handleOpen}: InvokerProps) => {
            closeDialog = handleClose;
            return (
                <Button
                    onClick={handleOpen}
                    data-testid='button-order-return'
                    ref={ref}
                    variant='text'
                    startIcon={<CancelIcon />}
                    color='primary'
                    size='small'
                >
                    {buttonChildren}
                </Button>
            );
        };
        return (
            <Dialog
                invoker={dialogInvoker}
                dialogContent={dialogContent}
                withCancelButton={false}
                dialogTitle={t('cancel_order_and_return_payment')}
            />
        );
    }
);

OrderReturnButtonWithForm.displayName = 'OrderReturnButtonWithForm';

export default OrderReturnButtonWithForm;
