/**
 * Saves kay value pair to local storage if available
 */
export function saveToLocalStorage({key = '', value = ''}: {key: string; value: string}): void {
    if (storageAvailable('localStorage')) {
        window.localStorage.setItem(key, value);
    }
}

/**
 * Retrieves value from local storage if available
 */
export function getItemFromLocalStorage({key = ''}: {key: string}): string | null {
    if (storageAvailable('localStorage')) {
        return window.localStorage.getItem(key);
    }

    return null;
}

/**
 * Saves kay value pair to session storage if available
 */
export function saveToSessionStorage({key = '', value = ''}: {key: string; value: string}): void {
    if (storageAvailable('sessionStorage')) {
        window.sessionStorage.setItem(key, value);
    }
}

/**
 * Retrieves value from session storage if available
 */
export function getItemFromSessionStorage({key = ''}: {key: string}): string | null {
    if (storageAvailable('sessionStorage')) {
        return window.sessionStorage.getItem(key);
    }

    return null;
}

/**
 * Check if storage is available
 */
function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
}
