import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchGeneric, ThunkApi} from '~/api';
import {handleReducerCases} from '~/helpers/redux';
import {LandingData, ReducerEnvelope} from '~/typings/Api';

interface CurrencyInfo {
    name: string;
    color: string;
    type: string;
    decimals: number;
}

export interface ChartRate {
    average?: string;
    last_sec: string;
}

export interface ViewPortInterface {
    applied: boolean;
    interval_length: number;
    rates_count: number;
    timeframe: string;
    timeframe_altered: boolean;
}

export interface ChartData {
    from: CurrencyInfo;
    to: CurrencyInfo;
    rates: ChartRate[];
    update_at: number;
    viewport_optimize: ViewPortInterface;
}

export interface ChartReducer {
    chartData: ReducerEnvelope<ChartData>;
    multichartData: ReducerEnvelope<LandingData>;
}

const initialState: ChartReducer = {
    chartData: {isFetching: false},
    multichartData: {isFetching: false},
};

export const fetchMultichartData = createAsyncThunk('multichartData', async (fiatCurrencyCode: string, thunkApi) => {
    const res = await fetchGeneric(
        {
            url: 'landing/data-v2',
            params: {fiat: fiatCurrencyCode},
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

interface FetchChartDataProps {
    exchange?: 'SimpleCoinApp';
    timeframe?: number;
    viewport_x?: number;
    interval: number;
    average?: number;
    since?: number;
    until?: number;
    from: string;
    to: string;
}

export const fetchChartData = createAsyncThunk(
    'chartData',
    async (
        {
            exchange = 'SimpleCoinApp',
            timeframe = 1440,
            viewport_x = 800,
            average = 1,
            from,
            to,
            since,
            until,
            interval,
        }: FetchChartDataProps,
        thunkApi
    ) => {
        const res = await fetchGeneric(
            {
                url: 'chart/pair-rates',
                params: {
                    exchange,
                    timeframe,
                    viewport_x,
                    interval,
                    average,
                    since,
                    until,
                    from,
                    to,
                },
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

const slice = createSlice({
    name: 'multichart',
    initialState,
    extraReducers: (builder) => {
        handleReducerCases(fetchChartData, 'chartData', builder, undefined, true);
        handleReducerCases(fetchMultichartData, 'multichartData', builder);
    },
    reducers: {},
});

export const {actions, reducer} = slice;
