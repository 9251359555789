import {put, takeEvery, all} from 'redux-saga/effects';
import {
    claimOrder,
    claimRegisteredOrder,
    fetchChangeRefundAddress,
    fetchChangeStatus,
    fetchRemoveLimit,
    fetchUpdateLimit,
    invalidateClaimOrder,
    invalidateClaimRegisteredOrder,
    invalidateRemoveLimit,
    invalidateSetRefundAddress,
    invalidateStatusChange,
    invalidateUpdateLimit,
    refetchOrder,
    invalidateAcceptPrice,
    fetchAcceptPrice,
} from './orderSlice';
import {invalidateWalletCreate, invalidateWalletDelete, invalidateWalletUpdate} from './walletSlice';
import {invalidateBankCreate, invalidateBankDelete, invalidateBankUpdate} from './bankSlice';
import {
    fetchCreateAuthOrder,
    fetchCreateGuestOrder,
    invalidateAuthOrderCreate,
    invalidateGuestOrderCreate,
} from './exchangeFormSlice';
import {
    invalidateResendActivationEmail,
    invalidateValidateEmailConfirmationToken,
    resendActivationEmail,
    validateEmailConfirmationToken,
} from './siteSlice';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* internalInvalidateStatusChange(action) {
    try {
        const {id, token} = action.meta.arg;
        yield put(invalidateStatusChange());
        // @ts-ignore
        yield put(refetchOrder({id, token}));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error in saga worker', e);
    }
}

function* invalidateFetchUpdateLimit(action) {
    const {id, token} = action.meta.arg;
    yield put(invalidateUpdateLimit());
    // @ts-ignore
    yield put(refetchOrder({id, token}));
}

/**
 * Generic invalidator function that calls the first item in arguments array
 * @param argsArray
 */
function* invalidator(argsArray: Array<any>) {
    const action = argsArray[0];
    yield put(action());
}

function* invalidateFetchRemoveLimit(action) {
    const {id, token} = action.meta.arg;
    yield put(invalidateRemoveLimit());
    // @ts-ignore
    yield put(refetchOrder({id, token}));
}

function* invalidateAuthExchange() {
    yield put(invalidateAuthOrderCreate());
}

function* invalidateGuestExchange() {
    yield put(invalidateGuestOrderCreate());
}

function* invalidateWallet(action) {
    try {
        switch (action.type) {
            case 'wallet/update/fulfilled':
                yield put(invalidateWalletUpdate());
                break;

            case 'wallet/delete/fulfilled':
                yield put(invalidateWalletDelete());
                break;
            case 'wallet/create/fulfilled':
                yield put(invalidateWalletCreate());
                break;
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error in saga worker', e);
    }
}

function* invalidateBank(action) {
    try {
        switch (action.type) {
            case 'bank/update/fulfilled':
                yield put(invalidateBankUpdate());
                break;

            case 'bank/delete/fulfilled':
                yield put(invalidateBankDelete());
                break;
            case 'bank/create/fulfilled':
                yield put(invalidateBankCreate());
                break;
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error in saga worker', e);
    }
}

/**
 * This function is responsible for triggering invalidation after certain actions are fulfilled
 * todo: handle rejection invalidation
 */
function* invalidatorSaga() {
    const walletActionsToBeInvalidated = [
        'wallet/create/fulfilled',
        'wallet/delete/fulfilled',
        'wallet/update/fulfilled',
    ];
    const bankActionsToBeInvalidated = ['bank/create/fulfilled', 'bank/delete/fulfilled', 'bank/update/fulfilled'];

    yield all([
        takeEvery(fetchChangeStatus.fulfilled.type, internalInvalidateStatusChange),
        takeEvery(
            (action) => walletActionsToBeInvalidated.some((strAction) => action.type.includes(strAction)),
            invalidateWallet
        ),
        takeEvery(
            (action) => bankActionsToBeInvalidated.some((strAction) => action.type.includes(strAction)),
            invalidateBank
        ),
        takeEvery(fetchCreateAuthOrder.fulfilled.type, invalidateAuthExchange),
        takeEvery(fetchCreateGuestOrder.fulfilled.type, invalidateGuestExchange),
        takeEvery(fetchUpdateLimit.fulfilled.type, invalidateFetchUpdateLimit),
        takeEvery(fetchRemoveLimit.fulfilled.type, invalidateFetchRemoveLimit),
        takeEvery(fetchChangeRefundAddress.fulfilled.type, invalidator, [invalidateSetRefundAddress]),
        takeEvery(claimOrder.fulfilled.type, invalidator, [invalidateClaimOrder]),
        takeEvery(claimRegisteredOrder.fulfilled.type, invalidator, [invalidateClaimRegisteredOrder]),
        takeEvery(resendActivationEmail.fulfilled.type, invalidator, [invalidateResendActivationEmail]),
        takeEvery(validateEmailConfirmationToken.fulfilled.type, invalidator, [
            invalidateValidateEmailConfirmationToken,
        ]),
        takeEvery(fetchAcceptPrice.fulfilled.type, invalidator, [invalidateAcceptPrice]),
    ]);
}

/**
 * This function should be run as sagaMiddleware.run(rootSaga); after store setup
 */
export function* rootSaga() {
    yield all([invalidatorSaga()]);
}
