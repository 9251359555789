/**
 * Check whether given item is type object
 * we do not use typeof var === 'object' because of the null being object bug
 * @param obj
 */
export function isObject(obj: any): boolean {
    return obj !== null && typeof obj === 'object';
}

/**
 * Java-like hash code for non-secure hash
 * @param value
 */
export function hashCode(value: string): number {
    let hash = 0;
    if (value.length === 0) {
        return hash;
    }
    for (let i = 0; i < value.length; i++) {
        const char = value.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}
