import React from 'react';
import {useTranslation} from 'react-i18next';
import Badge from './Badge';
import {OrderStatusName} from '~/src/typings';
import {STATUSES} from '~/src/config/localParams';

export interface OrderStatusBadgeProps {
    status: OrderStatusName;
}

/**
 * Get status color.
 *
 * @param {string} status Status name for which to get color.
 * @return {null|*} Returns HEX color format.
 */
export function getStatusColor(status: string): string | null {
    const statusInfo = STATUSES[status] || null;

    if (statusInfo === null) {
        return null;
    }

    return statusInfo.color;
}

/**
 * Get translatable status name.
 *
 * @param {string} status Status name.
 * @return {string}
 */
export function getI18nStatusNameKey(status: string): string {
    const statusInfo = STATUSES[status] || null;

    if (statusInfo === null) {
        return '';
    }

    return statusInfo.name_i18nKey;
}

/**
 * Get translatable base/direction description name.
 *
 * @param {string} status Status name.
 * @param {bool} direction Boolean flag whether to return direction status description instead of base one.
 * @return {null|string} translatable key
 */
export function getI18nStatusDescriptionKey(status: string, direction?: boolean): string | null {
    const statusInfo = STATUSES[status] || null;

    if (statusInfo === null) {
        return null;
    }

    if (typeof direction === 'undefined') {
        direction = false;
    }

    if (!direction) {
        return statusInfo.baseDescription_i18nKey;
    }

    return statusInfo.directionDescription;
}

/**
 * Get status direction by name.
 *
 * @param {string} status Status name.
 */
export function getStatusDirection(status: string) {
    if (!STATUSES[status]) {
        return null;
    }
    return STATUSES[status].type;
}

/**
 * Renders badge with specified status translated into correct language.
 * todo: refactor this component to remove complicated helpers
 * @param {string} status Status to be checked.
 * @return {null|*}
 * @constructor
 */
export default function OrderStatusBadge({status, ...rest}: OrderStatusBadgeProps) {
    const {t} = useTranslation();

    if (!status) {
        return null;
    }

    const color = getStatusColor(status);
    const name = t(getI18nStatusNameKey(status));
    const i18nTitle: string | null = getI18nStatusDescriptionKey(status);

    const title = i18nTitle ? t(i18nTitle) : null;

    if (!color || !name || !title) {
        return null;
    }

    const statusDirection = getStatusDirection(status);

    if (!rest['data-testid']) {
        rest['data-testid'] = `order-status-badge-${statusDirection}`;
    }

    return (
        <Badge textColor='#000000' bgColor={color} {...rest}>
            {name}
        </Badge>
    );
}

OrderStatusBadge.displayName = 'OrderStatusBadge';
