import {findLastIndex} from './array';

/**
 * Structure describing single data item for series of type Line or Area
 */
export interface LineData {
    time: number;
    /**
     * Price value of data item
     */
    value: number;
}

/**
 * Counts percentage price change between first and last data point
 *
 * @param {LineData} rates
 * @return {number | undefined}
 */
export function countPercentageChange(rates?: LineData[]): number {
    if (rates?.length) {
        return countPercentageChangeLineDataArray({array: rates});
    }
    return 0;
}

/**
 * Counts percentage price change between first and last data point of given array
 * @param {LineData[]} array
 * @return {number}
 */
export function countPercentageChangeLineDataArray({array}: {array: LineData[]}): number {
    if (array && array.length) {
        const validNumberPredicate = (dataPoint: LineData) =>
            typeof dataPoint.value === 'number' && !Number.isNaN(dataPoint.value);
        const firstValue = array.find(validNumberPredicate)?.value;
        const lastValueIndex = findLastIndex(array, validNumberPredicate);
        const lastValue = array[lastValueIndex]?.value;

        if (!firstValue || !lastValue) {
            return 0;
        }

        const change = (lastValue - firstValue) / firstValue;
        const percentual: number = change * 100;

        if (!Number.isNaN(percentual)) {
            return percentual;
        }
    }
    return 0;
}

/**
 * Converts array of strings into array of numbers
 * @param {string[]} stringArray
 * @return {number[]}
 */
function convertToNumberArray(stringArray: string[]): number[] {
    return stringArray.map((str) => Number.parseFloat(str));
}

/**
 * Count percentage change from number/string array
 * @param {string[] | number[]} array
 * @return {number}
 */
export function countPercentageChangeArray({
    array,
}: {
    array: (number | null)[] | (string | null)[];
}): number | string | undefined | null {
    if (!array || array.length === 0) {
        return null;
    }

    let numberArray: number[];

    if (typeof array[0] === 'string') {
        numberArray = convertToNumberArray(array as string[]);
    } else if (typeof array[0] === 'number') {
        numberArray = array as number[];
    } else {
        return 0;
    }

    const validNumberPredicate = (value: number) => typeof value === 'number' && !Number.isNaN(value) && value !== null;

    const firstValue = numberArray.find(validNumberPredicate);
    const lastValueIndex = findLastIndex(numberArray, validNumberPredicate);
    const lastValue = numberArray[lastValueIndex];

    if (firstValue === undefined || lastValue === undefined) {
        return 0;
    }

    const change = (lastValue - firstValue) / firstValue;
    const percentual: number = change * 100;

    if (!Number.isNaN(percentual)) {
        return percentual;
    }

    return null;
}

// Calculate interval between two data points in minutes based on timeframe in seconds
export function getInterval(timeframe: number | string): number {
    switch (parseInt(String(timeframe))) {
        case 1440:
            // day - 288 data points
            return 5;
        case 10080:
            // week - 672 data points
            return 15;
        case 43200:
            // month - 720 data points
            return 60;
        case 129600:
            // 3 months - 540 data points
            return 240;
        case 518400:
            // year - 360 data points
            return 1440;
        case -42:
            // all - 360+ data points
            return 1440;
        case -365:
            //ytd (from the beginning of this calendar year)  - x data points
            return 240;
        default:
            return 1;
    }
}
