import {fetchGeneric} from '~/src/helpers/action';

export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const REQUEST_CHANGE_PASSWORD_SUCCESS = 'REQUEST_CHANGE_PASSWORD_SUCCESS';
export const REQUEST_CHANGE_PASSWORD_FAILURE = 'REQUEST_CHANGE_PASSWORD_FAILURE';
export const REQUEST_CHANGE_PASSWORD_INVALIDATE = 'REQUEST_CHANGE_PASSWORD_INVALIDATE';

export const REQUEST_ENABLE_2FA = 'REQUEST_ENABLE_2FA';
export const REQUEST_ENABLE_2FA_SUCCESS = 'REQUEST_ENABLE_2FA_SUCCESS';
export const REQUEST_ENABLE_2FA_FAILURE = 'REQUEST_ENABLE_2FA_FAILURE';
export const REQUEST_ENABLE_2FA_INVALIDATE = 'REQUEST_ENABLE_2FA_INVALIDATE';

export const REQUEST_FETCH_2FA_SECRET = 'REQUEST_FETCH_2FA_SECRET';
export const REQUEST_FETCH_2FA_SECRET_SUCCESS = 'REQUEST_FETCH_2FA_SECRET_SUCCESS';
export const REQUEST_FETCH_2FA_SECRET_FAILURE = 'REQUEST_FETCH_2FA_SECRET_FAILURE';

export const REQUEST_DISABLE_2FA = 'REQUEST_DISABLE_2FA';
export const REQUEST_DISABLE_2FA_SUCCESS = 'REQUEST_DISABLE_2FA_SUCCESS';
export const REQUEST_DISABLE_2FA_FAILURE = 'REQUEST_DISABLE_2FA_FAILURE';
export const REQUEST_DISABLE_2FA_INVALIDATE = 'REQUEST_DISABLE_2FA_INVALIDATE';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';

export const REQUEST_DELETE_ACCOUNT = 'REQUEST_DELETE_ACCOUNT';
export const REQUEST_DELETE_ACCOUNT_SUCCESS = 'REQUEST_DELETE_ACCOUNT_SUCCESS';
export const REQUEST_DELETE_ACCOUNT_FAILURE = 'REQUEST_DELETE_ACCOUNT_FAILURE';

export const REQUEST_DISABLE_SOCIAL_LOGIN = 'REQUEST_DISABLE_SOCIAL_LOGIN';
export const REQUEST_DISABLE_SOCIAL_LOGIN_SUCCESS = 'REQUEST_DISABLE_SOCIAL_LOGIN_SUCCESS';
export const REQUEST_DISABLE_SOCIAL_LOGIN_FAILURE = 'REQUEST_DISABLE_SOCIAL_LOGIN_FAILURE';

export interface FetchChangePasswordProps {
    current_password: string;
    new_password: string;
    new_password_again: string;
    code?: string;
}

/**
 * Send request to change password.
 * @param current_password
 * @param new_password
 * @param new_password_again
 * @param code
 */
export function fetchChangePassword({
    current_password,
    new_password,
    new_password_again,
    code,
}: FetchChangePasswordProps) {
    return fetchGeneric({
        method: 'post',
        url: 'security/change-password',
        data: {
            current_password,
            new_password,
            new_password_again,
            code,
        },
        actions: {
            pre: REQUEST_CHANGE_PASSWORD,
            success: REQUEST_CHANGE_PASSWORD_SUCCESS,
            failure: REQUEST_CHANGE_PASSWORD_FAILURE,
            invalidate: REQUEST_CHANGE_PASSWORD_INVALIDATE,
        },
    });
}

/**
 * Send request to reset password.
 * @returns {function(*): Promise<AxiosResponse<any>|never>}
 */
export function fetchResetPassword() {
    return fetchGeneric({
        method: 'post',
        url: 'security/reset-password',
        actions: {
            pre: REQUEST_RESET_PASSWORD,
            success: REQUEST_RESET_PASSWORD_SUCCESS,
            failure: REQUEST_RESET_PASSWORD_FAILURE,
        },
    });
}

export interface FetchEnable2FaProps {
    secret: string;
    password: string;
    code: string;
}

/**
 * Request enabling two factor authentication
 * @param {string} secret 2FA secret token
 * @param {string} password - current password
 * @param {int} code - generated 2FA code
 */
export function fetchEnable2Fa({secret, password, code}: FetchEnable2FaProps) {
    return fetchGeneric({
        method: 'post',
        url: 'security/enable-2fa',
        data: {
            secret,
            password,
            code,
        },
        actions: {
            pre: REQUEST_ENABLE_2FA,
            success: REQUEST_ENABLE_2FA_SUCCESS,
            failure: REQUEST_ENABLE_2FA_FAILURE,
        },
        afterSuccess: REQUEST_ENABLE_2FA_INVALIDATE,
    });
}

export interface FetchDisable2FaProps {
    password: string;
    code: string;
}

/**
 * Request disabling two factor authentication
 * @param {string} password Current password
 * @param {int} code 2FA code
 */
export function fetchDisable2Fa({password, code}: FetchDisable2FaProps) {
    return fetchGeneric({
        method: 'post',
        url: 'security/disable-2fa',
        data: {
            password,
            code,
        },
        actions: {
            pre: REQUEST_DISABLE_2FA,
            success: REQUEST_DISABLE_2FA_SUCCESS,
            failure: REQUEST_DISABLE_2FA_FAILURE,
        },
        afterSuccess: REQUEST_DISABLE_2FA_INVALIDATE,
    });
}

/**
 * Request 2FA secret and data to display on QR code
 */
export function fetch2FaSecret() {
    return fetchGeneric({
        method: 'post',
        url: 'security/secret-2fa',
        actions: {
            pre: REQUEST_FETCH_2FA_SECRET,
            success: REQUEST_FETCH_2FA_SECRET_SUCCESS,
            failure: REQUEST_FETCH_2FA_SECRET_FAILURE,
        },
    });
}

/**
 * Request account to be deleted
 */
export function fetchDeleteAccount() {
    return fetchGeneric({
        method: 'delete',
        url: 'profile/delete',
        actions: {
            pre: REQUEST_DELETE_ACCOUNT,
            success: REQUEST_DELETE_ACCOUNT_SUCCESS,
            failure: REQUEST_DELETE_ACCOUNT_FAILURE,
        },
    });
}

/**
 * Request social login disable
 */
export function fetchDisableSocialLogin() {
    return fetchGeneric({
        method: 'post',
        url: 'user/disable-social',
        actions: {
            pre: REQUEST_DISABLE_SOCIAL_LOGIN,
            success: REQUEST_DISABLE_SOCIAL_LOGIN_SUCCESS,
            failure: REQUEST_DISABLE_SOCIAL_LOGIN_FAILURE,
        },
    });
}
