import {Box, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, useTheme} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getSimplifiedOrderStatus, SimplifiedOrderStatus} from '~/helpers/order';
import {OrderBaseStatus, OrderFinalStatus, StatusLog} from '~/typings/Order';

interface LifeCycleItem {
    /** Number used to determine whether a status is before or after another status. */
    order: number;

    /** True if lifecycle item is valid only if source currency of the exchange is crypto-currency. */
    incomingCryptoOnly?: boolean;

    /** Position of the status in the life-cycle. */
    position: 'first' | 'last' | 'middle';
}

// Metadata related to simplified order statuses within the order life-cycle.
const lifecycleItems: Map<SimplifiedOrderStatus, LifeCycleItem> = new Map([
    ['new', {order: 10, position: 'first', incomingCryptoOnly: false}],
    ['paidUnconfirmed', {order: 20, position: 'middle', incomingCryptoOnly: true}],
    ['paid', {order: 30, position: 'middle', incomingCryptoOnly: false}],
    ['dispatched', {order: 40, position: 'middle', incomingCryptoOnly: false}],
    ['done', {order: 50, position: 'last', incomingCryptoOnly: false}],
]);

interface SimplifiedOrderStatusHistoryItem {
    timestamp: number;
    simplifiedOrderStatus: SimplifiedOrderStatus;
}

interface OrderLifeCycleWidgetProps {
    simplifiedOrderStatus: SimplifiedOrderStatus;
    incomingCrypto: boolean;
    isSuspended: boolean;
    simplifiedStatusHistory?: Array<SimplifiedOrderStatusHistoryItem>;
}

export function mapOrderStatusHistory(statusLog: StatusLog[]): SimplifiedOrderStatusHistoryItem[] {
    const allowedStatuses = new Set([
        'new',
        'confirming_incoming',
        'confirming_outgoing',
        'dispatched',
        'received',
        'cancelled',
        'returned',
        'delivered',
    ]);

    const finalStatuses = new Set(['delivered', 'cancelled', 'returned']);

    return statusLog
        ?.filter((element) => allowedStatuses.has(element.status_name))
        .map((element) => {
            if (finalStatuses.has(element.status_name)) {
                return {
                    timestamp: element.created_at,
                    simplifiedOrderStatus: getSimplifiedOrderStatus(
                        'done',
                        'proceed',
                        element.status_name as OrderFinalStatus
                    ),
                };
            } else {
                return {
                    timestamp: element.created_at,
                    simplifiedOrderStatus: getSimplifiedOrderStatus(
                        element.status_name as OrderBaseStatus,
                        'proceed',
                        null
                    ),
                };
            }
        });
}

export default function OrderLifeCycleWidget({
    simplifiedOrderStatus,
    incomingCrypto,
    isSuspended,
    simplifiedStatusHistory,
}: OrderLifeCycleWidgetProps): JSX.Element {
    const {t} = useTranslation();
    const theme = useTheme();
    const sortedStatusHistory = simplifiedStatusHistory?.sort((itemA, itemB) => itemB.timestamp - itemA.timestamp);

    // Draw icon inside current life-cycle item
    function renderCurrentItem(simplifiedOrderStatus: string, isSuspended: boolean, height: number): JSX.Element {
        if (simplifiedOrderStatus === 'canceled') {
            // Draw cross x
            return (
                <g transform={'rotate(45 20 ' + height / 2 + ')'}>
                    <rect
                        width='10'
                        height='2'
                        x='15'
                        y={height / 2 - 1}
                        rx='1'
                        ry='1'
                        fill={theme.palette.orderStatus[simplifiedOrderStatus]}
                    />
                    <rect
                        width='2'
                        height='10'
                        x='19'
                        y={height / 2 - 5}
                        rx='1'
                        ry='1'
                        fill={theme.palette.orderStatus[simplifiedOrderStatus]}
                    />
                </g>
            );
        } else if (isSuspended) {
            // Draw pause icon
            return (
                <g>
                    <rect
                        width='3'
                        height='10'
                        x='15.5'
                        y={height / 2 - 5}
                        rx='1'
                        ry='1'
                        fill={theme.palette.orderStatus[simplifiedOrderStatus]}
                    />
                    <rect
                        width='3'
                        height='10'
                        x='21.5'
                        y={height / 2 - 5}
                        rx='1'
                        ry='1'
                        fill={theme.palette.orderStatus[simplifiedOrderStatus]}
                    />
                </g>
            );
        } else {
            // Draw dot
            return (
                <ellipse
                    cx='20'
                    cy={height / 2}
                    rx='5'
                    ry='5'
                    stroke='0'
                    fill={theme.palette.orderStatus[simplifiedOrderStatus]}
                />
            );
        }
    }

    // Render life-cycle item
    function renderLifecycleItem(
        lifecycleItem: SimplifiedOrderStatus,
        isSuspended: boolean,
        lifecycleItemStatus: 'passed' | 'current' | 'none' = 'none',
        position: 'first' | 'last' | 'middle' = 'middle'
    ): JSX.Element {
        // Height of the life-cycle element in px
        const height = 70;

        // If the life-cycle item is passed or current than try to fing a timestamp, when the state was reached.
        let historyItem: SimplifiedOrderStatusHistoryItem | undefined;
        if (lifecycleItemStatus === 'passed' || lifecycleItemStatus === 'current') {
            historyItem = sortedStatusHistory?.find((it) => it.simplifiedOrderStatus === lifecycleItem);
        }

        const tooltip = t('order_status_tooltip_' + lifecycleItem);
        let textColor;
        switch (lifecycleItemStatus) {
            case 'current':
                textColor = theme.palette.orderStatus[simplifiedOrderStatus];
                break;
            case 'none':
                textColor = theme.palette.grey[500];
                break;
            default:
                textColor = theme.palette.text.primary;
        }

        return (
            <ListItem sx={{p: 0, m: 0}} key={'lifecycleItem_' + lifecycleItem}>
                <ListItemIcon sx={{height: height + 'px', position: 'relative', color: '#c3c6d4'}}>
                    <svg
                        viewBox={'0, 0, 40, ' + height}
                        height={height}
                        style={{position: 'absolute', top: 0, left: '0'}}
                    >
                        {/* Draw the line up (connector up) except this is the first life-cycle item. */}
                        {position !== 'first' && (
                            <rect
                                x='16'
                                y='0'
                                width='8'
                                height={height / 2}
                                stroke='0'
                                fill={
                                    lifecycleItemStatus === 'none'
                                        ? theme.palette.grey[300]
                                        : theme.palette.orderStatus[simplifiedOrderStatus]
                                }
                            />
                        )}
                        {/* Draw the line down (connector down) except this is the last life-cycle item. */}
                        {position !== 'last' && (
                            <rect
                                x='16'
                                y={height / 2}
                                width='8'
                                height={height / 2}
                                stroke='0'
                                fill={
                                    lifecycleItemStatus === 'passed'
                                        ? theme.palette.orderStatus[simplifiedOrderStatus]
                                        : theme.palette.grey[300]
                                }
                            />
                        )}
                    </svg>
                    {/* Draw a filled dot if the lifecycle item is not current. */}
                    {lifecycleItemStatus !== 'current' && (
                        <svg
                            viewBox={'0, 0, 40, ' + height}
                            height={height}
                            style={{position: 'absolute', top: 0, left: '0'}}
                        >
                            <ellipse
                                cx='20'
                                cy={height / 2}
                                rx='10'
                                ry='10'
                                stroke='0'
                                fill={
                                    lifecycleItemStatus === 'none'
                                        ? theme.palette.grey[300]
                                        : theme.palette.orderStatus[simplifiedOrderStatus]
                                }
                            />
                        </svg>
                    )}
                    {/* Draw a circle with particular icon if the lifecycle item is current. */}
                    {lifecycleItemStatus === 'current' && (
                        <svg
                            viewBox={'0, 0, 40, ' + height}
                            height={height}
                            style={{position: 'absolute', top: 0, left: '0'}}
                        >
                            <ellipse
                                cx='20'
                                cy={height / 2}
                                rx='13'
                                ry='13'
                                stroke='0'
                                fill={theme.palette.orderStatus[simplifiedOrderStatus]}
                            />
                            <ellipse
                                cx='20'
                                cy={height / 2}
                                rx='10'
                                ry='10'
                                stroke='0'
                                fill={theme.palette.secondary.contrastText}
                            />
                            {renderCurrentItem(lifecycleItem, isSuspended, height)}
                        </svg>
                    )}
                </ListItemIcon>
                <Tooltip title={tooltip}>
                    <ListItemText
                        sx={{my: 0}}
                        primaryTypographyProps={{
                            variant: 'caption',
                            fontWeight: lifecycleItemStatus === 'current' ? 700 : 400,
                            color: textColor,
                        }}
                    >
                        {t('order_status_' + lifecycleItem)}
                        {historyItem && (
                            <Box>
                                <Typography fontSize={{xs: 13}} fontWeight={300} color={theme.palette.grey[500]}>
                                    {dayjs.unix(historyItem.timestamp).format('L LT')}
                                </Typography>
                            </Box>
                        )}
                    </ListItemText>
                </Tooltip>
            </ListItem>
        );
    }

    const lifecycleItemForOrder = lifecycleItems.get(simplifiedOrderStatus);
    if (lifecycleItemForOrder) {
        return (
            <List sx={{minWidth: '160px', padding: 0}}>
                {[...lifecycleItems.keys()].map((itemKey) => {
                    const iteratedLifecycleItem = lifecycleItems.get(itemKey);
                    if (!iteratedLifecycleItem || !lifecycleItemForOrder) {
                        return null;
                    }
                    if (iteratedLifecycleItem.incomingCryptoOnly && !incomingCrypto) {
                        return null;
                    }
                    let status: 'passed' | 'current' | 'none' = 'none';
                    if (iteratedLifecycleItem.order < lifecycleItemForOrder.order) {
                        status = 'passed';
                    } else if (itemKey === simplifiedOrderStatus) {
                        status = 'current';
                    }
                    return renderLifecycleItem(itemKey, isSuspended, status, iteratedLifecycleItem.position);
                })}
            </List>
        );
    } else if (simplifiedOrderStatus === 'canceled') {
        return (
            <List sx={{minWidth: '160px'}}>
                {renderLifecycleItem('new', false, 'passed', 'first')}
                {renderLifecycleItem('canceled', false, 'current', 'last')}
            </List>
        );
    } else {
        return <></>;
    }
}
