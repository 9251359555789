import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
    Button,
    Alert,
    // MenuItem, useMediaQuery,
    Stack,
} from '@mui/material';
import yup from '../../config/Yup';
import {RootState, useCoreAppDispatch} from '../../redux/store';
import {Currency} from '../../typings/currency';
import {
    Order,
    // Wallet
} from '../../typings/Order';
import {fetchWallets} from '../../redux/walletSlice';
import {enqueueSnackbar} from '../../redux/snackbarSlice';
import {fetchChangeRefundAddress, fetchChangeStatus} from '../../redux/orderSlice';
import {manageResponse} from '../../helpers/api';
import {STATUS_RETURN_TO_CLIENT} from '../../config/params';
import Space from '../Space';
import {useFormik} from 'formik';
import {CryptoAddressDropdownRenderProps} from '~/typings/ExchangeFormTypings';

interface OrderReturnCryptoFormProps {
    order: Order;
    fromCurrency: Currency;
    handleClose?: () => void;
    cryptoAddressDropdown?: (props: CryptoAddressDropdownRenderProps) => JSX.Element;
}

const rules = yup.object().shape({
    address: yup.number().required(),
});

/**
 * Renders form where client can choose address to get coins back.
 */
function OrderReturnCryptoForm({order, fromCurrency, handleClose, cryptoAddressDropdown}: OrderReturnCryptoFormProps) {
    const {returnFunds} = useSelector((state: RootState) => state.order);
    const dispatch = useCoreAppDispatch();
    const {t} = useTranslation();

    const refundAddress = order.refundAddress ? order.refundAddress.address : '';
    const refundAddressLabel = order.refundAddress ? order.refundAddress.address_label : '';

    // On component mount
    useEffect(() => {
        dispatch(fetchWallets({currencyId: fromCurrency.id}));
    }, []);

    useEffect(() => {
        manageResponse({
            reducer: returnFunds,
            onSuccess: () => {
                dispatch(enqueueSnackbar({message: t('refund_address_set_successfully'), variant: 'success'}));
                // changes status to return to client after successful return address change
                dispatch(fetchChangeStatus({id: order.id, status: STATUS_RETURN_TO_CLIENT}));
            },
        });
    }, [returnFunds]);

    const initialValues = useMemo(() => (order.refundAddress ? {address: order.refundAddress.id} : {}), []);

    const hasReturnFee = Number.parseFloat(order.return_amount ?? '0') !== Number.parseFloat(order.from_amount);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: rules,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            dispatch(fetchChangeRefundAddress({orderId: order.id, addressId: values.address}));
        },
    });

    const {values, handleChange, handleSubmit, errors, setFieldValue} = formik;

    return (
        <>
            <Space />
            {order.has_refund_address && (
                <>
                    <Alert severity='info'>
                        {t('react_refund_widget_already_chosen_refund_address', {
                            refundAddress: `${refundAddress} (${refundAddressLabel})`,
                        })}
                    </Alert>
                    <Space />
                </>
            )}

            <form onSubmit={handleSubmit} name='return-crypto-form'>
                <Stack spacing={4}>
                    {hasReturnFee ? (
                        <Alert severity='info'>
                            {t('react_order_crypto_return_fee_info_message', {
                                currency: fromCurrency.name,
                                amount: order.return_amount,
                            })}
                        </Alert>
                    ) : (
                        <Alert severity='info'>{t('react_order_crypto_no_fee_info_message')}</Alert>
                    )}

                    {cryptoAddressDropdown?.({
                        cryptoAccount: values.address,
                        setFieldValue,
                        handleChange,
                        currencyCode: fromCurrency.name,
                        errorMessage: errors['cryptoAccount'],
                    })}

                    <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-between'>
                        <Button variant='outlined' color='secondary' onClick={handleClose}>
                            {t('button_close')}
                        </Button>

                        {order.status_direction === STATUS_RETURN_TO_CLIENT ? (
                            <Button type='submit'>{t('refund_widget_btn_change_refund_address')}</Button>
                        ) : (
                            <Button type='submit' data-testid='crypto-refund-confirm-address'>
                                {t('react_suspended_widget_return_currency_btn', {
                                    amount: order.return_amount,
                                    currency: fromCurrency.name,
                                })}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </form>
        </>
    );
}

export default OrderReturnCryptoForm;
