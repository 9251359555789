{
  "bank_account": "Bankovn\u00ed \u00fa\u010det",
  "cancel": "Zru\u0161it",
  "crypto_wallet": "Krypto pen\u011b\u017eenka",
  "exchange": "Sm\u011bn\u00e1rna",
  "order_has_been_canceled": "Objedn\u00e1vka byla zru\u0161ena",
  "order_has_been_proceeded": "Objedn\u00e1vka byla odbavena",
  "order_has_been_suspended": "Objedn\u00e1vka byla pozastavena",
  "order_status_history": "Historie stavu objedn\u00e1vky",
  "orders": "Objedn\u00e1vky",
  "payment_information": "Informace o p\u0159\u00edjemci platby",
  "proceed": "Odbavit",
  "rates": "Kurzy",
  "return": "Vr\u00e1tit",
  "setting_language_preference_text": "Zvolte jazyk, ve kter\u00e9m se V\u00e1m budou zobrazovat str\u00e1nky a zas\u00edlat emaily",
  "setting_send_email_after_login": "Zaslat email po ka\u017ed\u00e9m p\u0159ihl\u00e1\u0161en\u00ed",
  "suspend": "Pozastavit",
  "wallet_updated_successfully": "Pen\u011b\u017eenka byla \u00fasp\u011b\u0161n\u011b aktualizov\u00e1na",
  "we_will_send_you_money_back_shortly": "Jakmile to bude mo\u017en\u00e9, za\u0161leme V\u00e1m pen\u00edze zp\u011bt na \u00fa\u010det",
  "guest_login_and_signup_order_separation": "nebo",
  "order_button_create_order": "Vytvo\u0159it objedn\u00e1vku",
  "guest_login": "P\u0159ihl\u00e1sit",
  "guest_signup": "Registrovat",
  "menu_guest_link_about_us": "O n\u00e1s",
  "menu_guest_link_help": "N\u00e1pov\u011bda",
  "menu_guest_link_terms": "Obchodn\u00ed podm\u00ednky",
  "order_form_caption": "Vytvo\u0159te svou objedn\u00e1vku p\u0159\u00edmo zde",
  "order_form_email": "E-mail",
  "forgot_password": "Zapomn\u011bli jste heslo?",
  "login_form_submit_button": "P\u0159ihl\u00e1sit",
  "page_login_title": "P\u0159ihl\u00e1\u0161en\u00ed do {{brandName}}",
  "page_signup_title": "Registrovat",
  "signup_form_please_fill": "Pros\u00edm, pro registraci vypl\u0148te n\u00e1sleduj\u00edc\u00ed formul\u00e1\u0159:",
  "signup_form_submit_button": "Registrovat",
  "order_form_placeholder_currency": "M\u011bna",
  "refund_widget_add_new_refund_address": "P\u0159idat zp\u00e1te\u010dn\u00ed adresu",
  "refund_address_select_label": "Zp\u00e1te\u010dn\u00ed adresa",
  "copied": "Zkop\u00edrov\u00e1no",
  "suspended_widget_return_money_back": "Vr\u00e1tit platbu zp\u011bt",
  "suspended_widget_top_alert_message": "Objedn\u00e1vka je pozastavena, \u010dekaj\u00edc\u00ed na Va\u0161e rozhodnut\u00ed.",
  "add_order": "Vytvo\u0159it objedn\u00e1vku",
  "language": "Jazyk",
  "logged_in": "P\u0159ihl\u00e1\u0161en\u00ed",
  "search": "Vyhledat",
  "status_direction": "Sm\u011br stavu",
  "status_direction_description_cancelled": "Zru\u0161it objedn\u00e1vku",
  "status_direction_description_proceed": "Obnovit objedn\u00e1vku (zafixovat kurz)",
  "status_direction_description_return_to_client": "Poslat pen\u00edze/krypto zp\u011bt",
  "status_direction_description_suspended": "Pozastavit objedn\u00e1vku (uvolnit kurz)",
  "status_final": "Kone\u010dn\u00fd stav",
  "status_name_cancelled": "Zru\u0161en\u00e1",
  "status_name_confirming_incoming": "P\u0159\u00edchoz\u00ed potvrzovan\u00e1",
  "status_name_confirming_outgoing": "Odchoz\u00ed potvrzovan\u00e1",
  "status_name_delivered": "Odbaven\u00e1",
  "status_name_done": "Hotov\u00e1",
  "status_name_new": "Nov\u00e1",
  "status_name_proceed": "Odbavit",
  "status_name_received": "P\u0159ijat\u00e1",
  "status_name_return_to_client": "Vr\u00e1tit klientovi",
  "status_name_returned": "Vr\u00e1ceno",
  "status_name_suspended": "Pozastaveno",
  "suspended_widget_table_change_percent": "Zm\u011bna",
  "client_bank_account_list_add_new_account": "P\u0159idat nov\u00fd bankovn\u00ed \u00fa\u010det",
  "account_form_create_button": "P\u0159idat bankovn\u00ed \u00fa\u010det",
  "account_form_save_button": "Ulo\u017eit",
  "create_new_wallet_header": "P\u0159idat adresu pen\u011b\u017eenky",
  "delete_account": "Smazat  \u00fa\u010det",
  "delete_wallet": "Smazat adresu pen\u011b\u017eenky",
  "update_account": "Aktualizovat \u00fa\u010det",
  "update_wallet": "Aktualizovat pen\u011b\u017eenku",
  "wallet_form_create_button": "P\u0159idat adresu pen\u011b\u017eenky",
  "header_what_now": "Jak d\u00e1le",
  "profile_page_header_my_documents": "P\u0159ipojen\u00e9 soubory",
  "profile_page_header_my_profile": "Verifikace",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "V\u00e1\u0161 profil je prov\u011b\u0159ov\u00e1n",
  "profile_page_save_button": "Ulo\u017eit",
  "profile_page_tab_level1": "\u00darove\u0148 1",
  "profile_page_tab_level2": "\u00darove\u0148 2",
  "profile_page_tab_this_level_is_accepted": "Va\u0161e \u00fadaje jsou ov\u011b\u0159eny",
  "refund_address_set_successfully": "Zp\u00e1te\u010dn\u00ed adresa byla \u00fasp\u011b\u0161n\u011b nastavena",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "Pokud zatrhnete box n\u00ed\u017ee a ode\u0161lete formul\u00e1\u0159, nebudete nad\u00e1le moci m\u011bnit zadan\u00e9 \u00fadaje. Data budou odesl\u00e1ny k verifikaci na\u0161im person\u00e1lem.",
  "client_sidebar_avatar_hash_description": "Zapamatujte si tento obr\u00e1zek. Byl vytvo\u0159en unik\u00e1tn\u011b pro V\u00e1\u0161 \u00fa\u010det. Pokud se zm\u011bnil, je mo\u017en\u00e9, \u017ee se V\u00e1m n\u011bkdo sna\u017e\u00ed podvrhnout ciz\u00ed str\u00e1nky. Zkontrolujte adresu ve sv\u00e9m prohl\u00ed\u017ee\u010di jestli je spr\u00e1vn\u00e1 a je vlevo od n\u00ed zobrazen zelen\u00fd visac\u00ed z\u00e1mek.",
  "navigation_link_logout": "Odhl\u00e1sit",
  "navigation_link_rates": "Kurzy",
  "company_about": "Simplecoin je \u010desk\u00e1 sm\u011bn\u00e1rna kryptom\u011bn. Na\u0161\u00edm c\u00edlem je ud\u011blat obchod s Bitcoinem a ostatn\u00edmi digit\u00e1ln\u00edmi m\u011bnami tak jednoduch\u00fdm,  jak je to jen mo\u017en\u00e9. Se Simplecoinem m\u016f\u017eete kryptom\u011bny nakupovat, prod\u00e1vat a vym\u011b\u0148ovat mezi sebou velmi jednodu\u0161e.",
  "sidebar_navigation_orders": "Objedn\u00e1vky",
  "sidebar_navigation_profile": "Verifikace",
  "sidebar_navigation_security": "Zabezpe\u010den\u00ed",
  "sidebar_navigation_settings": "Nastaven\u00ed",
  "company_header": "O spole\u010dnosti {{brandName}}",
  "company_header_contacts": "Kontakty",
  "company_header_social": "Soci\u00e1ln\u00ed s\u00edt\u011b",
  "company_subheader": "Jednoduch\u00e1 sm\u011bn\u00e1rna kryptom\u011bn",
  "company_social_group": "Skupina {{brandName}}",
  "company_header_billing_address": "Faktura\u010dn\u00ed adresa",
  "page_header_company": "{{brandName}} - Sm\u011bn\u00e1rna Bitcoinu a kryptom\u011bn | BTC LTC ETH BCH XRP",
  "footer_sidebar_link_about": "O n\u00e1s",
  "client_order_reset_all_label": "Resetovat v\u0161e",
  "client_order_search_btn": "Vyhledat objedn\u00e1vky",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "P\u0159\u00edkaz k p\u0159evodu byl zad\u00e1n do internetov\u00e9ho bankovnictv\u00ed a nyn\u00ed \u010dek\u00e1me a\u017e se objev\u00ed na bankovn\u00edm v\u00fdpisu.",
  "error": "Chyba",
  "client_bank_accounts_widget_header": "Dokumenty vztahuj\u00edc\u00ed se k bankovn\u00edmu \u00fa\u010dtu",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "O\u010dek\u00e1v\u00e1me p\u0159\u00edchoz\u00ed transakci. Pokud ji obdr\u017e\u00edme, za\u0161leme ji zp\u011bt, proto\u017ee sm\u011br objedn\u00e1vky je nastaven na vr\u00e1tit.",
  "page_subheader_settings_order_emails": "Emaily k objedn\u00e1vce",
  "page_subheader_settings_security_emails": "Bezpe\u010dnostn\u00ed emaily",
  "client_setting_hint_send_email_when_order_confirming_incoming": "Zaslat email pokud bude k objedn\u00e1vce p\u0159i\u0159azena p\u0159\u00edchoz\u00ed krypto transakce, kter\u00e1 \u010dek\u00e1 na potvrzen\u00ed",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "Zaslat email pokud bude k objedn\u00e1vce p\u0159i\u0159azena odchoz\u00ed bankovn\u00ed nebo krypto transakce, kter\u00e1 \u010dek\u00e1 na potvrzen\u00ed",
  "client_setting_hint_send_email_when_order_done": "Zaslat email pokud bude objedn\u00e1vka dokon\u010dena",
  "client_setting_hint_send_email_when_order_proceed": "Zaslat email pokud bude sm\u011br objedn\u00e1vky nastaven na \"vym\u011bnit\"",
  "client_setting_hint_send_email_when_order_received": "Zaslat email pokud k objedn\u00e1vce byla p\u0159i\u0159azena p\u0159\u00edchoz\u00ed bankovn\u00ed transakce nebo p\u0159\u00edchoz\u00ed krypto transakce byla potvrzena",
  "client_setting_hint_send_email_when_order_return_to_client": "Zaslat email pokud byl sm\u011br objedn\u00e1vky zm\u011bn\u011bn na \"vr\u00e1tit\"",
  "client_setting_hint_send_email_when_order_suspended": "Zaslat email pokud byl sm\u011br objedn\u00e1vky zm\u011bn\u011bn na \"pozastaveno\"",
  "client_settings_label_order_confirming_incoming": "Nepotvrzen\u00e1 P\u0159\u00edchoz\u00ed",
  "client_settings_label_order_confirming_outgoing": "Nepotvrzen\u00e1 Odchoz\u00ed",
  "client_settings_label_order_done": "Vy\u0159\u00edzeno",
  "client_settings_label_order_proceed": "Prov\u00e9st",
  "client_settings_label_order_received": "P\u0159ijato",
  "client_settings_label_order_return_to_client": "Vr\u00e1tit",
  "client_settings_label_order_suspended": "Pozastaveno",
  "page_subheader_settings_general": "Obecn\u00e1 nastaven\u00ed",
  "profile_page_level1_my_document_subheader_paragraph": "Jak\u00fdkoli jin\u00fd dokument, kter\u00fd m\u016f\u017eeme pou\u017e\u00edt pro Va\u0161i identifikaci. M\u016f\u017eete zde p\u0159ipojit n\u011bkolik soubor\u016f.",
  "profile_page_level2_my_document_subheader_paragraph": "Zde m\u016f\u017eete p\u0159ipojit n\u011bkolik soubor\u016f",
  "profile_page_level2_note_description": "Pokud chcete obchodovat jako firma, napi\u0161te jakou ve firm\u011b zast\u00e1v\u00e1te pozici, I\u010cO a DI\u010c. Takt\u00e9\u017e vysv\u011btlete nestandardn\u00ed situace jako p\u0159\u00edjem prost\u0159edk\u016f od t\u0159et\u00edch osob, zastupov\u00e1n\u00ed t\u0159et\u00edch osob a podobn\u011b.",
  "profile_page_level2_trading_purpose_description": "Pros\u00edm uve\u010fte, jak\u00fd je \u00fa\u010del Va\u0161\u00ed sm\u011bny. Tuto informaci vy\u017eaduj\u00ed \u010desk\u00e9 regulace, kter\u00e9 se vztahuj\u00ed na obchodn\u00edky s kryptom\u011bnou.",
  "profile_page_level2_trading_source_of_income_description": "Pros\u00edm popi\u0161te zdroj Va\u0161ich p\u0159\u00edjm\u016f. Pro velmi velk\u00e9 \u010d\u00e1stky V\u00e1s po\u017e\u00e1d\u00e1me o jak\u00fdkoli dokument, kter\u00fd m\u016f\u017ee podpo\u0159it Va\u0161e tvrzen\u00ed. Pokud \u017e\u00e1dn\u00fd takov\u00fd dokument nem\u016f\u017eete poskytnout, explicitn\u011b tento fakt zde uve\u010fte. V p\u0159\u00edpad\u011b zam\u011bstn\u00e1n\u00ed a podnik\u00e1n\u00ed uve\u010fte i n\u00e1zev a I\u010cO spole\u010dnosti.",
  "action_reset_password_error_email_not_sent": "Je n\u00e1m l\u00edto, ale nejsme schopni resetovat heslo pro email, kter\u00fd jste zadali.",
  "action_reset_password_success": "Na V\u00e1\u0161 email byly zasl\u00e1ny instrukce pro pokra\u010dov\u00e1n\u00ed.",
  "address_was_deleted": "Adresa byla odstran\u011bna.",
  "bank_account_was_added": "Bankovn\u00ed \u00fa\u010det byl p\u0159id\u00e1n.",
  "bank_account_was_deleted": "Bankovn\u00ed \u00fa\u010det byl odstran\u011bn.",
  "bank_account_was_updated": "Bankovn\u00ed \u00fa\u010det byl aktualizov\u00e1n.",
  "change": "Zm\u011bnit",
  "change_password": "Zm\u011bnit heslo",
  "delete": "Smazat",
  "email_confirmed": "V\u00e1\u0161e emailov\u00e1 adresa byla potvrzena.",
  "enter_email_address_to_recover_account": "Pros\u00edm zadejte email, kter\u00fd jste pou\u017eili pro registraci. Za\u0161leme v\u00e1m instrukce pro obnoven\u00ed hesla.",
  "file_was_deleted": "Soubor byl odstran\u011bn",
  "information_successfully_saved": "Va\u0161e osobn\u00ed informace byly \u00fasp\u011b\u0161n\u011b ulo\u017eeny",
  "new_address_was_added": "Nov\u00e1 adresa byla ulo\u017eena",
  "new_password_saved": "Nov\u00e9 heslo bylo ulo\u017eeno",
  "order_has_been_created_guest": "Objedn\u00e1vka byla vytvo\u0159ena. Jak postupovat d\u00e1l se dozv\u00edte v e-mailu, kter\u00fd jsme V\u00e1m zaslali.",
  "pls_choose_new_password": "Zvolte sv\u00e9 nov\u00e9 heslo pros\u00edm:",
  "request_password_reset": "Po\u017e\u00e1dat o reset hesla",
  "reset_password": "Resetovat heslo",
  "save": "Ulo\u017eit",
  "security": "Zabezpe\u010den\u00ed",
  "send": "Odeslat",
  "signup": "Registrace",
  "update": "Aktualizovat",
  "are_you_sure_you_want_to_delete_this_file": "Opravdu si p\u0159ejete smazat tento soubor?",
  "client_bank_account_message_warning_about_symbol": "N\u00e1\u0161 symbol bude p\u0159id\u00e1n do t\u00e9to zpr\u00e1vy",
  "from_amount_currency_label": "Z \u00fa\u010dtu",
  "to_amount_currency_label": "Na \u00fa\u010det",
  "menu_guest_link_rates": "Kurzy",
  "is_automatic_proceed_on_duplicate_payment": "Automaticky potvrdit aktu\u00e1ln\u00ed kurz u duplicitn\u00edch plateb",
  "is_automatic_proceed_on_late_payment": "Automaticky potvrdit aktu\u00e1ln\u00ed kurz u zpo\u017ed\u011bn\u00fdch plateb",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "V p\u0159\u00edpad\u011b duplicitn\u00ed platby (platba, kter\u00e1 byla sp\u00e1rov\u00e1na s ji\u017e uhrazenou objedn\u00e1vkou), syst\u00e9m automaticky vytvo\u0159\u00ed kopii t\u00e9to objedn\u00e1vky. Norm\u00e1ln\u011b je tato objedn\u00e1vka pozastavena. Pokud zapnete tuto volbu, nov\u00e1 objedn\u00e1vka bude aktualizov\u00e1na sou\u010dasn\u00fdm kurzem a stavem \"Odbavit\". T\u00edmto zp\u016fsobem m\u016f\u017eete prov\u00e1d\u011bt sm\u011bny automaticky pouh\u00fdm zasl\u00e1n\u00edm platby a bez jak\u00e9koliv interakce s na\u0161\u00ed webovou str\u00e1nkou.",
  "setting_hint_is_automatic_proceed_on_late_payment": "V p\u0159\u00edpad\u011b, \u017ee Va\u0161e platba n\u00e1m bude p\u0159ips\u00e1na po \u010dasov\u00e9m limitu, syst\u00e9m automaticky potvrd\u00ed aktu\u00e1ln\u00ed kurz a objedn\u00e1vka bude p\u0159ipravena k odbaven\u00ed. Obvykle jsou takov\u00e9 objedn\u00e1vky pozdr\u017eeny a \u010dekaj\u00ed na manu\u00e1ln\u00ed potvrzen\u00ed kurzu klientem.",
  "no_camera_found": "Nebyla nalezena \u017e\u00e1dn\u00e1 kamera",
  "scan_address": "Na\u010d\u00edst z QR k\u00f3du",
  "button_disable": "Vypnout",
  "confirm": "Potvrdit",
  "header_disable_google_authenticator": "Vypnout dvouf\u00e1zov\u00e9 ov\u011b\u0159ov\u00e1n\u00ed",
  "header_enable_google_authenticator": "Zapnout dvouf\u00e1zov\u00e9 ov\u011b\u0159ov\u00e1n\u00ed",
  "label_2fa_password": "Heslo",
  "label_2fa_secret": "Tajn\u00fd kl\u00ed\u010d",
  "2fa_secret_description": "Upozorn\u011bn\u00ed: z\u00e1lohujte si tento k\u00f3d na bezpe\u010dn\u00e9 m\u00edsto. M\u016f\u017ee b\u00fdt pou\u017eit k obnoven\u00ed p\u0159\u00edstupu k dvoufaktorov\u00e9mu ov\u011b\u0159en\u00ed v p\u0159\u00edpad\u011b ztr\u00e1ty telefonu.",
  "btn_disable": "Vypnout",
  "btn_enable": "Zapnout",
  "two_factor_authentication": "Dvoufaktorov\u00e9 ov\u011b\u0159en\u00ed",
  "fill_information_below_to_disable_two_factor_authentication": "Vypl\u0148te n\u00ed\u017ee uveden\u00e9 informace pro deaktivaci dvoufaktorov\u00e9ho ov\u011b\u0159en\u00ed:",
  "alert_success_email_link_was_resent_again": "Nov\u00fd email pro potvrzen\u00ed emailov\u00e9 adresy byl odesl\u00e1n.",
  "hint_login_form_2fa_code": "V p\u0159\u00edpad\u011b, \u017ee k \u00fa\u010dtu nen\u00ed umo\u017en\u011bna dvoufaktorov\u00e1 autentizace, k\u00f3d nen\u00ed vy\u017eadov\u00e1n.",
  "footer_sidebar_link_terms": "Podm\u00ednky",
  "button_download_order_pdf": "St\u00e1hnout v PDF",
  "button_hint_download_order_pdf": "St\u00e1hn\u011bte si informace o objedn\u00e1vce v PDF",
  "alert_success_affiliate_payout_request_successfully_created": "Po\u017eadavek k v\u00fdb\u011bru byl obdr\u017een",
  "alert_success_you_have_become_affiliate": "V\u00e1\u0161 partnersk\u00fd \u00fa\u010det byl pr\u00e1v\u011b aktivov\u00e1n",
  "button_create": "Vytvo\u0159it",
  "button_save": "Ulo\u017eit",
  "page_affiliate_dashboard_copy_url_header": "Odkaz Va\u0161eho partnersk\u00e9ho programu",
  "page_affiliate_dashboard_overview_header": "P\u0159ehled programu",
  "page_affiliate_dashboard_overview_paragraph": "N\u00ed\u017ee je zobrazen p\u0159ehled Va\u0161eho partnersk\u00e9ho programu.",
  "page_affiliate_dashboard_paragraph": "P\u0159ehled Va\u0161eho partnersk\u00e9ho programu. Zde si m\u016f\u017eete prohl\u00e9dnout detailn\u00ed statistiky programu a po\u017e\u00e1dat o v\u00fdb\u011br v libovoln\u00e9 m\u011bn\u011b, kterou {{brandName}} podporuje.",
  "page_affiliate_statistics_button": "Statistiky",
  "page_affiliate_stats_first_paragraph": "Na t\u00e9to str\u00e1nce si m\u016f\u017eete prohl\u00e9dnout statistiku partnersk\u00e9ho programu, kolikr\u00e1t byl vyu\u017eit u\u017eivateli a kdy.",
  "page_affiliate_stats_label_conversion": "Po\u010det kliknut\u00ed na banery",
  "page_affiliate_stats_label_period": "Datum",
  "page_affiliate_withdraw_button": "Vybrat",
  "page_become_affiliate_first_paragraph": "Pokud se chcete p\u0159ipojit do na\u0161eho partnersk\u00e9ho programu, za\u0161krtn\u011bte toto pol\u00ed\u010dko, \u010d\u00edm\u017e vyj\u00e1d\u0159\u00edte souhlas s podm\u00ednkami programu a formul\u00e1\u0159 ode\u0161lete.",
  "page_header_affiliate_dashboard": "Affiliate program",
  "page_header_become_affiliate": "P\u0159ihl\u00e1sit se k partnersk\u00e9mu programu",
  "sidebar_navigation_affiliate": "Affiliate program",
  "label_agree_with_affiliate_conditions": "Souhlas\u00edm s podm\u00ednkami partnersk\u00e9ho programu",
  "page_affiliate_withdraw_request_paragraph": "Zvolte jestli chcete provizi vybrat na bankovn\u00ed \u00fa\u010det nebo krypto pen\u011b\u017eenku.",
  "page_affiliate_finance_first_paragraph": "Zde vid\u00edte seznam objedn\u00e1vek p\u0159i\u0159azen\u00fdch k Va\u0161emu \u00fa\u010dtu a profit, kter\u00fd dost\u00e1v\u00e1te ke ka\u017ed\u00e9 objedn\u00e1vce.",
  "page_affiliate_finance_label_order": "Objedn\u00e1vka",
  "page_affiliate_finance_button": "P\u0159\u00edjem",
  "page_affiliate_finance_label_profit_in_eur": "Profit (EUR)",
  "page_affiliate_asset_banners_paragraph": "Na sv\u00e9 str\u00e1nky m\u016f\u017eete um\u00edstit interaktivn\u00ed banery. Nakop\u00edrujte n\u00e1sleduj\u00edc\u00ed HTML a vlo\u017ete na p\u0159\u00edslu\u0161n\u00e9 m\u00edsto, kde je chcete zobrazovat.",
  "page_affiliate_assets_button": "Propaga\u010dn\u00ed materi\u00e1ly",
  "api_order_unsupported_currency_pair": "Tuto sm\u011bnu neumo\u017e\u0148ujeme",
  "footer_sidebar_link_data_processing": "GDPR",
  "alert_success_ticket_created": "Po\u017eadavek byl \u00fasp\u011b\u0161n\u011b vytvo\u0159en.",
  "alert_success_ticket_reply_sent": "Odpov\u011b\u010f na po\u017eadavek byla \u00fasp\u011b\u0161n\u011b odesl\u00e1na.",
  "button_send": "Odeslat",
  "page_header_ticket_create": "Nov\u00fd po\u017eadavek",
  "page_header_ticket_list": "Po\u017eadavky",
  "page_header_ticket_reply": "Odpov\u011bd\u011bt na po\u017eadavek",
  "page_ticket_reply_you": "Vy",
  "sidebar_navigation_ticket": "Podpora",
  "alert_success_ticket_closed": "Po\u017eadavek byl ozna\u010den jako vy\u0159\u00edzen\u00fd",
  "button_create_support_ticket": "Podpora",
  "button_hint_create_support_ticket": "Pokud m\u00e1te jakoukoli ot\u00e1zku nebo probl\u00e9m s touto objedn\u00e1vkou, m\u016f\u017eete si vytvo\u0159it \u017e\u00e1dost o podporu.",
  "page_tickets_pending_tickets": "\u010cekaj\u00edc\u00ed po\u017eadavky",
  "page_tickets_solved_tickets": "Vy\u0159e\u0161en\u00e9 po\u017eadavky",
  "page_tickets_no_pending_tickets": "Dosud \u017e\u00e1dn\u00e9 vytvo\u0159en\u00e9 nebo nevy\u0159\u00edzen\u00e9 po\u017eadavky.",
  "page_tickets_no_solved_tickets": "Prozat\u00edm \u017e\u00e1dn\u00e9 vy\u0159e\u0161en\u00e9 po\u017eadavky",
  "take_selfie_button": "Ud\u011blejte selfie",
  "no_camera_access_provided": "Nen\u00ed k dispozici \u017e\u00e1dn\u00fd p\u0159\u00edstup k fotoapar\u00e1tu. Zkontrolujte pros\u00edm opr\u00e1vn\u011bn\u00ed kamery a ujist\u011bte se, \u017ee nejsou zak\u00e1z\u00e1ny.",
  "menu_guest_link_privacy_policy": "Zpracov\u00e1n\u00ed osobn\u00edch \u00fadaj\u016f",
  "change_password_social_reset_password_claim": "Pros\u00edm stiskn\u011bte tla\u010d\u00edtko n\u00ed\u017ee. Za\u0161leme V\u00e1m email s odkazem, kter\u00fd V\u00e1m umo\u017en\u00ed zm\u011bnit heslo.",
  "alert_change_password_email_was_sent": "Pros\u00edm zkontrolujte sv\u016fj email, kde naleznete odkaz pomoc\u00ed kter\u00e9ho m\u016f\u017eete nastavit nov\u00e9 heslo.",
  "page_confirm_two_factor": "Potvrdit dvoufaktorov\u00e9 p\u0159ihla\u0161ov\u00e1n\u00ed",
  "page_confirm_two_factor_paragraph": "Vlo\u017ete pros\u00edm k\u00f3d vygenerovan\u00fd aplikac\u00ed pro dvoufaktorov\u00e9 p\u0159ihla\u0161ov\u00e1n\u00ed.",
  "cookie_consent_i_agree": "Rozum\u00edm",
  "cookie_consent_message": "Pou\u017e\u00edv\u00e1me soubory cookies pro zaji\u0161t\u011bn\u00ed p\u0159ihla\u0161ovan\u00ed, navigaci a anal\u00fdzy n\u00e1v\u0161t\u011bvnosti. D\u011bkujeme za V\u00e1\u0161 souhlas s jejich pou\u017eit\u00edm. <0>Vyberte svoje cookies.</0>",
  "try_again_button": "Zkusit znovu",
  "dropable_or_pastable_from_clipboard": "P\u0159et\u00e1hn\u011bte sem soubory z Va\u0161eho po\u010d\u00edta\u010de nebo vlo\u017ete obr\u00e1zek ze schr\u00e1nky stiskem CTRL-V",
  "hint_enter_two_factor_code_to_confirm_action": "Zadejte k\u00f3d z Google Authenticatoru",
  "add_new_ticket": "Vytvo\u0159it po\u017eadavek",
  "close_ticket": "Ozna\u010dit jako vy\u0159e\u0161en\u00e9",
  "client_setting_hint_send_email_limit_price_changed": "Zaslat email pokud byla na objedn\u00e1vce nastavena nebo zm\u011bn\u011bna limitn\u00ed sazba.",
  "client_settings_label_order_limit_price_changed": "Limitn\u00ed kurz",
  "button_set_limit": "Nastavit limit",
  "suspended_widget_header_limit_rate": "Limitn\u00ed kurz",
  "suspended_widget_header_limit_rate_hint": "Nastaven\u00edm limitn\u00edho kurzu doc\u00edl\u00edte automatick\u00e9ho potvrzen\u00ed objedn\u00e1vky, kdy\u017e aktu\u00e1ln\u00ed tr\u017en\u00ed kurz p\u0159ekro\u010d\u00ed V\u00e1mi nastaven\u00fd limitn\u00ed kurz.",
  "suspended_widget_header_market_rate": "Aktu\u00e1ln\u00ed kurz",
  "suspended_widget_header_market_rate_hint": "Aktu\u00e1ln\u00ed kurz, kter\u00fd V\u00e1m m\u016f\u017eeme nyn\u00ed nab\u00eddnout. V\u0161echny poplatky jsou ji\u017e zahrnuty, tak\u017ee je to p\u0159esn\u00fd kurz, kter\u00fd obdr\u017e\u00edte.",
  "suspended_widget_header_order_rate": "Kurz objedn\u00e1vky",
  "suspended_widget_header_order_rate_hint": "Kurz objedn\u00e1vky v momentu jej\u00edho vytvo\u0159en\u00ed. Tento kurz ji\u017e zahrnuje v\u0161echny poplatky, v\u010detn\u011b transak\u010dn\u00edch.",
  "button_change_limit": "Zm\u011bnit",
  "button_limit_cancel": "Zru\u0161it",
  "suspended_widget_limit_form_paragraph": "Tyto dv\u011b pole representuj\u00ed pouze jeden kurz. Hodnota v druh\u00e9m poli, je p\u0159evr\u00e1cenou hodnotou pole prvn\u00edho, ob\u011b pole se aktualizuj\u00ed pr\u016fb\u011b\u017en\u011b. Dovoluje V\u00e1m to zadat kurz zp\u016fsobem, na kter\u00fd jste zvykl\u00ed.",
  "hint_close_ticket_button": "Pokud kliknete na toto tla\u010d\u00edtko, po\u017eadavek bude ozna\u010den jako vy\u0159e\u0161en\u00fd a pro dal\u0161\u00ed komunikaci bude pot\u0159eba vytvo\u0159it nov\u00fd po\u017eadavek.",
  "heading_chart": "Graf",
  "button_close": "Zav\u0159\u00edt",
  "boolean_option_no": "Ne",
  "boolean_option_yes": "Ano",
  "generic_link_learn_more": "Zjistit v\u00edce",
  "profile_page_header_my_documents_level0_paragraph": "<strong>Pros\u00edm p\u0159ipojte dokumenty ov\u011b\u0159uj\u00edc\u00ed Va\u0161\u00ed toto\u017enost</strong><br>\nDoklady je pot\u0159eba dodat ve vysok\u00e9 kvalit\u011b, sken 300dpi nebo v\u00edce, \u010di kvalitn\u00ed fotografie, \u017e\u00e1dn\u00e1 \u010d\u00e1st nesm\u00ed b\u00fdt zakryta nebo zm\u011bn\u011bna.\n\"Jin\u00fd dokument\" m\u016f\u017ee b\u00fdt doklad, z n\u011bho\u017e lze zjistit identifika\u010dn\u00ed \u00fadaje p\u0159\u00edslu\u0161n\u00e9 fyzick\u00e9 osoby, druh a \u010d\u00edslo pr\u016fkazu toto\u017enosti, st\u00e1t, pop\u0159\u00edpad\u011b org\u00e1n, kter\u00fd jej vydal, a dobu jeho platnosti.",
  "profile_page_header_my_documents_level1_paragraph": "<strong>Pros\u00edm p\u0159ipojte n\u00e1sleduj\u00edc\u00ed dokumenty:</strong><br>\n- jak\u00fdkoli dokument, kter\u00fd potvrzuje zdroj Va\u0161ich p\u0159\u00edjm\u016f<br>\n- Va\u0161i vlastn\u00ed fotografii, na kter\u00e9 dr\u017e\u00edte ob\u010dansk\u00fd pr\u016fkaz<br>\n- pokud nakupujete na firmu, p\u0159ipojte registra\u010dn\u00ed dokumenty firmy (pokud nem\u00e1te z\u00e1pis ve ve\u0159ejn\u00e9m rejst\u0159\u00edku)<br>\n- jak\u00e9koli dal\u0161\u00ed dokumenty, o kter\u00e9 jsme V\u00e1s po\u017e\u00e1dali",
  "chart_module_market_price_inverse": "P\u0159evr\u00e1tit",
  "chart_module_no_data_available": "Data nejsou k dispozici",
  "bank_account_verified": "Ov\u011b\u0159eno",
  "profile_page_bank_account_header": "Va\u0161e bankovn\u00ed \u00fa\u010dty",
  "profile_page_bank_accounts_paragraph": "Pro \u00fasp\u011b\u0161nou identifikaci na d\u00e1lku bude pot\u0159eba prov\u00e9st ov\u011b\u0159en\u00ed alespo\u0148 jednoho bankovn\u00edho \u00fa\u010dtu. Na z\u00e1lo\u017ece p\u0159\u00edjemci zalo\u017ete sv\u016fj \u00fa\u010det a potom dle instrukc\u00ed p\u0159ipojte po\u017eadovan\u00e9 dokumenty a p\u0159\u00edpadn\u011b za\u0161lete ov\u011b\u0159ovac\u00ed platbu.",
  "bank_account_file_upload_unverified_level0_paragraph": "Abychom mohli dokon\u010dit Va\u0161i identifikaci na d\u00e1lku (ov\u011b\u0159en\u00ed profilu na \u00farove\u0148 1) budeme pot\u0159ebovat dolo\u017eit existenci bankovn\u00edho \u00fa\u010dtu na Va\u0161e jm\u00e9no. \nPros\u00edm p\u0159ipojte dokument vydan\u00fd bankou, na kter\u00e9m uvid\u00edme \u010d\u00edslo \u00fa\u010dtu a jm\u00e9no majitele. M\u016f\u017ee to b\u00fdt smlouva o veden\u00ed \u00fa\u010dtu nebo pravideln\u00fd v\u00fdpis. Soubor PDF nebo sken pap\u00edrov\u00e9ho dokumentu je vyhovuj\u00edc\u00ed.",
  "guest_market_table": "Tabulkov\u00e9 zobrazen\u00ed",
  "validation_error_not_matching_passwords": "Hesla se neshoduj\u00ed",
  "react_send_from_amount_here": "Za\u0161lete {{amount}} {{currency}} na n\u00e1sleduj\u00edc\u00ed adresu:",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Obdr\u017eeli jsme Va\u0161i platbu, ale rozhodl/a jste se pro jej\u00ed vr\u00e1cen\u00ed. \u010c\u00e1stku {{amount}} <0>{{currency}}</0> za\u0161leme obratem.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "Obdr\u017eeli jsme {{amountFrom}} <0>{{currencyFrom}}</0> k Va\u0161\u00ed objedn\u00e1vce, za\u0161leme v\u00e1m {{amountTo}} <1>{{currencyTo}}</1>",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "Evidujeme p\u0159\u00edchoz\u00ed krypto transakci pro {{amount}} <0>{{currency}}</0>. Moment\u00e1ln\u011b \u010dek\u00e1me na jej\u00ed potvrzen\u00ed.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "Odeslali jsme krypto transakci za {{amount}} <0>{{currency}}</0>. Moment\u00e1ln\u011b \u010dek\u00e1me na v\u00edce potvrzen\u00ed odchoz\u00ed transakce.",
  "react_suspended_widget_accept_btn": "P\u0159ijmout {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": "Vr\u00e1tit {{amount}} {{currency}}",
  "react_order_created_high_market_volatility_message": "Vzhledem k vysok\u00e9 volatilit\u011b trhu, jsme schopni garantovat kurz pouze po dobu {{duration}} minut. Rychlost krypto transakce z\u00e1vis\u00ed na v\u00fd\u0161ce poplatku. Pro rychl\u00e9 p\u0159ijet\u00ed Va\u0161\u00ed krypto transakce, je tedy d\u016fle\u017eit\u00e9 zvolit jeho spr\u00e1vnou v\u00fd\u0161i. Pokud bude platba p\u0159ips\u00e1na po limitu a sou\u010dasn\u011b se v\u00fdrazn\u011b zm\u011bn\u00ed kurz, budeme v\u00e1s kontaktovat e-mailem s nab\u00eddkou t\u011bchto mo\u017enost\u00ed: p\u0159ijet\u00ed nov\u00e9ho sm\u011bnn\u00e9ho kurzu a pokra\u010dov\u00e1n\u00ed v objedn\u00e1vce, nebo vr\u00e1cen\u00ed va\u0161ich pen\u011bz zp\u011bt.",
  "react_order_suspend_over_limit_message": "Objedn\u00e1vka je automaticky pozastavena, jeliko\u017e jste dos\u00e1hl/a Va\u0161eho limitu pro obchodov\u00e1n\u00ed.",
  "chart_timeframe_option_all": "V\u0161e",
  "react_bank_account_file_upload_unverified_paragraph": "Budeme tak\u00e9 pot\u0159ebovat libovolnou p\u0159\u00edchoz\u00ed transakci z tohoto \u00fa\u010dtu abychom vid\u011bli jm\u00e9no majitele. Jestli budete pen\u00edze z tohoto \u00fa\u010dtu zas\u00edlat k n\u00e1m, nen\u00ed pot\u0159eba d\u011blat nic. Pokud ale budete kryptom\u011bnu prod\u00e1vat a p\u0159ij\u00edmat pen\u00edze na tento \u00fa\u010det, za\u0161lete n\u00e1m z n\u011bj jednu korunu nebo euro cent na n\u011bkter\u00fd z <0>na\u0161ich \u00fa\u010dt\u016f uveden\u00fdch zde</0>.",
  "react_2fa_qr_code_description": "Pro lep\u0161\u00ed zabezpe\u010den\u00ed si m\u016f\u017eete zapnout dvouf\u00e1zov\u00e9 ov\u011b\u0159ov\u00e1n\u00ed. Je pot\u0159eba m\u00edt nainstalovanou aplikaci <0>Google Authenticator</0>. Pro aktivaci slu\u017eby budete pot\u0159ebovat touto aplikac\u00ed oskenovat QR k\u00f3d z t\u00e9to str\u00e1nky, zadat st\u00e1vaj\u00edc\u00ed heslo a k\u00f3d kter\u00fd V\u00e1m aplikace v telefonu vygeneruje.",
  "chart_timeframe_option_day": "Den",
  "chart_timeframe_option_month": "M\u011bs\u00edc",
  "chart_timeframe_option_week": "T\u00fdden",
  "chart_timeframe_option_year": "Rok",
  "chart_timeframe_option_year_to_date": "Od za\u010d\u00e1tku roku",
  "react_profile_page_level1_paragraph_before_form": "\u00darove\u0148 1 povoluje obchodovat a\u017e do \u010d\u00e1stky {{limit_level_2}} EUR za {{tradeLimitDays}} dn\u00ed.",
  "react_profile_page_level2_paragraph_before_form": "\u00darove\u0148 2 povoluje obchodovat \u010d\u00e1stky v ekvivalentu p\u0159es {{limit_level_2}} Eur za {{tradeLimitDays}} dn\u00ed. V n\u011bkter\u00fdch p\u0159\u00edpadech V\u00e1s m\u016f\u017eeme po\u017e\u00e1dat o dal\u0161\u00ed potvrzen\u00ed zdroj\u016f Va\u0161ich p\u0159\u00edjm\u016f.",
  "react_page_header_posts_by_category": "Kategorie: {{name}}",
  "chart_timeframe_option_3months": "3 m\u011bs\u00edce",
  "ticket_load_more": "Na\u010d\u00edst v\u00edce",
  "ticket_type_your_message": "Napi\u0161te zpr\u00e1vu...",
  "no_results_found": "Nenalezeny \u017e\u00e1dn\u00e9 v\u00fdsledky",
  "validation_field_required": "Toto pole je vy\u017eadov\u00e1no",
  "validation_min_characters": "Pole mus\u00ed obsahovat alespo\u0148 ${min} znak\u016f",
  "uploaded_file_information_complete": "Informace v tomto dokumentu jsou kompletn\u00ed",
  "uploaded_file_click_to_fill_information": "Klikn\u011bte ZDE pro dopln\u011bn\u00ed informac\u00ed",
  "react_order_header": "Objedn\u00e1vka {{id}}",
  "profile_page_header_my_personal_data": "Osobn\u00ed \u00fadaje",
  "validation_error_incorrect_phone_number": "Nespr\u00e1vn\u00e9 telefonn\u00ed \u010d\u00edslo",
  "react_file_upload_extension_error": "Jsou povoleny pouze n\u00e1sleduj\u00edc\u00ed form\u00e1ty: {{extensions}}",
  "qr_code_scan_success": "QR k\u00f3d je \u00fasp\u011b\u0161n\u011b oskenov\u00e1n",
  "upload_success": "Nahr\u00e1n\u00ed prob\u011bhlo \u00fasp\u011b\u0161n\u011b",
  "button_open_form": "Otev\u0159\u00edt formul\u00e1\u0159",
  "client_bank_trade_volume_label": "Hodnota objedn\u00e1vek pro bankovn\u00ed \u00fa\u010det",
  "qr_payment_hint": "M\u016f\u017eete pou\u017e\u00edt aplikaci smart bankingu ve Va\u0161em telefonu podporuj\u00edc\u00ed syst\u00e9m QR Platba",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0>P\u0159ihlaste se</0> a z\u00edskejte objemov\u00e9 a osobn\u00ed slevy",
  "sidebar_user_traded_volume": "Obrat za {{tradeLimitDays}} dn\u00ed: {{traded}}/{{limit}}EUR",
  "trading_limits_info_message": "Limit pro klienty bez verifikace: {{limitLevel1}} EUR/{{level1TradeLimitDays}} dn\u00ed<br/>\n\u00darove\u0148 1: {{limitLevel2}} EUR/{{level2TradeLimitDays}} dn\u00ed<br/>\n\u00darove\u0148 2: form\u00e1ln\u00ed limit nen\u00ed stanoven",
  "sidebar_user_traded_volume_sum": "Objem obchod\u016f: {{traded}} EUR",
  "react_refund_widget_already_chosen_refund_address": "Zvolil/a jste {{refundAddress}} jako adresu pro vr\u00e1cen\u00ed.",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Tato objedn\u00e1vka je ji\u017e zaplacena. Vr\u00e1cen\u00ed platby je zpoplatn\u011bno. Nazp\u011bt obdr\u017e\u00edte {{amount}} {{currency}} na zadanou adresu.",
  "refund_widget_btn_change_refund_address": "Zm\u011bnit adresu pro vr\u00e1cen\u00ed",
  "react_order_crypto_return_fee_info_message": "Vr\u00e1cen\u00ed objedn\u00e1vky je zpoplatn\u011bno. Bude V\u00e1m zasl\u00e1no {{amount}} {{currency}} na zadanou adresu.",
  "react_suspended_widget_return_currency_btn": "Vr\u00e1tit {{amount}} {{currency}} do t\u00e9to pen\u011b\u017eenky",
  "we_will_send_you_crypto_back_shortly": "Jakmile to bude mo\u017en\u00e9, za\u0161leme V\u00e1m kryptom\u011bnu zp\u011bt.",
  "button_remove_limit": "Odstranit limit",
  "react_alert_limit_removed": "Limit \u00fasp\u011b\u0161n\u011b zru\u0161en",
  "unconfirmed_email_please_confirm_or_resend": "Pros\u00edm potvr\u010fte svou emailovou adresu nebo m\u016f\u017eete <0>znovu poslat email s aktiva\u010dn\u00edm k\u00f3dem</0>",
  "button_back": "Zp\u011bt",
  "button_next": "Dal\u0161\u00ed",
  "button_submit_profile": "Odeslat profil k ov\u011b\u0159en\u00ed",
  "you_need_to_log_out_before_seeing_anonymous_order": "Pro zobrazen\u00ed anonymn\u00ed objedn\u00e1vky se mus\u00edte odhl\u00e1sit",
  "react_form_rate_explenation": "Jak po\u010d\u00edt\u00e1me Va\u0161i cenu?",
  "sign_up_to_return_guest_order": "Pro vr\u00e1cen\u00ed objedn\u00e1vky se pros\u00edm zaregistrujte a p\u0159idejte pen\u011b\u017eenku.",
  "error_page_not_found": "Str\u00e1nka nebyla nalezena",
  "react_page_affiliate_withdraw_request_button": "V\u00fdb\u011br {{amount}} {{currency}}",
  "affiliate_withdraw_history_table_converted_amount": "Mno\u017estv\u00ed po sm\u011bn\u011b",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Zkop\u00edrujte adresu URL uvedenou n\u00ed\u017ee a sd\u00edlejte ji na sv\u00e9m blogu, soci\u00e1ln\u00edch s\u00edt\u00edch nebo se sv\u00fdmi p\u0159\u00e1teli. Kdokoli kdo tento odkaz pou\u017eije a pot\u00e9 se do {{duration}} dn\u00ed registruje nebo vytvo\u0159\u00ed objedn\u00e1vku bude za\u0159azen do Va\u0161eho partnersk\u00e9ho programu programu. Obdr\u017e\u00edte provizi z ka\u017ed\u00e9 sm\u011bny provedenou klientem, kter\u00e9ho jste pozvali.",
  "react_affiliaite_balance_information": "Z\u016fstatek: {{balance}}<br/>P\u0159\u00edjem celkem: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "Z\u016fstatek je V\u00e1\u0161 nevyplacen\u00fd p\u0159\u00edjem, kter\u00fd m\u016f\u017eete vybrat. Celkov\u00fd p\u0159\u00edjem je kolik jste vyd\u011blal od za\u010d\u00e1tku spolupr\u00e1ce.",
  "label_affiliate_overview_joined_at": "Za\u010d\u00e1tek spolupr\u00e1ce",
  "label_repeat_password": "Zopakujte heslo",
  "react_consent_to_tos": "Pokra\u010dov\u00e1n\u00edm prohla\u0161uji, \u017ee jsem \u010detl a souhlas\u00edm s <0>obchodn\u00edmi podm\u00ednkami</0> a <1>Zpracov\u00e1n\u00edm osobn\u00edch \u00fadaj\u016f</1> spole\u010dnost\u00ed {{companyName}}",
  "label_delete_account": "Odstran\u011bn\u00ed \u00fa\u010dtu",
  "button_delete_account": "Odstranit \u00fa\u010det",
  "delete_account_warning_paragraph": "Pokud budete pokra\u010dovat bude V\u00e1\u0161 \u00fa\u010det u Simplecoinu trvale odstran\u011bn. Je n\u00e1m l\u00edto, \u017ee odch\u00e1z\u00edte. M\u016f\u017eete se registrovat pozd\u011bji se stejn\u00fdm emailem, ale \u00fa\u010det bude bez historie a ov\u011b\u0159en\u00ed.",
  "account_delete_success": "V\u00e1\u0161 \u00fa\u010det byl odstran\u011bn",
  "log_out_success": "Byli jste odhl\u00e1\u0161eni",
  "session_time_remaining_label": "\u010cas do odhl\u00e1\u0161en\u00ed",
  "session_expire_in_label": "Budete odhl\u00e1\u0161en",
  "order_filter_label": "Filtr objedn\u00e1vek",
  "order_filter_button_all": "V\u0161echny",
  "to_currency_amount_field_label": "Chci obdr\u017eet",
  "status_name_unfinished": "Nedokon\u010den\u00e1",
  "status_description_unfinished": "Objedn\u00e1vka zat\u00edm nedos\u00e1hla kone\u010dn\u00e9ho stavu",
  "filter_drawer_incomplete_filters_warning": "Vyberte pros\u00edm alespo\u0148 jeden stav z ka\u017ed\u00e9 z kategorie",
  "help_pages_label": "Centrum pomoci",
  "react_file_upload_size_error": "Soubor kter\u00fd jste se sna\u017eili nahr\u00e1t je p\u0159\u00edli\u0161 velik\u00fd. Maxim\u00e1ln\u00ed velikost souboru je {{size}}MB",
  "alert_submit_previous_level_first": "Vypl\u0148te pros\u00edm nejd\u0159\u00edve p\u0159edchoz\u00ed \u00farove\u0148 verifikace profilu",
  "wrong_vs_format_message": "Variabiln\u00ed symbol obsahuje maxim\u00e1ln\u011b 10 \u010d\u00edslic",
  "wrong_ss_format_message": "Specifick\u00fd symbol obsahuje maxim\u00e1ln\u011b 10 \u010d\u00edslic",
  "wrong_ks_format_message": "Konstantn\u00ed symbol obsahuje maxim\u00e1ln\u011b 4 \u010d\u00edslice",
  "button_extend_session": "Prodlou\u017eit relaci",
  "fiat_payment_recipient_name": "Jm\u00e9no p\u0159\u00edjemce",
  "fiat_payment_recipient_street": "Ulice p\u0159\u00edjemce",
  "fiat_payment_recipient_city": "M\u011bsto p\u0159\u00edjemce",
  "fiat_payment_recipient_country": "Zem\u011b p\u0159\u00edjemce",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "Za\u0161lete {{amount}} <0>{{currency}}</0> na n\u00e1sleduj\u00edc\u00ed bankovn\u00ed \u00fa\u010det",
  "crypto_qr_hint": "N\u00e1sleduj\u00edc\u00ed k\u00f3d m\u016f\u017eete naskenovat nebo kliknut\u00edm na n\u011bj otev\u0159\u00edt pen\u011b\u017eenku ve Va\u0161em za\u0159\u00edzen\u00ed.",
  "react_validation_error_insufficient_funds": "Nedostatek prost\u0159edk\u016f. M\u00e1te pouze {{amount}} {{currencyName}}.",
  "client_settings_label_affiliate_new_income": "Nov\u00e1 provize byla p\u0159ips\u00e1na",
  "client_setting_hint_affiliate_new_income": "Poslat email po p\u0159ips\u00e1n\u00ed provize na \u00fa\u010det partnersk\u00e9ho programu",
  "page_subheader_settings_affiliate": "Affiliate program",
  "check_address_on_trezor_header": "Zkontrolujte adresu na Trezoru",
  "check_address_on_trezor_body": "A stiskn\u011bte tla\u010d\u00edtko pokra\u010dovat",
  "show_address_on_trezor_button_label": "Uk\u00e1zat adresu na Trezoru",
  "send_by_trezor_button": "Zaplatit Trezorem",
  "send_by_trezor_description_tooltip": "M\u016f\u017eete vyu\u017e\u00edt tuhle funkci pro bezpe\u010dn\u00e9 zasl\u00e1n\u00ed platby z p\u0159ipojen\u00e9ho za\u0159\u00edzen\u00ed Trezor.",
  "trezor_address_import_dialog_header": "N\u00e1sleduj\u00edc\u00ed adresy pat\u0159\u00ed k vybran\u00e9mu \u00fa\u010dtu Trezoru. Pros\u00edm, vyberte jednu.",
  "import_from_trezor_button": "P\u0159idat z Trezoru",
  "address_used": "pou\u017eit\u00e1",
  "trezor_transaction_sent_dialog_title": "Transakce byla \u00fasp\u011b\u0161n\u011b odesl\u00e1na.",
  "trezor_transaction_sent_dialog_content": "ID transakce je: {{txid}} Pros\u00edm, chvilku po\u010dkejte dokud se transakce sp\u00e1ruje s objedn\u00e1vkou. M\u016f\u017eete pou\u017e\u00edt ikonu obnoven\u00ed pro znovu na\u010dten\u00ed objedn\u00e1vky.",
  "trezor_address_import_available_for_currencies": "Importov\u00e1n\u00ed adres z Trezoru je mo\u017en\u00e9 pro: {{currencies}}",
  "trezor_link_learn_more": "Zjist\u011bte v\u00edc o pou\u017e\u00edv\u00e1n\u00ed Trezoru na {{brandName}}",
  "page_refresh_button_label": "Obnovit",
  "profile_level_one_send_transfer_instead_of_document_upload": "M\u00edsto poskytnut\u00ed dokumentu po\u0161lu bankovn\u00ed p\u0159evod z \u00fa\u010dtu veden\u00e9ho na m\u00e9 jm\u00e9no",
  "profile_level1_primary_document_front_label": "P\u0159edn\u00ed strana prvn\u00edho dokumentu",
  "profile_level1_primary_document_reverse_label": "Zadn\u00ed strana prvn\u00edho dokumentu",
  "profile_level1_secondary_document_front_label": "P\u0159edn\u00ed strana druh\u00e9ho dokumentu",
  "profile_level1_secondary_document_reverse_label": "Zadn\u00ed strana druh\u00e9ho dokumentu",
  "profile_page_level1_primary_document_label": "Prvn\u00ed dokument toto\u017enosti",
  "profile_page_level1_secondary_document_label": "Druh\u00fd dokument toto\u017enosti",
  "profile_page_level1_additional_documents_label": "Dodate\u010dn\u00e9 dokumenty",
  "profile_page_level1_document_passport": "Cestovn\u00ed pas",
  "profile_page_level1_document_national_id": "Ob\u010dansk\u00fd pr\u016fkaz",
  "profile_page_level1_document_other": "Jin\u00fd dokument",
  "profile_page_level1_document_driving_license": "\u0158idi\u010dsk\u00fd pr\u016fkaz",
  "profile_page_level1_primary_document_type_select_information": "Vyberte typ prvn\u00edho dokumentu",
  "profile_page_level1_secondary_document_type_select_information": "Vyberte typ druh\u00e9ho dokumentu",
  "profile_page_level1_additional_document_type_select_information": "P\u0159idejte dodate\u010dn\u00fd dokument",
  "profile_page_level1_additional_document_skip_label": "Nem\u00e1m dal\u0161\u00ed dokumenty k verifikaci",
  "address_format_bech32_wallet_support_warning": "Tato adresa je ve form\u00e1tu bech32, kter\u00fd nen\u00ed doposud v Trezor pen\u011b\u017eence pln\u011b podporov\u00e1n. Pro plnou funk\u010dnost pros\u00edm vyberte typ \u00fa\u010dtu \"Segwit accounts\" nebo \"Legacy accounts\".  Adresu ve form\u00e1tu bech32 lze nyn\u00ed pou\u017e\u00edt s pen\u011b\u017eenkou electrum.org.",
  "label_document_title": "N\u00e1zev dokumentu",
  "react_new_app_version_available_dialog_title": "Nov\u00e1 verze {{brandName}} je k dispozici",
  "update_application_version": "Aktualizovat",
  "third_party_guest_order_provide_email_paragraph": "Pro pokra\u010dov\u00e1n\u00ed zadejte email",
  "third_party_order_claim_email_verification_message": "Pros\u00edm, zkontrolujte si sv\u016fj mail, kde najdete odkaz na potvrzen\u00ed zalo\u017een\u00ed \u00fa\u010dtu.",
  "third_party_guest_order_confirm_or_resend_email": "Pros\u00edm, potvr\u010fte svou emailovou adresu nebo klikn\u011bte na tla\u010d\u00edtko n\u00ed\u017ee pro op\u011btovn\u00e9 odesl\u00e1n\u00ed mailu.",
  "log_in_to_see_order_registered_client": "Pro zobrazen\u00ed objedn\u00e1vky se p\u0159ihlaste",
  "client_recipients_list_header": "P\u0159\u00edjemci",
  "sidebar_navigation_recipients": "P\u0159\u00edjemci",
  "recipients_filter_label": "Filtrovat p\u0159\u00edjemce",
  "client_add_new_recipient_button": "P\u0159idat p\u0159\u00edjemce",
  "reset_filters_button": "Resetovat",
  "create_new_recipient_header": "P\u0159idat nov\u00e9ho p\u0159\u00edjemce",
  "disable_social_login_label": "Odpojit \u00fa\u010det soci\u00e1ln\u00ed s\u00edt\u011b",
  "btn_unlink": "Odpojit",
  "alert_error_please_set_password_to_disable_social_login": "Pros\u00edm nastavte si prvn\u00ed vlastn\u00ed heslo k \u00fa\u010dtu Simplecoinu, aby bylo umo\u017en\u011bno p\u0159ihl\u00e1\u0161en\u00ed po odebr\u00e1n\u00ed p\u0159ihl\u00e1\u0161en\u00ed pomoc\u00ed soci\u00e1ln\u00edho \u00fa\u010dtu.",
  "disable_social_login_warning_paragraph": "Pokud budete pokra\u010dovat, V\u00e1\u0161 soci\u00e1ln\u00ed \u00fa\u010det bude odpojen. Potom se budete moci p\u0159ihla\u0161ovat pouze Va\u0161im {{brandName}} heslem.",
  "social_login_disable_success": "P\u0159ihl\u00e1\u0161en\u00ed pomoc\u00ed \u00fa\u010dt\u016f soci\u00e1ln\u00edch s\u00edt\u00ed bylo zak\u00e1z\u00e1no",
  "file_attached_to_ticket_auto_message": "Soubor nahr\u00e1n: {{fileNames}}",
  "network_error_warning": "P\u0159ipojen\u00ed k internetu nen\u00ed k dispozici",
  "password_strength_very_weak": "Velmi slab\u00e9 heslo",
  "password_strength_weak": "Slab\u00e9 heslo",
  "password_strength_good": "P\u0159ijateln\u00e9 heslo",
  "password_strength_great": "Siln\u00e9 heslo",
  "status_description_on_hold": "Objedn\u00e1vka je zadr\u017eena",
  "status_direction_description_on_hold": "Objedn\u00e1vka je zadr\u017eena",
  "exchange_form_fee_included_tooltip_title": "Kurz, kter\u00fd vid\u00edte, je v\u010detn\u011b transak\u010dn\u00edch poplatk\u016f",
  "exchange_form_fee_not_included_tooltip_title": "N\u00ed\u017ee uveden\u00e9 poplatky nejsou zahrnuty v kurzu. Pro jejich zapo\u010d\u00edt\u00e1n\u00ed zadejte mno\u017estv\u00ed.",
  "exchange_form_total_fee_eur": "Poplatek celkem: ~{{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Poplatek za p\u0159\u00edchoz\u00ed transakci: {{amount}} {{currency}}",
  "exchange_form_fee_tooltip_fee_out": "Poplatek za odchoz\u00ed transakci: {{amount}} {{currency}}",
  "exchange_form_fee_label_no_fee": "Bez poplatku",
  "exchange_form_fee_label_no_fee_explanation": "Tato sm\u011bna nem\u00e1 \u017e\u00e1dn\u00fd transak\u010dn\u00ed poplatek",
  "exchange_form_fee_label_included": "V\u010detn\u011b transak\u010dn\u00edho poplatku",
  "exchange_form_fee_label_excluded": "Transak\u010dn\u00ed poplatek nen\u00ed zahrnut",
  "order_view_back_to_vendor_button_label": "Zp\u011bt na {{providerName}}",
  "table_sort_label": "Se\u0159adit",
  "pagination_next_page": "Dal\u0161\u00ed str\u00e1nka",
  "pagination_previous_page": "P\u0159edchoz\u00ed str\u00e1nka",
  "pagination_rows_per_page": "\u0158\u00e1dk\u016f na str\u00e1nku",
  "pagination_display_rows": "z",
  "button_download_csv": "St\u00e1hnout zobrazen\u00e9 jako CSV",
  "button_print": "Tisk",
  "table_view_columns": "Zobrazit sloupce",
  "table_filter": "Filtrovat",
  "table_filters_label": "Filtry",
  "show_columns_label": "Uk\u00e1zat sloupce",
  "table_rows_selected": "ozna\u010den\u00fdch sloupc\u016f",
  "add_account": "P\u0159idat \u00fa\u010det",
  "account_balance": "Z\u016fstatek",
  "account_minimal_balance_label": "Minim\u00e1ln\u00ed z\u016fstatek",
  "page_header_accounts": "\u00da\u010dty",
  "sidebar_navigation_accounts": "\u00da\u010dty",
  "sidebar_navigation_trade": "Obchodovat",
  "landing_page_chart_widget_buy_button": "Koupit",
  "exchange_form_from_amount_label": "Nakoup\u00edte za",
  "exchange_form_to_amount_label": "Dostanete",
  "footer_trading_since": "M\u011bn\u00edme kryptom\u011bny od roku 2013",
  "footer_headers_subscribe_to_newsletter": "Odeb\u00edrejte na\u0161e novinky",
  "footer_rights_reserved": "\u00a9 {{year}} {{companyName}} V\u0161echna pr\u00e1va vyhrazena.",
  "landing_header": "Sm\u011bn\u00e1rna kryptom\u011bn s d\u016fv\u011brou od roku 2013",
  "landing_subheader": "Snadn\u00e9 obchodov\u00e1n\u00ed. Provozujeme v\u00edce ne\u017e 30 m\u011bnov\u00fdch p\u00e1r\u016f.",
  "landing_button_join": "Jdu do toho",
  "landing_button_about_us": "O n\u00e1s",
  "landing_recent_orders_header": "Posledn\u00ed objedn\u00e1vky",
  "landing_statistics_30_day_order_count_label": "Po\u010det sm\u011bn proveden\u00fdch v posledn\u00edch 30 dnech",
  "landing_statistics_verified_clients_count": "Ov\u011b\u0159en\u00fdch klient\u016f",
  "landing_statistics_volume_in_currency": "30 denn\u00ed objem v {{currency}}",
  "landing_header_help": "Pomoc",
  "button_buy_now": "Sm\u011bnit te\u010f",
  "footer_header_support": "Podpora",
  "footer_link_help": "N\u00e1pov\u011bda",
  "footer_header_company": "Spole\u010dnost",
  "footer_header_features": "Funkce",
  "footer_features_trezor_support": "Podpora Trezoru",
  "footer_features_limit_order": "Limitn\u00ed objedn\u00e1vka",
  "footer_email_newsletter_form_text": "Nenech si nic uj\u00edt! P\u0159ihla\u0161 se k odb\u011bru newsletteru od {{brandName}}",
  "landing_email_newsletter_subscribe_button": "Odeb\u00edrat",
  "landing_email_newsletter_subscribe_field_placeholder": "Zde vlo\u017ete svou emailovou adresu",
  "landing_email_subscribe_consent_warning": "Vlo\u017een\u00edm emailu souhlas\u00edm s obchodn\u00edmi podm\u00ednkami a zpracov\u00e1n\u00edm osobn\u00edch \u00fadaj\u016f",
  "footer_link_transaction_fees": "Poplatky za transakci",
  "footer_link_trade_limits": "Limity sm\u011bn",
  "add_the_following_comment_to_payment": "U platby zadejte n\u00e1sleduj\u00edc\u00ed zpr\u00e1vu pro p\u0159\u00edjemce",
  "alert_error_please_set_password_to_enable_two_factor": "P\u0159ed zapnut\u00edm dvoufaktorov\u00e9ho p\u0159ihla\u0161ov\u00e1n\u00ed je pot\u0159eba nastavit heslo",
  "alert_success_google_authenticator_disabled_successfully": "Dvouf\u00e1zov\u00e9 ov\u011b\u0159ov\u00e1n\u00ed bylo \u00fasp\u011b\u0161n\u011b vypnuto.",
  "alert_success_google_authenticator_enabled_successfully": "Dvouf\u00e1zov\u00e9 ov\u011b\u0159ov\u00e1n\u00ed bylo \u00fasp\u011b\u0161n\u011b zapnuto.",
  "confirmed": "Potvrzen\u00e1",
  "created_at": "Vytvo\u0159eno",
  "enter_form_email_placeholder": "Email",
  "enter_form_password_placeholder": "Heslo",
  "fiat_payments_without_note_cause_delay_warning": "Platby bez cel\u00e9 t\u00e9to zpr\u00e1vy pro p\u0159\u00edjemce nebudou sp\u00e1rov\u00e1ny automaticky. To vede k prodlev\u00e1m p\u0159i zpracov\u00e1n\u00ed objedn\u00e1vky a n\u00e1sledn\u00e9mu riziku zm\u011bny kurzu.",
  "form_label_new_password": "Nov\u00e9 heslo",
  "incoming_bank_transaction": "P\u0159\u00edchoz\u00ed bankovn\u00ed transakce",
  "incoming_crypto_transaction": "P\u0159\u00edchoz\u00ed krypto transakce",
  "label_2fa_code": "2FA K\u00f3d",
  "label_account_constant": "Konstantn\u00ed symbol",
  "label_account_label": "Pojmenov\u00e1n\u00ed",
  "label_account_number": "\u010c\u00edslo \u00fa\u010dtu kam V\u00e1m za\u0161leme pen\u00edze",
  "label_account_specific": "Specifick\u00fd symbol",
  "label_account_vs": "Variabiln\u00ed symbol",
  "label_address": "Adresa",
  "label_address_label": "Pojmenov\u00e1n\u00ed",
  "label_addresses": "Adresa",
  "label_affiliate_overview_affiliate_id": "ID Partnersk\u00e9ho programu",
  "label_affiliate_overview_estimated_profit": "Nedokon\u010den\u00e9 objedn\u00e1vky",
  "label_affiliate_overview_referrals_count": "P\u0159ihl\u00e1\u0161en\u00ed u\u017eivatel\u00e9",
  "label_affiliate_overview_url_hits_count": "Kliknut\u00ed na bannery",
  "label_amount": "\u010c\u00e1stka",
  "label_arbitrary_data": "Destination Tag",
  "label_bank_account": "Bankovn\u00ed \u00fa\u010det",
  "label_bank_account_id": "Bankovn\u00ed \u00fa\u010det",
  "label_bank_accounts": "Bankovn\u00ed \u00fa\u010dty",
  "label_birthday": "Datum narozen\u00ed",
  "label_city": "M\u011bsto",
  "label_confirmations": "Potvrzen\u00ed (aktu\u00e1ln\u011b / pot\u0159ebn\u00e9 minimum)",
  "label_country": "St\u00e1t",
  "label_created_at": "Vytvo\u0159eno",
  "label_currency": "M\u011bna",
  "label_current_password": "Aktu\u00e1ln\u00ed heslo",
  "label_document_number": "\u010c\u00edslo dokumentu",
  "label_expires_at": "Platnost do",
  "label_first_name": "Jm\u00e9no",
  "label_from_amount_currency": "z",
  "label_hash": "Hash",
  "label_id": "ID",
  "label_in_block_id": "ID bloku",
  "label_is_politician": "Politicky exponovan\u00e1 osoba",
  "label_issued_by": "Vyd\u00e1no \u00fa\u0159adem",
  "label_issued_by_country": "Zem\u011b vydavatele",
  "label_last_name": "P\u0159\u00edjmen\u00ed",
  "label_limit_price": "Limitn\u00ed kurz",
  "label_message": "Zpr\u00e1va pro p\u0159\u00edjemce",
  "label_monthly_volume": "Zobchodov\u00e1no za 30 dn\u00ed",
  "label_new_password_again": "Znovu nov\u00e9 heslo",
  "label_note": "Pozn\u00e1mka",
  "label_other_account_number": "Bankovn\u00ed \u00fa\u010det",
  "label_our_bank_account_id": "Bankovn\u00ed \u00fa\u010det {{brandName}}",
  "label_owner_address": "Adresa majitele \u00fa\u010dtu",
  "label_owner_city": "M\u011bsto majitele \u00fa\u010dtu",
  "label_owner_country": "St\u00e1t majitele",
  "label_owner_name": "Jm\u00e9no majitele \u00fa\u010dtu",
  "label_paid_at": "Zaplaceno",
  "label_personal_id": "Rodn\u00e9 \u010d\u00edslo",
  "label_phone": "Telefonn\u00ed \u010d\u00edslo",
  "label_rate": "Kurz p\u0159i objedn\u00e1vce",
  "label_ready_for_verification": "P\u0159ipraveno k ov\u011b\u0159en\u00ed",
  "label_receive_address": "P\u0159\u00edjmov\u00e1 adresa",
  "label_status": "Stav",
  "label_status_direction": "Sm\u011br",
  "label_status_final": "Kone\u010dn\u00fd stav",
  "label_street": "Ulice",
  "label_ticket_body": "Popis po\u017eadavku",
  "label_ticket_category": "Kategorie",
  "label_ticket_title": "P\u0159edm\u011bt",
  "label_to_amount_currency": "Na",
  "label_trading_purpose": "\u00da\u010del obchodov\u00e1n\u00ed",
  "label_trading_source_of_income": "Zdroj p\u0159\u00edjmu",
  "label_zipcode": "PS\u010c",
  "mail_client_identification_bullet_point_fill_personal_information": "Vypl\u0148te osobn\u00ed informace",
  "oops_something_went_wrong": "Jejda, n\u011bco se pokazilo. Pros\u00edm zkuste to pozd\u011bji.",
  "order_id": "ID objedn\u00e1vky",
  "outgoing_bank_transaction": "Odchoz\u00ed bankovn\u00ed transakce",
  "outgoing_crypto_transaction": "Odchoz\u00ed krypto transakce",
  "page_header_settings": "Nastaven\u00ed",
  "password_was_changed": "Heslo bylo zm\u011bn\u011bno",
  "settings_updated": "Nastaven\u00ed byla aktualizov\u00e1na",
  "social_login_with_facebook": "P\u0159ihlaste se \u00fa\u010dtem Facebook",
  "social_login_with_vkontakte": "P\u0159ihlaste se \u00fa\u010dtem VKontakte",
  "social_signin_with_google": "P\u0159ihlaste se \u00fa\u010dtem Google",
  "social_signin_with_linkedin": "P\u0159ihlaste se \u00fa\u010dtem LinkedIn",
  "status": "Stav",
  "status_description_cancelled": "Objedn\u00e1vka je zru\u0161ena",
  "status_description_confirming_incoming": "Objedn\u00e1vka potvrzuje novou p\u0159\u00edchoz\u00ed transakci",
  "status_description_confirming_outgoing": "Objedn\u00e1vka potvrzuje novou odchoz\u00ed transakci",
  "status_description_delivered": "Objedn\u00e1vka je doru\u010dena",
  "status_description_done": "Objedn\u00e1vka je dokon\u010dena",
  "status_description_new": "Objedn\u00e1vka byla vytvo\u0159ena, ale prozat\u00edm jsme nep\u0159ijali p\u0159\u00edchoz\u00ed platbu",
  "status_description_proceed": "Objedn\u00e1vka bude provedena jak bylo zam\u00fd\u0161leno.",
  "status_description_received": "Obdr\u017eeli jsme Va\u0161i bankovn\u00ed/krypto platbu",
  "status_description_return_to_client": "Objedn\u00e1vka je nastaven\u00e1 na vr\u00e1cen\u00ed klientovi",
  "status_description_returned": "Transakce t\u00e9to objedn\u00e1vky byla vr\u00e1cen\u00e1 klientovi",
  "status_description_suspended": "Objedn\u00e1vka je pozastavena z t\u011bchto mo\u017en\u00fdch d\u016fvod\u016f: platba p\u0159ips\u00e1na pozd\u011b, bankovn\u00ed zpr\u00e1va pro p\u0159\u00edjemce nen\u00ed spr\u00e1vn\u00e1,  p\u0159ekro\u010den\u00fd m\u011bs\u00ed\u010dn\u00ed limit a pot\u0159eba ov\u011b\u0159en\u00ed profilu, V\u00e1\u0161 pokyn.",
  "ticket_category_affiliate": "Partner",
  "ticket_category_api": "API",
  "ticket_category_business_verification": "Ov\u011b\u0159en\u00ed firemn\u00edch klient\u016f",
  "ticket_category_order": "Objedn\u00e1vky",
  "ticket_category_other": "Ostatn\u00ed",
  "ticket_category_security": "Zabezpe\u010den\u00ed",
  "ticket_category_suggestion": "N\u00e1vrh na zlep\u0161en\u00ed",
  "ticket_category_verification": "Ov\u011b\u0159en\u00ed",
  "validation_error_incorrect_email_format": "Zadejte e-mail ve spr\u00e1vn\u00e9m tvaru.",
  "you_have_accepted_current_suspended_deal": "P\u0159ijali jste st\u00e1vaj\u00edc\u00ed hodnotu",
  "close": "Zav\u0159\u00edt",
  "status_on_hold": "Zadr\u017een\u00e1",
  "submit": "Odeslat",
  "resend": "Poslat znovu",
  "reviews": "Napsali o n\u00e1s",
  "label_counter_address": "Adresa proti\u00fa\u010dtu",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "Za\u0161lete {{amount}} <0>{{currency}}</0> na jeden z n\u00e1sleduj\u00edc\u00edch bankovn\u00edch \u00fa\u010dt\u016f",
  "order_filter_button_new": "Nov\u00e9",
  "order_filter_button_in_progress": "Aktivn\u00ed",
  "order_filter_button_finished": "Hotov\u00e9",
  "order_filter_button_custom": "Vlastn\u00ed",
  "client_order_status_base_label": "Status",
  "client_order_status_direction_label": "Sm\u011br",
  "client_order_status_final_label": "Kone\u010dn\u00fd stav",
  "ticket_status_solved": "Vy\u0159e\u0161eno",
  "ticket_status_in_progress": "Prob\u00edhaj\u00edc\u00ed",
  "ticket_status_waiting_for_admin": "Odeslan\u00fd",
  "ticket_status_waiting_for_client": "Nov\u00e1 zpr\u00e1va",
  "member_of": "Zakl\u00e1daj\u00edc\u00ed \u010dlen",
  "level_one_verification_warning_back_transfer_instead_of_statement": "V\u00edce informac\u00ed o ov\u011b\u0159en\u00ed bankovn\u00edho \u00fa\u010dtu naleznete v <0>tomto \u010dl\u00e1nku</0>",
  "label_bank_account_number": "\u010c\u00edslo bankovn\u00edho \u00fa\u010dtu",
  "label_bank_account_details": "Informace o bankovn\u00edm \u00fa\u010dtu",
  "validation_max_characters": "Pole m\u016f\u017ee obsahovat maxim\u00e1ln\u011b ${max} znak\u016f",
  "verification_required_if_order_made_warning_for_registered_clients": "Pro odbaven\u00ed t\u00e9to objedn\u00e1vky budete muset nav\u00fd\u0161it svoj\u00ed \u00farove\u0148 verifikace. Va\u0161e objedn\u00e1vka bude proto automaticky pozastavena a po dokon\u010den\u00ed verifikace si budete muset potvrdit aktu\u00e1ln\u00ed kurz v detailu objedn\u00e1vky. Informace o limitech a verifikaci <0>naleznete zde</0>.",
  "verification_required_if_order_made_warning_for_guests": "Pro odbaven\u00ed t\u00e9to objedn\u00e1vky se budete muset zaregistrovat a n\u00e1sledn\u011b nav\u00fd\u0161it svoj\u00ed \u00farove\u0148 verifikace. Va\u0161e objedn\u00e1vka bude proto automaticky pozastavena a po dokon\u010den\u00ed verifikace si budete muset potvrdit aktu\u00e1ln\u00ed kurz v detailu objedn\u00e1vky. Informace o limitech a verifikaci <0>naleznete zde</0>.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "P\u0159ihlaste se nebo zaregistrujte pro vytvo\u0159en\u00ed objedn\u00e1vky",
  "get_verified_to_start_trading_warning_message": "Pro obchodov\u00e1n\u00ed mus\u00edte nav\u00fd\u0161it svoj\u00ed \u00farove\u0148 ov\u011b\u0159en\u00ed. Nyn\u00ed m\u00e1te <strong>\u00farove\u0148 {{currentVerificationLevel}}</strong> a pot\u0159ebujete <strong>\u00farove\u0148 {{requiredVerificationLevel}}</strong>. P\u0159ejd\u011bte pros\u00edm do z\u00e1lo\u017eky profil a pokra\u010dujte dle instrukc\u00ed.",
  "offer_will_expire_in": "Platnost t\u00e9to nab\u00eddky vypr\u0161\u00ed",
  "offer_has_expired": "Platnost t\u00e9to nab\u00eddky vypr\u0161ela. Pros\u00edm vytvo\u0159te novou na str\u00e1nce partnera nebo p\u0159\u00edmo na <0>{{company}}</0>.",
  "order_offer_header": "Nab\u00eddka sm\u011bny na {{companyName}}",
  "verification_required_for_order_creation_partner_order": "Pro vytv\u00e1\u0159en\u00ed objedn\u00e1vek na {{brandName}} je nyn\u00ed vy\u017eadov\u00e1na je verifikace na \u00farove\u0148 {{level}}. Pros\u00edme o registraci a ov\u011b\u0159en\u00ed profilu pro pokra\u010dov\u00e1n\u00ed.",
  "label_specify_destination_tag": "D\u016fle\u017eit\u00e9: uve\u010fte {{destinationTag}} jako destination tag.",
  "validation_error_required_characters_exactly": "Pole ${path} mus\u00ed obsahovat p\u0159esn\u011b ${length} znak\u016f",
  "csv_export_column_from_currency": "V\u00fdchoz\u00ed m\u011bna",
  "csv_export_column_to_currency": "C\u00edlov\u00e1 m\u011bna",
  "react_order_crypto_no_fee_info_message": "Tato operace je bez poplatku. Vr\u00e1cena V\u00e1m bude cel\u00e1 \u010d\u00e1stka.",
  "login_confirmation_form_check_email_paragraph": "Odeslali jsme V\u00e1m email s ov\u011b\u0159ovac\u00edm k\u00f3dem. Zkontrolujte si pros\u00edm schr\u00e1nku a zadejte k\u00f3d pro dokon\u010den\u00ed p\u0159ihl\u00e1\u0161en\u00ed.",
  "login_confirmation_title": "Verifikace emailu",
  "login_confirmation_email_resent": "Email byl znovu zasl\u00e1n",
  "login_confirmation_email_not_received": "Neobdr\u017eeli jste verifika\u010dn\u00ed email? Zkontrolujte slo\u017eku spam. M\u016f\u017ee to trvat i n\u011bkolik minut.",
  "login_confirmation_code_input_placeholder": "6-ti m\u00edstn\u00fd k\u00f3d",
  "lost_2fa_device_contact_support": "Ztratili jste telefon? Kontaktujte podporu na {{companyEmail}} nebo {{companyPhone}} ",
  "higher_verification_limit_required_warning": "Pro zobrazen\u00ed platebn\u00edch instrukc\u00ed ov\u011b\u0159te sv\u016fj profil",
  "verification_through_onfido_disclaimer": "Ov\u011b\u0159en\u00ed identity provede spole\u010dnost Onfido.",
  "signup_and_verification_required_warning": "Pro pokra\u010dov\u00e1n\u00ed se pros\u00edm <0>zaregistrujte</0> a pot\u00e9 ov\u011b\u0159te profil.",
  "go_to_profile_button": "Verifikace",
  "profile_page_divex_address_ownership_disclaimer_heading": "Prohl\u00e1\u0161en\u00ed o zabezpe\u010den\u00ed",
  "profile_page_divex_address_ownership_disclaimer_message": "Pros\u00edm p\u0159ilo\u017ete sken nebo fotokopii \u010destn\u00e9ho prohl\u00e1\u0161en\u00ed o vlastnictv\u00ed, kter\u00fdm prokazujete \u017ee jste jedin\u00fd kdo m\u00e1 p\u0159\u00edstup ke kryptom\u011bnov\u00fdm adres\u00e1m, kter\u00e9 budete pou\u017e\u00edvat v r\u00e1mci sm\u011bn\u00e1rny Divex.",
  "profile_page_divex_address_ownership_disclaimer_warning": "Prohl\u00e1\u0161en\u00ed vypl\u0148ujete, abychom si byli jist\u00ed, \u017ee nap\u0159\u00edklad v\u00e1\u0161 poradce nem\u00e1 p\u0159\u00edstup k va\u0161\u00ed kryptom\u011bnov\u00e9 pen\u011b\u017eence a proto v\u00e1m nem\u016f\u017ee kryptom\u011bny v budoucnu odcizit.\n\nChcete-li se dozv\u011bd\u011bt v\u00edce o doporu\u010den\u00e9m zabezpe\u010den\u00ed pen\u011b\u017eenky, <0>klikn\u011bte zde</0>.",
  "signup_for_invited_only_divex_message": "Registrace je pouze pro klienty sm\u011bn\u00e1rny Divex. </br>\nChcete se st\u00e1t klientem? Kontaktujte jednoho z na\u0161ich partner\u016f, kter\u00fd v\u00e1m pom\u016f\u017ee s registrac\u00ed a nastaven\u00edm cel\u00e9ho procesu.</br>\nSeznam v\u0161ech partner\u016f je <0>zde</0>.",
  "divex_address_ownership_disclaimer_upload_label": "Prohl\u00e1\u0161en\u00ed nahrajte zde",
  "email_verification_token_expired_log_in_again": "Verifika\u010dn\u00ed odkaz je nespr\u00e1vn\u00fd nebo vypr\u0161ela jeho platnost. Pros\u00edm p\u0159ihlaste se znovu",
  "pagination_total_visible_rows": "{visibleCount} z {totalCount}",
  "phone_hint_include_prefix": "Uve\u010fte v\u010detn\u011b mezin\u00e1rodn\u00edho p\u0159ed\u010d\u00edsl\u00ed",
  "sidebar_navigation_transactions": "Transakce",
  "account_balance_formatted": "Z\u016fstatek: {{balanceWithCurrency}}",
  "transfer_sent_successfully": "P\u0159evod proveden",
  "cookie_consent_i_agree_to_all": "Povolit v\u0161e",
  "cookie_consent_i_agree_to_required": "Pouze funk\u010dn\u00ed cookies",
  "divex_affil_find_more": "V\u00edce informac\u00ed o partnersk\u00e9m programu naleznete v <0>tomto \u010dl\u00e1nku<0/>",
  "button_i_confirm": "Potvrzuji",
  "wallet_ownership_declaration_paragraph_1": "Pro ulo\u017een\u00ed digit\u00e1ln\u00edch aktiv mi bylo doporu\u010deno pou\u017e\u00edvat hardwarovou pen\u011b\u017eenku Trezor, kter\u00e1 je opat\u0159en\u00e1 PINem.",
  "wallet_ownership_declaration_paragraph_2": "Jsem vlastn\u00edkem z\u00e1lohy pen\u011b\u017eenky (12-24 slov), kterou m\u00e1m bezpe\u010dn\u011b ulo\u017eenou. Je mi zn\u00e1mo, \u017ee kryptom\u011bny v pen\u011b\u017eence lze kontrolovat i pomoc\u00ed z\u00e1lohy, tedy bez samotn\u00e9ho za\u0159\u00edzen\u00ed.",
  "wallet_ownership_declaration_paragraph_3": "Poradce, kter\u00fd mi pom\u00e1hal se zalo\u017een\u00edm pen\u011b\u017eenky, nem\u011bl k z\u00e1loze m\u00e9 pen\u011b\u017eenky p\u0159\u00edstup a nemohl ud\u011blat jej\u00ed kopii.",
  "page_header_exchanges": "Sm\u011bny",
  "sidebar_navigation_dashboard": "N\u00e1st\u011bnka",
  "dashboard_header": "N\u00e1st\u011bnka",
  "page_header_transfers": "P\u0159evody",
  "settings_button_general_settings": "Obecn\u00e1 nastaven\u00ed",
  "settings_button_security_settings": "Zabezpe\u010den\u00ed",
  "settings_button_email_settings": "Pravidla pro odes\u00edl\u00e1n\u00ed e-mail\u016f",
  "settings_button_profile_verification": "Ov\u011b\u0159en\u00ed profilu",
  "action_activate_account_email_sent": "Do uveden\u00e9 e-mailov\u00e9 schr\u00e1nky jsme v\u00e1m odeslali odkaz na aktivaci hesla.",
  "action_activate_account_error_email_not_sent": "E-mail, kter\u00fd jste zvolili nesouhlas\u00ed. Zvolte jin\u00fd e-mail.",
  "request_account_activation": "Aktivace \u00fa\u010dtu",
  "account_activation_enter_email_provided_to_support": "Aktivujte si Divex \u00fa\u010det vlo\u017een\u00edm e-mailu, kter\u00fd jste pou\u017eili pro registraci na \u0161kolen\u00ed \u00davod do Bitcoinu nebo Diverzo Academy.",
  "account_activate_button": "Aktivovat \u00fa\u010det",
  "account_activation_password_saved": "Va\u0161e heslo bylo ulo\u017eeno. Nyn\u00ed se m\u016f\u017eete p\u0159ihl\u00e1sit.",
  "breadcrumb_category_order": "Objedn\u00e1vka",
  "breadcrumb_subcategory_order_id": "Objedn\u00e1vka #{{id}}",
  "page_header_general_settings": "Obecn\u00e1 nastaven\u00ed",
  "page_header_email_settings": "Pravidla pro odes\u00edl\u00e1n\u00ed e-mail\u016f",
  "dashboard_order_table_last_orders": "Posledn\u00ed objedn\u00e1vky",
  "dashboard_order_table_all_orders": "V\u0161echny objedn\u00e1vky",
  "user_discount": "Sleva z mar\u017ee",
  "get_discount": "Z\u00edskejte slevu",
  "volume_discount": "Mno\u017estevn\u00ed sleva",
  "personal_discount": "Osobn\u00ed sleva",
  "ticket_status_all": "V\u0161echny",
  "id": "ID",
  "exchange_form_total_fee": "Poplatky celkem",
  "exchange_form_out_fee_crypto": "Poplatek za odchoz\u00ed transakci",
  "exchange_form_out_fee_fiat": "Poplatek za p\u0159\u00edchoz\u00ed transakci",
  "exchange_form_in_fee_crypto": "Poplatek s\u00edt\u011b za p\u0159\u00edchoz\u00ed transakci",
  "exchange_form_in_fee_fiat": "Poplatek za p\u0159\u00edchoz\u00ed p\u0159evod ",
  "support": "Podpora",
  "tickets": "Po\u017eadavky",
  "ticket": "Po\u017eadavek",
  "message": "Zpr\u00e1va",
  "upload_file": "Nahr\u00e1t soubor",
  "you": "Vy",
  "page_become_affiliate_partner_header": "Sta\u0148te se Simplecoin <0>affiliate</0> partnerem",
  "page_become_affiliate_partner_bonus": "Doporu\u010dujte na\u0161\u00ed sm\u011bn\u00e1rnu a z\u00edsk\u00e1vejte <strong> provizi <br> a\u017e {{affiliatePercent}}%</strong> z ka\u017ed\u00e9 dokon\u010den\u00e9 sm\u011bny.",
  "page_become_affiliate_partner_involved": "Jdu do toho",
  "page_become_affiliate_partner_information": "V\u00edce informac\u00ed",
  "page_affiliate_dashboard_header": "Affiliate program",
  "page_affiliate_dashboard_current_balance_header": "Aktu\u00e1ln\u00ed z\u016fstatek",
  "page_affiliate_dashboard_current_balance_withdraw": "Vybrat z\u016fstatek",
  "order_related_support": "Podpora pro objedn\u00e1vku",
  "page_affiliate_dashboard_total_profit": "Celkov\u00fd v\u00fdd\u011blek",
  "page_become_affiliate_partner_copy_link_header": "V\u00e1\u0161 affiliate odkaz",
  "page_become_affiliate_partner_bonus_start": "Za\u010d\u00e1tek spolupr\u00e1ce:",
  "page_become_affiliate_partner_bonus_tip": "<strong> Tip! </strong> Pou\u017eijte na\u0161e bannery - p\u0159ipravili jsme r\u016fzn\u00e9 velikosti a motivy.",
  "page_become_affiliate_partner_promotional": "Uk\u00e1zat propaga\u010dn\u00ed materi\u00e1ly",
  "document_issued_by_government": "Doklad vydan\u00fd st\u00e1tem",
  "document_back_side_scan": "Foto zadn\u00ed strany dokladu",
  "document_front_side_scan": "Foto p\u0159edn\u00ed strany dokladu",
  "document_type": "Typ dokladu",
  "scan_quality_warning": "Foto je pot\u0159eba dodat ve vysok\u00e9 kvalit\u011b, sken 300dpi nebo v\u00edce, \u010di kvalitn\u00ed fotografie, \u017e\u00e1dn\u00e1 \u010d\u00e1st nesm\u00ed b\u00fdt zakryta nebo zm\u011bn\u011bna.",
  " page_become_affiliate_partner_income_header": "P\u0159ehled p\u0159ijm\u016f",
  "page_become_affiliate_partner_withdrawal_header": "P\u0159ehled v\u00fdb\u011br\u016f",
  "page_become_affiliate_partner_statistics_header": "P\u0159ehled proklik\u016f",
  "secondary_document": "Dopl\u0148kov\u00fd doklad",
  "selfie_with_id": "Selfie s dokladem v ruce",
  "affiliate_withdraw_breadcrumb": "V\u00fdb\u011br",
  "affiliate_withdraw_header": "Vybrat z\u016fstatek z affiliate \u00fa\u010dtu",
  "affiliate_withdraw_info_alert": "V\u00e1\u0161 z\u016fstatek na affiliate \u00fa\u010dtu je <strong>{{balanceCurrencyEur}}</strong>. M\u016f\u017eete si ho vybrat ve kter\u00e9koliv z podporovan\u00fdch m\u011bn. P\u0159esnou \u010d\u00e1stku po konverzi uvid\u00edte po v\u00fdb\u011bru p\u0159\u00edjemce.",
  "bank_account_create_form_header": "P\u0159idat nov\u00fd \u00fa\u010det v {{currencyCode}}",
  "crypto_address_create_form_header": "P\u0159idat novou {{currencyCode}} pen\u011b\u017eenku",
  "settings_social_create_password_button": "Nastavit heslo",
  "recipient_page_table_name": "N\u00e1zev",
  "recipient_page_table_address": "Adresa / \u010c\u00edslo \u00fa\u010dtu",
  "recipient_page_table_currency": "M\u011bna",
  "recipient_page_table_header": "Seznam",
  "wallet_welcome_page": "Chyst\u00e1te se p\u0159ej\u00edt do <br/>\nSimplecoin Wallet",
  "wallet_welcome_page_condions": "Souhlas\u00edm s  <0>obchodn\u00edmi podm\u00ednkami</0> Simplecoin Wallet",
  "wallet_welcome_page_button": "Pokra\u010dovat do Simplecoin Wallet",
  "wallet_welcome_page_benefits_1": "Vytvo\u0159te si portfolio u Simplecoin",
  "wallet_welcome_page_benefits_2": "Snadn\u00fd vklad finan\u010dn\u00edch prost\u0159edk\u016f",
  "wallet_welcome_page_benefits_3": "Jednodu\u0161\u0161\u00ed a rychle obchodujte s kryptom\u011bnam",
  "wallet_welcome_page_benefits_4": "V\u00fdhodn\u011bj\u0161\u00ed poplatky za sm\u011bnu",
  "wallet_welcome_page_benefits_5": "Prioritn\u00ed podpora",
  "verification_transaction": "Ov\u011b\u0159ovac\u00ed transakce",
  "verification_call_info": "V r\u00e1mci verifikace si pot\u0159ebujeme ov\u011b\u0159it i Va\u0161e telefonn\u00ed \u010d\u00edslo a proto V\u00e1m zavol\u00e1me a potvrd\u00edme, \u017ee verifikace prob\u011bhla bez probl\u00e9m\u016f. M\u016f\u017eete si vybrat p\u0159ibli\u017enou dobu, kter\u00e1 V\u00e1m nejv\u00edce vyhovuje pro z\u00e1v\u011bre\u010dn\u00fd telefon\u00e1t.",
  "verification_call": "Ov\u011b\u0159ovac\u00ed telefon\u00e1t",
  "when_should_we_call": "Kdy V\u00e1m m\u00e1me zavolat",
  "asap": "Co nejd\u0159\u00edve",
  "email": "E-mail",
  "verification_and_limits": "Verifikace a limity",
  "profile_verification": "Verifikace profilu",
  "limits": "Limity",
  "start_with_verification": "Zah\u00e1jit verifikaci",
  "verification_in_progress": "Prob\u00edh\u00e1 ov\u011b\u0159en\u00ed",
  "level1": "\u00darove\u0148 1",
  "done": "Hotovo",
  "ok": "OK",
  "profile_verification_done_info": "Na e-mail jsme v\u00e1m odeslali informace k ov\u011b\u0159ovac\u00ed platb\u011b pro p\u0159\u00edpad, \u017ee byste se k nim cht\u011bli vr\u00e1tit. O dokon\u010den\u00ed verifikace v\u00e1s budeme informovat e-mailem.",
  "sidebar_navigation_portfolios": "Portfolio",
  "page_dashboard_insert_deposit": "Vlo\u017eit prost\u0159edky",
  "page_trade_head_title": "Investice",
  "transaction_tabs_title_exchange": "Sm\u011bna",
  "transaction_tabs_title_deposit": "Vklad",
  "transaction_tabs_title_withdraw": "V\u00fdb\u011br",
  "transaction_tabs_title_transfer": "P\u0159evod",
  "label_summary": "Shrnut\u00ed",
  "increase_limit": "Nav\u00fd\u0161it limit",
  "read_only": "Nelze m\u011bnit",
  "page_portfolio_header": "Portfolio",
  "amount": "\u010c\u00e1stka",
  "purpose_of_crypto_trading": "\u00da\u010del obchodov\u00e1n\u00ed s kryptom\u011bnami",
  "what_is_purpose_of_crypto_trading": "Za jak\u00fdm \u00fa\u010delem obchodujete s kryptom\u011bnami",
  "get_familiar_with_cryptocurrencies": "Sezn\u00e1men\u00ed se s kryptom\u011bnami",
  "occasional_buing_selling": "P\u0159\u00edle\u017eitostn\u00fd n\u00e1kup a prodej",
  "one_off_investment": "Jednor\u00e1zov\u00e1 investice",
  "regular_savings": "Pravideln\u00e9 spo\u0159en\u00ed",
  "other": "Jin\u00fd / Prodej kryptom\u011bny",
  "speculations": "Spekulace",
  "describe_your_purpose_of_cryptotrading": "Popi\u0161te, za jak\u00fdm \u00fa\u010delem obchodujete s kryptom\u011bnami",
  "today": "dnes",
  "morning": "dopoledne",
  "evening": "pozdn\u00ed odpoledne",
  "afternoon": "odpoledne",
  "tomorrow": "z\u00edtra",
  "proof_of_income": "Dolo\u017een\u00ed p\u0159\u00edjmu",
  "proof_of_income_info": "Z\u00e1kon proti legalizaci v\u00fdnos\u016f z trestn\u00e9 \u010dinnosti a financov\u00e1n\u00ed terorismu (tzv. AML z\u00e1kon) n\u00e1m ukl\u00e1d\u00e1, abychom prov\u011b\u0159ili zdroj Va\u0161ich p\u0159\u00edjm\u016f v p\u0159\u00edpad\u011b, \u017ee chcete obchodovat s vy\u0161\u0161\u00edmi \u010d\u00e1stkami. Mus\u00edme V\u00e1m tedy polo\u017eit n\u011bkolik ot\u00e1zek ohledn\u011b p\u016fvodu pen\u011bz, se kter\u00fdmi u n\u00e1s chcete obchodovat.",
  "income": "P\u0159\u00edjem",
  "one-off_income": "jednor\u00e1zov\u00fd p\u0159\u00edjem",
  "monthly_income": "m\u011bs\u00ed\u010dn\u00ed p\u0159\u00edjem",
  "quarterly_income": "\u010dtvrtletn\u00ed p\u0159\u00edjem",
  "yearly_income": "ro\u010dn\u00ed p\u0159\u00edjem",
  "frequency": "Periodicita",
  "source_of_income": "Zdroj p\u0159\u00edjmu",
  "employment": "Zam\u011bstn\u00e1n\u00ed",
  "position_in_company": "Pracovn\u00ed pozice",
  "company_name": "N\u00e1zev spole\u010dnosti",
  "running_own_business": "Vlastn\u00ed podnik\u00e1n\u00ed (OSV\u010c)",
  "rental": "Pron\u00e1jem",
  "share_of_profit": "Pod\u00edl na zisku z hospod\u00e1\u0159sk\u00e9 \u010dinnosti spole\u010dnosti",
  "pension": "D\u016fchod",
  "documents_supporting_the_income": "Dokumenty dokl\u00e1daj\u00edc\u00ed p\u0159\u00edjem",
  "income_source_description": "Popis",
  "add_income": "P\u0159idat p\u0159\u00edjem",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Prov\u011b\u0159en\u00ed z\u00e1kladn\u00edho p\u0159\u00edjmu",
  "proof_of_basic_income_info": "Abychom V\u00e1m mohli nastavit alespo\u0148 z\u00e1kladn\u00ed limit pro obchodov\u00e1n\u00ed a vklady, pot\u0159ebujeme v\u011bd\u011bt, zda m\u00e1te alespo\u0148 minim\u00e1ln\u00ed p\u0159\u00edjem. Nen\u00ed \u017e\u00e1dn\u00fd probl\u00e9m tento z\u00e1kladn\u00ed limit pozd\u011bji nav\u00fd\u0161it, pokud budete cht\u00edt obchodovat \"na pln\u00fd plyn\". Ani pokud takov\u00fd p\u0159\u00edjem nem\u00e1te, nen\u00ed nic ztraceno. Pozd\u011bji n\u00e1m pop\u00ed\u0161ete zdroj prost\u0159edk\u016f, se kter\u00fdmi chcete obchodovat a my V\u00e1m podle toho nastav\u00edme limit.",
  "step": "Krok",
  "basic_income_agreement": "Potvrzuji, \u017ee m\u016fj p\u0159ijem odpov\u00edd\u00e1 v p\u0159epo\u010dtu nejm\u00e9n\u011b 15.000 K\u010d m\u011bs\u00ed\u010dn\u011b",
  "semi-yearly_income": "pololetn\u00ed p\u0159\u00edjem",
  "incomes": "P\u0159\u00edjmy",
  "income_state_submited": "\u010dek\u00e1 na potvrzen\u00ed",
  "income_state_approved": "schv\u00e1len\u00fd",
  "income_state_new": "nov\u011b p\u0159idan\u00fd",
  "updating_incomes_info": "Ka\u017edou \u00fapravu Va\u0161ich dolo\u017een\u00fdch p\u0159\u00edjm\u016f (p\u0159id\u00e1n\u00ed nov\u00e9ho nebo smaz\u00e1n\u00ed existuj\u00edc\u00edho) mus\u00edme posoudit a dle toho odpov\u00eddaj\u00edc\u00edm zp\u016fsobem uprav\u00edme tak\u00e9 V\u00e1\u0161 obchodn\u00ed limit.",
  "submit_for_approval": "Odeslat ke schv\u00e1len\u00ed",
  "income_state_unapproved_change": "neschv\u00e1len\u00e1 zm\u011bna",
  "prooved_incomes": "Dolo\u017een\u00e9 p\u0159\u00edjmy",
  "verification_and_incomes": "Verifikace a p\u0159\u00edjmy",
  "trading_limits": "Limity pro obchodov\u00e1n\u00ed",
  "current_allowed_trading_amount": "Povolen\u00e1 \u010d\u00e1stka pro obchodov\u00e1n\u00ed",
  "current_allowed_trading_amount_info": "Toto je maxim\u00e1ln\u00ed \u010d\u00e1stka, kterou m\u016f\u017eete pr\u00e1v\u011b te\u010f vlo\u017eit do Simplecoin Pen\u011b\u017eenky nebo sm\u011bnit v Simplecoin Sm\u011bn\u00e1rn\u011b. Tato \u010d\u00e1stka se v \u010dase m\u011bn\u00ed podle toho, jak \u010dasto obchodujete. M\u016f\u017ee se V\u00e1m postupn\u011b sni\u017eovat a\u017e na nulu nebo naopak nar\u016fst zp\u011bt a\u017e na v\u00fd\u0161i Va\u0161eho p\u016fvodn\u00edho limitu. Do limitu se zapo\u010d\u00edt\u00e1vaj\u00ed jen zaplacen\u00e9 objedn\u00e1vky a potvrzen\u00e9 vklady.",
  "individual_limits": "Jednotliv\u00e9 limity",
  "time_period": "\u010casov\u00e9 obdob\u00ed",
  "limit_extension": "Roz\u0161i\u0159uj\u00edc\u00ed limit",
  "remaining_limit_amount": "Zb\u00fdvaj\u00edc\u00ed \u010d\u00e1stka, kterou lze je\u0161t\u011b \u010derpat",
  "state_of_limit_execution": "Stav \u010derp\u00e1n\u00ed limitu",
  "in_time_period": "za",
  "individual_trading_limits_info": "Pokud m\u00e1te v\u00edce jednotliv\u00fdch limit\u016f, mus\u00edte vyhov\u011bt v\u0161em. Aplikuje se v\u017edy ten, kter\u00fd je aktu\u00e1ln\u011b nejv\u00edce restriktivn\u00ed.",
  "trading_limit_extension_info": "Pokud V\u00e1m nesta\u010d\u00ed standardn\u00ed limit, pak V\u00e1m m\u016f\u017eeme limit je\u0161t\u011b nav\u00fd\u0161it na z\u00e1klad\u011b dolo\u017een\u00ed jednor\u00e1zov\u00e9ho p\u0159\u00edjmu. Tento nav\u00fd\u0161en\u00fd limit budeme postupn\u011b sni\u017eovat v\u017edy, kdy\u017e budete obchodovat nad standardn\u00ed limit.",
  "income_state_deleted": "smazan\u00fd",
  "account_update_success": "\u00da\u010det byl zm\u011bn\u011bn",
  "account_label_edit": "Upravit \u00fa\u010det",
  "account_action_archive": "Archivovat \u00fa\u010det",
  "waiting_for_payment": "\u010dek\u00e1 se na p\u0159ijet\u00ed platby",
  "waiting_for_payment_approval": "\u010dek\u00e1 se na ov\u011b\u0159en\u00ed platby",
  "verification_transaction_info": "Pro \u00fasp\u011b\u0161nou identifikaci na d\u00e1lku bude pot\u0159eba prov\u00e9st ov\u011b\u0159en\u00ed alespo\u0148 jednoho va\u0161eho bankovn\u00edho \u00fa\u010dtu (veden\u00e9ho na Va\u0161e jm\u00e9no). K tomu n\u00e1m sta\u010d\u00ed obdr\u017eet i jedinou korunu z tohoto \u00fa\u010dtu.",
  "verification_transaction_received_info": "Ov\u011b\u0159ovac\u00ed transakce byla p\u0159ijata. U\u017e si jenom ov\u011b\u0159\u00edme, \u017ee p\u0159i\u0161la z va\u0161eho bankovn\u00edho \u00fa\u010dtu.",
  "verification_transaction_approved_info": "V\u00e1\u0161 bankovn\u00ed \u00fa\u010det byl ov\u011b\u0159en. Tento krok m\u016f\u017eeme tedy p\u0159esko\u010dit.",
  "export_to_csv": "Export do CSV",
  "new_account_button": "Vytvo\u0159it \u00fa\u010det",
  "remove_recipient": "Odstranit p\u0159\u00edjemce",
  "recipient_detail": "Detail p\u0159\u00edjemce",
  "account_label": "N\u00e1zev \u00fa\u010dtu",
  "veriffied": "ov\u011b\u0159en\u00fd",
  "not_verified": "neov\u011b\u0159en\u00fd",
  "label_create_trade": "Sm\u011bnit",
  "remove_income": "Odebrat p\u0159\u00edjem",
  "transaction_detail_header": "Detail transakce",
  "transaction_type": "Typ",
  "trade": "Obchodovat",
  "withdraw_label_about": "Chyst\u00e1te se prov\u00e9st v\u00fdb\u011br",
  "withdraw_summary_fees": "Shrnut\u00ed a poplatky",
  "Choose-destination-bank-account": "Zvolte c\u00edlov\u00fd \u00fa\u010det",
  "wallet_account_destination": "Zvolte c\u00edlovou {{currencyName}} adresu",
  "account_unique_name_label": "Zadejte unik\u00e1tn\u00ed jmenovku \u00fa\u010dtu",
  "account_note_label": "Zde m\u016f\u017eete p\u0159idat pozn\u00e1mku",
  "account_detail_suspend_alert": "V\u00e1\u0161 \u00fa\u010det byl pozastaven. Pros\u00edme kontaktujte podporu.",
  "current_balance_label": "Z\u016fstatek",
  "balance_blocked_label": "Blokovan\u00fd z\u016fstatek",
  "label_not_choosen_account": "Nejd\u0159\u00edve vyberte \u00fa\u010det",
  "label_not_enough_money": "M\u00e1te nedostate\u010dn\u00fd z\u016fstatek",
  "label_set_all_funds": "Vybrat v\u0161e",
  "label_available_balance": "Disponibiln\u00ed z\u016fstatek",
  "label_withdraw": "V\u00fdb\u011br",
  "account_label_description": "Popis \u00fa\u010dtu",
  "label_balance_after_transaction": "Z\u016fstatek po transakci",
  "label_fee": "Poplatek",
  "label_counter_party": "Protistrana",
  "label_operation_confirm_withdraw": "Potvrzen\u00edm vyberete n\u00e1sleduj\u00edc\u00ed \u010d\u00e1stku",
  "label_operation_confirm_transfer": "Potvrzen\u00edm provedete n\u00e1sleduj\u00edc\u00ed p\u0159evod",
  "label_operation_confirm_exchange": "Potvrzen\u00edm provedete n\u00e1sleduj\u00edc\u00ed sm\u011bnu",
  "label_no_account_this_currency": "V t\u00e9to m\u011bn\u011b je\u0161t\u011b nem\u00e1te \u00fa\u010det",
  "transaction_info_acc_head": "\u010c\u00e1stka a m\u011bna",
  "label_from_account": "Zdrojov\u00fd \u00fa\u010det",
  "label_target_account": "C\u00edlov\u00fd \u00fa\u010det",
  "exchanges_balances_alert_fiat": "Tuto sm\u011bnu je\u0161t\u011b neumo\u017e\u0148ujeme",
  "account_create_success": "\u00da\u010det byl vytvo\u0159en",
  "withdraw_label_requests": "\u017d\u00e1dosti o v\u00fdb\u011br",
  "operations_label_dashboard_header": "Operace",
  "label_currency_general": "Z\u00e1kladn\u00ed m\u011bna",
  "income_state_draft": "neov\u011b\u0159en\u00fd",
  "label_client_currency_balance": "Z\u016fstatek v z\u00e1kladn\u00ed m\u011bn\u011b",
  "label_show_all": "Zobrazit v\u0161e",
  "dashboard_balances_last_transactions": "Posledn\u00ed transakce",
  "dashboard_balances_no_accounts": "Nem\u00e1te \u017e\u00e1dn\u00fd \u00fa\u010det",
  "dashboard_balances_no_withdrawal_requests": "\u017d\u00e1dn\u00e9 \u017e\u00e1dosti o v\u00fdb\u011br",
  "dashboard_balances_no_transaction": "Nem\u00e1te \u017e\u00e1dn\u00e9 transakce",
  "general_settings_label_base_currency": "Tato m\u011bna se bude zobrazovat v p\u0159ehledech a jako v\u00fdchoz\u00ed na cel\u00e9m webu.",
  "general_settings_header_base_currency": "Z\u00e1kladn\u00ed m\u011bna",
  "page_portfolio_closed_suspend_accounts_header": "Pozastaven\u00e9 a zru\u0161en\u00e9 \u00fa\u010dty",
  "confirm_withdraw_button": "Potvrdit v\u00fdb\u011br",
  "button_withdraw_continue_to_confirmation": "Pokra\u010dovat",
  "recipient_name": "N\u00e1zev p\u0159\u00edjemce",
  "button_create_transfer": "Potvrdit p\u0159evod",
  "button_transfer_continue_to_confirmation": "Pokra\u010dovat",
  "button_exchange_continue_to_confirmation": "Pokra\u010dovat",
  "total_balance": "Celkov\u00fd z\u016fstatek",
  "no_trading_limit_extension": "Nem\u00e1te \u017e\u00e1dn\u00fd roz\u0161i\u0159uj\u00edc\u00ed limit.",
  "deposit_component_automatic_create_acc_label": "V t\u00e9to m\u011bn\u011b je\u0161t\u011b u n\u00e1s nem\u00e1te \u00fa\u010det. Zalo\u017e\u00edme v\u00e1m ho automaticky.",
  "deposit": "Vlo\u017eit",
  "withdraw": "Vybrat",
  "create_default_account_label": "Standardn\u00ed \u00fa\u010det pro",
  "create_default_account_description": "Automaticky vytvo\u0159en\u00fd \u00fa\u010det p\u0159i vkladu prost\u0159edk\u016f",
  "create_default_account_button": "Vytvo\u0159it v\u00fdchoz\u00ed {{currencyCode}} u\u010det",
  "dashboard_blog_posts_header": "Posledn\u00ed p\u0159\u00edsp\u011bvky z blogu",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "P\u0159ipsan\u00e1 \u010d\u00e1stka",
  "exchange_rate": "Kurz",
  "accounting_time": "\u010cas za\u00fa\u010dtov\u00e1n\u00ed",
  "date": "Datum",
  "amounts": "\u010c\u00e1stky",
  "success_order_header": "Co d\u00e1l? Pro dokon\u010den\u00ed uhra\u010fte objedn\u00e1vku.",
  "success_order_email": "Platebn\u00ed \u00fadaje jsme V\u00e1m zaslali na e-mail <0>{{email}}</0>",
  "finop_type_DEPOSIT": "vklad",
  "finop_type_EXCHANGE": "sm\u011bna",
  "finop_type_TRANSFER": "p\u0159evod",
  "finop_type_WITHDRAWAL": "v\u00fdb\u011br",
  "exchange_made": "Sm\u011bna byla provedena",
  "from_account": "Z \u00fa\u010dtu",
  "to_account": "Na \u00fa\u010det",
  "accounts": "\u00da\u010dty",
  "portfolio_balance_summary_fiat_header": "Fiat:",
  "portfolio_balance_summary_crypto_header": "Kryptom\u011bny:",
  "order_success_check_crypto": "Zkontrolujte, pros\u00edm, zda je adresa va\u0161\u00ed pen\u011b\u017eenky spr\u00e1vn\u00e1.",
  "success_order_label_id": "\u010c\u00edslo va\u0161\u00ed objedn\u00e1vky je <0>{{order_id}}</0>. D\u011bkujeme!",
  "exchange_form_crypto_address_field_label": "Adresa kryptopen\u011b\u017eenky",
  "order_success_check_bank": "Zkontrolujte, zda je bankovn\u00ed \u00fa\u010det spr\u00e1vn\u00fd.",
  "other_currencies": "Dal\u0161\u00ed m\u011bny",
  "no_funds_in_portfolio": "Ve Va\u0161em portfoliu nem\u00e1te nyn\u00ed \u017e\u00e1dn\u00e9 prost\u0159edky. Vlo\u017ete na n\u011bkter\u00fd z Va\u0161ich \u00fa\u010dt\u016f fiat nebo krypto prost\u0159edky a m\u016f\u017eete za\u010d\u00edt \u010dile obchodovat.",
  "exchange_form_register": "pro vy\u0161\u0161\u00ed limity",
  "list": "Seznam",
  "withdrawal_request_status_new": "\u010cek\u00e1 na vy\u0159\u00edzen\u00ed",
  "withdrawal_request_status_confirming_outgoing": "Odbavena",
  "withdrawal_request_status_done": "Vy\u0159\u00edzen",
  "withdrawal_request_status_canceled": "Zru\u0161ena",
  "landing_main_header": "<0>Nejv\u011bt\u0161\u00ed</0> sm\u011bn\u00e1rna kryptom\u011bn v \u010cR",
  "next_landing_subheader": "M\u011bn\u00edme kryptom\u011bny u\u017e <0>od roku 2013</0>.",
  "header_link_about_us": "O n\u00e1s",
  "header_link_get_started": "Jak za\u010d\u00edt",
  "header_link_blog": "Blog",
  "header_service_hours": "Po-Ne 9-21",
  "buy_now_button": "Nakoupit",
  "chart_header": "N\u00e1kup a prodej",
  "exchange_form_label": "N\u00e1kup a prodej",
  "how_it_works_paragraph_1_content": "Objedn\u00e1vku zvl\u00e1dnete ud\u011blat t\u0159eba u\u017e za minutu. Do 200 \u20ac / 10 dn\u00ed n\u00e1m bude sta\u010dit pouze v\u00e1\u0161 e-mail a adresa kryptom\u011bnov\u00e9 pen\u011b\u017eenky. Od vy\u0161\u0161\u00edch \u010d\u00e1stek ji\u017e budete pot\u0159ebovat registraci a verifikaci.",
  "how_it_works_paragraph_1_heading": "Objedn\u00e1te",
  "how_it_works_paragraph_2_heading": "Zaplat\u00edte",
  "how_it_works_paragraph_3_heading": "Obdr\u017e\u00edte kryptom\u011bny",
  "how_it_works_paragraph_2_content": "Po dokon\u010den\u00ed objedn\u00e1vky v\u00e1m poskytneme pokyny, jak n\u00e1m poslat pen\u00edze na \u00fa\u010det (nebo kryptom\u011bny do pen\u011b\u017eenky) a vy objedn\u00e1vku zaplat\u00edte.",
  "how_it_works_paragraph_3_content": "Po obdr\u017een\u00ed va\u0161\u00ed platby ode\u0161leme kryptom\u011bny do va\u0161\u00ed pen\u011b\u017eenky. V\u011bt\u0161inou to stihneme u\u017e do 30 minut!",
  "landing_support_section_paragraph": "Pot\u0159ebujete poradit se sm\u011bnou kryptom\u011bn nebo se spr\u00e1vou hardwarov\u00e9 pen\u011b\u017eenky Trezor? Kontaktujte n\u00e1s prost\u0159ednictv\u00edm e-mailu, telefonicky nebo p\u0159ij\u010fte osobn\u011b do na\u0161eho klientsk\u00e9ho centra. R\u00e1di V\u00e1m pom\u016f\u017eeme.",
  "landing_support_section_heading": "Skute\u010dn\u00ed lid\u00e9, skute\u010dn\u00e1 podpora.",
  "contact_us_button": "Kontaktovat",
  "landing_advantages_1_heading": "Jednoduch\u00fd a bezpe\u010dn\u00fd n\u00e1kup kryptom\u011bn",
  "landing_advantages_1_text": "Objedn\u00e1vku ud\u011bl\u00e1te u\u017e za 1 minutu. A po p\u0159ips\u00e1n\u00ed prost\u0159edk\u016f na n\u00e1\u0161 \u00fa\u010det transakci ru\u010dn\u011b ov\u011b\u0159\u00edme a kryptom\u011bny v\u00e1m po\u0161leme pr\u016fm\u011brn\u011b do 30 minut.",
  "landing_advantages_2_heading": "Skute\u010dn\u00ed lid\u00e9, skute\u010dn\u00e1 podpora",
  "landing_advantages_3_heading": "Nejstar\u0161\u00ed a nejv\u011bt\u0161\u00ed sm\u011bn\u00e1rna v \u010cR",
  "landing_advantages_2_text": "Pot\u0159ebujete poradit se sm\u011bnou kryptom\u011bn? M\u016f\u017eete n\u00e1m kdykoliv napsat, zavolat nebo n\u00e1m poslat ticket. V\u011bt\u0161inu po\u017eadavk\u016f \u0159e\u0161\u00edme obratem.",
  "landing_advantages_3_text": "M\u011bn\u00edme kryptom\u011bny u\u017e od roku 2013. Sm\u011bnili jsme kryptom\u011bny v\u00edce ne\u017e 21 tis\u00edc lidem v celkov\u00e9 hodnot\u011b p\u0159es 8 miliard K\u010d. Jsme jedni ze zakl\u00e1daj\u00edc\u00edch \u010dlen\u016f <0>\u010cKMA</0>.",
  "ckma_founder": "Zakladaj\u00edc\u00ed \u010dlen",
  "newsletter_field_placeholder": "V\u00e1\u0161 e-mail",
  "footer_sub_header_newsletter_subscription": "Novinky ze sv\u011bta kryptom\u011bn",
  "footer_link_career": "Kari\u00e9ra",
  "footer_link_affiliate_program": "Affiliate program",
  "footer_link_privacy_notice": "Zpracov\u00e1n\u00ed osobn\u00edch \u00fadaj\u016f",
  "footer_link_terms_and_conditions": "Obchodn\u00ed podm\u00ednky",
  "footer_link_contact": "Kontakt",
  "footer_link_limits_and_verification": "Limity a verifikace",
  "footer_link_faq": "\u010cast\u00e9 dotazy",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "Od roku 2013 jsme sm\u011bnili kryptom\u011bny v hodnot\u011b p\u0159es 8 miliard \u010desk\u00fdch korun.",
  "landing_bottom_section_header_trade_easily": "Obchodujte s kryptom\u011bnami jednodu\u0161e",
  "landing_bottom_section_button_buy_coins": "Koupit kryptom\u011bny",
  "how_it_works_header": "Jak to funguje?",
  "landing_wrote_about_us_header": "Napsali o n\u00e1s",
  "landing_last_orders_header": "Aktu\u00e1ln\u00ed n\u00e1kupy na\u0161ich klient\u016f",
  "header_faq": "\u010cast\u00e9 dotazy",
  "faq_general_questions_header": "O Simplecoin obecn\u011b",
  "log_in_button": "P\u0159ihl\u00e1sit se",
  "header_button_buy_crypto": "Koupit kryptom\u011bny",
  "header_short_button_buy": "Nakoupit",
  "search_button": "Hledat",
  "header_support": "N\u00e1pov\u011bda",
  "send_button": "Odeslat",
  "email_subscribe_header": "P\u0159ihlaste se k odb\u011bru novinek ze sv\u011bta krptom\u011bn",
  "affiliate_advantage_payout_anytime": "V\u00fdb\u011br odm\u011bny mo\u017en\u00fd kdykoliv",
  "affiliate_advantage_banner": "Bannery na web nebo blog",
  "affiliate_advantage_referral_link": "Vlastn\u00ed referral odkaz",
  "affiliate_advantage_fiat_or_crypto": "Vypl\u00e1cen\u00ed ve fiat nebo kryptu",
  "affiliate_advantage_cookie": "Cookies na {{affiliateCookieValidityDays}} dn\u00ed",
  "affiliate_advantage_reward_from_margin": "Odm\u011bna {{affiliateMarginReward}}% z\u00a0mar\u017ee sm\u011bny",
  "affiliate_header": "Affiliate program",
  "affiliate_subheader": "Sta\u0148te se partnerem Simplecoin, doporu\u010dujte na\u0161i sm\u011bn\u00e1rnu lidem a z\u00edskejte provizi z ka\u017ed\u00e9 sm\u011bny, kterou ud\u011blaj\u00ed.",
  "header_earn_with_us": "Vyd\u011bl\u00e1vejte s firmou {{brand}}",
  "create_account_button": "Registrace",
  "affiliate_how_it_works_header": "Jak to funguje?",
  "affiliate_how_it_works_step_1_header": "Zalo\u017ete si \u00fa\u010det u Simplecoinu a p\u0159ihlaste se do affiliate programu",
  "affiliate_how_it_works_step_2_header": "Nab\u00edz\u00edme v\u00e1m speci\u00e1ln\u00ed odkaz nebo banner, kter\u00fd budete moci \u0161\u00ed\u0159it jak uzn\u00e1te za vhodn\u00e9",
  "affiliate_how_it_works_step_3_text": "Ka\u017ed\u00e1 osoba, kter\u00e1 p\u0159ijde na str\u00e1nku Simplecoin prost\u0159ednictv\u00edm va\u0161eho odkazu, dostane soubor cookie. Pokud vytvo\u0159\u00ed objedn\u00e1vku do 7 dn\u00ed, z\u00edsk\u00e1te z n\u00ed zisk ve v\u00fd\u0161i {{affiliateMarginReward}}% z na\u0161\u00ed mar\u017ee. Tyto v\u00fdnosy z\u00edsk\u00e1te za ka\u017edou objedn\u00e1vku, kterou u n\u00e1s zobchoduje. U objedn\u00e1vek, vytvo\u0159en\u00fdch po limitu 7 dn\u00ed, odkaz ji\u017e nen\u00ed aktivn\u00ed.",
  "affiliate_how_it_works_step_2_text": "K dispozici m\u00e1te URL adresu a bannery, kter\u00e9 m\u016f\u017eete sd\u00edlet na sv\u00e9m webu, blogu, soci\u00e1ln\u00edch s\u00edt\u00edch nebo se sv\u00fdmi p\u0159\u00e1teli. Kdokoli, kdo tento odkaz pou\u017eije a pot\u00e9 se do 7 dn\u00ed registruje nebo vytvo\u0159\u00ed objedn\u00e1vku, bude za\u0159azen do va\u0161eho partnersk\u00e9ho programu na dva roky.",
  "affiliate_how_it_works_step_1_text": "Pro vstup do affiliate programu je nezbytn\u00e1 registrace i verifikace. Registrace umo\u017en\u00ed p\u0159\u00edstup do administrace a spr\u00e1vu va\u0161eho \u00fa\u010dtu. Verifikaci pot\u0159ebujeme pro vypl\u00e1cen\u00ed odm\u011bn. \u0158\u00edd\u00edme se dobr\u00fdmi mravy a nepodporujeme nekal\u00e9 praktiky ani jedn\u00e1n\u00ed. Tot\u00e9\u017e o\u010dek\u00e1v\u00e1me od na\u0161ich partner\u016f.",
  "affiliate_how_it_works_step_4_text": "V administraci sv\u00e9ho affiliate programu si m\u016f\u017eete kdykoliv prohl\u00e9dnout, kolik jste ji\u017e vyd\u011blali. A\u017e budete m\u00edt chu\u0165, \u010d\u00e1stku si vyberete v kryptu nebo fiat m\u011bn\u011b. Platbu v\u00e1m ode\u0161leme na v\u00e1\u0161 bankovn\u00ed \u00fa\u010det nebo kryptopen\u011b\u017eenku.",
  "affiliate_how_it_works_step_3_header": "Z objedn\u00e1vek v\u00e1mi doveden\u00fdch klient\u016f z\u00edsk\u00e1v\u00e1te {{affiliateMarginReward}}% z na\u0161\u00ed mar\u017ee",
  "affiliate_how_it_works_step_4_header": "V\u00fdd\u011blek si vyberete, kdykoliv uzn\u00e1te za vhodn\u00e9",
  "limits_and_verification_header": "Limity a verifikace",
  "limits_and_verification_subheader": "Ze z\u00e1konn\u00fdch d\u016fvod\u016f mus\u00edme, stejn\u011b jako v\u0161echny ostatn\u00ed sm\u011bn\u00e1rny, <0>verifikovat ka\u017edou transakci nad ur\u010dit\u00fd limit.</0> V p\u0159\u00edpad\u011b, \u017ee se v\u00e1s to bude t\u00fdkat, m\u016f\u017eete se snadno zaregistrovat a nechat sv\u016fj \u00fa\u010det jednodu\u0161e verifikovat. Informace o limitech a verifikaci jsou v boxech n\u00ed\u017ee.",
  "limits_table_level_0_header": "do {{limitLevel0Eur}} \u20ac",
  "limits_table_level_0_subheader": "za {{limitLevel0DurationDays}} dn\u00ed",
  "limits_table_registration": "Povinn\u00e1 registrace a identifikace",
  "limits_table_row_2_documents_photo": "Fotografie 2 osobn\u00edch doklad\u016f",
  "limits_table_row_selfie": "Selfie s dokladem",
  "limits_table_row_verification_bank_transfer": "Ov\u011b\u0159ovac\u00ed platba",
  "limits_table_row_verification_phone_call": "Ov\u011b\u0159ovac\u00ed telefon\u00e1t",
  "limits_table_row_purpose_od_crypto_purchase": "\u00da\u010del transakce",
  "limits_table_row_source_of_income": "Dolo\u017een\u00ed p\u016fvodu p\u0159\u00edjmu",
  "limits_table_row_individual_limits_base_on_income_source": "Individu\u00e1ln\u00ed limity na z\u00e1klad\u011b zdroj\u016f p\u0159\u00edjm\u016f",
  "limits_table_level_1_header": "{{limitLevel0Eur}} \u2014 {{limitLevel1Eur}} \u20ac",
  "limits_table_level_1_subheader": "za {{limitLevel1DurationDays}} dn\u00ed",
  "limits_table_level_2_subheader": "za {{limitLevel2DurationDays}} dn\u00ed",
  "limits_table_level_2_header": "p\u0159es {{limitLevel2EurStart}} \u20ac ",
  "footer_mobile_navigation_expand_label": "Navigace na webu",
  "support_search_placeholder": "Co v\u00e1s zaj\u00edm\u00e1?",
  "error_404_header": "Tuto str\u00e1nku nem\u00e1me",
  "error_404_subheader": "Ale m\u016f\u017eete pokra\u010dovat na...",
  "error_page_contact_us": "Pokud si mysl\u00edte, \u017ee je to chyba, tak n\u00e1s pros\u00edm kontaktujte pomoc\u00ed emailu na {{supportEmail}}. D\u011bkujeme!",
  "link_blog_label": "Co je nov\u00e9ho na Simplecoinu",
  "link_contact_label": "Kontaktujte n\u00e1s",
  "link_faq_label": "Ot\u00e1zky a odpov\u011bdi",
  "about_page_recommendations_section_header": "Doporu\u010duj\u00ed n\u00e1s",
  "cookie_dialog_text_content": "{{brand}} pou\u017e\u00edv\u00e1 soubory cookie, aby bylo pou\u017e\u00edv\u00e1n\u00ed na\u0161ich slu\u017eeb pohodln\u011bj\u0161\u00ed a aby se u\u017eivatelsk\u00fd komfort neust\u00e1le zlep\u0161oval. N\u00ed\u017ee si m\u016f\u017eete vybrat, kter\u00e9 typy soubor\u016f cookie budou pou\u017eity. Pro v\u00edce informac\u00ed si p\u0159e\u010dt\u011bte na\u0161e <0>z\u00e1sady pou\u017e\u00edv\u00e1n\u00ed soubor\u016f cookie</0>",
  "cookie_dialog_necessary_cookies_header": "Nezbytn\u00e9 cookies",
  "cookie_dialog_necessary_cookies_text": "Soubory cookie, kter\u00e9 jsou pot\u0159ebn\u00e9 pro fungov\u00e1n\u00ed webov\u00fdch str\u00e1nek.",
  "cookie_dialog_necessary_cookies_always_active": "V\u017edy aktivn\u00ed",
  "cookie_dialog_statistical_cookies_header": "Statistick\u00e9 cookies",
  "cookie_dialog_statistical_cookies_text": "Soubory cookie, kter\u00e9 n\u00e1m pom\u00e1haj\u00ed m\u011b\u0159it a pochopit, jak na\u0161e slu\u017eby pou\u017e\u00edv\u00e1te.",
  "cookie_dialog_marketing_cookies_header": "Marketingov\u00e9 cookies",
  "cookie_dialog_marketing_cookies_text": "Cookies that help us in some way",
  "cookie_dialog_personalization_cookies_header": "Personalizovan\u00fd obsah",
  "cookie_dialog_personalization_cookies_text": "Cookies thanks to which you will not need to set some things twice",
  "cookie_dialog_accept_all_button": "Povolit v\u0161echny",
  "cookie_dialog_confirm_choices_button": "Potvrdit moje volby",
  "cookie_dialog_header": "Nastaven\u00ed preferenc\u00ed cookies",
  "support_label_breadcrumbs_navigation": "N\u00e1pov\u011bda",
  "buy_currency_button": "Koupit {{currencyName}}",
  "exchange_form_get_wallet_help": "Je\u0161t\u011b nem\u00e1te pen\u011b\u017eenku?",
  "scan_address_qr_code": "Na\u010d\u00edst z QR k\u00f3du",
  "exchange_form_crypto_address_field_placeholder": "bc1..",
  "order_form_email_and_account_step_label": "Detaily objedn\u00e1vky",
  "order_form_amounts_step_label": "\u010c\u00e1stka a m\u011bna",
  "order_form_header": "Objedn\u00e1vka",
  "landing_login_dialog_title": "Introducing Simplecoin Pro. A new platform with lower fees and instant currency exchanges. No need for a crypto wallet.\n",
  "landing_login_dialog_pro_header": "Try Simplecoin Pro",
  "landing_login_dialog_exchange_header": "Go to Simplecoin Exchange",
  "landing_login_dialog_exchange_text": "Simple crypto exchange service you know and love",
  "landing_login_dialog_pro_text": "Try Pro for even better trading experience",
  "landing_login_dialog_pro_login_button": "Log in to Pro",
  "landing_login_dialog_exchange_login_button": "Log in to Exchange",
  "about_us_section_small_header": "O n\u00e1s",
  "about_us_section_1_header": "D\u011bl\u00e1me sv\u011bt n\u00e1kupu \u010di prodeje <0>Bitcoinu</0> a dal\u0161\u00edch digit\u00e1ln\u00edch m\u011bn <0>jednodu\u0161\u0161\u00ed</0>.",
  "about_us_section_1_subheader": "Sv\u011bt kryptom\u011bn ma\u017ee hranice a p\u0159in\u00e1\u0161\u00ed v\u00e1m i n\u00e1m skute\u010dnou svobodu a nov\u00fd pohled na finance.",
  "about_us_section_2_header": "Jsme experti na kryptom\u011bny",
  "about_us_section_2_subheader": "Vrac\u00edme pen\u00edze do rukou oby\u010dejn\u00fdch lid\u00ed",
  "about_us_section_2_content": "Simplecoin je p\u0159edev\u0161\u00edm technologick\u00e1 firma, kter\u00e1 sv\u00e9 zku\u0161enosti op\u00edr\u00e1 o expertn\u00ed znalosti na\u0161ich program\u00e1tor\u016f. Investujeme \u010das a pozornost pr\u00e1v\u011b do vysok\u00e9 standardu bezpe\u010dnosti va\u0161ich klientsk\u00fdch \u00fa\u010dt\u016f a dat.",
  "about_us_section_3_header": "Objevujete s n\u00e1mi sv\u011bt digit\u00e1ln\u00edch m\u011bn ji\u017e od <0>roku 2013</0>",
  "about_us_section_3_text": "Od samotn\u00e9ho za\u010d\u00e1tku jsme ryze \u010deskou spole\u010dnost\u00ed s jasnou vlastnickou strukturou. Obchodovat s kryptom\u011bnami m\u016f\u017ee \u00fapln\u011b ka\u017ed\u00fd. P\u0159in\u00e1\u0161\u00edme dal\u0161\u00ed mo\u017enost pr\u00e1ce s financemi, kter\u00e9 ne\u0159\u00edd\u00ed banky a st\u00e1t. P\u0159in\u00e1\u0161\u00edme v\u00e1m mo\u017enosti, kter\u00e9 si \u0159\u00edd\u00edte vy sami. Ukazujeme lidem, \u017ee sv\u011bt kryptom\u011bn je jednoduch\u00fd.",
  "about_us_section_4_header": "Modern\u00ed technologie a zabezpe\u010den\u00ed",
  "about_us_section_4_subheader": "Db\u00e1me o vysokou bezpe\u010dnost u\u017eivatelsk\u00fdch \u00fa\u010dt\u016f",
  "about_us_section_4_text": "Simplecoin je p\u0159edev\u0161\u00edm technologick\u00e1 firma. Sv\u00e9 zku\u0161enosti op\u00edr\u00e1me o expertn\u00ed znalosti na\u0161ich program\u00e1tor\u016f. Investujeme \u010das a pozornost do vysok\u00e9 \u00farovn\u011b bezpe\u010dnosti \u00fa\u010dt\u016f a dat na\u0161ich klient\u016f.",
  "contact_header": "Ozv\u011bte se n\u00e1m",
  "contact_page_email_label": "E-mail",
  "contact_page_phone_label": "Telefon",
  "contact_page_customer_center": "Klientsk\u00e9 centrum",
  "contact_page_billing_info": "Faktura\u010dn\u00ed \u00fadaje",
  "support_search_result_count": "{{resultsCount}} v\u00fdsledk\u016f",
  "limits_table_item_not_needed": "Ne",
  "limits_table_item_required": "Vy\u017eadov\u00e1no",
  "how_to_get_started_step_1_name": "Krok 1",
  "how_to_get_started_step_1_header": "Pot\u0159ebujete kryptopen\u011b\u017eenku",
  "how_to_get_started_step_1_cta": "Jak zalo\u017eit kryptopen\u011b\u017eenku",
  "how_to_get_started_step_1_text": "Pen\u011b\u017eenka slou\u017e\u00ed k uchov\u00e1v\u00e1n\u00ed kryptom\u011bn. P\u0159i n\u00e1kupu j\u00ed budete pot\u0159ebovat. Pokud pen\u011b\u017eenku nem\u00e1te, m\u016f\u017eete si j\u00ed st\u00e1hnout t\u0159eba jako aplikaci do sv\u00e9ho mobilu nebo po\u010d\u00edta\u010de. Porad\u00edme v\u00e1m, jak na to. ",
  "how_to_get_started_step_2_name": "Krok 2",
  "how_to_get_started_step_2_header": "Zaregistrujte se",
  "how_to_get_started_step_2_subheader": "M\u00e1 to sv\u00e9 v\u00fdhody",
  "how_to_get_started_step_2_text": "Budete pot\u0159ebovat e-mailovou adresu a bezpe\u010dn\u00e9 heslo. Objedn\u00e1vku m\u016f\u017eete ud\u011blat i bez registrace, ale p\u0159ijdete tak o objemovou slevu a mo\u017enost spravovat objedn\u00e1vku ve sv\u00e9m u\u017eivatelsk\u00e9m \u00fa\u010dtu.",
  "how_to_get_started_step_3_name": "Krok 3",
  "how_to_get_started_step_3_header": "Vytvo\u0159te objedn\u00e1vku",
  "how_to_get_started_step_3_subheader": "Te\u010f u\u017e to zabere jen minutu",
  "how_to_get_started_step_3_text": "Vypl\u0148te objedn\u00e1vkov\u00fd formul\u00e1\u0159 - v\u0161e pot\u0159ebn\u00e9 u\u017e m\u00e1te (mailovou adresu a adresu kryptopen\u011b\u017eenky).  Na e-mail v\u00e1m za\u0161leme pokyny k platb\u011b. Jakmile ov\u011b\u0159\u00edme p\u0159ips\u00e1n\u00ed prost\u0159edk\u016f, za\u0161leme v\u00e1m po\u017eadovanou m\u011bnu. V\u011bt\u0161inou to stihneme do 30 min.",
  "how_to_get_started_step_3_hint": "V\u011bnujte pozornost platebn\u00edm pokyn\u016fm. Nezapome\u0148te do zpr\u00e1vy pro p\u0159\u00edjemce vlo\u017eit text dle pokyn\u016f. Objedn\u00e1vku d\u00edky tomu zpracujeme rychleji.",
  "how_to_get_started_step_4_name": "",
  "how_to_get_started_step_4_header": "",
  "how_to_get_started_step_4_subheader": "",
  "how_to_get_started_step_4_text": "",
  "how_to_get_started_step_4_cta": "",
  "landing_clients_since_2013": "klient\u016f od roku 2013",
  "landing_monthly_volume": "objem za posledn\u00edch 30 dn\u00ed",
  "landing_exchanges_per_month": "sm\u011bn za posledn\u00edch 30 dn\u00ed",
  "cookie_bar_header": "Aby v\u0161e fungovalo spr\u00e1vn\u011b, <0>Simplecoin</0> pou\u017e\u00edv\u00e1 cookies.",
  "cookie_bar_text": "Pou\u017e\u00edv\u00e1me soubory cookies pro zaji\u0161t\u011bn\u00ed p\u0159ihla\u0161ov\u00e1n\u00ed, navigaci a anal\u00fdzy n\u00e1v\u0161t\u011bvnosti. D\u011bkujeme za V\u00e1\u0161 souhlas s jejich pou\u017eit\u00edm.",
  "cookie_bar_agree_and_continue_button": "Souhlas\u00edm",
  "cookie_bar_manage_cookie_settings_button": "Nastaven\u00ed cookies",
  "order_detail_info_header": "Detaily objedn\u00e1vky",
  "order_detail_order_id": "\u010c\u00edslo objedn\u00e1vky",
  "order_detail_created_at": "Vytvo\u0159eno",
  "order_detail_status": "Stav",
  "order_detail_rate": "Kurz objedn\u00e1vky",
  "order_detail_button_cancel": "Zru\u0161it",
  "order_detail_button_suspend": "Pozastavit",
  "order_detail_button_download": "St\u00e1hnout PDF",
  "order_detail_button_new_ticket": "Vytvo\u0159it po\u017eadavek na podporu",
  "order_detail_history_table_header": "Historie objedn\u00e1vky",
  "order_history_datetime_column_name": "Datum a \u010das",
  "order_history_status_column_name": "Stav",
  "order_detail_outgoing_payment_label": "Odchoz\u00ed platba",
  "order_detail_incoming_payment_label": "Va\u0161e platba",
  "order_detail_incoming_payment_waiting_for_transaction": "Va\u0161e platba je\u0161t\u011b nebyla detekov\u00e1na",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "\u010cek\u00e1me na va\u0161\u00ed platbu",
  "order_payment_instructions_header": "Za\u0161lete platbu s n\u00e1sleduj\u00edc\u00edmi \u00fadaji",
  "order_form_payment_instructions_bank_account_number_label": "\u010c\u00edslo bankovn\u00edho \u00fa\u010dtu",
  "order_form_payment_instructions_bank_amount_label": "\u010c\u00e1stka",
  "order_form_payment_instructions_bank_message_label": "Zpr\u00e1va pro p\u0159\u00edjemce",
  "order_form_payment_instructions_use_qr": "Nebo vyu\u017eijte QR k\u00f3d",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Bankovn\u00ed \u00fa\u010det sm\u011bn\u00e1rny",
  "order_status_done": "Hotov\u00e1",
  "order_status_delivered": "Vy\u0159\u00edzen\u00e1",
  "order_status_canceled": "Zru\u0161en\u00e1",
  "order_status_returned": "Vr\u00e1cen\u00e1",
  "order_status_in_progress": "Prob\u00edhaj\u00edc\u00ed",
  "order_status_confirming_incoming": "P\u0159\u00edchoz\u00ed potvrzovan\u00e1",
  "order_status_confirming_outgoing": "Odchoz\u00ed potvrzovan\u00e1",
  "order_status_new": "Nov\u00e1",
  "order_status_suspended": "Pozastavena",
  "order_status_received": "P\u0159ijata",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "Va\u0161e platba je\u0161t\u011b nebyla detekov\u00e1na",
  "show_payment_instructions_dialog_opener_button": "Zobrazit platebn\u00ed instrukce",
  "support_section_header": "Nena\u0161li jste odpov\u011b\u010f?",
  "support_section_text": "Na z\u00e1kaznickou linku se n\u00e1m dovol\u00e1te od pond\u011bl\u00ed do ned\u011ble mezi 9:00 a 21:00. Nebo n\u00e1m po\u0161lete e-mail. Na\u0161i kolegov\u00e9 v\u00e1m v\u017edy ochotn\u011b porad\u00ed a bezpe\u010dn\u011b v\u00e1s n\u00e1kupem provedou.",
  "label_crypto_sent_to_address": "Adresa",
  "label_transaction_id": "ID transakce",
  "order_detail_button_proceed": "Pokra\u010dovat ve zpracov\u00e1n\u00ed",
  "label_amount_and_currency": "\u010c\u00e1stka",
  "label_payment_sent_at": "Vytvo\u0159ena",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "P\u0159\u00edchoz\u00ed platba je potvrzovan\u00e1",
  "order_detail_outgoing_payment_order_suspended": "Objedn\u00e1vka je pozastavena a \u010dek\u00e1 na Va\u0161e rozhodnut\u00ed",
  "order_detail_outgoing_payment_order_return_to_client": "Va\u0161e platba bude vr\u00e1cena",
  "order_form_payment_instructions_company_billing_details": "Faktura\u010dn\u00ed \u00fadaje",
  "order_has_been_created": "Objedn\u00e1vka byla vytvo\u0159ena",
  "fiat_payment_instructions_amount_label": "\u010c\u00e1stka",
  "order_detail_button_return": "Vr\u00e1tit",
  "order_view_original_price_label": "P\u0159edchoz\u00ed nab\u00eddka",
  "order_view_limit_price_label": "Limitn\u00ed cena",
  "order_view_current_price_label": "Aktu\u00e1ln\u00ed nab\u00eddka",
  "cryypto_payment_instructions_amount_label": "\u010c\u00e1stka",
  "payment_instruction_hint_fill_amount_to_show_qr": "Pro zobrazen\u00ed QR k\u00f3du zadejte \u010d\u00e1stku",
  "deposit_payment_instruction_amount_label": "V\u00fd\u0161e vkladu",
  "form_email_field": "Email",
  "confirm_button": "Potvrdit",
  "email_placeholder": "Email",
  "change_in_24hours": "V\u00fdvoj za posledn\u00edch 24 hodin",
  "order_offer_amounts_label": "Va\u0161e nab\u00eddka",
  "order_offer_your_crypto_address": "Va\u0161e krypto adresa",
  "order_offer_continue_to_login": "Pokra\u010dujte s \u00fa\u010dtem {{companyName}}",
  "order_offer_or_separator": "nebo",
  "order_offer_continue_without_registration": "Pokra\u010dovat bez registrace",
  "order_offer_validity_notice": "Tato nab\u00eddka vypr\u0161\u00ed za <strong>{{timeRemaining}}</strong>. Pros\u00edme, p\u0159ihlaste se nebo pokra\u010dujte s e-mailem.",
  "order_offer_continue_as_guest": "Pokra\u010dovat bez registrace",
  "order_offer_existing_email_please_click_continue_button": "Tento e-mail je ji\u017e registrov\u00e1n. Pros\u00edme vyberte mo\u017enost \"Pokra\u010dovat s \u00fa\u010dtem {{companyName}}\"",
  "landing_header_references": "Reference",
  "crypto_payment_instructions_amount_label": "\u010c\u00e1stka",
  "crypto_payment_instructions_destination_tag_field_label": "Destination tag",
  "order_form_mail_help_text": "Na tento e-mail V\u00e1m za\u0161leme pokyny pro dokon\u010den\u00ed objedn\u00e1vky",
  "validation_required_email": "Zadejte email",
  "validation_required_crypto_account": "Zadejte kryptopen\u011b\u017eenku",
  "landing_help_section_header": "Zde najdete odpov\u011bdi na nej\u010dast\u011bj\u0161\u00ed ot\u00e1zky. Krom\u011b p\u00edsemn\u00fdch n\u00e1vod\u016f v\u00e1m ale r\u00e1di porad\u00edme i na na\u0161\u00ed z\u00e1kaznick\u00e9 lince na telefonu +420 608 746 753 nebo e-mailu info@simplecoin.eu, od pond\u011bl\u00ed do ned\u011ble 9-21 h. Provedeme v\u00e1s prvn\u00edm n\u00e1kupem, pom\u016f\u017eeme s objedn\u00e1vkou \u010di registrac\u00ed, porad\u00edme, jak si zalo\u017eit pen\u011b\u017eenku, nebo zodpov\u00edme va\u0161e dotazy. Nev\u00e1hejte se n\u00e1s na cokoli zeptat.",
  "guest_exchange_form_limit_alert": "N\u00e1kup bez registrace je mo\u017en\u00fd do v\u00fd\u0161e {{limit}} {{code}}. Pro vy\u0161\u0161\u00ed \u010d\u00e1stky se pros\u00edm p\u0159ihla\u0161te nebo zaregistrujte.",
  "exchange_form_email_label": "V\u00e1\u0161 e-mail",
  "exchange_form_alert_change": "Sm\u011bnu mezi zvolen\u00fdmi m\u011bnami neumo\u017e\u0148ujeme. Pros\u00edm upravte zvolenou kombinaci ",
  "welcome_in_wallet": "V\u00edtejte v pen\u011b\u017eence!",
  "start_with_funding_text_2": "Nyn\u00ed u\u017e se zn\u00e1me. M\u016f\u017eete tedy vlo\u017eit pen\u00edze a za\u010d\u00edt obchodovat podle libosti.",
  "start_with_funding_text_1": "Skv\u011bl\u00e1 pr\u00e1ce!",
  "amount_in_fiat_payment_instructions_info": "\u010c\u00e1stka je nutn\u00e1 pro generov\u00e1n\u00ed QR k\u00f3du",
  "limit_table_level_0_text": "Do hodnoty {{limitLevel0Eur}} \u20ac v rozmez\u00ed 10 dn\u00ed m\u016f\u017eete nakupovat bez registrace a bez nutnosti ov\u011b\u0159en\u00ed osobn\u00edch doklad\u016f.",
  "label_go_to_order": "P\u0159ej\u00edt k n\u00e1kupu",
  "label_register": "Registrovat",
  "account_detail": "Detail \u00fa\u010dtu",
  "signup_form_subtitle": "Z\u00edsk\u00e1te lep\u0161\u00ed kurz a vy\u0161\u0161\u00ed limity obchodov\u00e1n\u00ed. Budete m\u00edt p\u0159ehled o sv\u00fdch transakc\u00edch.",
  "label_guest_login": "Pokud ji\u017e m\u00e1te \u00fa\u010det, <0>p\u0159ihlaste se</0>",
  "label_guest_signup": "Pokud je\u0161t\u011b nem\u00e1te \u00fa\u010det, <0>zaregistrujte se</0>",
  "label_register_success": "Registrace byla \u00fasp\u011b\u0161n\u00e1",
  "label_register_success_verify": "Nyn\u00ed pros\u00edm aktivujte sv\u016fj \u00fa\u010det",
  "label_register_success_sended_verify": "Na e-mail {{mail}} jsme V\u00e1m zaslali odkaz pro aktivaci \u00fa\u010dtu.",
  "label_account_notes": "Pozn\u00e1mky k \u00fa\u010dtu",
  "deposit_request_card_head": "\u017d\u00e1dosti o vklad",
  "dashboard_balances_no_deposit_requests": "\u017d\u00e1dn\u00e9 \u017e\u00e1dosti o vklad",
  "deposit_request_status_pending": "\u010cek\u00e1 na vy\u0159\u00edzen\u00ed",
  "exchange_form_terms_must_be_accepted": "Tento souhlas je povinn\u00fd",
  "validation_required_bank_account": "Zadejte \u010d\u00edslo bankovn\u00edho \u00fa\u010dtu",
  "order_status_dispatched": "Odbaven\u00e1",
  "order_status_paid": "Uhrazen\u00e1",
  "order_status_paidUnconfirmed": "Uhrazen\u00e1 (1/2)",
  "order_status_tooltip_new": "Objedn\u00e1vka \u010dek\u00e1 na zaplacen\u00ed.",
  "order_status_tooltip_paidUnconfirmed": "Vid\u00edme p\u0159\u00edchoz\u00ed transakci. \u010cek\u00e1 se na jej\u00ed potvrzen\u00ed na block-chainu.",
  "order_status_tooltip_paid": "Objedn\u00e1vka je uhrazena. Vid\u00edme p\u0159\u00edchoz\u00ed platbu. Pracujeme na odbaven\u00ed objednan\u00e9 m\u011bny ze sm\u011bn\u00e1rny.",
  "order_status_tooltip_paid_suspended": "Objedn\u00e1vka je uhrazena.",
  "order_status_tooltip_dispatched": "Odeslali jsme objednanou m\u011bnu na c\u00edlovou adresu/\u00fa\u010det. \u010cek\u00e1me na potvrzen\u00ed transakce na block-chainu/bankou.",
  "order_status_tooltip_done": "Objedn\u00e1vka je \u00fasp\u011b\u0161n\u011b vy\u0159\u00edzena.",
  "order_status_tooltip_canceled": "Objedn\u00e1vka byla zru\u0161ena.",
  "suspended_order_tooltip": "Zpracov\u00e1n\u00ed objedn\u00e1vky je pozastaveno.",
  "order_detail_outgoing_payment_waiting_to_be_sent": "Pracujeme na tom, abychom co nejd\u0159\u00edve odbavili objednan\u00e9 prost\u0159edky.",
  "exchange_limit_for_verification_level_2_text": "V\u00e1\u0161 limit pro obchodov\u00e1n\u00ed nen\u00ed form\u00e1ln\u011b stanoven. Pro velk\u00e9 \u010d\u00e1stky bude individu\u00e1ln\u011b posouzen dle <0>dolo\u017een\u00fdch p\u0159\u00edjm\u016f</0>.",
  "your_limit_is": "V\u00e1\u0161 limit je",
  "trading_limit": "Limit pro obchodov\u00e1n\u00ed",
  "exchange_trading_limit_info": "Limit je automaticky sn\u00ed\u017een pot\u00e9, co obdr\u017e\u00edme \u00fahradu za va\u0161\u00ed objedn\u00e1vku. Neuhrazen\u00e9 objedn\u00e1vky limit neovliv\u0148uj\u00ed.",
  "you_have_no_suitable_wallet_among_recipients": "Mezi p\u0159\u00edjemci nem\u00e1te \u017e\u00e1dnou pen\u011b\u017eenku pro {{currencyCode}}.",
  "you_have_no_suitable_account_among_recipients": "Mezi p\u0159\u00edjemci nem\u00e1te \u017e\u00e1dn\u00fd bankovn\u00ed \u00fa\u010det pro {{currencyCode}}.",
  "rate_when_order_created": "Kurz p\u0159i vytvo\u0159en\u00ed objedn\u00e1vky",
  "order_status_proceed": "Pokra\u010dovat ve zpracov\u00e1n\u00ed",
  "waiting_for_limit": "\u010cek\u00e1 na limit",
  "button_more": "V\u00edce ...",
  "exchange_form_expand_optional_fields": "Detaily platby (variabiln\u00ed symbol, atd.)",
  "return_fiat_payment_info": "Tato objedn\u00e1vka je ji\u017e zaplacena. Vr\u00e1cen\u00ed platby je zpoplatn\u011bno. Nazp\u011bt m\u016f\u017eete obdr\u017eet {{amount}} {{currency}} na p\u016fvodn\u00ed bankovn\u00ed \u00fa\u010det.",
  "cancel_order_and_return_payment": "Zru\u0161it objedn\u00e1vku a vr\u00e1tit platbu",
  "remaining_limit": "Zb\u00fdv\u00e1",
  "trading_limit_reached": "Vy\u010derpali jste obchodn\u00ed limit pro dan\u00e9 obdob\u00ed.",
  "attached_files": "P\u0159ilo\u017een\u00e9 soubory",
  "there_are_no_files": "Nen\u00ed nahr\u00e1n \u017e\u00e1dn\u00fd soubor",
  "why_we_need_verification_info": "Z\u00e1kon o\u0161et\u0159uj\u00edc\u00ed legalizaci v\u00fdnos\u016f z tresn\u00e9 \u010dinnosti (zn\u00e1m\u00fd pod zkratkou AML) n\u00e1m ukl\u00e1d\u00e1, abychom l\u00e9pe znali sv\u00e9 klienty. Proto je nutn\u00e9, abyste n\u00e1m o sob\u011b \u0159ekl/\u0159ekla p\u00e1r informac\u00ed, kter\u00e9 samoz\u0159ejm\u011b z\u016fst\u00e1vaj\u00ed bezpe\u010dn\u011b ulo\u017een\u00e9 pouze u n\u00e1s.",
  "proceed_to_verification_action": "P\u0159ejd\u011bte pros\u00edm k verifikaci",
  "verification_request_line2": "\u010cek\u00e1 V\u00e1s n\u011bkolik krok\u016f v na\u0161em pr\u016fvodci a pak u\u017e m\u016f\u017eete pln\u011b vyu\u017e\u00edvat na\u0161i pen\u011b\u017eenku.",
  "verification_request_line1": "Nyn\u00ed se mus\u00edme trochu poznat",
  "document_issued_by_government_info": "P\u0159ilo\u017ete pros\u00edm doklad vydan\u00fd st\u00e1tem, kter\u00fd potvrzuje informace uveden\u00e9 v p\u0159edchoz\u00edm kroku.",
  "secondary_document_info": "P\u0159ilo\u017ete pros\u00edm dal\u0161\u00ed doklad, kter\u00fd potvrzuje informace uveden\u00e9 v prvn\u00edm kroku.",
  "selfie_with_id_info": "P\u0159ilo\u017ete pros\u00edm fotografii, na kter\u00e9 je vid\u011bt v\u00e1\u0161 obli\u010dej a na kter\u00e9 dr\u017e\u00edte osobn\u00ed doklad v ruce. \u00dadaje na dokladu mus\u00ed b\u00fdt \u010diteln\u00e9.",
  "understood_button": "Rozum\u00edm",
  "no_prooved_incomes_info": "Zat\u00edm nejsou dolo\u017eeny \u017e\u00e1dn\u00e9 p\u0159\u00edjmy. I tak m\u016f\u017eete obchodovat s kryptom\u011bnami a\u017e do v\u00fd\u0161e z\u00e1kladn\u00edho limitu (10 000 EUR/m\u011bs\u00edc pro verifikovan\u00e9 klienty). Pro zv\u00fd\u0161en\u00ed Va\u0161eho obchodn\u00edho limitu nad 10 000 EUR/m\u011bs\u00edc je ji\u017e pot\u0159eba dolo\u017eit odpov\u00eddaj\u00edc\u00ed p\u0159\u00edjem.",
  "verified": "Ov\u011b\u0159en",
  "payment_instructions": "Platebn\u00ed \u00fadaje",
  "make_payment_to_one_of_our_accounts": "Prove\u010fte platbu na jeden z na\u0161ich \u00fa\u010dt\u016f",
  "payment_in": "Platba v",
  "iban_payment_instructions": "Instrukce pro platbu IBAN",
  "new_price_is_being_loaded": "Z\u00edsk\u00e1v\u00e1m novou cenovou nab\u00eddku...",
  "price_refresh_timer_tooltip": "Zobrazenou cenu a kurz pro V\u00e1s aktualizujeme ka\u017ed\u00fdch p\u00e1r sekund.",
  "send_to_address": "Zaslat na adresu",
  "payment_specific_data": "Platebn\u00ed \u00fadaje",
  "bank_account_owner": "Majitel \u00fa\u010dtu",
  "filter_all_currencies": "V\u0161echny m\u011bny",
  "ticket_detail": "Detail po\u017eadavku",
  "last_verification_step_warning": "Toto je posledn\u00ed krok verifikace. Po jeho odesl\u00e1n\u00ed nebudete nad\u00e1le moci m\u011bnit zadan\u00e9 \u00fadaje. V\u00e1\u0161 profil bude verifikov\u00e1n na\u0161\u00edm person\u00e1lem.",
  "exchange_form_limit_alert": "Objedn\u00e1vka v t\u00e9to v\u00fd\u0161i ji\u017e p\u0159esahuje v\u00e1\u0161 povolen\u00fd obchodn\u00ed limit {{limit}} {{code}}. Objedn\u00e1vku m\u016f\u017eete vytvo\u0159it, ale nebude ji mo\u017en\u00e9 odbavit, dokud se neverifikujete nebo nedolo\u017e\u00edte odpov\u00eddaj\u00edc\u00ed p\u0159\u00edjmy.",
  "order_limit_alert": "Po zaplacen\u00ed t\u00e9to objedn\u00e1vky p\u0159es\u00e1hnete v\u00e1\u0161 obchodn\u00ed limit a objedn\u00e1vku nebude mo\u017en\u00e9 odbavit, dokud se neverifikujete nebo nedolo\u017e\u00edte odpov\u00eddaj\u00edc\u00ed p\u0159\u00edjmy.",
  "remove_recipient_confirmation_question": "Opravdu chcete smazat tohoto p\u0159\u00edjemce? Tuto akci nebude mo\u017en\u00e9 vr\u00e1tit zp\u011bt.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Pole '{{fieldName}}' mus\u00ed b\u00fdt vypln\u011bn\u00e9",
  "inheritance": "D\u011bdictv\u00ed",
  "relationship_to_testator": "Vztah k z\u016fstaviteli",
  "inherited_from": "D\u011bdictv\u00ed po (jm\u00e9no)",
  "landing_how_to_get_started_button": "Jak za\u010d\u00edt",
  "affiliate_partners_must_be_verified": "Abyste se mohl st\u00e1t na\u0161\u00edm partnerem, mus\u00edme V\u00e1s nejd\u0159\u00edve poznat.",
  "regular_income": "Pravideln\u00fd p\u0159\u00edjem",
  "tax_declaration": "Da\u0148ov\u00e9 p\u0159izn\u00e1n\u00ed",
  "annual_report": "V\u00fdro\u010dn\u00ed zpr\u00e1va",
  "date_of_acceptance": "Datum p\u0159ijet\u00ed",
  "document_confirming_acceptance_of_inheritance": "Dokument potvrzuj\u00edc\u00ed p\u0159ijet\u00ed d\u011bdictv\u00ed",
  "financial_closing_statement": "\u00da\u010detn\u00ed uz\u00e1v\u011brka",
  "decision_on_sharing_profit": "Rozhodnut\u00ed o v\u00fdplat\u011b zisku",
  "sale_contract": "Prodejn\u00ed smlouva",
  "real_estate_registry_extract": "V\u00fdpis z registru nemovitost\u00ed",
  "typical_income_attachments": "Typick\u00e9 p\u0159\u00edlohy",
  "sale_of_immovable_property": "Prodej nemovitosti",
  "address_of_property": "Adresa nemovitosti",
  "date_of_sale": "Datum prodeje",
  "sale_channel": "Zp\u016fsob prodeje / Zprost\u0159edkovatel prodeje",
  "paycheck": "V\u00fdplatn\u00ed p\u00e1ska",
  "employment_contract": "Pracovn\u00ed smlouva",
  "field_of_business": "Obor podnik\u00e1n\u00ed",
  "company_id": "I\u010c",
  "monetary_donation": "Pen\u011b\u017en\u00ed dar",
  "donor": "D\u00e1rce",
  "donor_relationship_to_recipient": "Vztah d\u00e1rce k obdarovan\u00e9mu",
  "purpose_of_donation": "\u00da\u010del daru",
  "source_of_donated_funds": "P\u016fvod darovan\u00fdch prost\u0159edk\u016f",
  "email_subscribe_success": "\u00dasp\u011b\u0161n\u011b jste se p\u0159ihl\u00e1sili k odb\u011bru novinek",
  "landing_index_meta_tag_title": "Simplecoin - Nejv\u011bt\u0161\u00ed sm\u011bn\u00e1rna kryptom\u011bn v \u010cR",
  "landing_index_meta_tag_description": "S d\u016fv\u011brou od roku 2013",
  "landing_how_to_get_started_meta_tag_title": "Simplecoin - Jak za\u010d\u00edt s n\u00e1kupem kryptom\u011bn",
  "landing_how_to_get_started_meta_tag_description": "Jednoduch\u00fd podrobn\u00fd n\u00e1vod pro ka\u017ed\u00e9ho",
  "landing_about_meta_tag_description": "Jsme experti na kryptom\u011bny",
  "landing_about_meta_tag_title": "Simplecoin - O n\u00e1s",
  "landing_faq_meta_tag_description": "Co pot\u0159ebuji k n\u00e1kupu kryptom\u011bn? Pro\u010d se verifikovat?",
  "landing_faq_meta_tag_title": "Simplecoin - \u010cast\u00e9 dotazy",
  "landing_support_meta_tag_title": "Simplecoin - N\u00e1pov\u011bda",
  "landing_support_meta_tag_description": "Podrobn\u00e9 n\u00e1vody, jak si zalo\u017eit pen\u011b\u017eenku, ud\u011blat objedn\u00e1vku nebo si nastavit DCA",
  "landing_tos_meta_tag_description": "F\u00e9rov\u00fd obchod s kryptom\u011bnami",
  "landing_tos_meta_tag_title": "Simplecoin - Obchodn\u00ed podm\u00ednky",
  "landing_privacy_meta_tag_title": "Simplecoin - zpracov\u00e1n\u00ed obchodn\u00edch \u00fadaj\u016f",
  "landing_privacy_meta_tag_description": "Va\u0161e \u00fadaje jsou u n\u00e1s v bezpe\u010d\u00ed",
  "landing_affiliate_meta_tag_description": "Sta\u0148te se partnerem Simplecoin a z\u00edskejte provizi",
  "landing_affiliate_meta_tag_title": "Simplecoin - affiliate program",
  "landing_limits_meta_tag_description": "Mo\u017enosti n\u00e1kupu bez registrace a kdy je t\u0159eba se ji\u017e verifikovat",
  "landing_limits_meta_tag_title": "Simplecoin - limity a verifikace",
  "landing_contact_meta_tag_description": "Domluvte si osobn\u00ed sch\u016fzku v na\u0161em klientsk\u00e9m centru nebo vyu\u017eijte klientskou podporu 9-21 h. ka\u017ed\u00fd den v t\u00fddnu.",
  "landing_contact_meta_tag_title": "Simplecoin - kontakt",
  "react_new_app_version_available_dialog_content": "K dispozici je nov\u00e1 verze. Obnovte pros\u00edm str\u00e1nku, abyste se ujistili, \u017ee pou\u017e\u00edv\u00e1te nejnov\u011bj\u0161\u00ed verzi.",
  "wallet_recipient_warning_1": "Kdokoliv zn\u00e1 p\u0159\u00edstupov\u00e9 \u00fadaje ke krypto-pen\u011b\u017eence (zejm\u00e9na recovery seed - 12-24 slov slou\u017e\u00edc\u00edch k obnov\u011b pen\u011b\u017eenky), m\u016f\u017ee libovoln\u011b nakl\u00e1dat s prost\u0159edky v t\u00e9to pen\u011b\u017eence.",
  "wallet_recipient_warning_2": "Pokud pen\u011b\u017eenku zalo\u017eil n\u011bkdo jin\u00fd ne\u017e j\u00e1 s\u00e1m, m\u00e1 pravd\u011bpodovn\u011b k t\u00e9to pen\u011b\u017eence p\u0159\u00edstup a m\u016f\u017ee libovoln\u011b nakl\u00e1dat s prost\u0159edky v t\u00e9to pen\u011b\u017eence.",
  "wallet_recipient_warning_3": "Pokud ztrat\u00edm p\u0159\u00edstup k pen\u011b\u017eence a nem\u00e1m k dispozici jej\u00ed z\u00e1lohu (recovery seed), ztr\u00e1c\u00edm p\u0159\u00edstup ke v\u0161em prost\u0159edk\u016fm na pen\u011b\u017eence.",
  "wallet_recipient_warning_4": "Pokud p\u0159id\u00e1v\u00e1m jako p\u0159\u00edjemce adresu krypto-pen\u011b\u017eenky, kter\u00e1 nen\u00ed moje, nebudu moci p\u0159\u00edmo disponovat prost\u0159edky v t\u00e9to pen\u011b\u017eence. Z\u00e1le\u017e\u00ed na t\u0159et\u00ed stran\u011b, zda mi umo\u017en\u00ed t\u011bmito prost\u0159edky disponovat.",
  "warning": "Upozorn\u011bn\u00ed",
  "ticket_attachments_info": "P\u0159\u00edlohy lze k po\u017eadavku p\u0159ipojit v n\u00e1sleduj\u00edc\u00edm kroku (po jeho vytvo\u0159en\u00ed).",
  "one_off_income": "Jednor\u00e1zov\u00fd p\u0159\u00edjem",
  "button_previous": "P\u0159edchoz\u00ed",
  "years_of_service": "Odpracovan\u00e1 l\u00e9ta",
  "primary_or_secondary_activity": "Hlavn\u00ed / vedlej\u0161\u00ed \u010dinnost",
  "rental_contract": "Smlouva o pron\u00e1jmu",
  "bank_statement_with_incoming_payment": "V\u00fdpis z bankovn\u00edho \u00fa\u010dtu s p\u0159\u00edchoz\u00ed platbou",
  "pension_confirmation_letter": "Potvrzen\u00ed o p\u0159izn\u00e1n\u00ed d\u016fchodu",
  "loan": "P\u016fj\u010dka",
  "loan_maturity_date": "Datum splatnosti",
  "loan_effective_date": "Datum poskytnut\u00ed",
  "lender": "V\u011b\u0159itel",
  "loan_agreement": "Smlouva o poskytnut\u00ed p\u016fj\u010dky",
  "donation_agreement": "Darovac\u00ed smlouva",
  "savings": "\u00daspory",
  "bank_statement": "V\u00fdpis z bankovn\u00edho \u00fa\u010dtu",
  "sale_of_movable_property": "Prodej v\u011bci movit\u00e9",
  "sale_of_company_share": "Prodej pod\u00edlu ve spole\u010dnosti",
  "investment_return": "Investi\u010dn\u00ed v\u00fdnos",
  "investment_period": "Doba investov\u00e1n\u00ed",
  "crypto_mining": "T\u011b\u017eba kryptom\u011bn",
  "mining_target_crypto_address": "Krypto adresa vyt\u011b\u017een\u00e9 m\u011bny",
  "verified_clients_limit_info": "Verifikovan\u00ed klienti mohou obchodovat a\u017e do v\u00fd\u0161e 10 000 EUR za m\u011bs\u00edc bez nutnosti dal\u0161\u00edho dolo\u017een\u00ed p\u0159\u00edjm\u016f.",
  "expected_trading_volume": "O\u010dek\u00e1van\u00fd objem obchodov\u00e1n\u00ed",
  "expected_trading_volume_info": "Nastaven\u00ed pl\u00e1nu o\u010dek\u00e1van\u00fdch obchod\u016f je nepovinn\u00e9 a nez\u00e1vazn\u00e9. Pom\u016f\u017ee l\u00e9pe nastavit va\u0161e obchodn\u00ed limity a urychlit tak Va\u0161e obchodov\u00e1n\u00ed.",
  "add_expected_trading_volume": "P\u0159idat v\u00fdhled obchodov\u00e1n\u00ed",
  "trading_volume_period": "V\u00fdhled na obdob\u00ed",
  "landing_quarterly_volume": "Objem za 90 dn\u00ed",
  "landing_quarterly_exchanges": "Objedn\u00e1vek za 90 dn\u00ed",
  "income_state_revoked": "k dopln\u011bn\u00ed",
  "crypto_deposit_fee_info": "Pro rychl\u00e9 p\u0159ijet\u00ed Va\u0161\u00ed krypto transakce, je d\u016fle\u017eit\u00e9 zvolit spr\u00e1vnou v\u00fd\u0161i poplatku.",
  "deposit_on_account": "Vklad na \u00fa\u010det",
  "show": "zobrazit",
  "hide": "skr\u00fdt",
  "account_status_suspended": "pozastaven\u00fd",
  "account_status_closed": "uzav\u0159en\u00fd",
  "account_status_active": "aktivn\u00ed",
  "suspended_accounts": "Pozastaven\u00e9 \u00fa\u010dty",
  "closed_accounts": "Uzav\u0159en\u00e9 \u00fa\u010dty",
  "active_accounts": "Aktivn\u00ed \u00fa\u010dty",
  "create_wallet_account_form_header": "Vytvo\u0159it nov\u00fd \u00fa\u010det",
  "account_number": "\u010c\u00edslo \u00fa\u010dtu",
  "accounted_transactions": "Za\u00fa\u010dtovan\u00e9 transakce",
  "counterparty": "Protistrana",
  "all_financial_operations": "V\u0161e",
  "accounting_balance": "\u00da\u010detn\u00ed z\u016fstatek",
  "pending_deposits": "Neza\u00fa\u010dtovan\u00e9 vklady",
  "pending_withdrawals": "Neza\u00fa\u010dtovan\u00e9 v\u00fdb\u011bry",
  "pending_transactions": "Neza\u00fa\u010dtovan\u00e9 transakce",
  "blocked_amount_alert": "Na tomto \u00fa\u010dtu je blokovan\u00e1 \u010d\u00e1stka ve v\u00fd\u0161i {{amount}} {{currency}}. Pros\u00edm kontaktujte podporu pro v\u00edce informac\u00ed.",
  "available_balance_tooltip": "Disponibiln\u00ed z\u016fstatek je \u010d\u00e1stka, kterou m\u00e1te aktu\u00e1ln\u011b k dispozici pro obchodov\u00e1n\u00ed. Disponibiln\u00ed z\u016fstatek nezahrnuje nepotvrzen\u00e9 vklady ale ji\u017e zohled\u0148uje blokovan\u00e9 \u010d\u00e1stky nap\u0159. z d\u016fvodu prob\u00edhaj\u00edc\u00edch v\u00fdb\u011br\u016f.",
  "total_balance_tooltip": "\u00da\u010detn\u00ed z\u016fstatek je sou\u010dtem v\u0161ech potvrzen\u00fdch transakc\u00ed na va\u0161em \u00fa\u010dtu.",
  "deposit_requests_total_tooltip": "Neza\u00fa\u010dtovan\u00e9 vklady jsou p\u0159\u00edchoz\u00ed transakce na V\u00e1\u0161 \u00fa\u010det, u kter\u00fdch \u010dek\u00e1me na potvrzen\u00ed na block-chainu nebo nebyly p\u0159ips\u00e1ny z d\u016fvodu p\u0159ekro\u010den\u00ed va\u0161eho obchodn\u00edho limitu.",
  "withdrawal_requests_total_tooltip": "Neza\u00fa\u010dtovan\u00e9 v\u00fdb\u011bry jsou v\u00fdb\u011bry \u010dekaj\u00edc\u00ed na potvrzen\u00ed na block-chainu.",
  "cancel_account": "Zru\u0161it \u00fa\u010det",
  "cancel_account_confirmation_question": "Opravdu chcete zru\u0161it tento \u00fa\u010det? Zru\u0161en\u00ed \u00fa\u010dtu je nevratn\u00e1 operace.",
  "empty_account_required_to_close_it": "Zav\u0159\u00edt lze pouze \u00fa\u010dty s nulov\u00fdm z\u016fstatkem.",
  "account_closed_success": "\u00da\u010det byl \u00fasp\u011b\u0161n\u011b uzav\u0159en.",
  "approved_incomes": "Schv\u00e1len\u00e9 p\u0159\u00edjmy",
  "pending_incomes": "P\u0159\u00edjmy \u010dekaj\u00edc\u00ed na schv\u00e1len\u00ed",
  "show_details": "zobrazit podrobnosti",
  "hide_details": "skr\u00fdt podrobnosti",
  "hide_approved_incomes": "Skr\u00fdt schv\u00e1len\u00e9 p\u0159\u00edjmy",
  "show_approved_incomes": "uk\u00e1zat schv\u00e1len\u00e9 p\u0159\u00edjmy",
  "increase_trading_limit": "Zv\u00fd\u0161en\u00ed obchodn\u00edho limitu",
  "save_as_draft": "Ulo\u017eit jako draft",
  "withdrawal_success": "\u017d\u00e1dost o v\u00fdb\u011br byla \u00fasp\u011b\u0161n\u011b pod\u00e1na",
  "withdrawal_confirmation_transaction_fee_label": "Poplatek: {{feeAmount}} {{feeCurrencyCode}}",
  "withdrawal_form_fee_in_amount_switch_label": "Poplatek za v\u00fdb\u011br zapo\u010dten",
  "withdrawal_recap_table_fee": "Poplatek",
  "withdrawal_recap_table_deducted_amount": "Celkov\u00e1 \u010d\u00e1stka",
  "withdrawal_recap_table_net_withdraw_amount": "P\u0159\u00edjemce dostane",
  "remove_income_confirmation": "Opravdu chcete smazat tento p\u0159\u00edjem?",
  "deposit_form_show_instructions": "Zobrazit platebn\u00ed instrukce",
  "income_state_readyForApproval": "\u010dek\u00e1 na schv\u00e1len\u00ed",
  "withdrawal_form_show_bank_fields_toggle": "Zobrazit upraviteln\u00e1 pole",
  "end_income": "Ukon\u010dit p\u0159\u00edjem",
  "edit_income": "Upravit p\u0159\u00edjem",
  "delete_income": "Smazat p\u0159\u00edjem",
  "pending_transaction_detail": "Detail neza\u00fa\u010dtovan\u00e9 transakce",
  "account": "\u00da\u010det",
  "verification_wizard": "Pr\u016fvodce verifikac\u00ed",
  "portfolio_volume": "Objem Va\u0161eho portfolia",
  "profile_verification_info_message_for_business": "<0>N\u00e1vod na registraci firmy najdete zde.</0>",
  "unknown_source": "nezn\u00e1m\u00fd zdroj",
  "amount_netto": "\u010c\u00e1stka (bez poplatku)",
  "deposit_request_status_on_hold": "Pozastaven",
  "exchange_form_verified_client_discount_message": "Verifikovan\u00ed klienti okam\u017eit\u011b u\u0161et\u0159\u00ed <0>{{nominalDiscount}} {{discountCurrency}}</0> za {{toCurrency}}.",
  "sign_up_to_get_discount": "Zaregistrujte se pro slevu",
  "exchange_form_verified_client_discount_message_generic": "Verifikovan\u00ed klienti maj\u00ed lep\u0161\u00ed cenu",
  "exchange_form_verified_client_discount_message_from_crypto": "Verifikovan\u00ed klienti okam\u017eit\u011b z\u00edskaj\u00ed o <0>{{nominalDiscount}} {{discountCurrency}}</0> lep\u0161\u00ed cenu za {{toCurrency}}.",
  "price_for_verified_clients": "Cena pro verifikovan\u00e9 klienty:",
  "contact_page_customer_center_reservation_info": "Pokud m\u00e1te z\u00e1jem o sch\u016fzku v klientsk\u00e9m centru, pros\u00edme <0>objednejte se zde online</0>",
  "label_house_number": "\u010c. p.",
  "react_file_upload_count_error": "maxim\u00e1ln\u00ed po\u010det nahran\u00fdch soubor\u016f {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "Mrz\u00ed n\u00e1s to, ale platby v {{currency}} nejsou moment\u00e1ln\u011b mo\u017en\u00e9.",
  "payment_instructions_delay_warning": "Bankovn\u00ed p\u0159evod v \u010desk\u00fdch korun\u00e1ch prob\u00edh\u00e1 okam\u017eit\u011b, pokud tuto slu\u017ebu nab\u00edz\u00ed Va\u0161e banka.<br/>\nP\u0159evody v eurech mohou trvat d\u00e9le. Pro zachov\u00e1n\u00ed p\u016fvodn\u00edho kurzu z objedn\u00e1vky m\u016f\u017eete p\u0159ilo\u017eit PDF potvrzen\u00ed platby od Va\u0161\u00ed banky (slu\u017eba je dostupn\u00e1 ka\u017ed\u00fd den od 9:00 do 21:00). Ka\u017ed\u00fd po\u017eadavek posuzujeme individu\u00e1ln\u011b a budeme V\u00e1s co nejd\u0159\u00edve kontaktovat.\n\n",
  "payment_confirmation_upload_info": "Potvrzen\u00ed p\u016fvodn\u00edho kurzu je posuzov\u00e1no individu\u00e1ln\u011b a z\u00e1vis\u00ed na Va\u0161\u00ed platebn\u00ed historii a v\u00fd\u0161i objedn\u00e1vky.",
  "attach_payment_confirmation": "P\u0159ilo\u017eit potvrzen\u00ed o platb\u011b",
  "price_fixed_until": "Cenu V\u00e1m garantujeme do {{time}}",
  "payment_confirmation_attached": "Potvrzen\u00ed o platb\u011b p\u0159ipojeno",
  "validation_incorrect_format_generic": "Neplatn\u00fd form\u00e1t",
  "exchange_fee_percentage_info": "Poplatky tvo\u0159\u00ed {{feePercentage}}% obchodovan\u00e9 \u010d\u00e1stky",
  "citizenship": "St\u00e1tn\u00ed ob\u010danstv\u00ed",
  "second_citizenship": "Druh\u00e9 st\u00e1tn\u00ed ob\u010dasntv\u00ed",
  "second_citizenship_helper": "Pokud m\u00e1te dvoj\u00ed ob\u010danstv\u00ed, uve\u010fte ob\u011b",
  "dashboard_intro_text_how_to_start": "Na\u0161e pen\u011b\u017eenka funguje podobn\u011b jako internetov\u00e9 bankovnictv\u00ed. Abyste mohli za\u010d\u00edt Simplecoin p\u011bn\u011b\u017eenku pou\u017e\u00edvat, mus\u00edte do n\u00ed vlo\u017eit prost\u0159edky na virtu\u00e1ln\u00ed \u00fa\u010dty, kter\u00e9 si v n\u00ed zalo\u017e\u00edte. Pro za\u010d\u00e1tek V\u00e1m doporu\u010dujeme zalo\u017eit po jednom \u00fa\u010dtu pro m\u011bny, se kter\u00fdmi chcete za\u010d\u00edt obchodovat. Pozd\u011bji samoz\u0159ejm\u011b m\u016f\u017eete p\u0159idat dal\u0161\u00ed m\u011bnov\u00e9 \u00fa\u010dtu.",
  "how_to_start_using_wallet": "Jak za\u010d\u00edt pou\u017e\u00edvat Simplecoin pen\u011b\u017eenku?",
  "create_accounts": "Zalo\u017ete si \u00fa\u010dty",
  "default_account_label": "{{currencyCode}} v\u00fdchoz\u00ed \u00fa\u010det",
  "default_account_description": "Tento \u00fa\u010det byl pro v\u00e1s automaticky vytvo\u0159en \u00favodn\u00edm pr\u016fvodcem.",
  "about_virtual_account_info": "Virtu\u00e1ln\u00ed \u00fa\u010det je m\u00edsto, kde v Simplecoin pen\u011b\u017eence dr\u017e\u00edte sv\u00e9 prost\u0159edky. Pokud chcete dr\u017eet prost\u0159edky ve vybran\u00e9 m\u011bn\u011b, mus\u00edte si pro tuto m\u011bnu zalo\u017eit nejm\u00e9n\u011b jeden \u00fa\u010det. Zalo\u017een\u00ed i veden\u00ed \u00fa\u010dtu je zdarma. Obchodov\u00e1n\u00ed prob\u00edh\u00e1 tak, \u017ee p\u0159ev\u00e1d\u00edte prost\u0159edky mezi jednotliv\u00fdmi \u00fa\u010dty, \nkter\u00e9 mohou b\u00fdt v r\u016fzn\u00fdch m\u011bn\u00e1ch.",
  "preferred_currencies_question": "Ve kter\u00fdch m\u011bn\u00e1ch budete nej\u010dast\u011bji obchodovat?",
  "crypto": "Krypto",
  "fiat": "Fiat",
  "create_accounts_for_selected_currencies": "Zalo\u017eit \u00fa\u010dty pro vybran\u00e9 m\u011bny",
  "accounts_successfully_created": "Zakl\u00e1d\u00e1n\u00ed \u00fa\u010dt\u016f bylo \u00fasp\u011b\u0161n\u011b dokon\u010deno. Zalo\u017eili jsme pro v\u00e1s {{accountsCount}} \u00fa\u010dt\u016f.",
  "create_your_first_accounts": "Zalo\u017ete si sv\u00e9 prvn\u00ed \u00fa\u010dty",
  "wallet_logo": "pen\u011b\u017eenka",
  "just_one_citizenship": "pouze jedno ob\u010danstv\u00ed",
  "information_about_verification_status_change": "O zm\u011bn\u011b stavu verifikace V\u00e1s budeme informovat na e-mail {{email}}.",
  "amount_too_low": "P\u0159\u00edli\u0161 n\u00edzk\u00e1 \u010d\u00e1stka",
  "footer_link_legal_documents": "Smluvn\u00ed dokumenty",
  "log_into_wallet": "Pen\u011b\u017eenka",
  "log_into_exchange": "Sm\u011bn\u00e1rna",
  "log_into_exchange_caption": "Jednor\u00e1zov\u00fd n\u00e1kup do va\u0161\u00ed pen\u011b\u017eenky",
  "log_into_wallet_caption": "Nejjednodu\u0161\u00ed obchodov\u00e1n\u00ed s kryptom\u011bnami",
  "payment_request": "\u017d\u00e1dost o platbu",
  "payment_hash": "Hash platby",
  "memo": "Popis",
  "scan_invoice_qr": "Naskenujte QR k\u00f3d platby",
  "lightning_payment": "Lightning platba",
  "scan_qr": "Naskenujte QR k\u00f3d",
  "pay": "Zaplatit"
}