import {
    REQUEST_CHANGE_PASSWORD,
    REQUEST_CHANGE_PASSWORD_SUCCESS,
    REQUEST_CHANGE_PASSWORD_FAILURE,
    REQUEST_CHANGE_PASSWORD_INVALIDATE,
    REQUEST_ENABLE_2FA,
    REQUEST_ENABLE_2FA_SUCCESS,
    REQUEST_ENABLE_2FA_FAILURE,
    REQUEST_DISABLE_2FA,
    REQUEST_DISABLE_2FA_FAILURE,
    REQUEST_DISABLE_2FA_SUCCESS,
    REQUEST_FETCH_2FA_SECRET,
    REQUEST_FETCH_2FA_SECRET_FAILURE,
    REQUEST_FETCH_2FA_SECRET_SUCCESS,
    REQUEST_ENABLE_2FA_INVALIDATE,
    REQUEST_DISABLE_2FA_INVALIDATE,
    REQUEST_RESET_PASSWORD,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_FAILURE,
    REQUEST_DELETE_ACCOUNT,
    REQUEST_DELETE_ACCOUNT_SUCCESS,
    REQUEST_DELETE_ACCOUNT_FAILURE,
    REQUEST_DISABLE_SOCIAL_LOGIN,
    REQUEST_DISABLE_SOCIAL_LOGIN_SUCCESS,
    REQUEST_DISABLE_SOCIAL_LOGIN_FAILURE,
} from './SecurityActions';
import {Request2FaSecret} from '~/src/typings/endpoints/security/Request2FaSecret';
import {Bank, ReducerEnvelope} from '@simplecoin/core';

export interface SecurityReducersInterface {
    changePassword: ReducerEnvelope<null>;
    resetPassword: ReducerEnvelope<Bank>;
    enable2Fa: ReducerEnvelope<null>;
    disable2Fa: ReducerEnvelope<null>;
    secret2Fa: ReducerEnvelope<Request2FaSecret>;
    deleteAccount: ReducerEnvelope<null>;
    disableSocialLogin?: ReducerEnvelope<null>;
}

const INITIAL_STATE = {
    changePassword: {isFetching: false},
    resetPassword: {isFetching: false},
    enable2Fa: {isFetching: false},
    disable2Fa: {isFetching: false},
    secret2Fa: {isFetching: false},
    deleteAccount: {isFetching: false},
};

/**
 * @param state
 * @param action
 * @return {object}
 */
export default function (state: SecurityReducersInterface = INITIAL_STATE, action): SecurityReducersInterface {
    switch (action.type) {
        // change password
        case REQUEST_CHANGE_PASSWORD:
            return {...state, changePassword: {isFetching: true}};
        case REQUEST_CHANGE_PASSWORD_SUCCESS:
            return {...state, changePassword: {isFetching: false, ...action.payload}};
        case REQUEST_CHANGE_PASSWORD_FAILURE:
            return {...state, changePassword: {isFetching: false, ...action.payload}};
        case REQUEST_CHANGE_PASSWORD_INVALIDATE:
            return {...state, changePassword: {isFetching: false}};

        // reset password
        case REQUEST_RESET_PASSWORD:
            return {...state, resetPassword: {isFetching: true}};
        case REQUEST_RESET_PASSWORD_SUCCESS:
            return {...state, resetPassword: {isFetching: false, ...action.payload}};
        case REQUEST_RESET_PASSWORD_FAILURE:
            return {...state, resetPassword: {isFetching: false, ...action.payload}};

        // enable two-factor authentication
        case REQUEST_ENABLE_2FA:
            return {...state, enable2Fa: {isFetching: true}};
        case REQUEST_ENABLE_2FA_SUCCESS:
            return {...state, enable2Fa: {isFetching: false, ...action.payload}};
        case REQUEST_ENABLE_2FA_INVALIDATE:
            return {...state, enable2Fa: {isFetching: false}};
        case REQUEST_ENABLE_2FA_FAILURE:
            return {...state, enable2Fa: {isFetching: false, ...action.payload}};

        // disable two-factor authentication
        case REQUEST_DISABLE_2FA:
            return {...state, disable2Fa: {isFetching: true}};
        case REQUEST_DISABLE_2FA_SUCCESS:
            return {...state, disable2Fa: {isFetching: false, ...action.payload}};
        case REQUEST_DISABLE_2FA_INVALIDATE:
            return {...state, disable2Fa: {isFetching: false}};
        case REQUEST_DISABLE_2FA_FAILURE:
            return {...state, disable2Fa: {isFetching: false, ...action.payload}};

        // fetch two-factor authentication token
        case REQUEST_FETCH_2FA_SECRET:
            return {...state, secret2Fa: {isFetching: true}};
        case REQUEST_FETCH_2FA_SECRET_SUCCESS:
            return {...state, secret2Fa: {isFetching: false, ...action.payload}};
        case REQUEST_FETCH_2FA_SECRET_FAILURE:
            return {...state, secret2Fa: {isFetching: false, ...action.payload}};

        // fetch delete account
        case REQUEST_DELETE_ACCOUNT:
            return {...state, deleteAccount: {isFetching: true}};
        case REQUEST_DELETE_ACCOUNT_SUCCESS:
            return {...state, deleteAccount: {isFetching: false, ...action.payload}};
        case REQUEST_DELETE_ACCOUNT_FAILURE:
            return {...state, deleteAccount: {isFetching: false, ...action.payload}};

        // fetch disable social login
        case REQUEST_DISABLE_SOCIAL_LOGIN:
            return {...state, disableSocialLogin: {isFetching: true}};
        case REQUEST_DISABLE_SOCIAL_LOGIN_SUCCESS:
            return {...state, disableSocialLogin: {isFetching: false, ...action.payload}};
        case REQUEST_DISABLE_SOCIAL_LOGIN_FAILURE:
            return {...state, disableSocialLogin: {isFetching: false, ...action.payload}};

        default:
            return state;
    }
}
