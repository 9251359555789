import {Paper, Typography} from '@mui/material';
import React from 'react';

interface InfoProps {
    info: string;
}

export default function Info({info}: InfoProps) {
    return (
        <Paper sx={{backgroundColor: '#FCFCFD', boxShadow: 'none', lineHeight: 1.4}}>
            <Typography variant='caption' fontWeight={500}>
                {info}
            </Typography>
        </Paper>
    );
}
