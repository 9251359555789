import {createSvgIcon} from '@mui/material/utils';
import React from 'react';

const DashboardIcon = createSvgIcon(
    <>
        <path
            fill='none'
            d='M8 22H14C18.4392 22 21 19.4392 21 15V9C21 4.5608 18.4392 2 14 2H8C3.5608 2 1 4.5608 1 9V15C1 19.4392 3.5608 22 8 22Z'
            strokeWidth='2'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />

        <path
            fill='none'
            d='M6 14.8734L9.11053 11.7629C9.36431 11.5091 9.77576 11.5091 10.0295 11.7629L11.3772 13.1105C11.631 13.3643 12.0424 13.3643 12.2962 13.1105L15.4067 10'
            strokeWidth='2'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </>,
    'Dashboard'
);

export default DashboardIcon;
