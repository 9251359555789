import {configureStore} from '@reduxjs/toolkit';
import {reducer as chartReducer} from './multichartSlice';
import {reducer as exchangeFormReducer} from './exchangeFormSlice';
import {reducer as orderReducer} from './orderSlice';
import {reducer as snackbarReducer} from './snackbarSlice';
import {reducer as siteReducer} from './siteSlice';
import {reducer as walletsReducer} from './walletSlice';
import {reducer as bankReducer} from './bankSlice';
import {reducer as redirectReducer} from './redirectSlice';
import {useDispatch} from 'react-redux';
import {trezorConnectSlice} from './trezorConnectSlice';

export const coreReducers = {
    multichart: chartReducer,
    exchangeForm: exchangeFormReducer,
    order: orderReducer,
    snackbar: snackbarReducer,
    site: siteReducer,
    wallets: walletsReducer,
    bank: bankReducer,
    redirect: redirectReducer,
    trezorConnect: trezorConnectSlice.reducer,
};

const store = configureStore({
    reducer: coreReducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useCoreAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
