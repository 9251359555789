import {styled} from '@mui/material/styles';
import {WidthBreakpoint} from '~/src/config/localParams';
import {Grid} from '@mui/material';

const FormWrapper = styled(Grid)`
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05);
    border-radius: 16px;
    padding: 36px 40px 48px 36px;
    max-width: 970px;
    margin: 20px auto;
    width: 100%;

    @media (max-width: ${WidthBreakpoint.sm}) {
        padding: 20px;
        max-width: 98%;
        border: none;
        box-shadow: none;
    }
`;

export default FormWrapper;
