import {getConfigValue} from '~/helpers/config';

// @ts-ignore
export const round = (num: number, precision: number) => Number(Math.round(num + 'e+' + precision) + 'e-' + precision);

/**
 * A helper that should be using whenever displaying an amount - balance, transaction amount etc. It is not suitable
 * for formatting inputs.
 */
export function formatAmount(value: number | string, fixedDecimals: number, locale = 'en'): string {
    try {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }

        // round to desired number of decimals
        const roundedValue = round(value, fixedDecimals);

        // remove decimals over the fixedDecimals limit
        // get rid of trailing zeros and add localized formatting
        // useGrouping: false would remove thousands separator
        return roundedValue.toLocaleString(locale, {maximumFractionDigits: fixedDecimals});
    } catch (e) {
        if (getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Failed to format amount', e);
        }
        return '0';
    }
}

/**
 * Helper that should be used when dealing with decimal number inputs
 */
export function formatInputAmount(value: number, fixedDecimals): string {
    try {
        return value.toLocaleString('fullwide', {maximumFractionDigits: fixedDecimals, useGrouping: false});
    } catch (e) {
        return '0';
    }
}

/**
 * Normalizes provided amount, e.g. converts "," to ".".
 */
export function normalizeAmount(amount: string) {
    amount = amount.replace(/,/g, '.'); // change decimal separator from comma to dot
    amount = amount.replace(/[^.\d]/g, '');
    amount = amount.replace(/\.\.+/g, '.');
    amount = amount.replace(/\s/g, ''); // remove whitespace
    return amount;
}
