import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import CountrySelector from './CountrySelector';
import Space from './Space';
import {
    FormHelperText,
    FormControl,
    TextField,
    InputAdornment,
    Collapse,
    useTheme,
    Stack,
    Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {FormikInterface} from '~/typings/ExchangeFormTypings';

interface BankCreateField {
    name: string;
    visible: boolean;
    label?: string;
    component?: React.ReactNode;
    hint?: string;
    collapse?: boolean;
}

interface BankCreateFormProps {
    currencyCode: string;
    formik: FormikInterface;
}

/**
 *  Renders bank create form
 */
export default function BankAccountFields({currencyCode, formik}: BankCreateFormProps) {
    const {t} = useTranslation();
    const [expanded, setExpanded] = useState<boolean>(false);
    const theme = useTheme();
    const {errors, touched, values, handleChange, handleBlur} = formik;
    const CZKChosen = currencyCode?.toUpperCase() === 'CZK';
    const isCurrencyChosen = !!currencyCode;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    /**
     * Renders single fields into an array.
     */
    function mapEachField(field: BankCreateField, _key: number): React.ReactNode {
        if (field.component && field.visible) {
            return (
                <React.Fragment key={field.name}>
                    <Space size={10} />
                    {field.component}
                </React.Fragment>
            );
        }

        const hasError = !!errors[field.name] && touched[field.name];

        if (field.visible && !field.collapse) {
            return (
                <div key={field.name}>
                    <FormControl error={hasError} fullWidth>
                        <TextField
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={hasError}
                            name={field.name}
                            label={field.label}
                            type='text'
                            margin='normal'
                            variant='outlined'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {hasError && <ErrorIcon color='error' />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {hasError && <FormHelperText id={field.name}>{errors[field.name]}</FormHelperText>}
                    </FormControl>

                    {field.hint && <FormHelperText>{field.hint}</FormHelperText>}
                </div>
            );
        }
    }

    /**
     * Renders single fields into an array.
     */
    function collapsedField(field: BankCreateField, key: number): React.ReactNode {
        const hasError = !!errors[field.name] && touched[field.name];
        if (field.visible && field.collapse) {
            return (
                <div key={key}>
                    <FormControl error={hasError} fullWidth>
                        <TextField
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={hasError}
                            name={field.name}
                            label={field.label}
                            type='text'
                            margin='normal'
                            variant='outlined'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {errors[field.name] && touched[field.name] && <ErrorIcon color='error' />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {errors[field.name] && <FormHelperText id={field.name}>{errors[field.name]}</FormHelperText>}
                    </FormControl>

                    {field.hint && <FormHelperText>{field.hint}</FormHelperText>}
                </div>
            );
        }
    }

    const fields: BankCreateField[] = [
        {
            name: 'account_number',
            label: t('label_account_number'),
            visible: isCurrencyChosen,
            collapse: false,
        },
        {
            name: 'owner_name',
            label: t('label_owner_name'),
            visible: isCurrencyChosen && !CZKChosen,
            collapse: false,
        },
        {
            name: 'owner_address',
            label: t('label_owner_address'),
            visible: isCurrencyChosen && !CZKChosen,
            collapse: false,
        },
        {
            name: 'owner_country',
            visible: isCurrencyChosen && !CZKChosen,
            component: (
                <CountrySelector
                    name='owner_country'
                    errorMessage={errors.owner_country}
                    value={values.owner_country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t('label_owner_country')}
                    fullWidth
                />
            ),
        },
        {
            name: 'owner_city',
            label: t('label_owner_city'),
            visible: isCurrencyChosen && !CZKChosen,
            collapse: false,
        },
        {
            name: 'account_vs',
            label: t('label_account_vs'),
            visible: isCurrencyChosen && CZKChosen,
            collapse: true,
        },
        {
            name: 'account_specific',
            label: t('label_account_specific'),
            visible: isCurrencyChosen && CZKChosen,
            collapse: true,
        },
        {
            name: 'account_constant',
            label: t('label_account_constant'),
            visible: isCurrencyChosen && CZKChosen,
            collapse: true,
        },
        {
            name: 'message',
            label: t('label_message'),
            hint: t('client_bank_account_message_warning_about_symbol'),
            visible: isCurrencyChosen,
            collapse: CZKChosen,
        },
    ];

    return (
        <div>
            {fields.map(mapEachField)}
            {collapsedField && CZKChosen && (
                <>
                    <Stack direction='row' sx={{marginLeft: `14px`, cursor: 'pointer'}} justifyContent='flex-start'>
                        <Typography color={theme.cool.grey70} variant='textS' onClick={handleExpandClick}>
                            {t('exchange_form_expand_optional_fields')}
                        </Typography>
                    </Stack>
                    <Collapse in={expanded} timeout='auto' unmountOnExit>
                        {fields.map(collapsedField)}
                    </Collapse>
                </>
            )}
        </div>
    );
}
