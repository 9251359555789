import {useSearchParams} from 'react-router-dom';

/**
 * Returns object with keys and values of params
 * use as: const {param1. param2} = useSearchParameters();
 */
export default function useSearchParameters(): {[name: string]: string} {
    const [searchParams] = useSearchParams();
    // instead of iterating over entries, this returns object with key-value pairs
    return Object.fromEntries([...searchParams]);
}
