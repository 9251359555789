import {Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import React from 'react';
import dayjs from '../../config/dayjs';
import {getSimplifiedOrderStatus2} from '~/helpers';

interface OrderHistoryItem {
    id: number;
    status_name: string;
    created_at: number;
}

export default function OrderHistoryWidget({orderHistory}: {orderHistory: Array<OrderHistoryItem>}) {
    const {t} = useTranslation();

    function mapHistory(item: OrderHistoryItem, key) {
        const simplifiedOrderStatus = getSimplifiedOrderStatus2(item.status_name);
        return (
            <TableRow key={key}>
                <TableCell>{dayjs.unix(item.created_at).format('LLL')}</TableCell>

                <TableCell>{t('order_status_' + simplifiedOrderStatus)}</TableCell>
            </TableRow>
        );
    }

    return (
        <Card>
            <CardHeader title={t('order_detail_history_table_header')} />
            <CardContent>
                <Table>
                    <TableBody>{orderHistory.map(mapHistory)}</TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
