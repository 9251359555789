import React from 'react';
import {styled} from '@mui/material';
import {CustomIcon} from '../../typings/IconsTypings';
import arrowIcon from '../../assets/images/order-arrow-icon.svg';

const ArrowIcon = styled('img')`
    height: 32px;
    transform: rotate(90deg);
`;

export const SimpleArrowDownIcon = ({height = 32, width = 'auto'}: CustomIcon) => {
    return <ArrowIcon src={arrowIcon} height={height} width={width} />;
};
