import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import spayd from 'spayd';
import {useSelector} from 'react-redux';
import QrCode from 'qrcode.react';
import {Currency} from '~/typings/currency';
import {Stack, Typography, Box, MenuItem, TextField, Skeleton, Link} from '@mui/material';
import {fetchCompanyBankAccounts} from '~/redux/orderSlice';
import {RootState, useCoreAppDispatch} from '~/redux/store';
import useBackendSettings from '../../hooks/useBackendSettings';
import DetailView from '../DetailView';
import {Settings} from '~/typings/settings';
import CustomSelect from '../CustomSelect';
import {useCurrency} from '~/hooks/currency';
import {formatAmount} from '~/helpers';
import {CompanyBank} from '~/typings/Order';

export interface FiatPaymentInstructionsProps {
    note: string | null;
    currency?: Currency;
    amount?: string;
    allowAmountChange?: boolean; // if present, let amount be changed
    qrLogoSrc: string;
    showWalletBankAccounts?: boolean;
}

interface CompanyBankWithIndex extends CompanyBank {
    index: number;
}

/**
 * Renders incoming fiat component which shows list of bank accounts where user can send money and what bank not should
 * be included by the transaction.
 *
 * @param {string | null} note Note client should include in the transaction.
 * @param {string} amount Expecting amount.
 * @param allowAmountChange
 * @param {Currency | undefined} currency  Currency of the amount. If undefined, any currency will be selectable! It is meant only for verification payment where amount is the same (1CZK/1EUR)
 * @param {string | undefined} qrLogoSrc
 * @param showWalletBankAccounts
 */
function FiatPaymentInstructions({
    note,
    amount,
    allowAmountChange,
    currency,
    qrLogoSrc,
    showWalletBankAccounts = false,
}: FiatPaymentInstructionsProps) {
    const {t, i18n} = useTranslation();
    const {companyBanks} = useSelector((state: RootState) => state.order);
    const [selectedBank, setSelectedBank] = useState<CompanyBankWithIndex | undefined>();
    const [paymentAmount, setPaymentAmount] = useState<string | undefined>(amount);
    const dispatch = useCoreAppDispatch();
    const isCzk = selectedBank?.currency_code.toUpperCase() === 'CZK';
    const usedCurrency = useCurrency({code: selectedBank?.currency_code.toUpperCase()});
    const settings: Settings = useBackendSettings();
    const companyName = settings.companyName;
    const [showIbanInstructions, setshowIbanInstructions] = useState<boolean>(false);

    const banks: CompanyBankWithIndex[] = useMemo(() => {
        let innerBanks: CompanyBankWithIndex[] =
            companyBanks?.response?.[0].map((bank, index) => {
                return {...bank, index};
            }) ?? [];

        if (currency) {
            innerBanks = innerBanks.filter((bank) => bank.currency_id === currency.id);
        }

        if (showWalletBankAccounts) {
            innerBanks = innerBanks.filter((bank) => bank.is_for_wallet);
        } else {
            innerBanks = innerBanks.filter((bank) => !bank.is_for_wallet);
        }

        return innerBanks;
    }, [companyBanks?.response, currency]);

    useEffect(() => {
        if (banks.length) {
            setSelectedBank(banks[0]);
        }
    }, [banks]);

    useEffect(() => {
        dispatch(fetchCompanyBankAccounts());
    }, []);

    /**
     * Returns bank select item
     */
    function getBank(bank: CompanyBankWithIndex) {
        return (
            <MenuItem key={bank.index} value={bank.index}>
                {`${bank.name} (${bank.currency_code})`}
            </MenuItem>
        );
    }

    const handleAmountChange = (e) => {
        setPaymentAmount(e.target.value);
    };

    const paymentInfo = useMemo(() => {
        if (selectedBank && paymentAmount) {
            return {
                acc: selectedBank?.iban,
                ['am']: parseFloat(paymentAmount).toFixed(2).toString() ?? undefined, // can optionally be absent
                cc: selectedBank.currency_code,
                msg: note ?? undefined,
                dt: new Date(),
                pt: 'IP', // instant payment by default
                rn: companyName,
            };
        }
    }, [selectedBank, paymentAmount]);

    let spaydString = useMemo(() => {
        if (paymentInfo) {
            try {
                return spayd(paymentInfo);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn(e);
            }
        }
        return '';
    }, [paymentInfo]);

    try {
        spaydString = paymentInfo ? spayd(paymentInfo) : '';
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
    }

    const imgHeight = 21;
    const imgWidth = 156;

    const imageSettings = {
        src: qrLogoSrc ?? '',
        height: imgHeight,
        width: imgWidth,
        x: undefined,
        y: undefined,
        excavate: true,
    };

    /**
     * Sets selected bank
     * @param e
     */
    function onBankSelectChange(e) {
        const selectedIndex = e.target.value;
        const newBank = banks.find((bank) => bank.index === selectedIndex);
        setSelectedBank(newBank);
    }

    const ibanPaymentInformation = [
        {
            label: t('order_form_payment_instructions_bank_account_number_label'),
            value: selectedBank?.iban,
            copyButton: true,
        },
        {
            label: t('order_form_payment_instructions_bank_message_label'),
            value: note,
            copyButton: true,
        },
        {
            label: t('fiat_payment_recipient_name'),
            value: settings.companyName,
            copyButton: true,
        },
        {
            label: t('fiat_payment_recipient_street'),
            value: settings.companyStreet,
            copyButton: true,
        },
        {
            label: t('fiat_payment_recipient_city'),
            value: settings.companyCity,
            copyButton: true,
        },
        {
            label: t('fiat_payment_recipient_country'),
            value: settings.companyCountry,
            copyButton: true,
        },
    ];

    const czechPaymentInformation = [
        {
            label: t('fiat_payment_instructions_amount_label'),
            value: `${formatAmount(paymentAmount ?? 0, usedCurrency?.fixed_decimals ?? 2, usedCurrency?.name)} ${
                usedCurrency?.name
            }`,
        },
        {
            label: t('order_form_payment_instructions_bank_account_number_label'),
            value: `${selectedBank?.account_number}/${selectedBank?.bank_code}`,
            copyButton: true,
        },
        {
            label: t('order_form_payment_instructions_bank_message_label'),
            value: note,
            copyButton: true,
        },
    ].slice(allowAmountChange ? 1 : 0); // If the change of amount is allowed then the amount is not part of the view. It has own editable field.

    const eurPaymentInformation = [
        {
            label: t('fiat_payment_instructions_amount_label'),
            value: `${formatAmount(paymentAmount ?? 0, usedCurrency?.fixed_decimals ?? 2, i18n.language)} ${
                usedCurrency?.name
            }`,
        },
        ...ibanPaymentInformation,
    ].slice(allowAmountChange ? 1 : 0); // If the change of amount is allowed then the amount is not part of the view. It has own editable field.

    if (currency && banks.length === 0) {
        return (
            <Stack>
                <Typography variant='body2'>
                    {t('sorry_no_bank_for_given_currency', {currency: currency.name})}
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack direction={{xs: 'column', md: 'row'}} spacing={8} padding={{xs: 0, md: 0}}>
            <Stack direction='column' alignItems='flex-start' spacing={4} sx={{width: 1}}>
                {/*<Typography variant='h3'>{t('payment_instructions')}</Typography>*/}
                {banks.length > 1 && (
                    <Stack spacing={1} sx={{width: 1}}>
                        <Typography variant='body2' textAlign='left'>
                            {t('make_payment_to_one_of_our_accounts')}
                        </Typography>
                        <CustomSelect
                            name='bank-account'
                            id='bank-account'
                            label=''
                            renderOption={getBank}
                            options={banks}
                            onChange={onBankSelectChange}
                            native={false}
                            value={selectedBank?.index ?? ''}
                        />
                    </Stack>
                )}
                {allowAmountChange && (
                    <TextField
                        label={t('fiat_payment_instructions_amount_label')}
                        value={paymentAmount}
                        onChange={handleAmountChange}
                        id='amount'
                        fullWidth
                        InputProps={{
                            readOnly: !allowAmountChange,
                            inputMode: 'numeric',
                            endAdornment: selectedBank?.currency_code,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={isCzk && !paymentAmount && t('amount_in_fiat_payment_instructions_info')}
                    />
                )}
                {isCzk ? (
                    <>
                        <DetailView items={czechPaymentInformation} valueMaxLength={0} />
                        <Link
                            component='button'
                            variant='body2'
                            color='secondary'
                            onClick={() => setshowIbanInstructions(!showIbanInstructions)}
                        >
                            {t('iban_payment_instructions')}
                        </Link>
                        {showIbanInstructions && <DetailView items={ibanPaymentInformation} valueMaxLength={0} />}
                    </>
                ) : (
                    <DetailView items={eurPaymentInformation} valueMaxLength={0} />
                )}
            </Stack>
            {isCzk && (
                <Box
                    display='flex'
                    sx={{
                        spacing: {xs: 2, md: 4},
                        flexGrow: 1,
                        direction: 'column',
                        alignItems: {xs: 'center', md: 'flex-start'},
                        justifyContent: {xs: 'center', md: 'flex-start'},
                        width: '100%',
                    }}
                >
                    <Box sx={{background: '#FAF9FF', padding: '16px', borderRadius: '20px'}}>
                        {spaydString ? (
                            <QrCode size={200} value={spaydString} imageSettings={imageSettings} level='Q' />
                        ) : (
                            <Skeleton height={200} width={200} />
                        )}
                    </Box>
                </Box>
            )}
        </Stack>
    );
}

export default FiatPaymentInstructions;
