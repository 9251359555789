import i18n, {InitOptions} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {TEST_LANGUAGE_CODE} from './localParams';

import cs from '../localization/cs.json';
import en from '../localization/en.json';
import es from '../localization/es.json';
import pl from '../localization/pl.json';
import ru from '../localization/ru.json';
import sk from '../localization/sk.json';
import vi from '../localization/vi.json';
import de from '../localization/de.json';
import it from '../localization/it.json';
import uk from '../localization/uk.json';
import {ensureLanguageOnly, getConfigValue} from '@simplecoin/core';
import store from '../../src/redux/store';

/**
 * Waits for settings (checks every 5ms)
 */
export function waitAndReturnSettings(callback) {
    const state = store.getState();
    const settings = state.site.settings;

    if (settings.settingsData) {
        callback(settings.settingsData);
    } else {
        setTimeout(() => {
            waitAndReturnSettings(callback);
        }, 5);
    }
}

const languageResources = {
    cs: {
        simplecoin_web: cs,
    },
    en: {
        simplecoin_web: en,
    },
    es: {
        simplecoin_web: es,
    },
    pl: {
        simplecoin_web: pl,
    },
    ru: {
        simplecoin_web: ru,
    },
    sk: {
        simplecoin_web: sk,
    },
    vi: {
        simplecoin_web: vi,
    },
    de: {
        simplecoin_web: de,
    },
    it: {
        simplecoin_web: it,
    },
    uk: {
        simplecoin_web: uk,
    },
};

const defaultNamespace = 'simplecoin_web';

// this code may be invoked before settings are loaded, so callback is needed here
waitAndReturnSettings((settings) => {
    const languages = settings.languages ?? [];
    let whitelist = languages.length ? languages.map((lang) => ensureLanguageOnly(lang)) : false;
    whitelist =
        getConfigValue('environment') === 'development' && languages.length
            ? [...whitelist, TEST_LANGUAGE_CODE]
            : whitelist;
    const options: InitOptions = {
        returnEmptyString: false,
        fallbackLng: false,
        debug: getConfigValue('environment') === 'development',

        resources: languageResources,

        // have a common namespace used around the full app
        ns: [defaultNamespace],
        defaultNS: defaultNamespace,
        supportedLngs: whitelist,
        load: 'languageOnly', // search only for en without region
        cleanCode: true, // EN -> en

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false,
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'p', 'b'],
        },

        initImmediate: false,

        detection: {
            // order and from where user language should be detected
            order: ['path', 'localStorage', 'cookie', 'navigator'],

            // keys or params to lookup language from
            lookupCookie: 'rlang',
            lookupLocalStorage: 'rlang',
            lookupFromPathIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            cookieMinutes: 365 * 24 * 60,
            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            cookieOptions: {sameSite: 'strict'},
        },
    };

    i18n.use(LanguageDetector).use(initReactI18next).init(options);
});

/**
 * Loads, adds specified language to resources and only then changes language
 * @param {string} languageCode
 */
export function changeI18nLanguage(languageCode: string) {
    const languageWithoutRegion = ensureLanguageOnly(languageCode);
    i18n.changeLanguage(languageWithoutRegion);
}

/**
 * Checks if the specified bundle already is in the i18n redux
 * @param {string} languageCode
 * @return {boolean}
 */
export function localeAvailable(languageCode: string) {
    return i18n.hasResourceBundle(languageCode, defaultNamespace);
}

export default i18n;
