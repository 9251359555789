import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchGeneric, handleReducerCases, ThunkApi, ReducerEnvelope} from '@simplecoin/core';

export type TradingVolumePeriod = 'month' | 'quarter' | 'semi-year' | 'year';

export interface ExpectedTradingVolume {
    amount: string;
    currency: string;
    period: TradingVolumePeriod;
}

/** This interface ecapsulates pile of optional fields that are filled based on source of income. */
export interface IncomeExtra {
    addressOfProperty?: string;
    companyId?: string;
    companyName?: string;
    dateOfAcceptance?: string;
    dateOfSale?: string;
    description?: string;
    donor?: string;
    donorRelationshipToRecipient?: string;
    fieldOfBusiness?: string;
    inheritedFrom?: string;
    positionInCompany?: string;
    purposeOfDonation?: string;
    relationshipToTestator?: string;
    saleChannel?: string;
    sourceOfDonatedFunds?: string;
}

export type IncomeFrequency = 'one-off' | 'monthly' | 'quarterly' | 'semi-yearly' | 'yearly';

export type IncomeStatus = 'draft' | 'readyForApproval' | 'approved' | 'revoked';

export interface Income {
    uuid?: string;
    createdAt?: number;
    status: IncomeStatus;
    revokedReason?: string;
    amount: number | null;
    currency: string;
    frequency: IncomeFrequency;
    source: string;
    description?: string;

    /** A flag marking the income as a deleted by client. */
    deleted?: boolean;
    extra?: IncomeExtra;
}

export interface Incomes {
    readyForApproval?: boolean;
    incomes: Array<Income>;
}

export interface IncomesInterface {
    list: ReducerEnvelope<Incomes>;
    createIncomeResponse: ReducerEnvelope<null>;
    updateIncomeResponse: ReducerEnvelope<null>;
    deleteIncomeResponse: ReducerEnvelope<null>;
    submitForApprovalResponse: ReducerEnvelope<null>;
    expectedTradingVolume?: ExpectedTradingVolume;
}

const initialState: IncomesInterface = {
    list: {isFetching: false},
    createIncomeResponse: {isFetching: false},
    updateIncomeResponse: {isFetching: false},
    deleteIncomeResponse: {isFetching: false},
    submitForApprovalResponse: {isFetching: false},
};

export const listIncomes = createAsyncThunk<ReducerEnvelope<Incomes>>('income/list', async (_props, thunkApi) => {
    const response = await fetchGeneric(
        {
            url: '/income/list',
        },
        thunkApi as ThunkApi
    );

    return response.data;
});

export const createIncome = createAsyncThunk<ReducerEnvelope<null>, Income>(
    'income/create',
    async (props, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: '/income/create',
                data: {...props, '@type': 'src.services.verification.income.IncomeRequest'},
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

export const updateIncome = createAsyncThunk<ReducerEnvelope<null>, Income>(
    'income/update',
    async (props, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: '/income/update',
                params: {uuid: props.uuid},
                data: {...props, '@type': 'src.services.verification.income.IncomeRequest'},
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

export const deleteIncome = createAsyncThunk<ReducerEnvelope<null>, string>(
    'income/delete',
    async (props, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: '/income/delete',
                params: {uuid: props},
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

export const submitIncomeForApproval = createAsyncThunk<ReducerEnvelope<null>, ExpectedTradingVolume | undefined>(
    'income/submitForApproval',
    async (props, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: '/income/submit-for-approval',
                data: isNaN(parseFloat(props?.amount ?? ''))
                    ? undefined
                    : {...props, '@type': 'src.services.verification.tradeVolume.TradeVolumeRequest'},
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

const slice = createSlice({
    name: 'incomes',
    initialState,
    extraReducers: (builder) => {
        handleReducerCases(listIncomes, 'list', builder);
        handleReducerCases(createIncome, 'createIncomeResponse', builder);
        handleReducerCases(updateIncome, 'updateIncomeResponse', builder);
        handleReducerCases(deleteIncome, 'deleteIncomeResponse', builder);
        handleReducerCases(submitIncomeForApproval, 'submitForApprovalResponse', builder);
    },
    reducers: {
        invalidateCreateIncome: (state) => {
            state.createIncomeResponse = initialState.createIncomeResponse;
        },
        invalidateUpdateIncome: (state) => {
            state.updateIncomeResponse = initialState.updateIncomeResponse;
        },
        setExpectedTradingVolume: (state, action: PayloadAction<ExpectedTradingVolume>) => {
            state.expectedTradingVolume = action.payload;
        },
    },
});

export const {actions, reducer} = slice;
export const {invalidateCreateIncome, invalidateUpdateIncome, setExpectedTradingVolume} = slice.actions;
