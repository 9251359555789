import React, {useEffect, useRef, useState} from 'react';
import QrScan from 'qr-scanner';
import {styled, useMediaQuery} from '@mui/material';
import {QrScannerProps} from '~/typings/QrScannerTypings';

let qrScanner: QrScan | null = null;

const VideoWrapper = styled('div')(({theme}) => ({
    '.scan-region-highlight-svg': {
        stroke: '#FD6300 !important',
    },

    '.scan-region-highlight': {
        borderRadius: '10px',
        outline: 'rgba(0, 0, 0, .25) solid 50vmax',
    },

    [theme.breakpoints.up('md')]: {},
}));

/**
 * Renders QR Code scanner element
 * @param onSuccessfulScan callback that will be invoked after successful scanning event. The param of this callback
 * will be an object like the following:
 * { address: "tb1qq0nlktq22854tv4lrw6jenpgmcjg5hrvxypnj0", options: {labal: "some label"}, currency: "6" }
 * @param onError
 * @param showViewFinder
 * @return {*}
 * @constructor
 */
export default function QrScanner({onSuccessfulScan, onError, width = 600}: QrScannerProps) {
    const [scanResult, setScanResult] = useState();
    const videoRef = useRef<HTMLVideoElement>(null);
    const isMobile = useMediaQuery('(max-width:900px)');

    const videoWidth = isMobile ? 300 : width;
    const videoHeight = isMobile ? 300 : 'auto';

    useEffect(() => {
        return () => {
            qrScanner?.destroy?.();
            qrScanner = null;
        };
    }, []);

    useEffect(() => {
        if (scanResult) {
            qrScanner?.stop();

            onSuccessfulScan(scanResult);
        }
    }, [scanResult, qrScanner]);

    function onScan(result) {
        setScanResult(result.data);
    }

    useEffect(() => {
        if (videoRef.current && !qrScanner) {
            qrScanner = new QrScan(videoRef.current, onScan, {
                highlightCodeOutline: true,
                highlightScanRegion: true,
                returnDetailedScanResult: true,
            });

            qrScanner.start().catch((reason) => {
                onError?.(reason);
            });
        }
    }, [videoRef.current]);

    return (
        <VideoWrapper>
            <video ref={videoRef} muted autoPlay playsInline preload='none' width={videoWidth} height={videoHeight} />
        </VideoWrapper>
    );
}

QrScanner.displayName = 'QrScanner';
