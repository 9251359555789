import {AxiosPromise} from 'axios';
import {fetchItems} from '~/src/helpers/action';

export const REQUEST_RECIPIENTS = 'REQUEST_RECIPIENTS';
export const REQUEST_RECIPIENTS_SUCCESS = 'REQUEST_RECIPIENTS_SUCCESS';
export const REQUEST_RECIPIENTS_FAILURE = 'REQUEST_RECIPIENTS_FAILURE';

/**
 * Fetch recipients - bank accounts and wallets
 */
export function fetchRecipients({pagination, currencyId}): (dispatch: any) => AxiosPromise {
    const query = currencyId ? {filter: {currency_id: currencyId}} : undefined;

    return fetchItems({
        url: 'recipient?expand=currency',
        pagination,
        query,
        actions: {
            pre: REQUEST_RECIPIENTS,
            success: REQUEST_RECIPIENTS_SUCCESS,
            failure: REQUEST_RECIPIENTS_FAILURE,
        },
    });
}
