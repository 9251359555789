import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Link,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {t} from 'i18next';
import React, {useEffect, useState} from 'react';
import kycImage from '../../assets/svg/kyc.svg';
import {submitWalletConsent} from '~/src/redux/slices/profileSlice';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '~/src/redux/store';
import getLink from '~/src/config/links';

const PurpleBall = styled('div')`
    border-radius: 50%;
    position: absolute;
    width: 96px;
    height: 96px;
    right: -52px;
    top: 104px;
    background: linear-gradient(180deg, #41277e 23.65%, #f4f2ff 113.33%);
`;

const OrangeBall = styled('div')`
    border-radius: 50%;
    position: absolute;
    width: 204px;
    height: 204px;
    bottom: -34px;
    left: -102px;
    background: linear-gradient(200.04deg, #fd6300 37.65%, #fff1e7 84.29%);
`;

export default function VerificationRequest() {
    const profileData = useSelector((state: RootState) => state.profile?.data?.response);
    const submitWalletConsentResponse = useSelector((state: RootState) => state.profile?.submitWalletConsent);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Checked consent checkbox
    const [checked, setChecked] = useState<boolean>(profileData?.wallet_policy_consent ?? false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (
            submitWalletConsentResponse.status === 'ok' &&
            !submitWalletConsentResponse.isFetching &&
            submitWalletConsentResponse.response
        ) {
            setChecked(true);
        }
    }, [submitWalletConsentResponse?.response]);

    const handleCheckboxChange = (e) => {
        if (checked !== e.target.checked) {
            if (e.target.checked) {
                dispatch(submitWalletConsent());
            }
        }
    };

    return (
        <Card sx={{position: 'relative'}}>
            <CardContent>
                <Stack spacing={5} alignItems='center' sx={{my: 5}}>
                    <Typography align='center' variant='h2'>
                        {t('verification_request_line2')}
                    </Typography>
                    <Box>
                        <img src={kycImage} alt='KYC' height={130} />
                    </Box>
                    <Stack spacing={2} sx={{width: {xs: '100%', md: '80%', lg: '60%'}}}>
                        <Typography align='center'>{t('why_we_need_verification_info')}</Typography>
                        <Typography align='center'>{t('verification_request_line2')}</Typography>
                    </Stack>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                                color='primary'
                                disabled={checked}
                            />
                        }
                        label={
                            <Trans
                                components={[<Link key={0} href={getLink('wallet-terms-of-services')} />]}
                                i18nKey='wallet_welcome_page_condions'
                            />
                        }
                    />
                    <Box>
                        <Button variant='outlined' size='large' href='/profile' disabled={!checked}>
                            {t('proceed_to_verification_action')}
                        </Button>
                    </Box>
                </Stack>
                {!isMobileScreen && <OrangeBall />}
                {!isMobileScreen && <PurpleBall />}
            </CardContent>
        </Card>
    );
}
