import {useNavigate, To, NavigateOptions} from 'react-router-dom';
import {ensureLanguageOnly} from '../helpers/language';
import {ensureLanguageInPath} from '../helpers/url';
import {useTranslation} from 'react-i18next';

export type NavigateFunc = (to: To, options?: NavigateOptions) => void;

/**
 * Hook that returns a modified navigate function that adds locale in front of path
 *
 * navigate('orders') will navigate to en/orders if en is the user language
 */
export default function useLocalizedNavigate(): NavigateFunc {
    const originalNavigate = useNavigate();
    const {i18n} = useTranslation();

    const modifiedNavigate = (to: To, options?: NavigateOptions) => {
        const url: string = typeof to === 'string' ? to : '';
        const language = ensureLanguageOnly(i18n.language);
        const urlWithLanguage = ensureLanguageInPath({path: url, language});
        originalNavigate(urlWithLanguage, options);
    };

    return modifiedNavigate;
}
