import React from 'react';
import {styled, CircularProgress, CircularProgressProps} from '@mui/material';

const Wrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const CenteredCircularProgress = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
`;

export interface LoaderProps extends CircularProgressProps {
    inline?: boolean;
    center?: boolean;
}

/**
 * Renders loader.
 *
 * Notice: of wrap and center is used, wrap is absolutely positioned element, which should be placed within relative
 * container element, otherwise it would take as much space as possible.
 *
 * @param {bool} inline
 * @param {bool} center
 * @param props
 * @return {*}
 * @constructor
 */
export default function Loader({inline = false, center = true, ...props}: LoaderProps) {
    const animatedLoader =
        !inline && center ? <CenteredCircularProgress {...props} /> : <CircularProgress {...props} />;

    if (!inline && center) {
        return <Wrapper>{animatedLoader}</Wrapper>;
    }

    return animatedLoader;
}

Loader.displayName = 'Loader';
