import React, {forwardRef, ReactElement, useMemo} from 'react';
import {
    InputLabel,
    FormHelperText,
    FormControl,
    useMediaQuery,
    ListSubheader,
    MenuItem,
    styled,
    selectClasses,
    Select as BaseSelect,
} from '@mui/material';
import {BaseSelectProps, OutlinedSelectProps} from '@mui/material/Select/Select';

type CustomSelectPropsToExtend = OutlinedSelectProps & BaseSelectProps;
export interface CustomSelectProps extends CustomSelectPropsToExtend {
    errorMessage?: string;
    hintMessage?: string;
    renderOption: (item: any, key: number, isNative: boolean) => React.ReactElement;
    options?: Array<any>;
    allOptionsGroupLabel?: 'string';
    preferredOptions?: Array<any> | null;
    preferredOptionsGroupLabel?: 'string';
    appendEmptyOption?: boolean;
    /** Label for the empty value */
    emptyOptionLabel?: string;
    labelShrink?: boolean;
    OptGroupElement?: HTMLElement;
}

const BaseStyledSelect = styled(BaseSelect)`
    .${selectClasses.select} {
        display: flex;
    }
`;

/**
 * Presentational component to render button/button-link element. The link will have appended locale and not cause additional redirect
 */
const CustomSelect = forwardRef(
    (
        {
            label,
            name,
            options = [],
            value,
            allOptionsGroupLabel,
            preferredOptions = [],
            preferredOptionsGroupLabel,
            renderOption,
            errorMessage,
            hintMessage,
            fullWidth = true,
            appendEmptyOption = false,
            emptyOptionLabel,
            labelShrink = true,
            children,
            ...props
        }: CustomSelectProps,
        ref
    ): ReactElement => {
        const hasError = !!errorMessage;
        const isMobile = useMediaQuery('(max-width:900px)');
        const renderAsNative = props.native || (isMobile && props.native === undefined);

        const emptyOption = renderAsNative ? (
            <option aria-label='None' value=''>
                {emptyOptionLabel ?? ' '}
            </option>
        ) : (
            <MenuItem value=''>{emptyOptionLabel ?? ' '}</MenuItem>
        );
        const renderedPreferredOptions = useMemo(
            () => preferredOptions?.map((item, i) => renderOption(item, i, isMobile)) ?? [],
            [preferredOptions, isMobile]
        );
        const lastKey = renderedPreferredOptions.length + 1; // make sure keys are unique

        let renderedOptions = useMemo(
            () => options.map((item, i) => renderOption(item, i + lastKey, isMobile)),
            [options, isMobile]
        );

        // if only options, empty option should be part of options group
        if (appendEmptyOption && !preferredOptions?.length) {
            renderedOptions = [emptyOption, ...renderedOptions];
        }

        //todo: test err with formik, replace usages in react-fe

        const preferredOptionsGroup = renderAsNative ? (
            <optgroup key={0} label={preferredOptionsGroupLabel}>
                {preferredOptions}
            </optgroup>
        ) : (
            [<ListSubheader key={0}>{preferredOptionsGroupLabel}</ListSubheader>, renderedPreferredOptions]
        );

        const allOptionsGroup = renderAsNative ? (
            <optgroup key={1} label={allOptionsGroupLabel}>
                {renderedOptions}
            </optgroup>
        ) : (
            [<ListSubheader key={1}>{allOptionsGroupLabel}</ListSubheader>, renderedOptions]
        );

        let resultOptions = preferredOptions?.length ? [preferredOptionsGroup, allOptionsGroup] : renderedOptions;

        if (appendEmptyOption && preferredOptions?.length) {
            // if preferred options are provided, the empty option should be a separate group
            resultOptions = [emptyOption, ...resultOptions];
        }

        return (
            <FormControl error={hasError} required={props.required} disabled={props.disabled} fullWidth={fullWidth}>
                {label && (
                    <InputLabel id={`${name}-label`} shrink={labelShrink}>
                        {label}
                    </InputLabel>
                )}

                <BaseStyledSelect
                    label={label}
                    name={name}
                    labelId={`${name}-label`}
                    native={renderAsNative}
                    value={value ?? ''}
                    displayEmpty
                    notched
                    {...props}
                    ref={ref}
                >
                    {resultOptions}
                    {children}
                </BaseStyledSelect>

                {hasError && <FormHelperText>{errorMessage}</FormHelperText>}

                {hintMessage && <FormHelperText error={false}>{hintMessage}</FormHelperText>}
            </FormControl>
        );
    }
);

CustomSelect.displayName = 'CustomSelect';

export default CustomSelect;
