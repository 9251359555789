import {combineReducers} from 'redux';
import ticket, {TicketReducersInterface} from '../pages/ticket/TicketReducers';
import affiliate, {AffiliateReducersInterface} from '../pages/affiliate/AffiliateReducers';

export interface ModuleReducersInterface {
    ticket: TicketReducersInterface;
    affiliate: AffiliateReducersInterface;
}

export default combineReducers<ModuleReducersInterface>({
    ticket,
    affiliate,
});
