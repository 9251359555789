import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ListResponse, ReducerEnvelope} from '~/typings/Api';
import {
    CompanyBanks,
    LiveSuspend,
    Order,
    OrderOffer,
    OrderStatusName,
    STATUS_CANCELLED,
    STATUS_PROCEED,
    STATUS_RETURN_TO_CLIENT,
    STATUS_SUSPENDED,
} from '~/typings/Order';
import {handleReducerCases} from '~/helpers/redux';
import {PaginatedPropsWithQuery} from '~/typings/Pagination';
import {downloadFile, fetchGeneric, ThunkApi} from '~/api';
import {AxiosResponse} from 'axios';

/**
 * Represents structure returned from order endpoint.
 */
export interface Orders extends ListResponse {
    items: Order[];
}

export interface StatusChangeEnvelope extends ReducerEnvelope<null> {
    statusName?: OrderStatusName;
}

export interface OrderReducerInterface {
    list: ReducerEnvelope<Orders>;
    detail: ReducerEnvelope<Order>;
    companyBanks: ReducerEnvelope<CompanyBanks>;
    statusChange: StatusChangeEnvelope;
    liveSuspend: ReducerEnvelope<LiveSuspend>;
    updateLimit: ReducerEnvelope<{message: string}>;
    removeLimit: ReducerEnvelope<null>;
    acceptPrice: ReducerEnvelope<null>;
    returnFunds: ReducerEnvelope<null>;
    orderClaim: ReducerEnvelope<null>;
    guestOrderClaim: ReducerEnvelope<null>;
    offer: ReducerEnvelope<{email: string; offer: OrderOffer}>;
}

const initialState: OrderReducerInterface = {
    list: {isFetching: false},
    detail: {isFetching: false},
    companyBanks: {isFetching: false},
    statusChange: {isFetching: false},
    liveSuspend: {isFetching: false},
    updateLimit: {isFetching: false},
    removeLimit: {isFetching: false},
    acceptPrice: {isFetching: false},
    returnFunds: {isFetching: false},
    orderClaim: {isFetching: false},
    guestOrderClaim: {isFetching: false},
    offer: {isFetching: false},
};

export interface GenericOrderProps {
    token?: string;
    id?: number;
}

export interface ChangeRefundAddressProps {
    addressId?: number;
    orderId?: number;
}

export const listOrders = createAsyncThunk<ReducerEnvelope<Order>, PaginatedPropsWithQuery>(
    'order/list',
    async (props, thunkApi) => {
        const defaultQuery = {
            expand: ['fromCurrency', 'toCurrency', 'bankAccount', 'cryptoAccount'].join(','),
        };

        const response = await fetchGeneric(
            {
                url: 'order',
                params: {...defaultQuery, ...props.query, ...props.pagination},
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

export const fetchChangeRefundAddress = createAsyncThunk<ReducerEnvelope<Order>, ChangeRefundAddressProps>(
    'order/returnFunds',
    async ({orderId, addressId}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: 'order/refund-address',
                params: {id: orderId},
                data: {refund_address_id: addressId},
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export const fetchOrder = createAsyncThunk<ReducerEnvelope<Order>, GenericOrderProps>(
    'order/detail',
    async ({token, id}, thunkApi) => {
        const expandQuery =
            'fromCurrency,toCurrency,bankAccount,incomingCryptoTransaction,outgoingCryptoTransaction,incomingBankTransaction,outgoingBankTransaction,cryptoAccount,statusLogs,incomingBanks,refundAddress';

        const res = await fetchGeneric(
            {
                url: 'order/view',
                params: {token, id: token ? undefined : id, expand: expandQuery},
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export const refetchOrder = createAsyncThunk<ReducerEnvelope<Order>, GenericOrderProps>(
    'order/detail-update',
    async ({token, id}, thunkApi) => {
        const expandQuery =
            'fromCurrency,toCurrency,bankAccount,incomingCryptoTransaction,outgoingCryptoTransaction,incomingBankTransaction,outgoingBankTransaction,cryptoAccount,statusLogs,incomingBanks,refundAddress';

        const res = await fetchGeneric(
            {
                url: 'order/view',
                params: {token, id: token ? undefined : id, expand: expandQuery},
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export const fetchDownloadOrder = createAsyncThunk<void, GenericOrderProps>(
    'order/download',
    async ({token, id}, thunkApi) => {
        await fetchGeneric(
            {
                method: 'post',
                url: 'order/download',
                params: {token, id: token ? undefined : id},
                responseType: 'arraybuffer',
            },
            thunkApi as ThunkApi
        ).then((response: AxiosResponse) => {
            downloadFile(response);
        });
    }
);

// todo: make sure it can do silent update
export const fetchLiveSuspended = createAsyncThunk(
    'order/liveSuspend',
    async ({token, id}: GenericOrderProps, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'order/live-suspend',
                params: {token, id: token ? undefined : id},
            },
            thunkApi as ThunkApi
        );
        return response.data;
    }
);

export interface FetchUpdateLimitProps {
    id?: number;
    token?: string;
    rate?: number;
    rateInverse?: number;
}

export const fetchUpdateLimit = createAsyncThunk<void, FetchUpdateLimitProps>(
    'order/updateLimit',
    async ({token, id, rate, rateInverse}, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'order/limit',
                params: {token, id: token ? undefined : id},
                data: {rate, rateInverse},
            },
            thunkApi as ThunkApi
        );
        return response.data;
    }
);

export const fetchRemoveLimit = createAsyncThunk<void, GenericOrderProps>(
    'order/removeLimit',
    async ({token, id}, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'order/remove-limit',
                params: {token, id: token ? undefined : id},
            },
            thunkApi as ThunkApi
        );
        return response.data;
    }
);

export const fetchAcceptPrice = createAsyncThunk<void, GenericOrderProps>(
    'order/accept',
    async ({token, id}, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'order/accept',
                params: {token, id: token ? undefined : id},
            },
            thunkApi as ThunkApi
        );
        return response.data;
    }
);

interface ClaimOrderProps {
    token: string;
    email: string;
}

export const claimOrder = createAsyncThunk<void, ClaimOrderProps>(
    'order/guestOrderClaim',
    async ({token, email}, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'order/claim-guest',
                data: {orderToken: token, email},
            },
            thunkApi as ThunkApi,
            false
        );
        return response.data;
    }
);

// todo: this endpoint should be removed as claiming is done on landing where auth endpoints are not possible
export const claimRegisteredOrder = createAsyncThunk<void, {token: string}>(
    'order/orderClaim',
    async ({token}, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'order/claim',
                params: {token},
            },
            thunkApi as ThunkApi
        );
        return response.data;
    }
);

export const viewOffer = createAsyncThunk<void, {token: string}>('order/offer', async ({token}, thunkApi) => {
    const response = await fetchGeneric(
        {
            method: 'get',
            url: 'order/view-offer',
            params: {token},
        },
        thunkApi as ThunkApi,
        false
    );
    return response.data;
});

export interface FetchChangeStatusProps {
    id?: number;
    token?: string;
    status: OrderStatusName;
}

export const fetchChangeStatus = createAsyncThunk<ReducerEnvelope<null>, FetchChangeStatusProps>(
    'order/statusChange',
    async ({token, id, status}, thunkApi) => {
        id = token ? undefined : id;

        let endpoint: string | null = null;

        switch (status) {
            case STATUS_CANCELLED:
                endpoint = 'cancel';
                break;
            case STATUS_PROCEED:
                endpoint = 'proceed';
                break;
            case STATUS_RETURN_TO_CLIENT:
                endpoint = 'return';
                break;
            case STATUS_SUSPENDED:
                endpoint = 'suspend';
                break;
            default:
                // eslint-disable-next-line no-console
                console.warn(`Status ${status} is not configured as endpoint`);
                return null;
        }

        const res = await fetchGeneric(
            {
                method: 'post',
                url: `order/${endpoint}`,
                params: {token, id: token ? undefined : id},
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export const fetchCompanyBankAccounts = createAsyncThunk('order/companyBanks', async (_, thunkApi) => {
    const res = await fetchGeneric({url: 'company-bank/list'}, thunkApi as ThunkApi);
    return res.data;
});

const statusChangeFulfilledHandler = (state, action) => {
    state.statusChange = {
        ...action.payload,
        statusName: action.meta.arg.status,
        isFetching: false,
    };
};

const slice = createSlice({
    name: 'order',
    initialState,
    extraReducers: (builder) => {
        handleReducerCases(fetchCompanyBankAccounts, 'companyBanks', builder);
        handleReducerCases(fetchOrder, 'detail', builder);
        handleReducerCases(refetchOrder, 'detail', builder, undefined, true);
        handleReducerCases(fetchChangeStatus, 'statusChange', builder, statusChangeFulfilledHandler);
        handleReducerCases(fetchLiveSuspended, 'liveSuspend', builder, undefined, true);
        handleReducerCases(fetchUpdateLimit, 'updateLimit', builder);
        handleReducerCases(fetchRemoveLimit, 'removeLimit', builder);
        handleReducerCases(fetchAcceptPrice, 'acceptPrice', builder);
        handleReducerCases(fetchChangeRefundAddress, 'returnFunds', builder);
        handleReducerCases(claimRegisteredOrder, 'orderClaim', builder);
        handleReducerCases(claimOrder, 'guestOrderClaim', builder);
        handleReducerCases(viewOffer, 'offer', builder);
        handleReducerCases(listOrders, 'list', builder);
    },
    reducers: {
        invalidateStatusChange: (state) => {
            state.statusChange = initialState.statusChange;
        },
        invalidateOrder: (state) => {
            state.statusChange = initialState.statusChange;
        },
        invalidateUpdateLimit: (state) => {
            state.updateLimit = initialState.updateLimit;
        },
        invalidateRemoveLimit: (state) => {
            state.removeLimit = initialState.removeLimit;
        },
        invalidateAcceptPrice: (state) => {
            state.acceptPrice = initialState.acceptPrice;
        },
        invalidateSetRefundAddress: (state) => {
            state.returnFunds = initialState.returnFunds;
        },
        invalidateClaimOrder: (state) => {
            state.guestOrderClaim = initialState.guestOrderClaim;
        },
        invalidateClaimRegisteredOrder: (state) => {
            state.orderClaim = initialState.orderClaim;
        },
    },
});

export const {actions, reducer} = slice;
export const {
    invalidateStatusChange,
    invalidateUpdateLimit,
    invalidateRemoveLimit,
    invalidateSetRefundAddress,
    invalidateClaimOrder,
    invalidateClaimRegisteredOrder,
    invalidateOrder,
    invalidateAcceptPrice,
} = slice.actions;
