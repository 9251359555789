import React, {useEffect} from 'react';
import {
    fetchSettings,
    initiateAxiosApi,
    Settings,
    SnackbarNotifier,
    SnackbarProvider,
    useBackendSettings,
    getConfigValue,
    RedirectProgrammatic,
} from '@simplecoin/core';
import {I18nextProvider} from 'react-i18next';
import {CssBaseline} from '@mui/material';
import {LicenseInfo} from '@mui/x-license-pro';
import Routes from './Routes';
import i18next from './config/i18n';
import AffiliateLinkTracker from './pages/affiliate/AffiliateLinkTracker';
import LanguageChangeListener from '~/src/handlers/LanguageChangeListener';
import NewVersionNotifier from '~/src/handlers/NewVersionNotifier';
import logo from './assets/svg/logo.svg';
import Router from './Router';
import {Loader} from '~/src/components';
import {useAppDispatch} from '~/src/redux/store';
const baseUrl = getConfigValue('apiUrl');
const settingsEndpoint = `${baseUrl}/landing/settings`;
import TagManager from 'react-gtm-module';
import {ThemeSettingProvider} from '~/src/MuiTheme';

function GtmInitializer() {
    useEffect(() => {
        const tagManagerArgs = {
            gtmId: getConfigValue('googleTag'),
        };
        TagManager.initialize(tagManagerArgs);
    }, []);

    return null;
}

/**
 * Core application component.
 */
export default function App() {
    const dispatch = useAppDispatch();
    const settings: Settings | undefined = useBackendSettings();

    // On mount
    useEffect(() => {
        injectCorporateInfo();
        LicenseInfo.setLicenseKey(
            'b8aec272252ee00b2a9ec7d1c437478bT1JERVI6NDI2MTMsRVhQSVJZPTE2ODI2NzY2MTkwMDAsS0VZVkVSU0lPTj0x'
        );

        dispatch(fetchSettings(settingsEndpoint));
        // initiate core axios
        initiateAxiosApi(getConfigValue('apiUrl'));
    }, []);

    /**
     * Create <script> element tag with company information.
     *
     * Company's information retrieved from settings.
     */
    function injectCorporateInfo(): boolean {
        const ld = document.createElement('script');
        ld.type = 'application/ld+json';
        const url = window.location.origin;
        const companyPhone = settings?.companyPhone;
        const companyName = settings?.companyName;
        const companyEmail = settings?.companyEmail;

        // When company name is missing, we cannot display snippet as it would be empty in the search results
        if (companyName === null) {
            return false;
        }

        ld.innerHTML = `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "${companyName}",
            "url": "${url}",
            "logo": "${logo}",
            "contactPoint": [{
                "@type": "ContactPoint",
                "telephone": "${companyPhone}",
                "contactType": "customer service",
                "email": "${companyEmail}"
            }]
        }`;

        document.body.append(ld);

        return true;
    }

    if (!settings) {
        return <Loader />;
    }

    return (
        <ThemeSettingProvider>
            <CssBaseline />
            <I18nextProvider i18n={i18next}>
                <Router>
                    <SnackbarProvider>
                        <AffiliateLinkTracker />
                        <RedirectProgrammatic />
                        <SnackbarNotifier />
                        <Routes />
                        <LanguageChangeListener />
                        <NewVersionNotifier />
                        <GtmInitializer />
                    </SnackbarProvider>
                </Router>
            </I18nextProvider>
        </ThemeSettingProvider>
    );
}

App.displayName = 'App';
