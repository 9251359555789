import React, {useEffect, useMemo} from 'react';
import {styled, Alert, Collapse, IconButton} from '@mui/material';
import DOMPurify from 'dompurify';
import CloseIcon from '@mui/icons-material/Close';
import {CLOSED_NOTIFICATIONS_STORAGE_KEY, WidthBreakpoint} from '~/src/config/localParams';
import {getConfigValue, getItemFromSessionStorage, hashCode, saveToSessionStorage} from '@simplecoin/core';

const NotificationWrapper = styled('div')`
    @media (max-width: ${WidthBreakpoint.sm}) {
        font-size: 80%;
        padding: 7px;
    }
`;

const StyledAlert = styled(Alert)(
    ({theme}) => `
    .MuiAlert-icon {
        align-items: center;
    }
    color: white;
    background-color: ${theme.palette.primary.main};
    border-radius: 16px;
`
);

/**
 * Renders single notification
 * @param {string} content can be HTML or just text
 * @constructor
 */
export default function Notification({content}: {content: string}) {
    const [open, setOpen] = React.useState<boolean | undefined>();
    const htmlContent = useMemo(() => {
        const sanitizedContent = DOMPurify.sanitize(content);
        return {__html: sanitizedContent};
    }, [content]);

    /**
     * Retrieves clsoed message keys from session storage
     */
    function getClosedNotifications(): number[] {
        const json = getItemFromSessionStorage({key: CLOSED_NOTIFICATIONS_STORAGE_KEY});
        if (json) {
            try {
                return JSON.parse(json) ?? [];
            } catch (e) {
                if (getConfigValue('environment') === 'development') {
                    // eslint-disable-next-line no-console
                    console.error('Failed to parse json from session storage', e);
                }
            }
        }
        return [];
    }

    useEffect(() => {
        const fromStorage = getClosedNotifications();
        const hash = hashCode(content);
        if (!fromStorage.length || !fromStorage.includes(hash)) {
            // notification has not been closed
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        if (open === false) {
            const fromStorage = getClosedNotifications();
            if (!fromStorage.includes(hashCode(content))) {
                fromStorage.push(hashCode(content));
                const jason = JSON.stringify(fromStorage);
                saveToSessionStorage({key: CLOSED_NOTIFICATIONS_STORAGE_KEY, value: jason});
            }
        }
    }, [open]);

    /**
     * Handled closing
     */
    function onCloseClick() {
        setOpen(false);
    }

    const alertAction = (
        <IconButton aria-label='close' color='inherit' size='small' onClick={onCloseClick}>
            <CloseIcon fontSize='inherit' />
        </IconButton>
    );

    return (
        <Collapse in={open}>
            <StyledAlert severity='info' action={alertAction}>
                <NotificationWrapper dangerouslySetInnerHTML={htmlContent} />
            </StyledAlert>
        </Collapse>
    );
}

Notification.displayName = 'Notification';
