import React, {useEffect, useState} from 'react';
import Select from '@mui/material/Select';
import {styled, MenuItem, Box, useTheme} from '@mui/material';
import {getConfigValue, useBackendSettings} from '@simplecoin/core';
import i18n, {changeI18nLanguage} from '~/src/config/i18n';
import {CountryCode} from '~/src/typings';
import {LANGUAGES, TEST_LANGUAGE_CODE, TEST_LANGUAGE_REGION} from '~/src/config/localParams';

import enUS from '../assets/svg/lang-en-US.svg';
import csCZ from '../assets/svg/lang-cs-CZ.svg';
import skSK from '../assets/svg/lang-sk-SK.svg';
import plPL from '../assets/svg/lang-pl-PL.svg';
import deDE from '../assets/svg/lang-de-DE.svg';
import itIT from '../assets/svg/lang-it-IT.svg';
import esES from '../assets/svg/lang-es-ES.svg';
import ruRU from '../assets/svg/lang-ru-RU.svg';
import ukUA from '../assets/svg/lang-uk-UA.svg';
import nlNL from '../assets/svg/lang-nl-NL.svg';
import viVN from '../assets/svg/lang-vi-VN.svg';
import {fetchUpdateAccountSettings} from '~/src/pages/settings/SettingsActions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/src/redux/store';

const iconMap: {[countryCode in CountryCode]: any} = {};
iconMap.en = enUS;
iconMap.cs = csCZ;
iconMap.sk = skSK;
iconMap.pl = plPL;
iconMap.de = deDE;
iconMap.it = itIT;
iconMap.es = esES;
iconMap.ru = ruRU;
iconMap.uk = ukUA;
iconMap.nl = nlNL;
iconMap.vi = viVN;

if (getConfigValue('environment') === 'development') {
    iconMap[TEST_LANGUAGE_CODE] = null;
}

/**
 * On screen sizes smaller than max-width we only show flag without the Language name
 */

interface StyledSelectProps {
    isoutlined?: boolean;
}

const StyledSelect = styled(Select)<StyledSelectProps>(
    ({isoutlined}) => `
        background: #fff;
        box-shadow: ${
            isoutlined ? '0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05)' : 'unset'
        };
        border-radius: 16px;

        .MuiInput-input,
        .MuiOutlinedInput-input {
            img {
                vertical-align: middle;
            }
            padding: 14.5px;
            margin-right: -19px; // counter the forced 32px right padding by mui
        }

        .MuiSelect-icon {
            display: none;
        }
        .MuiOutlinedInput-notchedOutline {
            border: 0;
        }
    `
);

const FlagIcon = styled('img')`
    width: 22px;
    height: 22px;
    border-radius: 50%;
`;

export interface LanguageChooserProps {
    value?: CountryCode;
    onChange?: (e: CountryCode) => void;
    changeLanguage?: boolean;
    languages?: CountryCode[];
    isoutlined?: boolean;
}

const mapCodeLanguage = {};

/**
 * Renders language dropdown. Choosing languages changes it globally within application.
 * Default value can be provided by passing value parameter.
 *
 * For mobiles, only flag is shown in unopened state.
 */
export default function LanguageChooser({
    value,
    changeLanguage = true,
    languages,
    onChange,
    isoutlined = true,
}: LanguageChooserProps) {
    const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(value);
    const {auth} = useSelector((state: RootState) => state.user);
    const isAuth = auth?.response?.is_auth;

    const settingslanguages: string[] = useBackendSettings('languages', []);
    const dispatch = useDispatch();
    const theme = useTheme();

    const [settingLanguagesWithRegion, setSettingLanguagesWithRegion] = useState<string[]>([]);

    useEffect(() => {
        if (settingslanguages && settingslanguages.length) {
            setSettingLanguagesWithRegion([...settingslanguages]);
        }
    }, [settingslanguages]);

    const preparedLanguageItems = React.useMemo(() => {
        if (!settingLanguagesWithRegion || !settingLanguagesWithRegion.length) {
            return null;
        }

        LANGUAGES.forEach((languageItem) => {
            mapCodeLanguage[languageItem.code] = languageItem;
        });

        if (
            getConfigValue('environment') === 'development' &&
            !settingLanguagesWithRegion.includes(TEST_LANGUAGE_REGION)
        ) {
            settingLanguagesWithRegion.push(TEST_LANGUAGE_REGION);
        }

        return settingLanguagesWithRegion.map((languageWithRegion: string) => {
            const languageCode: string = languageWithRegion.substring(0, 2);

            if (!mapCodeLanguage[languageCode]) {
                // eslint-disable-next-line no-console
                console.warn(`${languageCode} language is not available!`);
                return null;
            }

            const {name} = mapCodeLanguage[languageCode];
            const {nativeName} = mapCodeLanguage[languageCode];

            /**
             * LanguageChooser component itself can take prop an array of 'languages',
             * if languages prop does exists, it renders language codes in dropdown menu.
             */
            if (languages && languages.indexOf(languageCode) === -1) {
                return null;
            }

            const icon = typeof iconMap[languageCode] !== 'undefined' ? iconMap[languageCode] : null;

            return (
                <MenuItem key={languageCode} value={languageCode}>
                    {icon && <FlagIcon src={iconMap[languageCode]} alt={name} height={22} />}
                    <Box className='Language-Name' sx={{marginLeft: '1em'}}>
                        {nativeName}
                    </Box>
                </MenuItem>
            );
        });
    }, [settingLanguagesWithRegion]);

    // MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input css-cgd4f4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    // MuiMenuItem-root MuiMenuItem-gutters MuiButtonBase-root css-1f67oos-MuiButtonBase-root-MuiMenuItem-root

    useEffect(() => {
        if (selectedLanguage !== i18n.language && changeLanguage) {
            setSelectedLanguage(getLanguageCode(i18n.language));
        }
    }, [selectedLanguage, i18n.language, changeLanguage]);

    useEffect(() => {
        if (typeof value === 'undefined') {
            value = i18n.language;
        }

        value = getLanguageCode(value);

        setSelectedLanguage(value);
    }, [value]);

    /**
     * Get two character code
     * @param code
     * @return {string} code
     */
    function getLanguageCode(code: string | undefined): string | undefined {
        /**
         *  This is fallback to fix issue with LanguageDetector library. It does not have option to specify how detected language
         *  should be stored in cache. We use language only format: en, where is value stored in local storage or cookies:
         *  en-US, which makes empty selected value in component.
         *
         *  I create issue in their repo, maybe they fix it:
         *  @link https://github.com/i18next/i18next-browser-languageDetector/issues/195
         */
        if (typeof code === 'string' && code.length > 2) {
            code = code.substring(0, 2);
        }
        return code;
    }

    /**
     * Handle language change.
     * @param e
     */
    function handleChange(e: React.ChangeEvent<{name?: string; value: any}>) {
        const language = e.target.value;

        setSelectedLanguage(language);

        if (changeLanguage) {
            changeI18nLanguage(language);
        }

        if (typeof onChange === 'function') {
            onChange(language);
        }

        if (isAuth) {
            dispatch(fetchUpdateAccountSettings({language: language}));
        }
    }

    if (!selectedLanguage) {
        return null;
    }

    return (
        <StyledSelect
            onChange={handleChange}
            value={selectedLanguage}
            isoutlined={String(isoutlined)}
            sx={{
                [theme.breakpoints.between('md', 'xl')]: {
                    '& .Language-Name': {
                        display: 'none',
                    },
                },
            }}
        >
            {preparedLanguageItems}
        </StyledSelect>
    );
}

LanguageChooser.displayName = 'LanguageChooser';
