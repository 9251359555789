import React from 'react';
import {Breakpoint, useTheme, useMediaQuery} from '@mui/material';

interface DisplayProps {
    start: Breakpoint;
    end: Breakpoint;
    children: any;
}

/**
 * Displays children if screen width matches given breakpoint constraints
 */
export default function DisplayBetween({start, end, children}: DisplayProps) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.between(start, end));

    if (matches) {
        return <>{children}</>;
    }

    return null;
}

DisplayBetween.displayName = 'DisplayBetween';
