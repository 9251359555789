import React, {JSXElementConstructor} from 'react';
import {styled, Grid} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {green} from '@mui/material/colors';
import {AlertVariant} from '~/src/typings';

export interface AlertProps {
    children: React.ReactNode;
    variant?: AlertVariant;
    withIcon?: boolean;
}

interface SettingsMap {
    [variant: string]: {
        backgroundColor: string;
        color: string;
    };
}

interface BaseAlertProps {
    bgColor: string;
    textColor: string;
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    danger: DangerIcon,
    error: DangerIcon,
    info: InfoIcon,
};

const settings: SettingsMap = {
    success: {
        backgroundColor: green[600],
        color: '#fff',
    },
    // danger and error are identical
    danger: {
        backgroundColor: '#dc3545',
        color: '#fff',
    },
    error: {
        backgroundColor: '#dc3545',
        color: '#fff',
    },
    info: {
        backgroundColor: '#cce5ff',
        color: '#394085',
    },
    warning: {
        backgroundColor: '#ffc107',
        color: '#000000',
    },
};

const BaseAlert = styled('div', {
    shouldForwardProp: (prop) => prop !== 'textColor' && prop !== 'bgColor',
})`
    background-color: ${(props: BaseAlertProps) => props.bgColor};
    font-size: 15px;
    line-height: 20px;
    color: ${(props: BaseAlertProps) => props.textColor};
    padding: 11px;
    border-radius: 5px;

    a {
        text-decoration: underline;
    }
`;

const IconWrapper = styled(Grid)`
    && {
        padding-right: 10px;
    }
`;

const ContentWrapper = styled(Grid)`
    && {
        line-height: inherit;
    }
`;

const iconStyle = {verticalAlign: 'middle'};

/**
 * Renders alert in different colours with specified text, icons, etc.
 *
 * @param {string} variant
 * @param {boolean} withIcon
 * @param {React.ReactNode} children
 * @param {object} rest
 * @return {*}
 * @constructor
 */
export default function Alert({variant = 'info', withIcon = true, children, ...rest}: AlertProps) {
    const item = settings[variant] || null;
    let Icon: JSXElementConstructor<React.ReactNode> | null = null;

    if (withIcon && variantIcon[variant]) {
        Icon = variantIcon[variant];
    }

    if (!item || (withIcon && !Icon)) {
        return null;
    }

    return (
        <BaseAlert textColor={item.color} bgColor={item.backgroundColor} data-testid='alert' {...rest}>
            <Grid container direction='row' alignItems='center' wrap='nowrap'>
                <Grid item>
                    <Grid container direction='column'>
                        <IconWrapper item>
                            {Icon && (
                                <>
                                    <Icon style={iconStyle} />
                                    &nbsp;
                                </>
                            )}
                        </IconWrapper>
                    </Grid>
                </Grid>
                <ContentWrapper item>{children}</ContentWrapper>
            </Grid>
        </BaseAlert>
    );
}

Alert.displayName = 'Alert';
