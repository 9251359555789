import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {successSnackbar} from '~/src/snackbars/SnackbarActions';
import {validateEmailConfirmationToken} from './SiteActions';
import {RootState} from '~/src/redux/store';
import {SiteReducerInterface} from '~/src/pages/site/siteReducer';
import {manageResponse} from '@simplecoin/core';
import useSearchParameters from '~/src/hooks/useSearchParameters';

/**
 * Component validates email confirmation token from url and redirects to either login or index page
 */
export default function EmailConfirmation() {
    const {validateEmailConfirmationToken: tokenValidity} = useSelector<RootState, SiteReducerInterface>(
        (state) => state.user
    );
    const navigate = useNavigate();
    const params = useParams();
    const {origin, origin_tag} = useSearchParameters();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // contains order token
    const originTag = origin && origin === 'order-claim' && origin_tag;

    useEffect(() => {
        if (params.token) {
            dispatch(validateEmailConfirmationToken(params.token, !originTag));
        }
    }, []);

    useEffect(() => {
        manageResponse({
            dispatch,
            reducer: tokenValidity,
            onSuccess: () => {
                dispatch(successSnackbar({message: t('email_confirmed')}));
                if (originTag) {
                    // redirect to order page
                    navigate(`/order-guest/${origin_tag}`);
                } else {
                    navigate('/login');
                }
            },
            onError: () => {
                if (originTag) {
                    // redirect to order page
                    navigate(`/order-guest/${origin_tag}`);
                } else {
                    navigate('/');
                }
            },
        });
    }, [tokenValidity]);

    return null;
}

EmailConfirmation.displayName = 'EmailConfirmation';
