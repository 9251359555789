import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Loader} from '../components';
import Welcome from '../pages/welcome/Welcome';
import {getProfileData, Profile} from '../redux/slices/profileSlice';
import {RootState, useAppDispatch} from '../redux/store';

export default function (WrappedComponent: any) {
    function VerificationAndConsentCheck(props) {
        const profileInterface = useSelector((state: RootState) => state.profile);
        const {auth} = useSelector((state: RootState) => state.user);
        const profileData: Profile | undefined = profileInterface?.data?.response;
        const dispatch = useAppDispatch();

        useEffect(() => {
            // Assume that the policy consent and verification will not be revoked during the session.
            // Once the profile loaded we rely on that values even they are not refreshed.
            if (!profileData?.wallet_policy_consent || !profileData?.verified) {
                dispatch(getProfileData());
            }
        }, [profileInterface.submitWalletConsent]);

        if (profileInterface.data.isFetching || auth.isFetching) {
            return <Loader />;
        }
        // Check if we have:
        // 1) client's consent with wallet policy
        // 2] and either the client passed the new verification or he reached verification level 2 in old Exchange system
        else if (
            profileData?.wallet_policy_consent &&
            (profileData?.verified || auth.response?.user.verification_level === 2)
        ) {
            return <WrappedComponent {...props} />;
        } else {
            return <Welcome />;
        }
    }

    return VerificationAndConsentCheck;
}
