import {ActionReducerMapBuilder, AsyncThunk} from '@reduxjs/toolkit';
import {getConfigValue} from '~/helpers/config';

/**
 *  Helpers from this file aim to simplify adding extra reducers
 */
export const handleReducerCases = (
    thunk: AsyncThunk<any, any, any>,
    stateKey: string,
    builder: ActionReducerMapBuilder<any>,
    customFulfilledHandler?: (state, action) => void,
    withoutIsFetching = false
) => {
    builder.addCase(thunk.pending, (state) => {
        try {
            if (!withoutIsFetching) {
                state[stateKey] = {isFetching: true};
            }
        } catch (e) {
            if (getConfigValue('environment') === 'development') {
                // eslint-disable-next-line no-console
                console.warn('Error in pending reducer helper: ', state, stateKey, e);
            }
        }
    });

    builder.addCase(thunk.fulfilled, (state, action) => {
        try {
            if (customFulfilledHandler) {
                customFulfilledHandler(state, action);
            } else {
                state[stateKey] = action.payload;
                state[stateKey].isFetching = false;
            }
        } catch (e) {
            if (getConfigValue('environment') === 'development') {
                // eslint-disable-next-line no-console
                console.warn('Error in fulfilled reducer helper: ', state, stateKey, e);
            }
        }
    });

    builder.addCase(thunk.rejected, (state, action) => {
        if (getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Rejection in redux: ', state, action);
        }
        state[stateKey].isFetching = false;
        state[stateKey].error = 'System error';
    });
};
