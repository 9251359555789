import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '~/src/components/LoadingComponent';

interface LoadableComponentProps {
    loader: () => Promise<any>;
    fallback?: () => React.ReactNode | null;
    resolveComponent?: (components: any, props: any) => any;
}

/**
 * Helper for Loadable providing default Loader
 * @param {() => Promise<any>} loader
 * @param {(() => (React.ReactNode | null)) | undefined} loading
 * @constructor
 */
export default function LoadableComponent({loader, fallback, ...rest}: LoadableComponentProps) {
    const loadingComponent = fallback ?? <LoadingComponent />;
    return loadable(loader, {fallback: loadingComponent, ...rest});
}

LoadableComponent.displayName = 'LoadableComponent';
