import qs from 'qs';
import {AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {batch} from 'react-redux';
import {closeSnackbar, failureSnackbar, warningSnackbar} from '~/src/snackbars/SnackbarActions';
import i18n from '../config/i18n';
import api from '../config/api';
import {Action} from '~/src/typings';
import {RootState} from '~/src/redux/store';
import {getAuthKey, hasError, isObject, setAuthProps, getConfigValue, RedirectActions} from '@simplecoin/core';

export type FetchGenericActionType = string | Action | ((func: any) => void);

export interface FetchGenericActions {
    pre?: FetchGenericActionType;
    success?: FetchGenericActionType;
    failure?: FetchGenericActionType;
    invalidate?: FetchGenericActionType;
}

export interface FetchGenericProps {
    method?: Method;
    url: string;
    data?: Record<string, unknown>;
    jsonData?: any;
    headers?: Record<string, unknown>;
    actions?: FetchGenericActions;
    afterSuccess?: FetchGenericActionType;
    afterFailure?: FetchGenericActionType;
    query?: Record<string, unknown>;
    displaySnackbars?: boolean;
    failureUrl?: string;
    failureSnackType?: {[code: number]: 'error' | 'warning'};
    axiosProps?: AxiosRequestConfig;
    allowToFail?: boolean;
    authKey?: string;
}

/**
 * Fetch generic API endpoint.
 *
 * @param {"get" | "post" | "put" | undefined} method Method name, e.g. get or post.
 * @param {string} url URL where to send request.
 * @param {{} | undefined} data
 * @param {{} | undefined} headers
 * @param {FetchGenericActions | undefined} actions
 * @param {string | Action | ((func: any) => void) | undefined} afterSuccess Action to invoke after success.
 * @param {string | Action | ((func: any) => void) | undefined} afterFailure Action to invoke after failure.
 * @param {{} | undefined} query  List of query arguments.
 * @param {boolean | undefined} displaySnackbars Flag indicating whether to display snackbar component on API/regular error received
 * @param {string | undefined} failureUrl  url to redirect programatically to in case of envelope failure (if status === 'fail')
 * @param {{[p: number]: "error" | "warning"} | undefined} failureSnackType
 * @param {string | undefined} accessToken providedCSRF access token (taken from cookies if not provided)
 * @param {string | undefined} allowToFail if true, status codes 400-500 will not redurect to errorUrl
 * @param {AxiosRequestConfig | undefined} axiosProps Props passed to axios() as configuration.
 * @return {(dispatch: any, getState: () => RootState) => AxiosPromise}
 */
export function fetchGeneric({
    method = 'get',
    url,
    data = {},
    headers = {},
    actions = {},
    afterSuccess,
    afterFailure,
    query = {},
    jsonData = null,
    displaySnackbars = true,
    failureUrl = '',
    failureSnackType,
    authKey,
    allowToFail = false,
}: FetchGenericProps): (dispatch: any, getState: () => RootState) => AxiosPromise {
    const {pre, success, failure, invalidate} = actions;

    return (dispatch, getState): Promise<any> => {
        // creating new axios instance to prevent interceptor collision
        const axiosInstance: AxiosInstance = api.create();

        if (typeof pre === 'string') {
            dispatch({type: pre});
        } else if (typeof pre === 'function' || isObject(pre)) {
            dispatch(pre);
        }

        const queryKeys = Object.keys(query);
        if (queryKeys.length > 0) {
            const queryStr = qs.stringify(query, {
                encoder: (str, defaultEncoder, charset, type) => {
                    if (type === 'key' && str === 'perPage') {
                        return 'per-page';
                    } else {
                        return defaultEncoder(str);
                    }
                },
            });
            url += `?${queryStr}`;
        }

        if (!(data instanceof FormData)) {
            data = qs.stringify(data);
        }

        if (jsonData) {
            data = JSON.stringify(jsonData.data);
            headers['Content-Type'] = 'application/json';
        }

        const csrfToken = authKey ?? getAuthKey();

        if (csrfToken) {
            headers['X-Authorization'] = `Bearer ${csrfToken}`;
        }

        const interceptorId = axiosInstance.interceptors.response.use(
            (response) => response,
            (error: AxiosError): void => {
                const status = error.response ? error.response.status : null;

                const logId =
                    error && error.response && error.response.headers && error.response.headers['x-log-id']
                        ? error.response.headers['x-log-id']
                        : null;

                if (status !== null) {
                    if (status === 401) {
                        dispatch(RedirectActions.redirectProgrammatic({url: '/logout'}));
                    } else if ((status === 403 || status === 503) && error.response?.headers['permissions-policy']) {
                        window.location.reload();
                    } else if (!allowToFail && status >= 400 && status < 500) {
                        dispatch(RedirectActions.redirectProgrammatic({url: failureUrl || '/error'}));
                        // Setting failure url to '' in order not to invoke another redirect in handling dispatchFailure
                        if (failureUrl) {
                            failureUrl = '';
                        }
                    }
                }

                let data = error.response && error.response.data ? error.response.data : null;

                // For example, 404 returned in plain HTML, it cannot be converted to JSON, so we need to override
                // data as object and later synthetically recreate error response object
                if (typeof data === 'string' || data === null) {
                    data = {};
                }

                const DEFAULT_ERROR_MESSAGE = i18n.t('oops_something_went_wrong');

                const message = data && data.error ? data.error : DEFAULT_ERROR_MESSAGE;

                if (getConfigValue('environment') === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn(
                        `[Request] failed calling ${url} because of ${error.name}, message: "${error.message}"`
                    );
                }

                if (error.message === 'Network Error') {
                    handleNetworkError(dispatch);
                    displaySnackbars = false; // prevent failure snackbars
                }

                if (isObject(data)) {
                    data.error = message;
                }

                dispatchFailure({
                    data,
                    dispatch,
                    displaySnackbars,
                    logId,
                    failureUrl,
                    allowToFail,
                    failureSnackType,
                    actions: {failure, afterFailure},
                });
            }
        );

        return axiosInstance
            .request({
                method,
                url,
                data,
                headers,
                withCredentials: true,
            })
            .then((response: AxiosResponse) => {
                // response is undefined if handled by onRejected interceptor
                if (!response) {
                    return;
                }
                if (response.data?.page || response.data?.perPage || response.data?.totalCount) {
                    response.data._meta = {
                        currentPage: response.data.page + 1,
                        perPage: response.data.perPage,
                        totalCount: response.data.totalCount,
                        pageCount: response.data.perPage / (response.data.page + 1),
                    };
                }
                const backendVersion = response?.headers?.['x-version'] ?? null;
                if (backendVersion) {
                    const state = getState();
                    const {backendVersion: currentBackendVersion} = state.user;
                    if (backendVersion !== currentBackendVersion) {
                        dispatch(RedirectActions.setBackendVersion(backendVersion));
                    }
                }

                const authKey = response?.headers?.['auth-key'] ?? null;
                const authKeyExpiration = response?.headers?.['auth-key-expiration'] ?? null;
                const currentAuthKey = getAuthKey();
                if (authKeyExpiration && authKey && currentAuthKey !== authKey) {
                    setAuthProps({token: authKey, expires_at: authKeyExpiration});
                }

                const logId = response?.headers?.['x-log-id'] ?? null;

                // check for error presence or fail status
                if (hasError(response.data)) {
                    dispatchFailure({
                        data: response.data,
                        dispatch,
                        displaySnackbars,
                        logId,
                        failureUrl,
                        allowToFail,
                        failureSnackType,
                        actions: {failure, afterFailure},
                    });
                } else {
                    // Check whether content can be downloaded or not
                    if (isDownloadResponse(response)) {
                        downloadFile(response);
                    } else {
                        dispatchSuccess({data: response.data, dispatch, actions: {success, afterSuccess}});
                    }
                }
            })
            .catch((err) => {
                if (getConfigValue('environment') === 'development') {
                    // eslint-disable-next-line no-console
                    console.error('Error caught in fetch generic: ', err);
                }

                if (displaySnackbars) {
                    const DEFAULT_ERROR_MESSAGE = i18n.t('oops_something_went_wrong');
                    dispatch(failureSnackbar({message: DEFAULT_ERROR_MESSAGE}));
                }
            })
            .finally(() => {
                if (invalidate) {
                    dispatchInvalidate({dispatch, invalidate});
                }
                // not ejecting interceptor may cause unexpected behavior
                axiosInstance.interceptors.response.eject(interceptorId);
            });
    };
}

/**
 * Handles network error by closing all previous snackbars and sending a snackbar informing about network not being available
 * @param dispatch
 */
function handleNetworkError(dispatch) {
    batch(() => {
        dispatch(closeSnackbar({dismissAll: true}));
        dispatch(warningSnackbar({message: i18n.t('network_error_warning')}));
    });
}

/**
 * Dispatches success action and subsequently afterSuccess (if present)
 * @param {object} data
 * @param {function} dispatch
 * @param {string|object|function} success
 * @param {string|object|function} afterSuccess
 */
function dispatchSuccess({
    data,
    dispatch,
    actions: {success, afterSuccess} = {},
}: {
    data: any;
    dispatch: any;
    actions?: {
        success?: FetchGenericActionType;
        afterSuccess?: FetchGenericActionType;
    };
}): void {
    if (typeof success === 'string') {
        dispatch({type: success, payload: data});
    } else if (isObject(success)) {
        dispatch(success);
    } else if (typeof success === 'function') {
        dispatch(success(data));
    }

    if (afterSuccess) {
        if (typeof afterSuccess === 'string') {
            dispatch({type: afterSuccess});
        } else if (typeof afterSuccess === 'function' || isObject(afterSuccess)) {
            dispatch(afterSuccess);
        }
    }
}

/**
 * Dispatches failure action and subsequently afterFailure (if present)
 *
 */
function dispatchFailure({
    data,
    dispatch,
    displaySnackbars,
    logId,
    failureUrl,
    failureSnackType,
    allowToFail = false,
    actions: {failure, afterFailure} = {},
}: {
    data: any;
    dispatch: any;
    displaySnackbars?: boolean;
    logId?: string;
    failureUrl: string;
    allowToFail?: boolean;
    failureSnackType?: {[code: number]: 'error' | 'warning'};
    actions?: {
        failure?: FetchGenericActionType;
        afterFailure?: FetchGenericActionType;
    };
}): void {
    if (typeof failure === 'string') {
        dispatch({type: failure, payload: data});
    } else if (typeof failure === 'function') {
        dispatch(failure(data));
    } else if (isObject(failure)) {
        dispatch(failure);
    }

    // By default use failureSnackbar (red alert)
    let snackFx = failureSnackbar;

    /**
     * Identity which function to use to invoke failure action.
     * @param type
     */
    function determineSnackFx(type: 'error' | 'warning') {
        switch (type) {
            case 'error':
                return failureSnackbar;
            case 'warning':
                return warningSnackbar;
        }
    }

    if (failureSnackType) {
        const errorCode: string | null = (data && data.error_code) || null;

        for (const key in failureSnackType) {
            if (errorCode !== null && key === errorCode) {
                snackFx = determineSnackFx(failureSnackType[key]);
            }
        }
    }

    if (displaySnackbars) {
        if (typeof data.error === 'string') {
            dispatch(snackFx({message: data.error, logId}));
        } else if (isObject(data.error)) {
            Object.keys(data.error).forEach((key) => {
                if (typeof data.error[key] === 'string') {
                    // if item is an object it is meant for form use
                    dispatch(snackFx({message: data.error[key], logId}));
                }
            });
        }
    }

    if (afterFailure) {
        if (typeof afterFailure === 'string') {
            dispatch({type: afterFailure});
        } else if (typeof afterFailure === 'function' || isObject(afterFailure)) {
            dispatch(afterFailure);
        }
    }

    if (!allowToFail && failureUrl) {
        dispatch(RedirectActions.redirectProgrammatic({url: failureUrl}));
    }
}

/**
 * Dispatches invalidate action
 * @param {function} dispatch
 * @param {string|object|function} invalidate
 */
function dispatchInvalidate({
    dispatch,
    invalidate,
}: {
    dispatch: any;
    invalidate: string | Record<string, unknown> | (() => void);
}): void {
    if (typeof invalidate === 'string') {
        dispatch({type: invalidate});
    } else if (typeof invalidate === 'function' || isObject(invalidate)) {
        dispatch(invalidate);
    }
}

/**
 * Retrieves files name from response in casewhen content-disposition
 * @param response
 * @param {boolean} generateWhenMissing Generate file name, when it is missing in content-disposition header.
 * @return {string | null}
 */
export function getFileNameFromResponse(response: any, generateWhenMissing = false): string | null {
    // Check content disposition and whether content can be downloaded
    const cd = response.headers && response.headers['content-disposition'];

    const fileNamePart = /filename="(.*?)"/gm.exec(cd);

    if (fileNamePart && fileNamePart[1]) {
        return fileNamePart[1];
    }

    if (generateWhenMissing) {
        return `doc-${new Date().getTime()}`;
    }

    return null;
}

/**
 *
 * @param {object} response
 * @return {boolean}
 */
function isDownloadResponse(response: any): boolean {
    // Check content disposition and whether content can be downloaded
    const cd = response.headers && response.headers['content-disposition'];

    return /attachment/gm.test(cd);
}

/**
 * Invokes download of the file's data.
 * @param {object} response
 */
export function downloadFile(response: any): void {
    if (typeof response !== undefined) {
        const fileName = getFileNameFromResponse(response, true);

        if (fileName) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }
}

export async function downloadReport(type: string): void {
    const apiUrl = getConfigValue('apiUrl');
    const csrfToken = getAuthKey();
    let fileName = '';

    const fetchPromise = fetch(`${apiUrl}/reports/${type}`, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'X-Authorization': `Bearer ${csrfToken}`,
        },
    });

    fetchPromise
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP Error: ${response.status}`);
            }

            fileName = response.headers.get('content-disposition').match(/filename="(.*?)"/)[1];
            return response.text();
        })
        .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // or any other extension
            document.body.appendChild(link);
            link.click();
        });
}

interface FetchItemsProps {
    url: string;
    actions?: {
        pre?: string;
        success?: string;
        failure?: string;
    };
    pagination?: {
        page?: number | null;
        perPage?: number;
        offset?: number;
    };
    query?: Record<string, unknown>;
}

/**
 * Generic action to fetch items from API endpoint.
 *
 * @param {string} url URL where to send request.
 * @param {string} pre Pre request action name.
 * @param {string} success Success response action name.
 * @param {string} failure Failure response action name.
 * @param page
 * @param perPage
 * @param offset
 * @param {object} query list of query parameters added to URL.
 * @return {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchItems({
    url,
    actions: {pre, success, failure} = {},
    pagination: {page = null, perPage = 10, offset = 0} = {},
    query = {},
}: FetchItemsProps) {
    if (url.indexOf('?') === -1) {
        url += '?';
    } else {
        url += '&';
    }

    url += `page=${page || offset / perPage + 1}&per-page=${perPage}`;

    const queryKeys = Object.keys(query);
    if (queryKeys.length > 0) {
        url += `&${qs.stringify(query)}`;
    }

    return fetchGeneric({
        method: 'get',
        url,
        actions: {pre, success, failure},
    });
}
