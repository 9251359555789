import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Card, CardContent, Checkbox, FormControlLabel, Link, Stack, Typography} from '@mui/material';
import {CustomButton} from '@simplecoin/core';
import {useAppDispatch} from '~/src/redux/store';
import {submitWalletConsent} from '~/src/redux/slices/profileSlice';
import getLink from '~/src/config/links';

/**
 * Welcome page, trigger in dashboard
 */
export default function PolicyConsent() {
    const {t} = useTranslation();

    // Checked consent checkbox
    const [checked, setChecked] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const handleCheckboxChange = (e) => {
        if (checked !== e.target.checked) setChecked(e.target.checked);
    };

    const handleContinueButtonClick = () => {
        dispatch(submitWalletConsent());
    };

    return (
        <Card>
            <CardContent>
                <Stack direction='column' alignItems='center' justifyContent='center' spacing={5} sx={{my: 5}}>
                    <Typography variant='h1' align='center'>
                        <Trans i18nKey='wallet_welcome_page' />
                    </Typography>
                    <Stack direction={{xs: 'column', sm: 'row'}} justifyContent='center' mt={3} alignItems='center'>
                        <FormControlLabel
                            control={<Checkbox checked={checked} onChange={handleCheckboxChange} color='primary' />}
                            label={
                                <Trans
                                    components={[<Link key={0} href={getLink('wallet-terms-of-services')} />]}
                                    i18nKey='wallet_welcome_page_condions'
                                />
                            }
                        />
                    </Stack>
                    <CustomButton disabled={!checked} variant='outlined' onClick={handleContinueButtonClick}>
                        {t('wallet_welcome_page_button')}
                    </CustomButton>
                </Stack>
            </CardContent>
        </Card>
    );
}
