import {LANGUAGES} from '../config/params';

/**
 * Ensures passed language string with region.
 *
 * @param {string} language Language code used to find language with region, e.g. "en" would be used to find "en-US".
 * @param {string} defaultValue Default language param used in case when language is not found in the list of
 * available languages.
 * @return {string} languageRegion
 */
export function ensureWithRegion(language: string, defaultValue = 'en-US'): string {
    let matched = false;

    if (!language) {
        return defaultValue;
    }

    let languageCode = language;
    if (language.length > 2) {
        languageCode = language.substr(0, 2);
    }

    LANGUAGES.map((item) => {
        if (item.code === languageCode) {
            language = item.region;
            matched = true;
        }
    });

    if (!matched) {
        language = defaultValue;
    }

    return language;
}

/**
 * Returns language code without region. For example en-US is transformed to en
 * @param {string} language can be in 'en-US' format
 * @param {string} defaultValue
 * @return {string} language
 */
export function ensureLanguageOnly(language: string, defaultValue = 'en'): string {
    let result = defaultValue;

    if (!language) {
        return defaultValue;
    }

    LANGUAGES.map((item) => {
        if (item.code === language.substring(0, 2)) {
            result = item.code;
        }
    });

    return result;
}
