import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import i18n, {changeI18nLanguage} from '../config/i18n';
import Loader from '../components/Common/Loader';
import {authUser} from '~/src/pages/site/SiteActions';
import {REDIRECT_PARAM_NAME} from '~/src/config/localParams';
import {RootState, useAppDispatch} from '~/src/redux/store';
import {constructFailureUrl, ensureLanguageOnly, getPathWithoutLanguage} from '@simplecoin/core';
import useSearchParameters from '~/src/hooks/useSearchParameters';

export interface AuthProps {
    redirectToExchange?: boolean;
    redirectToLogin?: boolean;
    displaySnackbars?: boolean;
    switchLanguage?: boolean;
    allowRenderUnauthorized?: boolean;
}

/**
 * Authentication wrapper.
 * Helps to protect auth-only routes.
 *
 * @param WrappedComponent
 * @param redirectToExchange - use to redirect to exchange page on successful authentication
 * @param redirectToLogin - use to redirect to login page on failed authentication
 * @param displaySnackbars
 * @param switchLanguage
 * @param renderUnauthorized
 * @return {AuthenticationCheck}
 */
export default function (
    WrappedComponent: any,
    {
        redirectToExchange = false,
        redirectToLogin = true,
        displaySnackbars = true,
        switchLanguage = true,
        allowRenderUnauthorized = false,
    }: AuthProps
) {
    /**
     * @param props
     * @return {*}
     * @constructor
     */
    function AuthenticationCheck(props) {
        const {auth} = useSelector((state: RootState) => state.user);
        const navigate = useNavigate();
        const location = useLocation();
        const dispatch = useAppDispatch();
        const searchParameters = useSearchParameters();
        const isAuthenticated = auth.status === 'ok' && auth?.response?.is_auth === true;

        /**
         * On component mount. If redirectionToLogin is true, a failure URL with target path as REDIRECT_PARAM_NAME
         * param value is added.
         */
        useEffect(() => {
            let failureUrl;
            const notAtLogoutPage = getPathWithoutLanguage(location.pathname, true) !== '/logout';

            if (searchParameters[REDIRECT_PARAM_NAME] && redirectToLogin) {
                // redirect param already in url
                failureUrl = constructFailureUrl({targetPathname: searchParameters[REDIRECT_PARAM_NAME]});
            } else if (redirectToLogin && notAtLogoutPage) {
                failureUrl = constructFailureUrl({targetPathname: location.pathname});
            }
            dispatch(authUser({displaySnackbars, failureUrl}));
        }, []);

        /**
         * Handles redirection to /exchange if user is already authenticated and to /login otherwise
         */
        useEffect(() => {
            if (auth.isFetching) {
                return;
            }

            if (isAuthenticated) {
                const user = auth?.response?.user;

                if (user && switchLanguage) {
                    const userLanguage = ensureLanguageOnly(user.language);

                    if (i18n.language !== userLanguage) {
                        changeI18nLanguage(userLanguage);
                    }

                    if (redirectToExchange) {
                        const notAtExchangePage = getPathWithoutLanguage(location.pathname, true) !== '/exchange';
                        if (notAtExchangePage) {
                            navigate('/exchange');
                        }
                    }
                }
            } else {
                const notAtLoginPage = getPathWithoutLanguage(location.pathname, true) !== '/login';

                if (redirectToLogin && notAtLoginPage) {
                    navigate('/login');
                }
            }
        }, [isAuthenticated, auth]);

        if (!auth.isFetching && !isAuthenticated && redirectToLogin) {
            navigate('/login');
        }

        if (!isAuthenticated && !allowRenderUnauthorized) {
            return <Loader />;
        }

        return <WrappedComponent {...props} user={auth} />;
    }

    return AuthenticationCheck;
}
