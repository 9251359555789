import React from 'react';
import {styled} from '@mui/material/styles';
import {Currency} from '~/src/typings';

export interface CurrencyColorProps {
    currency?: Currency;
    name?: string;
    color?: string;
    missingColor?: string;
}

interface WrapperProps {
    textColor: string;
}

const Wrapper = styled('span', {
    shouldForwardProp: (prop) => prop !== 'textColor',
})<WrapperProps>`
    color: ${(props) => props.textColor};
`;

/**
 * Renders colored currency name.
 *
 * It can be used in two ways: (1) pass currency as object with at least name and color or (2) by passing
 * explicitly name of the currency and color.
 *
 * @param currency
 * @param name
 * @param color
 * @param missingColor
 * @param props
 * @return {*|null}
 * @constructor
 */
export default function CurrencyColor({currency, name, color, missingColor = '#000', ...props}: CurrencyColorProps) {
    const currencyName = (currency && currency.name) || name;
    const currencyColor = (currency && currency.color) || color;

    if (!currencyName || !currencyColor) {
        return null;
    }

    if (currencyColor) {
        return (
            <Wrapper textColor={currencyColor} {...props}>
                {currencyName}
            </Wrapper>
        );
    }

    return (
        <Wrapper textColor={missingColor} {...props}>
            {currencyName}
        </Wrapper>
    );
}

CurrencyColor.displayName = 'CurrencyColor';
