import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Chart from './Chart';
import {useSelector} from 'react-redux';
import {RootState, useCoreAppDispatch} from '~/redux/store';
import {fetchChartData, fetchMultichartData} from '~/redux/multichartSlice';
import {
    Box,
    Button,
    Grid,
    Stack,
    Typography,
    styled,
    MenuItem,
    Select,
    Paper,
    useTheme,
    Tooltip,
    Divider,
} from '@mui/material';
import PriceChange from '../exchange-form/PriceChange';
import {useTranslation} from 'react-i18next';
import useBackendSettings from '../../hooks/useBackendSettings';
import renderWithSettings from '../../renderWithSettings';
import {Currencies} from '~/typings/currency';
import {formatAmount} from '~/helpers/price';
import {initiateAxiosApi} from '~/api';
import FlatCurrencyIcon from '../FlatCurrencyIcon';
import TimeframeDropdown from './TimeframeDropdown';
import useWindowSize from '../../hooks/useWindowsSize';
import CustomSelect from '../CustomSelect';
import {getInterval} from '~/helpers/chart';

const ChartWrapper = styled(Grid)`
    && {
        height: 690px;
        width: auto;
        border-radius: 20px;
        background: #ffffff;
        box-shadow: 0 24px 128px rgba(42, 28, 82, 0.05);
        display: flex;
        padding-right: 20px;
        z-index: 100;
    }
`;

interface CurrencyItem {
    name: string;
    code: string;
    baseCurrencyCode: string;
    price: string;
    change: number;
}

export interface MultichartProps {
    fromCurrencyCodePrefill: string;
    toCurrencyCodePrefill: string;
    mobile?: boolean;
    apiUrl?: string;
    showBuyCryptoButton?: boolean;
    initialTimeframe?: number;
}

function MultiChart({
    fromCurrencyCodePrefill = 'EUR',
    toCurrencyCodePrefill = 'BTC',
    mobile = false,
    apiUrl,
    showBuyCryptoButton = false,
    initialTimeframe = 1440,
}: MultichartProps) {
    const {t, i18n} = useTranslation();
    const dispatch = useCoreAppDispatch();
    const currencies: Currencies = useBackendSettings('currencies', {});
    const rateReducer = useSelector((state: RootState) => state.multichart.chartData);
    const multichartDataReducer = useSelector((state: RootState) => state.multichart.multichartData);
    const [fromCurrencyCode, setFromCurrencyCode] = useState<string>(fromCurrencyCodePrefill);
    const [toCurrencyCode, setToCurrencyCode] = useState<string>(toCurrencyCodePrefill);
    const [timeframe, setTimeframe] = useState(initialTimeframe);
    const theme = useTheme();
    const [windowWidth] = useWindowSize();

    const [chartWidth, setChartWidth] = useState<number>();

    // This is workaround how to get width of an HTML element.
    // Problem with useRef is that it does not trigger re-render whet the ref.current is set.
    // Therefore there is no way to render the chart when the width for it is known.
    // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    const multiChartRef = useCallback(
        (node) => {
            if (mobile) {
                // Subtract paddings
                setChartWidth(node?.offsetWidth);
            } else {
                // Subtract width of currency widget in the left column
                setChartWidth(node?.offsetWidth - 300);
            }
        },
        [windowWidth]
    );

    useEffect(() => {
        dispatch(
            fetchChartData({
                from: fromCurrencyCode,
                to: toCurrencyCode,
                timeframe: timeframe,
                interval: getInterval(timeframe),
            })
        );
    }, [fromCurrencyCode, toCurrencyCode, timeframe]);

    useEffect(() => {
        apiUrl && initiateAxiosApi(apiUrl);
        dispatch(fetchMultichartData(fromCurrencyCode));
    }, [fromCurrencyCode]);

    const handleBaseCurrencyChange = (e) => {
        setFromCurrencyCode(e.target.value);
    };

    // get currency codes, then objects
    const currencyCodes = multichartDataReducer?.response?.rates.map((chartData) => chartData.to) ?? [];

    const fromCurrenciesArray = ['CZK', 'EUR'];

    const currencyArray = Object.keys(currencies)
        .filter((key) => key.length >= 3)
        .map((key) => currencies[key])
        .filter((curr) => curr?.name && currencyCodes.includes(curr.name));

    // might be expensive to go through all
    const currencyWidgetData: CurrencyItem[] = useMemo(
        () =>
            multichartDataReducer?.response?.rates.map((chartDataItem) => ({
                name: currencies[chartDataItem.to]?.full_name ?? chartDataItem.to,
                code: chartDataItem.to,
                baseCurrencyCode: chartDataItem.from,
                price: chartDataItem.latestPrice,
                change: chartDataItem.change ?? 0.0,
            })) ?? [],
        [multichartDataReducer?.response?.rates]
    );

    function renderCurrencyWidgetInner(currencyItem: CurrencyItem, i: number) {
        const secondaryColor = theme.palette.secondary.main;
        const formattedPrice = formatAmount(
            currencyItem.price,
            currencies[currencyItem.code]?.fixed_decimals ?? 5,
            i18n.language
        );

        return (
            <Stack key={i} spacing={1} justifyContent='center' sx={{height: '100%'}}>
                <Stack direction='row' spacing={1.5} alignItems='center'>
                    <FlatCurrencyIcon code={currencyItem.code} color={secondaryColor} />
                    <Typography variant='h4' color='primary'>
                        {currencyItem.name}
                    </Typography>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='space' justifyContent='space-between'>
                    <Typography variant='caption' color='text.secondary'>
                        {formattedPrice} {currencyItem.baseCurrencyCode}
                    </Typography>
                    <Tooltip title={t('change_in_24hours')}>
                        <Box>
                            <PriceChange priceChange={(currencyItem.change * 100).toFixed(2)} />
                        </Box>
                    </Tooltip>
                </Stack>
            </Stack>
        );
    }

    function getCurrencyWidget(currencyItem: CurrencyItem, i: number) {
        const highlighted = toCurrencyCode === currencyItem.code;
        return (
            <Box
                key={'currencyWidget_' + currencyItem.code + i}
                sx={{
                    height: '108px',
                    minWidth: '300px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                }}
            >
                <Box sx={{boxShadow: `inset 0px -1px ${theme.palette.divider}`}}>
                    <Box
                        key={'innerCurrencyWidget_' + currencyItem.code}
                        sx={{
                            height: '108px',
                            minWidth: '270px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            borderTopRightRadius: '16px',
                            borderBottomRightRadius: '16px',
                            pl: 4,
                            backgroundColor: highlighted ? 'rgba(65, 39, 126, 0.05)' : 'transparent',
                            borderLeftColor: highlighted ? theme.palette.secondary.main : 'transparent',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: 8,
                            cursor: 'pointer',
                            '&:hover': {
                                borderLeftColor: highlighted
                                    ? theme.palette.secondary.main
                                    : theme.palette.secondary.main + '4C',
                            },
                        }}
                        onClick={() => setToCurrencyCode(currencyItem.code)}
                    >
                        {renderCurrencyWidgetInner(currencyItem, i)}
                    </Box>
                </Box>
            </Box>
        );
    }

    function renderMobileCurrencyWidget(currencyItem: CurrencyItem, i: number, _isNative: boolean) {
        return (
            <MenuItem key={i} value={currencyItem.code}>
                <Box
                    sx={{
                        width: '100%',
                        height: '88px',
                        padding: '0 24px',
                        borderRadius: '16px',
                    }}
                >
                    {renderCurrencyWidgetInner(currencyItem, i)}
                </Box>
            </MenuItem>
        );
    }

    const dataLoaded = multichartDataReducer.response && rateReducer.response;

    const chartContent = mobile ? (
        <Paper>
            <Box width='100%' id='mobile-chart-wrapper' ref={multiChartRef}>
                <Stack alignItems='center' divider={<Divider variant='middle' style={{width: '100%'}} />}>
                    {showBuyCryptoButton && (
                        <Button variant='outlined' color='secondary'>
                            {t('buy_currency_button', {currencyName: toCurrencyCode})}
                        </Button>
                    )}
                    <CustomSelect
                        options={currencyWidgetData}
                        renderOption={renderMobileCurrencyWidget}
                        onChange={(e) => setToCurrencyCode(e.target.value as string)}
                        value={toCurrencyCode}
                        native={false}
                        sx={{'& .MuiOutlinedInput-notchedOutline': {border: 'none'}}}
                    />
                    <Box mt={3}>
                        <Chart
                            rates={rateReducer?.response?.rates ?? []}
                            timeframe={timeframe}
                            fromCurrencyCode={fromCurrencyCode}
                            toCurrencyCode={toCurrencyCode}
                            chartHeight={250}
                            chartWidth={chartWidth}
                            mobile
                        />
                    </Box>
                </Stack>
            </Box>
        </Paper>
    ) : (
        <Paper sx={{pl: 0}}>
            <Box id='multichart-wrapper' sx={{display: 'grid', gridTemplateColumns: '300px auto'}} ref={multiChartRef}>
                <Stack direction='column' alignItems='center' justifyContent='center'>
                    {currencyWidgetData.map(getCurrencyWidget)}
                </Stack>

                <Stack direction='column'>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        alignContent='center'
                        spacing={2}
                        sx={{padding: '16px'}}
                    >
                        <TimeframeDropdown
                            id='timeframeDropdown'
                            name='timeframe'
                            value={timeframe}
                            onChange={(e: React.ChangeEvent<any>) => {
                                setTimeframe(e.target.value);
                            }}
                        />
                        <Select
                            name={fromCurrencyCode}
                            value={fromCurrencyCode}
                            onChange={handleBaseCurrencyChange}
                            size='small'
                        >
                            {fromCurrenciesArray.map((currency, i) => (
                                <MenuItem key={i} value={currency}>
                                    {currency}
                                </MenuItem>
                            ))}
                        </Select>
                        {showBuyCryptoButton && (
                            <Button variant='outlined' color='secondary'>
                                {t('buy_currency_button', {currencyName: currencyArray[toCurrencyCode]?.full_name})}
                            </Button>
                        )}
                    </Stack>

                    {chartWidth && (
                        <Chart
                            rates={rateReducer?.response?.rates ?? []}
                            timeframe={timeframe}
                            fromCurrencyCode={fromCurrencyCode}
                            toCurrencyCode={toCurrencyCode}
                            chartHeight={500}
                            chartWidth={chartWidth}
                        />
                    )}
                </Stack>
            </Box>
        </Paper>
    );

    const dataNotAvailableView = (
        <ChartWrapper>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                width='100%'
                height='100%'
                justifyContent='center'
            >
                <Typography align='center'>Data not available at the moment</Typography>
            </Box>
        </ChartWrapper>
    );

    return dataLoaded ? chartContent : dataNotAvailableView;
}

export default renderWithSettings<MultichartProps>(MultiChart);
