import {Box, Chip, Divider, Stack, Tooltip, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../redux/store';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useInterval} from '@simplecoin/core';
import TradingLimitWidget from './TradingLimitWidget';
import {fetchTradeLimits} from '../redux/slices/tradingLimitsSlice';
import {Tile} from './Common/Tile';

const roundDiscount = (n: number) => Math.round(n * 100) / 100;

/**
 * Component that shows user discount.
 */
export default function UserDiscountAndLimit() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    // Loaded during authentication
    const user = useSelector((state: RootState) => {
        return state.user;
    });

    // The limit can change anytime not being under control of front-end.
    useInterval(() => {
        dispatch(fetchTradeLimits());
    }, 5000 * 60);

    return (
        <Box
            sx={{
                maxWidth: '320px',
                my: 2,
            }}
        >
            <Tile bgVariant='neutral'>
                <Stack direction='column' spacing={3} divider={<Divider sx={{borderColor: 'white'}} />}>
                    {user.auth?.response?.user && (
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                                <Typography variant='body2'>{t('user_discount')}</Typography>
                                <Chip
                                    label={roundDiscount(user.auth?.response?.user.total_discount) + ' %'}
                                    color='secondary'
                                    size='small'
                                    sx={{fontWeight: 700}}
                                />
                            </Stack>
                            <Tooltip
                                arrow
                                title={
                                    <>
                                        <Box>
                                            {t('volume_discount') +
                                                ': ' +
                                                roundDiscount(user.auth?.response?.user.volume_discount) +
                                                ' %'}
                                        </Box>
                                        <Box>
                                            {t('personal_discount') +
                                                ': ' +
                                                roundDiscount(user.auth?.response?.user.personal_discount) +
                                                ' %'}
                                        </Box>
                                    </>
                                }
                            >
                                <InfoOutlinedIcon fontSize='small' />
                            </Tooltip>
                        </Stack>
                    )}
                    <TradingLimitWidget />
                </Stack>
            </Tile>
        </Box>
    );
}
