import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from './store';
import {Settings} from '~/typings/settings';
import {handleReducerCases} from '~/helpers/redux';
import {fetchGeneric, ThunkApi} from '~/api';
import {ReducerEnvelope} from '~/typings/Api';
import {getConfigValue} from '~/helpers/config';
import dayjs from '~/config/dayjs';
import {LANGUAGES} from '~/config/params';

export async function getSettings(apiEndpoint): Promise<Settings | null> {
    try {
        const settings = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
        });

        return await settings.json();
    } catch (e) {
        if (getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.error('Failed to fetch settings', e);
        }

        return null;
    }
}

interface settingsReducer {
    settings: {
        isFetching: boolean;
        settingsData?: Settings;
        fetchedAt?: number;
    };
    resendActivationEmail: ReducerEnvelope<null>;
    validateEmailConfirmationToken: ReducerEnvelope<null>;
    syncGuestLanguage: ReducerEnvelope<null>;
}

const initialState: settingsReducer = {
    settings: {isFetching: false},
    resendActivationEmail: {isFetching: false},
    validateEmailConfirmationToken: {isFetching: false},
    syncGuestLanguage: {isFetching: false},
};

export const SETTINGS_LIFETIME_SECONDS = 60; // fetch settings if older than 10 minutes

export const syncGuestLanguage = createAsyncThunk<ReducerEnvelope<null>, string>(
    'syncGuestLanguage',
    async (language, thunkApi) => {
        if (!language) {
            return false;
        }

        if (language.length === 2) {
            const filteredLanguage = LANGUAGES.filter((item) => item.code === language);

            if (filteredLanguage.length !== 0) {
                language = filteredLanguage[0].region;
            }
        }

        const response = await fetchGeneric(
            {
                method: 'post',
                url: `user/language?code=${language}`,
            },
            thunkApi as ThunkApi,
            false
        );
        return response.data;
    }
);

export const validateEmailConfirmationToken = createAsyncThunk<
    ReducerEnvelope<null>,
    {token: string; showSnackbars: boolean}
>('validateEmailConfirmationToken', async ({token, showSnackbars}, thunkApi) => {
    const response = await fetchGeneric(
        {
            method: 'get',
            url: `user/email-confirmation?token=${token}`,
        },
        thunkApi as ThunkApi,
        showSnackbars
    );
    return response.data;
});

export const resendActivationEmail = createAsyncThunk<ReducerEnvelope<null>, string>(
    'resendActivationEmail',
    async (email, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'user/resend-email-confirmation',
                data: {email},
            },
            thunkApi as ThunkApi,
            false
        );
        return response.data;
    }
);

export interface ReportErrorProps {
    url: string;
    message: string;
    componentStack: string;
    date: Date;
    appVersion: string;
}

export const reportError = createAsyncThunk('reportError', async (props: ReportErrorProps, thunkApi) => {
    const response = await fetchGeneric(
        {
            method: 'post',
            url: 'l/og',
            data: props,
        },
        thunkApi as ThunkApi,
        false
    );
    return response.data;
});

export const fetchSettings = createAsyncThunk<{settingsData: Settings | null}, string>(
    'settings',
    async (apiEndpoint: string) => {
        const settings = await getSettings(apiEndpoint);
        return {settingsData: settings, fetchedAt: dayjs().unix()};
    },
    {
        // @ts-ignore
        condition: (userId, {getState}) => {
            // @ts-ignore
            const state: RootState = getState();
            const settingsPresent = !!state.site.settings.settingsData;
            if (settingsPresent) {
                const currentTimestamp = dayjs().unix();
                if (
                    state.site.settings.fetchedAt &&
                    state.site.settings.fetchedAt < currentTimestamp - SETTINGS_LIFETIME_SECONDS
                ) {
                    return true;
                }
                // Already fetched, don't need to re-fetch
                return false;
            }
        },
    }
);

const siteSlice = createSlice({
    name: 'site',
    initialState,
    extraReducers: (builder) => {
        handleReducerCases(fetchSettings, 'settings', builder);
        handleReducerCases(resendActivationEmail, 'resendActivationEmail', builder);
        handleReducerCases(validateEmailConfirmationToken, 'validateEmailConfirmationToken', builder);
        handleReducerCases(syncGuestLanguage, 'syncGuestLanguage', builder);
    },
    reducers: {
        invalidateResendActivationEmail: (state) => {
            state.resendActivationEmail = initialState.resendActivationEmail;
        },
        invalidateValidateEmailConfirmationToken: (state) => {
            state.validateEmailConfirmationToken = initialState.validateEmailConfirmationToken;
        },
    },
});

export const {invalidateResendActivationEmail, invalidateValidateEmailConfirmationToken} = siteSlice.actions;

export const {actions, reducer} = siteSlice;
