import {getConfigValue} from '~/helpers/config';

/**
 * Helper for parsing json
 * @param {string} json
 * @return {any}
 */
export function parseJson(json: string): any | undefined {
    let result;

    try {
        result = JSON.parse(json);
    } catch (e) {
        if (getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Failed to parse json');
        }
    }

    return result;
}
