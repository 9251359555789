/**
 * Config values that must be available for core library to work
 */
export interface CommonFrontendConfig {
    recaptchaSiteKey?: string;
    trezorConnectUrl: string;
    trezorDevMail: string;
    exchangeUrl: string;
    walletUrl: string;
    landingUrl: string;
    apiUrl: string;
    frontEndType: 'wallet' | 'exchange' | 'landing';
    environment: 'production' | 'development';
    preferredCountries: Array<string>;
}

declare global {
    interface Window {
        config: CommonFrontendConfig; // this config should always be there
    }
}

type ConfigKey<T> = keyof T | keyof CommonFrontendConfig;

/**
 * Returns config value from `window.config`, where it must have been set by frontend.
 *
 * For use with additional config you can do:
 * `const test = getConfigValue<string, {testV: string}>('testV', '');`
 */
export function getConfigValue<R, T = void>(key?: ConfigKey<T>, defaultValue: any = null): R {
    if (key && typeof window !== 'undefined') {
        // could run in node in which case window will not be defined
        const value = window?.config?.[String(key)];
        const configEnv = window?.config?.['environment'];

        if (value === undefined && configEnv === 'development') {
            // eslint-disable-next-line no-console
            console.warn(`Config key ${String(key)} seems to be missing or empty`);
        }
        return value ?? defaultValue;
    }
    return defaultValue;
}
