import {useTranslation} from 'react-i18next';
import {formatAmount} from '../helpers/price';
import {useCurrency} from '../hooks/currency';
import {Tooltip} from '@mui/material';
import React from 'react';

interface AmountProps {
    value?: string | number | null;
    currencyCode: string;
    appendCurrencyCode?: boolean;
    textBefore?: string;
    textAfter?: string;
}

export default function FormattedAmount({
    value,
    currencyCode,
    appendCurrencyCode = false,
    textBefore,
    textAfter,
}: AmountProps) {
    const {i18n} = useTranslation();
    const currency = useCurrency({code: currencyCode});

    if (value == null || !currency) {
        return null;
    }

    const formattedValue = formatAmount(value, currency.fixed_decimals, i18n.language);

    let result = formattedValue;

    if (appendCurrencyCode) {
        result += ' ' + currencyCode.toUpperCase();
    }

    if (textBefore) {
        result = textBefore + ' ' + result;
    }

    if (textAfter) {
        result = result + ' ' + textAfter;
    }

    const valueStr = typeof value === 'string' ? value : value.toString();

    if (formattedValue === valueStr) {
        // no need to show tooltip as formatted amount is equal to the provided one
        return <>{result}</>;
    }

    return (
        <Tooltip title={valueStr} arrow>
            <span>{result}</span>
        </Tooltip>
    );
}
