import React, {useRef} from 'react';
import {Typography, Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useBackendSettings, CustomButton, Dialog, useInterval, DialogInterface} from '@simplecoin/core';
import {logIfDevelopment} from '~/src/helpers/log';

/**
 * Renders dialog asking user to refresh the page if the application version has been found to be outdated
 */
export default function NewVersionNotifier() {
    const {t} = useTranslation();
    const refresherDialogRef = useRef<DialogInterface>(null);

    useInterval(async () => {
        try {
            const currentDate = new Date();
            const milliseconds = currentDate.getMilliseconds();
            // prevent cashing by cloudflare
            const response = await fetch('/version.json?' + milliseconds);
            const {version} = await response.json();
            const currentVersion = document.getElementById('head')?.getAttribute('data-app-v');

            if (version && currentVersion && version !== currentVersion) {
                refresherDialogRef?.current?.openDialog();
            }
        } catch (e) {
            logIfDevelopment(e, {level: 'error'});
        }
    }, 1000 * 60 * 5);

    const brandName = useBackendSettings('brand_name', '');

    /**
     * Reload page
     */
    function handleUpdate() {
        window.location.reload();
    }

    return (
        <div>
            <Dialog withCancelButton={false} ref={refresherDialogRef}>
                <Stack direction='column' spacing={4} maxWidth={600} alignItems='center'>
                    <Typography align='center' variant='h3'>
                        {t('react_new_app_version_available_dialog_title', {brandName})}
                    </Typography>

                    <CustomButton onClick={handleUpdate} sx={{maxWidth: '160px'}}>
                        {t('update_application_version')}
                    </CustomButton>

                    <Typography variant='body2' align='center'>
                        {t('react_new_app_version_available_dialog_content')}
                    </Typography>
                </Stack>
            </Dialog>
        </div>
    );
}
