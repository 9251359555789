import {SvgIcon} from '@mui/material';
import React from 'react';

export default function PauseIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' width='22' height='22' {...props}>
            <g fill='none'>
                <path d='M13 7V15' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M9 7V15' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                <path
                    d='M8 21H14C18.5 21 21 18.5 21 14V8C21 3.5 18.5 1 14 1H8C3.5 1 1 3.5 1 8V14C1 18.4392 3.5 21 8 21Z'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </SvgIcon>
    );
}
