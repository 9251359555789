import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import {
    manageResponse,
    // useBackendSettings,
    Form,
    ViewResponse,
    yup,
} from '@simplecoin/core';
import {FormikProps, FormikValues} from 'formik';
import {successSnackbar} from '~/src/snackbars/SnackbarActions';
import {resetPassword, validateResetPasswordToken} from './SiteActions';
import {HeadManager, Space} from '../../components';
import {RootState, useAppDispatch} from '~/src/redux/store';
import {SiteReducerInterface} from '~/src/pages/site/siteReducer';
import PasswordField from '~/src/components/PasswordField';
import FormLayout from '~/src/components/Guest/FormLayout';
import {useNavigate, useParams} from 'react-router-dom';
import useReCaptcha from '~/src/hooks/useReCaptcha';

const formProps = {name: 'reset-password-form'};

/**
 * Renders reset password page where user allowed to enter new password if correct token provided.
 *
 * On component mount it send request to validate token, if token valid allowes to see mounted content, otherwise
 * user sent to login page.
 */
export default function ResetPassword() {
    const {validateResetPasswordToken: resetTokenReducer, resetPassword: passwordResetReducer} = useSelector<
        RootState,
        SiteReducerInterface
    >((state) => state.user);

    const showCaptcha = useReCaptcha('passwordReset');
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {token} = useParams();
    const navigate = useNavigate();
    const [formik, setFormik] = useState();

    useEffect(() => {
        dispatch(validateResetPasswordToken(token));
    }, []);

    useEffect(() => {
        manageResponse({
            dispatch,
            formik,
            reducer: resetTokenReducer,
        });
    }, [resetTokenReducer]);

    useEffect(() => {
        manageResponse({
            dispatch,
            formik,
            reducer: passwordResetReducer,
            onSuccess: () => {
                dispatch(successSnackbar({message: t('new_password_saved')}));
                navigate('/login');
            },
        });
    }, [passwordResetReducer]);

    /**
     * handes reset form submission
     * @param values
     * @param formik
     */
    function handleSubmit(values, formik) {
        setFormik(formik);
        dispatch(resetPassword({token, ...values}));
    }

    const fields = [
        {
            type: 'custom',
            name: 'password',
            // eslint-disable-next-line react/display-name
            component: ({values, handleChange}: FormikProps<FormikValues>) => (
                <>
                    <Space size={10} />
                    <PasswordField
                        name='password'
                        value={values.password}
                        onChange={handleChange}
                        label={t('form_label_new_password')}
                        autoComplete='new-password'
                        withStrengthIndicator
                    />
                </>
            ),
        },
        {
            type: 'custom',
            name: 'password_again',
            // eslint-disable-next-line react/display-name
            component: ({values, handleChange}: FormikProps<FormikValues>) => (
                <>
                    <Space size={10} />
                    <PasswordField
                        name='password_again'
                        value={values.password_again}
                        onChange={handleChange}
                        label={t('label_new_password_again')}
                        autoComplete='new-password'
                    />
                </>
            ),
        },
        {
            type: 'text',
            inputMode: 'numeric',
            pattern: '[0-9]*',
            name: 'code',
            label: t('label_2fa_code'),
            value: '',
            hint: t('hint_login_form_2fa_code'),
        },

        {
            type: 'captcha',
            name: 'reCaptcha',
            visible: showCaptcha,
        },
    ];

    const rules = yup.object().shape({
        password: yup.string().max(255).min(8).required(),
        password_again: yup
            .string()
            .min(8)
            .max(255)
            .required()
            .oneOf([yup.ref('password'), null], t('validation_error_not_matching_passwords')),
        reCaptcha: showCaptcha
            ? yup.string().nullable().required() // eslint-disable-line
            : yup.string(),
        code: yup.string().length(6),
    });

    /**
     * returns content to render after token verification succeeds
     * @return {*}
     */
    function onFetched() {
        return (
            <>
                <Typography align='center' variant='h5'>
                    {t('pls_choose_new_password')}
                </Typography>

                <Form
                    reducer={resetTokenReducer}
                    rules={rules}
                    fields={fields}
                    formProps={formProps}
                    onSubmit={handleSubmit}
                    submitText={t('send')}
                />
            </>
        );
    }

    return (
        <FormLayout>
            <HeadManager title={t('reset_password')} />
            <ViewResponse reducer={resetTokenReducer} loaderInline={false} fetched={onFetched} />
        </FormLayout>
    );
}

ResetPassword.displayName = 'ResetPassword';
