import React from 'react';
import {styled} from '@mui/material/styles';
import {WidthBreakpoint} from '~/src/config/localParams';

const Content = styled('div')`
    margin: 0 auto;
    flex: 1 0 auto;
    flex-direction: column;
    width: auto;
    padding: 0 15px;

    @media (min-width: ${WidthBreakpoint.md}) {
        width: 900px;
    }

    @media (min-width: ${WidthBreakpoint.lg}) {
        width: 970px;
    }

    @media (min-width: ${WidthBreakpoint.xl}) {
        width: 1170px;
    }
`;

const Wrapper = styled('div')`
    padding-top: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: ${WidthBreakpoint.xl}) {
        padding-top: 20px;
    }
`;

const Page = styled('div')`
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
    flex: 1;
    padding: 10%;
    color: #333;
    font-size: 14px;

    @media (max-width: ${WidthBreakpoint.xl}) {
        padding: 15px;
    }
`;

export interface InfoPageProps {
    children: React.ReactNode;
}

/**
 * Renders info page mostly for guest users, such as posts or about page.
 *
 * @param children
 * @return {*}
 * @constructor
 */
export default function InfoPage({children}: InfoPageProps) {
    return (
        <Content>
            <Wrapper>
                <Page>{children}</Page>
            </Wrapper>
        </Content>
    );
}

InfoPage.displayName = 'InfoPage';
