// const WAValidator = require('multicoin-address-validator');
import {bech32} from 'bech32';
import {getConfigValue} from '~/helpers/config';
import WAValidator from 'multicoin-address-validator';

/**
 * Get specified position from provided path.
 * It would return null in case when:
 * - position is off range
 * - position exists, but empty
 * - path is empty
 * - position value is not numeric
 * @param {string} path
 * @param {number} position
 * @return {number | null}
 */
export function getIntegerFromPathAtPosition(path: string, position: number): number | null {
    if (!path) {
        return null;
    }

    const split = path.split('/');

    if (split.length < position) {
        return null;
    }

    if (!split[position]) {
        return null;
    }

    const pathPart = Number.parseInt(split[position]);

    if (isNaN(pathPart)) {
        return null;
    }

    return pathPart;
}

/**
 * Extracts account number from BIP44/49/84 address derivation path
 * @link https://github.com/bitcoin/bips/blob/master/bip-0084.mediawiki
 * The path structure is as following:  m / purpose' / coin_type' / account' / change / address_index
 * For example: Account 0, first receiving address = m/84'/0'/0'/0/0
 *
 * @param {string} path
 * @return {number | null}
 */
export function getAccountNumberFromPath(path = ''): number | null {
    return getIntegerFromPathAtPosition(path, 3);
}

/**
 * Extracts address index from BIP44/49/84 path see getAccountNumberFromPath for explanation
 *
 * @param {string} path
 * @return {number | null}
 */
export function getAddressIndexFromPath(path = ''): number | null {
    return getIntegerFromPathAtPosition(path, 5);
}

/**
 * Converts amount from bitcoin to satoshis (suitable for all bitcoin-like coins)
 * This function purposely avoids multiplication/division not to loose precision 0.0045 * 100 = 0.44999999999999996 in js
 * bitcoin to sat shifts the decimal point by 8 digits
 * @param {string} amountBitcoins
 * @return {string | null}
 */
export function toSatoshis(amountBitcoins: string): string | null {
    if (isNaN(Number(amountBitcoins))) {
        return null;
    }

    const [integer, decimal] = amountBitcoins.split('.');

    // no decimal, just multiply by 100
    if (typeof decimal === 'undefined') {
        return String(Number(amountBitcoins) * 100000000);
    }

    // bitcoin to decimal shifts the decimal point by 8 digits
    const addedZeroes = '0'.repeat(8 - decimal.length);

    if (integer === '0') {
        return String(Number(decimal + addedZeroes));
    }

    return String(Number(integer + decimal + addedZeroes));
}

/**
 * Verifies whether supplied address id of format bech32
 * @param {string} address
 * @return {boolean}
 */
export function isBech32(address: string): boolean {
    try {
        bech32.decode(address);
        return true;
    } catch (e) {
        return false;
    }
}

interface AddressAndDestinationTag {
    address: string;
    destinationTag?: string;
}

/**
 * Extracts address and destination tag from internal format
 * example: ripple:rD1TMTduQXiaNPWi86LpsMz79cktky4GUW?dt=1352518838
 * @param string
 * @param acceptAddressOnly
 */
export function decodeXrpAddressAndDestinationTag(string: string, acceptAddressOnly = false): AddressAndDestinationTag {
    const matcher = RegExp(/^ripple:([a-zA-Z\d]{25,35})(\?dt=(\d+))?$/);
    const results = matcher.exec(string);

    if (!results || results.length < 4) {
        if (acceptAddressOnly) {
            const addressMatcher = RegExp(/^[a-zA-Z\d]{25,35}$/);
            const addressResults = addressMatcher.exec(string);

            if (addressResults?.length && addressResults.length > 0) {
                return {address: string, destinationTag: undefined};
            }
        }

        return {address: 'N/A', destinationTag: undefined};
    }

    return {address: results[1], destinationTag: results[3]};
}

// export async function validateAddress(address?: string, currency?: string): Promise<boolean> {
//     if (!address || !currency) {
//         return false;
//     }

//     try {
//         let apiUrl = getConfigValue<string>('apiUrl', '');
//         apiUrl = apiUrl.replace('/v1', ''); // remove the /api prefix
//         const queryParams = new URLSearchParams({address: address, currency: currency});
//         const headers = {
//             'Content-type': 'application/json',
//         };

//         if (getConfigValue('environment') === 'development') {
//             queryParams.append('network', 'testnet');
//         }

//         const validateResult = await fetch(`${apiUrl}/api/validate-address?` + queryParams, {
//             method: 'GET',
//             headers: headers,
//         });

//         const result = await (validateResult.json() as Promise<{isValid: boolean}>);
//         return result.isValid;
//     } catch (e) {
//         if (getConfigValue('environment') === 'development') {
//             // eslint-disable-next-line no-console
//             console.error('Failed to validate address', e);
//         }

//         return false;
//     }
// }

export async function validateAddress(address?: string, currency?: string): Promise<boolean> {
    if (!address || !currency) {
        return false;
    }

    try {
        const networkType = getConfigValue('environment') === 'development' ? 'testnet' : 'prod';
        const result: boolean = WAValidator.validate(address, currency, networkType);
        return result;
    } catch (e) {
        if (getConfigValue('environment') === 'development') throw e;
        return false;
    }
}
