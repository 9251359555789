import {useBackendSettings} from '@simplecoin/core';

export default function useReCaptcha(useCaseCode: 'passwordReset' | 'login' | 'signup' | 'guestOrder'): boolean {
    const showCaptchaSetting: string = useBackendSettings('showCaptcha', '');
    if (showCaptchaSetting == null) {
        return false;
    } else {
        const showRecaptchaArray = showCaptchaSetting.split(/[,;]+/).map((s) => s.trim());
        return showRecaptchaArray.includes('all') || showRecaptchaArray.includes(useCaseCode);
    }
}
