import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ReducerEnvelope} from '~/typings/Api';
import {Wallet, WalletList} from '~/typings/Order';
import {fetchGeneric, ThunkApi} from '~/api';
import {handleReducerCases} from '~/helpers/redux';

export interface WalletReducerInterface {
    list?: ReducerEnvelope<WalletList>;
    view?: ReducerEnvelope<Wallet>;
    update?: ReducerEnvelope<Wallet>;
    create?: ReducerEnvelope<Wallet>;
    delete?: ReducerEnvelope<null>;
}

const initialState: WalletReducerInterface = {
    list: {isFetching: false},
    view: {isFetching: false},
    update: {isFetching: false},
    create: {isFetching: false},
    delete: {isFetching: false},
};

export interface Pagination {
    page?: number | null;
    perPage?: number;
}

export interface PaginatedProps {
    pagination?: Pagination;
}

export interface FetchWalletsProps extends PaginatedProps {
    currencyId?: number;
}

const defaultPaginationProps: Pagination = {
    perPage: 12,
};

export const fetchWallets = createAsyncThunk<ReducerEnvelope<WalletList>, FetchWalletsProps>(
    'wallet/list',
    async ({pagination, currencyId}, thunkApi) => {
        if (!pagination) {
            pagination = defaultPaginationProps;
        }
        const query = {expand: 'currency'};

        if (currencyId) {
            query['filter'] = {currency_id: currencyId};
        }

        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'wallet',
                params: {...pagination, ...query},
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export const fetchWallet = createAsyncThunk<ReducerEnvelope<WalletList>, {id: number; failureUrl?: string}>(
    'wallet/view',
    async ({id, failureUrl}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'wallet/view',
                params: {id, expand: 'currency'},
            },
            thunkApi as ThunkApi,
            true,
            failureUrl
        );

        return res.data;
    }
);

export const updateWallet = createAsyncThunk<ReducerEnvelope<WalletList>, {id: number; label: string}>(
    'wallet/update',
    async ({id, label}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: 'wallet/update',
                params: {id},
                data: {address_label: label},
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export const deleteWallet = createAsyncThunk<ReducerEnvelope<WalletList>, number>(
    'wallet/delete',
    async (id, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'delete',
                url: `wallet/delete?id=${id}`,
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export const createWallet = createAsyncThunk<ReducerEnvelope<WalletList>, CreateWalletProps>(
    'wallet/create',
    async (props, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: 'wallet/create',
                data: {...props},
            },
            thunkApi as ThunkApi
        );

        return res.data;
    }
);

export interface CreateWalletProps {
    address: string;
    address_label?: string | null;
    address_derivation_path?: string;
    arbitrary_data?: string | null;
    currency_id: number;
    code: number; // 2FA CODE IF SET UP
}

const slice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        invalidateWalletDelete: (state) => {
            state.delete = initialState.delete;
        },
        invalidateWalletUpdate: (state) => {
            state.update = initialState.update;
        },
        invalidateWalletCreate: (state) => {
            state.create = initialState.create;
        },
    },
    extraReducers: (builder) => {
        handleReducerCases(fetchWallet, 'view', builder);
        handleReducerCases(fetchWallets, 'list', builder);
        handleReducerCases(deleteWallet, 'delete', builder);
        handleReducerCases(updateWallet, 'update', builder);
        handleReducerCases(createWallet, 'create', builder);
    },
});

export const {actions, reducer} = slice;
export const {invalidateWalletDelete, invalidateWalletUpdate, invalidateWalletCreate} = slice.actions;
