import React from 'react';
import swapIcon from '../../assets/images/swap-icon.svg';
import {styled} from '@mui/material';
import {CustomIcon} from '../../typings/IconsTypings';

interface CircleProps {
    marginMobile?: number;
    topMobile?: number;
}

export const Circle = styled('div')<CircleProps>(({marginMobile, topMobile}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '35px',
    top: '-17px',
    width: '42px',
    height: '42px',
    background: '#fff',
    boxShadow: '0px 4px 8px rgba(42, 28, 82, 0.08)',
    borderRadius: '50px',
    'z-index': 10,
    ':hover': {
        border: '2px solid #fd6300',
        boxShadow: '0px 0px 0px 4px rgba(253, 99, 0, 0.08)',
    },
    '@media (max-width: 900px) and (min-width: 350px)': {
        right: '55%',
        marginRight: `${marginMobile}px`,
        top: `${topMobile}px`,
    },
    '@media (max-width: 400px) and (min-width: 350px)': {
        right: '60%',
    },
}));

interface SwapIconInterface extends CustomIcon, CircleProps {}

export const SwapIcon = ({height = 18, width = 18, marginMobile = -176, topMobile = -17}: SwapIconInterface) => {
    return (
        <Circle marginMobile={marginMobile} topMobile={topMobile}>
            <img src={swapIcon} height={height} width={width} />
        </Circle>
    );
};
