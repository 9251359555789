import React, {Suspense} from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import {Loader} from '~/src/components';
import store from '~/src/redux/store';
import {getConfigValue} from '@simplecoin/core';

if (window.self !== window.top) window.top.location = window.location.href;

/**
 * This function will make error "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node."
 * Not break the app, instead it will only show console error.
 *
 * This should be removed once a better fix is available: https://github.com/facebook/react/issues/11538
 *   https://bugs.chromium.org/p/chromium/issues/detail?id=872770
 */
function applyRemoveNodeWorkaround() {
    if (typeof Node === 'function' && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function (child) {
            if (child.parentNode !== this) {
                if (getConfigValue('environment') === 'development') {
                    // eslint-disable-next-line no-console
                    console.error('Cannot remove a child from a different parent', child, this);
                }
                return child;
            }
            // eslint-disable-next-line prefer-rest-params
            return originalRemoveChild.apply(this, arguments);
        };

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function (newNode, referenceNode) {
            if (referenceNode && referenceNode.parentNode !== this) {
                if (getConfigValue('environment') === 'development') {
                    // eslint-disable-next-line no-console
                    console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
                }
                return newNode;
            }
            // eslint-disable-next-line prefer-rest-params
            return originalInsertBefore.apply(this, arguments);
        };
    }
}

applyRemoveNodeWorkaround();

render(
    <Suspense fallback={<Loader />}>
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>,
    document.getElementById('root')
);
