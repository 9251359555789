import React, {useRef, useLayoutEffect, useState, useEffect} from 'react';
import {BrowserHistory, createBrowserHistory} from 'history';
import {Router as BaseRouter} from 'react-router-dom';
import {isObject} from 'formik';
import i18n from '~/src/config/i18n';
import {ensureLanguageInPath, ensureLanguageOnly} from '@simplecoin/core';

/**
 * Custom BrowserRouter that injects custom history
 * @param children
 * @constructor
 */
export default function BrowserRouter({children}) {
    const historyRef = useRef<BrowserHistory>();
    if (historyRef.current == null) {
        historyRef.current = createBrowserHistory({window});
    }

    const history = historyRef.current;

    useEffect(() => {
        const originalPushFunction = history.push;

        // if path is string, we assume it is a pathname only and provide hash and search from the history object
        // if path is a location object, its search and hash are passed to the native push function
        // @ts-ignore see above
        history.push = function (pathOrObject, state) {
            try {
                let result;
                let urlUnchanged = true;

                const language = ensureLanguageOnly(i18n.language);

                if (isObject(pathOrObject)) {
                    result = {...pathOrObject}; // making a copy of the originally passed object as it may be history.location
                    result.pathname = ensureLanguageInPath({path: pathOrObject.pathname, language});
                    urlUnchanged = result.pathname === history.location.pathname;
                } else if (typeof pathOrObject === 'string') {
                    result = ensureLanguageInPath({path: pathOrObject, language});
                    urlUnchanged = result === history.location.pathname;
                }

                if (urlUnchanged) {
                    return null;
                }
                return originalPushFunction(result, state);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Failed to override history push func', e);
            }
            return originalPushFunction(pathOrObject, state);
        };
    }, []);

    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    // if user goes to location without language prefix, this function will add it and push to history
    useLayoutEffect(() => {
        history.listen((listener) => {
            const language = ensureLanguageOnly(i18n.language);
            const newPathname = ensureLanguageInPath({path: listener.location.pathname, language});

            if (newPathname !== listener.location.pathname) {
                // push changed location
                history?.push(newPathname);
            } else {
                const newLocation = {...listener, location: {...listener.location, pathname: newPathname}};
                // if no pathname change is needed, just set the current location
                setState(newLocation);
            }
        });
    }, [history]);

    return (
        <BaseRouter location={state.location} navigationType={state.action} navigator={history}>
            {children}
        </BaseRouter>
    );
}
