import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import DOMPurify from 'dompurify';
import {getConfigValue} from '@simplecoin/core';

const getGuery = () => `query last3Posts {
  posts(where: {orderby: {field: DATE, order: DESC}}, first: 3) {
    edges {
      node {
        id
        featuredImage {
          node {
            sourceUrl
          }
        }
        date
        slug
        title
        excerpt
        link
      }
    }
  }
}`;

const headers = {'Content-Type': 'application/json'};

export const listBlogPosts = createAsyncThunk<BlogPost[], string>('blog/list', async () => {
    headers['Authorization'] = 'Basic ' + Buffer.from('developer:Javelin').toString('base64');
    const response = await fetch(getConfigValue('blogApiUrl'), {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query: getGuery(),
        }),
    });

    try {
        const json = await response.json();
        if (json.errors) {
            // eslint-disable-next-line no-console
            console.error(json.errors);
        }

        return json.data.posts.edges.map(({node}) => {
            const title = DOMPurify.sanitize(node.title);
            const excerpt = DOMPurify.sanitize(node.excerpt);
            return {
                ...node,
                title,
                excerpt,
                imageUrl: node?.featuredImage?.node?.sourceUrl,
            };
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw new Error('Failed to fetch wordpress GraphQL API');
    }
});

interface BlogPost {
    id: string;
    date: string;
    slug: string;
    title: string;
    link: string;
    excerpt: string;
    imageUrl: string;
}

interface BlogPostsReducerInterface {
    list: {
        isFetching: boolean;
        posts?: BlogPost[];
    };
}

const initialState: BlogPostsReducerInterface = {
    list: {isFetching: false},
};

const slice = createSlice({
    name: 'blog',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(listBlogPosts.pending, (state) => {
            state.list = {isFetching: true};
        });

        builder.addCase(listBlogPosts.fulfilled, (state, action) => {
            state.list = {isFetching: false};
            state.list.posts = action.payload;
        });

        builder.addCase(listBlogPosts.rejected, (state) => {
            state.list = {isFetching: false};
        });
    },
    reducers: {},
});

export const {actions, reducer} = slice;
