import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchGeneric, handleReducerCases, ThunkApi, ReducerEnvelope} from '@simplecoin/core';

export interface ProfileInterface {
    data: ReducerEnvelope<Profile>;
    submit: ReducerEnvelope<null>;
    submitReadyForVerification: ReducerEnvelope<null>;
    submitWalletConsent: ReducerEnvelope<null>;
}

/**
 *  Types of object returned from profile/info
 */
export interface Profile {
    person?: Person;
    checks?: Checks;
    extra?: ExtraProfileData;
    ready_for_verification?: boolean; // can be undefined due to updates where the attribut can be omitted
    verified?: boolean; // can be undefined due to updates where the attribut can be omitted
    wallet_policy_consent?: boolean; // can be undefined due to updates where the attribut can be omitted
}

export interface Person {
    first_name?: string;
    last_name?: string;
    birthday?: number;
    phone?: string;
    email: string;
    personal_id?: string;
    country?: string;
    city?: string;
    street?: string;
    zipcode?: string;
    is_politician?: boolean;
    house_number?: string;
    citizenship1?: string;
    citizenship2?: string;
}

export interface Checks {
    primary_document_provided: boolean;
    secondary_document_provided: boolean;
    selfie_id_provided: boolean;
    verification_payment: 'received' | 'verified' | null;
    is_phone_verified: boolean;
    has_any_account: boolean;
}

export interface ExtraProfileData {
    purpose_of_crypto_trading?: Array<string>;
    other_trading_purpose_description?: string;
    when_should_we_call?: string;
    has_basic_income?: boolean;
    checklist?: Array<string>;
    primary_document_type?: string;
    secondary_document_type?: string;
}

export interface Income {
    created?: number;
    approved?: number;
    amount?: number;
    currency?: string;
    frequency?: 'one_off' | 'monthly' | 'quarterly' | 'semi-yearly' | 'yearly';
    source?: string;
    companyName?: string;
    positionInCompany?: string;
    description?: string;
    state: 'unapproved_change' | 'submited' | 'approved';
    deleted: boolean;
}

export interface TradingLimitStructure {
    allowed_trading_amount: number;
    currency: string;
    limits: Array<TradingLimit>;
    limit_extension: TradingLimitExtension;
}

export interface TradingLimits {
    allowed_trading_amount: number;
    limits: Array<TradingLimit>;
}

export interface TradingLimit {
    created: number;
    limit_amount: number;
    remaining_amount: number;
    currency: string;
    window_in_months: number;
}

export interface TradingLimitExtension {
    amount: number;
    currency: string;
    history: Array<TradingLimitExtensionHistoryItem>;
}

export interface TradingLimitExtensionHistoryItem {
    date: number;
    amount: number;
    currency: string;
    note: string;
    changed_by: number;
}

const initialState: ProfileInterface = {
    data: {isFetching: false},
    submit: {isFetching: false},
    submitReadyForVerification: {isFetching: false},
    submitWalletConsent: {isFetching: false},
};

export const getProfileData = createAsyncThunk<ReducerEnvelope<Profile>>('profile/data', async (_props, thunkApi) => {
    const response = await fetchGeneric(
        {
            url: 'profile/get-data',
        },
        thunkApi as ThunkApi
    );

    return response.data;
});

export const submitProfileData = createAsyncThunk<ReducerEnvelope<null>, Profile>(
    'profile/submit',
    async (props, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'profile/update-data',
                data: props,
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

export const submitProfileReadyForVerification = createAsyncThunk<ReducerEnvelope<null>>(
    'profile/submitReadyForVerification',
    async (_props, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'profile/ready-for-verification',
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

export const submitWalletConsent = createAsyncThunk<ReducerEnvelope<null>>(
    'submitWalletConsent',
    async (_props, thunkApi) => {
        const response = await fetchGeneric(
            {
                method: 'post',
                url: 'profile/wallet-consent',
            },
            thunkApi as ThunkApi
        );

        return response.data;
    }
);

const slice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: (builder) => {
        handleReducerCases(getProfileData, 'data', builder);
        handleReducerCases(submitProfileData, 'submit', builder);
        handleReducerCases(submitProfileReadyForVerification, 'submitReadyForVerification', builder);
        handleReducerCases(submitWalletConsent, 'submitWalletConsent', builder);
    },
    reducers: {
        invalidateSubmitProfile: (state) => {
            state.submit = initialState.submit;
        },
        invalidateProfileData: (state) => {
            state.data = initialState.data;
        },
    },
});

export const {actions, reducer} = slice;
export const {invalidateSubmitProfile, invalidateProfileData} = slice.actions;
