import {
    REQUEST_FETCH_SETTINGS,
    REQUEST_FETCH_SETTINGS_FAILURE,
    REQUEST_FETCH_SETTINGS_SUCCESS,
    REQUEST_UPDATE_SETTINGS,
    REQUEST_UPDATE_SETTINGS_FAILURE,
    REQUEST_UPDATE_SETTINGS_SUCCESS,
    REQUEST_UPDATE_SETTINGS_INVALIDATE,
    FetchUpdateSettingsProps,
} from './SettingsActions';
import {Action} from '~/src/typings';
import {Settings} from '~/src/typings/endpoints/settings/Settings';
import {ReducerEnvelope} from '@simplecoin/core';

export interface SettingsReducerInterface {
    userSettings: ReducerEnvelope<Settings>;
    updateSettings?: ReducerEnvelope<FetchUpdateSettingsProps>;
}

const INITIAL_STATE = {
    userSettings: {isFetching: false},
    updateSettings: {isFetching: false},
};

/**
 * @param state
 * @param action
 * @return {object}
 */
export default function (
    state: SettingsReducerInterface = INITIAL_STATE,
    action: Action = {}
): SettingsReducerInterface {
    switch (action.type) {
        // fetch settings
        case REQUEST_FETCH_SETTINGS:
            return {...state, userSettings: {isFetching: true}};
        case REQUEST_FETCH_SETTINGS_SUCCESS:
            return {...state, userSettings: {isFetching: false, ...action.payload}};
        case REQUEST_FETCH_SETTINGS_FAILURE:
            return {...state, userSettings: {isFetching: false, ...action.payload}};

        // update settings
        case REQUEST_UPDATE_SETTINGS:
            return {...state, updateSettings: {isFetching: true}};
        case REQUEST_UPDATE_SETTINGS_SUCCESS:
            return {...state, updateSettings: {isFetching: false, ...action.payload}};
        case REQUEST_UPDATE_SETTINGS_FAILURE:
            return {...state, updateSettings: {isFetching: false, ...action.payload}};
        case REQUEST_UPDATE_SETTINGS_INVALIDATE:
            return {...state, updateSettings: undefined};

        default:
            return state;
    }
}
