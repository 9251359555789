export interface TrezorDeviceInfo {
    label?: string;
    device_id?: string;
    model?: string;
}

/**
 * Returns currency name for test network for use in trezor connect
 *
 * The names are shortcuts from: https://github.com/trezor/connect/blob/v8/src/data/coins.json
 *
 * @param {string} currencyCode
 * @return {string | null} null returned when currency code is unknown.
 */
export function getTestnetCoinName(currencyCode: string): string | null {
    currencyCode = currencyCode?.toUpperCase();

    switch (currencyCode) {
        case 'BTC':
            return 'TEST';
        case 'BCH':
            return 'TBCH';
        case 'LTC':
            return 'tLTC';
        case 'ZEC':
            return 'TAZ';
        case 'ETH':
            return 'tROP';
        case 'XRP':
            return 'tXRP';
        default:
            return null;
    }
}

/**
 * Extract relevant infromation about connected trezor hw wallet
 *
 */
export function getTrezorInfo(trezorDevice): TrezorDeviceInfo {
    const trezorInfo: TrezorDeviceInfo = {};

    if (trezorDevice.features) {
        const trezorFeatures = trezorDevice.features;
        trezorInfo.device_id = trezorFeatures.device_id;
        trezorInfo.label = trezorFeatures.label;
        trezorInfo.model = trezorFeatures.model;
    }

    return trezorInfo;
}
