import {SvgIcon} from '@mui/material';
import React from 'react';

export default function PdfIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <g
                stroke='currentColor'
                strokeWidth='2'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                transform='translate(1 1)'
            >
                <path d='M1 10V8C1 3.5 3.5 1 8 1H14C18.5 1 21 3.5 21 8V10' />
                <path d='M6 6H16' />
                <path d='M6 10H16' />
                <path d='M1 21V14H4C4.53043 14 5.03914 14.2107 5.41421 14.5858C5.78929 14.9609 6 15.4696 6 16C6 16.5304 5.78929 17.0391 5.41421 17.4142C5.03914 17.7893 4.53043 18 4 18H1' />
                <path d='M9 14.0004V21.0004H12C12.2642 21.0061 12.5268 20.9582 12.772 20.8597C13.0172 20.7611 13.2399 20.614 13.4267 20.4272C13.6136 20.2403 13.7607 20.0176 13.8592 19.7724C13.9577 19.5272 14.0056 19.2646 14 19.0004V16.0004C14.0056 15.7363 13.9577 15.4737 13.8592 15.2285C13.7607 14.9833 13.6136 14.7606 13.4267 14.5737C13.2399 14.3869 13.0172 14.2398 12.772 14.1412C12.5268 14.0427 12.2642 13.9948 12 14.0004H9Z' />
                <path d='M17 21V14H21' />
                <path d='M17 18H21' />
            </g>
        </SvgIcon>
    );
}
