import {Accordion, AccordionDetails, AccordionSummary, Alert, Box, Stack, useMediaQuery, useTheme} from '@mui/material';
import React, {useEffect} from 'react';
import OrderInfoWidget from './OrderInfoWidget';
import PaymentInfoWidget from './PaymentInfoWidget';
import OrderHistoryWidget from './OrderHistoryWidget';
import {fetchOrder, refetchOrder} from '~/redux/orderSlice';
import {useSelector} from 'react-redux';
import {RootState, useCoreAppDispatch} from '~/redux/store';
import {useTranslation} from 'react-i18next';
import {Order} from '~/typings/Order';
import OrderStatus from '../OrderStatus';
import {OrderViewComponentProps} from '~/typings/OrderViewTypings';
import {initiateAxiosApi} from '~/api';
import renderWithSettings from '../../renderWithSettings';
import {manageResponse} from '~/helpers/api';
import {getSimplifiedOrderStatus} from '~/helpers/order';
import {enqueueSnackbar} from '~/redux/snackbarSlice';
import FetchingBackdrop from '../FetchingBackdrop';
import OrderLifeCycleWidget, {mapOrderStatusHistory} from './OrderLifecycleWidget';
import {useCurrency} from '~/hooks/currency';
import {Currency} from '~/typings/currency';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useInterval from '~/hooks/useInterval';

function OrderView({
    token,
    order,
    userAuth,
    apiUrl,
    trezorSendTransaction,
    trezorSentTransactionTxId,
    paymentConfirmationUpload,
    cryptoAddressDropdown,
}: OrderViewComponentProps) {
    const {detail, statusChange, acceptPrice} = useSelector((state: RootState) => state.order);
    const {t} = useTranslation();
    const guestOrder = detail?.response;
    const dispatch = useCoreAppDispatch();
    const theme = useTheme();
    const isMobileScreenLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const orderData: Order | undefined = order ?? guestOrder;
    const isCanceledWithoutPayment = orderData?.status === 'done' && orderData.status_final === 'cancelled';
    const fromCurrency = useCurrency({id: orderData?.from_currency_id}) as Currency;

    useEffect(() => {
        initiateAxiosApi(apiUrl);
    }, []);

    const emailConfirmed = orderData?.owner?.is_email_confirmed;

    // update order data every 5 minutes
    useInterval(() => {
        dispatch(refetchOrder({id: orderData?.id, token}));
    }, 1000 * 60 * 5);

    useEffect(() => {
        manageResponse({
            reducer: statusChange,
            onSuccess: onSuccessStatusChange,
        });
    }, [statusChange]);

    useEffect(() => {
        if (token) {
            dispatch(fetchOrder({token}));
        }
    }, []);

    /**
     * Handles successful order status change event in manageResponse's onSuccess() callback.
     */
    function onSuccessStatusChange() {
        if (statusChange && statusChange?.statusName) {
            let translation = '';

            if (statusChange.statusName === 'returned') {
                if (fromCurrency?.type === 'fiat') {
                    translation = t('we_will_send_you_money_back_shortly');
                } else {
                    translation = t('we_will_send_you_crypto_back_shortly');
                }
            } else {
                switch (statusChange.statusName) {
                    case 'cancelled':
                        translation = t('order_has_been_canceled');
                        break;
                    case 'proceed':
                        translation = t('order_has_been_proceeded');
                        break;
                    case 'suspended':
                        translation = t('order_has_been_suspended');
                        break;
                }
            }

            dispatch(enqueueSnackbar({message: translation, variant: 'success'}));
        }
    }

    /**
     * Get notification content
     */
    function mapNotification(notification, i) {
        return (
            <div key={i}>
                <Alert severity={notification.variant}>{notification.text}</Alert>
            </div>
        );
    }

    const showOutgoingPayment =
        new Set(['received', 'confirming_outgoing', 'done']).has(orderData?.status ?? '') &&
        orderData?.status_final !== 'cancelled';

    return (
        <>
            {orderData && (
                <Stack direction='row'>
                    <Stack direction='column' spacing={4} sx={{maxWidth: '700px', width: 1}}>
                        {!emailConfirmed && orderData.notifications && orderData.notifications.map(mapNotification)}

                        {isMobileScreenLayout && (
                            <Accordion
                                sx={{
                                    backgroundColor: 'rgba(65, 39, 126, 0.05)',
                                    borderRadius: '16px',
                                    '&:first-of-type': {borderTopLeftRadius: '16px', borderTopRightRadius: '16px'},
                                }}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <OrderStatus
                                        status={orderData.status}
                                        statusDirection={orderData.status_direction}
                                        statusFinal={orderData.status_final}
                                        variant='filled'
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <OrderLifeCycleWidget
                                        simplifiedOrderStatus={getSimplifiedOrderStatus(
                                            orderData.status,
                                            orderData.status_direction,
                                            orderData.status_final
                                        )}
                                        isSuspended={orderData.status_direction === 'suspended'}
                                        incomingCrypto={fromCurrency?.type === 'crypto'}
                                        simplifiedStatusHistory={mapOrderStatusHistory(orderData.statusLogs)}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        )}

                        {emailConfirmed ? (
                            <Stack direction='column' spacing={6}>
                                <OrderInfoWidget
                                    order={orderData}
                                    token={token}
                                    isOverLimit={!!orderData?.owner?.is_over_limit}
                                    user={userAuth?.user}
                                    cryptoAddressDropdown={cryptoAddressDropdown}
                                />

                                {orderData.notifications && orderData.notifications.length > 0 && (
                                    <Stack direction='column' spacing={3}>
                                        {orderData.notifications.map(mapNotification)}
                                    </Stack>
                                )}

                                {showOutgoingPayment && <PaymentInfoWidget type='outgoing' order={orderData} />}

                                {!isCanceledWithoutPayment && (
                                    <PaymentInfoWidget
                                        type='incoming'
                                        order={orderData}
                                        userAuth={userAuth}
                                        trezorSendTransaction={trezorSendTransaction}
                                        trezorSentTransactionTxId={trezorSentTransactionTxId}
                                        paymentConfirmationUpload={paymentConfirmationUpload}
                                    />
                                )}

                                <OrderHistoryWidget orderHistory={orderData?.statusLogs ?? []} />
                            </Stack>
                        ) : (
                            <OrderInfoWidget
                                order={orderData}
                                token={token}
                                isOverLimit={!!orderData?.owner?.is_over_limit}
                            />
                        )}
                    </Stack>
                    {!isMobileScreenLayout && (
                        <Box sx={{mt: '60px', ml: 5}}>
                            <OrderLifeCycleWidget
                                simplifiedOrderStatus={getSimplifiedOrderStatus(
                                    orderData.status,
                                    orderData.status_direction,
                                    orderData.status_final
                                )}
                                isSuspended={orderData.status_direction === 'suspended'}
                                incomingCrypto={fromCurrency?.type === 'crypto'}
                                simplifiedStatusHistory={mapOrderStatusHistory(orderData.statusLogs)}
                            />
                        </Box>
                    )}
                </Stack>
            )}

            <FetchingBackdrop reducerList={[detail, statusChange, acceptPrice]} />
        </>
    );
}

export default renderWithSettings<OrderViewComponentProps>(OrderView);
