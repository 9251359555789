import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {dayjs, changeYupLocale, SiteActions} from '@simplecoin/core';
import {changeI18nLanguage, localeAvailable} from '~/src/config/i18n';
import syncGuestLanguage = SiteActions.syncGuestLanguage;
import {useAppDispatch} from '~/src/redux/store';

/**
 * Handles locale in url changes and yup language initialization
 * @constructor
 */
export default function LanguageChangeListener(): null {
    const navigate = useNavigate();
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const dispatch = useAppDispatch();

    // register on change listener
    useEffect(() => {
        i18n.on('languageChanged', onLanguageChange);
    }, []);

    // initialize yup and dayjs language
    useEffect(() => {
        if (i18n.language) {
            changeYupLocale(t, i18n.language);
            dayjs.locale(i18n.language);
        }
    }, []);

    // Trigger adding locale to url (happens in Router component)
    useEffect(() => {
        navigate(location);
        if (!localeAvailable(i18n.language)) {
            changeI18nLanguage(i18n.language);
        }
    }, [location.pathname, i18n.language]);

    /**
     * Function invoked on languageChange. It handles dispatching syncLanguage to backend and lang change in yup, moment
     * @param lang
     */
    function onLanguageChange(lang) {
        dispatch(syncGuestLanguage(lang));
        changeYupLocale(t, lang);
        dayjs.locale(lang);
    }

    return null;
}

LanguageChangeListener.displayName = 'LanguageChangeListener';
