import {cloneElement, ReactElement} from 'react';

interface ConditionalWrapProps {
    condition: boolean;
    target: ReactElement;
    wrapWith: ReactElement;
}

/**
 * Helper method to reduce code in render
 * Returns target wrapped in wrapper if condition is true, otherwise returns target
 *
 * example of usage:
 *
 * ```js
 * const formWrapper = <div>;
 * const showOpenFormButton = true;
 * const content = <Grid container direction='row' spacing={1} justifyContent='center' alignItems='center'>
 *  const wrapped = conditionalWrap({condition: showOpenFormButton, target: content, wrapWith: formWrapper});
 * ```
 */
export function conditionalWrap({condition, target, wrapWith}: ConditionalWrapProps): any {
    return condition ? cloneElement(wrapWith, {}, target) : target;
}

/**
 * Helper to wrap component
 */
export function wrap(content: any, wrapper: any): any {
    return cloneElement(wrapper, {}, content);
}
