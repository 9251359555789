import {File} from '~/src/typings/models/File';
import {fetchGeneric} from '~/src/helpers/action';

export const REQUEST_FILES = 'REQUEST_FILE';
export const REQUEST_FILES_SUCCESS = 'REQUEST_FILE_SUCCESS';
export const REQUEST_FILES_FAILURE = 'REQUEST_FILE_FAILURE';

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';
export const UPLOAD_FILES_INVALIDATE = 'UPLOAD_FILES_INVALIDATE';

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';
export const DELETE_FILE_INVALIDATE = 'DELETE_FILE_INVALIDATE';

export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAILURE = 'UPDATE_FILE_FAILURE';
export const UPDATE_FILE_INVALIDATE = 'UPDATE_FILE_INVALIDATE';

export interface FileObjectInterface {
    objectName: string;
    objectId: number;
    objectNameExtension?: string;
    objectSubgroup?: string | null;
}

export interface FileInterface extends FileObjectInterface {
    fileId: number;
}

/**
 * Fetch list of files.
 *
 * @param {string} Object name (table name).
 * @param {number} Object id. Normally id.
 * @param {string} Object extension. Unique extension per object name and it.
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchFiles({objectName, objectId, objectNameExtension, objectSubgroup}: FileObjectInterface) {
    return fetchGeneric({
        method: 'get',
        url: 'file/index',
        query: {
            object_name: objectName,
            object_id: objectId,
            object_name_group: objectNameExtension || undefined,
            object_subgroup: objectSubgroup || undefined,
        },
        actions: {
            pre: {
                type: REQUEST_FILES,
                payload: {objectName, objectId, objectNameExtension, objectSubgroup},
            },
            success: (response) => ({
                type: REQUEST_FILES_SUCCESS,
                payload: {
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                    response,
                },
            }),
            failure: {
                type: REQUEST_FILES_FAILURE,
                payload: {objectName, objectId, objectNameExtension, objectSubgroup},
            },
        },
    });
}

/**
 * Fetch upload endpoint.
 *
 * @param {string} objectName
 * @param {number} objectId
 * @param {string} objectNameExtension
 * @param objectSubgroup
 * @param data
 * @param {boolean} fetchFilesOnInvalidate
 * @param fileNames
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchUpload(
    {objectName, objectId, objectNameExtension, objectSubgroup}: FileObjectInterface,
    data: any,
    fetchFilesOnInvalidate = true,
    fileNames: string[]
) {
    return fetchGeneric({
        method: 'post',
        url: 'file/upload',
        query: {
            object_name: objectName,
            object_id: objectId,
            object_name_group: objectNameExtension,
            object_subgroup: objectSubgroup,
        },
        data,
        headers: {'Content-Type': 'multipart/form-data'},
        actions: {
            pre: {
                type: UPLOAD_FILES,
                payload: {objectName, objectId, objectNameExtension, objectSubgroup},
            },
            success: (response) => ({
                type: UPLOAD_FILES_SUCCESS,
                payload: {
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                    fileNames,
                    response,
                },
            }),
            failure: {
                type: UPLOAD_FILES_FAILURE,
                payload: {objectName, objectId, objectNameExtension, objectSubgroup},
            },
        },
        afterSuccess: (dispatch) => {
            dispatch({
                type: UPLOAD_FILES_INVALIDATE,
                payload: {
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                },
            });
            if (fetchFilesOnInvalidate) {
                return dispatch(fetchFiles({objectName, objectId, objectNameExtension, objectSubgroup}));
            }
        },
    });
}

/**
 * Request deleting file with id
 *
 * @param {number} fileId id returned in file/index
 * @param {string} objectName
 * @param {number} objectId
 * @param {string} objectNameExtension
 * @param {string | null} objectSubgroup
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchDeleteFile({
    fileId,
    objectName,
    objectId,
    objectNameExtension,
    objectSubgroup = null,
}: FileInterface) {
    return fetchGeneric({
        method: 'post',
        url: `file/delete?id=${fileId}`,
        actions: {
            pre: {
                type: DELETE_FILE,
                payload: {
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                    fileId,
                },
            },
            success: (response) => ({
                type: DELETE_FILE_SUCCESS,
                payload: {
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                    fileId,
                    response,
                },
            }),
            failure: {
                type: DELETE_FILE_FAILURE,
                payload: {
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                    fileId,
                },
            },
        },
        afterSuccess: (dispatch) => {
            dispatch({
                type: DELETE_FILE_INVALIDATE,
                payload: {
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                },
            });

            return dispatch(fetchFiles({objectName, objectId, objectNameExtension, objectSubgroup}));
        },
    });
}

/**
 * Update file information
 *
 * @param {FileInterface} fileUploadData
 * @param {File} fileData
 * @param {string} name file name
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchUpdate(fileUploadData: FileInterface, fileData: File, name: string) {
    const {objectName, objectId, objectNameExtension, objectSubgroup, fileId} = fileUploadData;
    fileData = {...fileData, name};
    return fetchGeneric({
        method: 'post',
        url: `file/update?id=${fileId}`,
        data: fileData,
        actions: {
            pre: {
                type: UPDATE_FILE,
                payload: {
                    fileId,
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                },
            },
            success: (response) => ({
                type: UPDATE_FILE_SUCCESS,
                payload: {
                    fileId,
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                    response: response?.response,
                },
            }),
            failure: {
                type: UPDATE_FILE_FAILURE,
                payload: {
                    fileId,
                    objectName,
                    objectId,
                    objectNameExtension,
                    objectSubgroup,
                },
            },
        },
        afterSuccess: {
            type: UPDATE_FILE_INVALIDATE,
            payload: {
                data: fileData,
                fileId,
                objectName,
                objectId,
                objectNameExtension,
                objectSubgroup,
            },
        },
    });
}
