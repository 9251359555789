import React from 'react';
import {Stack, Typography, useTheme, useMediaQuery, Divider, IconButton, StackTypeMap} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';
import {truncateMiddle} from '../helpers/text';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

export interface DetailViewItem {
    label: string;
    value: string | React.ReactElement | number | null | undefined;
    action?: React.ReactElement;
    visible?: boolean;
    hideEmpty?: boolean;
    dataTestId?: string;
    // Value longer than given number of characters will be truncated in the middle. Overrides the same but global option for the whole view. Set 0 for keeping the value untouched.
    valueMaxLength?: number;
    // Allow taking the value to a clibpoard.
    copyButton?: boolean;
}

export interface DetailViewProps {
    items: DetailViewItem[];
    hideEmpty?: boolean;
    stackContainerProps?: StackTypeMap;
    stackItemProps?: StackTypeMap;
    labelTypographyVariant?: Variant;
    valueTypographyVariant?: Variant;
    // Values longer than given number of characters will be truncated in the middle. Set 0 for keeping the value untouched.
    valueMaxLength?: number;
}

/**
 * Renders list of items in a table-like view where label on the left side and values on the right side.
 */
export default function DetailView({
    items,
    hideEmpty = false,
    stackContainerProps,
    stackItemProps,
    labelTypographyVariant = 'body2',
    valueTypographyVariant = 'body2',
    valueMaxLength = 30,
}: DetailViewProps) {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    if (!items || items.length === 0) {
        return null;
    }

    // Filter visible items
    items = items.filter((item: DetailViewItem) => {
        if (item.visible === true) {
            return true;
        }

        if (item.visible === false) {
            return false;
        }

        if (hideEmpty && !item.value) {
            return false;
        }

        return true;
    });

    return (
        <Stack direction='column' divider={<Divider />} width={1}>
            {items.map((item: DetailViewItem, key: number) => {
                const innerStackDirection = item.action || mobile ? 'column' : 'row';
                const innerStackFlexGrow = item.action ? 0 : 1;
                const innerStackAlignItems = item.action || mobile ? 'flex-start' : 'center';
                let valueElement;
                if (typeof item.value === 'string') {
                    const calculatedMaxLength =
                        item.valueMaxLength !== null && item.valueMaxLength !== undefined
                            ? item.valueMaxLength
                            : valueMaxLength;

                    valueElement = (
                        <Typography
                            variant={valueTypographyVariant}
                            fontWeight={700}
                            textAlign={mobile ? 'left' : 'right'}
                        >
                            {calculatedMaxLength > 0
                                ? truncateMiddle({text: item.value, maxSize: calculatedMaxLength})
                                : item.value}
                        </Typography>
                    );
                } else if (typeof item.value === 'number') {
                    valueElement = (
                        <Typography variant={valueTypographyVariant} fontWeight={700}>
                            {item.value}
                        </Typography>
                    );
                } else {
                    valueElement = item.value;
                }

                return (
                    <Stack
                        direction='row'
                        key={key}
                        padding='16px 24px'
                        alignContent='space-between'
                        justifyContent='space-between'
                        alignItems='center'
                        {...stackContainerProps}
                    >
                        <Stack
                            direction={innerStackDirection}
                            justifyContent='space-between'
                            alignItems={innerStackAlignItems}
                            flexGrow={innerStackFlexGrow}
                            spacing={mobile ? 1 : 2}
                            {...stackItemProps}
                        >
                            {typeof item.label === 'string' ? (
                                <Typography variant={labelTypographyVariant}>{item.label}</Typography>
                            ) : (
                                item.label
                            )}
                            <Stack direction='row' alignItems='center'>
                                {valueElement}
                                {item.copyButton && (
                                    <CopyToClipboard text={item.value}>
                                        <IconButton color='secondary'>
                                            <ContentCopyRoundedIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                )}
                            </Stack>
                        </Stack>

                        {item?.action}
                    </Stack>
                );
            })}
        </Stack>
    );
}
