import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {RootState, useAppDispatch} from '~/src/redux/store';
import logo from '~/src/assets/img/qr-logo.svg';
import {Alert, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {FiatPaymentInstructions, useCurrency, ViewResponse} from '@simplecoin/core';
import {getProfileData, submitProfileData} from '~/src/redux/slices/profileSlice';
import {getChecklistWithStep, STEP_VERIFICATION_PAYMENT} from './StepCodes';
import Info from '../Info';

/**
 * Renders bank account upload step
 * @param onFormCompleted
 * @param {function} submitFunction function for parent component (Stepper) to invoke on next button click
 * @param loadProfileData
 * @constructor
 */
export default function VerificationPayment({
    onFormCompleted,
    submitFunction,
    loadProfileData = true,
}: {
    onFormCompleted?: (boolean) => void;
    submitFunction?: (onSubmit: () => void) => void;
    loadProfileData?: boolean;
}) {
    const {auth: user} = useSelector((state: RootState) => state.user);
    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();

    const profileInterface = useSelector((state: RootState) => state.profile?.data);
    const profileData = profileInterface?.response;
    const eurCurrency = useCurrency({code: 'EUR'});
    const czkCurrency = useCurrency({code: 'CZK'});

    const banks = useSelector((state: RootState) => state.order?.companyBanks?.response?.[0]);
    const currenciesAvailableForPayment = new Set(banks?.map((bank) => bank.currency_code));

    let initialCurrencyCode = 'CZK';
    // If there is only one bank for payments, use its currency (it is assumed that it is either CZK or EUR)
    if (currenciesAvailableForPayment.size === 1) {
        initialCurrencyCode = currenciesAvailableForPayment.values().next().value;
    }
    // If there are more banks with different currencies, decide upon the language
    else if (currenciesAvailableForPayment.size > 1) {
        initialCurrencyCode = i18n.language === 'cs' ? 'CZK' : 'EUR';
    }
    const [currency, setCurrency] = useState(useCurrency({code: initialCurrencyCode}));

    // Load data - to get fresh checklist
    useEffect(() => {
        if (loadProfileData) {
            dispatch(getProfileData());
        }
    }, []);

    useEffect(() => {
        if (submitFunction) {
            submitFunction(handleClick);
        }
    }, [handleClick]);

    // This step is completed just by displaying information.
    useEffect(() => {
        if (typeof onFormCompleted === 'function') {
            onFormCompleted(true);
        }
    }, []);

    /**
     * Submits current step
     */
    function handleClick() {
        dispatch(
            submitProfileData({
                extra: {
                    ...profileData?.extra,
                    checklist: getChecklistWithStep(STEP_VERIFICATION_PAYMENT, profileData?.extra?.checklist),
                },
            })
        );
    }

    /**
     * Render payment instructions
     */
    function renderPaymentInstructions(): JSX.Element {
        return (
            <Stack spacing={3} sx={{mb: 3}}>
                <Typography variant='h2'>{t('verification_transaction')}</Typography>
                <Info info={t('verification_transaction_info')} />
                {currenciesAvailableForPayment.size > 1 && (
                    <ToggleButtonGroup color='secondary' value={currency?.name} exclusive onChange={onCurrencyChange}>
                        {currenciesAvailableForPayment.has('CZK') && (
                            <ToggleButton value='CZK' sx={{backgroundColor: 'white'}}>
                                {t('payment_in') + ' CZK'}
                            </ToggleButton>
                        )}
                        {currenciesAvailableForPayment.has('EUR') && (
                            <ToggleButton value='EUR' sx={{backgroundColor: 'white'}}>
                                {t('payment_in') + ' EUR'}
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                )}
                <FiatPaymentInstructions
                    amount='1'
                    note={user?.response?.user.verification_bank_note}
                    qrLogoSrc={logo}
                    currency={currency}
                    showWalletBankAccounts={false}
                />
            </Stack>
        );
    }

    function onCurrencyChange(event: React.MouseEvent<HTMLElement>, value: string | null) {
        if (value === 'CZK') {
            setCurrency(czkCurrency);
        } else {
            setCurrency(eurCurrency);
        }
    }

    /**
     * Render info that payment is there.
     */
    function renderPaymentReceived(): JSX.Element {
        const text =
            profileData?.checks?.verification_payment === 'verified'
                ? t('verification_transaction_approved_info')
                : t('verification_transaction_received_info');
        return (
            <Stack spacing={3} sx={{mb: 3}}>
                <Typography variant='h2'>{t('verification_transaction')}</Typography>
                <Alert severity='info'>{text}</Alert>
                <FiatPaymentInstructions
                    amount='1'
                    note={user?.response?.user.verification_bank_note}
                    qrLogoSrc={logo}
                    currency={currency}
                    showWalletBankAccounts={false}
                />
            </Stack>
        );
    }

    /**
     * Render page when data is fetched.
     */
    function render(): JSX.Element {
        if (profileData?.checks?.verification_payment === null) {
            return renderPaymentInstructions();
        } else {
            return renderPaymentReceived();
        }
    }

    return <ViewResponse fetched={render} reducer={profileInterface} />;
}
