import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import QrCode from 'qrcode.react';
import {Currency} from '~/typings/currency';
import {decodeXrpAddressAndDestinationTag} from '~/helpers/crypto';
import {Alert, Box, Stack, TextField, Typography, useMediaQuery} from '@mui/material';
import DetailView, {DetailViewItem} from '../DetailView';
import {formatAmount} from '~/helpers/price';

export interface ExpectIncomingCryptoProps {
    address: string;
    qrText: string;
    amount?: string;
    allowAmountChange?: boolean;
    currency: Currency;
    waitingTimeInSecs: number;
    qrLogoSrc: string;
    amountFieldLabel?: string;
    infoAlert?: string;
}

/**
 * Renders incoming crypto component which shows the address and amount and qr code.
 *
 * @param {string} address Crypto address where user should send coins.
 * @param amountFieldLabel
 * @param {string} qrText QR code text, should contain format in BIP0021 spec, e.g. {coinPrefix}:{address}?amount={amount}.
 * @param {float} amount Expecting amount.
 * @param allowAmountChange Use if used as deposit form where client can choose the amount
 * @param {object} currency Currency of the amount.
 * @param {number} waitingTimeInSecs Waiting time for crypto in seconds.
 * @param qrLogoSrc
 */
function CryptoPaymentInstructions({
    address,
    amountFieldLabel,
    qrText,
    amount,
    allowAmountChange = false,
    currency,
    waitingTimeInSecs,
    qrLogoSrc,
    infoAlert,
}: ExpectIncomingCryptoProps) {
    const {t, i18n} = useTranslation();
    const volatilityMessageValues = {duration: Math.floor(waitingTimeInSecs / 60)};
    const [cryptoAddress, setCryptoAddress] = useState(address);
    const [destinationTag, setDestinationTag] = useState<string>();
    const [paymentAmount, setPaymentAmount] = useState<string | undefined>(amount);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (currency) {
            if (currency.name === 'XRP') {
                const {address: decodedAdress, destinationTag: dt} = decodeXrpAddressAndDestinationTag(address);
                if (dt !== undefined && dt.length) {
                    setDestinationTag(dt);
                }
                setCryptoAddress(decodedAdress);
            }
        }
    }, [address, currency]);

    /**
     * Created BIP21-like string containing address, amount and currency
     */

    const amountSubstring = paymentAmount ? `?amount=${paymentAmount}` : '';
    const generatedQrContent = currency?.urn_schema ? `${currency.urn_schema}:${address}${amountSubstring}` : '';

    const handleAmountChange = (e) => {
        setPaymentAmount(e.target.value);
    };

    if (!address || !currency) {
        return null;
    }

    const imageSettings = {
        src: qrLogoSrc,
        height: 26,
        width: 170,
        x: undefined,
        y: undefined,
        excavate: true,
    };

    const paymentInformation: Array<DetailViewItem> = [
        {
            label: t('crypto_payment_instructions_amount_label'),
            value: `${formatAmount(paymentAmount ?? 0, currency?.fixed_decimals ?? 2, i18n.language)} ${
                currency?.name
            }`,
        },
        {
            label: t('send_to_address'),
            value: cryptoAddress,
            valueMaxLength: isMobile ? 32 : 0,
            copyButton: true,
        },
    ].slice(allowAmountChange ? 1 : 0); // If the change of amount is allowed then the amount is not part of the view. It has own editable field.

    if (destinationTag) {
        paymentInformation.push({
            label: t('crypto_payment_instructions_destination_tag_field_label'),
            value: destinationTag,
            copyButton: true,
        });
    }

    const displayedInfoAlert =
        infoAlert ?? t('react_order_created_high_market_volatility_message', volatilityMessageValues);

    return (
        <div>
            <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-between' spacing={4}>
                <Stack direction='column' alignItems='stretch' flexGrow={1} spacing={6}>
                    <Typography variant='h3' textAlign='left'>
                        {t('payment_instructions')}
                    </Typography>
                    <Alert severity='info' data-testid='volatility-alert'>
                        {displayedInfoAlert}
                    </Alert>
                    <Box width='100%' display='flex' flexDirection='column'>
                        {allowAmountChange && (
                            <TextField
                                label={amountFieldLabel ?? t('crypto_payment_instructions_amount_label')}
                                value={paymentAmount}
                                onChange={handleAmountChange}
                                id='amount'
                                name='amount'
                                InputProps={{
                                    readOnly: !allowAmountChange,
                                    inputMode: 'decimal',
                                    endAdornment: currency.name,
                                }}
                            />
                        )}
                        <DetailView items={paymentInformation} />
                    </Box>
                </Stack>

                <Stack
                    direction='column'
                    alignItems={{xs: 'center', md: 'flex-start'}}
                    flexGrow={1}
                    spacing={{xs: 2, md: 4}}
                >
                    <Typography variant='h3' textAlign='left'>
                        {t('order_form_payment_instructions_use_qr')}
                    </Typography>
                    <Box sx={{background: '#FAF9FF', borderRadius: '20px'}} padding='16px'>
                        <QrCode
                            size={200}
                            value={allowAmountChange ? generatedQrContent : qrText}
                            imageSettings={imageSettings}
                            level='Q'
                        />
                    </Box>

                    {/*    todo: pay by trezor button here */}
                </Stack>
            </Stack>
        </div>
    );
}

CryptoPaymentInstructions.displayName = 'CryptoPaymentInstructions';

export default CryptoPaymentInstructions;
