import {Link, Stack, Tooltip, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../redux/store';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {fetchTradeLimits} from '../redux/slices/tradingLimitsSlice';
import StyledAmount from './StyledAmount';

/**
 * Component that shows user discount.
 */
export default function TradingLimitWidget() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    // Loaded only if not found in store. Must be refreshed after trading in the business logic.
    const tradingLimitsReducer = useSelector((rootState: RootState) => rootState?.tradingLimits);
    const tradingLimitsInfo = tradingLimitsReducer?.info?.response;

    useEffect(() => {
        if (!tradingLimitsInfo) {
            dispatch(fetchTradeLimits());
        }
    }, []);

    if (tradingLimitsInfo) {
        return (
            <Stack direction='column' spacing={1}>
                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                    <Typography variant='body2'>{t('your_limit_is')}</Typography>
                    <Tooltip title={t('current_allowed_trading_amount_info')}>
                        <InfoOutlinedIcon fontSize='small' />
                    </Tooltip>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <StyledAmount
                        amount={tradingLimitsInfo.allowedTrading}
                        currencyCode={tradingLimitsInfo.currency}
                        size='small'
                    />
                </Stack>
                <Link href='/verification' color='secondary'>
                    <Typography variant='body2' color='secondary'>
                        {t('limits')} →
                    </Typography>
                </Link>
            </Stack>
        );
    } else {
        return null;
    }
}
