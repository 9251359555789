import {Currency} from './currency';
import {User} from './User';
import {ListResponse} from './Api';

export type OrderBaseStatus = 'new' | 'confirming_incoming' | 'confirming_outgoing' | 'received' | 'done';
export type OrderDirectionStatus = 'proceed' | 'suspended' | 'return_to_client' | 'on_hold';
export type OrderFinalStatus = 'cancelled' | 'delivered' | 'returned' | 'unfinished' | null;
export type OrderStatusName = OrderBaseStatus | OrderDirectionStatus | OrderFinalStatus;

export const STATUS_NEW: OrderBaseStatus = 'new';
export const STATUS_CONFIRMING_INCOMING: OrderBaseStatus = 'confirming_incoming';
export const STATUS_RECEIVED: OrderBaseStatus = 'received';
export const STATUS_CONFIRMING_OUTGOING: OrderBaseStatus = 'confirming_outgoing';
export const STATUS_DONE: OrderBaseStatus = 'done';

/**
 * Possible direction statuses.
 */
export const STATUS_PROCEED: OrderDirectionStatus = 'proceed';
export const STATUS_SUSPENDED: OrderDirectionStatus = 'suspended';
export const STATUS_RETURN_TO_CLIENT: OrderDirectionStatus = 'return_to_client';
export const STATUS_ON_HOLD: OrderDirectionStatus = 'on_hold';

/**
 * Possible final statuses.
 */
export const STATUS_CANCELLED: OrderFinalStatus = 'cancelled';
export const STATUS_DELIVERED: OrderFinalStatus = 'delivered';
export const STATUS_RETURNED: OrderFinalStatus = 'returned';
export const STATUS_MISSING: OrderFinalStatus = null;
// the unfinished status is only used for order filtering
export const STATUS_UNFINISHED: OrderFinalStatus = 'unfinished';

export interface Wallet {
    id: number;
    address: string;
    address_label: string | null;
    address_derivation_path: string | null;
    address_url: string | null;
    arbitrary_data: string | null;
    currency_id: number;
    currency?: Currency;
    updated_at: number;
    created_at: number;
}

/**
 * Represents structure returned from wallet/index endpoint.
 */
export interface WalletList extends ListResponse {
    items: Wallet[];
}

export interface CompanyBank {
    currency_id: number;
    currency_code: string;
    name: string;
    iban: string;
    account_number: string;
    bank_code?: string;
    comment: string;
    is_for_wallet: boolean;
}

export interface CompanyBanks {
    [key: number]: CompanyBank[];
}

export interface CryptoTransaction {
    amount: string;
    hash: string;
    hash_url: string | null;
    address: string;
    address_url: string | null;
    block_id: number | null;
    is_confirmed: boolean;
    counter_address: string;
    confirmations: number;
    created_at: number;
}

export interface BankTransaction {
    account_number: string;
    amount: string;
    bank_name: string;
    created_at: number;
    note: string;
    owner_name: string;
    symbol: number;
    traded_amount: number | null;
}

export interface Bank {
    id: number;
    name?: string;
    account_number: string;
    updated_at: number;
    created_at: number;
}

export interface BankAccount extends Bank {
    account_constant: string | null;
    account_label: string | null;
    account_specific: string | null;
    account_vs: string | null;
    currency?: Currency;
    currency_id: number | null;
    document_count: string | null; // number as string: "1"
    is_verified: number; // 0 as false, 1 as true
    message: string | null;
    owner_address: string | null;
    owner_city: string | null;
    owner_country: string | null;
    owner_name: string | null;
}

/**
 * Represents structure returned from order/view endpoint. Some fields are only
 * returned if they are requested by the expand param
 */
export interface Order {
    id: number;
    status: OrderBaseStatus;
    status_direction: OrderDirectionStatus;
    status_final: OrderFinalStatus;
    from_amount: string;
    to_amount: string | null;
    return_amount: string | null;
    pairing_data: string | null; // renamed from receive_address
    receive_address_url: string | null;
    receive_address_bip21: string | null;
    rate: string;
    fiat_note: string | null;
    is_cic: boolean;
    is_coc: boolean;
    is_cob: boolean;
    is_limit: boolean;
    is_over_limit_when_paid: boolean;
    has_refund_address: boolean;
    notifications: Array<string>;
    price_fixation_period: number;
    updated_at: number;
    created_at: number;
    done_at: number | null;
    from_currency_id: number;
    to_currency_id: number;
    bankAccount?: BankAccount | null;
    cryptoAccount?: Wallet | null;
    incomingCryptoTransaction?: CryptoTransaction | null;
    outgoingCryptoTransaction?: CryptoTransaction | null;
    incomingBankTransaction?: BankTransaction | null;
    outgoingBankTransaction?: BankTransaction | null;
    statusLogs: StatusLog[];
    refundAddress?: Wallet | null;
    owner?: User | null;
    provider_url?: string | null;
    provider_name?: string | null;
    from_amount_in_eur: string;
    fromCurrency?: Currency;
    toCurrency?: Currency;
    to_currency_code: string;
    from_currency_code: string;
    is_payment_confirmation_uploaded: boolean;
}

export interface StatusLog {
    id: number;
    status_name: string;
    created_at: number;
}

/**
 * Represents structure returned from order/live-suspend endpoint.
 */
export interface LiveSuspend {
    live_rate: string;
    live_rate_value: number;
    live_rate_inverse_value: number;
    live_rate_inverse: string;

    current_rate: string;
    current_rate_raw: number;
    current_rate_inverse_raw: number;
    current_rate_inverse: string;
    current_rate_value: number;
    current_rate_inverse_value: number;

    limit_rate: string;
    limit_rate_inverse: string;
    limit_rate_value: number;
    limit_rate_raw: string;
    limit_rate_inverse_raw: number;
    limit_rate_inverse_value: number;

    live_change_percent: string;
    live_change_sign: string;
    live_price: string;
    live_price_difference: string;

    is_limit: boolean;
    is_over_limit: boolean;

    to_currency_id: number;
    from_currency_id: number;
}

export interface OrderOffer {
    crypto_address: string;
    from_amount: number;
    from_currency_id: 4;
    provider_url?: string | null;
    provider_name?: string | null;
    to_amount: number;
    to_currency_id: number;
    expired_at: number;
}

/**
 * List of available language codes. The tx code stands for test language.
 */
export type CountryCode = 'en' | 'cs' | 'sk' | 'pl' | 'de' | 'it' | 'es' | 'ru' | 'uk' | 'nl' | 'vi' | 'tx';

/**
 * List of language codes with region. Tx-tx is the test language.
 */
export type CountryCodeRegion =
    | 'en-US'
    | 'cs-CZ'
    | 'sk-SK'
    | 'pl-PL'
    | 'de-DE'
    | 'it-IT'
    | 'es-ES'
    | 'ru-RU'
    | 'uk-UA'
    | 'nl-NL'
    | 'vi-VN'
    | 'tx-TX';
