import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Typography,
    CardActions,
    Stack,
    SvgIcon,
    Divider,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '~/src/redux/store';
import StatusChip from '~/src/components/Common/StatusChip';
import InProgressIcon from '../../../assets/svg/progress-icon-filled.svg';
import {getProfileData, Profile} from '~/src/redux/slices/profileSlice';
import {
    STEP_BASIC_INCOME_PROVIDED,
    STEP_PERSONAL_DATA_PROVIDED,
    STEP_TRADING_PURPOSE_PROVIDED,
    STEP_VERIFICATION_CALL,
    STEP_VERIFICATION_PAYMENT,
} from './StepCodes';
import {Dialog, ViewResponse} from '@simplecoin/core';
import VerificationPayment from './VerificationPayment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Tile} from '~/src/components/Common/Tile';

interface ChecklistItem {
    label: string;
    status: 'NOT_COMPLETED' | 'IN_PROGRESS' | 'COMPLETED';
    message?: string | JSX.Element;
}

/**
 * Card with profile verification checklist.
 * @param loadProfileData - Calls getProfileData() on mount.
 */
export default function ProfileChecklistCard({loadProfileData}: {loadProfileData?: boolean}) {
    const {t, i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const profileInterface = useSelector((state: RootState) => state.profile);
    const profileData: Profile | undefined = profileInterface?.data?.response;
    const user = useSelector((state: RootState) => state.user.auth?.response?.user);
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

    useEffect(() => {
        if (loadProfileData) {
            dispatch(getProfileData());
        }
    }, []);

    /**
     * Render checklist item.
     * @param itemLabel Localised name of the step in checklist
     * @param done True if the step is done.
     */
    function renderChecklistItem(item: ChecklistItem, index: number): JSX.Element {
        /**
         * Render icon according to a status.
         */
        function getIcon(status: 'COMPLETED' | 'IN_PROGRESS' | 'NOT_COMPLETED'): JSX.Element {
            if (status === 'COMPLETED') {
                return <CheckCircleRoundedIcon color='secondary' />;
            } else if (status === 'IN_PROGRESS') {
                return <img src={InProgressIcon} alt='In progress...' />;
            } else {
                return (
                    <SvgIcon viewBox='0 0 24 24'>
                        <ellipse cx={12} cy={12} rx={10} ry={10} fill='#F1EEFF' />
                    </SvgIcon>
                );
            }
        }

        return (
            <Stack
                key={`checklistItem_${index}`}
                direction={{xs: 'column', md: 'row'}}
                alignItems={{xs: 'flex-start', md: 'center'}}
                justifyContent='space-between'
                sx={{
                    padding: 2,
                }}
            >
                <Stack direction='row' spacing={2} alignItems='center'>
                    {getIcon(item?.status)}
                    <Typography variant='label'>{item.label}</Typography>
                </Stack>
                {item.message && (
                    <Box sx={{py: {xs: 2, md: 0}}}>
                        <Typography variant='caption'>{item.message}</Typography>
                    </Box>
                )}
            </Stack>
        );
    }

    /**
     * Get status of verification transaction.
     */
    function getVerificationTransactionStatus(): 'COMPLETED' | 'IN_PROGRESS' | 'NOT_COMPLETED' {
        if (profileData?.checks?.verification_payment) {
            return 'COMPLETED';
        } else if (profileData?.extra?.checklist?.includes(STEP_VERIFICATION_PAYMENT)) {
            return 'IN_PROGRESS';
        } else {
            return 'NOT_COMPLETED';
        }
    }
    const verificationTransactionStatus = useMemo(getVerificationTransactionStatus, [
        profileData?.extra,
        profileData?.checks,
    ]);

    let verificationTransactionMesage: string | JSX.Element;
    if (verificationTransactionStatus === 'IN_PROGRESS') {
        if (profileData?.checks?.verification_payment === 'received') {
            verificationTransactionMesage = t('waiting_for_payment_approval');
        } else {
            verificationTransactionMesage = (
                <Stack justifyContent='center' textAlign='center'>
                    {t('waiting_for_payment')}
                    <Dialog
                        invoker={({handleOpen}) => (
                            <Button size='small' variant='outlined' onClick={handleOpen}>
                                {t('show_payment_instructions_dialog_opener_button')}
                            </Button>
                        )}
                        dialogTitle={t('order_payment_instructions_header')}
                        dialogContent={<VerificationPayment loadProfileData={false} />}
                    />
                </Stack>
            );
        }
    }

    /**
     * Get status of verification call.
     */
    function getVerificationCallStatus(): 'COMPLETED' | 'IN_PROGRESS' | 'NOT_COMPLETED' {
        if (profileData?.checks?.is_phone_verified) {
            return 'COMPLETED';
        } else if (profileData?.extra?.checklist?.includes(STEP_VERIFICATION_CALL)) {
            return 'IN_PROGRESS';
        } else {
            return 'NOT_COMPLETED';
        }
    }
    const verificationCallStatus = useMemo(getVerificationCallStatus, [profileData?.extra, profileData?.checks]);

    const verificationChecklist: Array<ChecklistItem> = useMemo(
        () => [
            {
                label: t('profile_page_header_my_personal_data'),
                status: profileData?.extra?.checklist?.includes(STEP_PERSONAL_DATA_PROVIDED)
                    ? 'COMPLETED'
                    : 'NOT_COMPLETED',
            },
            {
                label: t('document_issued_by_government'),
                status:
                    profileData?.checks?.primary_document_provided && profileData?.checks?.secondary_document_provided
                        ? 'COMPLETED'
                        : 'NOT_COMPLETED',
            },
            {
                label: t('selfie_with_id'),
                status: profileData?.checks?.selfie_id_provided ? 'COMPLETED' : 'NOT_COMPLETED',
            },
            {
                label: t('purpose_of_crypto_trading'),
                status: profileData?.extra?.checklist?.includes(STEP_TRADING_PURPOSE_PROVIDED)
                    ? 'COMPLETED'
                    : 'NOT_COMPLETED',
            },
            {
                label: t('proof_of_basic_income'),
                status: profileData?.extra?.checklist?.includes(STEP_BASIC_INCOME_PROVIDED)
                    ? 'COMPLETED'
                    : 'NOT_COMPLETED',
            },
            {
                label: t('verification_transaction'),
                status: verificationTransactionStatus,
                message: verificationTransactionMesage,
            },
            {
                label: t('verification_call'),
                status: verificationCallStatus,
                message:
                    verificationCallStatus === 'IN_PROGRESS'
                        ? profileData?.extra?.when_should_we_call === 'callAsap'
                            ? ''
                            : profileData?.extra?.when_should_we_call
                        : undefined,
            },
        ],
        [profileData?.extra, profileData?.checks, i18n.language]
    );

    // Mapping of verification statuses to general statuses used by StatusChip component
    let verificationStatus;
    let verificationStatusLabel;
    if (profileData) {
        if (profileData.verified) {
            verificationStatus = 'active';
            verificationStatusLabel = t('verified');
        } else if (profileData.ready_for_verification) {
            verificationStatus = 'waiting';
            verificationStatusLabel = t('verification_in_progress');
        } else {
            verificationStatus = 'canceled';
            verificationStatusLabel = t('not_verified');
        }
    } else {
        verificationStatus = 'canceled';
        verificationStatusLabel = t('not_verified');
    }

    useEffect(() => {
        // Profile data are loaded
        if (!!profileData) {
            // Client is verified - do not bother him with the checklist - collapse it
            if (profileData.verified) {
                setIsAccordionExpanded(false);
            }
            // Client is not verified - expand the checklist
            else {
                setIsAccordionExpanded(true);
            }
        }
        // Profile data are not loaded, keep it collapsed
        else {
            setIsAccordionExpanded(false);
        }
    }, [profileData?.verified]);

    /**
     * Content of this component called when data is loaded.
     */
    function renderOnFetch() {
        return (
            <Accordion
                expanded={isAccordionExpanded}
                onChange={(_event: React.SyntheticEvent, expanded: boolean) => setIsAccordionExpanded(expanded)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{alignItems: 'flex-start'}}>
                    <Stack direction='column' spacing={2} sx={{width: 1}}>
                        <Typography variant='h2'>{t('profile_verification')}</Typography>
                        <Tile bgVariant='neutral'>
                            <StatusChip status={verificationStatus} label={verificationStatusLabel} variant='filled' />
                        </Tile>
                        {verificationStatus === 'waiting' && (
                            <Typography variant='body2'>
                                {t('information_about_verification_status_change', {email: user?.email})}
                            </Typography>
                        )}
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack mt={6} divider={<Divider />}>
                        {verificationChecklist.map(renderChecklistItem)}
                    </Stack>
                </AccordionDetails>
                <CardActions>
                    {!profileData?.verified && !profileData?.ready_for_verification && (
                        <Button href='/profile' size='large' sx={{marginRight: 3}}>
                            {t('start_with_verification')}
                        </Button>
                    )}
                </CardActions>
            </Accordion>
        );
    }

    return <ViewResponse reducer={profileInterface?.data} loaderInline={false} fetched={renderOnFetch} />;
}
