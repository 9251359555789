import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    InputLabel,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    LiveSuspend,
    Order,
    OrderBaseStatus,
    STATUS_CANCELLED,
    STATUS_CONFIRMING_INCOMING,
    STATUS_NEW,
    STATUS_ON_HOLD,
    STATUS_PROCEED,
    STATUS_RECEIVED,
    STATUS_RETURN_TO_CLIENT,
    STATUS_SUSPENDED,
} from '~/typings/Order';
import {useTranslation} from 'react-i18next';
import {
    fetchAcceptPrice,
    fetchChangeStatus,
    fetchDownloadOrder,
    fetchLiveSuspended,
    fetchRemoveLimit,
    refetchOrder,
} from '~/redux/orderSlice';
import {User} from '~/typings/User';
import {RootState, useCoreAppDispatch} from '~/redux/store';
import OrderReturnButtonWithForm from './OrderReturnButtonWithForm';
import {useCurrency} from '~/hooks/currency';
import {Currencies, Currency} from '~/typings/currency';
import useBackendSettings from '../../hooks/useBackendSettings';
import {useSelector} from 'react-redux';
import {manageResponse} from '~/helpers/api';
import {enqueueSnackbar} from '~/redux/snackbarSlice';
import TimelineIcon from '@mui/icons-material/Timeline';
import Dialog, {DialogInterface, InvokerProps} from '../Dialog';
import SuspendedLimitForm from './SuspendedLimitForm';
import ViewResponse from '../ViewResponse';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TuneIcon from '@mui/icons-material/Tune';
import useInterval from '../../hooks/useInterval';
import AmountToAmount from '../AmountToAmount';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SuspendedOrderIcon from '../icons/SuspendedOrderIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TicketIcon from '../icons/TicketIcon';
import PdfIcon from '../icons/PdfIcon';
import PauseIcon from '~/components/icons/PauseIcon';
import ResponsiveText from '~/components/ResponsiveText';
import {CryptoAddressDropdownRenderProps} from '~/typings/ExchangeFormTypings';

const TIMER_LIMIT = 30;

// export interface TradeLimitDays {
//     level0: number;
//     level1: number;
// }

export default function OrderInfoWidget({
    order,
    token,
    isOverLimit,
    user,
    cryptoAddressDropdown,
}: {
    order: Order;
    token?: string;
    isOverLimit: boolean;
    user?: User;
    cryptoAddressDropdown?: (props: CryptoAddressDropdownRenderProps) => JSX.Element;
}) {
    const dispatch = useCoreAppDispatch();
    const theme = useTheme();
    const fromCurrency = useCurrency({id: order.from_currency_id}) as Currency;
    const toCurrency = useCurrency({id: order.to_currency_id}) as Currency;
    const currencies = useBackendSettings<Currencies>('currencies', {});
    const {t} = useTranslation();
    const isLimitEnabledSetting = useBackendSettings('isLimit', false);
    const {liveSuspend, removeLimit, acceptPrice, statusChange} = useSelector((state: RootState) => state.order);
    const changeColorStyle = useMemo(
        () => ({color: liveSuspend?.response?.live_change_sign === '-' ? 'red' : 'green'}),
        [liveSuspend]
    );
    const liveSuspendValue = liveSuspend?.response;
    const isSuspended = order.status_direction === STATUS_SUSPENDED && order.status_final !== STATUS_CANCELLED;
    const isSuspendedReceived = isSuspended && order.status === STATUS_RECEIVED;
    const isSuspendedOverLimit = isSuspendedReceived && isOverLimit;
    const suspendFormRef = useRef<{handleFormSubmit: () => void}>();
    const isLimitSet = order.is_limit;
    const [seconds, setSeconds] = useState(30);
    const [requestOpenLimitDialog, setRequesOpenLimitDialog] = useState(false);
    const limitDialogRef = useRef<DialogInterface>(null);
    const emailConfirmed = order.owner?.is_email_confirmed;
    const isAuthOrder = order?.owner?.is_registered;

    // Next... menu
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const moreMenuOpenFlag = Boolean(moreMenuAnchorEl);

    // Context menu for suspended orders
    const [suspendedMenuAnchorEl, setSuspendedMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const suspendedMenuOpenFlag = Boolean(suspendedMenuAnchorEl);

    // Context menu with set limit
    const [limitMenuAnchorEl, setLimitMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const limitMenuOpenFlag = Boolean(limitMenuAnchorEl);

    const handleAcceptPrice = () => dispatch(fetchAcceptPrice({id: order.id, token}));

    useEffect(() => {
        manageResponse({
            reducer: acceptPrice,
            dispatch,
            onSuccess: () => {
                dispatch(enqueueSnackbar({variant: 'success', message: t('you_have_accepted_current_suspended_deal')}));
                dispatch(refetchOrder({id: order.id, token}));
            },
        });
    }, [acceptPrice]);

    useEffect(() => {
        if (statusChange.statusName === STATUS_SUSPENDED && requestOpenLimitDialog) {
            limitDialogRef.current?.openDialog();
            setRequesOpenLimitDialog(false);
        }
    }, [statusChange, requestOpenLimitDialog]);

    // User can accept new offer only if current order is nor dispatched neither canceled.
    const statusesWhenAllowedFetchOffer = new Set<OrderBaseStatus>(['new', 'confirming_incoming', 'received']);
    const fetchNewOfferFlag = order && !isOverLimit && statusesWhenAllowedFetchOffer.has(order?.status);

    useEffect(() => {
        if (fetchNewOfferFlag) {
            dispatch(fetchLiveSuspended({id: order.id, token}));
        }
    }, []);

    useInterval(() => {
        if (fetchNewOfferFlag) {
            if (seconds <= 0) {
                setSeconds(TIMER_LIMIT);
                dispatch(fetchLiveSuspended({id: order.id, token}));
            } else {
                setSeconds(seconds - 1);
            }
        }
    }, 1000);

    // Follow remove limit changes
    useEffect(() => {
        manageResponse({
            reducer: removeLimit,
            dispatch,
            onSuccess: () => {
                dispatch(enqueueSnackbar({message: t('react_alert_limit_removed'), variant: 'success'}));
            },
        });
    }, [removeLimit]);

    /**
     * Handles cancel action click.
     */
    function handleCancelled() {
        return dispatch(fetchChangeStatus({id: order.id, token, status: STATUS_CANCELLED}));
    }

    /**
     * Handles proceed action click.
     */
    function handleProceed() {
        return dispatch(fetchChangeStatus({id: order.id, token, status: STATUS_PROCEED}));
    }

    /**
     * Handles suspend action click.
     */
    function handleSuspended() {
        dispatch(fetchChangeStatus({id: order.id, token, status: STATUS_SUSPENDED}));
        dispatch(fetchLiveSuspended({id: order.id, token}));
    }

    /**
     * Download order information PDF.
     */
    function handleDownloadPdf() {
        order && dispatch(fetchDownloadOrder({id: order.id, token: token}));
    }

    const showProceedAction =
        order.status === STATUS_NEW &&
        order.status_direction !== STATUS_PROCEED &&
        order.status_direction !== STATUS_ON_HOLD &&
        !isOverLimit;

    const showRefundAction =
        (order.status === STATUS_CONFIRMING_INCOMING && order.status_direction !== STATUS_RETURN_TO_CLIENT) ||
        isSuspendedReceived;

    const showCancelAction = order.status === STATUS_NEW && order.status_final !== STATUS_CANCELLED;

    const showSuspendAction =
        (order.status === STATUS_NEW || order.status === STATUS_CONFIRMING_INCOMING) &&
        order.status_direction !== STATUS_SUSPENDED &&
        order.status_final !== STATUS_CANCELLED &&
        order.status_direction !== STATUS_ON_HOLD;

    const showSetLimitPriceAction =
        (order.status_direction === STATUS_SUSPENDED ||
            order.status === STATUS_NEW ||
            order.status === STATUS_CONFIRMING_INCOMING) &&
        order.status_final !== STATUS_CANCELLED &&
        isLimitEnabledSetting &&
        !order.is_limit;

    const showModifyLimitPriceAction =
        (order.status_direction === STATUS_SUSPENDED ||
            order.status === STATUS_NEW ||
            order.status === STATUS_CONFIRMING_INCOMING) &&
        order.status_final !== STATUS_CANCELLED &&
        isLimitEnabledSetting &&
        order.is_limit;

    const showCancelLimitPriceAction =
        (order.status_direction === STATUS_SUSPENDED ||
            order.status === STATUS_NEW ||
            order.status === STATUS_CONFIRMING_INCOMING) &&
        order.status_final !== STATUS_CANCELLED &&
        isLimitEnabledSetting &&
        order.is_limit;
    const showBackToVendorButton = !!order.provider_url;

    function handleRemoveLimit() {
        const {id} = order;
        dispatch(fetchRemoveLimit({id, token}));
    }

    /**
     * Get buttons used to open limit dialog when fresh rate is fetched.
     */
    function buttonsOnLiveSuspendFetched({reducer: suspend}: {reducer: LiveSuspend}) {
        const dialogInvokers = ({handleOpen}: InvokerProps) => {
            return (
                <>
                    {showSetLimitPriceAction && (
                        <Grid item xs={6} md='auto'>
                            <Button
                                variant='text'
                                onClick={handleOpen}
                                startIcon={<TimelineIcon />}
                                color='primary'
                                size='small'
                                sx={{whiteSpace: 'nowrap'}}
                            >
                                {t('button_set_limit')}
                            </Button>
                        </Grid>
                    )}
                </>
            );
        };

        return limitDialog(suspend, dialogInvokers);
    }

    /**
     * Get menu item used to change limit when fresh rate is fetched.
     */
    function menuItemsOnLiveSuspendFetched({reducer: suspend}: {reducer: LiveSuspend}) {
        const dialogInvokers = ({handleOpen}: InvokerProps) => {
            return (
                <>
                    {showModifyLimitPriceAction && (
                        <MenuItem onClick={handleOpen}>
                            <ListItemIcon>
                                <TuneIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText disableTypography sx={{color: 'primary.main', fontWeight: 700}}>
                                {t('button_change_limit')}
                            </ListItemText>
                        </MenuItem>
                    )}
                </>
            );
        };

        return limitDialog(suspend, dialogInvokers);
    }

    /**
     * Get dialog to set limit.
     */
    const limitDialog = (suspend: LiveSuspend, limitDialogInvoker) => {
        const fromCurrency = currencies[suspend.from_currency_id];
        const toCurrency = currencies[suspend.to_currency_id];

        let orderLimitRateRaw;
        let orderLimitRateInverseRaw;

        if (suspend) {
            // Order or limit rate depending whether limit was previously set on order or not
            orderLimitRateRaw = order.is_limit ? suspend.limit_rate_raw : suspend.current_rate_raw;
            orderLimitRateInverseRaw = order.is_limit
                ? suspend.limit_rate_inverse_raw
                : suspend.current_rate_inverse_raw;
        }

        const fromAmount = Number.parseFloat(order.from_amount);

        return (
            <Dialog
                invoker={limitDialogInvoker}
                dialogContent={({handleClose}) => (
                    <SuspendedLimitForm
                        order={order}
                        fromCurrency={fromCurrency}
                        toCurrency={toCurrency}
                        limitRate={orderLimitRateRaw}
                        limitRateInverse={orderLimitRateInverseRaw}
                        fromAmount={fromAmount}
                        token={token}
                        onSuccess={handleClose}
                        ref={suspendFormRef}
                    />
                )}
                dialogActions={
                    <Button onClick={() => suspendFormRef?.current?.handleFormSubmit?.()}>
                        {order.is_limit ? t('button_change_limit') : t('button_set_limit')}
                    </Button>
                }
                withCancelButton
                ref={limitDialogRef}
            />
        );
    };

    /**
     * Component that shows original order price
     */
    const originalPriceComponent = (size: 'large' | 'standard', showCurrencyIcons: boolean) => (
        <AmountToAmount
            fromAmount={parseFloat(order.from_amount)}
            fromCurrencyCode={fromCurrency.name}
            toAmount={parseFloat(order.to_amount ?? '0')}
            toCurrencyCode={toCurrency.name}
            showCurrencyIcons={showCurrencyIcons}
            size={size}
        />
    );

    /**
     * Component that shows current price offer and button to accept that offer.
     */
    const currentPriceOfferComponent = (
        <Stack alignItems='flex-start' spacing={3} sx={{mb: 4}}>
            <Stack>
                {isSuspended && <InputLabel shrink>{`${t('order_view_current_price_label')} (${seconds})`}</InputLabel>}
                <AmountToAmount
                    fromAmount={parseFloat(order.from_amount)}
                    fromCurrencyCode={fromCurrency.name}
                    toAmount={parseFloat(liveSuspend.response?.live_price ?? '0')}
                    toCurrencyCode={toCurrency.name}
                    showCurrencyIcons
                    size='large'
                />
            </Stack>

            {!isOverLimit && isSuspendedReceived && liveSuspendValue && (
                <Stack direction={{xs: 'column', md: 'row'}} spacing={3}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Typography variant='body2' fontWeight={700}>
                            {t('suspended_widget_table_change_percent')}
                        </Typography>
                        <Typography variant='body2' style={changeColorStyle}>
                            {liveSuspendValue.live_change_sign + liveSuspendValue.live_change_percent}
                        </Typography>
                        <Typography variant='body2'>
                            {`(${liveSuspendValue.live_price_difference} ${toCurrency.name})`}
                        </Typography>
                    </Stack>

                    <Button onClick={handleAcceptPrice} size='small' data-testid='suspended-widget-accept-button'>
                        {t('react_suspended_widget_accept_btn', {
                            amount: liveSuspendValue.live_price,
                            currency: toCurrency.name,
                        })}
                    </Button>
                </Stack>
            )}

            {isSuspendedOverLimit && (
                <Alert severity='warning'>
                    {t('react_order_suspend_over_limit_message')}
                    <Link href='/verification' color='secondary'>
                        {t('verification_and_limits')}
                    </Link>
                </Alert>
            )}
        </Stack>
    );

    // client's crypto address
    const cryptoAddressLabel = t('label_receive_address');

    const cryptoAddressValue = order?.owner?.is_registered ? (
        <Tooltip title={order.cryptoAccount?.address}>
            <span>
                <Link href={`/recipients/wallets/${order.cryptoAccount?.id}`} underline='none'>
                    {order.cryptoAccount?.address_label}
                </Link>
            </span>
        </Tooltip>
    ) : (
        <>
            <Link
                href={order.cryptoAccount?.address_url ?? ''}
                underline='none'
                component='a'
                target='_blank'
                rel='nofollow noopener noreferrer'
            >
                <ResponsiveText text={order.cryptoAccount?.address} maxSize={{xs: 26, md: 46}} />
            </Link>
        </>
    );
    // truncate, explorer url, copy, currency

    const bankAccountLabel = t('label_bank_account_id');
    const bankAccountValue = order?.owner?.is_registered ? (
        <Tooltip title={order.bankAccount?.account_number}>
            <span>
                <Link
                    href={`/recipients/bank-accounts/${order.bankAccount?.id}`}
                    underline='none'
                    component='a'
                    target='_blank'
                    rel='nofollow noopener noreferrer'
                >
                    {order.bankAccount?.account_label}
                </Link>
            </span>
        </Tooltip>
    ) : (
        <Typography>{order.bankAccount?.account_number}</Typography>
    );

    return (
        <Box>
            {/* Button bar */}
            {emailConfirmed && (
                <Grid
                    container
                    alignItems='center'
                    justifyContent='flex-start'
                    rowSpacing={2}
                    columnSpacing={{xs: 0.5, md: 2}}
                    mb={3}
                >
                    {/* A chip that indicates suspended orders. The comes with a context menu for suspended orders. */}
                    {isSuspended && (
                        <>
                            <Grid item xs={12} md='auto'>
                                <Chip
                                    label={t('order_status_suspended')}
                                    icon={<SuspendedOrderIcon />}
                                    deleteIcon={<KeyboardArrowDownIcon />}
                                    onDelete={
                                        showProceedAction
                                            ? (event) => {
                                                  setSuspendedMenuAnchorEl(event.currentTarget);
                                              }
                                            : undefined
                                    }
                                    sx={{
                                        backgroundColor: '#FFF1E7',
                                        borderWidth: '1px',
                                        borderColor: theme.palette.secondary.main,
                                        borderStyle: 'solid',
                                        '& .MuiChip-label': {color: theme.palette.secondary.main, fontWeight: 700},
                                        '& .MuiChip-icon': {color: theme.palette.secondary.main, fontSize: '22px'},
                                        '& .MuiChip-deleteIcon': {color: theme.palette.secondary.main},
                                    }}
                                />
                            </Grid>
                            <Menu
                                anchorEl={suspendedMenuAnchorEl}
                                open={suspendedMenuOpenFlag}
                                onClose={() => {
                                    setSuspendedMenuAnchorEl(null);
                                }}
                            >
                                <MenuItem onClick={handleProceed}>
                                    <ListItemIcon>
                                        <PlayArrowIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText disableTypography sx={{color: 'primary.main', fontWeight: 700}}>
                                        {t('order_detail_button_proceed')}
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                    )}

                    {/* Suspend order button */}
                    {showSuspendAction && (
                        <Grid item xs={6} md='auto'>
                            <Button
                                size='small'
                                variant='text'
                                color='primary'
                                onClick={handleSuspended}
                                startIcon={<PauseIcon />}
                            >
                                {t('order_detail_button_suspend')}
                            </Button>
                        </Grid>
                    )}

                    {/* A chip that indicates that the order has limit set. It comes with a context menu with limit orders. */}
                    {isLimitSet && order.status_final !== STATUS_CANCELLED && (
                        <>
                            <Grid item xs={12} md='auto'>
                                <Chip
                                    label={t('waiting_for_limit')}
                                    icon={<TimelineIcon />}
                                    deleteIcon={<KeyboardArrowDownIcon />}
                                    onDelete={(event) => {
                                        setLimitMenuAnchorEl(event.currentTarget);
                                    }}
                                    sx={{
                                        backgroundColor: '#EBE7F6',
                                        borderWidth: '1px',
                                        borderColor: theme.palette.primary.main,
                                        borderStyle: 'solid',
                                        '& .MuiChip-label': {color: theme.palette.primary.main, fontWeight: 700},
                                        '& .MuiChip-icon': {color: theme.palette.primary.main, fontSize: '22px'},
                                        '& .MuiChip-deleteIcon': {color: theme.palette.primary.main},
                                    }}
                                />
                            </Grid>
                            <Menu
                                anchorEl={limitMenuAnchorEl}
                                open={limitMenuOpenFlag}
                                onClose={() => {
                                    setLimitMenuAnchorEl(null);
                                }}
                            >
                                <ViewResponse reducer={liveSuspend} fetched={menuItemsOnLiveSuspendFetched} />

                                {showCancelLimitPriceAction && (
                                    <MenuItem onClick={handleRemoveLimit}>
                                        <ListItemIcon>
                                            <DeleteOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText disableTypography sx={{color: 'primary.main', fontWeight: 700}}>
                                            {t('button_remove_limit')}
                                        </ListItemText>
                                    </MenuItem>
                                )}
                            </Menu>
                        </>
                    )}

                    {/* A button to set limit - fresh rate must be fetched first. */}
                    {!isOverLimit && isLimitEnabledSetting && isAuthOrder && (
                        <ViewResponse reducer={liveSuspend} fetched={buttonsOnLiveSuspendFetched} />
                    )}

                    {/* Show cancel order button */}
                    {showCancelAction && (
                        <Grid item xs={6} md='auto'>
                            <Button
                                variant='text'
                                onClick={handleCancelled}
                                startIcon={<CancelIcon />}
                                color='primary'
                                size='small'
                            >
                                {t('order_detail_button_cancel')}
                            </Button>
                        </Grid>
                    )}

                    {/* Show refund order button - user see it as a cancel button. */}
                    {showRefundAction && (
                        <Grid item xs={6} md='auto'>
                            <OrderReturnButtonWithForm
                                order={order}
                                fromCurrency={fromCurrency}
                                token={token}
                                buttonChildren={t('order_detail_button_cancel')}
                                cryptoAddressDropdown={cryptoAddressDropdown}
                            />
                        </Grid>
                    )}

                    {/* Show back to trezor/invity button */}
                    {showBackToVendorButton && (
                        <Grid item xs={6} md='auto'>
                            <Link
                                component='a'
                                href={order.provider_url ?? undefined}
                                target='_blank'
                                rel='nofollow noopener noreferrer'
                                underline='none'
                            >
                                <Button color='primary' size='small' variant='text'>
                                    {t('order_view_back_to_vendor_button_label', {providerName: order.provider_name})}
                                </Button>
                            </Link>
                        </Grid>
                    )}

                    {/* A menu hiding next less important actions. */}
                    <Grid item xs={6} md='auto'>
                        <Button
                            id='more-menu-button'
                            aria-controls={moreMenuOpenFlag ? 'next-menu' : undefined}
                            aria-haspopup
                            aria-expanded={moreMenuOpenFlag ? 'true' : undefined}
                            size='small'
                            color='primary'
                            variant='text'
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setMoreMenuAnchorEl(event.currentTarget);
                            }}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            {t('button_more')}
                        </Button>
                    </Grid>
                    <Menu
                        anchorEl={moreMenuAnchorEl}
                        open={moreMenuOpenFlag}
                        onClose={() => {
                            setMoreMenuAnchorEl(null);
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'more-menu-button',
                        }}
                    >
                        <MenuItem onClick={handleDownloadPdf}>
                            <ListItemIcon sx={{color: 'primary.main'}}>
                                <PdfIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText disableTypography sx={{color: 'primary.main', fontWeight: 700}}>
                                {t('order_detail_button_download')}
                            </ListItemText>
                        </MenuItem>
                        {user && order?.id && (
                            <MenuItem>
                                <ListItemIcon sx={{color: 'primary.main'}}>
                                    <TicketIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText disableTypography sx={{color: 'primary.main', fontWeight: 700}}>
                                    <Link href={`/ticket/create/${order.id}`} underline='none'>
                                        {t('order_detail_button_new_ticket')}
                                    </Link>
                                </ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                </Grid>
            )}

            <Card>
                <CardContent>
                    <Stack>
                        {!isSuspended && originalPriceComponent('large', true)}
                        {!isSuspended && (
                            <Stack
                                direction={{xs: 'column', sm: 'row'}}
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{padding: '10px 20px'}}
                            >
                                <Typography variant='body2'>{t('order_detail_rate')}</Typography>

                                <Typography variant='body2' fontWeight={700}>
                                    {order.rate}
                                </Typography>
                            </Stack>
                        )}

                        <Stack
                            direction={{xs: 'column', sm: 'row'}}
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{padding: '10px 20px'}}
                        >
                            {toCurrency?.type === 'crypto' ? (
                                <>
                                    <Typography variant='body2'>{cryptoAddressLabel}</Typography>

                                    <Typography variant='body2' fontWeight={700}>
                                        {cryptoAddressValue}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant='body2'>{bankAccountLabel}</Typography>

                                    <Typography variant='body2' fontWeight={700}>
                                        {bankAccountValue}
                                    </Typography>
                                </>
                            )}
                        </Stack>
                        {isSuspended && !isSuspendedOverLimit && currentPriceOfferComponent}
                        {isSuspended && (
                            <Accordion
                                sx={{
                                    backgroundColor: 'rgba(65, 39, 126, 0.05)',
                                    borderRadius: '8px',
                                    '&:first-of-type': {borderTopLeftRadius: '8px', borderTopRightRadius: '8px'},
                                    borderWidth: 0,
                                    boxShadow: 'none',
                                    padding: 0,
                                }}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Stack
                                        direction={{xs: 'column', md: 'row'}}
                                        justifyContent='space-between'
                                        sx={{width: 1, pr: 1}}
                                    >
                                        <Typography variant='body2'>
                                            {isLimitSet
                                                ? t('order_view_limit_price_label')
                                                : t('order_view_original_price_label')}
                                        </Typography>
                                        {originalPriceComponent('standard', false)}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant='body2' fontWeight={300} textAlign='right'>
                                        {order.rate}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
}
