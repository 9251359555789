import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Select, MenuItem} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {RootState} from '../../redux/store';
import {AccountAddress, AccountInfo} from '@trezor/connect-web';
import {getAccountNumberFromPath, getAddressIndexFromPath} from '../../helpers/crypto';

export interface CryptoAddressSelectProps {
    accountInfo?: AccountInfo;
    currencyCode: string | null;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    addressFieldName: string;
}

/**
 *  Renders a select with crypto addresses of a trezor account.
 *
 *  For btc-like coins the addresses are in accountInfo.addresses
 *  For eth there is only one address in accountInfo.descriptor
 *
 */
export default function TrezorCryptoAddressSelect({
    accountInfo,
    setFieldValue,
    currencyCode,
    addressFieldName,
}: CryptoAddressSelectProps) {
    const {deviceInfo} = useSelector((state: RootState) => state.trezorConnect);
    const [selectionIndex, setSelectionIndex] = useState(0);
    const {t} = useTranslation();
    const trezorLabel = deviceInfo?.label ?? '';

    const addresses: AccountAddress[] = accountInfo?.addresses?.used
        ? [...accountInfo.addresses.used, ...accountInfo.addresses.unused]
        : [getSingleAddress(accountInfo)];

    useEffect(() => {
        setSelectionIndex(getFirstUnusedAddressIndex());
    }, [accountInfo]);

    useEffect(() => {
        const selectedAddress = addresses[selectionIndex];

        if (!selectedAddress || !selectedAddress.path || !selectedAddress.address) {
            return;
        }

        const accountNumber = (getAccountNumberFromPath(selectedAddress.path) ?? 0) + 1; // indexed from 0, but displayed from 1
        const addressNumber = (getAddressIndexFromPath(selectedAddress.path) ?? 0) + 1; // indexed from 0, but displayed from 1

        const addressLabelPrefill = `Trezor ${trezorLabel} ${currencyCode} account #${accountNumber} address #${addressNumber}`;

        setFieldValue(addressFieldName, selectedAddress.address);
        setFieldValue('address_label', addressLabelPrefill);
        setFieldValue('address_derivation_path', selectedAddress.path);
    }, [selectionIndex]);

    /**
     * Returns selected address index
     * @return {number}
     */
    function getFirstUnusedAddressIndex() {
        let addressIndex: number = addresses.length;

        addresses.forEach((address, i) => {
            const hasTransfer = address?.transfers && address.transfers > 0;
            if (!hasTransfer && i < addressIndex) {
                addressIndex = i;
            }
        });

        return addressIndex;
    }
    /**
     * Creates Address from available accountInfo object
     */
    function getSingleAddress(accountInfo): AccountAddress {
        return {
            path: accountInfo.path,
            address: accountInfo.descriptor,
            transfers: 0,
        };
    }

    /**
     * Get address label to show in select
     * @param address
     */
    function getAddressLabel(address) {
        const used = address.transfers > 0 ? `(${t('address_used')})` : '';
        return `#${(getAddressIndexFromPath(address.path) ?? 0) + 1} ${address.address} ${used}`;
    }

    /**
     * Handle change selection
     * @param event
     */
    function handleChange(event) {
        setSelectionIndex(event.target.value);
    }

    const options = useMemo(
        () =>
            addresses.map((item, key) => (
                <MenuItem key={key} value={key}>
                    {getAddressLabel(item)}
                </MenuItem>
            )),
        [addresses]
    );

    return (
        <Select value={selectionIndex} onChange={handleChange} variant='outlined'>
            {options}
        </Select>
    );
}

TrezorCryptoAddressSelect.displayName = 'TrezorCryptoAddressSelect';
