import React from 'react';
import {createSvgIcon} from '@mui/material';

const InfoIcon = createSvgIcon(
    <>
        <rect width='21' height='21' rx='10.5' fill='#F4F2FF' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.494 7.327C11.248 7.34 11.794 6.924 11.794 6.17C11.794 5.403 11.248 4.987 10.494 5C9.75303 5.013 9.20703 5.403 9.20703 6.17C9.20703 6.898 9.75303 7.314 10.494 7.327ZM8 13.9999H9.46703V9.99993H8V8.49993H11.5V8.68662H11.508V13.9999H13V15.4999H8V13.9999Z'
            fill='#4F525F'
        />
    </>,
    'InfoIcon'
);

export default InfoIcon;
