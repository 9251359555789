import React, {useMemo} from 'react';
import Helmet from 'react-helmet';
import {
    ensureLanguageInPath,
    ensureLanguageOnly,
    getConfigValue,
    getPathWithoutLanguage,
    useBackendSettings,
} from '@simplecoin/core';
import {useLocation} from 'react-router-dom';
import i18n from '~/src/config/i18n';

export interface HeadProps {
    title?: string;
    description?: string;
    appendName?: boolean;
    prependName?: boolean;
    children?: any;
    availableInLanguages?: string[];
    canonicalPathWithLanguage?: boolean;
}

/**
 * Helper component for using Helmet
 *  The title param can have the company name prepended or appended based on given props
 *
 *
 * @param title
 * @param description - text for the description meta tag. Should be up to 300 chars according to https://www.link-assistant.com/news/html-tags-for-seo.html
 * @param appendName
 * @param prependName
 * @param children
 * @param languages - list of available language codes that the page is available for. If not provided,
 * all languages from settings will be set instead!
 * @param canonicalPathWithLanguage - an option not to include language in canonical url. Use if some language variants may
 * have identical content of body
 * @constructor
 */
export default function HeadManager({
    title = '',
    description = '',
    appendName = true,
    prependName = false,
    children,
    availableInLanguages,
    canonicalPathWithLanguage = true,
}: HeadProps) {
    const languages = useBackendSettings<string[]>('languages', ['en', 'cs']);
    const companyName = useBackendSettings('companyName', '');
    const location = useLocation();

    availableInLanguages = availableInLanguages || languages;

    const baseUrl = getConfigValue('appUrl', '');
    const url = baseUrl + location.pathname;

    title = appendName ? `${title} | ${companyName}` : title;
    title = prependName ? `${companyName} |${title}` : title;

    const languageVariants = useMemo(
        () =>
            availableInLanguages?.map((language, key) => {
                language = ensureLanguageOnly(language);
                const localizedUrl = baseUrl + ensureLanguageInPath({path: location.pathname, language});
                return <link key={key} rel='alternate' hrefLang={language} href={localizedUrl} />;
            }),
        [availableInLanguages, location.pathname]
    );

    const canonicalUrl = canonicalPathWithLanguage ? url : baseUrl + getPathWithoutLanguage(location.pathname, true);

    return (
        <Helmet>
            <html lang={i18n.language} />
            <title>{title}</title>
            {title && <meta property='og:title' content={title} />}
            {languageVariants}
            {url && <link rel='canonical' href={canonicalUrl} />}
            {url && <meta property='og:url' content={url} />}
            {description && <meta name='description' content={description} />}
            {description && <meta property='og:description' content={description} />}
            {children}
        </Helmet>
    );
}

HeadManager.displayName = 'HeadManager';
