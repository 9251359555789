import {enqueueSnackbar} from '~/redux/snackbarSlice';
import {getTrezorConnect} from './TrezorConnect';
import {setAccountInfo, setAddress, transactionSent} from '~/redux/trezorConnectSlice';
import {getTestnetCoinName} from '~/helpers/trezorUtils';
import {UI_REQUEST} from '@trezor/connect-web';
import {getConfigValue} from '~/helpers/config';

/**
 * Get info abou user selected account (by discovery)
 */
export function getTrezorAccountInfo(currencyName, dispatch) {
    getTrezorConnect(dispatch, (instance) => {
        instance
            .getAccountInfo({
                coin: currencyName,
                details: 'tokens',
            })
            .then((response) => {
                dispatch(setAccountInfo(response));
            })
            .catch((reason) => {
                handleFailure(reason, dispatch);
            });
    });
}

/**
 * Handle trezor connect promise rejection
 */
function handleFailure(reason, dispatch) {
    if (getConfigValue('environment') === 'development') {
        dispatch(enqueueSnackbar({variant: 'error', message: `Trezor error: ${reason}`}));
    } else {
        // todo: make sure this way of invokint t func works
        // @ts-ignore
        // dispatch(enqueueSnackbar({variant: 'error', message: i18n.getI18n().t('oops_something_went_wrong')}));
    }
}

interface SendTransactionParams {
    currencyName: string;
    amountSatoshis: string;
    address: string;
}
/**
 * A call to send transaction using trezor
 */
export function trezorSendTransaction({currencyName, amountSatoshis, address}: SendTransactionParams, dispatch) {
    getTrezorConnect(dispatch, (instance) => {
        instance
            .composeTransaction({
                coin: currencyName,
                outputs: [
                    {
                        amount: amountSatoshis,
                        address,
                    },
                ],
                push: true,
            })
            .then((response) => {
                dispatch(transactionSent(response));
            })
            .catch((reason) => {
                handleFailure(reason, dispatch);
            });
    });
}

export interface VerifyAddressParams {
    addressDerivationPath: string;
    address: string;
    currencyName: string;
    callback: () => void;
}

/**
 * Verify that address belongs to connected trezor device and show it on the display
 */
export function trezorVerifyAddress(
    {addressDerivationPath, address, currencyName, callback}: VerifyAddressParams,
    dispatch
) {
    getTrezorConnect(dispatch, (instance) => {
        instance.on(UI_REQUEST.ADDRESS_VALIDATION, () => callback());
        let getAddressMethod = instance.getAddress;
        // Ethereum and Ripple have a different method with same signature
        if (currencyName.toUpperCase() === 'ETH') {
            getAddressMethod = instance.ethereumGetAddress;
        } else if (currencyName.toUpperCase() === 'XRP') {
            getAddressMethod = instance.rippleGetAddress;
        }

        // coin names differ for testnets in trezor connect
        if (getConfigValue('environment') === 'development') {
            currencyName = getTestnetCoinName(currencyName) ?? '';
        }

        getAddressMethod({
            path: addressDerivationPath,
            address,
            coin: currencyName,
            showOnTrezor: true,
        })
            .then((response) => {
                dispatch(setAddress(response));
            })
            .catch((reason) => {
                handleFailure(reason, dispatch);
            });
    });
}
