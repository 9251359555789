import {SvgIcon} from '@mui/material';
import React from 'react';

export default function PortfolioIcon() {
    return (
        <SvgIcon
            viewBox='0 0 24 24'
            sx={{fill: 'currentColor', strokeWidth: 0, stroke: '#41277E', strokeLinejoin: 'round'}}
        >
            <path d='m 17.697641,20.601373 c -1.701156,1.286955 -3.82041,2.050334 -6.117961,2.050334 -5.6056905,0 -10.15,-4.54431 -10.15,-10.15 3e-7,-5.60569 4.5443098,-10.1499997 10.15,-10.1499997 0.617341,0 1.221809,0.055114 1.808753,0.1606891 l -1.808753,9.9893106 8.125771,5.848886 c -0.585951,0.899355 -1.271037,1.673194 -2.00781,2.25078 z' />
            <path d='m 13.59361,10.572947 1.488012,-8.4116487 c 3.299431,0.7100575 5.930147,3.2198755 6.81422,6.4514052 z' />
            <path d='m 13.719672,12.09066 8.481647,-1.955865 c 0.479018,2.484919 -0.10319,5.018936 -1.514152,7.026465 z' />
        </SvgIcon>
    );
}
