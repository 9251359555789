import React from 'react';
import {Grid, GridProps, useMediaQuery, Pagination, Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Space} from '.';

export interface CardListProps {
    items?: any[];
    meta: {
        currentPage: number;
        pageCount: number;
        totalCount: number;
        perPage: number;
    };
    gridItemProps?: GridProps;
    componentItem: any;
    componentProps?: Record<string, unknown>;
    handlePageChange: (e: React.MouseEvent<HTMLElement>, page: number) => void;
    containerProps?: GridProps;
    noItemsMessage?: string;
}

/**
 * Helper component which renders list of items in <Grid> component from Material UI with pagination.
 * @param items
 * @param currentPage
 * @param pageCount
 * @param totalCount
 * @param perPage
 * @param gridItemProps
 * @param ComponentItem
 * @param componentProps
 * @param handlePageChange
 * @param containerProps
 * @param noItemsMessage
 * @constructor
 */
export default function CardList({
    items = [],
    meta: {currentPage = 1, pageCount = 0, totalCount = 0, perPage = 20} = {},
    gridItemProps = {
        xl: 3,
        lg: 4,
        md: 6,
        sm: 12,
        xs: 12,
    },
    componentItem: ComponentItem,
    componentProps = {},
    handlePageChange,
    containerProps = {
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        spacing: 1,
    },
    noItemsMessage = '',
}: CardListProps) {
    const {t} = useTranslation();
    const mobileScreen = useMediaQuery('(max-width:600px)');
    const size = mobileScreen ? 'medium' : 'large';

    const paginationComponent =
        totalCount > perPage ? (
            <>
                <Stack>
                    <Pagination
                        data-testid='pagination-item'
                        showFirstButton
                        showLastButton
                        count={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}
                        size={size}
                    />
                </Stack>
                <Space />
            </>
        ) : null;

    return (
        <>
            {paginationComponent}

            <Grid container {...containerProps}>
                {items && items.length > 0 ? (
                    items.map((item, key) => (
                        <Grid item key={key} {...gridItemProps} data-testid='card-item'>
                            <ComponentItem item={item} key={key} {...componentProps} />
                        </Grid>
                    ))
                ) : (
                    <Grid item data-testid='card-list-no-results'>
                        {noItemsMessage || t('no_results_found')}
                    </Grid>
                )}
            </Grid>

            <Space />

            {paginationComponent}
        </>
    );
}

CardList.displayName = 'CardList';
