import React from 'react';
import {styled} from '@mui/material';

export interface BadgeProps {
    textColor: string;
    bgColor: string;
    children: string | React.ReactNode;
}

/**
 * Renders Badge.
 * @param {string} textColor
 * @param {string} bgColor
 * todo: instead of having empty bg color and normal color, maybe it could be using theme colors
 */
const BadgeWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textColor',
})<BadgeProps>`
    color: ${(props) => props.textColor || '#000'};
    background-color: ${(props) => props.bgColor || '#fff'};
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 2px;
    line-height: 15px;
    padding: 6px 11px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
`;

/**
 * Renders simple badge.
 *
 * @param textColor
 * @param bgColor
 * @param children
 * @param rest
 * @constructor
 */
export default function Badge({textColor, bgColor, children, ...rest}: BadgeProps) {
    if (!rest['data-testid'] && typeof children === 'string') {
        rest['data-testid'] = `badge-${children.toLowerCase()}`;
    }

    return (
        <BadgeWrapper textColor={textColor} bgColor={bgColor} {...rest} data-testid={rest['data-testid'] || ''}>
            {children}
        </BadgeWrapper>
    );
}

Badge.displayName = 'Badge';
