import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OptionsObject, SnackbarMessage, VariantType} from 'notistack';

export interface SnackbarInterface {
    logId?: string;
    message: SnackbarMessage;
    variant: VariantType;
    options?: OptionsObject;
    dismissed?: boolean;
    key?: number;
}

interface NotificationStateInterface {
    notifications: Array<SnackbarInterface>;
}

const initialState: NotificationStateInterface = {
    notifications: [],
};

const slice = createSlice({
    name: 'snackbar',
    initialState,

    reducers: {
        enqueueSnackbar: {
            reducer: (state, action: PayloadAction<SnackbarInterface>) => {
                // @ts-ignore
                state.notifications.push(action.payload);
            },
            prepare: (notification: SnackbarInterface) => {
                if (notification?.variant && !notification?.options?.variant) {
                    return {
                        payload: {
                            ...notification,
                            options: {...notification.options, variant: notification.variant},
                            key: new Date().getTime() + Math.random(),
                        },
                    };
                }
                const payload = {...notification, key: new Date().getTime() + Math.random()};
                return {payload};
            },
        },
        closeSnackbar: (state, action: PayloadAction<{dismissAll?: boolean; key?: number}>) => {
            state.notifications = state.notifications.map((notification) => {
                const shouldDismiss = notification.key === action.payload?.key || action.payload?.dismissAll;
                return shouldDismiss ? {...notification, dismissed: true} : {...notification};
            });
        },
        removeSnackbar: (state, action) => {
            state.notifications = state.notifications.filter((notification) => notification.key !== action.payload);
        },
    },
});
export const {actions, reducer} = slice;

export const {enqueueSnackbar, closeSnackbar, removeSnackbar} = actions;
