import {useLayoutEffect, useState} from 'react';

/**
 * Hook to get current window inner with and height
 * use: const [width, height] = useWindowSize();
 * @return {number[]}
 */
export default function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
