import React from 'react';
import {styled} from '@mui/material';

export interface SpaceProps {
    size?: string | number;
}

const Spacer = styled('div')`
    height: ${(props: SpaceProps) => props.size};
    width: 100%;
`;

/**
 * Spacer to separate elements vertically on the page.
 * @param {string|number} size
 * @return {*}
 * @constructor
 */
export default function Space({size = 20}: SpaceProps) {
    if (typeof size === 'number') {
        size = size + 'px';
    }

    return <Spacer size={size} />;
}

Space.displayName = 'Space';
