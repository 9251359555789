import {Button, styled, Tooltip, Typography, useTheme} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import Dialog, {DialogInterface} from '../../components/Dialog';
import {useTranslation} from 'react-i18next';
import {getTestnetCoinName} from '~/helpers/trezorUtils';
import {getTrezorAccountInfo} from './TrezorActions';
import TrezorCryptoAddressSelect from './TrezorCryptoAddressSelect';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/redux/store';
import {enqueueSnackbar} from '~/redux/snackbarSlice';
import {AccountInfo} from '@trezor/connect-web';
import {TREZOR_SUPPORTED_CURRENCY_IMPORT} from '~/config/params';
import {CropFree as CropFreeIcon} from '@mui/icons-material';
import {getConfigValue} from '~/helpers/config';

interface AddAddressFromTrezorButton {
    currencyCode: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    addressFieldName: string;
}

const QrScannerChip = styled('div')`
    padding: 6px 10px;
    background: #f4f2ff;
    border-radius: 99px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
`;

export default function AddAddressFromTrezorButton({
    currencyCode,
    setFieldValue,
    addressFieldName,
}: AddAddressFromTrezorButton) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {accountInfo} = useSelector((state: RootState) => state.trezorConnect);
    const addressSelectDialogRef = useRef<DialogInterface>(null);
    const [trezorAccountInfo, setTrezorAccountInfo] = useState<AccountInfo>();
    const theme = useTheme();

    useEffect(() => {
        if (accountInfo) {
            if (accountInfo.success) {
                const trezorAccountInfo = accountInfo.payload;
                setTrezorAccountInfo(trezorAccountInfo);
                addressSelectDialogRef?.current?.openDialog?.();
            } else {
                const errorMessage = accountInfo.payload && accountInfo.payload.error ? accountInfo.payload.error : '';
                dispatch(enqueueSnackbar({variant: 'error', message: `Trezor: ${errorMessage}`}));
            }
        }
    }, [accountInfo]);

    /**
     * Get address from Trezor by account discovery (letting user choose)
     */
    function getAddressfromTrezor() {
        let targetCurrencyCode = currencyCode.toUpperCase();
        const currencySupported = TREZOR_SUPPORTED_CURRENCY_IMPORT.includes(targetCurrencyCode);

        if (currencySupported) {
            // coin names differ for testnets in trezor connect
            if (getConfigValue('environment') === 'development') {
                targetCurrencyCode = getTestnetCoinName(targetCurrencyCode) ?? '';
            }

            if (targetCurrencyCode) {
                getTrezorAccountInfo(targetCurrencyCode, dispatch);
            }
        }
    }

    const trezorAddressTooltipTitle = t('trezor_address_import_available_for_currencies', {
        currencies: TREZOR_SUPPORTED_CURRENCY_IMPORT,
    });

    const handleSave = () => {
        addressSelectDialogRef?.current?.closeDialog();
    };

    return (
        <>
            <Tooltip title={trezorAddressTooltipTitle} arrow>
                <QrScannerChip onClick={getAddressfromTrezor}>
                    <CropFreeIcon fontSize='small' htmlColor={theme.cool.grey100} />
                    <Typography variant='subtitle2' color={theme.cool.grey100} fontWeight={400}>
                        {t('import_from_trezor_button')}
                    </Typography>
                </QrScannerChip>
            </Tooltip>

            <Dialog
                dialogTitle={t('trezor_address_import_dialog_header')}
                dialogContent={
                    <TrezorCryptoAddressSelect
                        accountInfo={trezorAccountInfo}
                        setFieldValue={setFieldValue}
                        currencyCode={currencyCode}
                        addressFieldName={addressFieldName}
                    />
                }
                ref={addressSelectDialogRef}
                dialogActions={<Button onClick={handleSave}>{t('save')}</Button>}
            />
        </>
    );
}
