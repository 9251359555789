import {getConfigValue} from '@simplecoin/core';
import i18next from '~/src/config/i18n';

type PostSlug =
    | 'wallet-terms-of-services'
    | 'terms-of-services'
    | 'privacy-policy'
    | 'support'
    | 'affiliate-banners'
    | 'affiliate-info';
type Site = 'landing' | 'blog';

type LinksInterface = {
    [slug in PostSlug]: {path: string; site: Site};
};

const LINKS: LinksInterface = {
    'privacy-policy': {path: 'privacy', site: 'landing'},
    'terms-of-services': {path: 'terms-of-services', site: 'landing'},
    'wallet-terms-of-services': {path: 'wallet-terms-of-services', site: 'landing'},
    'affiliate-banners': {path: 'post/affiliate-banners', site: 'landing'},
    'affiliate-info': {path: 'post/affiliate-info', site: 'landing'},
    support: {path: 'support', site: 'landing'},
};

const landingUrl: string = getConfigValue('landingUrl', '');
const blogUrl: string = getConfigValue('blogUrl', '');

export default function getLink(name: PostSlug, defaultValue = '/'): string {
    const langPrefix = i18next.language === 'cs' ? 'cs/' : '';

    try {
        const urls: {[site in Site]: string} = {
            landing: landingUrl,
            blog: blogUrl,
        };

        const url = urls[LINKS[name].site];

        return `${url}/${langPrefix}${LINKS[name].path}`;
    } catch (e) {
        return defaultValue;
    }
}
