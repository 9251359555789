import React from 'react';
import {Container} from '@mui/material';
import {PageHeader} from '~/src/components';
import {Helmet} from 'react-helmet';

export interface PageAuthProps {
    children: React.ReactNode | string;
    headerTitle?: string | React.ReactNode;
    headerAction?: React.ReactNode;
    headerSubTitle?: string | React.ReactNode;
    helmetTitle?: string;
    maxWidth?: 'xl' | 'lg' | 'md';
}

/**
 * Authorized client layout page.
 */
export default function Page({
    headerTitle,
    headerSubTitle,
    helmetTitle,
    headerAction,
    children,
    maxWidth = 'lg',
}: PageAuthProps) {
    return (
        <Container maxWidth={maxWidth}>
            <Helmet title={helmetTitle ?? headerTitle} />
            <PageHeader title={headerTitle} action={headerAction} subTitle={headerSubTitle} />
            {children}
        </Container>
    );
}
