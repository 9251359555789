import {Box, Stack, Typography, useTheme} from '@mui/material';
import React from 'react';
import loadable from '@loadable/component';
import {useTranslation} from 'react-i18next';

interface LogoProps {
    onClick?: () => void;
}

export default function Logo({onClick}: LogoProps) {
    const theme = useTheme();
    const {t} = useTranslation();
    const isWallet = process.env.BUILD_TYPE === 'wallet';

    let Logo;
    if (theme.logo?.name === 'simplecoin') {
        Logo = loadable(() => import('./SimplecoinLogo'));
    } else if (theme.logo?.name === 'chainblock') {
        Logo = loadable(() => import('./CainblockLogo'));
    } else if (theme.logo?.name === 'devizovaburza') {
        Logo = loadable(() => import('./DevizovaburzaLogo'));
    } else {
        Logo = <></>;
    }

    return (
        <Box onClick={onClick} sx={{display: 'flex', marginLeft: '16px', width: '320px'}}>
            <Stack direction='column' alignItems='flex-end'>
                <Logo />
                {theme.logo?.name === 'simplecoin' && isWallet && (
                    <Typography variant='h3'>{t('wallet_logo')}</Typography>
                )}
            </Stack>
        </Box>
    );
}
