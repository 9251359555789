import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchGeneric, handleReducerCases, OrderActions, ThunkApi, ReducerEnvelope} from '@simplecoin/core';
import PaginatedProps = OrderActions.PaginatedProps;
import {AccountList, BalancesListResponse, Account} from '~/src/typings/models/BalancesTypes';

export interface FetchAccountsProps extends PaginatedProps {
    currencyFilter?: string | null;
}

export interface AccountReducerInterface {
    listAccounts: ReducerEnvelope<AccountList>;
    activeAccountsList: ReducerEnvelope<AccountList>;
    suspendedAccountsList: ReducerEnvelope<AccountList>;
    closedAccountsList: ReducerEnvelope<AccountList>;
    create: ReducerEnvelope<Account>;
    createFromWizard: ReducerEnvelope<Account>;
    view: ReducerEnvelope<Account>;
    update: ReducerEnvelope<Account>;
    closeResponse: ReducerEnvelope<number>;
}

const initialState: AccountReducerInterface = {
    listAccounts: {isFetching: false},
    activeAccountsList: {isFetching: false},
    suspendedAccountsList: {isFetching: false},
    closedAccountsList: {isFetching: false},
    create: {isFetching: false},
    createFromWizard: {isFetching: false},
    view: {isFetching: false},
    update: {isFetching: false},
    closeResponse: {isFetching: false},
};

export const fetchAccounts = createAsyncThunk<ReducerEnvelope<BalancesListResponse<Account>>, PaginatedProps>(
    'balances/accounts/list',
    async ({pagination: {page = null, perPage = 10}}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/accounts/list',
                params: {page, perPage},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

/**
 * Fetching accounts without setting isFetching flag to true.
 */
export const refetchAccounts = createAsyncThunk<ReducerEnvelope<BalancesListResponse<Account>>, PaginatedProps>(
    'balances/accounts/relist',
    async ({pagination: {page = null, perPage = 10}}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/accounts/list',
                params: {page, perPage},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchActiveAccounts = createAsyncThunk<ReducerEnvelope<BalancesListResponse<Account>>, FetchAccountsProps>(
    'balances/accounts/activelist',
    async ({pagination: {page = null, perPage = 10}, currencyFilter}, thunkApi) => {
        const filter: {status: string; currency?: string} = {status: 'active'};
        if (currencyFilter) {
            filter.currency = currencyFilter;
        }
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/accounts/list',
                params: {page, perPage, filter},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

/**
 * Fetching accounts without setting isFetching flag to true.
 */
export const refetchActiveAccounts = createAsyncThunk<ReducerEnvelope<BalancesListResponse<Account>>, PaginatedProps>(
    'balances/accounts/activeRelist',
    async ({pagination: {page = null, perPage = 10}}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/accounts/list',
                params: {page, perPage},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchClosedAccounts = createAsyncThunk<ReducerEnvelope<BalancesListResponse<Account>>, FetchAccountsProps>(
    'balances/accounts/closedlist',
    async ({pagination: {page = null, perPage = 10}, currencyFilter}, thunkApi) => {
        const filter: {status: string; currency?: string} = {status: 'closed'};
        if (currencyFilter) {
            filter.currency = currencyFilter;
        }
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/accounts/list',
                params: {page, perPage, filter},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchSuspendedAccounts = createAsyncThunk<
    ReducerEnvelope<BalancesListResponse<Account>>,
    FetchAccountsProps
>('balances/accounts/listsuspended', async ({pagination: {page = null, perPage = 10}, currencyFilter}, thunkApi) => {
    const filter: {status: string; currency?: string} = {status: 'suspended'};
    if (currencyFilter) {
        filter.currency = currencyFilter;
    }
    const res = await fetchGeneric(
        {
            method: 'get',
            url: 'balances/accounts/list',
            params: {page, perPage, filter},
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

export const fetchAccountDetails = createAsyncThunk<ReducerEnvelope<Account>, number>(
    'balances/accounts/single',
    async (id, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/accounts/single',
                params: {id},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchUpdateAccount = createAsyncThunk<
    ReducerEnvelope<Account>,
    {accountId: number; label: string | null; description: string | null}
>('balances/accounts/update', async ({accountId, label, description}, thunkApi) => {
    const res = await fetchGeneric(
        {
            method: 'post',
            url: 'balances/accounts/update',
            data: {
                '@type': 'api.v1.modules.balances.forms.UpdateAccountRequest',
                accountId: accountId,
                label: label,
                description: description,
            },
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

export const createAccount = createAsyncThunk<
    ReducerEnvelope<Account>,
    {currencyCode: string; label: string; description: string}
>('balances/accounts/create', async ({currencyCode, label, description}, thunkApi) => {
    const res = await fetchGeneric(
        {
            method: 'post',
            url: 'balances/accounts/create',
            params: {currencyCode: currencyCode, label: label, description: description},
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

export const createAccountFromWizard = createAsyncThunk<
    ReducerEnvelope<Account>,
    {currencyCode: string; label: string; description: string}
>('balances/accounts/createFromWizard', async ({currencyCode, label, description}, thunkApi) => {
    const res = await fetchGeneric(
        {
            method: 'post',
            url: 'balances/accounts/create',
            params: {currencyCode: currencyCode, label: label, description: description},
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

export const closeAccount = createAsyncThunk<ReducerEnvelope<null>, any>(
    'balances/accounts/close',
    async (accountId, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'delete',
                url: 'balances/accounts/close',
                params: {accountId},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        invalidateAccountDelete: (state) => {
            state.closeResponse = initialState.closeResponse;
        },
        invalidateAccountUpdate: (state) => {
            state.update = initialState.update;
        },
        invalidateAccountCreate: (state) => {
            state.create = initialState.create;
        },
        invalidateActiveAccounts: (state) => {
            state.create = initialState.create;
        },
    },
    extraReducers: (builder) => {
        handleReducerCases(fetchAccounts, 'listAccounts', builder);
        handleReducerCases(refetchAccounts, 'listAccounts', builder, undefined, true);
        handleReducerCases(fetchActiveAccounts, 'activeAccountsList', builder);
        handleReducerCases(refetchActiveAccounts, 'activeAccountsList', builder, undefined, true);
        handleReducerCases(fetchSuspendedAccounts, 'suspendedAccountsList', builder);
        handleReducerCases(fetchClosedAccounts, 'closedAccountsList', builder);
        handleReducerCases(fetchAccountDetails, 'view', builder);
        handleReducerCases(fetchUpdateAccount, 'update', builder);
        handleReducerCases(createAccount, 'create', builder);
        handleReducerCases(createAccountFromWizard, 'createFromWizard', builder);
        handleReducerCases(closeAccount, 'closeResponse', builder);
    },
});

export const {actions, reducer} = accountSlice;
export const {invalidateAccountDelete, invalidateAccountUpdate, invalidateAccountCreate, invalidateActiveAccounts} =
    accountSlice.actions;
