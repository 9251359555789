import React, {useEffect} from 'react';
import RateDisclaimer from './RateDisclaimer';
import ExchangeContainer from './ExchangeContainer';
import ExchangeFormCurrencySelects from './ExchangeFormCurrencySelects';
import {
    Box,
    Checkbox,
    Link,
    Stack,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
    FormHelperText,
    Alert,
} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import CryptoAccountFields from './CryptoAccountFields';
import {
    fetchCreateAuthOrder,
    fetchCreateGuestOrder,
    fetchRate,
    invalidatePrice,
    invalidateRate,
} from '~/redux/exchangeFormSlice';
import BankAccountFields from '../BankAccountFields';
import {useSelector} from 'react-redux';
import {ExchangeFormProps, RenderFormProps, SubmitSuccessFuncProps} from '~/typings/ExchangeFormTypings';
import {initiateAxiosApi} from '~/api';
import {LoadingButton} from '@mui/lab';
import {RootState, useCoreAppDispatch} from '~/redux/store';
import useBackendSettings from '../../hooks/useBackendSettings';
import renderWithSettings from '../../renderWithSettings';
import {enqueueSnackbar} from '~/redux/snackbarSlice';
import {Currencies} from '~/typings/currency';
import {FormTextField} from '../formComponents/FormTextField';

interface IsGuestProps {
    isGuest: boolean;
}

const MainStack = styled(Stack, {shouldForwardProp: (prop) => prop !== 'isGuest'})<IsGuestProps>(
    ({isGuest}) => `
    width: ${isGuest ? '1150px' : '100%'};
    background: #ffffff;
    box-shadow: 0px 24px 128px rgba(42, 28, 82, 0.05);
    border-radius: 20px;
    padding-bottom: ${isGuest ? '85px' : '46px'};
    padding-top: ${isGuest ? '0' : '46px'};

    @media (max-width: 1200px) {
        width: auto;
    }

    .Exchange-form-root {
        width: 100%;
    }
`
);

const HeaderItem = styled('div')`
    padding-top: 56px;

    @media (max-width: 600px) {
        padding: 16px 0 32px 0;
    }
`;

const Section = styled('div', {shouldForwardProp: (prop) => prop !== 'isGuest'})<IsGuestProps>(
    ({isGuest}) => `
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px 80px;
    width: ${isGuest ? 'calc(100% - 160px)' : '100%'};

    @media (max-width: 1200px) {
        width: ${isGuest ? 'calc(100% - 40px)' : '100%'};
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 16px 8px;
    }
`
);

const ExchangeFormWrapper = styled('div')`
    width: 100%;
`;

const InputsBox = styled('div')`
    width: 100%;
`;

/**
 * Guest exchange form
 * @return {JSX.Element}
 * @constructor
 */
function ExchangeForm({
    userAuth,
    bankAccountDropdown,
    cryptoAddressDropdown,
    apiUrl,
    onSuccess,
    clientCurrency = 'CZK',
    fromCurrencyCodePrefill,
    toCurrencyCodePrefill,
    fromAmountPrefill,
    toAmountPrefill,
}: ExchangeFormProps) {
    const {authCreate, guestCreate, price} = useSelector((state: RootState) => state.exchangeForm);
    const creatingInProgress = authCreate?.isFetching === true || guestCreate?.isFetching === true;

    // prefer data taken from props, if absent, try to find last called price request's params
    const fromAmount = fromAmountPrefill ?? price?.requestMeta?.fromAmount;
    const toAmount = toAmountPrefill ?? price?.requestMeta?.toAmount;
    const fromCurrencyCode = fromCurrencyCodePrefill ?? price?.requestMeta?.fromCurrencyCode;
    const toCurrencyCode = toCurrencyCodePrefill ?? price?.requestMeta?.toCurrencyCode;

    const {t, ready} = useTranslation();
    const isMobile = useMediaQuery('(max-width:900px)');
    const companyName = useBackendSettings('companyName');
    const currencies: Currencies = useBackendSettings('currencies', {});
    const isGuest = !userAuth?.is_auth;
    const dispatch = useCoreAppDispatch();
    const theme = useTheme();

    useEffect(() => {
        initiateAxiosApi(apiUrl);
        return () => {
            dispatch(invalidatePrice());
            dispatch(invalidateRate());
        };
    }, []);

    /**
     * Handles showing snackbar on successful order creation
     */
    function handleSubmitSuccess(props: SubmitSuccessFuncProps) {
        if (props.id) {
            dispatch(enqueueSnackbar({message: t('order_has_been_created'), variant: 'success'}));
            onSuccess?.(props);
        } else {
            dispatch(enqueueSnackbar({message: t('order_has_been_created_guest'), variant: 'success'}));
            onSuccess?.(props);
        }
        dispatch(fetchRate({fromCurrencyCode: 'CZK', toCurrencyCode: 'BTC'})); // fetching new rate for default currency
    }

    function handleOrderSubmit(exchangeFormValues) {
        const fromCurrencyId = currencies[exchangeFormValues.fromCurrencyCode]?.id;
        const toCurrencyId = currencies[exchangeFormValues.toCurrencyCode]?.id;

        if (isGuest) {
            dispatch(
                fetchCreateGuestOrder({
                    ...exchangeFormValues,
                    fromCurrencyId,
                    toCurrencyId,
                })
            );
        } else {
            dispatch(
                fetchCreateAuthOrder({
                    ...exchangeFormValues,
                    fromCurrencyId,
                    toCurrencyId,
                })
            );
        }
    }

    /**
     * Renders exchange form with data provided by the ExchangeContainer component
     * */
    function renderExchangeForm({
        onFromCurrencyChange,
        onFromAmountChange,
        swapCurrencies,
        onToCurrencyChange,
        onToAmountChange,
        rateResponse,
        priceResponse,
        rateOrPriceError,
        showCrypto,
        showBank,
        showEmail,
        formik,
        timer,
    }: RenderFormProps) {
        const {
            toCurrencyCode,
            toAmount,
            fromAmount,
            fromCurrencyCode,
            email,
            arbitraryData,
            cryptoAccount,
            bankAccount,
            termsAndCondition,
        } = formik.values;
        const {handleChange, handleBlur, setFieldValue, errors, values} = formik;

        const cryptoAddressItem =
            userAuth?.is_auth && toCurrencyCode ? (
                cryptoAddressDropdown?.({
                    cryptoAccount: cryptoAccount as number,
                    setFieldValue,
                    handleChange,
                    currencyCode: toCurrencyCode,
                    errorMessage: errors['cryptoAccount'],
                })
            ) : (
                <CryptoAccountFields
                    cryptoAccount={cryptoAccount as string}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    arbitraryData={arbitraryData}
                    currencyCode={toCurrencyCode}
                    error={errors['cryptoAccount']}
                />
            );

        const guestBankAccountField = toCurrencyCode && (
            <BankAccountFields currencyCode={toCurrencyCode} formik={formik} />
        );

        const bankAccountItem =
            userAuth?.is_auth && toCurrencyCode
                ? bankAccountDropdown?.({
                      bankAccount: parseInt(bankAccount),
                      setFieldValue,
                      handleChange,
                      currencyCode: toCurrencyCode,
                      errorMessage: errors['bankAccount'],
                  })
                : guestBankAccountField;

        // The source and target amount fields must be filled.
        let isFormValid: boolean =
            !!values.fromAmount && values.fromAmount > 0 && !!values.toAmount && values.toAmount > 0;
        if (showCrypto) {
            isFormValid = isFormValid && !!values.cryptoAccount;
        } else if (showBank) {
            isFormValid = isFormValid && !!values.bankAccount;
        }
        // Submit button is always enabled for guest form
        const submitDisabled = !isGuest && !isFormValid;

        const currencySelectsErrors = [];

        // keys that backend might return (guesswork)
        const currencySelectsErrorKeys = [
            'from_currency',
            'to_currency',
            'fromCurrencyCode',
            'toCurrencyCode',
            'fromAmount',
            'toAmount',
            'to_amount',
            'from_amount',
        ];

        Object.keys(errors).map((errorKey) => {
            if (currencySelectsErrorKeys.includes(errorKey)) {
                // @ts-ignore
                currencySelectsErrors.push(errors[errorKey]);
            }
        });

        return (
            <ExchangeFormWrapper>
                <MainStack direction='column' alignItems='center' isGuest={isGuest}>
                    {isGuest && (
                        <HeaderItem>
                            <Typography variant='h1'>{t('order_form_header')}</Typography>
                        </HeaderItem>
                    )}
                    <Section isGuest={isGuest}>
                        <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-around' alignItems='center'>
                            <ExchangeFormCurrencySelects
                                onFromAmountChange={onFromAmountChange}
                                onFromCurrencyChange={onFromCurrencyChange}
                                onToAmountChange={onToAmountChange}
                                onToCurrencyChange={onToCurrencyChange}
                                onBlur={formik.handleBlur}
                                swapCurrencies={swapCurrencies}
                                fromAmount={fromAmount}
                                fromCurrencyCode={fromCurrencyCode}
                                toAmount={toAmount}
                                toCurrencyCode={toCurrencyCode}
                                errors={formik.errors}
                                direction={isMobile ? 'column' : 'row'}
                            />
                        </Stack>

                        {currencySelectsErrors.length > 0 &&
                            currencySelectsErrors.map((errorMessage, key) => (
                                <Box padding={1} key={key}>
                                    <Typography variant='body2' color='error' align='left'>
                                        {errorMessage}
                                    </Typography>
                                </Box>
                            ))}

                        {typeof rateOrPriceError === 'string' && (
                            <Box padding={1}>
                                <Typography variant='body2' color='error'>
                                    {rateOrPriceError}
                                </Typography>
                            </Box>
                        )}

                        {priceResponse?.over_limit && (
                            <Alert sx={{textAlign: 'start', my: 2}} severity='info'>
                                {isGuest
                                    ? t('guest_exchange_form_limit_alert', {
                                          limit: priceResponse?.from_limit_max,
                                          code: fromCurrencyCode,
                                      })
                                    : t('exchange_form_limit_alert', {
                                          limit: priceResponse?.from_limit_max,
                                          code: fromCurrencyCode,
                                      })}
                            </Alert>
                        )}

                        <Box paddingRight='10px' paddingLeft='10px' marginTop={2}>
                            <RateDisclaimer
                                rate={rateResponse}
                                price={priceResponse}
                                timer={timer}
                                errorFetchingRate={typeof rateOrPriceError === 'string'}
                            />
                        </Box>
                    </Section>

                    <Section isGuest={isGuest}>
                        {(showBank || showEmail || showEmail) && (
                            <InputsBox>
                                <Stack direction='column' spacing={{xs: 2, md: 2}}>
                                    {isGuest && (
                                        <FormTextField
                                            id='email'
                                            name='email'
                                            type='email'
                                            value={email}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            label={t('exchange_form_email_label')}
                                            autoComplete='email'
                                            errorMessage={errors['email']}
                                        />
                                    )}

                                    {showCrypto && cryptoAddressItem}
                                    {showBank && bankAccountItem}

                                    {isGuest && (
                                        <>
                                            <Stack
                                                direction='row'
                                                alignItems='flex-start'
                                                sx={{
                                                    background:
                                                        errors['termsAndCondition'] && isGuest ? '#FFEFE5' : '#fff',
                                                }}
                                            >
                                                <Checkbox
                                                    checked={termsAndCondition}
                                                    onChange={handleChange}
                                                    name='termsAndCondition'
                                                    color={errors['termsAndCondition'] ? 'error' : 'secondary'}
                                                    value={termsAndCondition}
                                                    sx={{
                                                        paddingLeft: 0,
                                                        paddingRight: '9px',
                                                        '&.MuiCheckbox-root': {
                                                            color: errors['termsAndCondition'] && '#FF4D26',
                                                        },
                                                    }}
                                                />

                                                <Typography align='left' variant='textS' color={theme.cool.grey70}>
                                                    {ready && (
                                                        <Trans
                                                            components={[
                                                                <Link href='https://simplecoin.eu/privacy' key={0} />,
                                                                <Link
                                                                    href='https://simplecoin.eu/terms-of-services'
                                                                    key={1}
                                                                />,
                                                            ]}
                                                            i18nKey='react_consent_to_tos'
                                                            values={{companyName: companyName}}
                                                        />
                                                    )}
                                                </Typography>
                                            </Stack>
                                            {errors['termsAndCondition'] && (
                                                <FormHelperText sx={{marginLeft: '14px'}} error>
                                                    {errors['termsAndCondition']}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}

                                    <LoadingButton
                                        loading={creatingInProgress}
                                        size='large'
                                        type='submit'
                                        variant='contained'
                                        color='secondary'
                                        disabled={submitDisabled}
                                        sx={{width: isGuest ? '100%' : 'fit-content'}}
                                    >
                                        {t('button_buy_now')}
                                    </LoadingButton>
                                </Stack>
                            </InputsBox>
                        )}
                    </Section>
                </MainStack>
            </ExchangeFormWrapper>
        );
    }

    return (
        <ExchangeContainer
            onSubmit={handleOrderSubmit}
            onSubmitSuccess={handleSubmitSuccess}
            showCaptcha={false}
            fromCurrencyCodePrefill={fromCurrencyCode ?? clientCurrency}
            toCurrencyCodePrefill={toCurrencyCode ?? 'BTC'}
            fromAmountPrefill={fromAmount}
            toAmountPrefill={toAmount}
            renderForm={renderExchangeForm}
            rootStyles={{display: 'flex', justifyContent: 'center'}}
            isGuest={isGuest}
        />
    );
}

export default renderWithSettings<ExchangeFormProps>(ExchangeForm);
