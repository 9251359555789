import React, {ReactElement} from 'react';
import {CustomIcon} from '../typings/IconsTypings';
import {Tooltip} from '@mui/material';
import InfoIcon from './icons/InfoIcon';

interface InfoIconTooltipProps extends CustomIcon {
    text: string | ReactElement;
    arrow?: boolean;
    iconProps?: any;
}
export const InfoIconTooltip = ({text, arrow = true}: InfoIconTooltipProps) => {
    return (
        <Tooltip
            title={text}
            arrow={arrow}
            componentsProps={{
                tooltip: {
                    sx: {
                        padding: '4px, 20px, 4px, 20px',
                        borderRadius: '4px',
                        width: 1,
                        minWidth: '220px',
                        textAlign: 'center',
                    },
                },
            }}
        >
            <span>
                <InfoIcon sx={{verticalAlign: 'middle', ml: 1, cursor: 'pointer'}} />
            </span>
        </Tooltip>
    );
};
