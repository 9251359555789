import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ListResponse, ReducerEnvelope} from '../typings/Api';
import {Bank, BankAccount} from '../typings/Order';
import {fetchGeneric, ThunkApi} from '../api';
import {handleReducerCases} from '../helpers/redux';

/**
 * Represents structure returned from bank/index endpoint.
 */
export interface BankAccountList extends ListResponse {
    items: BankAccount[];
}

export interface BankReducerInterface {
    list: ReducerEnvelope<BankAccountList>;
    view: ReducerEnvelope<Bank>;
    update: ReducerEnvelope<BankAccount>;
    create: ReducerEnvelope<BankAccount>;
    delete: ReducerEnvelope<null>;
}

const initialState: BankReducerInterface = {
    list: {isFetching: false},
    view: {isFetching: false},
    update: {isFetching: false},
    create: {isFetching: false},
    delete: {isFetching: false},
};

export interface Pagination {
    page?: number | null;
    perPage?: number;
}

export interface PaginatedProps {
    pagination: Pagination;
}

interface FetchBanksProps extends PaginatedProps {
    currencyId?: number;
}

const defaultPaginationProps: Pagination = {
    perPage: 12,
};

export const fetchBanks = createAsyncThunk<ReducerEnvelope<BankAccountList>, FetchBanksProps>(
    'bank/list',
    async ({pagination, currencyId}, thunkApi) => {
        if (!pagination) {
            pagination = defaultPaginationProps;
        }
        const query = {expand: 'currency'};

        if (currencyId) {
            query['filter'] = {currency_id: currencyId};
        }

        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'bank?docs_count=true',
                params: {...pagination, ...query},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const updateBank = createAsyncThunk<ReducerEnvelope<BankAccount>, {id: number; label: string}>(
    'bank/update',
    async ({id, label}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: 'bank/update',
                params: {id},
                data: {account_label: label},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const deleteBank = createAsyncThunk<ReducerEnvelope<null>, number>('bank/delete', async (id, thunkApi) => {
    const res = await fetchGeneric(
        {
            method: 'delete',
            url: `bank/delete?id=${id}`,
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

export const createBank = createAsyncThunk<ReducerEnvelope<BankAccount>, CreateBankProps>(
    'bank/create',
    async (props, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: 'bank/create',
                data: {...props},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchBank = createAsyncThunk<ReducerEnvelope<BankAccount>, number>('bank/view', async (id, thunkApi) => {
    const res = await fetchGeneric(
        {
            method: 'get',
            url: 'bank/view',
            params: {id, expand: 'currency'},
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

export interface CreateBankProps {
    currency_id: number;
    account_label: string;
    account_number: string;
    owner_name?: string;
    owner_address?: string;
    owner_country?: string;
    owner_city?: string;
    account_vs?: string;
    account_specific?: string;
    account_constant?: string;
    message?: string;
}

const slice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        invalidateBankDelete: (state) => {
            state.delete = initialState.delete;
        },
        invalidateBankUpdate: (state) => {
            state.update = initialState.update;
        },
        invalidateBankCreate: (state) => {
            state.create = initialState.create;
        },
    },
    extraReducers: (builder) => {
        handleReducerCases(fetchBank, 'view', builder);
        handleReducerCases(fetchBanks, 'list', builder);
        handleReducerCases(deleteBank, 'delete', builder);
        handleReducerCases(updateBank, 'update', builder);
        handleReducerCases(createBank, 'create', builder);
    },
});

export const {actions, reducer} = slice;
export const {invalidateBankDelete, invalidateBankUpdate, invalidateBankCreate} = slice.actions;
