import React, {forwardRef, useEffect, useImperativeHandle, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {batch, useSelector} from 'react-redux';
import {styled, TextField, InputAdornment, Alert, Stack, InputBaseProps} from '@mui/material';
import {useFormik} from 'formik';
import yup from '../../config/Yup';
import {Currency} from '../../typings/currency';
import {Order} from '../../typings/Order';
import {RootState, useCoreAppDispatch} from '../../redux/store';
import {manageResponse} from '../../helpers/api';
import {enqueueSnackbar} from '../../redux/snackbarSlice';
import {fetchLiveSuspended, fetchUpdateLimit, refetchOrder} from '../../redux/orderSlice';
import {normalizeAmount} from '../../helpers/price';

const StyledInputAdornment = styled(InputAdornment)`
    && {
        white-space: nowrap;
    }
`;

export interface SuspendedLimitFormProps {
    order: Order;
    fromCurrency: Currency | undefined;
    toCurrency: Currency | undefined;
    limitRate: number;
    limitRateInverse: number;
    fromAmount: number;
    token?: string;
    onSuccess: () => void;
}

const inputProps: InputBaseProps['inputProps'] = {step: 'any', inputMode: 'decimal'};

const formatInputNumber = (value: number, fixedDecimals: number) =>
    value.toLocaleString('fullwide', {
        useGrouping: false,
        maximumSignificantDigits: 6,
        maximumFractionDigits: fixedDecimals,
    });

/**
 * Component responsible for managing logic of limit form.
 */

const SuspendedLimitForm = forwardRef(
    (
        {
            order,
            fromCurrency,
            toCurrency,
            limitRate,
            limitRateInverse,
            fromAmount,
            token,
            onSuccess,
        }: SuspendedLimitFormProps,
        ref
    ) => {
        const {updateLimit} = useSelector((state: RootState) => state.order);
        const {t} = useTranslation();
        const dispatch = useCoreAppDispatch();
        const rateFixedDecimals = toCurrency?.fixed_decimals ?? 6;
        const rateInverseFixedDecimals = fromCurrency?.fixed_decimals ?? 6;
        const toAmountFixedDecimals = toCurrency?.fixed_decimals ?? 6;

        useImperativeHandle(ref, () => ({
            handleFormSubmit() {
                formik?.handleSubmit();
            },
        }));

        const formRules = useMemo(
            () =>
                yup.object().shape({
                    rate: yup.string().required(),
                    rateInverse: yup.string().required(),
                }),
            []
        );

        const toCurrencyAmount = getToAmount({rateInverse: limitRateInverse});

        const formInitialValues = useMemo(
            () => ({
                rate: formatInputNumber(limitRate, rateFixedDecimals),
                rateInverse: formatInputNumber(limitRateInverse, rateInverseFixedDecimals),
                toCurrencyAmount: formatInputNumber(toCurrencyAmount, toAmountFixedDecimals),
            }),
            [limitRate, limitRateInverse, toCurrencyAmount]
        );

        const formik = useFormik({
            initialValues: formInitialValues,
            validationSchema: formRules,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => {
                dispatch(
                    fetchUpdateLimit({
                        id: order.id,
                        token,
                        rate: parseFloat(normalizeAmount(values.rate)),
                        rateInverse: parseFloat(normalizeAmount(values.rateInverse)),
                    })
                );
            },
        });

        const {handleChange, setFieldValue, values, errors, touched} = formik;
        const toCurrencyName = toCurrency ? toCurrency.name : '';
        const fromCurrencyName = fromCurrency ? fromCurrency.name : '';

        useEffect(() => {
            manageResponse({
                reducer: updateLimit,
                formik,
                onSuccess: (response) => {
                    onSuccess?.();
                    batch(() => {
                        dispatch(enqueueSnackbar({message: response?.message, variant: 'success'}));
                        dispatch(fetchLiveSuspended({id: order.id, token}));
                        dispatch(refetchOrder({id: order.id, token}));
                    });
                },
            });
        }, [updateLimit]);

        /**
         * Calculate amount using provided rate and toAmount
         * @param rate
         * @param rateInverse
         * @return {number}
         */
        function getToAmount({rate, rateInverse}: {rate?: number; rateInverse?: number}): number {
            if (rate) {
                return fromAmount * rate;
            }
            if (rateInverse) {
                return fromAmount / rateInverse;
            }

            return 0;
        }

        const onRateChange = (e) => {
            handleChange(e);
            const normalizedStrAmount = normalizeAmount(e.target.value);
            const rateFloat = Number.parseFloat(normalizedStrAmount);
            const rateInverseFloat = 1 / rateFloat;
            setFieldValue('rateInverse', formatInputNumber(rateInverseFloat, rateInverseFixedDecimals));
            setFieldValue(
                'toCurrencyAmount',
                formatInputNumber(getToAmount({rateInverse: rateInverseFloat}), toAmountFixedDecimals)
            );
        };

        const onRateInverseChange = (e) => {
            handleChange(e);
            const normalizedStrAmount = normalizeAmount(e.target.value);
            const rateInverseFloat = Number.parseFloat(normalizedStrAmount);
            const rateFloat = 1 / rateInverseFloat;

            setFieldValue('rate', formatInputNumber(rateFloat, rateFixedDecimals));
            setFieldValue(
                'toCurrencyAmount',
                formatInputNumber(getToAmount({rateInverse: rateInverseFloat}), toAmountFixedDecimals)
            );
        };

        const onToAmountChange = (e) => {
            handleChange(e);
            const normalizedStrAmount = normalizeAmount(e.target.value);
            const toAmountFloat = Number.parseFloat(normalizedStrAmount);
            const rate = toAmountFloat / fromAmount;
            const rateInverse = 1 / rate;

            setFieldValue('rate', formatInputNumber(rate, rateFixedDecimals));
            setFieldValue('rateInverse', formatInputNumber(rateInverse, rateInverseFixedDecimals));
        };

        return (
            <Stack direction='column' spacing={4}>
                <Alert severity='info'>{t('suspended_widget_limit_form_paragraph')}</Alert>

                <form onSubmit={formik.handleSubmit} name='suspended-limit-form'>
                    <Stack direction='column' spacing={4}>
                        <TextField
                            variant='outlined'
                            onChange={onRateChange}
                            id='rate'
                            name='rate'
                            inputProps={inputProps}
                            type='text'
                            value={values.rate}
                            error={touched.rate && !!errors.rate}
                            helperText={touched.rate && errors.rate}
                            InputProps={{
                                startAdornment: (
                                    <StyledInputAdornment position='start'>1{fromCurrencyName} =</StyledInputAdornment>
                                ),
                                endAdornment: (
                                    <StyledInputAdornment position='end'>{toCurrencyName}</StyledInputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            variant='outlined'
                            onChange={onRateInverseChange}
                            id='rateInverse'
                            name='rateInverse'
                            type='text'
                            inputProps={inputProps}
                            value={values.rateInverse}
                            error={touched.rateInverse && !!errors.rateInverse}
                            helperText={touched.rateInverse && errors.rateInverse}
                            InputProps={{
                                startAdornment: (
                                    <StyledInputAdornment position='start'>1{toCurrencyName} =</StyledInputAdornment>
                                ),
                                endAdornment: (
                                    <StyledInputAdornment position='end'>{fromCurrencyName}</StyledInputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            variant='outlined'
                            onChange={onToAmountChange}
                            name='toCurrencyAmount'
                            id='toCurrencyAmount'
                            inputProps={inputProps}
                            type='text'
                            value={values.toCurrencyAmount}
                            error={touched.toCurrencyAmount && !!errors.toCurrencyAmount}
                            helperText={touched.toCurrencyAmount && errors.toCurrencyAmount}
                            InputProps={{
                                startAdornment: (
                                    <StyledInputAdornment position='start'>
                                        {t('to_currency_amount_field_label')}
                                    </StyledInputAdornment>
                                ),
                                endAdornment: (
                                    <StyledInputAdornment position='end'>{toCurrencyName}</StyledInputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </form>
            </Stack>
        );
    }
);

SuspendedLimitForm.displayName = 'SuspendedLimitForm';

export default SuspendedLimitForm;
