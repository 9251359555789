import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    fetchGeneric,
    handleReducerCases,
    OrderActions,
    ThunkApi,
    ListResponse,
    ReducerEnvelope,
} from '@simplecoin/core';
import PaginatedProps = OrderActions.PaginatedProps;
import {
    FinancialOperation,
    Exchange,
    BalancesListResponse,
    Account,
    WithdrawalRequest,
} from '~/src/typings/models/BalancesTypes';

export interface FinancialOperations extends ListResponse {
    items: FinancialOperation[];
}

export interface TransferInfo {
    initiatorId: number;
    sourceAccountId: number;
    targetAccountId: number;
    amount: number;
}

export interface ExchangePrice {
    uuid: string;
    fromAmount: number;
    toAmount: number;
    fromCurrencyCode: string;
    toCurrencyCode: string;
    rate: number;
    rateInverse: number;
    rateValue: number;
    rateValueInverse: number;
}

export interface ExchangePriceRequest {
    fromAmount: number | string | undefined;
    toAmount: number | string | undefined;
    fromCurrencyCode: string;
    toCurrencyCode: string;
}

export interface WithdrawalFee {
    id: string; // fee unique id
    currency: string; // currency code
    value: string;
}

export interface TradeReducerInterface {
    financialOperations: ReducerEnvelope<FinancialOperations>;
    transfer: ReducerEnvelope<any>;
    exchange: ReducerEnvelope<{financialOperationId: number}>;
    exchanges: ReducerEnvelope<Exchange>;
    withdraw: ReducerEnvelope<any>;
    transaction: ReducerEnvelope<FinancialOperation>;
    listWithdrawals: ReducerEnvelope<WithdrawalRequest>;
    exchangePrice: ReducerEnvelope<ExchangePrice>;
    withdrawalFee: ReducerEnvelope<WithdrawalFee>;
}

const initialState: TradeReducerInterface = {
    financialOperations: {isFetching: false},
    transfer: {isFetching: false},
    exchange: {isFetching: false},
    exchanges: {isFetching: false},
    withdraw: {isFetching: false},
    listWithdrawals: {isFetching: false},
    transaction: {isFetching: false},
    exchangePrice: {isFetching: false},
    withdrawalFee: {isFetching: false},
};

export interface FinancialOperationsListResponse<T> {
    items?: Array<T>;
}

interface TransactionPaginatedProps extends PaginatedProps {
    accountId?: number;
    financialOperationTypeFilter?: string;
}

interface WithdrawalPaginatedProps extends PaginatedProps {
    accountId?: number;
}

interface TransferProps {
    senderAccountId: number;
    recipientAccountId: number;
    amount: number;
}

interface MakeExchangeProps {
    exchangePriceUuid: string;
    fromAccountId: number;
    toAccountId: number;
}

interface WithdrawProps {
    accountId: number;
    amount: number;
    recipientId: number;
    feeId: string;
    overrides: Array<any>;
}

export const fetchFinancialOperations = createAsyncThunk<
    ReducerEnvelope<FinancialOperationsListResponse<any>>,
    TransactionPaginatedProps
>(
    'balances/operations/list',
    async ({pagination: {page = null, perPage = 10}, accountId, financialOperationTypeFilter}, thunkApi) => {
        const params: any = {page, perPage, accountId};
        if (financialOperationTypeFilter) {
            params.filter = {financialOperationType: financialOperationTypeFilter};
        }

        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/operations/list',
                params,
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const makeTransfer = createAsyncThunk<ReducerEnvelope<number>, TransferProps>(
    'balances/operations/transfer',
    async ({senderAccountId, recipientAccountId, amount}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: 'balances/operations/transfer',
                params: {
                    senderAccountId,
                    recipientAccountId,
                    amount,
                },
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const makeExchange = createAsyncThunk<ReducerEnvelope<number>, MakeExchangeProps>(
    'balances/exchanges/create',
    async (
        {exchangePriceUuid, fromAccountId, fromAmount, fromCurrency, toAccountId, toCurrency, toAmount},
        thunkApi
    ) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: 'balances/exchanges/create',
                data: {
                    '@type': 'api.v1.modules.balances.forms.ExchangeRequest',
                    fromAccountId,
                    fromAmount,
                    fromCurrency,
                    toAccountId,
                    toCurrency,
                    toAmount,
                    uuid: exchangePriceUuid,
                },
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchExchanges = createAsyncThunk<ReducerEnvelope<BalancesListResponse<Exchange>>, PaginatedProps>(
    'balances/exchanges/list',
    async ({pagination: {page = null, perPage = 10}}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/exchanges/list',
                params: {page, perPage},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const withdraw = createAsyncThunk<ReducerEnvelope<WithdrawProps>, WithdrawProps>(
    'balances/withdrawals/withdraw',
    async ({accountId, amount, recipientId, overrides, feeId}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: '/balances/withdrawals/withdraw',
                data: {
                    '@type': 'api.v1.modules.balances.forms.WithdrawRequest',
                    accountId: accountId,
                    amount: amount,
                    feeId: feeId,
                    recipientId: recipientId,
                    overrides: overrides,
                },
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchWithdrawalFee = createAsyncThunk<ReducerEnvelope<WithdrawProps>, {currencyCode: string}>(
    'balances/withdrawals/fee',
    async ({currencyCode}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: '/balances/withdrawals/fee-offer',
                params: {currencyCode},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const refetchWithdrawalFee = createAsyncThunk<ReducerEnvelope<WithdrawProps>, {currencyCode: string}>(
    'balances/withdrawals/refetch-fee',
    async ({currencyCode}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: '/balances/withdrawals/fee-offer',
                params: {currencyCode},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const fetchExchangePrice = createAsyncThunk<ReducerEnvelope<ExchangePrice>, ExchangePriceRequest>(
    'balances/exchanges/price',
    async ({fromAmount, toAmount, fromCurrencyCode, toCurrencyCode}, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'post',
                url: '/balances/exchanges/price',
                data: {
                    '@type': 'api.v1.modules.balances.forms.PriceRequest',
                    fromAmount,
                    toAmount,
                    fromCurrencyCode,
                    toCurrencyCode,
                },
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

export const refetchExchangePrice = createAsyncThunk<ReducerEnvelope<ExchangePrice>>(
    'balances/exchanges/price-refresh',
    async (_args, thunkApi) => {
        const {trade} = thunkApi.getState();
        const meta = trade.exchangePrice['requestMeta'];

        const res = await fetchGeneric(
            {
                method: 'post',
                url: '/balances/exchanges/price',
                data: {
                    '@type': 'api.v1.modules.balances.forms.PriceRequest',
                    ...meta,
                },
            },
            thunkApi as ThunkApi
        );

        // this allows to add values to meta object (not replace args, just add)
        return thunkApi.fulfillWithValue(res.data, meta);
    }
);

export const fetchWithdrawList = createAsyncThunk<
    ReducerEnvelope<BalancesListResponse<Account>>,
    WithdrawalPaginatedProps
>('balances/withdrawals/list', async ({pagination: {page = null, perPage = 10}, accountId}, thunkApi) => {
    const res = await fetchGeneric(
        {
            method: 'get',
            url: 'balances/withdrawals/list',
            params: {page, perPage, accountId},
        },
        thunkApi as ThunkApi
    );
    return res.data;
});

export const fetchSingleTransaction = createAsyncThunk<ReducerEnvelope<FinancialOperation>, number>(
    'balances/transaction/get',
    async (operationId, thunkApi) => {
        const res = await fetchGeneric(
            {
                method: 'get',
                url: 'balances/operations/get',
                params: {operationId},
            },
            thunkApi as ThunkApi
        );
        return res.data;
    }
);

const priceFulfilledHandler = (state, action) => {
    state.isFetching = false;
    state.exchangePrice = {...action.payload};
    state.exchangePrice.requestMeta = {...action.meta.arg};
};

const priceRefetchFulfilledHandler = (state, action) => {
    state.isFetching = false;
    state.exchangePrice = {...action.payload};
    // refetch returns meta differently, because it's not possible to manipulate args
    state.exchangePrice.requestMeta = {
        fromAmount: action.meta.fromAmount,
        toAmount: action.meta.toAmount,
        fromCurrencyCode: action.meta.fromCurrencyCode,
        toCurrencyCode: action.meta.toCurrencyCode,
    };
};

const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        invalidateMakeTransfer: (state) => {
            state.transfer = initialState.transfer;
        },
        invalidateMakeExchange: (state) => {
            state.exchange = initialState.exchange;
        },
        invalidateAccountWithdraw: (state) => {
            state.withdraw = initialState.withdraw;
        },
        invalidateExchangePrice: (state) => {
            state.exchangePrice = initialState.exchangePrice;
        },
        invalidatefetchFinancialOperations: (state) => {
            state.financialOperations = initialState.financialOperations;
        },
    },
    extraReducers: (builder) => {
        handleReducerCases(fetchFinancialOperations, 'financialOperations', builder, undefined, true);
        handleReducerCases(makeTransfer, 'transfer', builder);
        handleReducerCases(makeExchange, 'exchange', builder);
        handleReducerCases(fetchExchanges, 'exchanges', builder);
        handleReducerCases(withdraw, 'withdraw', builder);
        handleReducerCases(fetchWithdrawList, 'listWithdrawals', builder);
        handleReducerCases(fetchSingleTransaction, 'transaction', builder);
        handleReducerCases(fetchExchangePrice, 'exchangePrice', builder, priceFulfilledHandler);
        handleReducerCases(refetchExchangePrice, 'exchangePrice', builder, priceRefetchFulfilledHandler, true);
        handleReducerCases(refetchWithdrawalFee, 'withdrawalFee', builder, undefined, true);
        handleReducerCases(fetchWithdrawalFee, 'withdrawalFee', builder);
    },
});

export const {actions, reducer} = transactionSlice;
export const {
    invalidateMakeTransfer,
    invalidateMakeExchange,
    invalidateAccountWithdraw,
    invalidatefetchFinancialOperations,
    invalidateExchangePrice,
} = transactionSlice.actions;
