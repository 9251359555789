import {PaginatedProps} from '~/src/typings/Pagination';
import {fetchGeneric, fetchItems} from '~/src/helpers/action';

export const REQUEST_AFFILIATE_OVERVIEW = 'REQUEST_AFFILIATE_OVERVIEW';
export const REQUEST_AFFILIATE_OVERVIEW_SUCCESS = 'REQUEST_AFFILIATE_OVERVIEW_SUCCESS';
export const REQUEST_AFFILIATE_OVERVIEW_FAILURE = 'REQUEST_AFFILIATE_OVERVIEW_FAILURE';

export const REQUEST_JOIN_AFFILIATE = 'REQUEST_JOIN_AFFILIATE';
export const REQUEST_JOIN_AFFILIATE_SUCCESS = 'REQUEST_JOIN_AFFILIATE_SUCCESS';
export const REQUEST_JOIN_AFFILIATE_FAILURE = 'REQUEST_JOIN_AFFILIATE_FAILURE';
export const REQUEST_JOIN_AFFILIATE_INVALIDATE = 'REQUEST_JOIN_AFFILIATE_INVALIDATE';

export const REQUEST_AFFILIATE_STATISTICS = 'REQUEST_AFFILIATE_STATISTICS';
export const REQUEST_AFFILIATE_STATISTICS_SUCCESS = 'REQUEST_AFFILIATE_STATISTICS_SUCCESS';
export const REQUEST_AFFILIATE_STATISTICS_FAILURE = 'REQUEST_AFFILIATE_STATISTICS_FAILURE';

export const REQUEST_AFFILIATE_BALANCE = 'REQUEST_AFFILIATE_BALANCE';
export const REQUEST_AFFILIATE_BALANCE_FAILURE = 'REQUEST_AFFILIATE_BALANCE_FAILURE';
export const REQUEST_AFFILIATE_BALANCE_SUCCESS = 'REQUEST_AFFILIATE_BALANCE_SUCCESS';

export const REQUEST_AFFILIATE_WITHDRAW_HISTORY = 'REQUEST_AFFILIATE_WITHDRAW_HISTORY';
export const REQUEST_AFFILIATE_WITHDRAW_HISTORY_FAILURE = 'REQUEST_AFFILIATE_WITHDRAW_HISTORY_FAILURE';
export const REQUEST_AFFILIATE_WITHDRAW_HISTORY_SUCCESS = 'REQUEST_AFFILIATE_WITHDRAW_HISTORY_SUCCESS';

export const REQUEST_AFFILIATE_WITHDRAW = 'REQUEST_AFFILIATE_WITHDRAW';
export const REQUEST_AFFILIATE_WITHDRAW_FAILURE = 'REQUEST_AFFILIATE_WITHDRAW_FAILURE';
export const REQUEST_AFFILIATE_WITHDRAW_SUCCESS = 'REQUEST_AFFILIATE_WITHDRAW_SUCCESS';
export const REQUEST_AFFILIATE_WITHDRAW_INVALIDATE = 'REQUEST_AFFILIATE_WITHDRAW_INVALIDATE';

export const REQUEST_AFFILIATE_INCOME = 'REQUEST_AFFILIATE_INCOME';
export const REQUEST_AFFILIATE_INCOME_FAILURE = 'REQUEST_AFFILIATE_INCOME_FAILURE';
export const REQUEST_AFFILIATE_INCOME_SUCCESS = 'REQUEST_AFFILIATE_INCOME_SUCCESS';

export const REQUEST_RECORD_AFFILIATE = 'REQUEST_RECORD_AFFILIATE';
export const REQUEST_RECORD_AFFILIATE_SUCCESS = 'REQUEST_RECORD_AFFILIATE_SUCCESS';
export const REQUEST_RECORD_AFFILIATE_FAILURE = 'REQUEST_RECORD_AFFILIATE_FAILURE';

/**
 * Requests affiliate overview.
 */
export function fetchAffiliateOverview() {
    return fetchGeneric({
        url: 'affiliate/overview',
        actions: {
            pre: REQUEST_AFFILIATE_OVERVIEW,
            success: REQUEST_AFFILIATE_OVERVIEW_SUCCESS,
            failure: REQUEST_AFFILIATE_OVERVIEW_FAILURE,
        },
    });
}

/**
 * Handles join affiliate request
 */
export function fetchJoinAffiliate() {
    return fetchGeneric({
        url: 'affiliate/join',
        method: 'post',
        actions: {
            pre: REQUEST_JOIN_AFFILIATE,
            success: REQUEST_JOIN_AFFILIATE_SUCCESS,
            failure: REQUEST_JOIN_AFFILIATE_FAILURE,
            invalidate: REQUEST_JOIN_AFFILIATE_INVALIDATE,
        },
    });
}

/**
 * Handles fetching click statistics
 *
 * @param {number | null | undefined} page
 * @param {number | undefined} perPage
 * @param {number | undefined} offset
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchClickStatistics({pagination: {page = null, perPage = 10}}: PaginatedProps) {
    return fetchItems({
        url: 'affiliate/statistic',
        pagination: {page, perPage},
        actions: {
            pre: REQUEST_AFFILIATE_STATISTICS,
            success: REQUEST_AFFILIATE_STATISTICS_SUCCESS,
            failure: REQUEST_AFFILIATE_STATISTICS_FAILURE,
        },
    });
}

/**
 * Request available balance in given currency.
 *
 * @param {number} currencyId
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchBalanceInCurrency({currencyId}: {currencyId: number}) {
    return fetchGeneric({
        url: 'affiliate/balance',
        query: {currency_id: currencyId},
        actions: {
            pre: REQUEST_AFFILIATE_BALANCE,
            success: REQUEST_AFFILIATE_BALANCE_SUCCESS,
            failure: REQUEST_AFFILIATE_BALANCE_FAILURE,
        },
    });
}

/**
 * Withdraw available balance in given currency.
 *
 * @param {number | undefined} addressId
 * @param {number | undefined} bankAccountId
 * @return {(dispatch: any) => AxiosPromise}
 */
export function fetchWithdraw({addressId, bankAccountId}: {addressId?: number; bankAccountId?: number}) {
    const data = {client_address_id: addressId, client_bank_account_id: bankAccountId};

    return fetchGeneric({
        url: 'affiliate/withdraw',
        method: 'post',
        data,
        actions: {
            pre: REQUEST_AFFILIATE_WITHDRAW,
            success: REQUEST_AFFILIATE_WITHDRAW_SUCCESS,
            failure: REQUEST_AFFILIATE_WITHDRAW_FAILURE,
            invalidate: REQUEST_AFFILIATE_WITHDRAW_INVALIDATE,
        },
        afterSuccess: (dispatch) => {
            dispatch(fetchWithdrawHistory());
            dispatch(fetchAffiliateOverview());
        },
    });
}

/**
 * Withdraw available balance in given currency.
 *
 * @return {function(*): Promise<AxiosResponse<any>|never>}
 */
export function fetchWithdrawHistory({pagination: {page = null, perPage = 10}}: PaginatedProps) {
    return fetchItems({
        url: 'affiliate/withdraw-history?expand=clientAddress,clientBankAccount',
        pagination: {page, perPage},
        actions: {
            pre: REQUEST_AFFILIATE_WITHDRAW_HISTORY,
            success: REQUEST_AFFILIATE_WITHDRAW_HISTORY_SUCCESS,
            failure: REQUEST_AFFILIATE_WITHDRAW_HISTORY_FAILURE,
        },
    });
}

/**
 * Get orders contributing to user's affiliate income.
 */
export function fetchAffiliateIncomeHistory({pagination: {page = null, perPage = 10}}: PaginatedProps) {
    return fetchItems({
        url: 'affiliate/income-history',
        pagination: {page, perPage},
        actions: {
            pre: REQUEST_AFFILIATE_INCOME,
            success: REQUEST_AFFILIATE_INCOME_SUCCESS,
            failure: REQUEST_AFFILIATE_INCOME_SUCCESS,
        },
    });
}

/**
 * Makes request to affiliate endpont which created cookie on the client to mark used as invited one.
 *
 * @param {int} affiliateId Affiliate id.
 * @param {string} entryUrl URL where traffic was redirected.
 * @param {string|null} referrer Original referrer (from where traffic is coming from).
 * @return {function(*): Promise<AxiosResponse<any>|never>}
 */
export function fetchRecordAffiliate({affiliateId, entryUrl, referrer}) {
    return fetchGeneric({
        method: 'post',
        url: 'affiliate/remember',
        query: {id: affiliateId},
        data: {entry_url: entryUrl, referrer},
        actions: {
            pre: REQUEST_RECORD_AFFILIATE,
            success: REQUEST_RECORD_AFFILIATE_SUCCESS,
            failure: REQUEST_RECORD_AFFILIATE_FAILURE,
        },
        displaySnackbars: false,
    });
}
