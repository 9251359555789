import React from 'react';
import {Route, Routes as BaseRoutes} from 'react-router-dom';
import withAuth, {AuthProps} from './hoc/withAuth';
import RequestPasswordReset from './pages/site/RequestPasswordReset';
import ResetPassword from './pages/site/ResetPassword';
import AuthLayout from './components/Auth/AuthLayout';
import Error from './pages/site/Error';
import EmailConfirmation from './pages/site/EmailConfirmation';
import ResendEmail from './pages/site/ResendEmail';
import Confirm2Fa from './pages/site/Confirm2Fa';
import ErrorBoundary from '~/src/components/ErrorBoundary';
import LoadableComponent from '~/src/components/LoadableComponent';
import LoginConfirmation from '~/src/pages/site/LoginConfirmation';
import {LANGUAGE_URL_MATCH} from '~/src/config/localParams';
import withVerificationAndConsent from './hoc/withVerificationAndConsent';

export interface RouteItemProps {
    path: string;
    element: React.ElementType;
}

export interface RenderRouteProps {
    route: RouteItemProps;
    key: number;
}

export interface LoadableComponentProps {
    enforceAuth?: boolean;
    checkVerificationAndConsent?: boolean;
    hocProps?: AuthProps;
}

export const ROUTES: RouteItemProps[] = [
    {
        path: '/',
        element: loadableComponent(import('./pages/site/Login'), {enforceAuth: false}),
    },
    {
        path: '/exchange',
        element: loadableComponent(import('./pages/exchange/ExchangeAuthContainer'), {enforceAuth: true}),
    },
    {
        path: '/dashboard',
        element: loadableComponent(import('./pages/dashboard/Dashboard'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/trade',
        element: loadableComponent(import('./components/Trade/TradeForm'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/accounts',
        element: loadableComponent(import('./pages/accounts/AccountContainer'), {enforceAuth: true}),
    },
    {
        path: '/transactions',
        element: loadableComponent(import('./pages/transactions/Transactions'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/transactions/:id',
        element: loadableComponent(import('./pages/transactions/TransactionDetail'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/deposit-requests/:id',
        element: loadableComponent(import('./pages/transactions/DepositRequestDetail'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/withdrawal-requests/:id',
        element: loadableComponent(import('./pages/transactions/WithdrawalRequestDetail'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/accounts/:accountId',
        element: loadableComponent(import('./pages/accounts/AccountDetails'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/accounts/create',
        element: loadableComponent(import('./pages/accounts/AccountCreate'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/recipients',
        element: loadableComponent(import('./pages/recipient/RecipientsContainer'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/recipients/create',
        element: loadableComponent(import('./pages/recipient/RecipientCreate'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/recipients/wallets/:id',
        element: loadableComponent(import('./pages/wallet/WalletUpdate'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/recipients/bank-accounts/:id',
        element: loadableComponent(import('./pages/bank/BankUpdate'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/orders',
        element: loadableComponent(import('./pages/order/OrderContainer'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/orders/:id',
        element: loadableComponent(import('./pages/order/OrderView'), {enforceAuth: true}),
    },
    {
        path: '/login',
        element: loadableComponent(import('./pages/site/Login'), {
            hocProps: {redirectToExchange: true, displaySnackbars: false},
        }),
    },
    {
        path: '/confirm-login/:token',
        element: LoginConfirmation,
    },
    {
        path: '/request-password-reset',
        element: RequestPasswordReset,
    },
    {
        path: '/reset-password/:token',
        element: ResetPassword,
    },
    {
        path: '/confirm-2fa/:token',
        element: Confirm2Fa,
    },
    {
        path: '/logout',
        element: loadableComponent(import('./pages/site/Logout'), {
            enforceAuth: false,
            hocProps: {redirectToExchange: false},
        }),
    },
    {
        path: '/signup',
        element: loadableComponent(import('./pages/site/Signup'), {
            enforceAuth: true,
            hocProps: {
                redirectToExchange: true,
                redirectToLogin: false,
                displaySnackbars: false,
                allowRenderUnauthorized: true,
            },
        }),
    },
    {
        path: '/settings',
        element: loadableComponent(import('./pages/settings/Settings'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/settings/general',
        element: loadableComponent(import('./pages/settings/GeneralSettings'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/settings/email',
        element: loadableComponent(import('./pages/settings/EmailSettings'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/settings/security',
        element: loadableComponent(import('./pages/settings/security/SecuritySettings'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/settings/security/change-password',
        element: loadableComponent(import('./pages/settings/security/ChangePassword'), {enforceAuth: true}),
    },
    {
        path: '/settings/security/enable-2fa',
        element: loadableComponent(import('./pages/settings/security/Enable2Fa'), {enforceAuth: true}),
    },
    {
        path: '/settings/security/disable-2fa',
        element: loadableComponent(import('./pages/settings/security/Disable2Fa'), {enforceAuth: true}),
    },
    {
        path: '/profile',
        element: loadableComponent(import('./pages/profile/Profile'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/limits',
        element: loadableComponent(import('./pages/profile/LimitsPage'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/income',
        element: loadableComponent(import('./pages/profile/Income'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/income/view/:id',
        element: loadableComponent(import('./pages/profile/IncomeView'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/income/update/:uuid',
        element: loadableComponent(import('./pages/profile/Income'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/verification',
        element: loadableComponent(import('./pages/profile/VerificationAndLimits'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/increase-limit',
        element: loadableComponent(import('./pages/profile/IncreaseLimitPage'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/claim-order/:token',
        element: loadableComponent(import('./pages/order/ClaimOrder'), {
            enforceAuth: true,
            hocProps: {redirectToLogin: false, displaySnackbars: false},
        }),
    },
    {
        path: '/email-confirmation/:token',
        element: EmailConfirmation,
    },
    {
        path: '/ticket',
        element: loadableComponent(import('./pages/ticket/TicketContainer'), {enforceAuth: true}),
    },
    {
        path: '/ticket/create',
        element: loadableComponent(import('./pages/ticket/TicketCreate'), {enforceAuth: true}),
    },
    {
        path: '/ticket/create/:orderId',
        element: loadableComponent(import('./pages/ticket/TicketCreate'), {enforceAuth: true}),
    },
    {
        path: '/ticket/reply/:id',
        element: loadableComponent(import('./pages/ticket/TicketDiscussion'), {enforceAuth: true}),
    },
    {
        path: '/resend-email/:email',
        element: ResendEmail,
    },
    {
        path: '/error/:message',
        element: Error,
    },
    {
        path: '/affiliate',
        element: loadableComponent(import('./pages/affiliate/Affiliate'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/welcome',
        element: loadableComponent(import('./pages/welcome/Welcome'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/affiliate/withdraw',
        element: loadableComponent(import('./pages/affiliate/AffiliateWithdraw'), {
            enforceAuth: true,
        }),
    },
    {
        path: '/portfolio',
        element: loadableComponent(import('./pages/portfolio/PortfolioContainer'), {
            enforceAuth: true,
            checkVerificationAndConsent: true,
        }),
    },
    {
        path: '/signup-success',
        element: loadableComponent(import('./pages/site/RegistrationSuccess'), {
            enforceAuth: false,
            checkVerificationAndConsent: false,
        }),
    },
];

/**
 * Makes passed component loadable with ability to pass down props and whether or not wrap it with auth HOC.
 *
 * @param componentImport
 * @param checkAuth
 * @param hocProps
 * @return {*}
 */
function loadableComponent(
    componentImport: any,
    {enforceAuth = false, checkVerificationAndConsent = false, hocProps = {}}: LoadableComponentProps = {}
) {
    let loadable = LoadableComponent({
        loader: () => componentImport,
    });

    if (checkVerificationAndConsent && process.env.BUILD_TYPE === 'wallet') {
        loadable = withVerificationAndConsent(loadable);
    }

    if (enforceAuth) {
        loadable = withAuth(loadable, hocProps);
    }

    return loadable;
}

/**
 * Renders available ROUTES, determines layout to be used based on current path and route that matches it.
 *
 * @returns {*}
 * @constructor
 */
function Routes() {
    /**
     * Render single route.
     *
     * @param route
     * @param key
     * @return {*}
     */
    function renderRoute({route, key}: RenderRouteProps): React.ReactElement {
        const Component = route.element;

        const renderElement = (
            <ErrorBoundary>
                <Component />
            </ErrorBoundary>
        );

        const path = LANGUAGE_URL_MATCH + route.path;

        return <Route key={key} path={path} element={renderElement} />;
    }

    /**
     * Render ROUTES conditionally for guest and authorized clients.
     *
     * @param route
     * @param key
     * @return {*}
     */
    function renderRoutes(route, key) {
        const allRoutes: React.ReactElement[] = [];

        allRoutes.push(renderRoute({route, key}));

        return allRoutes;
    }

    return (
        <AuthLayout>
            <BaseRoutes>
                {ROUTES.map(renderRoutes)}
                <Route path='*' element={<Error code={404} />} />
            </BaseRoutes>
        </AuthLayout>
    );
}

Routes.displayName = 'Routes';

// make sure auth is called on every route
export default withAuth(Routes, {
    redirectToExchange: false,
    redirectToLogin: false,
    displaySnackbars: false,
    switchLanguage: false,
    allowRenderUnauthorized: true,
});
