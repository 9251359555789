import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Page} from '~/src/components';
import {RootState} from '~/src/redux/store';
import ProfileChecklistCard from '../profile/levelOne/ProfileChecklistCard';
import PolicyConsent from './PolicyConsent';
import StartWithFunding from './StartWithFunding';
import VerificationRequest from './VerificationRequest';

export default function Welcome() {
    const {t} = useTranslation();
    const profileData = useSelector((state: RootState) => state.profile?.data?.response);

    let content: JSX.Element;
    // No Wallet policy consent, but passed verification => The consent is mandatory
    if (!profileData?.wallet_policy_consent && (profileData?.ready_for_verification || profileData?.verified)) {
        content = <PolicyConsent />;
    }
    // No Wallet policy consent and not passed the verification process yet => The verification is mandatory
    else if (!profileData?.wallet_policy_consent || (!profileData?.ready_for_verification && !profileData?.verified)) {
        content = <VerificationRequest />;
    }
    // Waiting for verification => Keep waiting
    else if (!profileData?.verified && profileData?.ready_for_verification) {
        content = <ProfileChecklistCard />;
    }
    // Verified but without any funds => Fund your wallet
    else if (profileData?.verified && profileData.checks?.has_any_account === false) {
        content = <StartWithFunding />;
    }
    // This should never happen
    else {
        content = <PolicyConsent />;
    }

    return <Page helmetTitle={t('welcome_in_wallet')}>{content}</Page>;
}
