import React from 'react';
import {styled, lighten, useTheme} from '@mui/material/styles';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import {LinearProgressProps} from '@mui/material/LinearProgress/LinearProgress';
import {WidthBreakpoint} from '~/src/config/localParams';

const LinearBar = styled(LinearProgress, {
    shouldForwardProp: (prop) => prop !== 'barColor',
})<StyleProps>`
    border-radius: 6px;
    background-color: ${(props) => lighten(props.barColor, 0.4)};
    height: ${(props) => props.height}px;
    max-width: ${(props) => props.maxWidth}px;
    .${linearProgressClasses.bar} {
        background-color: ${(props) => props.barColor};
    }
`;

const StyledTypography = styled(Typography)<{textcolor: string}>`
    text-align: center;
    color: ${(props) => props.textcolor};
    @media (max-width: ${WidthBreakpoint.md}) {
        font-size: 0.8rem;
    }
`;

interface StyleProps {
    height: number | string;
    barColor: string;
    maxWidth?: number;
}

export interface ProgressBarProps extends LinearProgressProps {
    label?: string;
    labelProps?: any;
    value: number;
    height?: number;
    maxWidth?: string | number | undefined;
    primaryColor?: string;
    secondaryColor?: string;
    threshold?: number;
    rest?: any;
}

/**
 * Renders linear progress bar with optional label and adjustable height and color
 * @param {string} label text to show before progress bar
 * @param {object} labelProps
 * @param {number} value of progress bar in range 0-100
 * @param {number} height in pixels
 * @param maxWidth
 * @param {string} primaryColor - color the bar will be if value is within 0-99
 * @param {string} secondaryColor - color the bar will be if value is 100
 * @param {number} threshold - value over which color will be changed to secondary color
 * @param rest
 */
export default function ProgressBar({
    label = '',
    labelProps = {},
    value,
    height = 10,
    maxWidth = undefined,
    primaryColor = '#0bc013',
    secondaryColor = '#ff0000',
    threshold = 100,
    ...rest
}: ProgressBarProps) {
    value = value > 100 ? 100 : value;
    const color = value < threshold ? primaryColor : secondaryColor;
    const theme = useTheme();

    return (
        <div>
            <StyledTypography {...labelProps} textcolor={theme.palette.secondary.contrastText}>
                {label}
            </StyledTypography>
            <LinearBar
                color='secondary'
                barColor={color}
                height={height}
                maxWidth={maxWidth}
                variant='determinate'
                value={value}
                {...rest}
            />
        </div>
    );
}

ProgressBar.displayName = 'ProgressBar';
