import React, {Ref, useEffect, useMemo} from 'react';
import {FormikProps, FormikValues} from 'formik';
import {TextField, FormControl, FormHelperText, styled, MenuItem} from '@mui/material';
import ReCaptcha from './ReCaptcha';
import CustomSelect from './CustomSelect';

const CaptchaWrapper = styled('div')`
    margin-top: 15px;
`;

export interface FieldProps {
    formik: FormikProps<FormikValues>;
    item: FieldItemProps;
    fieldRef: Ref<any>;
}

export type FieldType =
    | 'select'
    | 'dropdown'
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'textarea'
    | 'captcha'
    | 'custom';

export interface FieldItemProps {
    component?: (formik: FormikProps<FormikValues>) => React.ReactElement;
    type?: FieldType;
    name: string;
    value?: any;
    visible?: boolean;
    label?: string;
    options?: Array<any>;
    hint?: string | null;
    error?: string | null;
    onChange?: (e: any) => void;
    autoComplete?: string;
    block?: boolean;
    fieldRef?: any;
    renderOnChangeOf?: string;
    inputMode?: 'none' | 'search' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal';
    pattern?: string;
    required?: boolean;
}

const renderOption = (item, index, isMobile) => {
    if (isMobile) {
        return (
            <option key={index} value={item.value}>
                {item.name}
            </option>
        );
    }
    return (
        <MenuItem key={index} value={item.value}>
            {item.name}
        </MenuItem>
    );
};

/**
 * Renders single form field.
 *
 * @param formik list of props passed from <Formik> component (returned bu useFormik hook).
 * @param item field to be rendered
 * @param fieldRef optional ref to be assigned (currently only used for google captcha)
 */
export default function Field({formik, item, fieldRef}: FieldProps) {
    const {name, type, required, autoComplete, options, component, renderOnChangeOf, inputMode, pattern} = item;

    let {label} = item;

    useEffect(() => {
        const hasLabel = typeof name !== 'undefined' && label;
        if (!hasLabel) {
            // For example, convert "first_name" to "First name"
            let newLabel = name.charAt(0).toUpperCase() + name.slice(1);
            newLabel = newLabel.replace(/[_-]/gi, ' ');
            newLabel = newLabel.replace(/\s{2,}gi/, ' ');

            label = newLabel;
        }
    }, []);

    const error = formik.errors[name];
    const value = formik.values[name] || '';
    const touched = formik.touched[name];
    const watchValue = renderOnChangeOf ? formik.values[renderOnChangeOf] : '';

    /**
     * Handles field change.
     */
    function handleOnChange(e) {
        if (item.onChange) {
            item.onChange(e);
        }
        if (formik.handleChange) {
            formik.handleChange(e);
        }
    }

    /**
     * Recaptcha change handler
     */
    function handleRecaptchaChange(value) {
        formik.setFieldValue(name, value);
    }

    /**
     * Field based on provided type and other props.
     */
    const field = useMemo(() => {
        switch (type) {
            case 'text':
            case 'email':
            case 'number':
            case 'password':
                return (
                    <TextField
                        {...item}
                        name={name}
                        value={value}
                        required={
                            false /* Required fields are handled by yup scheme. If this proiperty is set true the yup does not validate properly. */
                        }
                        onChange={handleOnChange}
                        onBlur={formik.handleBlur}
                        error={touched && !!error}
                        label={required ? label + '*' : label}
                        type={type}
                        autoComplete={autoComplete}
                        variant='outlined'
                        // @ts-ignore
                        inputProps={{pattern, inputMode}}
                    />
                );
            case 'textarea':
                return (
                    <TextField
                        {...item}
                        name={name}
                        multiline
                        rows='6'
                        value={value}
                        onChange={handleOnChange}
                        onBlur={formik.handleBlur}
                        error={touched && !!error}
                        required={
                            false /* Required fields are handled by yup scheme. If this proiperty is set true the yup does not validate properly. */
                        }
                        label={required ? label + '*' : label}
                        type={type}
                        margin='normal'
                        variant='outlined'
                    />
                );
            case 'select':
            case 'dropdown':
                return (
                    <CustomSelect
                        {...item}
                        error={touched && !!error}
                        value={value}
                        name={name}
                        required={
                            false /* Required fields are handled by yup scheme. If this proiperty is set true the yup does not validate properly. */
                        }
                        label={required ? label + '*' : label}
                        id={name}
                        onChange={handleOnChange}
                        onBlur={formik.handleBlur}
                        renderOption={renderOption}
                        options={options}
                    />
                );
            case 'captcha':
                return (
                    <CaptchaWrapper>
                        <ReCaptcha ref={fieldRef} onChange={handleRecaptchaChange} />
                    </CaptchaWrapper>
                );
            case 'custom':
                if (typeof component === 'function') {
                    return component(formik);
                }
                return null;
            default:
                // eslint-disable-next-line no-console
                console.warn(`Unrecognized field type ${type}`);
                return null;
        }
    }, [item, error, touched, value, fieldRef, watchValue]);

    return (
        <>
            <FormControl error={touched && !!error} fullWidth>
                {field}
                {/*@ts-ignore*/}
                {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
            </FormControl>

            {item.hint ? <FormHelperText>{item.hint}</FormHelperText> : null}
        </>
    );
}

Field.displayName = 'Field';
