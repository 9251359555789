import {createTheme, IconButton, MenuItem, Select, ThemeProvider, useTheme} from '@mui/material';
import {simplecoinExchangeAppTheme, useBackendSettings} from '@simplecoin/core';
import React, {useEffect, useMemo, useState} from 'react';
import {Theme} from '@mui/material/styles/createTheme';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {Loader} from './components';
import {chainblockTheme} from './ChainblockTheme';
import {devizovaBurzaTheme} from './DevizovaBurzaTheme';

declare module '@mui/material/styles' {
    interface Theme {
        logo: {
            name: string;
            width: number;
            height: number;
        };
    }
}

const localExchangeTheme = createTheme(simplecoinExchangeAppTheme, {
    components: {},
    logo: {
        name: 'simplecoin',
        width: 183,
        height: 25,
    },
});

const divexTheme = createTheme(localExchangeTheme, {
    palette: {
        primary: {
            light: '#FFF7E6',
            main: '#009D9E',
            dark: '#FFB874',
            contrastText: '#fff',
        },
        secondary: {
            light: '#56719c',
            main: '#000000',
            dark: '#ba000d',
            contrastText: '#fff',
        },
        text: {
            primary: '#010635',
            secondary: '#1478ef',
            disabled: '#898E9E',
        },
    },
});

type ThemeVariant = 'simplecoin' | 'divex' | 'chainblock' | 'devizovaburza';

const ThemeSwitchContext = React.createContext<{
    toggleColorMode?: () => void;
    setThemeVariant?: (value: ThemeVariant) => void;
}>({});

export const ThemeSettingProvider = ({children}) => {
    const settingsReducer = useSelector((state: RootState) => state.site.settings);
    const brand: string = useBackendSettings('brand_name', 'Simplecoin');
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const [baseTheme, setBaseTheme] = React.useState<Theme>(simplecoinExchangeAppTheme);
    const brandCode = brand?.toLowerCase()?.normalize('NFKD').replace(/[^\w]/g, '');

    // @ts-ignore brand should match themevariant
    const [themeVariant, setThemeVariant] = React.useState<ThemeVariant>(brandCode ?? 'simplecoin');

    useEffect(() => {
        // @ts-ignore brand should match themevariant
        setThemeVariant(brandCode ?? 'simplecoin');
    }, [brandCode]);

    useEffect(() => {
        switch (themeVariant.toLowerCase()) {
            case 'simplecoin': {
                setBaseTheme(localExchangeTheme);
                break;
            }
            case 'chainblock': {
                setBaseTheme(chainblockTheme);
                break;
            }
            case 'divex': {
                setBaseTheme(divexTheme);
                break;
            }
            case 'devizovaburza': {
                setBaseTheme(devizovaBurzaTheme);
                break;
            }
        }
    }, [themeVariant]);

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
            setThemeVariant: (value) => {
                setThemeVariant(value);
            },
        }),
        []
    );

    const currentTheme = useMemo(
        () =>
            createTheme(baseTheme, {
                palette: {
                    mode,
                },
            }),
        [baseTheme, mode, settingsReducer]
    );

    if (settingsReducer.settingsData && !settingsReducer.isFetching) {
        return (
            <ThemeSwitchContext.Provider value={colorMode}>
                <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
            </ThemeSwitchContext.Provider>
        );
    } else {
        return <Loader />;
    }
};

export const ThemeSelect = () => {
    const brand: string = useBackendSettings('brand_name', 'Simplecoin');
    const brandCode = brand?.toLowerCase()?.normalize('NFKD').replace(/[^\w]/g, '');
    // @ts-ignore brand should match themevariant
    const [value, setValue] = useState<ThemeVariant>(brandCode ?? 'simplecoin');
    const {setThemeVariant, toggleColorMode} = React.useContext(ThemeSwitchContext);
    const theme = useTheme();

    useEffect(() => {
        setThemeVariant?.(value);
    }, [value]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <>
            <Select label='Select theme' size='small' onChange={handleChange} value={value}>
                <MenuItem value='simplecoin'>Simplecoin</MenuItem>
                <MenuItem value='chainblock'>Chainblock</MenuItem>
                <MenuItem value='divex'>Divex</MenuItem>
                <MenuItem value='devizovaburza'>Devizová burza</MenuItem>
            </Select>

            <IconButton sx={{ml: 1}} onClick={toggleColorMode} color='inherit'>
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
        </>
    );
};
