import {
    REQUEST_TICKET_LIST,
    REQUEST_TICKET_LIST_SUCCESS,
    REQUEST_TICKET_LIST_FAILURE,
    CREATE_TICKET,
    CREATE_TICKET_SUCCESS,
    CREATE_TICKET_FAILURE,
    CREATE_TICKET_INVALIDATE,
    REQUEST_DISCUSSION_TICKET,
    REQUEST_DISCUSSION_TICKET_SUCCESS,
    REQUEST_DISCUSSION_TICKET_FAILURE,
    REQUEST_DISCUSSION_TICKET_LOAD_MORE_SUCCESS,
    REQUEST_DISCUSSION_TICKET_LOAD_MORE_FAILURE,
    CLOSE_TICKET,
    CLOSE_TICKET_SUCCESS,
    CLOSE_TICKET_FAILURE,
    CLOSE_TICKET_INVALIDATE,
    REQUEST_TICKET_INFO,
    REQUEST_TICKET_INFO_SUCCESS,
    REQUEST_TICKET_INFO_FAILURE,
    CREATE_TICKET_REPLY,
    CREATE_TICKET_REPLY_SUCCESS,
    CREATE_TICKET_REPLY_FAILURE,
    CREATE_TICKET_REPLY_INVALIDATE,
    CREATE_TICKET_LAST_REPLY_SUCCESS,
    CREATE_TICKET_LAST_REPLY_FAILURE,
    REQUEST_TICKET_ORDER,
    REQUEST_TICKET_ORDER_SUCCESS,
    REQUEST_TICKET_ORDER_FAILURE,
    REQUEST_TICKET_ORDER_INVALIDATE,
    REQUEST_DISCUSSION_TICKET_UPDATE,
    REQUEST_TICKET_INFO_UPDATE,
} from './TicketActions';
import {Action} from '~/src/typings';
import {Tickets} from '~/src/typings/endpoints/ticket/Tickets';
import {OrderBasicInfo, Ticket} from '~/src/typings/models/Ticket';
import {TicketReplies} from '~/src/typings/endpoints/ticket/TicketReplies';
import {TicketReply} from '~/src/typings/models/TicketReply';
import {ReducerEnvelope} from '@simplecoin/core';

export interface TicketReducersInterface {
    tickets?: ReducerEnvelope<Tickets>;
    create?: ReducerEnvelope<Ticket>;
    discussion: ReducerEnvelope<TicketReplies>;
    close?: ReducerEnvelope<null>;
    info?: ReducerEnvelope<Ticket>;
    reply?: ReducerEnvelope<TicketReply>;
    order?: ReducerEnvelope<OrderBasicInfo>;
}

/**
 * @param state
 * @param action
 * @return {object}
 */
export default function (
    state: TicketReducersInterface = {discussion: {}},
    action: Action = {}
): TicketReducersInterface {
    switch (action.type) {
        // Tickets
        case REQUEST_TICKET_LIST:
            return {...state, tickets: {isFetching: true}};
        case REQUEST_TICKET_LIST_SUCCESS:
            return {...state, tickets: {isFetching: false, ...action.payload}};
        case REQUEST_TICKET_LIST_FAILURE:
            return {...state, tickets: {isFetching: false, ...action.payload}};

        // Create new ticket
        case CREATE_TICKET:
            return {...state, create: {isFetching: true}};
        case CREATE_TICKET_SUCCESS:
            return {...state, create: {isFetching: false, ...action.payload}};
        case CREATE_TICKET_FAILURE:
            return {...state, create: {isFetching: false, ...action.payload}};
        case CREATE_TICKET_INVALIDATE:
            return {...state, create: undefined};

        // Discussion view
        case REQUEST_DISCUSSION_TICKET:
            return {...state, discussion: {isFetching: true}};
        case REQUEST_DISCUSSION_TICKET_UPDATE: // "Silent" update/refresh of already fetched ticket discussion without setting isFetchng
            return {...state, discussion: {...state.discussion, isFetching: false}};
        case REQUEST_DISCUSSION_TICKET_SUCCESS:
            action.payload.response.items.reverse();
            return {...state, discussion: {isFetching: false, ...action.payload}};
        case REQUEST_DISCUSSION_TICKET_FAILURE:
            return {...state, discussion: {isFetching: false, ...action.payload}};

        // Load more on Discussion view
        case REQUEST_DISCUSSION_TICKET_LOAD_MORE_SUCCESS:
            return {
                ...state,
                discussion: {
                    isFetching: false,
                    status: 'ok',
                    response: {
                        ...state.discussion.response,
                        items: [
                            ...action.payload.response.items.reverse(),
                            ...(state.discussion.response?.items ?? []), // items can be undefined, using nullish coalescing
                        ],
                        _meta: action.payload.response._meta,
                        _links: action.payload.response._links,
                    },
                },
            };

        case REQUEST_DISCUSSION_TICKET_LOAD_MORE_FAILURE:
            return {...state, discussion: {isFetching: false, ...action.payload}};

        // Last discussion replies
        case CREATE_TICKET_LAST_REPLY_SUCCESS:
            action.payload.response.items.reverse();
            return {...state, discussion: {isFetching: false, ...action.payload}};

        case CREATE_TICKET_LAST_REPLY_FAILURE:
            return {...state, discussion: {isFetching: false, ...action.payload}};

        // Close ticket
        case CLOSE_TICKET:
            return {...state, close: {isFetching: true}};
        case CLOSE_TICKET_SUCCESS:
            return {...state, close: {isFetching: false, ...action.payload}};
        case CLOSE_TICKET_FAILURE:
            return {...state, close: {isFetching: false, ...action.payload}};
        case CLOSE_TICKET_INVALIDATE:
            return {...state, close: undefined};

        // Ticket info
        case REQUEST_TICKET_INFO:
            return {...state, info: {isFetching: true}};
        case REQUEST_TICKET_INFO_UPDATE: // "Silent" update/refresh of already fetched ticket info without setting isFetchng
            return {...state};
        case REQUEST_TICKET_INFO_SUCCESS:
            return {...state, info: {isFetching: false, ...action.payload}};
        case REQUEST_TICKET_INFO_FAILURE:
            return {...state, info: {isFetching: false, ...action.payload}};

        // Create ticket reply
        case CREATE_TICKET_REPLY:
            return {...state, reply: {isFetching: true}};
        case CREATE_TICKET_REPLY_SUCCESS:
            return {
                ...state,
                discussion: {
                    isFetching: false,
                    response: {
                        ...state.discussion.response,
                        items: [...(state.discussion.response?.items ?? []), action.payload.response],
                    },
                },
                reply: {
                    isFetching: false,
                    ...action.payload,
                },
            };

        case CREATE_TICKET_REPLY_FAILURE:
            return {...state, discussion: {isFetching: false, ...action.payload}};
        case CREATE_TICKET_REPLY_INVALIDATE:
            return {...state, reply: undefined};

        // Ticket from single order view
        case REQUEST_TICKET_ORDER:
            return {...state, order: {isFetching: true}};
        case REQUEST_TICKET_ORDER_SUCCESS:
            return {...state, order: {isFetching: false, ...action.payload}};
        case REQUEST_TICKET_ORDER_FAILURE:
            return {...state, order: {isFetching: false, ...action.payload}};
        case REQUEST_TICKET_ORDER_INVALIDATE:
            return {...state, order: undefined};

        default:
            return state;
    }
}
