import React from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import HouseError from '../../components/HouseError';
import {useParams} from 'react-router-dom';
import {normalizeQueryValue} from '@simplecoin/core';

export interface ErrorProps {
    message?: string | React.ReactElement;
    code?: number;
    match?: {
        params?: {
            message: string;
        };
    };
}

/**
 * Renders error page.
 * @param message
 * @param code
 * @param match
 * @return {*}
 * @constructor
 */
export default function Error({message, code = 404}: ErrorProps) {
    const {t} = useTranslation();
    const {message: urlMessage} = useParams();

    if (urlMessage) {
        message = normalizeQueryValue(urlMessage);
    }

    if (!message) {
        switch (code) {
            case 404:
                message = t('error_page_not_found');
                break;
            default:
                return null;
        }
    }

    const completeMessage = `${t('error')} ${code}: ${message}`;

    const title = `${message} (#${code})`;

    return (
        <>
            <Helmet title={title} />

            <HouseError title={completeMessage} />
        </>
    );
}

Error.displayName = 'Error';
