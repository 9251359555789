import React from 'react';
import BasePaper, {PaperProps} from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import {styled} from '@mui/material/styles';
import {WidthBreakpoint} from '~/src/config/localParams';

const StyledPaper = styled(BasePaper)`
    && {
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05);
        border-radius: 16px;
    }
`;

const StyledCard = styled(CardContent)`
    padding: 30px;
    :last-child {
        padding-bottom: 30px;
    }
    @media (max-width: ${WidthBreakpoint.sm}) {
        padding: 20px;
    }
`;

/**
 * Renders paper component from Material UI with extra styles.
 *
 * @param {string} padding Padding to be applied on the <Paper> component.
 * @param {object} rest Other props to be passed down to the <Paper> component.
 * @constructor
 */

const Paper = React.forwardRef(({children, ...rest}: PaperProps, ref: React.Ref<any>) => (
    <StyledPaper {...rest} ref={ref}>
        <StyledCard>{children}</StyledCard>
    </StyledPaper>
));

Paper.displayName = 'Paper';

export default Paper;
