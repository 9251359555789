type LogLevel = 'log' | 'warning' | 'error';

interface LogIfDevelopmentOptions {
    level: LogLevel;
}

const defaultOptions: LogIfDevelopmentOptions = {
    level: 'warning',
};

export function logIfDevelopment(message: any, options: LogIfDevelopmentOptions = defaultOptions) {
    if (process.env.NODE_ENV === 'development') {
        switch (options.level) {
            case 'log':
                // eslint-disable-next-line no-console
                console.log(message);
                break;
            case 'warning':
                // eslint-disable-next-line no-console
                console.warn(message);
                break;
            case 'error':
                // eslint-disable-next-line no-console
                console.error(message);
                break;
        }
    }
}
