import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import qs from 'qs';
import {fetchRecordAffiliate} from './AffiliateActions';
import {AFFILIATE_PARAM_NAME} from '~/src/config/localParams';
import {RootState} from '~/src/redux/store';
import {manageResponse, removeTrackingParam, saveToSessionStorage} from '@simplecoin/core';

/**
 * Helps to track affiliate traffic.
 *
 * For example, it helps to see from where traffic is coming from (referrer) and where it lands (our page URL).
 *
 * It makes request to API endpoint, which creates trackable cookie for client.
 *
 * @return {null}
 * @constructor
 */
export default function AffiliateLinkTracker() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname, hash} = location;
    let {search} = location;

    const {recordAffiliate} = useSelector((state: RootState) => state.modules.affiliate);

    /**
     * Checks search params from window object and parses them via querystring library.
     *
     * @param {string} param Param name to look for.
     */
    function maybeCheckParams(param = AFFILIATE_PARAM_NAME) {
        const params = qs.parse(search, {ignoreQueryPrefix: true});

        const affiliateId = params[param] || null;

        if (affiliateId) {
            dispatch(fetchRecordAffiliate({affiliateId, entryUrl: window.location.href, referrer: document.referrer}));
            saveToSessionStorage({key: 'affiliateId', value: affiliateId});
        }
    }

    /**
     * Pushes location without tracking get param
     */
    function pushToNormalizedLocation() {
        search = removeTrackingParam(search);
        const location = {search, pathname, hash};
        navigate(location);
    }

    // On mount
    useEffect(() => {
        maybeCheckParams();
    }, []);

    // Track reducer change and normalize URL on success
    useEffect(() => {
        manageResponse({
            reducer: recordAffiliate,
            onAny: pushToNormalizedLocation,
        });
    }, [recordAffiliate]);

    return null;
}

AffiliateLinkTracker.displayName = 'AffiliateLinkTracker';
