import React, {createElement, useMemo} from 'react';
import countries from '../assets/json/countryList.json';
import CustomSelect from './CustomSelect';
import {getConfigValue} from '../helpers/config';
import {BaseSelectProps, OutlinedSelectProps} from '@mui/material/Select/Select';

type CustomSelectPropsToExtend = OutlinedSelectProps & BaseSelectProps;

export interface CountrySelectorProps extends CustomSelectPropsToExtend {
    label: string;
    value: string;
    onChange: (event: React.ChangeEvent<any>) => void;
    onBlur: (e: React.SyntheticEvent) => void;
    name: string;
    errorMessage?: any;
    formikField?: boolean;
    fullWidth?: boolean;
    required?: boolean;
    disabled?: boolean;
}

/**
 * Renders country selector element with native select as underlying input element. Country list is
 * provided by third party.
 */
export default function CountrySelector({onChange, ...props}: CountrySelectorProps) {
    const preferredCountries = getConfigValue<Array<string>>('preferredCountries', []);
    const options = useMemo(() => Object.keys(countries), [countries]);
    const OptionElement = (props) => createElement('option', props);

    const renderOption = (code: any, id: number) => (
        <OptionElement key={id} value={code}>
            {countries[code]}
        </OptionElement>
    );

    return (
        <CustomSelect
            options={options}
            preferredOptions={preferredCountries}
            renderOption={renderOption}
            onChange={onChange}
            native
            {...props}
        />
    );
}

CountrySelector.displayName = 'CountrySelector';
