import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/ru';
import 'dayjs/locale/nl';
import 'dayjs/locale/es';
import 'dayjs/locale/sk';
import 'dayjs/locale/uk';
import 'dayjs/locale/pl';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/vi';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(duration);

export {Dayjs};

export default dayjs;
