import {SvgIcon} from '@mui/material';
import React from 'react';

export default function RecipientIcon() {
    return (
        <SvgIcon
            viewBox='0 0 24 24'
            sx={{fill: 'currentColor', strokeWidth: 2, stroke: 'currentColor', strokeLinejoin: 'round'}}
        >
            <path
                fill='none'
                d='m 20.350712,12.384033 v 3.862176 c 0,0.23525 -0.189083,0.424639 -0.423954,0.424639 H 13.099617 M 6.0569501,7.4714254 V 2.7269288 c 0,-0.2352495 0.1890837,-0.4246389 0.4239543,-0.4246389 H 19.926758 c 0.234871,0 0.423954,0.1893894 0.423954,0.4246389 v 3.8621825'
            />
            <path
                fill='none'
                d='m 15.083702,6.5891113 h 7.152373 c 0.275751,0 0.497745,0.222353 0.497745,0.498547 v 4.7978277 c 0,0.276194 -0.221994,0.498547 -0.497745,0.498547 h -7.152373 c -0.275751,0 -0.497745,-0.222353 -0.497745,-0.498547 V 7.0876583 c 0,-0.276194 0.221994,-0.498547 0.497745,-0.498547 z'
            />
            <path d='m 17.519198,9.4865723 a 0.35119388,0.35303166 0 0 1 -0.351194,0.353033 0.35119388,0.35303166 0 0 1 -0.351193,-0.353033 0.35119388,0.35303166 0 0 1 0.351193,-0.353031 0.35119388,0.35303166 0 0 1 0.351194,0.353031 z' />
            <path
                strokeWidth='0.7'
                d='m 8.0190781,15.89232 c 1.8087298,0 3.2737189,-1.46499 3.2737189,-3.27372 0,-1.808729 -1.4649891,-3.2737174 -3.2737189,-3.2737174 -1.80873,0 -3.2737194,1.4649884 -3.2737194,3.2737174 0,1.80873 1.4649894,3.27372 3.2737194,3.27372 z m 0,1.636859 c -2.1852076,0 -6.5474386,1.096696 -6.5474386,3.27372 v 0.81843 c 0,0.450136 0.3682934,0.818429 0.8184298,0.818429 H 13.748087 c 0.450137,0 0.81843,-0.368293 0.81843,-0.818429 v -0.81843 c 0,-2.177024 -4.362232,-3.27372 -6.5474389,-3.27372 z'
            />
        </SvgIcon>
    );
}
