import React, {useEffect, useMemo, useRef} from 'react';
import {FormikHelpers, FormikProps, FormikValues, useFormik} from 'formik';
import Field, {FieldItemProps} from './Field';
import {Grid, GridProps, ButtonProps} from '@mui/material';
import {isObject} from '../helpers/common';
import {manageResponse} from '../helpers/api';
import CustomButton from './CustomButton';

interface FormProps {
    rules: any;
    fields: FieldItemProps[];
    onSubmit?: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<any>; // may be empty if custom footer is provided
    validateOnBlur?: boolean;
    validateOnChange?: boolean;
    validateOnMount?: boolean;
    footer?: (formikProps: FormikProps<FormikValues>) => void;
    submitText?: string;
    submitProps?: ButtonProps;
    children?: React.ReactChildren;
    initialValues?: FormikValues;
    grid?: boolean;
    reducer?: any; // may be empty if custom footer is provided
    formProps: any;
    formikAssign?: (formik: FormikProps<FormikValues>) => void;
    gridItemProps?: GridProps;
}

export interface CaptchaRef {
    reset(): void;
}

const dummyOnSubmit = () => undefined;

/**
 * Used to generate forms within application.
 *
 * It comes with handle helpers to simplify form building.
 *
 * Underneath it has Formik to help with validation and form state handling, etc.
 *
 *
 * Usage example:
 *
 * ```js
 * <Form
 *     rules={rules}
 *     fields={[
 *         {
 *             type: 'email',
 *             name: 'email',
 *             label: 'Email',
 *             value: '',
 *         },
 *         {
 *              type: 'password',
 *              name: 'password',
 *              label: 'Password',
 *              value: '',
 *              description: 'This is some password description',
 *         }
 *     ]}
 *     onSubmit={this.onSubmit}
 *     submitText={'Submit'}
 * />
 * ```
 *
 * or as custom component:
 *
 * ```js
 * <Form
 *     rules={rules}
 *     fields={[
 *         {
 *             component: (formik) => <SomeFieldComponent with props={true}>,
 *             name: 'fieldName',
 *             value: '',
 *         },
 *     ]}
 *     onSubmit={this.onSubmit}
 *     submitText={'Submit'}
 * />
 * ```
 *
 *
 *  * custom footer is also supported, useful for adding custom elements or if form submission is not required/unwanted
 *
 * ```js
 * <Form
 *     rules={rules}
 *     fields={[...]}
 *     submitText={'Submit'}
 *     footer={(props) => (
                            <Grid item>
                                <Button onClick={() => props.handleSubmit(props.values, props)}>
                                    {t('wallet_form_create_button')}
                                </Button>
                            </Grid>
                 );
             }
 * />
 * ```
 */
export default function Form({
    rules = {},
    fields = [],
    validateOnBlur = false,
    validateOnChange = false,
    validateOnMount = false,
    onSubmit,
    footer,
    submitText,
    submitProps = {
        size: 'large',
        variant: 'contained',
        color: 'primary',
        type: 'submit',
        style: {marginTop: '30px'},
    },
    initialValues,
    reducer,
    formProps,
    formikAssign,
    gridItemProps = {xs: 12},
}: FormProps) {
    const recaptchaRef = useRef<CaptchaRef>(null);

    /**
     * Get initial form values.
     *
     * @return object
     */
    const initials = useMemo(() => {
        if (initialValues && isObject(initialValues)) {
            Object.keys(initialValues).forEach((key) => {
                if (initialValues && !initialValues[key]) {
                    initialValues[key] = '';
                }
            });
        } else {
            initialValues = {};
            fields.forEach((field) => {
                if (initialValues && field.name) {
                    initialValues[field.name] = field.value || '';
                }
            });
        }

        return initialValues;
    }, []);

    const formik = useFormik({
        initialValues: initials ?? {},
        validationSchema: rules,
        validateOnMount,
        validateOnBlur,
        validateOnChange,
        onSubmit: onSubmit ?? dummyOnSubmit,
    });

    const {isValid} = formik;

    useEffect(() => {
        if (typeof formikAssign === 'function') {
            formikAssign(formik);
        }
    }, [isValid]);

    useEffect(() => {
        manageResponse({
            reducer,
            formik,
            onError: () => {
                if (recaptchaRef.current && typeof recaptchaRef.current.reset === 'function') {
                    // ref has to be assigned
                    recaptchaRef.current.reset();
                }
            },
        });
    }, [reducer]);

    // Filter for only visible items
    // Items which do not have 'visible' property would be visible by default
    fields = useMemo(() => fields.filter((item) => typeof item.visible === 'undefined' || item.visible), [fields]);

    /**
     * Renders single form field.
     *
     * @param {FieldItemProps} item
     * @param {number} key
     * @return {React.ReactNode}
     */
    function renderField(item: FieldItemProps, key: number): React.ReactNode {
        if (typeof item.component === 'function') {
            item.type = 'custom';
        }

        item.visible = undefined; // we don't want this to go to the DOM

        return (
            <Grid item key={key} {...gridItemProps}>
                <Field item={item} formik={formik} fieldRef={recaptchaRef} />
            </Grid>
        );
    }

    return (
        <form {...formProps} onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                {fields && fields.map(renderField)}
            </Grid>
            {footer ? (
                footer(formik)
            ) : (
                <CustomButton {...submitProps} reducer={reducer} loadingButton color='secondary'>
                    {submitText}
                </CustomButton>
            )}
        </form>
    );
}
