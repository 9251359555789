import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Button, Stack, Typography} from '@mui/material';
import {Currency} from '../../typings/currency';
import {fetchChangeStatus} from '../../redux/orderSlice';
import {Order, STATUS_RETURN_TO_CLIENT} from '../../typings/Order';
import {useCoreAppDispatch} from '../../redux/store';

export interface OrderReturnFiatFormProps {
    order: Order;
    fromCurrency: Currency;
    token?: string;
    handleClose?: () => void;
}

/**
 * Renders form where client can choose to get fiat money back
 */
export default function OrderReturnFiatForm({order, fromCurrency, token, handleClose}: OrderReturnFiatFormProps) {
    const dispatch = useCoreAppDispatch();
    const {t} = useTranslation();

    /**
     * Handles changing status to return to client
     */
    function changeStatus() {
        if (fetchChangeStatus) {
            dispatch(fetchChangeStatus({id: order.id, token, status: STATUS_RETURN_TO_CLIENT}));
            if (typeof handleClose === 'function') {
                handleClose();
            }
        }
    }

    const currencyName = fromCurrency ? fromCurrency.name : '';

    const alertValues = {
        currency: currencyName,
        amount: order.return_amount,
    };

    const alertComponents = [<Typography key={0}>{`${fromCurrency} `}</Typography>];
    const hasReturnFee = Number.parseFloat(order.return_amount ?? '0') !== Number.parseFloat(order.from_amount);

    const suspendedButtonValues = {
        amount: order.return_amount,
        currency: currencyName,
    };

    return (
        <Stack direction='column' spacing={4}>
            <Alert severity='info'>
                {hasReturnFee ? (
                    <Trans components={alertComponents} i18nKey='return_fiat_payment_info' values={alertValues} />
                ) : (
                    t('react_order_crypto_no_fee_info_message')
                )}
            </Alert>
            <Stack justifyContent='space-between' direction='row'>
                <Button variant='outlined' color='secondary' onClick={handleClose}>
                    {t('button_close')}
                </Button>

                <Button onClick={changeStatus} data-testid='button-order-popper-return'>
                    {t('react_suspended_widget_return_btn', suspendedButtonValues)}
                </Button>
            </Stack>
        </Stack>
    );
}
