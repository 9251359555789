import {getConfigValue} from '~/helpers/config';
import {TrezorDeviceInfo} from './TrezorDeviceInfoInterface';
import {getTrezorInfo} from '~/helpers/trezorUtils';
import {setConnected, setDisconnected} from '~/redux/trezorConnectSlice';
import {DEVICE_EVENT, DEVICE} from '@trezor/connect-web';

let trezorConnectInstance;

/**
 * Function that handles access to trezor connect instance
 */
export function getTrezorConnect(dispatch, callback) {
    const trezorUrl: string = getConfigValue('trezorConnectUrl', '');
    const trezorDevMail: string = getConfigValue('trezorDevMail', '');
    const appUrl: string = getConfigValue('appUrl', '');

    if (!trezorConnectInstance) {
        import('@trezor/connect-web').then((trezorConnectImport) => {
            const instance = trezorConnectImport.default;
            instance
                .init({
                    connectSrc: trezorUrl,
                    lazyLoad: true,
                    popup: true, // popup: false not available for 3rd parties
                    webusb: false, // webusb support not verified
                    debug: false, // see whats going on inside iframe
                    manifest: {
                        email: trezorDevMail,
                        appUrl: appUrl,
                    },
                })
                .then(() => {
                    trezorConnectInstance = instance;
                    registerEventHandlers(instance, dispatch);
                    callback(instance);
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('TrezorConnect init error', error);
                });
        });
    } else {
        callback(trezorConnectInstance);
    }
}

/**
 * Register trezor handlers
 */
function registerEventHandlers(instance, dispatch) {
    if (instance) {
        instance.on(DEVICE_EVENT, (event) => {
            if (event.type === DEVICE.CONNECT) {
                const trezorDeviceInfo: TrezorDeviceInfo = getTrezorInfo(event.payload);
                dispatch(setConnected(trezorDeviceInfo));
            } else if (event.type === DEVICE.DISCONNECT) {
                dispatch(setDisconnected());
            }
        });
    }
}
