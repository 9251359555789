import {
    AUTH_USER,
    AUTH_USER_FAILURE,
    AUTH_USER_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_INVALIDATE,
    LOGOUT_USER,
    LOGOUT_USER_FAILURE,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_INVALIDATE,
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_FAILURE,
    REQUEST_PASSWORD_RESET_SUCCESS,
    REQUEST_PASSWORD_RESET_INVALIDATE,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    VALIDATE_RESET_PASSWORD_TOKEN,
    VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
    VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_FAILURE,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_INVALIDATE,
    VALIDATE_EMAIL_CONFIRMATION_TOKEN,
    VALIDATE_EMAIL_CONFIRMATION_TOKEN_SUCCESS,
    VALIDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE,
    CONFIRM_TWO_FACTOR_CODE,
    CONFIRM_TWO_FACTOR_CODE_SUCCESS,
    CONFIRM_TWO_FACTOR_CODE_FAILURE,
    VALIDATE_TWO_FACTOR_CODE,
    VALIDATE_TWO_FACTOR_CODE_SUCCESS,
    VALIDATE_TWO_FACTOR_CODE_FAILURE,
    REQUEST_COMPANY_BANKS,
    REQUEST_COMPANY_BANKS_SUCCESS,
    REQUEST_COMPANY_BANKS_FAILURE,
    CONFIRM_TWO_FACTOR_CODE_INVALIDATE,
    REQUEST_LOGIN_CONFIRMATION,
    REQUEST_LOGIN_CONFIRMATION_SUCCESS,
    REQUEST_LOGIN_CONFIRMATION_FAILURE,
    RESEND_LOGIN_CONFIRMATION,
    RESEND_LOGIN_CONFIRMATION_INVALIDATE,
    RESEND_LOGIN_CONFIRMATION_FAILURE,
    RESEND_LOGIN_CONFIRMATION_SUCCESS,
} from './SiteActions';
import {Action} from '~/src/typings';
import {OrderActions, ReducerEnvelope, UserAuth} from '@simplecoin/core';
import CompanyBanks = OrderActions.CompanyBanks;

export interface LoginReducer {
    token: string;
    expires_at: number;
    created_at?: number;
    token_lifetime?: number;
}

export interface SiteReducerInterface {
    login: ReducerEnvelope<LoginReducer>;
    auth: ReducerEnvelope<UserAuth>;
    logout: ReducerEnvelope<{logged_out: boolean}>;
    signup: ReducerEnvelope<null>;
    requestPasswordReset: ReducerEnvelope<null>;
    confirm2Fa: ReducerEnvelope<LoginReducer>;
    validate2Fa: ReducerEnvelope<null>;
    validateResetPasswordToken: ReducerEnvelope<null>;
    resetPassword: ReducerEnvelope<null>;
    validateEmailConfirmationToken: ReducerEnvelope<null>;
    companyBanks: ReducerEnvelope<CompanyBanks>;
    confirmLogin: ReducerEnvelope<LoginReducer>;
    resendLoginConfirmation: ReducerEnvelope<null>;
}

const initialValues = {
    login: {isFetching: false},
    auth: {isFetching: false},
    logout: {isFetching: false},
    signup: {isFetching: false},
    requestPasswordReset: {isFetching: false},
    confirm2Fa: {isFetching: false},
    validate2Fa: {isFetching: false},
    validateResetPasswordToken: {isFetching: false},
    resetPassword: {isFetching: false},
    validateEmailConfirmationToken: {isFetching: false},
    companyBanks: {isFetching: false},
    confirmLogin: {isFetching: false},
    resendLoginConfirmation: {isFetching: false},
};

/**
 * @param state
 * @param action
 * @return {object}
 */
export default function (state: SiteReducerInterface = initialValues, action: Action = {}): SiteReducerInterface {
    switch (action.type) {
        // Login user
        case LOGIN_USER:
            return {...state, login: {isFetching: true}};
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                login: {isFetching: false, ...action.payload},
                auth: {isFetching: false},
            };
        case LOGIN_USER_FAILURE:
            return {...state, login: {isFetching: false, ...action.payload}};
        case LOGIN_USER_INVALIDATE:
            return {...state, login: {isFetching: false}};

        // Authenticate user
        case AUTH_USER:
            return handleAuthState(state, action);
        case AUTH_USER_SUCCESS:
            return handleAuthState(state, action);
        case AUTH_USER_FAILURE:
            return {...state, auth: {isFetching: false, ...action.payload}};

        // Logout user
        case LOGOUT_USER:
            return {...state, logout: {isFetching: true}};
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                logout: {isFetching: false, ...action.payload},
                auth: {isFetching: false},
            };

        case LOGOUT_USER_FAILURE:
            return {...state, logout: {isFetching: false, ...action.payload}};
        case LOGOUT_USER_INVALIDATE:
            return {...state, logout: {isFetching: false}};

        // Signup user
        case SIGNUP_USER:
            return {...state, signup: {isFetching: true}};
        case SIGNUP_USER_SUCCESS:
            return {...state, signup: {isFetching: false, ...action.payload}};
        case SIGNUP_USER_FAILURE:
            return {...state, signup: {isFetching: false, ...action.payload}};
        case SIGNUP_USER_INVALIDATE:
            return {...state, signup: {isFetching: false}};

        // Request password reset for user
        case REQUEST_PASSWORD_RESET:
            return {...state, requestPasswordReset: {isFetching: true}};
        case REQUEST_PASSWORD_RESET_SUCCESS:
            return {...state, requestPasswordReset: {isFetching: false, ...action.payload}};
        case REQUEST_PASSWORD_RESET_FAILURE:
            return {...state, requestPasswordReset: {isFetching: false, ...action.payload}};
        case REQUEST_PASSWORD_RESET_INVALIDATE:
            return {...state, requestPasswordReset: {isFetching: false}};

        // Reset password for user
        case RESET_PASSWORD:
            return {...state, resetPassword: {isFetching: true}};
        case RESET_PASSWORD_SUCCESS:
            return {...state, resetPassword: {isFetching: false, ...action.payload}};
        case RESET_PASSWORD_FAILURE:
            return {...state, resetPassword: {isFetching: false, ...action.payload}};

        // Validate reset password token
        case VALIDATE_RESET_PASSWORD_TOKEN:
            return {...state, validateResetPasswordToken: {isFetching: true}};
        case VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS:
            return {...state, validateResetPasswordToken: {isFetching: false, ...action.payload}};
        case VALIDATE_RESET_PASSWORD_TOKEN_FAILURE:
            return {...state, validateResetPasswordToken: {isFetching: false, ...action.payload}};

        // Validate email confirmation token
        case VALIDATE_EMAIL_CONFIRMATION_TOKEN:
            return {...state, validateEmailConfirmationToken: {isFetching: true}};
        case VALIDATE_EMAIL_CONFIRMATION_TOKEN_SUCCESS:
            return {...state, validateEmailConfirmationToken: {isFetching: false, ...action.payload}};
        case VALIDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE:
            return {...state, validateEmailConfirmationToken: {isFetching: false, ...action.payload}};

        // Confirm two factor code
        case CONFIRM_TWO_FACTOR_CODE:
            return {...state, confirm2Fa: {isFetching: true}};
        case CONFIRM_TWO_FACTOR_CODE_SUCCESS:
            return {...state, confirm2Fa: {isFetching: false, ...action.payload}};
        case CONFIRM_TWO_FACTOR_CODE_FAILURE:
            return {...state, confirm2Fa: {isFetching: false, ...action.payload}};
        case CONFIRM_TWO_FACTOR_CODE_INVALIDATE:
            return {...state, confirm2Fa: {isFetching: false}};

        // Validate two-factor code
        case VALIDATE_TWO_FACTOR_CODE:
            return {...state, validate2Fa: {isFetching: true}};
        case VALIDATE_TWO_FACTOR_CODE_SUCCESS:
            return {...state, validate2Fa: {isFetching: false, ...action.payload}};
        case VALIDATE_TWO_FACTOR_CODE_FAILURE:
            return {...state, validate2Fa: {isFetching: false, ...action.payload}};

        // Get company banks
        case REQUEST_COMPANY_BANKS:
            return {...state, companyBanks: {isFetching: true}};
        case REQUEST_COMPANY_BANKS_SUCCESS:
            return {...state, companyBanks: {isFetching: false, ...action.payload}};
        case REQUEST_COMPANY_BANKS_FAILURE:
            return {...state, companyBanks: {isFetching: false, ...action.payload}};

        case REQUEST_LOGIN_CONFIRMATION:
            return {...state, confirmLogin: {isFetching: true}};
        case REQUEST_LOGIN_CONFIRMATION_SUCCESS:
            return {...state, confirmLogin: {isFetching: false, ...action.payload}};
        case REQUEST_LOGIN_CONFIRMATION_FAILURE:
            return {...state, confirmLogin: {isFetching: false, ...action.payload}};

        case RESEND_LOGIN_CONFIRMATION:
            return {...state, resendLoginConfirmation: {isFetching: true}};
        case RESEND_LOGIN_CONFIRMATION_SUCCESS:
            return {...state, resendLoginConfirmation: {isFetching: false, ...action.payload}};
        case RESEND_LOGIN_CONFIRMATION_FAILURE:
            return {...state, resendLoginConfirmation: {isFetching: false, ...action.payload}};
        case RESEND_LOGIN_CONFIRMATION_INVALIDATE:
            return {...state, resendLoginConfirmation: {isFetching: false}};

        default:
            return state;
    }
}

/**
 * Handles authentication endpoint state.
 *
 * @param state
 * @param action
 * @return {{auth}|*|{auth: {isFetching: boolean}}}
 */
function handleAuthState(state: SiteReducerInterface, action: Action): SiteReducerInterface {
    switch (action.type) {
        // prevent removing auth content if validUntil not expired
        case AUTH_USER:
            if (state?.auth?.response?.user) {
                return state;
            }
            return {...state, auth: {isFetching: true}};
        case AUTH_USER_SUCCESS:
            return {...state, auth: {isFetching: false, ...action.payload}};
        default:
            return state;
    }
}
