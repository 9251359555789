{
  "bank_account": "Conto bancario",
  "cancel": "Cancella",
  "crypto_wallet": "Indirizzo wallet",
  "exchange": "Acquista o vendi ora",
  "order_has_been_canceled": "L'ordine \u00e8 stato cancellato",
  "order_has_been_proceeded": "L'ordine \u00e8 stato elaborato",
  "order_has_been_suspended": "L'ordine \u00e8 stato sospeso",
  "order_status_history": "Cronologia dello stato dell'ordine",
  "orders": "Ordini",
  "payment_information": "Informazioni pagamento",
  "proceed": "Procedi",
  "rates": "Tariffe",
  "return": "Richiedi rimborso",
  "setting_language_preference_text": "Scegli la lingua in cui preferisci visualizzare Chainblock Buy e ricevere comunicazioni e-mail.",
  "setting_send_email_after_login": "Invia e-mail dopo ogni accesso.",
  "suspend": "Sospendi",
  "wallet_updated_successfully": "Portafoglio aggiornato correttamente",
  "we_will_send_you_money_back_shortly": "Effettueremo il rimborso a breve",
  "guest_login_and_signup_order_separation": "o",
  "order_button_create_order": "Crea ordine   \t",
  "guest_login": "Accedi",
  "guest_signup": "Registrati",
  "menu_guest_link_about_us": "Chi siamo",
  "menu_guest_link_help": "FAQ",
  "menu_guest_link_terms": "Termini",
  "order_form_caption": "Crea il tuo ordine qui",
  "order_form_email": "Email",
  "forgot_password": "Hai dimenticato la password?",
  "login_form_submit_button": "Accedi",
  "page_login_title": "Accedi a {{Chainblock}}\n",
  "page_signup_title": "Iscriviti",
  "signup_form_please_fill": "Iscriviti",
  "signup_form_submit_button": "Iscriviti",
  "order_form_placeholder_currency": "Valuta",
  "refund_widget_add_new_refund_address": "Aggiungi indirizzo di rimborso",
  "refund_address_select_label": "Indirizzo Rimborso",
  "copied": "Copiato",
  "suspended_widget_return_money_back": "Richiedi un rimborso",
  "suspended_widget_top_alert_message": "L'ordine \u00e8 sospeso in attesa della tua decisione.",
  "add_order": "Aggiungi nuovo ordine",
  "language": "Lingua",
  "logged_in": "Login",
  "search": "Cerca",
  "status_direction": "Direzione dello stato",
  "status_direction_description_cancelled": "Annulla ordine",
  "status_direction_description_proceed": "Procedi ordine",
  "status_direction_description_return_to_client": "Restituire i miei soldi / monete",
  "status_direction_description_suspended": "Sospendi l'ordine",
  "status_final": "Stato finale",
  "status_name_cancelled": "Annullato",
  "status_name_confirming_incoming": "Conferma in arrivo",
  "status_name_confirming_outgoing": "Conferma in uscita",
  "status_name_delivered": "Consegnato",
  "status_name_done": "Fatto",
  "status_name_new": "Nuovo",
  "status_name_proceed": "Procedi",
  "status_name_received": "Ricevuto",
  "status_name_return_to_client": "In restituzione",
  "status_name_returned": "Tornati",
  "status_name_suspended": "Sospeso",
  "suspended_widget_table_change_percent": "Modifica",
  "client_bank_account_list_add_new_account": "Aggiungi nuovo account",
  "account_form_create_button": "Aggiungi conto bancario",
  "account_form_save_button": "Salva",
  "create_new_wallet_header": "Aggiungi nuovo Wallet",
  "delete_account": "Cancella Conto",
  "delete_wallet": "Cancella Portafoglio",
  "update_account": "Aggiorna Account",
  "update_wallet": "Aggiorna portafoglio",
  "wallet_form_create_button": "Aggiungi Wallet",
  "header_what_now": "Procedi con il pagamento",
  "profile_page_header_my_documents": "Documenti allegati",
  "profile_page_header_my_profile": "Verifica il tuo account Chainblock Buy",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "Clicca qui per procedere con la verifica del tuo profilo Chainblock Buy",
  "profile_page_save_button": "Procedi con la verifica",
  "profile_page_tab_level1": "Livello 1",
  "profile_page_tab_level2": "Livello 2",
  "profile_page_tab_this_level_is_accepted": "Questo livello \u00e8 stato accettato",
  "refund_address_set_successfully": "Indirizzo rimborso impostato con successo",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "Selezionando la casella, ci autorizzi a procedere con il processo di verifica del tuo profilo.",
  "client_sidebar_avatar_hash_description": "Ricorda questo avatar. Se \u00e8 stato modificato, \u00e8 possibile che qualcuno abbia violato il tuo account.",
  "navigation_link_logout": "Logout",
  "navigation_link_rates": "Tariffe",
  "company_about": "Chainblock \u00e8 la prima azienda italiana nel settore Bitcoin. Ci siamo dal 2013, quando un bitcoin costava poco pi\u00f9 di 100\u20ac. Ci occupiamo di rendere facile l'accesso alla compravendita di criptomonete, attraverso le nostre soluzioni immediate e sicure che consentono di convertire valute tradizionali in monete virtuali.",
  "sidebar_navigation_orders": "Ordini",
  "sidebar_navigation_profile": "Profilo",
  "sidebar_navigation_security": "Sicurezza",
  "sidebar_navigation_settings": "Impostazioni",
  "company_header": "Informazioni su {{Chainblock}}",
  "company_header_contacts": "Contatti",
  "company_header_social": "Social",
  "company_subheader": "Cryptocurrencies made easy, since 2013",
  "company_social_group": "Gruppo {{brandName}}\n",
  "company_header_billing_address": "Indirizzo di fatturazione",
  "page_header_company": "{{Chainblock}} Scambio di Bitcoin e criptovalute | BTC LTC ETH BCH XRP\n\n",
  "footer_sidebar_link_about": "Informazioni",
  "client_order_reset_all_label": "Ripristina tutto",
  "client_order_search_btn": "Cerca ordini",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "La transazione \u00e8 stata presa in carico dalla banca e stiamo aspettando che venga effettuata.",
  "error": "Errore",
  "client_bank_accounts_widget_header": "Documenti bancari",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "Stiamo attendendo di avere un riscontro della transazione. Provvederemo a restituirti l'importo inviato al pi\u00f9 presto.",
  "page_subheader_settings_order_emails": "Email ordini",
  "page_subheader_settings_security_emails": "Email di sicurezza",
  "client_setting_hint_send_email_when_order_confirming_incoming": "Invia un'email quando un ordine in criptovalute \u00e8 in attesa di conferma.",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "Invia un'email quando ricevi un pagamento (bonifico o criptovalute) in arrivo da Chainblock",
  "client_setting_hint_send_email_when_order_done": "Invia un'email quando ordine \u00e8 completato.",
  "client_setting_hint_send_email_when_order_proceed": "Invia un'email quando un ordine viene impostato su \"Procedi\"",
  "client_setting_hint_send_email_when_order_received": "Inviami un'e-mail quando viene ricevuto un pagamento bancario per un ordine o viene confermato un pagamento in criptovaluta in entrata.\n",
  "client_setting_hint_send_email_when_order_return_to_client": "Invia un'email quando un ordine viene restituito.",
  "client_setting_hint_send_email_when_order_suspended": "Invia un'email quando un ordine viene sospeso.",
  "client_settings_label_order_confirming_incoming": "Confermato e in entrata",
  "client_settings_label_order_confirming_outgoing": "Confermato e in uscita",
  "client_settings_label_order_done": "Ordine eseguito",
  "client_settings_label_order_proceed": "Procedi",
  "client_settings_label_order_received": "Ordine ricevuto",
  "client_settings_label_order_return_to_client": "Restituito",
  "client_settings_label_order_suspended": "Ordine sospeso",
  "page_subheader_settings_general": "Generali",
  "profile_page_level1_my_document_subheader_paragraph": "Per poter proseguire con la verifica del tuo profilo, ti preghiamo di scaricare, compilare e ricaricare nello spazio sottostante il modulo di adeguata verifica presente a questo link: http://bit.ly/chainblock-aml-privato\nNel caso in cui volessi operare con un profilo aziendale, ti preghiamo di scriverci all'indirizzo cbuy@chainblock.it\nModulo di adeguata verifica:",
  "profile_page_level2_my_document_subheader_paragraph": "Sono necessarie le seguenti informazioni:\n\u2013 fonte principale di reddito\n\u2013 modulo AML compilato",
  "profile_page_level2_note_description": "Nel caso di registrazione aziendale, dovrai fornire dei documenti specifici. Assicurati di riportare qualsiasi situazione non standard (es: ricezione pagamenti da terze parti, ordini per conto di terzi, etc.)",
  "profile_page_level2_trading_purpose_description": "Ti preghiamo di compilare questo campo",
  "profile_page_level2_trading_source_of_income_description": "Ti preghiamo di specificare la fonte di reddito con cui opererai su Chainblock Buy. Per importi molto alti, potremmo richiederti un'evidenza della provenienza del reddito.",
  "action_reset_password_error_email_not_sent": "L'indirizzo email non risulta valido.",
  "action_reset_password_success": "Segui le istruzioni inviate via email.",
  "address_was_deleted": "Indirizzo cancellato.",
  "bank_account_was_added": "Conto bancario aggiunto.",
  "bank_account_was_deleted": "Conto bancario cancellato.",
  "bank_account_was_updated": "Conto bancario aggiornato.",
  "change": "Cambia",
  "change_password": "Cambia Password",
  "delete": "Elimina",
  "email_confirmed": "Email confermata.",
  "enter_email_address_to_recover_account": "Ripristina password",
  "file_was_deleted": "File cancellato",
  "information_successfully_saved": "Informazioni salvate correttamente.",
  "new_address_was_added": "Indirizzo aggiunto correttamente.",
  "new_password_saved": "Password salvata correttamente.",
  "order_has_been_created_guest": "Ordine creato.Controlla l'email che ti abbiamo inviato per proseguire.",
  "pls_choose_new_password": "Scegli una nuova password:",
  "request_password_reset": "Ripristina password",
  "reset_password": "Ripristina password",
  "save": "Salva",
  "security": "Sicurezza",
  "send": "Invia",
  "signup": "Registrazione",
  "update": "Aggiorna",
  "are_you_sure_you_want_to_delete_this_file": "Vuoi veramente cancellare questo file?",
  "client_bank_account_message_warning_about_symbol": "* Il nostro simbolo sar\u00e0 aggiunto al messaggio quando ti invieremo il denaro.\n",
  "from_amount_currency_label": "Dal conto\n",
  "to_amount_currency_label": "Per accedere al conto\n",
  "menu_guest_link_rates": "Prezzi",
  "is_automatic_proceed_on_duplicate_payment": "Imposta stato \"Procedi\" su ordini duplicati",
  "is_automatic_proceed_on_late_payment": "Abilita acquisto automatico in caso di pagamento ritardato",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "In caso di doppio pagamento, il sistema creer\u00e0 automaticamente un nuovo ordine in attesa di conferma da parte tua.\nSe l'opzione \u00e8 abilitata, il sistema effettuer\u00e0 un nuovo acquisto con il prezzo di riferimento in tempo reale.\nDefault: disabilitato",
  "setting_hint_is_automatic_proceed_on_late_payment": "Se abilitato, in caso di pagamento tardivo procederemo a creare un nuovo ordine con il nuovo prezzo di mercato. \nDefault: disabilitato. Richiediamo conferma manuale.",
  "no_camera_found": "Non ci \u00e8 possibile accedere alla fotocamera. Controlla le impostazioni.",
  "scan_address": "Scan QR code",
  "button_disable": "Disabilita",
  "confirm": "Conferma",
  "header_disable_google_authenticator": "Disabilita Two-factor Authentication (2FA)",
  "header_enable_google_authenticator": "Abilita Two-factor Authentication (2FA)",
  "label_2fa_password": "Password",
  "label_2fa_secret": "Segreto",
  "2fa_secret_description": "Nota: Salva questo codice in un posto sicuro. Potrai usarlo per recuperare gli accessi al tuo account nel caso in cui perdessi il telefono.",
  "btn_disable": "Disabilita",
  "btn_enable": "Abilita",
  "two_factor_authentication": "Two-Factor Authentication (2FA)",
  "fill_information_below_to_disable_two_factor_authentication": "Compila i campi per disabilitare la 2FA (Two Factor Authentication)",
  "alert_success_email_link_was_resent_again": "Email di attivazione inviata con successo. Controlla il tuo client di posta.",
  "hint_login_form_2fa_code": "Il codice non \u00e8 richiesto quando l'autenticazione a due fattori (2FA) non \u00e8 abilitata.",
  "footer_sidebar_link_terms": "Termini di servizio",
  "button_download_order_pdf": "Download PDF",
  "button_hint_download_order_pdf": "Dettaglio ordine (PDF)",
  "alert_success_affiliate_payout_request_successfully_created": "Richiesta di prelievo effettuata con successo.",
  "alert_success_you_have_become_affiliate": "Complimenti! Benvenuto nel club Chainblock\u2122 Affiliate.",
  "button_create": "Crea",
  "button_save": "Salva",
  "page_affiliate_dashboard_copy_url_header": "Chainblock\u2122 Affiliate URL",
  "page_affiliate_dashboard_overview_header": "Panoramica",
  "page_affiliate_dashboard_overview_paragraph": "Riepilogo account Chainblock\u2122 Affiliate",
  "page_affiliate_dashboard_paragraph": "In questa pagina puoi visualizzare il riepilogo generale del tuo conto affiliato, vedere le statistiche dettagliate e richiedere un prelievo in qualsiasi valuta supportata da {{nomeBrand}}.\n",
  "page_affiliate_statistics_button": "Statistiche",
  "page_affiliate_stats_first_paragraph": "Le tue statistiche Chainblock\u2122 Affiliate. Scopri chi e come ha usato il tuo link di invito.",
  "page_affiliate_stats_label_conversion": "Click sui banner",
  "page_affiliate_stats_label_period": "Data",
  "page_affiliate_withdraw_button": "Prelievo",
  "page_become_affiliate_first_paragraph": "Accetto i termini e condizioni Chainblock\u2122 Affiliate",
  "page_header_affiliate_dashboard": "Dashboard Chainblock\u2122 Affiliate",
  "page_header_become_affiliate": "Diventa un Chainblock\u2122 Affiliate",
  "sidebar_navigation_affiliate": "Chainblock\u2122 Affiliate",
  "label_agree_with_affiliate_conditions": "Accetto i termini e le condizioni Chainblock\u2122 Affiliate",
  "page_affiliate_withdraw_request_paragraph": "Scegli il conto corrente bancario o l'indirizzo wallet dove ricevere i tuoi compensi come Chainblock Affiliate",
  "page_affiliate_finance_first_paragraph": "In questa pagina puoi vedere la lista degli ordini associati al tuo account e il relativo ricavo generato in EUR.",
  "page_affiliate_finance_label_order": "Ordine",
  "page_affiliate_finance_button": "Ricavato",
  "page_affiliate_finance_label_profit_in_eur": "Ricavo (EUR)",
  "page_affiliate_asset_banners_paragraph": "Da qui puoi includere i nostri Chainblock\u2122 Buy referral button.\nCopia uno dei codici sottostanti e includilo nel tuo sito per mostrare il referral button.",
  "page_affiliate_assets_button": "Materiali promozionali",
  "api_order_unsupported_currency_pair": "Opzione non disponibile",
  "footer_sidebar_link_data_processing": "GDPR",
  "alert_success_ticket_created": "Ticket creato correttamente.",
  "alert_success_ticket_reply_sent": "Risposta al ticket inviata correttamente.",
  "button_send": "Invia",
  "page_header_ticket_create": "Nuovo ticket",
  "page_header_ticket_list": "Ticket di supporto",
  "page_header_ticket_reply": "Rispondi al Ticket",
  "page_ticket_reply_you": "Tu",
  "sidebar_navigation_ticket": "Supporto",
  "alert_success_ticket_closed": "Ticket risolto.",
  "button_create_support_ticket": "Apri ticket",
  "button_hint_create_support_ticket": "Puoi creare un ticket di supporto nel caso in cui avessi domande o problemi con questo ordine.",
  "page_tickets_pending_tickets": "Ticket in sospeso",
  "page_tickets_solved_tickets": "Ticket risolti",
  "page_tickets_no_pending_tickets": "Nessun ticket presente.",
  "page_tickets_no_solved_tickets": "Nessun ticket risolto.",
  "take_selfie_button": "Scatta selfie",
  "no_camera_access_provided": "Non ci \u00e8 possibile accedere alla fotocamera. Controlla le impostazioni sul tuo dispositivo e assicurati che l'accesso alla fotocamera sia abilitato.",
  "menu_guest_link_privacy_policy": "Politica sulla riservatezza",
  "change_password_social_reset_password_claim": "Password dimenticata? Nessun problema.",
  "alert_change_password_email_was_sent": "Ti abbiamo inviato una mail per impostare una nuova password.",
  "page_confirm_two_factor": "Conferma autenticazione a due fattori",
  "page_confirm_two_factor_paragraph": "Inserisci il codice generato dall'app utilizzata per la 2FA:",
  "cookie_consent_i_agree": "Accetto",
  "cookie_consent_message": "Questo sito utilizza i cookie per agevolare la navigazione e la possibilit\u00e0 di fornire feedback, analizzare l'utilizzo dei nostri prodotti e servizi. <0>Politica dei cookie.\n",
  "try_again_button": "Riprova",
  "dropable_or_pastable_from_clipboard": "Trascina qui i tuoi file",
  "hint_enter_two_factor_code_to_confirm_action": "Inserisci il codice Google Authenticator.",
  "add_new_ticket": "Aggiungi ticket",
  "close_ticket": "Chiudi",
  "client_setting_hint_send_email_limit_price_changed": "Inviami un'email quando il prezzo limite viene impostato o cambiato.",
  "client_settings_label_order_limit_price_changed": "Prezzo limite",
  "button_set_limit": "Imposta limite",
  "suspended_widget_header_limit_rate": "Prezzo limite",
  "suspended_widget_header_limit_rate_hint": "Impostando il tasso limite, il tuo ordine verr\u00e0 accettato automaticamente quando il tasso di mercato raggiunger\u00e0 il tasso limite impostato da te.",
  "suspended_widget_header_market_rate": "Prezzo di mercato",
  "suspended_widget_header_market_rate_hint": "Prezzo finito del tuo ordine (commissioni incluse)",
  "suspended_widget_header_order_rate": "Prezzo d'ordine",
  "suspended_widget_header_order_rate_hint": "Questo \u00e8 il prezzo fissato al momento della creazione dell'ordine (inclusivo di commissioni).",
  "button_change_limit": "Cambia",
  "button_limit_cancel": "Cancella",
  "suspended_widget_limit_form_paragraph": " ",
  "hint_close_ticket_button": "Se fai click su questo pulsante, il ticket verr\u00e0 contrassegnato come risolto e non sar\u00e0 possibile avviare ulteriori discussioni a meno che non crei un nuovo ticket.",
  "heading_chart": "Grafico",
  "button_close": "Chiudi",
  "boolean_option_no": "No",
  "boolean_option_yes": "S\u00ec",
  "generic_link_learn_more": "Scopri di pi\u00f9",
  "profile_page_header_my_documents_level0_paragraph": "Per poter abilitare il tuo account Chainblock Buy, abbiamo bisogno di chiederti un paio di informazioni personali secondo le normative vigenti in Italia in materia di adeguata verifica e ai fini anti-riciclaggio e anti-terrorismo.",
  "profile_page_header_my_documents_level1_paragraph": "<strong> Allega i seguenti documenti: </strong> <br>\n- qualsiasi documento che possa aiutare a dimostrare la tua fonte di reddito <br>\n- foto di te con il tuo documento d'identit\u00e0 <br>\n- documenti di registrazione della societ\u00e0, se applicabile <br>\n- qualsiasi altro documento richiesto dal nostro staff",
  "chart_module_market_price_inverse": "Inverso",
  "chart_module_no_data_available": "Nessun dato disponibile.",
  "bank_account_verified": "Verificato",
  "profile_page_bank_account_header": "I tuoi conti correnti bancari",
  "profile_page_bank_accounts_paragraph": "Per una corretta identificazione su Internet, dobbiamo verificare almeno un conto bancario. Nella pagina dei destinatari imposta il tuo account, quindi allega il documento richiesto secondo le istruzioni, in alternativa invia il pagamento di prova.",
  "bank_account_file_upload_unverified_level0_paragraph": "Per completare la tua identificazione su Internet (verifica del profilo di livello 1) abbiamo bisogno della prova dell'esistenza del tuo conto bancario. Allega il documento emesso dalla banca, dove possiamo vedere il proprietario dell'account e il numero di conto. Pu\u00f2 essere estratto conto o contratto di mantenimento del conto. Si accetta la copia del documento cartaceo o del file PDF. Si prega di allegare il documento qui.",
  "guest_market_table": "Tabella dei prezzi",
  "validation_error_not_matching_passwords": "Le password non corrispondono",
  "react_send_from_amount_here": "Invia {{importo}} {{valuta}} al seguente indirizzo:\n",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Abbiamo ricevuto il tuo pagamento, ma hai deciso di riaverlo. Ti invieremo {{amount}} <0> {{currency}} a breve.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "Abbiamo ricevuto {{amountFrom}} <0> {{currencyFrom}} per il tuo ordine e ti invieremo {{amountTo}} <1> {{currencyTo}}",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "Vediamo una transazione crittografica in arrivo per {{amount}} <0> {{currency}}. In questo momento, stiamo aspettando ulteriori conferme.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "Abbiamo inviato una transazione crittografica in uscita per {{amount}} <0> {{currency}}. In questo momento, stiamo aspettando ulteriori conferme.",
  "react_suspended_widget_accept_btn": "Accetta {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": "Restituisci {{amount}} {{currency}}",
  "react_order_created_high_market_volatility_message": "L'ordine \u00e8 sospeso, perch\u00e9 hai raggiunto il volume massimo di scambi mensili. Devi aggiornare il tuo profilo al livello {{level}}.\nA causa dell'elevata volatilit\u00e0 del mercato, siamo in grado di mantenere questo tasso solo per {{duration}} minuti. La velocit\u00e0 della tua transazione crittografica dipende dalla commissione di transazione, quindi \u00e8 importante utilizzare la commissione di transazione corretta affinch\u00e9 la transazione possa essere verificata presto. Se il tuo pagamento arriva dopo questo orario e allo stesso tempo il tasso cambia notevolmente, ti contatteremo via email per offrire queste opzioni: accettare un nuovo tasso di cambio e procedere con l'ordine o restituire i tuoi fondi.",
  "react_order_suspend_over_limit_message": "L'ordine \u00e8 sospeso perch\u00e9 hai raggiunto il volume limite",
  "chart_timeframe_option_all": "Tutti",
  "react_bank_account_file_upload_unverified_paragraph": "Avremo anche bisogno di vedere le transazioni bancarie in arrivo da questo conto per poter vedere il nome del titolare del conto. Se si intende inviare fondi da questo conto a noi, non sono necessari altri passaggi. Solo se si intende ricevere fondi da questo conto come prima transazione, si prega di inviare prima un importo minimo a <0>uno qualsiasi dei nostri conti.\n\n",
  "react_2fa_qr_code_description": "Per una maggiore sicurezza del tuo account, puoi abilitare l'autenticazione a due fattori (2FA). Usiamo Google Authenticator. Per iniziare a usarlo, installa <0> l'applicazione Google Authenticator nel tuo telefono, scansiona il codice QR mostrato in questa pagina, inserisci la password corrente dell'account e il codice dall'applicazione.",
  "chart_timeframe_option_day": "Giorno",
  "chart_timeframe_option_month": "Mese",
  "chart_timeframe_option_week": "Settimana",
  "chart_timeframe_option_year": "Anno",
  "chart_timeframe_option_year_to_date": "Anno in corso\n",
  "react_profile_page_level1_paragraph_before_form": "Il livello 1 consente di negoziare per volumi fino all'equivalente di {{limit_level_2}} EUR in {{tradeLimitDays}} giorni.",
  "react_profile_page_level2_paragraph_before_form": "Il livello 2 consente ai clienti di scambiare un volume cumulativo superiore all'equivalente di {{limit_level_2}} EUR in {{tradeLimitDays}} giorni. In alcuni casi possono essere condotti ulteriori domande AML.",
  "react_page_header_posts_by_category": "Categoria: {{name}}",
  "chart_timeframe_option_3months": "3 mesi",
  "ticket_load_more": "Carica di pi\u00f9",
  "ticket_type_your_message": "Scrivi qui il tuo messaggio...",
  "no_results_found": "Nessun risultato trovato.",
  "validation_field_required": "Questo campo \u00e8 obbligatorio",
  "validation_min_characters": "Il campo deve contenere almeno $ {min} caratteri",
  "uploaded_file_information_complete": "Le informazioni su questo documento sono complete",
  "uploaded_file_click_to_fill_information": "Fare clic qui per inserire ulteriori informazioni",
  "react_order_header": "ID ordine {{id}}",
  "profile_page_header_my_personal_data": "Informazione personale",
  "validation_error_incorrect_phone_number": "Numero di telefono errato",
  "react_file_upload_extension_error": "Sono consentite solo le seguenti estensioni: {{extensions}}",
  "qr_code_scan_success": "Codice QR scansionato con successo",
  "upload_success": "Caricamento riuscito",
  "button_open_form": "Apri modulo",
  "client_bank_trade_volume_label": "Volume commerciale del conto bancario",
  "qr_payment_hint": "Puoi usare QR Platba. Scansiona il codice qui sotto utilizzando l'applicazione smart banking nel tuo telefono",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0> Iscriviti </0> e ottieni volume di trading e / o sconto personale.",
  "sidebar_user_traded_volume": "Volume di {{tradeLimitDays}} giorni: {{traded}} / {{limit}} EUR",
  "trading_limits_info_message": "Limite di volume non verificato: {{limitLevel1}} EUR / {{level1TradeLimitDays}} giorni <br/>\nLimite di volume di livello 1: {{limitLevel2}} EUR / {{level2TradeLimitDays}} giorni <br/>\nIl limite del volume di livello 2 non \u00e8 formalmente limitato",
  "sidebar_user_traded_volume_sum": "Volume degli scambi: {{traded}} EUR",
  "react_refund_widget_already_chosen_refund_address": "Hai scelto {{returnAddress}} come indirizzo per il rimborso.",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Abbiamo gi\u00e0 ricevuto le tue monete. Avete deciso di restituirle. Il pagamento di ritorno ha un costo aggiuntivo. Riceverai {{importo}} {{valuta}} al tuo indirizzo di rimborso.\n",
  "refund_widget_btn_change_refund_address": "Modifica l'indirizzo per il rimborso",
  "react_order_crypto_return_fee_info_message": "La restituzione dell'ordine ha un costo aggiuntivo. Riceverai {{importo}} {{valuta}} all'indirizzo selezionato.\n",
  "react_suspended_widget_return_currency_btn": "Restituisci {{amount}} {{currency}} a questo portafoglio",
  "we_will_send_you_crypto_back_shortly": "Ti restituiremo le monete a breve.",
  "button_remove_limit": "Rimuovi limite",
  "react_alert_limit_removed": "Limite rimosso con successo",
  "unconfirmed_email_please_confirm_or_resend": "Conferma prima il tuo indirizzo e-mail o <0> invia nuovamente l'e-mail di attivazione",
  "button_back": "Indietro",
  "button_next": "Il prossimo",
  "button_submit_profile": "Invia profilo",
  "you_need_to_log_out_before_seeing_anonymous_order": "Devi disconnetterti prima di vedere l'ordine anonimo",
  "react_form_rate_explenation": "Come viene calcolato il prezzo?",
  "sign_up_to_return_guest_order": "Per restituire il tuo ordine, registrati e aggiungi un portafoglio.",
  "error_page_not_found": "Pagina non trovata",
  "react_page_affiliate_withdraw_request_button": "Prelevare {{importo}} {{valuta}}",
  "affiliate_withdraw_history_table_converted_amount": "Importo convertito",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Copia l'URL di seguito e condividilo sul tuo blog, sui social media o con i tuoi amici. Chiunque faccia clic su questo link e si registri o crei un ordine entro {{duration}} giorni verr\u00e0 associato al tuo account affiliato. Riceverai guadagni da ogni scambio effettuato dall'utente che hai invitato.",
  "react_affiliaite_balance_information": "Saldo: {{balance}} Profitto totale: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "Il saldo \u00e8 l'importo corrente che puoi prelevare. Il profitto totale \u00e8 quello che hai guadagnato da quando ti sei iscritto al programma di affiliazione.",
  "label_affiliate_overview_joined_at": "Iscritto",
  "label_repeat_password": "Ripeti la password",
  "react_consent_to_tos": "Continuando dichiaro di aver letto e accettato i <0>Termini dei servizi e la <1>Privacy Policy di {{nomeazienda}}.\n",
  "label_delete_account": "Eliminare l'account",
  "button_delete_account": "Cancella il mio account",
  "delete_account_warning_paragraph": "Se procedi, i dati del tuo account verranno eliminati definitivamente. Siamo spiacenti di vederti andare.Puoi registrarti di nuovo in qualsiasi momento con lo stesso indirizzo email ma il tuo account sar\u00e0 vuoto.",
  "account_delete_success": "Il tuo account \u00e8 stato cancellato",
  "log_out_success": "Sei stato disconnesso.",
  "session_time_remaining_label": "Tempo rimanente della sessione",
  "session_expire_in_label": "La tua sessione scadr\u00e0",
  "order_filter_label": "Filtra ordini",
  "order_filter_button_all": "Tutti",
  "to_currency_amount_field_label": "Voglio ricevere",
  "status_name_unfinished": "Incompiuto",
  "status_description_unfinished": "L'ordine non ha ancora uno stato finale",
  "filter_drawer_incomplete_filters_warning": "Seleziona almeno uno stato da ogni categoria",
  "help_pages_label": "Centro assistenza",
  "react_file_upload_size_error": "Il file che hai provato a caricare \u00e8 troppo grande. La dimensione massima del file \u00e8 {{size}} MB",
  "alert_submit_previous_level_first": "Invia prima il livello  di verifica del profilo precedente.",
  "wrong_vs_format_message": "Il simbolo della variabile deve contenere solo cifre (fino a 10)",
  "wrong_ss_format_message": "Il simbolo specifico deve contenere solo cifre (fino a 10)",
  "wrong_ks_format_message": "Il simbolo della costante deve contenere solo cifre (fino a 4)",
  "button_extend_session": "Estendi la sessione",
  "fiat_payment_recipient_name": "Nome destinatario",
  "fiat_payment_recipient_street": "Via del destinatario",
  "fiat_payment_recipient_city": "Citt\u00e0 del destinatario\n",
  "fiat_payment_recipient_country": "Paese destinatario",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "Invia {{amount}} <0> {{currency}} al seguente conto bancario",
  "crypto_qr_hint": "Puoi scansionare il codice qui sotto o fare click su di esso per aprire il portafoglio sul tuo dispositivo.",
  "react_validation_error_insufficient_funds": "Fondi insufficienti. Hai solo {{importo}} di {{nomecurrency}}.",
  "client_settings_label_affiliate_new_income": "Nuovi fondi accreditati \n",
  "client_setting_hint_affiliate_new_income": "Inviami un'email quando ricevo un nuovo credito sul mio account affiliato\n",
  "page_subheader_settings_affiliate": "Affiliato",
  "check_address_on_trezor_header": "Controlla l'indirizzo su Trezor",
  "check_address_on_trezor_body": "Premi il pulsante e continua su di esso",
  "show_address_on_trezor_button_label": "Mostra indirizzo su Trezor",
  "send_by_trezor_button": "Invia da Trezor",
  "send_by_trezor_description_tooltip": "\u00c8 possibile utilizzare questa funzione per inviare il pagamento in modo sicuro dal portafoglio Trezor connesso.",
  "trezor_address_import_dialog_header": "I seguenti indirizzi appartengono all'account Trezor scelto. Selezionarne uno.",
  "import_from_trezor_button": "importazione da Trezor",
  "address_used": "usato",
  "trezor_transaction_sent_dialog_title": "La transazione \u00e8 stata inviata con successo.",
  "trezor_transaction_sent_dialog_content": "Hai pagato con Trezor! L'hash \u00e8: {{txid}}. Attendi che la transazione venga associata al tuo ordine.\n",
  "trezor_address_import_available_for_currencies": "Importazione indirizzo Trezor disponibile per: {{valute}}",
  "trezor_link_learn_more": "Per saperne di pi\u00f9 sull'utilizzo di Trezor su {{brandName}}\n",
  "page_refresh_button_label": "Ricaricare",
  "profile_level_one_send_transfer_instead_of_document_upload": "Invier\u00f2 un bonifico bancario da un conto a mio nome invece di caricare un documento.",
  "profile_level1_primary_document_front_label": "Il fronte del documento principale",
  "profile_level1_primary_document_reverse_label": "Il rovescio del documento principale",
  "profile_level1_secondary_document_front_label": "Il fronte del documento secondario",
  "profile_level1_secondary_document_reverse_label": "Il retro del documento secondario",
  "profile_page_level1_primary_document_label": "Documento primario",
  "profile_page_level1_secondary_document_label": "Documento secondario",
  "profile_page_level1_additional_documents_label": "Documenti aggiuntivi",
  "profile_page_level1_document_passport": "Passaporto",
  "profile_page_level1_document_national_id": "ID personale",
  "profile_page_level1_document_other": "Altro documento",
  "profile_page_level1_document_driving_license": "Patente di guida",
  "profile_page_level1_primary_document_type_select_information": "Scegli il tuo tipo di documento principale",
  "profile_page_level1_secondary_document_type_select_information": "Scegli il tipo di documento secondario",
  "profile_page_level1_additional_document_type_select_information": "Aggiungi un'altro documento",
  "profile_page_level1_additional_document_skip_label": "Non ho altri documenti da caricare",
  "address_format_bech32_wallet_support_warning": "Questo indirizzo \u00e8 in formato bech32 che non \u00e8 completamente supportato da Trezor Wallet. Per intero ...",
  "label_document_title": "Titolo del documento",
  "react_new_app_version_available_dialog_title": "\u00c8 disponibile una nuova versione di {{Chainblock}}.\n",
  "update_application_version": "Aggiornare",
  "third_party_guest_order_provide_email_paragraph": "Per continuare, fornire un indirizzo e-mail.",
  "third_party_order_claim_email_verification_message": "Controlla la tua posta elettronica dove troverai un link di conferma.",
  "third_party_guest_order_confirm_or_resend_email": "Conferma il tuo indirizzo email o utilizza il pulsante qui sotto per inviarlo di nuovo.",
  "log_in_to_see_order_registered_client": "Accedi per visualizzare l'ordine",
  "client_recipients_list_header": "Destinatari",
  "sidebar_navigation_recipients": "Destinatari",
  "recipients_filter_label": "Filtra i destinatari",
  "client_add_new_recipient_button": "Aggiungi nuovo destinatario",
  "reset_filters_button": "Reset",
  "create_new_recipient_header": "Aggiungi un destinatario",
  "disable_social_login_label": "Scollega l'account del social network",
  "btn_unlink": "Scollega",
  "alert_error_please_set_password_to_disable_social_login": "Impostare prima la password dell'account per scollegare l'accesso all'account dei social media.",
  "disable_social_login_warning_paragraph": "Se procedete, il vostro account di social media sar\u00e0 scollegato. Potrai accedere solo con la password del tuo account {{brandName}}.\n",
  "social_login_disable_success": "Accesso social disabilitato con successo",
  "file_attached_to_ticket_auto_message": "Messaggio che verr\u00e0 inviato dopo che l'utente avr\u00e0 caricato il file sul ticket",
  "network_error_warning": "Connessione Internet non disponibile",
  "password_strength_very_weak": "Password Debole",
  "password_strength_weak": "Password Debole",
  "password_strength_good": "Password accettabile",
  "password_strength_great": "Password sicura",
  "status_description_on_hold": "L'ordine \u00e8 in attesa",
  "status_direction_description_on_hold": "L'ordine \u00e8 in attesa",
  "exchange_form_fee_included_tooltip_title": "La tariffa che vedi include la seguente commissione di transazione",
  "exchange_form_fee_not_included_tooltip_title": "Le seguenti commissioni di transazione non sono incluse nella tariffa mostrata. Specifica l'importo per vedere la tariffa finale.",
  "exchange_form_total_fee_eur": "Commissioni totali: ~ {{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Commissione per transazione in entrata: {{amount}} {{currency}}",
  "exchange_form_fee_tooltip_fee_out": "Commissione per transazione in uscita: {{amount}} {{currency}}",
  "exchange_form_fee_label_no_fee": "Costo zero",
  "exchange_form_fee_label_no_fee_explanation": "Questa operazione non avr\u00e0 alcuna commissione di transazione aggiuntiva per la transazione",
  "exchange_form_fee_label_included": "Inclusa commissione di transazione",
  "exchange_form_fee_label_excluded": "Esclusa la commissione di transazione",
  "order_view_back_to_vendor_button_label": "Torna a {{vendor_name}}",
  "table_sort_label": "Ordinare",
  "pagination_next_page": "Pagina successiva",
  "pagination_previous_page": "Pagina precedente",
  "pagination_rows_per_page": "Righe per pagina",
  "pagination_display_rows": "verr\u00e0 visualizzato come: 10 di 75",
  "button_download_csv": "Scarica la versione visibile come CSV\n",
  "button_print": "Stampa",
  "table_view_columns": "Visualizza colonne",
  "table_filter": "Tabella dei filtri",
  "table_filters_label": "Filtri",
  "show_columns_label": "Mostra colonne",
  "table_rows_selected": "righe selezionate",
  "add_account": "Aggiungi account",
  "account_balance": "Bilancio",
  "account_minimal_balance_label": "Saldo minimo:",
  "page_header_accounts": "Accounts",
  "sidebar_navigation_accounts": "Conti",
  "sidebar_navigation_trade": "Scambio",
  "landing_page_chart_widget_buy_button": "Compra",
  "exchange_form_from_amount_label": "Acquista per",
  "exchange_form_to_amount_label": "Ottieni",
  "footer_trading_since": "Trading cryptocurrencies dal 2014",
  "footer_headers_subscribe_to_newsletter": "Iscriviti alla nostra newsletter\n",
  "footer_rights_reserved": "\u00a9 {{anno}} {{companyName}} Tutti i diritti riservati.\n",
  "landing_header": "Scambio di criptovaluta affidabile dal 2014",
  "landing_subheader": "Il trading reso facile per i clienti in  Europa.",
  "landing_button_join": "Entra o Registrati",
  "landing_button_about_us": "Chi siamo",
  "landing_recent_orders_header": "Ordini recenti",
  "landing_statistics_30_day_order_count_label": "Numero di scambi effettuati negli ultimi 30 giorni",
  "landing_statistics_verified_clients_count": "Cliente verificato",
  "landing_statistics_volume_in_currency": "Volume totale in {{currency}}",
  "landing_header_help": "Aiuto",
  "button_buy_now": "Compra ora",
  "footer_header_support": "Support",
  "footer_link_help": "Aiuto",
  "footer_header_company": "Societ\u00e0",
  "footer_header_features": "Caratteristiche",
  "footer_features_trezor_support": "Trezor support",
  "footer_features_limit_order": "Ordine Limite",
  "footer_email_newsletter_form_text": "Iscriviti alla nostra newsletter e non perdere le importanti notizie e mai nulla di quello che accade su {{Chainblock}}.",
  "landing_email_newsletter_subscribe_button": "Sottoscrivi",
  "landing_email_newsletter_subscribe_field_placeholder": "Inserisci il tuo indirizzo email qui",
  "landing_email_subscribe_consent_warning": "Inserendo la tua email accetti i nostri <0> Termini di servizio </0> e <1> Informativa sulla privacy </1>",
  "footer_link_transaction_fees": "Commissioni di transazione",
  "footer_link_trade_limits": "Limiti di trading",
  "add_the_following_comment_to_payment": "Assicurati di scrivere la causale seguente:",
  "alert_error_please_set_password_to_enable_two_factor": "Imposta una password per abilitare l'autenticazione a due fattori (2FA).",
  "alert_success_google_authenticator_disabled_successfully": "Two-factor Authentication (2FA) disabilitata correttamente.",
  "alert_success_google_authenticator_enabled_successfully": "Two-factor Authentication (2FA) abilitata correttamente.",
  "confirmed": "Confermato",
  "created_at": "Creato il",
  "enter_form_email_placeholder": "Email",
  "enter_form_password_placeholder": "Password",
  "fiat_payments_without_note_cause_delay_warning": "I pagamenti senza questo messaggio devono essere elaborati manualmente, il che potrebbe causare un ritardo nell'elaborazione dell'ordine e una conseguente variazione del tasso di cambio.\n",
  "form_label_new_password": "Nuova password",
  "incoming_bank_transaction": "Transazione bancaria in entrata",
  "incoming_crypto_transaction": "Transazione crypto in entrata",
  "label_2fa_code": "2FA Code",
  "label_account_constant": "Simbolo costante",
  "label_account_label": "Nome wallet",
  "label_account_number": "Numero conto bancario",
  "label_account_specific": "Simbolo Specifico",
  "label_account_vs": "Simbolo variabile",
  "label_address": "Indirizzo",
  "label_address_label": "Nome wallet",
  "label_addresses": "Indirizzo",
  "label_affiliate_overview_affiliate_id": "Chainblock\u2122 Affiliate ID",
  "label_affiliate_overview_estimated_profit": "Ordini in corso",
  "label_affiliate_overview_referrals_count": "Utenti invitati",
  "label_affiliate_overview_url_hits_count": "Click sui banner",
  "label_amount": "Importo",
  "label_arbitrary_data": "Tag di destinazione",
  "label_bank_account": "Conto  bancario",
  "label_bank_account_id": "Conto corrente",
  "label_bank_accounts": "Conti correnti",
  "label_birthday": "Data di nascita",
  "label_city": "Citt\u00e0",
  "label_confirmations": "Conferme (corrente / minimo richiesto)\n",
  "label_country": "Stato",
  "label_created_at": "Creato il",
  "label_currency": "Valuta",
  "label_current_password": "Password attuale",
  "label_document_number": "Numero documento",
  "label_expires_at": "Valido fino al",
  "label_first_name": "Nome",
  "label_from_amount_currency": "Da",
  "label_hash": "Hash",
  "label_id": "ID",
  "label_in_block_id": "ID blocco",
  "label_is_politician": "Persona politicamente esposta",
  "label_issued_by": "Rilasciato da",
  "label_issued_by_country": "Rilasciato in",
  "label_last_name": "Cognome",
  "label_limit_price": "Prezzo limite",
  "label_message": "Messaggio per il destinatario\n",
  "label_monthly_volume": "Volume mensile",
  "label_new_password_again": "Conferma nuova password",
  "label_note": "Nota",
  "label_other_account_number": "etichettare_un_altro_numero_di_conto",
  "label_our_bank_account_id": "{{Chainblock}} Conto corrente bancario",
  "label_owner_address": "Indirizzo proprietario",
  "label_owner_city": "Citt\u00e0 proprietario",
  "label_owner_country": "Stato proprietario",
  "label_owner_name": "Nome proprietario",
  "label_paid_at": "Pagato il",
  "label_personal_id": "Documento di identit\u00e0",
  "label_phone": "Telefono",
  "label_rate": "Tasso di cambio per l'ordine\n",
  "label_ready_for_verification": "Accetto i termini e condizioni",
  "label_receive_address": "Ricevi indirizzo",
  "label_status": "Stato",
  "label_status_direction": "Stato",
  "label_status_final": "Stato finale",
  "label_street": "Via",
  "label_ticket_body": "Descrizione del ticket",
  "label_ticket_category": "Categoria ticket",
  "label_ticket_title": "Titolo",
  "label_to_amount_currency": "A",
  "label_trading_purpose": "Motivo dei movimenti su Chainblock Buy",
  "label_trading_source_of_income": "Fonte di guadagno",
  "label_zipcode": "CAP",
  "mail_client_identification_bullet_point_fill_personal_information": "Inserisci informazioni personali",
  "oops_something_went_wrong": "Ops, qualcosa \u00e8 andato storto. Riprova pi\u00f9 tardi.",
  "order_id": "ID Ordine",
  "outgoing_bank_transaction": "Transazione bancaria in uscita",
  "outgoing_crypto_transaction": "Transazione in uscita",
  "page_header_settings": "Impostazioni",
  "password_was_changed": "Password cambiata correttamente",
  "settings_updated": "Le impostazioni sono state aggiornate",
  "social_login_with_facebook": "Accedi con Facebook",
  "social_login_with_vkontakte": "Accedi con Vkontakte",
  "social_signin_with_google": "Accedi con Google",
  "social_signin_with_linkedin": "Accedi con LinkedIn",
  "status": "Stato",
  "status_description_cancelled": "Ordine \u00e8 cancellato",
  "status_description_confirming_incoming": "L'ordine conferma la nuova transazione in arrivo",
  "status_description_confirming_outgoing": "L'ordine conferma la nuova transazione in uscita",
  "status_description_delivered": "L'ordine \u00e8 consegnato",
  "status_description_done": "L'ordine \u00e8 completato",
  "status_description_new": "L'ordine \u00e8 stato creato",
  "status_description_proceed": "L'ordine non ha problemi, pu\u00f2 essere proseguito",
  "status_description_received": "Ordina la transazione bancaria / crittografica ricevuta e pronta per continuare",
  "status_description_return_to_client": "L'ordine \u00e8 impostato per essere restituito al cliente",
  "status_description_returned": "Le transazioni dell'ordine vengono restituite al cliente",
  "status_description_suspended": "L'ordine \u00e8 stato sospeso. Non l'hai sospeso tu? Ecco alcuni dei possibili motivi: il tempo massimo per effettuare il pagamento \u00e8 scaduto, il volume mensile \u00e8 stato superato, la causale inserita non \u00e8 corretta, \u00e8 richiesta un'ulteriore verifica dei documenti.",
  "ticket_category_affiliate": "Chainblock Affiliate",
  "ticket_category_api": "API",
  "ticket_category_business_verification": "Account aziendale",
  "ticket_category_order": "Ordini",
  "ticket_category_other": "Altro",
  "ticket_category_security": "Sicurezza",
  "ticket_category_suggestion": "Suggerimenti",
  "ticket_category_verification": "Verifica profilo",
  "validation_error_incorrect_email_format": "convalida_errore_formato_email_errato",
  "you_have_accepted_current_suspended_deal": "Hai accettato l'offerta sospesa corrente",
  "close": "Chiuso",
  "status_on_hold": "In attesa",
  "submit": "Invia",
  "resend": "Invia di nuovo",
  "reviews": "Ha scritto di noi",
  "label_counter_address": "Indirizzo contatore",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "Invia {{amount}} <0> {{currency}} </0> a uno dei seguenti conti bancari",
  "order_filter_button_new": "Nuovo",
  "order_filter_button_in_progress": "In progress",
  "order_filter_button_finished": "Finito",
  "order_filter_button_custom": "Personalizzata",
  "client_order_status_base_label": "Stato",
  "client_order_status_direction_label": "Direzione stato",
  "client_order_status_final_label": "Stato finale\n",
  "ticket_status_solved": "Risolto",
  "ticket_status_in_progress": "In lavorazione",
  "ticket_status_waiting_for_admin": "Inviato\n",
  "ticket_status_waiting_for_client": "Nuovo messaggio",
  "member_of": "Membro di",
  "level_one_verification_warning_back_transfer_instead_of_statement": "Seleziona almeno  <0>this article</0> uno stato da ogni categoria",
  "label_bank_account_number": "numero di conto bancario",
  "label_bank_account_details": "Informazioni sul conto bancario",
  "validation_max_characters": "Il campo pu\u00f2 contenere solo fino a $ {max} caratteri",
  "verification_required_if_order_made_warning_for_registered_clients": "Dovrai aumentare il tuo livello di verifica per elaborare questo ordine. Informazioni sui limiti ...",
  "verification_required_if_order_made_warning_for_guests": "Per procedere con l'ordine \u00e8 necessario registrarsi e aumentare il livello di verifica. Il tuo ordine verr\u00e0 sospeso automaticamente e dovrai accettare la nuova tariffa dopo aver effettuato la verifica. Per informazioni sui limiti e sulla verifica visita <0>questa pagina.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "Trading_disabilitato_per_favore_accedi_o_registrati",
  "get_verified_to_start_trading_warning_message": "Messaggio_di_Avviso_per_iniziare_a_fare_trading_dorestii_verificarti",
  "offer_will_expire_in": "Questa offerta scadr\u00e0",
  "offer_has_expired": "Questa offerta \u00e8 scaduta. Si prega di creare un nuovo ordine",
  "order_offer_header": "Offerta di scambio {{companyName}}\n",
  "verification_required_for_order_creation_partner_order": "\u00c8 ora necessario raggiungere il livello di verifica {{livello}} su {{marchio}} prima di elaborare l'ordine. Iscriviti e verifica il tuo profilo per continuare.\n",
  "label_specify_destination_tag": "Importante: inserire {{destinationTag}} come tag di destinazione.",
  "validation_error_required_characters_exactly": "Il campo ${percorso} deve essere esattamente di ${lunghezza} caratteri.\n",
  "csv_export_column_from_currency": "Da valuta\n",
  "csv_export_column_to_currency": "Alla valuta\n",
  "react_order_crypto_no_fee_info_message": "Non addebitiamo alcuna spesa per questo ordine. Riceverete un rimborso completo.",
  "login_confirmation_form_check_email_paragraph": "Un codice di verifica \u00e8 stato inviato al tuo indirizzo e-mail. Controllare la posta in arrivo e incollare il codice sottostante per confermare l'accesso.",
  "login_confirmation_title": "Email di verifica\n",
  "login_confirmation_email_resent": "L'e-mail di conferma \u00e8 stata inviata con successo\n",
  "login_confirmation_email_not_received": "Non hai ricevuto l'e-mail di verifica? Controllare la cartella spam. Possono essere necessari alcuni minuti.\n",
  "login_confirmation_code_input_placeholder": "Codice a 6 cifre\n",
  "lost_2fa_device_contact_support": "Hai perso il tuo dispositivo? Contatta il supporto al {{Email aziendale}} o al {{Telefono aziendale}}.\n",
  "higher_verification_limit_required_warning": "Aumenta il livello di verifica per visualizzare le istruzioni di pagamento",
  "verification_through_onfido_disclaimer": "La verifica dell'identit\u00e0 sar\u00e0 eseguita da Onfido",
  "signup_and_verification_required_warning": "Si prega di <0>scrivere e verificare il proprio account per procedere con l'ordine.\n",
  "go_to_profile_button": "Vai al profilo",
  "profile_page_divex_address_ownership_disclaimer_heading": "Dichiarazione di sicurezza del portafoglio\n",
  "profile_page_divex_address_ownership_disclaimer_message": "Si prega di allegare una scansione o una fotocopia della dichiarazione di propriet\u00e0 del portafoglio che dimostra che siete gli unici ad avere accesso al portafoglio di criptovalute che userete all'interno di Divex.\n",
  "profile_page_divex_address_ownership_disclaimer_warning": "La dichiarazione \u00e8 necessaria per garantire che il vostro consulente non abbia accesso al vostro portafoglio di criptovalute e quindi non possa rubare i vostri fondi in futuro. Se volete saperne di pi\u00f9 sulla sicurezza dei portafogli consigliati, <0>cliccate qui.\n",
  "signup_for_invited_only_divex_message": "La registrazione \u00e8 riservata ai clienti dello scambio Divex. Vuoi diventare un cliente? Contatta uno dei nostri partner per aiutarti a registrarti e a impostare l'intero processo. L'elenco di tutti i partner \u00e8 disponibile <0>qui.\n",
  "divex_address_ownership_disclaimer_upload_label": "Allegare qui il documento\n",
  "email_verification_token_expired_log_in_again": "Questo link di verifica non \u00e8 corretto o \u00e8 scaduto. Effettuare nuovamente il login\n",
  "pagination_total_visible_rows": "{visibleCount} di {totalCount}\n",
  "phone_hint_include_prefix": "Si prega di fornire il numero con prefisso\n",
  "sidebar_navigation_transactions": "Transazioni\n",
  "account_balance_formatted": "Saldo: {{bilancioConValuta}}\n",
  "transfer_sent_successfully": "Trasferimento inviato con successo\n",
  "cookie_consent_i_agree_to_all": "Abilita tutti",
  "cookie_consent_i_agree_to_required": "Abilita solo i cookie funzionali",
  "divex_affil_find_more": "Scopri di pi\u00f9 sul programma di affiliazione in <0>questo articolo.<0/>\n",
  "button_i_confirm": "Confermo",
  "wallet_ownership_declaration_paragraph_1": "Mi \u00e8 stato consigliato di utilizzare il portafoglio hardware Trezor per l'archiviazione degli asset digitali e di utilizzarlo con il codice PIN.\n",
  "wallet_ownership_declaration_paragraph_2": "Sono il proprietario del seed principale (12-24 parole) che ho conservato in modo sicuro. Sono consapevole che le criptovalute contenute nel portafoglio possono essere spese utilizzando il master seed, anche senza il dispositivo stesso.\n",
  "wallet_ownership_declaration_paragraph_3": "Il consulente che mi ha aiutato a configurare il mio portafoglio non ha avuto accesso al mio master seed e non ha potuto farne una copia.\n",
  "page_header_exchanges": "Exchange di valute\n",
  "sidebar_navigation_dashboard": "Dashboard",
  "dashboard_header": "Dashboard",
  "page_header_transfers": "Trasferimenti",
  "settings_button_general_settings": "Generale\n",
  "settings_button_security_settings": "Sicurezza",
  "settings_button_email_settings": "Regole per l'invio di e-mail\n",
  "settings_button_profile_verification": "Verifica del profilo\n",
  "action_activate_account_email_sent": "Abbiamo inviato un'e-mail con il link per l'attivazione della password all'indirizzo e-mail.\n",
  "action_activate_account_error_email_not_sent": "L'e-mail fornita non corrisponde all'account che pu\u00f2 essere attivato in questo modo. Si prega di scegliere un altro indirizzo e-mail.\n",
  "request_account_activation": "Attivazione dell'account\n",
  "account_activation_enter_email_provided_to_support": "Attiva il tuo account Divex inserendo l'e-mail con cui ti sei registrato al corso Introduzione al Bitcoin o alla Diverzo Academy.\n",
  "account_activate_button": "Attivare l'account",
  "account_activation_password_saved": "La password \u00e8 stata salvata. Ora \u00e8 possibile effettuare il login.",
  "breadcrumb_category_order": "Ordine",
  "breadcrumb_subcategory_order_id": "Ordine #{{id}}",
  "page_header_general_settings": "Impostazioni generali",
  "page_header_email_settings": "Regole per le e-mail",
  "dashboard_order_table_last_orders": "Ultimo ordine",
  "dashboard_order_table_all_orders": "Tutti gli ordini",
  "user_discount": "Il tuo sconto",
  "get_discount": "Ottenere lo sconto",
  "volume_discount": "Volume sconto",
  "personal_discount": "Sconto personale",
  "ticket_status_all": "Tutti",
  "id": "ID",
  "exchange_form_total_fee": "Fee totali",
  "exchange_form_out_fee_crypto": "Commissione di transazione in uscita",
  "exchange_form_out_fee_fiat": "Commissione per il bonifico in uscita",
  "exchange_form_in_fee_crypto": "Commissione di rete in entrata",
  "exchange_form_in_fee_fiat": "Commissioni per i trasferimenti bancari in entrata",
  "support": "Supporto",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "message": "Messaggio",
  "upload_file": "Carica file",
  "you": "Tu",
  "page_become_affiliate_partner_header": "Diventa partner di Chainblock <0>affiliato\n",
  "page_become_affiliate_partner_bonus": "Raccomanda il nostro exchange a qualcuno e ricevi una commissione fino al {{affiliatePercent}}% di ogni scambio effettuato.\n",
  "page_become_affiliate_partner_involved": "Voglio partecipare\n",
  "page_become_affiliate_partner_information": "Ulteriori informazioni\n",
  "page_affiliate_dashboard_header": "Programma affiliazione",
  "page_affiliate_dashboard_current_balance_header": "Saldo attuale\n",
  "page_affiliate_dashboard_current_balance_withdraw": "Saldo del prelievo\n",
  "order_related_support": "Assistenza relativa all'ordine\n",
  "page_affiliate_dashboard_total_profit": "Profitto totale\n",
  "page_become_affiliate_partner_copy_link_header": "Il vostro link di affiliazione\n",
  "page_become_affiliate_partner_bonus_start": "Inizio della collaborazione\n",
  "page_become_affiliate_partner_bonus_tip": "<strong> Suggerimento! </strong> Utilizzate anche banner promozionali gi\u00e0 pronti in varie dimensioni e motivi",
  "page_become_affiliate_partner_promotional": "Mostra materiale promozionale\n",
  "document_issued_by_government": "Documento rilasciato dal governo\n",
  "document_back_side_scan": "Scansione del retro del documento\n",
  "document_front_side_scan": "Scansione del front del documento\n",
  "document_type": "Tipo di documento\n",
  "scan_quality_warning": "Sono richieste scansioni di buona qualit\u00e0 o foto completamente leggibili. Nessuna parte del documento pu\u00f2 essere modificata o rimossa.",
  " page_become_affiliate_partner_income_header": "Panoramica del reddito\n",
  "page_become_affiliate_partner_withdrawal_header": "Panoramica dei prelievi",
  "page_become_affiliate_partner_statistics_header": "Panoramica delle statistiche\n",
  "secondary_document": "Documento secondario\n",
  "selfie_with_id": "Selfie con carta d'identit\u00e0\n",
  "affiliate_withdraw_breadcrumb": "Prelievo\n",
  "affiliate_withdraw_header": "Prelievo del saldo affiliato\n",
  "affiliate_withdraw_info_alert": "Il saldo dell'affiliato \u00e8 {{bilancioCurrencyEur}}. Pu\u00f2 prelevare in una qualsiasi delle valute supportate. \u00c8 possibile vedere l'importo convertito dopo aver selezionato la valuta e il destinatario.\n",
  "bank_account_create_form_header": "Aggiungi un nuovo conto corrente {{currencyCode}}",
  "crypto_address_create_form_header": "Aggiungi un nuovo portafoglio {{currencyCode}}",
  "settings_social_create_password_button": "Imposta password",
  "recipient_page_table_name": "Nome",
  "recipient_page_table_address": "Indirizzo / Conto corrente bancario",
  "recipient_page_table_currency": "Currency",
  "recipient_page_table_header": "Elenco",
  "wallet_welcome_page": "Stai per andare su Simplecoin Wallet",
  "wallet_welcome_page_condions": "Accetto i termini e le <0>condizioni di Chainblock Wallet\n",
  "wallet_welcome_page_button": "Continua su Chainblock Wallet\n",
  "wallet_welcome_page_benefits_1": "Crea un portafoglio su Chainblock\n",
  "wallet_welcome_page_benefits_2": "Facile deposito di fondi\n",
  "wallet_welcome_page_benefits_3": "Trade di criptovalute pi\u00f9 facile e veloce\n",
  "wallet_welcome_page_benefits_4": "Commissioni di cambio pi\u00f9 vantaggiose\n",
  "wallet_welcome_page_benefits_5": "Assistenza prioritaria\n",
  "verification_transaction": "Transazione di verifica\n",
  "verification_call_info": "Nell'ambito del processo di verifica, dobbiamo verificare anche il vostro numero di telefono, quindi vi chiameremo per confermare che la verifica \u00e8 stata completata senza problemi. Potete scegliere l'orario pi\u00f9 comodo per la telefonata finale.\n",
  "verification_call": "Chiamata di verifica\n",
  "when_should_we_call": "Quando dovremmo chiamarvi\n",
  "asap": "Appena possibile\n",
  "email": "E-mail",
  "verification_and_limits": "Verifiche e limiti\n",
  "profile_verification": "Verifica del profilo\n",
  "limits": "Limiti\n",
  "start_with_verification": "Iniziare con la verifica\n",
  "verification_in_progress": "Verifica in corso\n",
  "level1": "Livello 1\n",
  "done": "Fatto\n",
  "ok": "OK",
  "profile_verification_done_info": "Vi abbiamo inviato per e-mail le informazioni sul pagamento di verifica, nel caso vogliate ricontattarle. Vi comunicheremo via e-mail che siete stati verificati.\n",
  "sidebar_navigation_portfolios": "Portafoglio\n",
  "page_dashboard_insert_deposit": "Inserire fondi\n",
  "page_trade_head_title": "Trade",
  "transaction_tabs_title_exchange": "Exchange",
  "transaction_tabs_title_deposit": "Deposito\n",
  "transaction_tabs_title_withdraw": "Prelievo\n",
  "transaction_tabs_title_transfer": "Trasferimento\n",
  "label_summary": "Riepilogo\n",
  "increase_limit": "Aumenta il limite\n",
  "read_only": "Solo lettura\n",
  "page_portfolio_header": "Portafoglio\n",
  "amount": "Importo",
  "purpose_of_crypto_trading": "Scopo del trading con le criptovalute\n",
  "what_is_purpose_of_crypto_trading": "Qual \u00e8 il suo scopo di fare trading con le criptovalute?\n",
  "get_familiar_with_cryptocurrencies": "Familiarizzare con le criptovalute\n",
  "occasional_buing_selling": "Acquisto/vendita occasionale\n",
  "one_off_investment": "Investimento una tantum\n",
  "regular_savings": "Risparmio regolare\n",
  "other": "Altro\n",
  "speculations": "Speculazioni\n",
  "describe_your_purpose_of_cryptotrading": "Descrivi il tuo scopo di fare trading con le criptovalute\n",
  "today": "oggi",
  "morning": "mattina\n",
  "evening": "sera",
  "afternoon": "pomeriggio\n",
  "tomorrow": "domani\n",
  "proof_of_income": "Prova di reddito\n",
  "proof_of_income_info": "La legge contro il riciclaggio di denaro e il finanziamento del terrorismo (AML) richiede di verificare la fonte del vostro reddito se volete operare con importi pi\u00f9 elevati. Pertanto, dobbiamo farvi alcune domande sull'origine del denaro che volete negoziare con noi.\n",
  "income": "Reddito",
  "one-off_income": "reddito una tantum\n",
  "monthly_income": "reddito mensile\n",
  "quarterly_income": "reddito trimestrale\n",
  "yearly_income": "reddito annuale\n",
  "frequency": "Frequenza\n",
  "source_of_income": "Fonte di reddito\n",
  "employment": "Occupazione\n",
  "position_in_company": "Posizione in azienda\n\n",
  "company_name": "Nome dell'azienda\n",
  "running_own_business": "Gestione di un'attivit\u00e0 in proprio\n",
  "rental": "Affitto",
  "share_of_profit": "Quota di profitto\n",
  "pension": "Pensione",
  "documents_supporting_the_income": "Documenti a sostegno del reddito\n",
  "income_source_description": "Descrizione",
  "add_income": "Aggiungi il prossimo reddito\n",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Prova del reddito di base\n",
  "proof_of_basic_income_info": "Per stabilire almeno un limite di base per il trading e il deposito, abbiamo bisogno di sapere se avete almeno un reddito minimo. Non c'\u00e8 alcun problema ad aumentare questo limite di base in un secondo momento, se si vuole fare trading \"a tutto gas\". Anche se non avete questo reddito, va bene lo stesso. In seguito descriverete la fonte dei fondi con cui volete fare trading e noi fisseremo il limite di conseguenza.\n",
  "step": "Step",
  "basic_income_agreement": "Confermo che il mio reddito corrisponde ad almeno 600 euro al mese\n",
  "semi-yearly_income": "\n",
  "incomes": "Redditi\n",
  "income_state_submited": "in attesa di approvazione\n",
  "income_state_approved": "approvato\n",
  "income_state_new": "nuovo",
  "updating_incomes_info": "Qualsiasi modifica dei vostri redditi (aggiunta di un nuovo reddito o cancellazione di uno esistente) deve essere valutata da noi e adegueremo di conseguenza il vostro limite di trading.\n",
  "submit_for_approval": "Sottoporre ad approvazione\n",
  "income_state_unapproved_change": "modifica non approvata\n",
  "prooved_incomes": "Redditi accertati\n",
  "verification_and_incomes": "Verifica e redditi\n",
  "trading_limits": "Limiti di trading\n",
  "current_allowed_trading_amount": "Importo consentito per il trading\n",
  "current_allowed_trading_amount_info": "Questo \u00e8 l'importo massimo che potete depositare in questo momento nel Portafoglio Chainblock negoziare su Chainblock Exchange. Questo importo cambia nel tempo a seconda della frequenza delle operazioni di trading. Pu\u00f2 diminuire gradualmente fino a zero o aumentare fino al limite massimo.\n",
  "individual_limits": "Limiti individuali\n",
  "time_period": "Periodo di tempo\n",
  "limit_extension": "Estensione del limite\n",
  "remaining_limit_amount": "Importo residuo che potete ancora prelevare dawn\n",
  "state_of_limit_execution": "Stato di esecuzione del limite\n",
  "in_time_period": "in",
  "individual_trading_limits_info": "Se si dispone di pi\u00f9 limiti individuali, viene applicato quello che consente di depositare/negoziare con l'importo pi\u00f9 elevato.\n",
  "trading_limit_extension_info": "Se il limite standard non \u00e8 sufficiente per voi, possiamo aumentare ulteriormente il vostro limite fornendo la prova di un reddito una tantum. Ridurremo gradualmente questo limite ogni volta che farete trading al di sopra del limite standard.\n",
  "income_state_deleted": "cancellato\n",
  "account_update_success": "L'account \u00e8 stato aggiornato\n",
  "account_label_edit": "Modifica account\n",
  "account_action_archive": "Archivio account\n",
  "waiting_for_payment": "in attesa del pagamento\n",
  "waiting_for_payment_approval": "in attesa dell'approvazione del pagamento\n",
  "verification_transaction_info": "Per un'identificazione remota riuscita, \u00e8 necessario verificare almeno uno dei vostri conti bancari (a vostro nome). A tal fine \u00e8 sufficiente ricevere un solo euro da questo conto.\n",
  "verification_transaction_received_info": "La transazione di verifica \u00e8 stata ricevuta. Dobbiamo solo verificare che provenga dal vostro conto bancario.\n",
  "verification_transaction_approved_info": "Il vostro conto bancario \u00e8 stato verificato. Possiamo quindi saltare questo passaggio.\n",
  "export_to_csv": "Esportazione in CSV\n",
  "new_account_button": "Crea un nuovo account",
  "remove_recipient": "Rimuovere il destinatario\n",
  "recipient_detail": "Dettaglio destinatario\n",
  "account_label": "Etichetta del conto\n",
  "veriffied": "verificato\n",
  "not_verified": "non verificato\n",
  "label_create_trade": "Creare trade\n",
  "remove_income": "Rimuovere il reddito\n",
  "transaction_detail_header": "Dettaglio della transazione\n",
  "transaction_type": "Tipo",
  "trade": "Trade",
  "withdraw_label_about": "Si sta per prelevare\n",
  "withdraw_summary_fees": "Riepilogo e spese\n",
  "Choose-destination-bank-account": "Scegliere il conto bancario di destinazione\n",
  "wallet_account_destination": "Scegliere l'indirizzo di destinazione {{valutaNome}}\n",
  "account_unique_name_label": "Fornisci un nome univoco per questo conto\n",
  "account_note_label": "\u00c8 possibile aggiungere qualsiasi nota aggiuntiva qui\n",
  "account_detail_suspend_alert": "Il vostro conto \u00e8 sospeso. Inviateci un'e-mail per ricevere assistenza.\n",
  "current_balance_label": "Saldo corrente\n",
  "balance_blocked_label": "Saldo bloccato\n",
  "label_not_choosen_account": "Non \u00e8 stato selezionato un conto\n",
  "label_not_enough_money": "I fondi sono insufficienti\n",
  "label_set_all_funds": "Imposta tutti i fondi\n",
  "label_available_balance": "Saldo disponibile\n",
  "label_withdraw": "Prelievo",
  "account_label_description": "Descrizione del conto\n",
  "label_balance_after_transaction": "Saldo dopo la transazione\n",
  "label_fee": "Fee",
  "label_counter_party": "Controparte\n",
  "label_operation_confirm_withdraw": "Confermare il prelievo\n",
  "label_operation_confirm_transfer": "Confermare il trasferimento\n",
  "label_operation_confirm_exchange": "Confermare lo scambio\n",
  "label_no_account_this_currency": "Non si dispone di un conto in questa valuta\n",
  "transaction_info_acc_head": "Importo e valuta\n",
  "label_from_account": "Dal conto\n",
  "label_target_account": "Conto di destinazione\n",
  "exchanges_balances_alert_fiat": "La conversione tra non-crypto non \u00e8 attualmente possibile\n",
  "account_create_success": "Il conto \u00e8 stato creato\n",
  "withdraw_label_requests": "Richieste di prelievo\n",
  "operations_label_dashboard_header": "Operazioni",
  "label_currency_general": "Valuta base\n",
  "income_state_draft": "Non Verificato",
  "label_client_currency_balance": "Bilancio in valuta base",
  "label_show_all": "Mostra tutto\n",
  "dashboard_balances_last_transactions": "Ultime transazioni\n",
  "dashboard_balances_no_accounts": "Non avete ancora un conto\n",
  "dashboard_balances_no_withdrawal_requests": "Nessuna richiesta di prelievo\n",
  "dashboard_balances_no_transaction": "Non ci sono ancora transazioni\n",
  "general_settings_label_base_currency": "Questa valuta sar\u00e0 utilizzata come predefinita in tutta l'applicazione.\n",
  "general_settings_header_base_currency": "Valuta di base\n",
  "page_portfolio_closed_suspend_accounts_header": "Conti sospesi e cancellati\n",
  "confirm_withdraw_button": "Prelievo",
  "button_withdraw_continue_to_confirmation": "Continua",
  "recipient_name": "Nome del destinatario\n",
  "button_create_transfer": "Crea trasferimento\n",
  "button_transfer_continue_to_confirmation": "Continua",
  "button_exchange_continue_to_confirmation": "Continua",
  "total_balance": "Bilancio Totale",
  "no_trading_limit_extension": "Non \u00e8 prevista un'estensione del limite di trading.\n",
  "deposit_component_automatic_create_acc_label": "Non hai ancora un conto Chainblock in questa valuta. Lo creeremo automaticamente per voi.\n",
  "deposit": "Deposito",
  "withdraw": "Prelievo",
  "create_default_account_label": "Conto predefinito per\n",
  "create_default_account_description": "Conto creato automaticamente quando si depositano fondi\n",
  "create_default_account_button": "Crea conto {{currencyCode}} predefinito\n",
  "dashboard_blog_posts_header": "Ultimi messaggi del blog\n",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "Importo accreditato\n",
  "exchange_rate": "Tasso di cambio\n",
  "accounting_time": "Tempo di contabilit\u00e0\n",
  "date": "Data",
  "amounts": "Importo",
  "success_order_header": "Cosa fare ora? Pagare per completare l'ordine.\n",
  "success_order_email": "Le abbiamo inviato le istruzioni per il pagamento via e-mail <0>{{email}}.\n",
  "finop_type_DEPOSIT": "deposito",
  "finop_type_EXCHANGE": "scambio",
  "finop_type_TRANSFER": "trasferimento\n",
  "finop_type_WITHDRAWAL": "prelevare",
  "exchange_made": "Scambio riuscito",
  "from_account": "Dal conto\n",
  "to_account": "Al conto\n",
  "accounts": "Conto corrente\n",
  "portfolio_balance_summary_fiat_header": "Fiat",
  "portfolio_balance_summary_crypto_header": "Cryptomonete:",
  "order_success_check_crypto": "Verificare che l'indirizzo del portafoglio sia corretto.",
  "success_order_label_id": "Il numero d'ordine \u00e8 <0>{{ordine_id}} creato. Grazie!\n",
  "exchange_form_crypto_address_field_label": "Indirizzo criptovaluta",
  "order_success_check_bank": "Controllare se il conto bancario \u00e8 corretto.\n",
  "other_currencies": "Altre valute\n",
  "no_funds_in_portfolio": "Non ci sono fondi in portafoglio.\n",
  "exchange_form_register": "per limiti pi\u00f9 elevati.\n",
  "list": "Elenco",
  "withdrawal_request_status_new": "In attesa\n\n",
  "withdrawal_request_status_confirming_outgoing": "Spedito\n",
  "withdrawal_request_status_done": "Fatto",
  "withdrawal_request_status_canceled": "Annullato",
  "landing_main_header": "<0>Il pi\u00f9 semplice exchange di criptovalute italiano",
  "next_landing_subheader": "Scambio di criptovalute <0>dal 2013.",
  "header_link_about_us": "Chi siamo",
  "header_link_get_started": "Iniziare",
  "header_link_blog": "Blog",
  "header_service_hours": "Lun-Dom 9-21",
  "buy_now_button": "Compra ora",
  "chart_header": " Comprare e vendere",
  "exchange_form_label": " Comprare e vendere",
  "how_it_works_paragraph_1_content": "Ci vuole solo un minuto per effettuare un ordine. Fino a 200 \u20ac / 10 giorni, tutto ci\u00f2 di cui abbiamo bisogno \u00e8 il tuo indirizzo e-mail e il tuo portafoglio di criptovalute. Per importi superiori \u00e8 necessario registrarsi e verificare.\n",
  "how_it_works_paragraph_1_heading": "Ordine",
  "how_it_works_paragraph_2_heading": "Pagamenti",
  "how_it_works_paragraph_3_heading": "Ricevere criptovalute",
  "how_it_works_paragraph_2_content": "Una volta completato l'ordine, vi forniremo le istruzioni per il pagamento.",
  "how_it_works_paragraph_3_content": "Una volta ricevuto il pagamento, invieremo la criptovaluta al vostro portafoglio. Di solito lo facciamo entro 30 minuti!",
  "landing_support_section_paragraph": "Avete bisogno di consigli sullo scambio di criptovalute? Potete sempre scriverci, telefonarci o inviarci un ticket. La maggior parte delle richieste viene gestita tempestivamente.",
  "landing_support_section_heading": "Persone reali , assistenza clienti reale",
  "contact_us_button": "Contatto",
  "landing_advantages_1_heading": "Commercio di criptovalute semplice e sicuro",
  "landing_advantages_1_text": "Ci vuole solo un minuto per creare un ordine. Una volta che i fondi sono stati accreditati sul nostro conto, verificheremo manualmente la transazione e vi invieremo la criptovaluta entro 30 minuti in media.",
  "landing_advantages_2_heading": "Persone reali, assistenza clienti umana\n",
  "landing_advantages_3_heading": "La pi\u00f9 antica azienda Italiana \n",
  "landing_advantages_2_text": "Avete bisogno di consigli sullo scambio di criptovalute? Potete sempre scriverci, telefonarci o inviarci un ticket. La maggior parte delle richieste viene gestita tempestivamente.\n",
  "landing_advantages_3_text": "Scambiamo criptovalute dal 2014. Abbiamo scambiato criptovalute per pi\u00f9 di 10 mila persone, per un valore  milioni di EURO.",
  "ckma_founder": "Membro fondatore di\n",
  "newsletter_field_placeholder": "Il tuo indirizzo e-mail\n",
  "footer_sub_header_newsletter_subscription": "Iscriviti alle notizie\n",
  "footer_link_career": "Lavoro\n",
  "footer_link_affiliate_program": "Programma di affiliazione",
  "footer_link_privacy_notice": "Privacy",
  "footer_link_terms_and_conditions": "Termini e condizioni",
  "footer_link_contact": "Contatti",
  "footer_link_limits_and_verification": "Limiti e verifica del conto\n",
  "footer_link_faq": "Domande frequenti\n",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "Abbiamo scambiato milioni di euro in crypto dal 2013.\n",
  "landing_bottom_section_header_trade_easily": "Scambiate monete con facilit\u00e0\n",
  "landing_bottom_section_button_buy_coins": "Acquistare criptovalute\n",
  "how_it_works_header": "Come funziona?\n",
  "landing_wrote_about_us_header": "Scrivete di noi\n",
  "landing_last_orders_header": "Ultime transazioni\n",
  "header_faq": "Le domande pi\u00f9 frequenti\n",
  "faq_general_questions_header": "Generale\n",
  "log_in_button": "Entrare",
  "header_button_buy_crypto": "Acquistare criptovalute",
  "header_short_button_buy": "Acquista",
  "search_button": "Ricerca",
  "header_support": "Supporto",
  "send_button": "Inviare",
  "email_subscribe_header": "Iscriviti per ricevere notizie dal mondo delle criptovalute\n",
  "affiliate_advantage_payout_anytime": "Ricevere il pagamento \u00e8 possibile in qualsiasi momento",
  "affiliate_advantage_banner": "Banner per il vostro sito web o blog\n",
  "affiliate_advantage_referral_link": "Il proprio link di riferimento\n",
  "affiliate_advantage_fiat_or_crypto": "Essere pagati in criptovaluta o in valuta fiat\n",
  "affiliate_advantage_cookie": "Cookies per {{affiliateCookieValidityDays}} giorni\n",
  "affiliate_advantage_reward_from_margin": "{{affiliateMarginReward}}% di ricompensa dal nostro margine\n",
  "affiliate_header": "Programma di affiliazione\n",
  "affiliate_subheader": "Diventa un partner affiliato di Chainblock e ricevi una commissione per ogni scambio effettuato da persone da te segnalate.\n",
  "header_earn_with_us": "Guadagna con {{marchio}}\n",
  "create_account_button": "Iscriviti",
  "affiliate_how_it_works_header": "Come funziona?\n",
  "affiliate_how_it_works_step_1_header": "Crea un account e iscriviti al nostro programma di affiliazione.\n",
  "affiliate_how_it_works_step_2_header": "Verr\u00e0 creato un link unico per te\n",
  "affiliate_how_it_works_step_3_text": "Ogni persona che arriva al sito Chainblock tramite il tuo link riceve un cookie Se effettuano un ordine entro 7 giorni, guadagnerai un profitto pari al {{affiliateMarginReward}}% del nostro margine. Guadagnerete questi rendimenti per ogni ordine che egli negozia con noi. Per gli ordini creati dopo il limite di 7 giorni, il link non \u00e8 pi\u00f9 attivo\n",
  "affiliate_how_it_works_step_2_text": "Avete a disposizione un URL dei banner che potete condividere sul vostro sito web, blog, social media o con i vostri amici. Chiunque utilizzi questo link e poi si iscriva o crei un ordine entro 7 giorni sar\u00e0 inserito nel vostro programma di affiliazione per due anni.\n",
  "affiliate_how_it_works_step_1_text": "Per aderire al programma di affiliazione sono necessarie sia la registrazione che la verifica. La registrazione consente di accedere all'amministrazione e di gestire il proprio account. La verifica \u00e8 necessaria per il pagamento dei premi. Ci atteniamo alle buone maniere e non incoraggiamo pratiche o rapporti sleali. Ci aspettiamo lo stesso dai nostri partner\n",
  "affiliate_how_it_works_step_4_text": "Nell'amministrazione del vostro programma di affiliazione potete vedere in qualsiasi momento quanto avete guadagnato. Quando lo desiderate, potete scegliere l'importo in criptovaluta o in valuta fiat. Invieremo il pagamento al vostro conto bancario o al vostro portafoglio di criptovalute.\n",
  "affiliate_how_it_works_step_3_header": "Riceverete il {{affiliateMarginReward}}% del nostro margine di profitto per ogni cliente che porterete.\n\n",
  "affiliate_how_it_works_step_4_header": "Puoi ritirare i tuoi guadagni in qualsiasi momento\n\n",
  "limits_and_verification_header": "Limiti e verifica\n",
  "limits_and_verification_subheader": "Per motivi legali, come tutti gli altri exchange, dobbiamo <0>verificare ogni transazione che superi un certo limite. Se questo \u00e8 il vostro caso, potete facilmente registrarvi e far verificare il vostro conto. Per informazioni sui limiti e sulla verifica, consultare i riquadri sottostanti.\n",
  "limits_table_level_0_header": "fino a {{LimiteLivello0Eur}} \u20ac\n",
  "limits_table_level_0_subheader": "per {{limiteLivello0DurataGiorni}} giorni\n",
  "limits_table_registration": "Registrazione e identificazione obbligatoria\n",
  "limits_table_row_2_documents_photo": "Foto di 2 documenti personali\n",
  "limits_table_row_selfie": "Selfie con documento\n",
  "limits_table_row_verification_bank_transfer": "Bonifico bancario di verifica\n",
  "limits_table_row_verification_phone_call": "Telefonata di verifica\n",
  "limits_table_row_purpose_od_crypto_purchase": "Scopo dell'acquisto di criptovalute\n",
  "limits_table_row_source_of_income": "Fonte di reddito\n",
  "limits_table_row_individual_limits_base_on_income_source": "Limiti individuali in base alla fonte di reddito\n",
  "limits_table_level_1_header": "{{limiteLivello0Eur}} - {{limiteLivello1Eur}} \u20ac\n",
  "limits_table_level_1_subheader": "per {{limiteLivello1DurataGiorni}} giorni\n",
  "limits_table_level_2_subheader": "per {{limiteLivello2DurataGiorni}} giorni\n",
  "limits_table_level_2_header": "oltre {{limitLevel2EurStart}} \u20ac\n",
  "footer_mobile_navigation_expand_label": "Navigazione nel sito\n",
  "support_search_placeholder": "Cosa vorresti sapere?\n",
  "error_404_header": "Non siamo riusciti a trovare questa pagina\n",
  "error_404_subheader": "Ma puoi continuare a...\n",
  "error_page_contact_us": "Se pensi che si tratti di un errore, scrivici a {{supportEmail}}. Grazie!\n",
  "link_blog_label": "Cosa c'\u00e8 di nuovo su Chainblock\n",
  "link_contact_label": "Contattateci\n",
  "link_faq_label": "Trova le risposte qui\n",
  "about_page_recommendations_section_header": "Consigliato da\n",
  "cookie_dialog_text_content": "{{brand}} utilizza i cookie per rendere pi\u00f9 confortevole l'utilizzo dei nostri servizi e per migliorare costantemente l'esperienza degli utenti. Di seguito \u00e8 possibile scegliere quali tipi di cookie verranno utilizzati. Per maggiori informazioni leggi la nostra <0>politica sui cookie\n",
  "cookie_dialog_necessary_cookies_header": "Cookie necessari\n",
  "cookie_dialog_necessary_cookies_text": "Cookie necessari per il funzionamento del sito web.\n",
  "cookie_dialog_necessary_cookies_always_active": "Sempre attivi\n",
  "cookie_dialog_statistical_cookies_header": "Cookie statistici\n\n",
  "cookie_dialog_statistical_cookies_text": "Cookie che ci aiutano a misurare e a capire come gli utenti utilizzano i nostri servizi.\n",
  "cookie_dialog_marketing_cookies_header": "Cookie di marketing\n",
  "cookie_dialog_marketing_cookies_text": "\n",
  "cookie_dialog_personalization_cookies_header": "Cookie di personalizzazione\n",
  "cookie_dialog_personalization_cookies_text": "Cookie grazie ai quali non sar\u00e0 necessario impostare alcune cose due volte.\n",
  "cookie_dialog_accept_all_button": "Accettare tutto\n",
  "cookie_dialog_confirm_choices_button": "Conferma le mie scelte\n",
  "cookie_dialog_header": "Le vostre preferenze sui cookie\n",
  "support_label_breadcrumbs_navigation": "Aiuto",
  "buy_currency_button": "Acquista {{currencyName}}",
  "exchange_form_get_wallet_help": "Nessun portafoglio?",
  "scan_address_qr_code": "Scansiona il QR",
  "exchange_form_crypto_address_field_placeholder": "bc1...",
  "order_form_email_and_account_step_label": "Informazioni sull'ordine",
  "order_form_amounts_step_label": "Importo e valuta",
  "order_form_header": "Ordine",
  "landing_login_dialog_title": "Vi presentiamo Chainblock pro. Una nuova piattaforma con commissioni pi\u00f9 basse e scambi di valuta istantanei. Non c'\u00e8 bisogno di un portafoglio di criptovalute.",
  "landing_login_dialog_pro_header": "Prova Chainblock Pro\n",
  "landing_login_dialog_exchange_header": "Vai a Chainblock Exchange\n",
  "landing_login_dialog_exchange_text": "Il semplice servizio di scambio di criptovalute che conoscete e amate\n",
  "landing_login_dialog_pro_text": "Prova Pro per un'esperienza di trading ancora migliore\n",
  "landing_login_dialog_pro_login_button": "Accedi a Pro\n",
  "landing_login_dialog_exchange_login_button": "Accedi a Exchange\n",
  "about_us_section_small_header": "Chi siamo",
  "about_us_section_1_header": "Rendiamo il mondo dell'acquisto o della vendita di <0>Bitcoin</0> e di altre valute digitali <0>pi\u00f9 facile</0>.",
  "about_us_section_1_subheader": "Il mondo delle criptovalute cancella i confini e vi offre una vera libert\u00e0 e una nuova prospettiva sulla finanza.\n",
  "about_us_section_2_header": "Siamo esperti di criptovalute\n",
  "about_us_section_2_subheader": "Aiutiamo a riportare il denaro nelle mani delle persone normali\n",
  "about_us_section_2_content": "Chainblock \u00e8 prima di tutto un'azienda tecnologica che si affida all'esperienza dei suoi programmatori. Investiamo tempo e attenzione specificamente in un elevato standard di sicurezza per i conti e i dati dei clienti.\n",
  "about_us_section_3_header": "Abbiamo reso facile l'acquisto di criptovalute <0>dal 2014\n",
  "about_us_section_3_text": "Fin dall'inizio siamo stati una societ\u00e0 Italiana con una chiara struttura proprietaria. Chiunque pu\u00f2 commerciare criptovalute. Offriamo un'altra possibilit\u00e0 di lavorare con le finanze che non sono gestite dalle banche e dallo Stato. Vi offriamo opzioni che potete gestire da soli. Mostriamo alle persone che il mondo delle criptovalute \u00e8 semplice.\n",
  "about_us_section_4_header": "Tecnologia moderna e sicurezza\n",
  "about_us_section_4_subheader": "Ci prendiamo cura dell'elevata sicurezza dei conti degli utenti.\n",
  "about_us_section_4_text": "Chainblock \u00e8 innanzitutto un'azienda tecnologica. Basiamo la nostra esperienza sulle conoscenze specialistiche dei nostri programmatori. Investiamo tempo e attenzione in un elevato livello di sicurezza per i conti e i dati dei nostri clienti.\n",
  "contact_header": "Contattateci",
  "contact_page_email_label": "E-mail\n",
  "contact_page_phone_label": "Telefono",
  "contact_page_customer_center": "Centro clienti\n",
  "contact_page_billing_info": "Informazioni sulla fatturazione\n",
  "support_search_result_count": "{{risultatiCount}} risultati\n",
  "limits_table_item_not_needed": "No",
  "limits_table_item_required": "Richiesto\n",
  "how_to_get_started_step_1_name": "Step1\n",
  "how_to_get_started_step_1_header": "\u00c8 necessario un portafoglio crypto\n",
  "how_to_get_started_step_1_cta": "Come configurare un portafoglio di criptovalute\n",
  "how_to_get_started_step_1_text": "Il portafoglio viene utilizzato per memorizzare le criptovalute. Ne avrete bisogno quando fate un acquisto. Se non avete un portafoglio, potete scaricarlo come app sul vostro cellulare o computer. Vi mostriamo come fare.\n",
  "how_to_get_started_step_2_name": "Step2",
  "how_to_get_started_step_2_header": "Iscriviti",
  "how_to_get_started_step_2_subheader": "Ha i suoi vantaggi",
  "how_to_get_started_step_2_text": "\u00c8 necessario un indirizzo e-mail e una password sicura. \u00c8 possibile effettuare un ordine senza registrarsi, ma si perder\u00e0 lo sconto sul volume e la possibilit\u00e0 di gestire l'ordine nel proprio account utente.",
  "how_to_get_started_step_3_name": "Step3",
  "how_to_get_started_step_3_header": "Crea un ordine",
  "how_to_get_started_step_3_subheader": "Ora basta un minuto",
  "how_to_get_started_step_3_text": "Compilate il modulo d'ordine - avete gi\u00e0 tutto ci\u00f2 che vi serve (indirizzo e-mail e indirizzo del portafoglio di criptovalute).  Vi invieremo le istruzioni per il pagamento via e-mail. Una volta verificato l'accredito dei fondi, vi invieremo la valuta desiderata. Di solito possiamo farlo entro 30 minuti.",
  "how_to_get_started_step_3_hint": "Prestare attenzione alle istruzioni di pagamento. Ricordate di inserire il testo nel messaggio al destinatario come indicato. Questo ci aiuter\u00e0 a elaborare l'ordine pi\u00f9 velocemente.",
  "how_to_get_started_step_4_name": "come_iniziare_il_passo_4_nome",
  "how_to_get_started_step_4_header": "come_iniziare_il_passo_4_intestazione",
  "how_to_get_started_step_4_subheader": "come_iniziare_il_passo_4_sottointestazione",
  "how_to_get_started_step_4_text": "come_iniziare_il_passo_4_testo",
  "how_to_get_started_step_4_cta": "come_iniziare_il_passo_4_cta",
  "landing_clients_since_2013": "clienti dal 2013\n",
  "landing_monthly_volume": "del volume negli ultimi 30 giorni\n",
  "landing_exchanges_per_month": "scambi negli ultimi 30 giorni\n",
  "cookie_bar_header": "<0>Chainblock utilizza i cookie per funzionare correttamente\n",
  "cookie_bar_text": "Utilizziamo i cookie per il login, la navigazione e l'analisi del traffico. Vi ringraziamo per il vostro consenso al loro utilizzo. Selezionare i cookie.",
  "cookie_bar_agree_and_continue_button": "Accetta e continua\n",
  "cookie_bar_manage_cookie_settings_button": "Preferenze per i cookie\n",
  "order_detail_info_header": "Dettagli dell'ordine\n",
  "order_detail_order_id": "Numero dell'ordine\n",
  "order_detail_created_at": "Creato a\n",
  "order_detail_status": "Stato dell'ordine\n",
  "order_detail_rate": "Tariffa dell'ordine\n",
  "order_detail_button_cancel": "Cancellare\n",
  "order_detail_button_suspend": "Sospendere\n",
  "order_detail_button_download": "Scarica in PDF\n",
  "order_detail_button_new_ticket": "Creare un ticket\n",
  "order_detail_history_table_header": "Cronologia dell'ordine\n",
  "order_history_datetime_column_name": "Data e ora\n",
  "order_history_status_column_name": "Stato dell'ordine\n",
  "order_detail_outgoing_payment_label": "Pagamento in uscita\n",
  "order_detail_incoming_payment_label": "Il vostro pagamento\n",
  "order_detail_incoming_payment_waiting_for_transaction": "La transazione non \u00e8 ancora stata rilevata\n",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "Siamo in attesa del vostro pagamento\n",
  "order_payment_instructions_header": "Invia il pagamento con i seguenti dati\n",
  "order_form_payment_instructions_bank_account_number_label": "Numero del conto bancario",
  "order_form_payment_instructions_bank_amount_label": "Importo",
  "order_form_payment_instructions_bank_message_label": "Messaggio di pagamento\n",
  "order_form_payment_instructions_use_qr": "Oppure scansionare il seguente codice QR\n",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Conto aziendale\n",
  "order_status_done": "Fatto",
  "order_status_delivered": "Inviato",
  "order_status_canceled": "Cancellato ",
  "order_status_returned": "Restituito",
  "order_status_in_progress": "In corso\n",
  "order_status_confirming_incoming": "Conferma in entrata",
  "order_status_confirming_outgoing": "Conferma in uscita\n",
  "order_status_new": "Nuovo",
  "order_status_suspended": "Sospeso\n",
  "order_status_received": "Ricevuto",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "La transazione non \u00e8 ancora stata rilevata",
  "show_payment_instructions_dialog_opener_button": "Mostra le istruzioni di pagamento",
  "support_section_header": "Non avete trovato la risposta?",
  "support_section_text": "Il servizio clienti pu\u00f2 essere contattato dal luned\u00ec alla domenica tra le 9:00 e le 21:00. Oppure inviateci un'e-mail. I nostri colleghi saranno sempre lieti di consigliarvi e guidarvi con sicurezza nell'acquisto.",
  "label_crypto_sent_to_address": "Indirizzo",
  "label_transaction_id": "Transazione ID",
  "order_detail_button_proceed": "Procedi",
  "label_amount_and_currency": "Importo",
  "label_payment_sent_at": "Creato",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "La transazione in arrivo \u00e8 in fase di conferma",
  "order_detail_outgoing_payment_order_suspended": "L'ordine \u00e8 sospeso, in attesa della vostra decisione. ",
  "order_detail_outgoing_payment_order_return_to_client": "Il pagamento verr\u00e0 restituito",
  "order_form_payment_instructions_company_billing_details": "Informazioni sulla fatturazione\n",
  "order_has_been_created": "Ordine creato con successo\n",
  "fiat_payment_instructions_amount_label": "Importo",
  "order_detail_button_return": "Ritorno",
  "order_view_original_price_label": "Offerta precedente",
  "order_view_limit_price_label": "Prezzo limite",
  "order_view_current_price_label": "Offerta attuale",
  "cryypto_payment_instructions_amount_label": "Importo",
  "payment_instruction_hint_fill_amount_to_show_qr": "Compilare l'importo depositato per generare il codice QR\n",
  "deposit_payment_instruction_amount_label": "Importo da depositare\n",
  "form_email_field": "Email\n",
  "confirm_button": "Confermare",
  "email_placeholder": "Email",
  "change_in_24hours": "Variazione nelle ultime 24 ore\n",
  "order_offer_amounts_label": "La tua offerta\n",
  "order_offer_your_crypto_address": "Il tuo indirizzo di criptovaluta\n",
  "order_offer_continue_to_login": "Continua con il conto {{aziendaNome}}\n",
  "order_offer_or_separator": "oppure",
  "order_offer_continue_without_registration": "Continua senza registrazione\n",
  "order_offer_validity_notice": "Questa offerta scadr\u00e0 tra {{tempoRimanente}}. Effettua il login o continua con l'email.\n",
  "order_offer_continue_as_guest": "Continua come ospite\n",
  "order_offer_existing_email_please_click_continue_button": "Questo indirizzo e-mail \u00e8 gi\u00e0 registrato. Scegliere l'opzione \"Continua con l'account {{nomeazienda}}\".\n",
  "landing_header_references": "Riferimenti",
  "crypto_payment_instructions_amount_label": "Importo",
  "crypto_payment_instructions_destination_tag_field_label": "Tag di destinazione",
  "order_form_mail_help_text": "Ti invieremo a questo indirizzo e-mail le istruzioni per completare l'ordine.\n",
  "validation_required_email": "Inserisci il tuo indirizzo e-mail\n",
  "validation_required_crypto_account": "Inserisci il tuo conto crypto",
  "landing_help_section_header": "Qui troverete le risposte alle domande pi\u00f9 comuni. Oltre alle istruzioni scritte, possiamo aiutarvi anche sulla nostra linea di assistenza clienti al numero +420 608 746 753 o via e-mail all'indirizzo info@simplecoin.eu, dal luned\u00ec alla domenica dalle 9.00 alle 21.00. Possiamo guidarvi nel vostro primo acquisto, aiutarvi a ordinare o a registrarvi, consigliarvi su come impostare il vostro portafoglio o rispondere alle vostre domande. Sentitevi liberi di chiederci qualsiasi cosa.",
  "guest_exchange_form_limit_alert": "L'acquisto senza registrazione \u00e8 possibile fino a {{code}} {{limite}}. Per importi superiori, effettuare il login o registrarsi.",
  "exchange_form_email_label": "La tua e-mail",
  "exchange_form_alert_change": "Il tasso di cambio tra queste valute non pu\u00f2 essere negoziato. Si prega di modificare la selezione della valuta.",
  "welcome_in_wallet": "Benvenuti nel portafoglio!\n",
  "start_with_funding_text_2": "Siete stati verificati. Ora potete depositare i fondi e iniziare a fare trading.\n",
  "start_with_funding_text_1": "Bene!",
  "amount_in_fiat_payment_instructions_info": "v",
  "limit_table_level_0_text": "Potete acquistare fino a \u20ac{{limitLevel0Eur}} entro 10 giorni senza registrazione e senza dover verificare i documenti personali.",
  "label_go_to_order": "Crea ordine",
  "label_register": "Registrazione",
  "account_detail": "Dettaglio del conto",
  "signup_form_subtitle": "Otterrete un tasso migliore e limiti di trading pi\u00f9 elevati. Avrete una panoramica delle vostre transazioni.\n",
  "label_guest_login": "Se hai gi\u00e0 un conto, <0>accedi\n",
  "label_guest_signup": "Se non hai ancora un account, <0>registrati\n",
  "label_register_success": "La registrazione \u00e8 avvenuta con successo\n",
  "label_register_success_verify": "Attiva ora il tuo account\n",
  "label_register_success_sended_verify": "Le abbiamo inviato un link per attivare il suo account all'indirizzo e-mail {{mail}}.",
  "label_account_notes": "Note sul conto",
  "deposit_request_card_head": "Richieste di deposito\n",
  "dashboard_balances_no_deposit_requests": "Nessuna richiesta di deposito\n",
  "deposit_request_status_pending": "In attesa\n",
  "exchange_form_terms_must_be_accepted": "I termini e le condizioni devono essere accettati\n",
  "validation_required_bank_account": "Inserire il numero di conto corrente bancario\n",
  "order_status_dispatched": "Spedito",
  "order_status_paid": "Pagato",
  "order_status_paidUnconfirmed": "Pagato (1/2)\n",
  "order_status_tooltip_new": "L'ordine \u00e8 in attesa di essere pagato.\n",
  "order_status_tooltip_paidUnconfirmed": "Vediamo una transazione in arrivo. \u00c8 in attesa di conferma sulla block-chain.\n",
  "order_status_tooltip_paid": "L'ordine \u00e8 stato pagato. Stiamo lavorando per spedire la valuta ordinata.\n",
  "order_status_tooltip_paid_suspended": "L'ordine \u00e8 stato pagato.\n",
  "order_status_tooltip_dispatched": "Abbiamo inviato la valuta ordinata all'indirizzo/conto di destinazione. Siamo in attesa della conferma da parte della blockchain/banca.\n",
  "order_status_tooltip_done": "L'ordine \u00e8 stato elaborato con successo.\n",
  "order_status_tooltip_canceled": "L'ordine \u00e8 stato annullato.\n",
  "suspended_order_tooltip": "L'elaborazione dell'ordine \u00e8 sospesa.\n",
  "order_detail_outgoing_payment_waiting_to_be_sent": "Stiamo lavorando per inviare i fondi ordinati il prima possibile.\n",
  "exchange_limit_for_verification_level_2_text": "Il vostro limite di trading non \u00e8 formalmente stabilito. Per importi elevati, sar\u00e0 valutato individualmente in base al vostro <0>reddito documentato.\n",
  "your_limit_is": "Il vostro limite \u00e8\n",
  "trading_limit": "Limite di trading\n",
  "exchange_trading_limit_info": "Il limite viene adeguato automaticamente dopo aver ricevuto il pagamento dell'ordine. I nuovi ordini non pagati non influiscono sul limite.\n",
  "you_have_no_suitable_wallet_among_recipients": "Non hai un portafoglio per la valuta {{currencyCode}} tra i tuoi destinatari.\n",
  "you_have_no_suitable_account_among_recipients": "I destinatari non dispongono di un conto bancario per la valuta {{currencyCode}}.\n",
  "rate_when_order_created": "Tasso al momento della creazione dell'ordine\n",
  "order_status_proceed": "Procedere all'elaborazione\n",
  "waiting_for_limit": "In attesa del limite\n",
  "button_more": "Altro",
  "exchange_form_expand_optional_fields": "Espandi i campi opzionali\n",
  "return_fiat_payment_info": "Abbiamo gi\u00e0 ricevuto il pagamento. Avete deciso di restituirlo. Ma il pagamento di ritorno ha un costo aggiuntivo. \u00c8 possibile ottenere {{importo}} {{valuta}} sul conto bancario originale.\n",
  "cancel_order_and_return_payment": "Annullare l'ordine e restituire il pagamento\n",
  "remaining_limit": "Rimanente",
  "trading_limit_reached": "Avete raggiunto il vostro limite di trading per un determinato periodo.\n",
  "attached_files": "File allegati",
  "there_are_no_files": "Non ci sono file caricati",
  "why_we_need_verification_info": "The anti money laundering law (AML) requires us to know our clients better. Therefore, it is necessary for you to tell us some information about yourself, which of course remains safely stored only with us.",
  "proceed_to_verification_action": "Procedere alla verifica",
  "verification_request_line2": "La nostra guida prevede alcuni passaggi e poi potrete utilizzare appieno il nostro portafoglio.",
  "verification_request_line1": "Ora dobbiamo conoscerci un po' meglio",
  "document_issued_by_government_info": "Allegare un documento rilasciato dal governo che confermi le informazioni fornite nella fase precedente.",
  "secondary_document_info": "Allegare un altro documento che confermi le informazioni fornite nella prima fase.",
  "selfie_with_id_info": "Si prega di allegare una foto in cui si tiene in mano il documento personale. I dati del documento devono essere leggibili e il vostro volto deve essere visibile.",
  "understood_button": "Capito",
  "no_prooved_incomes_info": "Non ci sono ancora entrate dimostrate. \u00c8 ancora possibile negoziare criptovalute fino al limite di base (10.000 EUR/mese per i clienti verificati). Per aumentare il limite di trading oltre i 10.000 euro/mese, \u00e8 necessario fornire una prova di reddito.",
  "verified": "Verificato",
  "payment_instructions": "Istruzioni di pagamento\n",
  "make_payment_to_one_of_our_accounts": "Effettuare il pagamento su uno dei nostri conti",
  "payment_in": "Pagamento in",
  "iban_payment_instructions": "Istruzioni di pagamento IBAN",
  "new_price_is_being_loaded": "Il nuovo prezzo \u00e8 in fase di caricamento...",
  "price_refresh_timer_tooltip": "Aggiorniamo il prezzo e il tasso visualizzato ogni pochi secondi.",
  "send_to_address": "Invia all'indirizzo",
  "payment_specific_data": "Dati di pagamento",
  "bank_account_owner": "Proprietario del conto bancario",
  "filter_all_currencies": "Tutte le valute",
  "ticket_detail": "Dettaglio biglietto",
  "last_verification_step_warning": "Questa \u00e8 l'ultima fase del processo di verifica. Una volta inviato, non sar\u00e0 pi\u00f9 possibile modificare le informazioni inserite. Il vostro profilo sar\u00e0 verificato dal nostro personale di back-office.",
  "exchange_form_limit_alert": "L'ordine di questo importo supera gi\u00e0 il limite di trading consentito {{limite}}. {{codice}}. \u00c8 ancora possibile creare l'ordine, ma non verr\u00e0 elaborato finch\u00e9 non si verificher\u00e0 la propria persona o non si fornir\u00e0 la prova di un reddito adeguato.",
  "order_limit_alert": "Quando pagherete per questo ordine, supererete il vostro limite di trading consentito e l'ordine non verr\u00e0 elaborato fino a quando non vi sarete verificati o avrete fornito la prova di un reddito adeguato.",
  "remove_recipient_confirmation_question": "Volete davvero eliminare questo destinatario? Non sar\u00e0 possibile annullare questa azione.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Il campo '{{fieldName}}' \u00e8 obbligatorio",
  "inheritance": "Eredit\u00e0",
  "relationship_to_testator": "Rapporto di parentela con l'intestatario",
  "inherited_from": "Ereditato da (nome)",
  "landing_how_to_get_started_button": "Come iniziare",
  "affiliate_partners_must_be_verified": "Per diventare nostro partner affiliato, dobbiamo prima raccogliere alcune informazioni su di te.\n",
  "regular_income": "Reddito regolare\n",
  "tax_declaration": "Dichiarazione dei redditi",
  "annual_report": "Relazione annuale\n\n",
  "date_of_acceptance": "Data dell'accettazione",
  "document_confirming_acceptance_of_inheritance": "Documento che conferma l'accettazione dell'eredit\u00e0\n",
  "financial_closing_statement": "Dichiarazione di chiusura finanziaria\n",
  "decision_on_sharing_profit": "Decisione di ripartizione dell'utile\n",
  "sale_contract": "Contratto di vendita\n",
  "real_estate_registry_extract": "Estratto del registro immobiliare\n",
  "typical_income_attachments": "Allegati tipici\n",
  "sale_of_immovable_property": "Vendita di un bene immobile\n",
  "address_of_property": "Indirizzo del bene\n",
  "date_of_sale": "Data di vendita\n",
  "sale_channel": "Canale di vendita / Modalit\u00e0 di vendita",
  "paycheck": "Pagamenti",
  "employment_contract": "Contratto di lavoro",
  "field_of_business": "Settore di attivit\u00e0",
  "company_id": "ID azienda",
  "monetary_donation": "Donazione in denaro",
  "donor": "Donatore",
  "donor_relationship_to_recipient": "Rapporto di parentela tra donatore e ricevente",
  "purpose_of_donation": "Scopo della donazione",
  "source_of_donated_funds": "Fonte dei fondi donati",
  "email_subscribe_success": "L'iscrizione alla nostra newsletter \u00e8 avvenuta con successo.",
  "landing_index_meta_tag_title": "Chainblock - Il pi\u00f9 semplice exchange di criptovalute ",
  "landing_index_meta_tag_description": "Trusted dal 2013",
  "landing_how_to_get_started_meta_tag_title": "Chainblock - Come iniziare ad acquistare criptovalute",
  "landing_how_to_get_started_meta_tag_description": "Una semplice guida passo-passo per tutti",
  "landing_about_meta_tag_description": "Siamo esperti di criptovalute",
  "landing_about_meta_tag_title": "Chainblock - Chi siamo",
  "landing_faq_meta_tag_description": "Di cosa ho bisogno per acquistare criptovalute? Cosa ottengo iscrivendomi?",
  "landing_faq_meta_tag_title": "Chainblock - FAQ",
  "landing_support_meta_tag_title": " Chainblock - Help",
  "landing_support_meta_tag_description": "Istruzioni dettagliate su come impostare un portafoglio, effettuare un ordine o impostare un DCA",
  "landing_tos_meta_tag_description": "Trading equo con le criptovalute",
  "landing_tos_meta_tag_title": "Chainblock - Terms and conditions",
  "landing_privacy_meta_tag_title": "Chainblock -  Privacy Policy",
  "landing_privacy_meta_tag_description": "I vostri dati sono al sicuro con noi",
  "landing_affiliate_meta_tag_description": "Diventa un partner Chainblock e guadagna una commissione",
  "landing_affiliate_meta_tag_title": "Chainblock - Programma di affiliazione",
  "landing_limits_meta_tag_description": "Opzioni di acquisto senza registrazione e quando \u00e8 necessario essere verificati",
  "landing_limits_meta_tag_title": "Chainblock-Limiti e verifica",
  "landing_contact_meta_tag_description": "Book a personal appointment at our client centre or take advantage of our client support 9am-9pm every day of the week.",
  "landing_contact_meta_tag_title": "Chainblock-contact",
  "react_new_app_version_available_dialog_content": "\u00c8 disponibile una nuova versione. Aggiornare la pagina per assicurarsi di utilizzare la versione pi\u00f9 recente.",
  "wallet_recipient_warning_1": "Chiunque conosca l'accesso segreto al cripto-portafoglio (in particolare il seed di recupero - 12-24 parole utilizzate per recuperare il portafoglio) pu\u00f2 utilizzare liberamente i fondi in esso contenuti.",
  "wallet_recipient_warning_2": "Se il portafoglio \u00e8 stato creato da una persona diversa da me, probabilmente ha accesso a questo portafoglio e pu\u00f2 utilizzare liberamente i fondi in esso contenuti.",
  "wallet_recipient_warning_3": "Se perdo l'accesso al mio portafoglio e non ho un backup (seed di recupero), perdo l'accesso a tutti i fondi nel mio portafoglio.",
  "wallet_recipient_warning_4": "Se aggiungo un indirizzo di criptovaluta che non \u00e8 il mio, non potr\u00f2 utilizzare direttamente i fondi in quel portafoglio. Spetta alla terza parte permettermi di disporre dei fondi.",
  "warning": "Attenzione",
  "ticket_attachments_info": "Gli allegati possono essere aggiunti al ticket nella fase successiva (dopo la creazione del ticket).",
  "one_off_income": "Reddito una tantum\n",
  "button_previous": "Precedente\n",
  "years_of_service": "Anni di servizio\n",
  "primary_or_secondary_activity": "Attivit\u00e0 primaria / secondaria",
  "rental_contract": "Contratto di locazione\n",
  "bank_statement_with_incoming_payment": "Estratto conto bancario con il pagamento in entrata\n",
  "pension_confirmation_letter": "Lettera di conferma della pensione",
  "loan": "Prestito",
  "loan_maturity_date": "Data di scadenza\n",
  "loan_effective_date": "Data di decorrenza\n",
  "lender": "Prestatore\n",
  "loan_agreement": "Contratto di prestito",
  "donation_agreement": "Accordo di donazione\n",
  "savings": "Risparmio\n",
  "bank_statement": "Estratto conto bancario",
  "sale_of_movable_property": "Vendita di beni mobili",
  "sale_of_company_share": "Vendita di azioni della societ\u00e0",
  "investment_return": "Rendimento dell'investimento",
  "investment_period": "Periodo di investimento",
  "crypto_mining": "Cripto-mining",
  "mining_target_crypto_address": "Indirizzo crittografico di destinazione delle monete estratte",
  "verified_clients_limit_info": "informazioni_limite_clienti_verificati",
  "expected_trading_volume": "volume_di_trading_previsto",
  "expected_trading_volume_info": "volume_di_commercio_previsto_info",
  "add_expected_trading_volume": "volume_di_commercio_previsto",
  "trading_volume_period": "volume_di_commercio_periodo",
  "landing_quarterly_volume": "volume_trimestrale",
  "landing_quarterly_exchanges": "scambi_trimestrali",
  "income_state_revoked": "stato_di_reddito_revocato",
  "crypto_deposit_fee_info": "crypto_deposit_fee_info",
  "deposit_on_account": "deposito_su_conto",
  "show": "mostra",
  "hide": "nascondere",
  "account_status_suspended": "stato_conto_sospeso",
  "account_status_closed": "stato_conto_chiuso",
  "account_status_active": "stato_conto_attivo",
  "suspended_accounts": "conti_sospesi",
  "closed_accounts": "conti_chiusi",
  "active_accounts": "conti_attivi",
  "create_wallet_account_form_header": "creare_un_conto_di_portafoglio",
  "account_number": "numero_conto",
  "accounted_transactions": "transazioni_contabilizzate",
  "counterparty": "controparte",
  "all_financial_operations": "tutte_le_operazioni_finanziarie",
  "accounting_balance": "bilancio_contabile",
  "pending_deposits": "depositi_in_ sospeso",
  "pending_withdrawals": "prelievi_in_attesa",
  "pending_transactions": "transazioni_in_attesa",
  "blocked_amount_alert": "avviso_importo_bloccato",
  "available_balance_tooltip": "tooltip_bilancio_disponibile",
  "total_balance_tooltip": "bilancio_tooltip totale",
  "deposit_requests_total_tooltip": "richieste_di_deposito_totale_tooltip",
  "withdrawal_requests_total_tooltip": "richieste_di_prelievo_totale_tooltip",
  "cancel_account": "cancellare_conto",
  "cancel_account_confirmation_question": "domanda_di_annullamento_della_conferma_dell'account",
  "empty_account_required_to_close_it": "conto_vuoto_richiesta_per_chiuderlo",
  "account_closed_success": "conto_chiuso_successo",
  "approved_incomes": "redditi_approvati",
  "pending_incomes": "redditi_in attesa",
  "show_details": "mostra_dettagli",
  "hide_details": "nascondi_dettagli",
  "hide_approved_incomes": "nascondi_proventi_approvati",
  "show_approved_incomes": "mostra_proventi_approvati",
  "increase_trading_limit": "aumentare_il_limite_di_commercio",
  "save_as_draft": "salva_come_bozza",
  "withdrawal_success": "ritiro_successo",
  "withdrawal_confirmation_transaction_fee_label": "etichetta_conferma_recesso_transazione_favorevole",
  "withdrawal_form_fee_in_amount_switch_label": "modulo_di_prelievo_in_importo_cambia_etichetta",
  "withdrawal_recap_table_fee": "tabella_prelievo_fee_premio",
  "withdrawal_recap_table_deducted_amount": "tabella_prelievi_importo_dedotto",
  "withdrawal_recap_table_net_withdraw_amount": "tabella_dei_prelievi_importo_netto_dei_prelievi",
  "remove_income_confirmation": "rimuovi_conferma_ingresso",
  "deposit_form_show_instructions": "modulo_deposito_mostrare_istruzioni",
  "income_state_readyForApproval": "stato_di_reddito_pronto_per_l'approvazione",
  "withdrawal_form_show_bank_fields_toggle": "modulo_prelievo_mostrare_campi_banca_toggle",
  "end_income": "fine_reddito",
  "edit_income": "modifica_reddito",
  "delete_income": "eliminare_reddito",
  "pending_transaction_detail": "dettaglio_transazione_in_ sospeso",
  "account": "conto",
  "verification_wizard": "verifica_guidata",
  "portfolio_volume": "volume_del_portafoglio",
  "profile_verification_info_message_for_business": "messaggio_di_verifica_per_le_aziende",
  "unknown_source": "sorgente_sconosciuta",
  "amount_netto": "Importo (netto)",
  "deposit_request_status_on_hold": "In attesa",
  "exchange_form_verified_client_discount_message": "I clienti verificati possono risparmiare fino a <0>{{scontoNomale}} {{discountCurrency}}</0> per {{toCurrency}}. </1>",
  "sign_up_to_get_discount": "Iscriviti per ottenere lo sconto",
  "exchange_form_verified_client_discount_message_generic": "I clienti verificati ottengono un prezzo migliore",
  "exchange_form_verified_client_discount_message_from_crypto": "I clienti verificati possono ottenere immediatamente <0>{{sconto nominale}} {{discountCurrency}} in pi\u00f9 per {{toCurrency}}.\n",
  "price_for_verified_clients": "Prezzo per i clienti verificati:",
  "contact_page_customer_center_reservation_info": "Se siete interessati a visitare il nostro ufficio, vi preghiamo di <0>fissare un appuntamento</0>.",
  "label_house_number": "etichetta_numero_casa",
  "react_file_upload_count_error": "the maximum number of uploaded files is {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "mi dispiace_non_bancario_per_la_valuta_inserita",
  "payment_instructions_delay_warning": "istruzioni_di_pagamento_avviso_di_ritardo",
  "payment_confirmation_upload_info": "conferma di pagamento_upload_info",
  "attach_payment_confirmation": "allegare_conferma_di_pagamento",
  "price_fixed_until": "prezzo_fisso_fino a quando",
  "payment_confirmation_attached": "conferma_di_pagamento_allegata",
  "validation_incorrect_format_generic": "Invalid format",
  "exchange_fee_percentage_info": "Fee makes {{feePercentage}}% of the traded amount",
  "citizenship": "Citizenship",
  "second_citizenship": "Second citizenship",
  "second_citizenship_helper": "If you have two citizenships state both",
  "dashboard_intro_text_how_to_start": "Our wallet works similarly to internet banking. In order to start using Simplecoin Wallet, you need to deposit funds into virtual accounts you create in it. To get started, we recommend you set up one account each for the currencies you want to start trading. Later, of course, you can add more currency accounts.",
  "how_to_start_using_wallet": "How to start using Simplecoin wallet?",
  "create_accounts": "Create accounts",
  "default_account_label": "{{currencyCode}} default account",
  "default_account_description": "This account was created automatically for you by the initial wizard.",
  "about_virtual_account_info": "A virtual account is where you keep your funds in your Simplecoin wallet. If you want to hold funds in your chosen currency, you must create at least one account for that currency. Setting up and maintaining an account is free. Trading is done by transferring funds between accounts, \nwhich may be in different currencies.",
  "preferred_currencies_question": "Which currencies will you most often trade in?",
  "crypto": "Crypto",
  "fiat": "Fiat",
  "create_accounts_for_selected_currencies": "Create accounts for selected currencies",
  "accounts_successfully_created": "Accounts has been successfully created! We have created {{accountsCount}} accounts for you.",
  "create_your_first_accounts": "Create your first accounts",
  "wallet_logo": "wallet",
  "just_one_citizenship": "just one citizenship",
  "information_about_verification_status_change": "We will inform you about the change of verification status to e-mail {{email}}.",
  "amount_too_low": "Amount too low",
  "footer_link_legal_documents": "Legal documents",
  "log_into_wallet": "Wallet",
  "log_into_exchange": "Exchange",
  "log_into_exchange_caption": "One-off purchases to your wallet",
  "log_into_wallet_caption": "The easiest cryptocurrency trading",
  "payment_request": "Payment request",
  "payment_hash": "Payment hash",
  "memo": "Memo",
  "scan_invoice_qr": "Scan invoice QR",
  "lightning_payment": "Lightning payment",
  "scan_qr": "Scan QR",
  "pay": "Pay"
}