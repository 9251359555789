interface Props {
    text: string | null;
    shortenChar?: string;
    maxSize?: number;
}

/**
 * Truncated text to desired length by removing middle part and adding separator there
 */
export function truncateMiddle({text = '', maxSize = 24, shortenChar = '...'}: Props): string {
    if (!text) {
        return '';
    }

    const textLength = text.length;
    if (maxSize <= shortenChar.length) {
        return shortenChar;
    }

    if (textLength <= maxSize) {
        return text;
    }

    const sepLen = shortenChar.length;
    let charsToShow = maxSize - sepLen;

    if (charsToShow % 2 !== 0) {
        charsToShow -= 1;
    }

    const frontChars = charsToShow / 2;
    const backChars = charsToShow / 2;

    const textProcess = text.substring(0, frontChars) + shortenChar + text.substring(textLength - backChars);

    return textProcess;
}

/**
 * Limits length of the text to the specified value.
 */
export function shorten({text = '', maxSize = 24, shortenChar = '...'}: Props): string {
    if (!text) {
        return '';
    }
    if (!text || text.length <= maxSize) return text;

    return text.substring(0, maxSize) + shortenChar;
}
