import {createTheme} from '@mui/material';
import {simplecoinExchangeAppTheme} from '@simplecoin/core';

const devizovaBurzaFont = ['"Ubuntu"', 'sans-serif'].join(', ');
const devizovaBurzaPrimaryColor = '#264159';
const textPrimaryColor = '#264159';

// '#72cce2';
// #e280d6

// linear-gradient(120deg,rgba(226,128,214,0.95),rgba(113,235,240,0.95));

export const devizovaBurzaTheme = createTheme(simplecoinExchangeAppTheme, {
    logo: {
        name: 'devizovaburza',
        width: 187,
        height: 80,
    },
    palette: {
        primary: {
            light: '#757ce8',
            main: devizovaBurzaPrimaryColor,
            dark: '#010528',
            contrastText: '#fff',
        },
        secondary: {
            main: '#e280d6',
            dark: '#ba000d',
            contrastText: '#fff',
        },
        text: {
            primary: textPrimaryColor,
            secondary: '#23262b',
            disabled: '#898E9E',
        },
        background: {
            default: '#fafafa',
        },
    },
    orange: {
        bg1: 'rgba(113,235,240,0.30)',
        bg2: 'rgba(113,235,240,0.20)',
    },
    typography: {
        fontSize: 16,
        fontFamily: devizovaBurzaFont,
        h1: {
            fontFamily: devizovaBurzaFont,
            color: textPrimaryColor,
        },
        h2: {
            fontFamily: devizovaBurzaFont,
            color: textPrimaryColor,
        },
        h3: {
            fontFamily: devizovaBurzaFont,
            color: textPrimaryColor,
        },
        h4: {
            fontFamily: devizovaBurzaFont,
            color: textPrimaryColor,
        },
        h5: {
            fontFamily: devizovaBurzaFont,
            color: textPrimaryColor,
        },
        h6: {
            fontFamily: devizovaBurzaFont,
            color: textPrimaryColor,
        },
        body1: {
            fontFamily: devizovaBurzaFont,
        },
        body2: {
            fontFamily: devizovaBurzaFont,
        },
        subtitle1: {
            fontFamily: devizovaBurzaFont,
        },
        subtitle2: {
            fontFamily: devizovaBurzaFont,
        },
        caption: {
            fontFamily: devizovaBurzaFont,
        },
        text: {
            fontFamily: devizovaBurzaFont,
        },
        label: {
            fontFamily: devizovaBurzaFont,
            color: textPrimaryColor,
        },
        textL: {
            fontFamily: devizovaBurzaFont,
        },
        textM: {
            fontFamily: devizovaBurzaFont,
        },
        textS: {
            fontFamily: devizovaBurzaFont,
        },
        textXs: {
            fontFamily: devizovaBurzaFont,
        },
        button: {
            fontFamily: devizovaBurzaFont,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: '"Ubuntu"';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap'),
                },
            `,
        },
        MuiButton: {
            styleOverrides: {
                containedSecondary: {
                    boxShadow: '0px 4px 16px rgba(226, 128, 214, 0.2), 0px 1px 2px rgba(226, 128, 214, 0.25)',
                    ':hover': {
                        backgroundColor: '#b769ae',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    color: devizovaBurzaPrimaryColor,
                    fontWeight: '700',
                    textTransform: 'none',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-shrink': {
                        color: textPrimaryColor,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: textPrimaryColor,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    '&.MuiTextField-root .MuiInputLabel-root': {
                        color: textPrimaryColor,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardWarning: {
                    backgroundColor: 'rgba(253, 99, 0, 0.1)',
                },
                filledInfo: {
                    backgroundColor: 'rgba(113,235,240,0.25)',
                },
                filledWarning: {
                    backgroundColor: 'rgba(253, 99, 0, 0.1)',
                },
                filledError: {
                    backgroundColor: '#FEF1F2',
                    color: '#E02D3C',
                },
                filledSuccess: {
                    backgroundColor: '#E9F9EA',
                },
            },
        },
    },
});
