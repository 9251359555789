import axios, {AxiosHeaders, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse} from 'axios';
import {getAuthKey, setAuthProps} from './helpers/cookie';
import {closeSnackbar, enqueueSnackbar} from './redux/snackbarSlice';
import {CreateAuthOrderProps, CreateGuestOrderProps} from './redux/exchangeFormSlice';
import i18next from 'i18next';
import {batch} from 'react-redux';
import {redirectProgrammatic, setBackendVersion} from './redux/redirectSlice';
import {isObject} from './helpers/common';
import {Dispatch} from 'redux';
import {RootState} from './redux/store';
import {parse, stringify} from 'qs';
import {getConfigValue} from '~/helpers/config';

let axiosInstance: AxiosInstance;

//

/**
 * Should be called on app init
 */
export function initiateAxiosApi(apiUrl: string) {
    if (!axiosInstance) {
        axiosInstance = axios.create({
            baseURL: apiUrl,
            headers: {
                'Content-type': 'application/json',
            },
            paramsSerializer: {
                encode: parse,
                serialize: stringify,
            },
        });
    }
}

/**
 * Helper making sure that axios has been initiated, will fail in 500ms
 */
async function getAxios(): Promise<AxiosInstance> {
    return new Promise(function (resolve) {
        (function waitForAxios(): void {
            if (axiosInstance) return resolve(axiosInstance);
            setTimeout(waitForAxios, 30);
        })();
    });
}

/**
 * Retrieves files name from response in casewhen content-disposition
 * @param response
 * @param {boolean} generateWhenMissing Generate file name, when it is missing in content-disposition header.
 * @return {string | null}
 */
function getFileNameFromResponse(response, generateWhenMissing = false): string | null {
    // Check content disposition and whether content can be downloaded
    const cd = response.headers && response.headers['content-disposition'];

    const fileNamePart = /filename="(.*?)"/gm.exec(cd);

    if (fileNamePart && fileNamePart[1]) {
        return fileNamePart[1];
    }

    if (generateWhenMissing) {
        return 'doc-' + new Date().getTime();
    }

    return null;
}

/**
 * Invokes download of the file's data.
 * @param {object} response
 */
export function downloadFile(response: any): void {
    const fileName = getFileNameFromResponse(response, true);

    if (fileName) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
}

export interface ThunkApi {
    dispatch: Dispatch;
    getState: () => RootState;
}

export function fetchGeneric(
    axiosParams: AxiosRequestConfig,
    thunkApi: ThunkApi,
    displaySnackbars = true,
    failureUrl?: string
): AxiosPromise {
    const {dispatch, getState} = thunkApi;

    return getAxios()
        .then((axios) => {
            const csrfToken = getAuthKey();
            const headers = new AxiosHeaders();

            // backend expects per-page param, but it's awkward to work with variables with dash in ts
            if (axiosParams?.params?.['perPage']) {
                axiosParams.params['per-page'] = axiosParams.params['perPage'];
            }

            const interceptor = axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    const logId = error?.response?.headers?.['x-log-id'] ?? null;
                    const status = error?.response?.status;

                    if (status) {
                        if (status === 401) {
                            dispatch(redirectProgrammatic({url: '/logout'}));
                        } else if (
                            (status === 403 || status === 503) &&
                            error.response?.headers['permissions-policy']
                        ) {
                            window.location.reload();
                        } else if (status >= 400 && status < 500) {
                            dispatch(redirectProgrammatic({url: failureUrl ?? '/error'}));
                            // Setting failure url to '' in order not to invoke another redirect in handling dispatchFailure
                        }
                    }

                    let data = error?.response?.data;
                    const DEFAULT_ERROR_MESSAGE = i18next.t('oops_something_went_wrong');

                    const message = data?.error ?? DEFAULT_ERROR_MESSAGE;

                    if (isObject(data)) {
                        data.error = message;
                    }

                    // For example, 404 returned in plain HTML, it cannot be converted to JSON, so we need to override
                    // data as object and later synthetically recreate error response object
                    if (typeof data === 'string' || data === null) {
                        data = {};
                    }

                    if (getConfigValue('environment') === 'development') {
                        // eslint-disable-next-line no-console
                        console.warn(
                            `[Request] failed calling ${axiosParams.url} because of ${error.name}, message: "${error.message}"`
                        );
                    }

                    if (error.message === 'Network Error') {
                        batch(() => {
                            dispatch(closeSnackbar({dismissAll: true}));
                            dispatch(
                                enqueueSnackbar({
                                    message: i18next.t('network_error_warning') as string,
                                    variant: 'error',
                                })
                            );
                        });
                    }

                    if (displaySnackbars) {
                        if (typeof data.error === 'string') {
                            dispatch(enqueueSnackbar({message: data.error, logId, variant: 'error'}));
                        } else if (isObject(data.error)) {
                            Object.keys(data.error).forEach((key) => {
                                if (typeof data.error[key] === 'string') {
                                    // if item is an object it is meant for form use
                                    dispatch(enqueueSnackbar({message: data.error[key], logId, variant: 'error'}));
                                }
                            });
                        }
                    }
                    return Promise.reject(error);
                }
            );

            headers['X-Authorization'] = 'Bearer ' + csrfToken;

            return axios
                .request({
                    headers,
                    withCredentials: !!csrfToken,
                    ...axiosParams,
                })
                .then((response: AxiosResponse) => {
                    const responseData = response?.data;

                    if (!responseData?.response?._meta) {
                        if (
                            !isNaN(parseInt(responseData?.response?.page)) &&
                            !isNaN(parseInt(responseData?.response?.perPage)) &&
                            !isNaN(parseInt(responseData?.response?.totalCount))
                        ) {
                            responseData.response._meta = {
                                currentPage: responseData.response.page,
                                perPage: responseData.response.perPage,
                                totalCount: responseData.response.totalCount,
                                pageCount: Math.ceil(responseData.response.totalCount / responseData.response.perPage),
                            };
                        }
                    }

                    const backendVersion = response?.headers?.['x-version'] ?? null;
                    if (backendVersion) {
                        const state = getState();
                        const {backendVersion: currentBackendVersion} = state.redirect;
                        if (backendVersion !== currentBackendVersion) {
                            dispatch(setBackendVersion(backendVersion));
                        }
                    }

                    const authKey = response?.headers?.['auth-key'] ?? null;
                    const authKeyExpiration = response?.headers?.['auth-key-expiration'] ?? null;
                    const currentAuthKey = getAuthKey();
                    if (authKeyExpiration && authKey && currentAuthKey !== authKey) {
                        setAuthProps({token: authKey, expires_at: parseInt(authKeyExpiration)});
                    }

                    if (displaySnackbars && typeof response?.data?.error === 'string') {
                        dispatch(
                            enqueueSnackbar({
                                message: response.data.error,
                                variant: 'error',
                                logId: response?.headers?.['x-log-id'],
                            })
                        );
                    }

                    return response;
                })
                .catch((e) => Promise.reject(e))
                .finally(() => {
                    axios.interceptors.response.eject(interceptor);
                });
        })
        .catch((e) => Promise.reject(e));
}

export const createGuestOrder = (
    thunkApi,
    {
        fromCurrencyId: from,
        toCurrencyId: to,
        fromAmount: from_amount,
        toAmount: to_amount,
        email,
        cryptoAccount: address,
        arbitraryData: arbitrary_data,
        reCaptcha,
        currency_id,
        account_label,
        account_number,
        owner_name,
        owner_address,
        owner_country,
        owner_city,
        account_vs,
        account_specific,
        account_constant,
        message,
    }: CreateGuestOrderProps
) => {
    const receiver = address
        ? {crypto: {address, arbitrary_data}}
        : {
              bank: {
                  currency_id,
                  account_label,
                  account_number,
                  owner_name,
                  owner_address,
                  owner_country,
                  owner_city,
                  account_vs,
                  account_specific,
                  account_constant,
                  message,
              },
          };

    return fetchGeneric(
        {
            method: 'post',
            url: 'exchange/create-guest',
            data: {
                from,
                to,
                from_amount,
                to_amount,
                email,
                ...receiver,
                reCaptcha,
            },
        },
        thunkApi as ThunkApi
    );
};

export const createAuthOrder = (
    thunkApi,
    {
        fromCurrencyId: from,
        toCurrencyId: to,
        fromAmount: from_amount,
        toAmount: to_amount,
        bankAccount: bank_account_id,
        cryptoAccount: address_id,
    }: CreateAuthOrderProps
) => {
    return fetchGeneric(
        {
            method: 'post',
            url: 'exchange/create',
            data: {
                from,
                to,
                from_amount,
                to_amount,
                bank_account_id,
                address_id,
            },
        },
        thunkApi as ThunkApi
    );
};
