import React from 'react';
import {ReducerEnvelope} from '../typings/Api';
import Loader from './Loader';

export interface ViewResponseProps<R> {
    reducer: ReducerEnvelope<R> | null | undefined;
    loader?: React.ReactElement;
    fetched: (response: {reducer: R}) => void;
    loaderInline?: boolean;
    updateAllowed?: boolean;
}

/**
 * Helps to work render logic related to provided reducer to avoid common checking for loading state, etc.
 *
 * Usage example:
 *
 * ```typescript
 * const {order} = this.props;
 *
 * <ViewResponse<YourResponseType>
 *      reducer: order,
 *      fetched: ({reducer: order}) => {
 *          // so, order is now actually order.response
 *      }
 * />
 * ```
 *
 * @param {ReducerEnvelope<R> | null | undefined} reducer
 * @param {<R>(response: {reducer: R | undefined}) => void} fetched
 * @param {boolean | undefined} loaderInline whether to make loader inline or not. Default: true
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined} loader
 * @param {any} updateAllowed whether fetched function should be invoked when reducer is in isFetching state but response is available (background refresh)
 * @return {null | any}
 * @constructor
 */
export default function ViewResponse<R>({
    reducer,
    fetched,
    loaderInline = true,
    loader,
    updateAllowed = false,
}: ViewResponseProps<R>) {
    if (!reducer) {
        return null;
    }

    if (typeof fetched !== 'function') {
        // eslint-disable-next-line no-console
        console.warn(`success prop should be type of function, now it is ${typeof fetched}`);
        return null;
    }

    if (!loader) {
        loader = <Loader inline={loaderInline} />;
    }
    const hasResponse = 'response' in reducer && reducer.status === 'ok';

    const showLoader = reducer.isFetching && (!hasResponse || (hasResponse && !updateAllowed));

    return (
        <>
            {showLoader && loader}
            {hasResponse && !showLoader && fetched({reducer: reducer.response as R})}
        </>
    );
}

ViewResponse.displayName = 'ViewResponse';
