import React from 'react';
import {Loader} from './index';
import {ReducerEnvelope} from '@simplecoin/core';

export interface FetchLoaderProps {
    reducer: ReducerEnvelope<any>;
    loaderInline?: boolean;
    loaderComponent?: React.ReactElement;
}

/**
 * Renders loader component for the time of reducer having isFetching=true
 * Useful for showing user that some action is in process
 * @param reducer
 * @param {boolean} loaderInline
 * @param loaderComponent
 */
export default function FetchLoader({reducer, loaderInline = true, loaderComponent}: FetchLoaderProps) {
    if (reducer && reducer.isFetching) {
        if (loaderComponent) {
            return loaderComponent;
        }
        return <Loader inline={loaderInline} />;
    }

    return null;
}

FetchLoader.displayName = 'FetchLoader';
