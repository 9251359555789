import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormikValues, useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {styled, Grid, TextField, Typography, Button} from '@mui/material';
import {loginConfirmation, loginConfirmationResend} from '~/src/pages/site/SiteActions';
import {LoginReducer} from '~/src/pages/site/siteReducer';
import Space from '../../components/Space';
import {RootState} from '~/src/redux/store';
import {closeSnackbar, successSnackbar, warningSnackbar} from '~/src/snackbars/SnackbarActions';
import {REDIRECT_PARAM_NAME} from '~/src/config/localParams';
import FormLayout from '~/src/components/Guest/FormLayout';
import {manageResponse, setAuthProps, CustomButton, yup} from '@simplecoin/core';
import useSearchParameters from '~/src/hooks/useSearchParameters';

const initialValues = {
    code: '',
};

const rules = yup.object().shape({
    code: yup.string().length(6).required(),
});

const WideConnectedButton = styled(CustomButton)`
    width: 100%;
`;

const WideButton = styled(Button)`
    height: 50px;
`;

const CodeField = styled(TextField)`
    && {
        .MuiOutlinedInput-input {
            font-size: 25px;
            padding: 12px;
        }
    }
`;

const Paragraph = styled('p')`
    font-size: 1.1rem;
    text-align: center;
`;

/**
 * Renders login page.
 */
export default function LoginConfirmation() {
    const dispatch = useDispatch();
    const {token} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {confirmLogin, resendLoginConfirmation} = useSelector((state: RootState) => state.user);
    const [resendActive, setResendActive] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const searchParameters = useSearchParameters();

    useEffect(() => {
        let interval = null;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
        }

        if (seconds <= 0) {
            setResendActive(true);
        }
        return () => clearInterval(interval);
    }, [seconds]);

    const formik = useFormik({
        initialValues,
        validationSchema: rules,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: (values: FormikValues) => {
            const {code} = values;
            dispatch(loginConfirmation({token, code}));
        },
        validateOnMount: false,
        isInitialValid: false,
    });

    const {values, handleChange, handleSubmit, isValid} = formik;

    // Redirect back to login page if 2fa token is incorrect
    useEffect(() => {
        manageResponse({
            formik,
            reducer: confirmLogin,
            onSuccess: (response: LoginReducer) => {
                setAuthProps(response);
                if (searchParameters[REDIRECT_PARAM_NAME]) {
                    navigate(searchParameters[REDIRECT_PARAM_NAME]);
                } else {
                    navigate('/dashboard');
                }
                dispatch(closeSnackbar({dismissAll: true})); // close all snackbars after successful login
            },
            onError: (error, errorCode) => {
                if (errorCode === 1000) {
                    // expired, need to login again
                    warningSnackbar({message: t('email_verification_token_expired_log_in_again')});
                    navigate('/login');
                }
            },
        });
    }, [confirmLogin]);

    // Show snackbar when email resent
    useEffect(() => {
        manageResponse({
            formik,
            reducer: resendLoginConfirmation,
            onSuccess: () => {
                dispatch(successSnackbar({message: t('login_confirmation_email_resent')}));
                setSeconds(60);
            },
        });
    }, [resendLoginConfirmation]);

    /**
     * Resends email with confirmation code and resets timer
     */
    function handleCodeResend() {
        dispatch(loginConfirmationResend({token}));
        setResendActive(false);
        setSeconds(60);
    }

    return (
        <FormLayout>
            <Helmet title={t('login_confirmation_title')} />

            <Space size={10} />

            <Typography align='center' variant='h4'>
                {t('login_confirmation_title')}
            </Typography>

            <Space size={45} />

            <Paragraph align='center'>{t('login_confirmation_form_check_email_paragraph')}</Paragraph>

            <Space size={30} />

            <form onSubmit={handleSubmit} name='confirm-2fa-form'>
                <Grid container justifyContent='space-between'>
                    <Grid xs={12}>
                        <CodeField
                            inputMode='numeric'
                            pattern='[0-9]*'
                            name='code'
                            type='text'
                            value={values.code}
                            onChange={handleChange}
                            label={t('login_confirmation_code_input_placeholder')}
                            variant='outlined'
                            autoComplete='off'
                        />
                    </Grid>

                    <Space size={10} />

                    <Grid container justifyContent='flex-end' item xs={12}>
                        <WideConnectedButton
                            type='submit'
                            variant='outlined'
                            reducer={confirmLogin}
                            disabled={!isValid}
                        >
                            {t('confirm')}
                        </WideConnectedButton>
                    </Grid>

                    <Space />

                    <Grid item justifyContent='flex-start' xs={12}>
                        {t('login_confirmation_email_not_received')}

                        <WideButton variant='text' disabled={!resendActive} onClick={handleCodeResend}>
                            {t('resend')}
                            {!resendActive && <> ({seconds})</>}
                        </WideButton>
                    </Grid>
                </Grid>

                <Space size={20} />
            </form>
        </FormLayout>
    );
}

LoginConfirmation.displayName = 'LoginConfirmation';
