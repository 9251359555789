import React, {ErrorInfo} from 'react';
import {styled} from '@mui/material';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import HouseError from '~/src/components/HouseError';
import {getConfigValue, isApplicationVersionOutdated, SiteActions} from '@simplecoin/core';
import ReportErrorProps = SiteActions.ReportErrorProps;
import {getAppVersion} from '~/src/helpers/config';

interface ErrorBoundaryProps {
    t: any;
    childrent: React.ReactNode;
    reportError: (props: ReportErrorProps) => void;
    backendVersion?: string;
}

interface ErrorBoundaryState {
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

const Title = styled('div')`
    font-size: 25px;
`;

const DevDetailView = styled('details')`
    white-space: pre-wrap;
    background-color: #fff;
    color: #000;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    font-family: 'Menlo', 'DejaVu Sans Mono', 'Liberation Mono', 'Consolas', 'Ubuntu Mono', 'Courier New', 'andale mono',
        'lucida console', monospace;
    border: 0;
    border-collapse: separate;
    line-height: 1.2;
`;

const ErrorMessage = styled('div')`
    font-size: 18px;
    margin: 10px 0;
`;

const StackTrace = styled('div')`
    font-size: 14px;
`;

/**
 * Handles error handling for passed down component.
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });

        const {reportError, backendVersion} = this.props;
        const url = window.location.href;
        const date = new Date();
        const message = `Error stack: ${error.stack}`;
        let appVersion = getAppVersion();
        const backend =
            backendVersion && appVersion && isApplicationVersionOutdated(backendVersion, appVersion)
                ? ` - old, backend: ${backendVersion}`
                : null;
        if (backend) {
            appVersion += backend;
        }
        reportError({
            url,
            message,
            date,
            componentStack: errorInfo.componentStack,
            appVersion: appVersion ?? 'unknown',
        });
    }

    render() {
        const {t} = this.props;

        if (this.state.errorInfo) {
            // Error path
            return (
                <HouseError data-testid='error-boundary'>
                    <Title>{t('oops_something_went_wrong')}</Title>

                    {getConfigValue('environment') === 'development' && (
                        <DevDetailView>
                            {this.state.error && <ErrorMessage>{this.state.error.toString()}</ErrorMessage>}
                            {this.state.error && this.state.error.stack && (
                                <StackTrace>{this.state.error.stack.toString()}</StackTrace>
                            )}
                        </DevDetailView>
                    )}
                </HouseError>
            );
        }

        return this.props.children;
    }
}

/**
 * @param dispatch
 */
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reportError: SiteActions.reportError,
        },
        dispatch
    );
}

/**
 * @param state
 * @return {object}
 */
function mapStateToProps(state) {
    return {
        backendVersion: state.user?.backendVersion,
    };
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ErrorBoundary);
