import React, {useState, useEffect, useMemo} from 'react';
import {useTheme} from '@mui/material';
import dayjs from '../../config/dayjs';
import {BusinessDay, ChartOptions, LineSeriesOptions} from 'lightweight-charts';
import {fetchChartData} from '~/redux/multichartSlice';
import {useCoreAppDispatch} from '~/redux/store';
import loadable from '@loadable/component';
import {getInterval} from '~/helpers/chart';

const LightweightChart = loadable(() => import('./LightweightChart'));

/**
 * Enum of possible price scale modes
 * Normal mode displays original price values
 * Logarithmic mode makes price scale show logarithms of series values instead of original values
 * Percentage turns the percentage mode on.
 * IndexedTo100 turns the "indexed to 100" mode on
 */
enum PriceScaleMode {
    Normal = 0,
    Logarithmic = 1,
    Percentage = 2,
    IndexedTo100 = 3,
}

type Nominal<T, Name extends string> = T & {
    [Symbol.species]: Name;
};
type UTCTimestamp = Nominal<number, 'UTCTimestamp'>;

enum TickMarkType {
    Year = 0,
    Month = 1,
    DayOfMonth = 2,
    Time = 3,
    TimeWithSeconds = 4,
}

/**
 * Structure describing single data item for series of type Line or Area
 */
export interface LineData {
    time: number;
    /**
     * Price value of data item
     */
    value: number;
}

interface SCChartDataPoint {
    average?: string;
    last_sec: string;
}

interface ChartHeaderProps {
    fromCurrencyCode: string;
    toCurrencyCode: string;
    chartHeight: number;
    chartWidth?: number;
    rates: SCChartDataPoint[];
    timeframe: number;
    mobile?: boolean;
}

/**
 * Renders chart header.
 */
export default function Chart({
    fromCurrencyCode,
    toCurrencyCode,
    chartHeight,
    chartWidth,
    rates,
    timeframe,
    mobile = false,
}: ChartHeaderProps) {
    const theme = useTheme();
    const dispatch = useCoreAppDispatch();

    const [chartPan, setChartPan] = useState<{since: number; until: number}>();
    const [lineData, setLineData] = useState<LineData[]>([]);

    useEffect(() => {
        if (rates.length) {
            setLineData(lightSingleLineData(rates));
        }
    }, [rates]);

    /**
     * Returns data ready to be passed to AreaChart
     */
    function lightSingleLineData(rates: SCChartDataPoint[]): LineData[] {
        return rates
            .map((rate: SCChartDataPoint) => {
                let value;
                if (rate.average) {
                    value = 1 / Number.parseFloat(rate.average);
                } else {
                    value = null;
                }
                return {time: Number.parseInt(rate.last_sec) as UTCTimestamp, value: value};
            })
            .filter((item) => !!item.value);
    }

    /**
     * Handles displaying formatted date based on the tick type
     * @param time
     * @param tickMarkType
     * @return {string}
     */
    function timeFormatter(time: UTCTimestamp | BusinessDay, tickMarkType: TickMarkType) {
        const date = dayjs.unix(time as number);
        switch (tickMarkType) {
            case TickMarkType.DayOfMonth:
                return date.format('MMM D');
            case TickMarkType.Month:
                return date.format('MMM');
            case TickMarkType.Time:
                return date.format('LT');
            case TickMarkType.TimeWithSeconds:
                return date.format('LTS');
            case TickMarkType.Year:
                return date.format('YYYY');
            default:
                return date.format('LT');
        }
    }

    // @ts-ignore
    const lightChartOptions: ChartOptions = useMemo(
        () => ({
            localization: {
                timeFormatter: (businessDayOrTimestamp) => {
                    // @ts-ignore
                    return dayjs.unix(businessDayOrTimestamp).format('LLL');
                },
                priceFormatter: (price) => {
                    return Number.parseFloat(price.toFixed(8));
                },
            },
            grid: {
                vertLines: {
                    color: 'rgba(197, 203, 206, 0.7)',
                    style: 3,
                },
                horzLines: {
                    visible: false,
                },
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
                barSpacing: 11,
                tickMarkFormatter: timeFormatter,
                borderVisible: false,
            },
            rightPriceScale: {
                invertScale: false,
                mode: PriceScaleMode.Percentage,
                borderVisible: false,
            },
            layout: {
                textColor: '#696969',
                fontSize: 14,
                fontFamily: theme.typography.fontFamily,
            },
            handleScale: {
                axisPressedMouseMove: false,
                mouseWheel: false,
            },
        }),
        []
    );

    // @ts-ignore
    const mobileLightChartOptions: ChartOptions = useMemo(
        () => ({
            localization: {
                timeFormatter: (businessDayOrTimestamp) => {
                    // @ts-ignore
                    return dayjs.unix(businessDayOrTimestamp).format('LLL');
                },
                priceFormatter: (price) => {
                    return Number.parseFloat(price.toFixed(8));
                },
            },
            grid: {
                vertLines: {
                    color: '#E8E4F5',
                    style: 3,
                },
                horzLines: {
                    visible: false,
                },
            },
            timeScale: {
                borderVisible: false,
            },
            leftPriceScale: {
                mode: PriceScaleMode.Percentage,
                // scaleMargins: {
                // 	top: 0.2,
                // 	bottom: 0.2,
                // },
                visible: true,
                borderVisible: true,
                borderColor: '#E8E4F5',
                drawTicks: false,
            },
            rightPriceScale: {
                visible: false,
            },
            layout: {
                textColor: '#696969',
                fontSize: 12,
                fontFamily: theme.typography.fontFamily,
            },
            handleScale: {
                axisPressedMouseMove: false,
                mouseWheel: false,
            },
            handleScroll: {
                horzTouchDrag: false,
                vertTouchDrag: false,
                pressedMouseMove: false,
            },
        }),
        []
    );

    // @ts-ignore
    const dataOptions: LineSeriesOptions = useMemo(
        () => ({
            topColor: theme.palette.secondary.main,
            bottomColor: 'rgba(255, 255, 255, 0)',
            lineColor: theme.palette.secondary.main,
            lineStyle: 0,
            lineWidth: 2,
            crosshairMarkerRadius: 8,
            lastValueVisible: !mobile,
            priceFormat: {
                type: 'custom',
                minMove: 0.00000001,
                formatter: (price) => {
                    return price.toFixed(8);
                },
            },
        }),
        [rates, theme]
    );

    /**
     // @ts-ignore
     * Updates chart [LogicalRange](https://github.com/tradingview/lightweight-charts/blob/master/docs/time-scale.md#logical-range) panning coordinates
     * @param {any} since
     * @param {any} until
     */
    // @ts-ignore
    function updateChartPan({from: since, to: until}) {
        setChartPan({since, until});
    }

    /**
     * Handles fetching of new chart rates on user pan interaction. Currently invoked on mousedown event
     * Logic range values are converted to start end end timestamp
     */
    function onLightChartPanComplete() {
        if (chartPan && lineData?.length) {
            const {since: sinceRangeValue, until: untilRangeValue} = chartPan;
            const lastTimestamp = lineData[lineData.length - 1].time;
            const firstTimestamp = lineData[0].time;
            const totalSeconds = lastTimestamp - firstTimestamp;
            const step = totalSeconds / (untilRangeValue - sinceRangeValue);
            let calculatedSinceTimestamp = firstTimestamp + sinceRangeValue * step;
            let calculatedUntilTimestamp = firstTimestamp + untilRangeValue * step;
            const now = Math.floor(Date.now() / 1000);
            if (calculatedUntilTimestamp > now) {
                calculatedUntilTimestamp = now;
                calculatedSinceTimestamp = now - totalSeconds;
            }

            dispatch(
                fetchChartData({
                    from: fromCurrencyCode,
                    to: toCurrencyCode,
                    since: calculatedSinceTimestamp,
                    until: calculatedUntilTimestamp,
                    interval: getInterval(timeframe),
                    timeframe: timeframe,
                })
            );
        }
    }

    // const tooltipLabel = fromCurrencyId + '/' + toCurrencyId;
    // const tooltipCurrency = isInverse ? fromCurrencyId : toCurrencyId;
    // const ratesAvailable = !!pairRates?.response?.rates?.length;
    // const decimals = isInverse ? currencies[fromCurrencyId]?.fixed_decimals : currencies[toCurrencyId]?.fixed_decimals;

    return (
        <LightweightChart
            chartOptions={mobile ? mobileLightChartOptions : lightChartOptions}
            seriesOptions={dataOptions}
            // @ts-ignore
            seriesData={lineData}
            height={chartHeight}
            width={chartWidth}
            // @ts-ignore
            onVisibleLogicalRangeChanged={updateChartPan}
            handlePan={onLightChartPanComplete}
            tooltipLabel={`${fromCurrencyCode}/${toCurrencyCode}`}
            tooltipCurrency={fromCurrencyCode}
            decimals={2}
            withTooltip
        />
    );
}
