import {createSvgIcon, Stack, Tooltip, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getSimplifiedOrderStatus} from '~/helpers/order';
import {OrderBaseStatus, OrderDirectionStatus, OrderFinalStatus} from '../typings/Order';

/*
    status: new, confirming_incoming, confirming_outgoing, received, done
    status_direction: proceed, suspended, return_to_client, on_hold
    status_final: cancelled, returned, delivered, unfinished

    status = new: New
    status = confirming_incoming: Paid (1/2)
    status = received: Paid (2/2)
    status = confirming_outgoing: Dispatched
    status_final = delivered: Completed
    status_final = cancelled | returned : Cancelled

*/

const Dot = createSvgIcon(<ellipse cx='12' cy='12' rx='4' ry='4' />, 'Dot');

interface OrderStatusProps {
    status: OrderBaseStatus;
    statusDirection: OrderDirectionStatus;
    statusFinal: OrderFinalStatus;
    variant: 'filled' | 'plain';
}

export default function OrderStatus({status, statusDirection, statusFinal, variant = 'plain'}: OrderStatusProps) {
    const {t} = useTranslation();
    const theme = useTheme();

    const simplifiedStatus = getSimplifiedOrderStatus(status, statusDirection, statusFinal);
    const label = t('order_status_' + simplifiedStatus);
    let tooltip;
    if (simplifiedStatus === 'paid' && statusDirection === 'suspended') {
        tooltip = t('order_status_tooltip_' + simplifiedStatus + '_suspended');
    } else {
        tooltip = t('order_status_tooltip_' + simplifiedStatus);
    }
    const color = variant === 'filled' ? 'primary.contrastText' : 'orderStatus.' + simplifiedStatus;
    const bgColor = variant === 'filled' ? theme.palette.orderStatus[simplifiedStatus] : 'transparent';

    return (
        <Tooltip title={tooltip}>
            <Stack
                direction='row'
                alignItems='center'
                sx={{
                    backgroundColor: bgColor,
                    borderRadius: '16px',
                    width: 'fit-content',
                    paddingRight: 2,
                    height: '28px',
                }}
            >
                <Dot sx={{color: color}} />
                <Typography color={color} variant='body2' fontWeight={700} component='span'>
                    {label}
                </Typography>
            </Stack>
        </Tooltip>
    );
}
