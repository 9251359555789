import {fetchGeneric} from '~/src/helpers/action';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAILURE = 'AUTH_USER_FAILURE';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_INVALIDATE = 'LOGIN_USER_INVALIDATE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';
export const LOGOUT_USER_INVALIDATE = 'LOGOUT_USER_INVALIDATE';

export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const SIGNUP_USER_INVALIDATE = 'SIGNUP_USER_INVALIDATE';

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';
export const REQUEST_PASSWORD_RESET_INVALIDATE = 'REQUEST_PASSWORD_RESET_INVALIDATE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const VALIDATE_RESET_PASSWORD_TOKEN = 'VALIDATE_RESET_PASSWORD_TOKEN';
export const VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS';
export const VALIDATE_RESET_PASSWORD_TOKEN_FAILURE = 'VALIDATE_RESET_PASSWORD_TOKEN_FAILURE';

export const VALIDATE_EMAIL_CONFIRMATION_TOKEN = 'VALIDATE_EMAIL_CONFIRMATION_TOKEN';
export const VALIDATE_EMAIL_CONFIRMATION_TOKEN_SUCCESS = 'VALIDATE_EMAIL_CONFIRMATION_TOKEN_SUCCESS';
export const VALIDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE = 'VALIDATE_EMAIL_CONFIRMATION_FAILURE';

export const CONFIRM_TWO_FACTOR_CODE = 'CONFIRM_TWO_FACTOR_CODE';
export const CONFIRM_TWO_FACTOR_CODE_SUCCESS = 'CONFIRM_TWO_FACTOR_CODE_SUCCESS';
export const CONFIRM_TWO_FACTOR_CODE_FAILURE = 'CONFIRM_TWO_FACTOR_CODE_FAILURE';
export const CONFIRM_TWO_FACTOR_CODE_INVALIDATE = 'CONFIRM_TWO_FACTOR_CODE_INVALIDATE';

export const VALIDATE_TWO_FACTOR_CODE = 'VALIDATE_TWO_FACTOR_CODE';
export const VALIDATE_TWO_FACTOR_CODE_SUCCESS = 'VALIDATE_TWO_FACTOR_CODE_SUCCESS';
export const VALIDATE_TWO_FACTOR_CODE_FAILURE = 'VALIDATE_TWO_FACTOR_CODE_FAILURE';

export const REQUEST_COMPANY_BANKS = 'REQUEST_COMPANY_BANKS';
export const REQUEST_COMPANY_BANKS_SUCCESS = 'REQUEST_COMPANY_BANKS_SUCCESS';
export const REQUEST_COMPANY_BANKS_FAILURE = 'REQUEST_COMPANY_BANKS_FAILURE';

export const REQUEST_LOGIN_CONFIRMATION = 'REQUEST_LOGIN_CONFIRMATION';
export const REQUEST_LOGIN_CONFIRMATION_SUCCESS = 'REQUEST_LOGIN_CONFIRMATION_SUCCESS';
export const REQUEST_LOGIN_CONFIRMATION_FAILURE = 'REQUEST_LOGIN_CONFIRMATION_FAILURE';

export const RESEND_LOGIN_CONFIRMATION = 'RESEND_LOGIN_CONFIRMATION';
export const RESEND_LOGIN_CONFIRMATION_SUCCESS = 'RESEND_LOGIN_CONFIRMATION_SUCCESS';
export const RESEND_LOGIN_CONFIRMATION_FAILURE = 'RESEND_LOGIN_CONFIRMATION_FAILURE';
export const RESEND_LOGIN_CONFIRMATION_INVALIDATE = 'RESEND_LOGIN_CONFIRMATION_INVALIDATE';

export interface LoginUserProps {
    email: string;
    password: string;
    code?: string;
    reCaptcha?: string;
    token?: string;
}

/**
 * Login user.
 *
 * API endpoint created cookie for user.
 *
 * @property {String} email User's email.
 * @property {String} password User's password.
 */
export function loginUser({email, password, code, reCaptcha, token}: LoginUserProps) {
    return fetchGeneric({
        method: 'post',
        url: 'user/login',
        data: {
            email,
            password,
            code,
            reCaptcha,
            token,
        },
        actions: {
            pre: LOGIN_USER,
            success: LOGIN_USER_SUCCESS,
            failure: LOGIN_USER_FAILURE,
            invalidate: LOGIN_USER_INVALIDATE,
        },
        displaySnackbars: false,
    });
}

/**
 * Logout user.
 *
 * API endpoint deletes cookie from user.
 *
 */
export function logoutUser(failureUrl = '/login') {
    return fetchGeneric({
        method: 'post',
        url: 'user/logout',
        actions: {
            pre: LOGOUT_USER,
            success: LOGOUT_USER_SUCCESS,
            failure: LOGOUT_USER_FAILURE,
            invalidate: LOGOUT_USER_INVALIDATE,
        },
        failureUrl,
    });
}

export interface LoginConfirmationProps {
    code?: string;
    token?: string;
}

/**
 * Login confirmation - user provided code is sent as confirmation
 */
export function loginConfirmation({code, token}: LoginConfirmationProps) {
    return fetchGeneric({
        method: 'post',
        url: 'user/confirm-login',
        query: {code, token},
        actions: {
            pre: REQUEST_LOGIN_CONFIRMATION,
            success: REQUEST_LOGIN_CONFIRMATION_SUCCESS,
            failure: REQUEST_LOGIN_CONFIRMATION_FAILURE,
            invalidate: LOGOUT_USER_INVALIDATE,
        },
    });
}

/**
 * Resend email with login confirmation
 */
export function loginConfirmationResend({token}: {token: string}) {
    return fetchGeneric({
        method: 'post',
        url: 'user/resend-login-confirmation',
        query: {token},
        actions: {
            pre: RESEND_LOGIN_CONFIRMATION,
            success: RESEND_LOGIN_CONFIRMATION_SUCCESS,
            failure: RESEND_LOGIN_CONFIRMATION_FAILURE,
            invalidate: RESEND_LOGIN_CONFIRMATION_INVALIDATE,
        },
    });
}

export interface SignupUserProps {
    email: string;
    password: string;
    reCaptcha?: string;
}

/**
 * Signup user.
 *
 * @property {String} email User's email.
 * @property {String} password User's password.
 */
export function signupUser({email, password, reCaptcha}: SignupUserProps) {
    return fetchGeneric({
        method: 'post',
        url: 'user/signup',
        data: {email, password, reCaptcha},
        actions: {
            pre: SIGNUP_USER,
            success: SIGNUP_USER_SUCCESS,
            failure: SIGNUP_USER_FAILURE,
            invalidate: SIGNUP_USER_INVALIDATE,
        },
    });
}

export interface RequestPasswordResetProps {
    email: string;
    reCaptcha?: string;
}

/**
 * Request password reset by email.
 *
 * @param email
 * @param reCaptcha
 */
export function requestPasswordReset({email, reCaptcha}: RequestPasswordResetProps) {
    return fetchGeneric({
        method: 'post',
        url: 'user/request-password-reset',
        data: {email, reCaptcha},
        actions: {
            pre: REQUEST_PASSWORD_RESET,
            success: REQUEST_PASSWORD_RESET_SUCCESS,
            failure: REQUEST_PASSWORD_RESET_FAILURE,
            invalidate: REQUEST_PASSWORD_RESET_INVALIDATE,
        },
    });
}

export interface ResetPasswordPropsProps {
    token: string;
    password: string;
    reCaptcha?: string;
    code?: string;
}

/**
 * Reset password by token from email.
 *
 * This action could be only invoked after successful request to requestPasswordReset.
 *
 * @see requestPasswordReset() is the method which generates unique token and sends user an reset email.
 *
 * @param token
 * @param password
 * @param code
 * @param reCaptcha
 */
export function resetPassword({token, password, code, reCaptcha}: ResetPasswordPropsProps) {
    return fetchGeneric({
        method: 'post',
        url: `user/reset-password?token=${token}`,
        data: {password, reCaptcha, code},
        actions: {
            pre: RESET_PASSWORD,
            success: RESET_PASSWORD_SUCCESS,
            failure: RESET_PASSWORD_FAILURE,
        },
    });
}

/**
 * Validate password reset token.
 *
 * @param token
 */
export function validateResetPasswordToken(token: string) {
    return fetchGeneric({
        method: 'post',
        url: `user/validate-reset-password-token?token=${token}`,
        data: {token},
        actions: {
            pre: VALIDATE_RESET_PASSWORD_TOKEN,
            success: VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
            failure: VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
        },
    });
}

export interface AuthUserProps {
    displaySnackbars?: boolean;
    failureUrl?: string;
}

/**
 * Check authentication status of a user.
 *
 */
export function authUser({displaySnackbars, failureUrl}: AuthUserProps = {}) {
    return fetchGeneric({
        displaySnackbars,
        method: 'post',
        url: 'user/auth',
        actions: {
            pre: AUTH_USER,
            success: AUTH_USER_SUCCESS,
            failure: AUTH_USER_FAILURE,
        },
        failureUrl,
    });
}

/**
 * Validate email confirmation token.
 *
 * @param token
 * @param showSnackbars
 */
export function validateEmailConfirmationToken(token: string, showSnackbars: boolean) {
    return fetchGeneric({
        method: 'get',
        url: `user/email-confirmation?token=${token}`,
        actions: {
            pre: VALIDATE_EMAIL_CONFIRMATION_TOKEN,
            success: VALIDATE_EMAIL_CONFIRMATION_TOKEN_SUCCESS,
            failure: VALIDATE_EMAIL_CONFIRMATION_TOKEN_FAILURE,
        },
        displaySnackbars: showSnackbars,
    });
}

/**
 * Confirm two factor code.
 * @param {string} token Temporary token to confirm request.
 */
export function validateTwoFactorCode(token: string) {
    return fetchGeneric({
        method: 'get',
        url: 'user/validate-2fa-token',
        query: {token},
        actions: {
            pre: VALIDATE_TWO_FACTOR_CODE,
            success: VALIDATE_TWO_FACTOR_CODE_SUCCESS,
            failure: VALIDATE_TWO_FACTOR_CODE_FAILURE,
        },
    });
}

/**
 * Confirm two factor code.
 *
 * @param {string} token Temporary token to confirm request.
 * @param {number} code
 */
export function confirmTwoFactorCode({token, code}: {token: string; code: string}) {
    return fetchGeneric({
        method: 'post',
        url: 'user/confirm-2fa',
        query: {token},
        data: {code},
        actions: {
            pre: CONFIRM_TWO_FACTOR_CODE,
            success: CONFIRM_TWO_FACTOR_CODE_SUCCESS,
            failure: CONFIRM_TWO_FACTOR_CODE_FAILURE,
            invalidate: CONFIRM_TWO_FACTOR_CODE_INVALIDATE,
        },
    });
}
