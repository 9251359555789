import {FormikHelpers} from 'formik';
import {Dispatch} from 'redux';
import {ReducerEnvelope} from '../typings/Api';
import {isObject} from './common';
import {enqueueSnackbar} from '../redux/snackbarSlice';

/**
 * Prepare error messages coming from API.
 *
 * Example: {"status":"fail","error":{"password":["Incorrect email or password."]}}
 *
 * Would turn into:
 *
 * {password: "Incorrect email or password."}
 *
 * So that Formik can render them properly.
 *
 * @param apiErrors
 * @param asString
 */
export function prepareErrors(
    apiErrors: Record<string, string[]>,
    {asString = false} = {}
): Record<string, string> | string {
    if (!apiErrors) {
        return {};
    }

    const preparedErrors = {};
    const stringErrors: string[] = [];

    const mainKeys = Object.keys(apiErrors);

    for (let i = 0; i < mainKeys.length; i++) {
        // ["password"] as an example
        const attrName = mainKeys[i];
        // Record only first error message
        preparedErrors[attrName] = apiErrors[attrName][0];
        stringErrors.push(apiErrors[attrName][0]);
    }

    if (asString) {
        return stringErrors.join('. ');
    }

    return preparedErrors;
}

/**
 *
 * Checks for error from backend and api.
 *
 * Envelope should have the following fields which should be added reducer:
 *
 *``` {
 *"status": "fail",
 *"error": "Some error message.",
 * *"response: null
 *}
 * ```
 *
 * @param reducer
 * @returns {boolean}
 */
export function hasError(reducer: any): boolean {
    if (!reducer) {
        return false;
    }

    if (reducer.status === 'fail' || reducer.error) {
        return true;
    }

    return false;
}

/**
 * Check whether request has response.
 *
 * @param data
 * @return {boolean}
 */
export function hasResponse(data: any): boolean {
    if (!data) {
        return false;
    }

    if (!('response' in data)) {
        return false;
    }

    return true;
}

/**
 * Get generic API errors from response.
 *
 * @param {ReducerEnvelope<any> | undefined} request
 * @return {string | string[] | undefined}
 */
export function getError(
    request: ReducerEnvelope<any> | undefined
): string | string[] | Record<string, string | string[]> | undefined {
    if (!request) {
        return undefined;
    }

    if ('error' in request) {
        return request.error ?? undefined;
    }

    return undefined;
}

/**
 * Get request error code if present
 * @param request
 * @return {null|number}
 */
function getErrorCode(request: any): number | null {
    if (!request) {
        return null;
    }

    if ('error_code' in request) {
        return Number.parseInt(request.error_code);
    }

    return null;
}

interface FormErrorOptions {
    prepareFormik?: boolean;
    asArray?: boolean;
}

/**
 * Retrieve form errors from response.
 *
 * @param request
 * @param options
 * @return {null|({}|{})}
 */
export function getFormErrors(request?: ReducerEnvelope<any>, options: FormErrorOptions = {}): any {
    if (!request) return null;

    const {prepareFormik = true, asArray = false} = options;

    const errors = getError(request);

    // if it's a plan string, it's not a form error and will be handled by fetchGeneric
    if (!errors || !isObject(errors)) return null;

    if (Object.keys(errors).length === 0) return null;

    /**
     * Helper for nested errors
     * @param errorObject
     * @return {string[]}
     */
    function getArrayOfMessages(errorObject): string[] {
        let results: string[] = [];
        Object.keys(errorObject).forEach((key: any): void => {
            if (typeof errorObject[key] === 'string') {
                results.push(errorObject[key]);
            } else if (Array.isArray(errorObject[key])) {
                results = [...results, ...getArrayOfMessages(errorObject[key])];
            } else if (isObject(errorObject[key])) {
                results = [...results, ...getArrayOfMessages(errorObject[key])];
            }
        });
        return results;
    }

    if (asArray) {
        let errorArray: string[] = [];

        if (isObject(errors)) {
            errorArray = getArrayOfMessages(errors);
        } else if (typeof errors === 'string') {
            errorArray = [errors];
        }

        return errorArray;
    }

    // @ts-ignore
    return prepareFormik && isObject(errors) ? prepareErrors(errors) : errors;
}

interface ManageResponseProps<T> {
    formik?: FormikHelpers<any> | null;
    reducer?: ReducerEnvelope<T>;
    dispatch?: Dispatch<any>;
    onSuccess?: (response?: T) => void;
    onError?: (
        error: string | Record<string, string | string[]> | string[],
        errorCode: number | null,
        response?: T
    ) => void;
    onAny?: () => void;
    displaySnackbarOnError?: boolean | false;
}

/**
 * Helper which manages response.
 *
 * For example it can be used inside componentDidUpdate() to display alerts, make some internal component changes.
 *
 * It provides multiple callback functions to check what kind of response this is.
 *
 * Example usage:
 *
 * ```js
 * manageResponse({
 *     formik,
 *     reducer: login,
 *     onSuccess: ({status, error, response}) => {
 *     }
 * })
 *
 * @param formik instance of Formik to set errors if such returned from the API or empty when should be ignored.
 * @param {object} reducer response which is mapped mapStateToProps() by Redux.
 * @param {function} dispatch thunkMiddleware handler.
 * @param {func} onSuccess callback called when success response was returned. Signature: function({status, error, response}).
 * @param {func} onError callback called when API returned some kind of error or envelope has error. Signature: function(error, errorCode).
 * is object list of errors prepared specifically for formik.
 * @param {func} onAny invoked after either successful or fail response is returned
 * @param displaySnackbarOnError
 */
export function manageResponse<T>({
    formik = null,
    reducer,
    dispatch,
    onSuccess,
    onError,
    onAny,
    displaySnackbarOnError = false,
}: ManageResponseProps<T>) {
    if (!reducer) {
        return;
    }

    if (hasError(reducer)) {
        const error = getError(reducer);
        const errorCode = getErrorCode(reducer);

        if (dispatch && displaySnackbarOnError && typeof error === 'string') {
            dispatch(enqueueSnackbar({message: error, variant: 'error'}));
        }

        if (isObject(error) && formik) {
            const formErrors = getFormErrors(reducer, {prepareFormik: true});
            formik.setErrors(formErrors);
        }

        if (typeof onError === 'function' && error) {
            onError(error, errorCode, reducer.response);
        }

        if (typeof onAny === 'function') {
            onAny();
        }
    } else if (!reducer.isFetching && reducer.status === 'ok') {
        if (hasResponse(reducer)) {
            if (typeof onSuccess === 'function') {
                onSuccess(reducer.response);
            }
        } else if (typeof onSuccess === 'function') {
            onSuccess();
        }

        if (typeof onAny === 'function') {
            onAny();
        }
    }
}
