import {useEffect, useRef} from 'react';

/**
 * Helper for dealing with intervals in react components
 *
 * Usage example:
 * const [seconds, setSeconds] = useState(0);
 * useInterval(() => {
 *   setSeconds(seconds + 1);
 * }, 1000);
 * @param callback
 * @param delay
 */
export default function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();

    // remember latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        // get current callback values
        const tick = () => {
            savedCallback.current?.();
        };

        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}
