import React, {ComponentType} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from './redux/store';
import {Box, CircularProgress} from '@mui/material';

/**
 * HOC that renders loader until settings are available in store
 */
export default function renderWithSettings<T>(WrappedComponent: ComponentType<T>) {
    function SettingsChecker(props: T) {
        const {settingsData} = useSelector((store: RootState) => store.site.settings);

        if (!settingsData) {
            return (
                <Box sx={{display: 'flex'}}>
                    <CircularProgress />
                </Box>
            );
        }

        // @ts-ignore
        return <WrappedComponent {...props} />;
    }

    return SettingsChecker;
}
