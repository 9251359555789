import Big from 'big.js';
import {FinancialOperation} from '../typings/models/BalancesTypes';

export function asNum(number?: string | number | null | undefined | Big, defaultValue?: number): number {
    if (number != null) {
        if (typeof number === 'number') {
            return number;
        } else if (typeof number === 'string') {
            return parseFloat(number);
        } else if (typeof number === 'object' && typeof number.toNumber === 'function') {
            return number.toNumber();
        } else {
            return -1;
        }
    } else {
        return defaultValue ?? -1;
    }
}

/**
 * Based on financial the operation type, select the right FinancialOperationBlock relevant for given account.
 */
export function getFinOpBlockForAccount(financialOperation: FinancialOperation, accountId: number) {
    switch (financialOperation.financialOperationType) {
        case 'DEPOSIT':
            if (asNum(financialOperation.target?.account.id) === asNum(accountId)) {
                return financialOperation.target;
            }
            break;
        case 'WITHDRAWAL':
            if (asNum(financialOperation.source?.account.id) === asNum(accountId)) {
                return financialOperation.source;
            }
            break;
        case 'EXCHANGE':
            // Given account is the source account of this financial operation
            if (financialOperation.source && asNum(accountId) === asNum(financialOperation.source.account.id)) {
                return financialOperation.source;
            }
            // Given account is the target account of this financial operation
            else if (financialOperation.target && asNum(accountId) === asNum(financialOperation.target.account.id)) {
                return financialOperation.target;
            }
            break;
        case 'TRANSFER':
            // Given account is the source account of this financial operation
            if (financialOperation.source && asNum(accountId) === asNum(financialOperation.source.account.id)) {
                return financialOperation.source;
            }
            // Given account is the target account of this financial operation
            else if (financialOperation.target && asNum(accountId) === asNum(financialOperation.target.account.id)) {
                return financialOperation.target;
            }
            break;
    }
    // eslint-disable-next-line no-console
    console.log(`ERROR: Cannot determine financial operation block for account ${accountId}.`, financialOperation);
    throw `ERROR: Cannot determine financial operation block for account ${accountId}.`;
}

/**
 * Based on financial the operation type, select the right FinancialOperationBlock relevant for given account counterparty.
 */
export function getFinOpBlockForAccountCounterparty(financialOperation: FinancialOperation, accountId: number) {
    switch (financialOperation.financialOperationType) {
        case 'EXCHANGE':
            // Given account is the source account of this financial operation
            if (financialOperation.source && asNum(accountId) === asNum(financialOperation.source.account.id)) {
                return financialOperation.target;
            }
            // Given account is the target account of this financial operation
            else if (financialOperation.target && asNum(accountId) === asNum(financialOperation.target.account.id)) {
                return financialOperation.source;
            }
            break;
        case 'TRANSFER':
            // Given account is the source account of this financial operation
            if (financialOperation.source && asNum(accountId) === asNum(financialOperation.source.account.id)) {
                return financialOperation.target;
            }
            // Given account is the target account of this financial operation
            else if (financialOperation.target && asNum(accountId) === asNum(financialOperation.target.account.id)) {
                return financialOperation.source;
            }
            break;
    }
    return null;
}
