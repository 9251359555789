export const STEP_PERSONAL_DATA_PROVIDED = 'PERSONAL_DATA_PROVIDED';
export const STEP_PRIMARY_DOCUMENT_PROVIDED = 'PRIMARY_DOCUMENT_PROVIDED';
export const STEP_SECONDARY_DOCUMENT_PROVIDED = 'SECONDARY_DOCUMENT_PROVIDED';
export const STEP_TRADING_PURPOSE_PROVIDED = 'TRADING_PURPOSE_PROVIDED';
export const STEP_VERIFICATION_PAYMENT = 'VERIFICATION_PAYMENT';
export const STEP_VERIFICATION_CALL = 'VERIFICATION_CALL';
export const STEP_BASIC_INCOME_PROVIDED = 'BASIC_INCOME_PROVIDED';

/**
 * Returns checklist that includes given step.
 * It checks if the step is already there. If not, the step is added into the checklist.
 * @param step Step to be in returned checklist.
 * @param checklist Current state of the checklist.
 * @returns New instance of checklist containing the provided step code.
 */
export function getChecklistWithStep(step: string, checklist?: Array<string>): Array<string> {
    if (checklist) {
        if (checklist?.includes(step)) {
            return checklist;
        } else {
            return [...checklist, step];
        }
    } else {
        return [step];
    }
}
