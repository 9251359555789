import {createSvgIcon, Stack, Tooltip, Typography} from '@mui/material';
import React from 'react';

export type StatusChipVariant = 'filled' | 'plain' | 'lightlyFilled';

interface StatusChipProps {
    label: string;
    tooltip?: string;
    status: 'new' | 'waiting' | 'active' | 'done' | 'canceled' | 'error';
    variant: StatusChipVariant;
}

const Dot = createSvgIcon(<ellipse cx='12' cy='12' rx='4' ry='4' />, 'Dot');

/**
 * Two state status chip component
 */
export default function StatusChip({label, tooltip, status, variant}: StatusChipProps) {
    let color;
    let bgColor;
    switch (variant) {
        case 'plain': {
            color = `status.${status}.primary`;
            bgColor = 'transparent';
            break;
        }
        case 'filled': {
            color = 'primary.contrastText';
            bgColor = `status.${status}.primary`;
            break;
        }
        case 'lightlyFilled': {
            color = `status.${status}.primary`;
            bgColor = `status.${status}.light`;
            break;
        }
    }

    const chip = (
        <Stack
            direction='row'
            alignItems='center'
            sx={{
                backgroundColor: bgColor,
                borderRadius: '16px',
                width: 'fit-content',
                paddingRight: 2,
                height: '28px',
            }}
        >
            <Dot sx={{color: color}} />
            <Typography color={color} variant='body2' fontWeight={700} component='span'>
                {label}
            </Typography>
        </Stack>
    );

    if (tooltip) {
        return <Tooltip title={tooltip}>{chip}</Tooltip>;
    } else {
        return chip;
    }
}
