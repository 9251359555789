/**
 * List of all available statuses.
 */

// Direction statuses
export type OrderDirectionStatus = 'proceed' | 'suspended' | 'return_to_client' | 'on_hold';

/**
 * Possible direction statuses.
 */
export const STATUS_PROCEED: OrderDirectionStatus = 'proceed';
export const STATUS_SUSPENDED: OrderDirectionStatus = 'suspended';
export const STATUS_RETURN_TO_CLIENT: OrderDirectionStatus = 'return_to_client';
export const STATUS_ON_HOLD: OrderDirectionStatus = 'on_hold';

export const REDIRECT_PARAM_NAME = 'backUrl';
export const AFFILIATE_PARAM_NAME = 'a';
export const TREZOR_SUPPORTED_CURRENCY_IMPORT = ['BTC', 'ETH', 'XRP', 'LTC', 'BCH', 'ZEC'];
/**
 * WARNING! See ExpectIncomingCrypto, currently only btc-like are supported (amount is converted to satoshis => decimal shifted by 8)
 */
export const TREZOR_SUPPORTED_CURRENCY_PAYMENT = ['BTC', 'LTC', 'BCH'];
export const CLOSED_NOTIFICATIONS_STORAGE_KEY = 'closed_notifications';

/**
 * Test language code. It is not standard, but does not conflict with any ISO 639-1 code.
 */
export const TEST_LANGUAGE_CODE = 'tx';

/**
 * Test language region code.
 */
export const TEST_LANGUAGE_REGION = 'tx-TX';

// List of available languages
// Use by language chooser
export const LANGUAGES = [
    {code: 'en', region: 'en-US', name: 'English', nativeName: 'English'},
    {code: 'cs', region: 'cs-CZ', name: 'Czech', nativeName: 'Čeština'},
    {code: 'sk', region: 'sk-SK', name: 'Slovak', nativeName: 'Slovenčina'},
    {code: 'pl', region: 'pl-PL', name: 'Polish', nativeName: 'Polski'},
    {code: 'de', region: 'de-DE', name: 'German', nativeName: 'Deutsch'},
    {code: 'it', region: 'it-IT', name: 'Italian', nativeName: 'Italiano'},
    {code: 'es', region: 'es-ES', name: 'Spanish', nativeName: 'Español'},
    {code: 'ru', region: 'ru-RU', name: 'Russian', nativeName: 'Русский'},
    {code: 'uk', region: 'uk-UA', name: 'Ukrainian', nativeName: 'Українська'},
    {code: 'nl', region: 'nl-NL', name: 'Dutch', nativeName: 'Nederlands'},
    {code: 'vi', region: 'vi-VN', name: 'Vietnamese', nativeName: 'Tiếng Việt'},
    {code: TEST_LANGUAGE_CODE, region: TEST_LANGUAGE_REGION, name: 'Test', nativeName: 'Test'},
];
