import React from 'react';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {Loader} from '../components';
import {getConfigValue} from '@simplecoin/core';

export interface LoadingComponentProps {
    error?: Error;
    pastDelay?: boolean;
}

/**
 * todo: documentation and possibility to retry
 * @param props
 * @return {null|*}
 * @constructor
 */
export default function LoadingComponent(props: LoadingComponentProps): React.ReactNode | null {
    const {t} = useTranslation();

    if (props.error) {
        if (getConfigValue('environment') === 'development') {
            return (
                <Typography component='div'>
                    <Typography component='h5' variant='h5' gutterBottom>
                        {props.error.name}: {props.error.message}
                    </Typography>
                </Typography>
            );
        } else {
            return <div data-testid='error-message'>{t('oops_something_went_wrong')}</div>;
        }
    } else if (props.pastDelay) {
        return <Loader />;
    } else {
        return null;
    }
}

LoadingComponent.displayName = 'LoadingComponent';
