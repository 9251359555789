/**
 * Returns a 0-4 score for password strength
 */
export function estimatePasswordStrength(
    password: string,
    setScore: (score: number) => void,
    userData?: string[]
): void {
    import('@zxcvbn-ts/core').then((core) => {
        import('@zxcvbn-ts/language-common').then((common) => {
            const options = {
                graphs: common.default.adjacencyGraphs,
                dictionary: {
                    ...common.default.dictionary,
                    userInputs: userData ?? [],
                },
            };
            core.zxcvbnOptions.setOptions(options);
            const score = core.zxcvbn(password).score;
            setScore(score);
        });
    });
}
