import {
    REQUEST_FILES,
    REQUEST_FILES_SUCCESS,
    REQUEST_FILES_FAILURE,
    UPLOAD_FILES,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE,
    UPLOAD_FILES_INVALIDATE,
    DELETE_FILE,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAILURE,
    DELETE_FILE_INVALIDATE,
    UPDATE_FILE,
    UPDATE_FILE_FAILURE,
    UPDATE_FILE_INVALIDATE,
    UPDATE_FILE_SUCCESS,
} from './UploadActions';
import {Action} from '~/src/typings';
import {FileListInterface} from '~/src/typings/endpoints/file/FileListInterface';
import {ReducerEnvelope} from '@simplecoin/core';

export interface UploadReducer<T> {
    [combinedKey: string]: T;
}

export interface UploadFilesEnvelope extends ReducerEnvelope<null> {
    fileNames: string[];
}

export interface UploadReducersInterface {
    index: UploadReducer<ReducerEnvelope<FileListInterface>>;
    upload?: UploadReducer<UploadFilesEnvelope>;
    deleteFile?: ReducerEnvelope<null>;
    update?: ReducerEnvelope<null>;
}

/**
 * @param state
 * @param action
 * @return {object}
 */
export default function (state: UploadReducersInterface = {index: {}}, action: Action = {}): UploadReducersInterface {
    let objectId = '';
    let objectName = '';
    let objectNameExtension = '';
    let objectSubgroup = '';

    if (action.payload) {
        objectId = action.payload.objectId;
        objectName = action.payload.objectName;
        objectNameExtension = action.payload.objectNameExtension;
        objectSubgroup = action.payload?.objectSubgroup ? action.payload?.objectSubgroup : '';
    }

    const combinedKey = objectName + objectId + objectNameExtension + objectSubgroup;

    switch (action.type) {
        // Request files

        case REQUEST_FILES:
            return {
                ...state,
                index: {
                    ...state.index,
                    [combinedKey]: {isFetching: true},
                },
            };

        case REQUEST_FILES_SUCCESS:
            return {
                ...state,
                index: {
                    ...state.index,
                    [combinedKey]: {
                        isFetching: false,
                        ...action.payload.response,
                    },
                },
            };

        case REQUEST_FILES_FAILURE:
            return {
                ...state,
                index: {
                    ...state.index,
                    [combinedKey]: {isFetching: false, ...action.payload},
                },
            };

        // Upload files
        case UPLOAD_FILES:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    [combinedKey]: {isFetching: true},
                },
            };

        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    [combinedKey]: {
                        isFetching: false,
                        fileNames: action?.payload?.fileNames,
                        ...action.payload.response,
                    },
                },
            };

        case UPLOAD_FILES_INVALIDATE:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    [combinedKey]: undefined,
                },
            };

        case UPLOAD_FILES_FAILURE:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    [combinedKey]: {isFetching: false, ...action.payload},
                },
            };

        // Delete file
        case DELETE_FILE:
            return {
                ...state,
                deleteFile: {
                    ...state.deleteFile,
                    [combinedKey]: {isFetching: true},
                },
            };

        case DELETE_FILE_SUCCESS:
            return {
                ...state,
                deleteFile: {
                    ...state.deleteFile,
                    [combinedKey]: {isFetching: false, ...action.payload.response},
                },
            };

        case DELETE_FILE_FAILURE:
            return {
                ...state,
                deleteFile: {
                    ...state.deleteFile,
                    [combinedKey]: {isFetching: false, ...action.payload},
                },
            };

        case DELETE_FILE_INVALIDATE:
            return {
                ...state,
                deleteFile: {
                    ...state.deleteFile,
                    [combinedKey]: undefined,
                },
            };

        // Upload files
        case UPDATE_FILE:
            return {
                ...state,
                update: {
                    ...state.update,
                    [combinedKey]: {isFetching: true},
                },
            };

        case UPDATE_FILE_SUCCESS:
            return {
                ...state,
                update: {
                    ...state.update,
                    [combinedKey]: {isFetching: false, ...action.payload.response},
                },
            };

        case UPDATE_FILE_INVALIDATE:
            return {
                ...state,
                update: {
                    ...state.update,
                    [combinedKey]: undefined,
                },
                index: {
                    ...state.index,
                    [combinedKey]: {
                        ...state.index[combinedKey],
                        response: {
                            ...state.index[combinedKey].response,
                            items: state.index[combinedKey].response?.items.map((item) => {
                                if (item.id === action.payload.data.id) {
                                    return {...action.payload.data};
                                }
                                return item;
                            }),
                        },
                    },
                },
            };

        case UPDATE_FILE_FAILURE:
            return {
                ...state,
                update: {
                    ...state.update,
                    [combinedKey]: {isFetching: false, ...action.payload.response},
                },
            };

        default:
            return state;
    }
}
