// worker Saga: will be fired on USER_FETCH_REQUESTED actions
import {all, put, takeEvery} from 'redux-saga/effects';
import {
    invalidateAccountWithdraw,
    invalidateMakeExchange,
    invalidateMakeTransfer,
    makeExchange,
    makeTransfer,
    withdraw,
} from '~/src/redux/slices/transactionSlice';

import {
    createAccount,
    closeAccount,
    fetchUpdateAccount,
    invalidateAccountCreate,
    invalidateAccountDelete,
    invalidateAccountUpdate,
} from '~/src/redux/slices/accountSlice';
import {ActionCreator} from 'redux';

/**
 * Generic invalidator function that calls the first item in arguments array
 * @param argsArray
 */
function* genericInvalidator(argsArray: Array<ActionCreator<any>>) {
    if (!argsArray.length) {
        // eslint-disable-next-line no-console
        console.error('Calling invalidator with no arguments');
        return;
    }

    const action = argsArray[0];

    try {
        yield put(action());
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error in generic invalidator', argsArray, e);
    }

    yield put(action());
}

export function* invalidatorSaga() {
    yield all([
        takeEvery(makeTransfer.fulfilled.type, genericInvalidator, [invalidateMakeTransfer]),
        takeEvery(makeExchange.fulfilled.type, genericInvalidator, [invalidateMakeExchange]),
        takeEvery(withdraw.fulfilled.type, genericInvalidator, [invalidateAccountWithdraw]),
        takeEvery(createAccount.fulfilled.type, genericInvalidator, [invalidateAccountCreate]),
        takeEvery(closeAccount.fulfilled.type, genericInvalidator, [invalidateAccountDelete]),
        takeEvery(fetchUpdateAccount.fulfilled.type, genericInvalidator, [invalidateAccountUpdate]),
    ]);
}
