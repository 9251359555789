import api from 'axios';
import {getConfigValue} from '@simplecoin/core';

// Configure AXIOS
api.defaults.baseURL = getConfigValue('apiUrl');
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
api.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
api.defaults.headers.common.Accept = 'application/json; charset=UTF-8';

export default api;
