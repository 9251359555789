import React, {useEffect, useState} from 'react';
import ProgressBar from '~/src/components/ProgressBar';
import {useTranslation} from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Space from '~/src/components/Space';
import {Typography, Grid, OutlinedInput, InputLabel, IconButton, InputAdornment, FormControl} from '@mui/material';
import {estimatePasswordStrength} from '@simplecoin/core';

// Keeps typing interval ID to limit number of requests to the API
let typingTimeout: number | undefined;

interface PasswordFieldProps {
    name: string;
    value: string;
    withStrengthIndicator?: boolean;
    onChange: () => void;
    label: string;
    userData?: string[]; // user data array - name, email
    errorMessage?: string;
    shrink?: string;
}

/**
 * Renders a password field with show password button allowing user to see written password and giving feedback on
 * password quality
 */
export default function PasswordField({
    name,
    value,
    withStrengthIndicator = false,
    onChange,
    label,
    userData,
    errorMessage,
    shrink = true,
    ...props
}: PasswordFieldProps) {
    const {t} = useTranslation();
    const [score, setScore] = useState<number | null>(null);
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        if (withStrengthIndicator && value) {
            if (typingTimeout !== undefined) {
                clearTimeout(typingTimeout);
            }

            typingTimeout = setTimeout(() => {
                estimatePasswordStrength(value, setScore, userData);
                clearTimeout(typingTimeout);
            }, 1000);
        }
    }, [value]);

    const inputType = passwordVisible ? 'text' : 'password';
    const autocomplete = 'new-password';

    const handleClickShowPassword = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    /**
     * Returns translated label for password strength indicator
     * @param {number} score in percents
     * @return {string}
     */
    function getStrengthLabel(score: number) {
        switch (score) {
            case 0:
            case 1:
                return (
                    <Typography variant='subtitle2' color='error.main'>
                        {t('password_strength_very_weak')}
                    </Typography>
                );
            case 2:
                return (
                    <Typography variant='subtitle2' color='error.main'>
                        {t('password_strength_weak')}
                    </Typography>
                );
            case 3:
                return (
                    <Typography variant='subtitle2' color='success.main'>
                        {t('password_strength_good')}
                    </Typography>
                );
            case 4:
                return (
                    <Typography variant='subtitle2' color='success.dark'>
                        {t('password_strength_great')}
                    </Typography>
                );
            default:
                return null;
        }
    }

    const passwordStrengthIndicator =
        score !== null ? (
            <Grid container direction='row' alignItems='center'>
                <Grid item xs={5}>
                    {getStrengthLabel(score)}
                </Grid>
                <Grid item xs={7}>
                    <ProgressBar value={score * 25} threshold={75} secondaryColor='#0bc013' primaryColor='#ff0000' />
                </Grid>
            </Grid>
        ) : null;

    const visibilityIcon = passwordVisible ? <Visibility /> : <VisibilityOff />;

    const errorState: boolean = (score !== null && score < 3) || !!errorMessage;

    return (
        <>
            <FormControl variant='outlined' error={errorState}>
                <InputLabel shrink={shrink} htmlFor='outlined-adornment-password'>
                    {label}
                </InputLabel>
                <OutlinedInput
                    id='outlined-adornment-password'
                    name={name}
                    value={value}
                    onChange={onChange}
                    type={inputType}
                    autoComplete={autocomplete}
                    label={label}
                    error={errorState}
                    notched={shrink}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                                size='large'
                            >
                                {visibilityIcon}
                            </IconButton>
                        </InputAdornment>
                    }
                    {...props}
                />
            </FormControl>

            <Space size={10} />

            {withStrengthIndicator && passwordStrengthIndicator}
        </>
    );
}

PasswordField.displayName = 'PasswordField';
