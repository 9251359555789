import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FormikValues, useFormik} from 'formik';
import {styled, TextField, Typography, Stack} from '@mui/material';
import {confirmTwoFactorCode, validateTwoFactorCode} from './SiteActions';
import {LoginReducer} from './siteReducer';
import Space from '../../components/Space';
import {closeSnackbar} from '~/src/snackbars/SnackbarActions';
import {REDIRECT_PARAM_NAME} from '~/src/config/localParams';
import FormLayout from '~/src/components/Guest/FormLayout';
import {useNavigate, useParams} from 'react-router-dom';
import {
    manageResponse,
    setAuthProps,
    CustomButton,
    ViewResponse,
    yup,
    useBackendSettings,
    Settings,
} from '@simplecoin/core';
import useSearchParameters from '~/src/hooks/useSearchParameters';
import {HeadManager} from '~/src/components';
import {RootState, useAppDispatch} from '~/src/redux/store';

const CodeField = styled(TextField)`
    && {
        .MuiOutlinedInput-input {
            font-size: 25px;
            padding: 12px;
        }
    }
`;

const WideConnectedButton = styled(CustomButton)`
    width: 100%;
`;

const initialValues = {
    code: '',
};

const rules = yup.object().shape({
    code: yup.string().length(6).required(),
});

/**
 * Renders field allowing user to enter 2FA code.
 *
 * On component mount it send request to validate token, if token valid allowes to see mounted content, otherwise
 * user sent to login page (url provided inside of validateTwoFactorCode action).
 */
function Confirm2Fa() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {token} = useParams<{token: string}>();
    const searchParams = useSearchParameters();
    const settings = useBackendSettings<Settings>();

    const {confirm2Fa, validate2Fa} = useSelector((state: RootState) => state.user);

    const formik = useFormik({
        initialValues,
        validationSchema: rules,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: FormikValues) => {
            const {code} = values;
            dispatch(confirmTwoFactorCode({token, code}));
        },
        validateOnMount: false,
    });

    const {values, errors, handleChange, handleSubmit} = formik;

    // Check if the token is valid
    useEffect(() => {
        dispatch(validateTwoFactorCode(token));
    }, [token]);

    // Validate request
    useEffect(() => {
        manageResponse({
            formik,
            reducer: confirm2Fa,
            onSuccess: (response: LoginReducer) => {
                setAuthProps(response);
                if (searchParams[REDIRECT_PARAM_NAME]) {
                    navigate(searchParams[REDIRECT_PARAM_NAME]);
                } else {
                    navigate('/dashboard');
                }
                dispatch(closeSnackbar({dismissAll: true})); // close all snackbars after successful login
            },
        });
    }, [confirm2Fa]);

    // Redirect back to login page if 2fa token is incorrect
    useEffect(() => {
        manageResponse({
            formik,
            reducer: validate2Fa,
            onError: () => {
                navigate('/login');
            },
        });
    }, [validate2Fa]);

    /**
     * Handles success callback for ViewResponse component.
     *
     * @return {*}
     */
    function onFetched() {
        return (
            <Stack direction='column' spacing={4} alignItems='center'>
                <Space size={24} />

                <Typography align='center' variant='h1'>
                    {t('page_confirm_two_factor')}
                </Typography>

                <Typography align='center' variant='body2'>
                    {t('page_confirm_two_factor_paragraph')}
                </Typography>

                <form onSubmit={handleSubmit} name='confirm-2fa-form'>
                    <Stack direction='column' spacing={2} alignItems='center' maxWidth='386px'>
                        <CodeField
                            inputMode='numeric'
                            pattern='[0-9]*'
                            name='code'
                            type='text'
                            value={values.code}
                            onChange={handleChange}
                            error={!!errors.code}
                            errorMessage={errors.code}
                            label={t('label_2fa_code')}
                            margin='normal'
                            variant='outlined'
                            autoComplete='off'
                            fullWidth
                        />
                        <WideConnectedButton type='submit' variant='contained' reducer={confirm2Fa}>
                            {t('confirm')}
                        </WideConnectedButton>
                    </Stack>

                    <Space size={20} />
                </form>

                <Typography variant='caption'>
                    {t('lost_2fa_device_contact_support', {
                        companyEmail: settings?.companyEmail,
                        companyPhone: settings?.companyPhone,
                    })}
                </Typography>
            </Stack>
        );
    }

    return (
        <FormLayout>
            <HeadManager title={t('page_confirm_two_factor')} />

            <ViewResponse reducer={validate2Fa} loaderInline={false} fetched={onFetched} />
        </FormLayout>
    );
}

export default Confirm2Fa;
