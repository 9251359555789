import React from 'react';
import {useTranslation} from 'react-i18next';
import {SocialVariant} from '~/src/typings';
import {IconButton, Stack, styled} from '@mui/material';

interface ButtonProps {
    textColor: string;
    bgColor: string;
}

const StyledButton = styled(IconButton)`
    padding: 12px 10px;
    display: flex;
    font-size: 17px;
    font-weight: 500;
    line-height: 31px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #363940;
    height: 40px;
    text-decoration: none;

    svg {
        margin-right: 10px;
        height: 24px;
    }
`;

const ButtonCentered = styled(StyledButton)`
    && {
        align-items: center;
        justify-content: center;
        border: 1px solid #6b6f7e;
        padding: 16px 32px;
        border-radius: 34px;
        height: 56px;
    }
`;

interface SocialItem extends ButtonProps {
    svg: React.ReactNode;
    i18nKey: string;
}

interface SocialItems {
    [socialName: string]: SocialItem;
}

const SOCIAL_DATA: SocialItems = {
    google: {
        svg: (
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'>
                <defs>
                    <path
                        id='a'
                        d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'
                    />
                </defs>
                <clipPath id='b'>
                    <use xlinkHref='#a' overflow='visible' />
                </clipPath>
                <path clipPath='url(#b)' fill='#FBBC05' d='M0 37V11l17 13z' />
                <path clipPath='url(#b)' fill='#EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z' />
                <path clipPath='url(#b)' fill='#34A853' d='M0 37l30-23 7.9 1L48 0v48H0z' />
                <path clipPath='url(#b)' fill='#4285F4' d='M48 48L17 24l-4-3 35-10z' />
            </svg>
        ),
        bgColor: '#ffffff',
        textColor: '#505f79',
        i18nKey: 'social_signin_with_google',
    },
    facebook: {
        svg: (
            <svg focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14222 14222'>
                <path
                    fill='#1777F2'
                    d='M14222 7111c0,-3927 -3184,-7111 -7111,-7111 -3927,0 -7111,3184 -7111,7111 0,3549 2600,6491 6000,7025l0 -4969 -1806 0 0 -2056 1806 0 0 -1567c0,-1782 1062,-2767 2686,-2767 778,0 1592,139 1592,139l0 1750 -897 0c-883,0 -1159,548 -1159,1111l0 1334 1972 0 -315 2056 -1657 0 0 4969c3400,-533 6000,-3475 6000,-7025z'
                />
                <path
                    fill='#FFFFFF'
                    d='M9879 9167l315 -2056 -1972 0 0 -1334c0,-562 275,-1111 1159,-1111l897 0 0 -1750c0,0 -814,-139 -1592,-139 -1624,0 -2686,984 -2686,2767l0 1567 -1806 0 0 2056 1806 0 0 4969c362,57 733,86 1111,86 378,0 749,-30 1111,-86l0 -4969 1657 0z'
                />
            </svg>
        ),
        bgColor: '#1977F3',
        textColor: '#FEFEFE',
        i18nKey: 'social_login_with_facebook',
    },
    linkedin: {
        svg: (
            <svg focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                <path
                    fill='#2867B2'
                    d='M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z'
                />
            </svg>
        ),
        bgColor: '#0077b5',
        textColor: '#ffffff',
        i18nKey: 'social_signin_with_linkedin',
    },
    vkontakte: {
        svg: (
            <svg
                focusable='false'
                className='svg-inline--fa fa-vk fa-w-18'
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 576 512'
            >
                <path
                    fill='currentColor'
                    d='M545 117.7c3.7-12.5 0-21.7-17.8-21.7h-58.9c-15 0-21.9 7.9-25.6 16.7 0 0-30 73.1-72.4 120.5-13.7 13.7-20 18.1-27.5 18.1-3.7 0-9.4-4.4-9.4-16.9V117.7c0-15-4.2-21.7-16.6-21.7h-92.6c-9.4 0-15 7-15 13.5 0 14.2 21.2 17.5 23.4 57.5v86.8c0 19-3.4 22.5-10.9 22.5-20 0-68.6-73.4-97.4-157.4-5.8-16.3-11.5-22.9-26.6-22.9H38.8c-16.8 0-20.2 7.9-20.2 16.7 0 15.6 20 93.1 93.1 195.5C160.4 378.1 229 416 291.4 416c37.5 0 42.1-8.4 42.1-22.9 0-66.8-3.4-73.1 15.4-73.1 8.7 0 23.7 4.4 58.7 38.1 40 40 46.6 57.9 69 57.9h58.9c16.8 0 25.3-8.4 20.4-25-11.2-34.9-86.9-106.7-90.3-111.5-8.7-11.2-6.2-16.2 0-26.2.1-.1 72-101.3 79.4-135.6z'
                />
            </svg>
        ),
        bgColor: '#4680C2',
        textColor: '#ffffff',
        i18nKey: 'social_login_with_vkontakte',
    },
    apple: {
        svg: (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 170' version='1.1'>
                <path
                    d='m150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zm-31.26-123.01c0 8.1021-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375-0.119-0.972-0.188-1.995-0.188-3.07 0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.3113 11.45-8.597 4.62-2.2516 8.99-3.4968 13.1-3.71 0.12 1.0831 0.17 2.1663 0.17 3.2409z'
                    fill='#fff'
                />
            </svg>
        ),
        bgColor: '#000000',
        textColor: '#ffffff',
        i18nKey: 'social_login_with_apple',
    },
};

export interface SocialLoginButtonProps {
    social: SocialVariant;
    href: string;
    children?: React.ReactNode | string;
}

/**
 * Renders social login button with SVG icon.
 * @param {string} social Social key name to render, e.g. "google".
 * @param {boolean} centered Wether or not center content of the button.
 * @param children
 */
export default function SocialLoginButton({social, children, href}: SocialLoginButtonProps) {
    const {t} = useTranslation();
    if (!social) {
        return null;
    }

    const socialData = SOCIAL_DATA[social];

    if (!socialData) {
        return null;
    }

    const {svg: icon, i18nKey} = socialData;

    if (!children) {
        children = t(i18nKey);
    }

    return (
        <ButtonCentered component='a' href={href}>
            <Stack
                direction='row'
                sx={{textDecoration: 'none'}}
                spacing={2}
                alignItems='center'
                justifyContent='center'
            >
                {icon}
                <span style={{minWidth: '155px'}} data-testid='social-name-label'>
                    {children}
                </span>
            </Stack>
        </ButtonCentered>
    );
}

SocialLoginButton.displayName = 'SocialLoginButton';
