import {Breadcrumbs as MuiBreadcrumbs, Link, styled, Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {useBackendSettings} from '@simplecoin/core';
import LocalizedLink from './LocalizedLink';
import {RootState} from '../redux/store';
import {BreadcrumbActionHolder} from '../redux/slices/breadcrumbSlice';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const StyledBreadcrumbs = styled(MuiBreadcrumbs)(({theme}) => ({
    '& .MuiLink-root': {
        fontSize: '15px',
    },
    padding: '0 25px',
    flexGrow: 2,
    [theme.breakpoints.down('lg')]: {
        padding: '0px',
    },
}));

const BreadcrumbsLink = styled(LocalizedLink)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: '15px',
    fontWeight: 700,
}));

/**
 * Shows breadcrumbs if they're available in redux
 * @constructor
 */
export default function Breadcrumbs() {
    const {data: breadcrumbsData} = useSelector((state: RootState) => state.breadcrumbs);
    const brand = useBackendSettings('brand_name', 'Simplecoin');

    if (!breadcrumbsData.category && !breadcrumbsData.currentPage) {
        return null;
    }

    function renderCurrentPage(): JSX.Element | null {
        if (breadcrumbsData.currentPage) {
            // Because a function cannot be stored in redux store we store it on global window object
            if ((window as BreadcrumbActionHolder).refreshOnLastBreadcrumbClick) {
                return (
                    <Link
                        component='button'
                        onClick={() => {
                            (window as BreadcrumbActionHolder).refreshOnLastBreadcrumbClick?.();
                        }}
                        underline='none'
                        color='text.primary'
                        fontWeight={400}
                    >
                        {breadcrumbsData.currentPage}
                    </Link>
                );
            } else {
                return (
                    <Typography variant='body2' component='span'>
                        {breadcrumbsData.currentPage}
                    </Typography>
                );
            }
        } else {
            return null;
        }
    }

    return (
        <StyledBreadcrumbs separator={<KeyboardArrowRightRoundedIcon />} color='secondary' aria-label='breadcrumb'>
            <BreadcrumbsLink key='1' to='/dashboard'>
                {brand}
            </BreadcrumbsLink>

            {breadcrumbsData.category && (
                <BreadcrumbsLink key='2' to={breadcrumbsData.categoryHref}>
                    {breadcrumbsData.category}
                </BreadcrumbsLink>
            )}

            {breadcrumbsData.subCategory && breadcrumbsData.subCategoryHref && (
                <BreadcrumbsLink key='3' to={breadcrumbsData.subCategoryHref}>
                    {breadcrumbsData.subCategory}
                </BreadcrumbsLink>
            )}

            {renderCurrentPage()}
        </StyledBreadcrumbs>
    );
}
