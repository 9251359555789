import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {createAuthOrder, createGuestOrder, fetchGeneric, ThunkApi} from '~/api';
import {Price, PriceRequestMeta} from '~/typings/Price';
import {Rate} from '~/typings/Rate';
import {ReducerEnvelope} from '~/typings/Api';
import {handleReducerCases} from '~/helpers/redux';

export interface PriceReducer extends ReducerEnvelope<Price> {
    requestMeta?: PriceRequestMeta;
}

export interface GuestOrderResponseValue {
    token?: string;
    order_id: number;
}
interface AuthOrderResponseValue {
    id?: number;
}

interface ExchangeReducer {
    rate: ReducerEnvelope<Rate>;
    price: PriceReducer;
    guestCreate: ReducerEnvelope<GuestOrderResponseValue>;
    authCreate: ReducerEnvelope<AuthOrderResponseValue>;
}

const initialState: ExchangeReducer = {
    rate: {isFetching: false},
    price: {isFetching: false},
    guestCreate: {isFetching: false},
    authCreate: {isFetching: false},
};

export interface BankAccountValues {
    currency_id: string;
    account_label: string;
    account_number: string;
    owner_name: string;
    owner_address: string;
    owner_country: string;
    owner_city: string;
    account_vs: string;
    account_specific: string;
    account_constant: string;
    message: string;
}

export interface CreateAuthOrderProps {
    fromCurrencyId: number;
    toCurrencyId: number;
    fromAmount: number;
    toAmount: number;
    bankAccount?: number;
    cryptoAccount?: number | string;
}

export interface CreateGuestOrderProps extends BankAccountValues {
    fromCurrencyId: number;
    toCurrencyId: number;
    fromAmount: number;
    toAmount: number;
    email: string;
    cryptoAccount?: string;
    arbitraryData?: string;
    reCaptcha?: string;
}

export interface PriceEndpointProps {
    fromCurrencyCode: string;
    toCurrencyCode: string;
    fromAmount?: number;
    toAmount?: number;
}

export interface RateEndpointProps {
    fromCurrencyCode: string;
    toCurrencyCode: string;
}

export const fetchCreateGuestOrder = createAsyncThunk(
    'exchange/create-guest',
    async (createGuestOrderParams: CreateGuestOrderProps, thunkApi) => {
        try {
            const res = await createGuestOrder(thunkApi, createGuestOrderParams);
            return res.data;
        } catch (err) {
            thunkApi.rejectWithValue('Rejection in actions creator' + JSON.stringify(err));
        }
    }
);

export const fetchCreateAuthOrder = createAsyncThunk(
    'exchange/create-auth',
    async (createAuthOrderProps: CreateAuthOrderProps, thunkApi) => {
        const res = await createAuthOrder(thunkApi, createAuthOrderProps);
        return res.data;
    }
);

const fetchRatePayloadCreator = async ({fromCurrencyCode, toCurrencyCode}: RateEndpointProps, thunkApi) => {
    try {
        const res = await fetchGeneric(
            {
                url: 'exchange/rate',
                params: {from_currency_code: fromCurrencyCode, to_currency_code: toCurrencyCode},
            },
            thunkApi as ThunkApi,
            false
        );
        return res.data;
    } catch (err) {
        thunkApi.rejectWithValue('Rejection in actions creator' + JSON.stringify(err));
    }
};

export const fetchRate = createAsyncThunk('exchange/rate', fetchRatePayloadCreator);
export const updateRate = createAsyncThunk('exchange/update-rate', fetchRatePayloadCreator);

const fetchPricePayloadCreator = async (
    {fromCurrencyCode, toCurrencyCode, fromAmount, toAmount}: PriceEndpointProps,
    thunkApi
) => {
    const direction = fromAmount ? 0 : 1;
    // @ts-ignore
    const amount: number = direction === 0 ? fromAmount : toAmount;
    const res = await fetchGeneric(
        {
            url: 'exchange/price',
            params: {from_currency_code: fromCurrencyCode, to_currency_code: toCurrencyCode, amount, direction},
        },
        thunkApi as ThunkApi,
        false
    );
    return res.data;
};

/**
 * Fetches price. Errors must be handled on component level
 */
export const fetchPrice = createAsyncThunk('exchange/price', fetchPricePayloadCreator);

/**
 * Updates price without isFetching state. Errors must be handled on component level
 */
export const updatePrice = createAsyncThunk('exchange/update-price', fetchPricePayloadCreator);

const priceFulfilledHandler = (state, action) => {
    state.isFetching = false;
    state.price = {...action.payload};
    state.price.requestMeta = {...action.meta.arg};
};

const slice = createSlice({
    name: 'exchange',
    initialState,
    extraReducers: (builder) => {
        handleReducerCases(fetchRate, 'rate', builder);
        handleReducerCases(updateRate, 'rate', builder, undefined, true);
        handleReducerCases(fetchPrice, 'price', builder, priceFulfilledHandler);
        handleReducerCases(updatePrice, 'price', builder, priceFulfilledHandler, true);
        handleReducerCases(fetchCreateGuestOrder, 'guestCreate', builder);
        handleReducerCases(fetchCreateAuthOrder, 'authCreate', builder);
    },
    reducers: {
        invalidatePrice: (state) => {
            state.price = initialState.price;
        },
        invalidateRate: (state) => {
            state.rate = initialState.rate;
        },
        invalidateAuthOrderCreate: (state) => {
            state.authCreate = initialState.authCreate;
        },
        invalidateGuestOrderCreate: (state) => {
            state.guestCreate = initialState.guestCreate;
        },
    },
});

export const {actions, reducer} = slice;

export const {invalidatePrice, invalidateRate, invalidateAuthOrderCreate, invalidateGuestOrderCreate} = slice.actions;
