import qs from 'qs';
import {matchPath} from 'react-router-dom';
import {ensureLanguageOnly} from './language';
import {AFFILIATE_PARAM_NAME, REDIRECT_PARAM_NAME} from '../config/params';

/**
 * Get path without language. Example: for /en/exchange it will return exchange (no leading slash)
 * @param path
 * @param withLeadingSlash
 *  @return {string} path
 */
export function getPathWithoutLanguage(path: string, withLeadingSlash = false): string {
    path = normalizePath(path);
    const result = path.replace(/(^|\/)([a-zA-Z]{2}(\/|$))/, '');

    if (path !== result) {
        return getPathWithoutLanguage(result, withLeadingSlash);
    }

    return ensureSlash({path: result, leading: withLeadingSlash});
}

/**
 * Returns path with provided language (if correct). If other language(s) are found, they're removed
 * @param {string} path
 * @param {string} language
 * @param {boolean} trailingSlash option to return path with trailing forward slash
 */
export function ensureLanguageInPath({path = '', language = '', trailingSlash = false}): string {
    path = normalizePath(path);
    language = ensureLanguageOnly(language);
    let result = `/${language}/${getPathWithoutLanguage(path, false)}`;

    result = ensureSlash({path: result, trailing: trailingSlash});

    return result;
}

/**
 * Replaces multiple forward slashes with single one
 * @param path
 */
function normalizePath(path: string) {
    return path.replace(/\/{2,}/, '/');
}

export interface EnsureSlashProps {
    path: string;
    leading?: boolean | undefined;
    trailing?: boolean | undefined;
}

/**
 * Ensures slashes around string path according to specified params
 * @param path
 * @param leading
 * @param trailing
 */
export function ensureSlash({path, leading = undefined, trailing = undefined}: EnsureSlashProps) {
    if (leading !== undefined) {
        if (leading) {
            path = path.charAt(0) === '/' ? path : `/${path}`;
        } else {
            path = path.charAt(0) === '/' ? path.substring(1) : path;
        }
    }

    if (trailing !== undefined) {
        if (trailing) {
            path = path.charAt(path.length - 1) === '/' ? path : `${path}/`;
        } else {
            path = path.charAt(path.length - 1) === '/' ? path.substring(0, path.length - 1) : path;
        }
    }
    return path;
}

/**
 * Constructs new url with the target path set as redirect param
 * @return {string}
 */
export function constructFailureUrl({targetPathname = ''}: {targetPathname?: string}) {
    let pathname = getPathWithoutLanguage(targetPathname);

    if (pathname && pathname !== 'login') {
        pathname = `?${REDIRECT_PARAM_NAME}=${pathname}`;
        return `/login${pathname}`;
    }

    return '/login';
}

/**
 * Checking whether pathname is matching regex defined by ROUTES constant.
 *
 * @param rePath
 * @param pathname
 * @return {boolean}
 */
export function isPathMatching(rePath, pathname): boolean {
    const result = matchPath(rePath, pathname);
    return !!result;
}

/**
 * Normalizes passed down query value by decoding URL characters + removing extra spaces.
 *
 * @param {string} value
 * @return {string | undefined}
 */
export function normalizeQueryValue(value?: string) {
    if (!value) {
        return value;
    }

    // Decodes URL from different characters, e.g. comma - ",", which is "%2C" encoded
    value = decodeURIComponent(value);
    // Replaces one or more spaces into single space
    return value.replace(/\++/g, ' ');
}

/**
 * Returns first word path.
 * Example: for path: /en/orders it returns orders
 *
 * @param {string} path
 * @return {string | undefined}
 */
export function getCategoryFromPath(path: string): string | undefined {
    const pathWithoutlanguage = getPathWithoutLanguage(path, true);
    const re = /\/([a-zA-Z-]{3,})(\/|$)/;
    const execArray = re.exec(pathWithoutlanguage);
    return execArray?.[1];
}

/**
 * Removes specified query params and returns new, normalized search value
 *
 * @param {string} search
 */
export function removeTrackingParam(search: string): string {
    const parsed = qs.parse(search, {ignoreQueryPrefix: true});

    if (parsed[AFFILIATE_PARAM_NAME]) {
        parsed[AFFILIATE_PARAM_NAME] = undefined;
    }

    return qs.stringify(parsed);
}

/**
 * Removes jwt from pathname (not to send it to google analytics)
 *
 * @param {string} pathname
 */
export function removeJwtFromPath(pathname: string): string {
    return pathname.replace(/\/[\w\d-_]{32}_\d{10,20}/, '');
}
