import {useSelector} from 'react-redux';
import {RootState} from '~/redux/store';
import {getConfigValue} from '~/helpers/config';

/**
 * Hook which helps to work with settings coming from backend. They are kept in redux.
 */
export default function useBackendSettings<T>(key?: string, defaultValue: any = {}): T {
    const {settingsData} = useSelector((store: RootState) => store.site.settings);

    if (!settingsData) {
        return defaultValue;
    }

    if (key) {
        const value = settingsData[key];

        if (value === undefined && getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.warn(`Setting ${key} seems to be missing or empty`);
        }
        return value ?? defaultValue;
    } else {
        return settingsData as unknown as T;
    }
}

useBackendSettings.displayName = 'useBackendSettings';
